import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import { ImmunizationConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import { MonitoringTabProps } from "../../../helpers/types";
import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

const ImmunizationsTab = ({
  id,
  query = ImmunizationsQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="immunization"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: ImmunizationConnection }>) => {
            const immunizations = isClinicalMonitoring
              ? data.impersonalImmunizations
              : data.immunizations;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {isEmpty(immunizations) || isEmpty(immunizations.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>: {immunizations.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={immunizations.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusSystem="eHealth/immunization_statuses"
                      tableName="immunizations"
                      pathSlug={
                        isClinicalMonitoring
                          ? `immunization/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "immunization"
                      }
                      isClinicalMonitoring={isClinicalMonitoring}
                      codeColumnTextType="vaccine"
                    />
                    <Pagination pageInfo={immunizations.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default ImmunizationsTab;

const ImmunizationsQuery = gql`
  query ImmunizationsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ImmunizationFilter!
    $orderBy: ImmunizationOrderBy
  ) {
    immunizations(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    immunizationDate,
    encounterId,
    status,
    immunizationId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(encounterId && {
        encounterId: paramToBase64("Encounter", encounterId)
      }),
      ...(immunizationDate && {
        date: `${immunizationDate.from}/${immunizationDate.to}`
      }),
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(immunizationId && {
        id: paramToBase64("Immunization", immunizationId)
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();

  return (
    <Flex flexDirection="column">
      <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter vaccine code"
            render={({ translation }) => (
              <Field.Text
                name="filter.vaccineCode"
                label={<Trans id="Vaccine code" />}
                placeholder={translation}
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter encounter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.encounterId"
                label={<Trans id="Encounter ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.encounterId"
            options={UUID_PATTERN}
            message="Invalid encounter ID"
          />
        </Box>
        <Box width={1 / 3} mt={3} px={1}>
          <DictionaryValue name="eHealth/immunization_statuses">
            {(dict: DictionaryAllValuesJson) => (
              <Field.Select
                name="filter.status"
                label={<Trans id="Immunization status" />}
                placeholder={i18n._(t`Select status`)}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || item}
                variant="select"
              />
            )}
          </DictionaryValue>
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Field.RangePicker
            rangeNames={[
              "filter.immunizationDate.from",
              "filter.immunizationDate.to"
            ]}
            label={<Trans id="Immunization date" />}
          />
          <Form.Spy>
            {({ values = {} }: $TSFixMe) => {
              if (
                (values.filter &&
                  values.filter.immunizationDate &&
                  values.filter.immunizationDate.from) ||
                (values.filter &&
                  values.filter.immunizationDate &&
                  values.filter.immunizationDate.to)
              ) {
                return (
                  <>
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.immunizationDate.from"
                          message={translation}
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.immunizationDate.to"
                          message={translation}
                        />
                      )}
                    />
                  </>
                );
              }
              return null;
            }}
          </Form.Spy>
        </Box>
        {isClinicalMonitoring && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter immunization ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.immunizationId"
                  label={<Trans id="Immunization ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.immunizationId"
              options={UUID_PATTERN}
              message="Invalid immunization ID"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
