import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { Scalars, ProgramService } from "@edenlabllc/graphql-schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import HeaderProgramService from "./HeaderProgramService";
import GeneralInfoProgramService from "./GeneralInfoProgramService";
import ServiceConnections from "./ServiceConnections";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"]["output"];
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams }: TLocationParams) => {
      const filteredParams = filteredLocationParams(locationParams);
      return (
        <Query
          query={ProgramServiceDetailsQuery}
          variables={{ id, ...filteredParams }}
        >
          {({
            loading,
            data
          }: QueryResult<{ programService: ProgramService }>) => {
            if (isEmpty(data) || isEmpty(data.programService)) return null;
            const {
              databaseId,
              isActive,
              requestAllowed,
              service,
              serviceGroup,
              consumerPrice,
              description,
              medicalProgram
            } = data.programService;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={6}>
                  <Box py={10}>
                    <Breadcrumbs.List>
                      <Breadcrumbs.Item to="/program-services">
                        <Trans>Program services</Trans>
                      </Breadcrumbs.Item>
                      <Breadcrumbs.Item>
                        <Trans>Program service details</Trans>
                      </Breadcrumbs.Item>
                    </Breadcrumbs.List>
                  </Box>
                  <HeaderProgramService
                    id={id!}
                    databaseId={databaseId}
                    isActive={isActive}
                    requestAllowed={requestAllowed}
                  />
                </Box>
                <Tabs.Nav>
                  <Tabs.NavItem to="./">
                    <Trans>General info</Trans>
                  </Tabs.NavItem>
                  <Tabs.NavItem to="./connections">
                    {service ? (
                      <Trans>Service</Trans>
                    ) : (
                      <Trans>Service group</Trans>
                    )}
                  </Tabs.NavItem>
                </Tabs.Nav>
                <Tabs.Content>
                  <Router>
                    <GeneralInfoProgramService
                      path="/"
                      id={id!}
                      consumerPrice={consumerPrice}
                      description={description}
                      medicalProgram={medicalProgram}
                    />
                    <ServiceConnections
                      path="/connections"
                      service={service}
                      serviceGroup={serviceGroup}
                    />
                  </Router>
                </Tabs.Content>
              </LoadingOverlay>
            );
          }}
        </Query>
      );
    }}
  </LocationParams>
);

const ProgramServiceDetailsQuery = gql`
  query ProgramServiceDetailsQuery($id: ID!) {
    programService(id: $id) {
      ...HeaderProgramService
      ...GeneralInfoProgramService
      ...ServiceConnections
    }
  }
  ${HeaderProgramService.fragments.entry}
  ${GeneralInfoProgramService.fragments.entry}
  ${ServiceConnections.fragments.entry}
`;

export default Details;
