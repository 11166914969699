import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationDispense } from "@edenlabllc/graphql-schema";

import Link from "../../../components/Link";
import Table from "../../../components/Table";

type MedicationDispenseDetailsProps = {
  details: MedicationDispense["details"];
};

export const MedicationDispenseDetails = ({
  details
}: MedicationDispenseDetailsProps) => {
  if (isEmpty(details)) return null;

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={details}
        header={{
          medicationId: <Trans id="Name">Medication</Trans>,
          medicationQty: <Trans>Medication dispense quantity</Trans>,
          sellPrice: <Trans>Actual retail price</Trans>,
          sellAmount: <Trans>Actual retail amount</Trans>,
          reimbursementAmount: (
            <Trans>Reimbursement amount with currency</Trans>
          ),
          discountAmount: <Trans>Discount amount with currency</Trans>
        }}
        renderRow={({
          medication,
          medicationQty,
          sellPrice,
          sellAmount,
          reimbursementAmount,
          discountAmount
        }) => ({
          medicationId: (
            <Link to={`/medications/${medication.id}`}>{medication.name}</Link>
          ),
          medicationQty,
          sellPrice,
          sellAmount,
          reimbursementAmount,
          discountAmount
        })}
      />
    </Box>
  );
};

MedicationDispenseDetails.fragments = {
  entry: gql`
    fragment MedicationDispenseDetail on MedicationDispenseDetail {
      discountAmount
      medication {
        id
        name
      }
      medicationQty
      reimbursementAmount
      sellAmount
      sellPrice
    }
  `
};
