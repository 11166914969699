import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Heading, Box } from "@rebass/emotion";

import system from "@edenlabllc/ehealth-system-components";
import { ProgramService } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import Price from "../../../components/Price";

import UpdateProgramServiceDescription from "./Mutations/UpdateDescription";

type GeneralInfoProgramServiceProps = RouteComponentProps & {
  id: ProgramService["id"];
  consumerPrice: ProgramService["consumerPrice"];
  description: ProgramService["description"];
  medicalProgram: ProgramService["medicalProgram"];
};

const GeneralInfoProgramService = ({
  id,
  consumerPrice,
  description,
  medicalProgram
}: GeneralInfoProgramServiceProps) => {
  const { databaseId, name, isActive } = medicalProgram || {};

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          consumerPrice: <Trans>Reimbursement amount</Trans>,
          description: <Trans>Description</Trans>
        }}
        data={{
          consumerPrice: consumerPrice && <Price amount={consumerPrice} />,
          description: (
            <DescriptionBox>
              {description}
              <UpdateProgramServiceDescription
                id={id}
                description={description}
              />
            </DescriptionBox>
          )
        }}
        labelWidth="120px"
      />
      {databaseId && (consumerPrice || description) && <Line />}
      {databaseId && (
        <>
          <Heading fontSize="1" fontWeight="normal" mb={5}>
            <Trans>Medical program</Trans>
          </Heading>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              name: <Trans>Name</Trans>,
              isActive: <Trans>Status</Trans>
            }}
            data={{
              databaseId: databaseId && (
                <Link to={`/medical-programs/${medicalProgram.id}`}>
                  {databaseId}
                </Link>
              ),
              name,
              isActive: (
                <Badge
                  type="ACTIVE_STATUS_M"
                  name={isActive}
                  variant={!isActive}
                  minWidth={100}
                />
              )
            }}
            labelWidth="120px"
          />
        </>
      )}
    </Box>
  );
};

GeneralInfoProgramService.fragments = {
  entry: gql`
    fragment GeneralInfoProgramService on ProgramService {
      description
      consumerPrice
      medicalProgram {
        id
        databaseId
        name
        isActive
      }
    }
  `
};

const DescriptionBox = system(
  {
    extend: Box
  },
  `
    white-space: pre-line;
  `
);

export default GeneralInfoProgramService;
