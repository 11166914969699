import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import {
  SearchIcon,
  PositiveIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import { Division, GlobalBudgetContract } from "@edenlabllc/graphql-schema";

import AddressView from "../../../components/AddressView";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import { GlobalBudgetContractQuery } from "./Details";

type DivisionsProps = RouteComponentProps<{
  id: string;
}>;

const Divisions = ({ id }: DivisionsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last, after, before, name } = locationParams;
      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Box px={5} pt={5} width={460}>
              <Trans
                id="Enter division name"
                render={({ translation }) => (
                  <Field.Text
                    name="name"
                    label={<Trans id="Find division" />}
                    placeholder={translation}
                    postfix={<SearchIcon color="silverCity" />}
                  />
                )}
              />
            </Box>
          </Form>
          <Query
            query={GlobalBudgetContractQuery}
            variables={{
              id,
              ...pagination({ first, last, after, before }),
              divisionFilter: { name }
            }}
          >
            {({
              loading,
              data
            }: QueryResult<{ globalBudgetContract: GlobalBudgetContract }>) => {
              if (
                isEmpty(data) ||
                isEmpty(data.globalBudgetContract) ||
                isEmpty(data.globalBudgetContract.contractorDivisions)
              )
                return null;
              const {
                globalBudgetContract: {
                  contractorDivisions: {
                    nodes: contractorDivisions = [],
                    pageInfo
                  }
                }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  {contractorDivisions!.length > 0 ? (
                    <>
                      <Table
                        data={contractorDivisions}
                        header={{
                          name: <Trans>Division name</Trans>,
                          addresses: <Trans>Address</Trans>,
                          mountainGroup: <Trans>Mountain region</Trans>,
                          phones: (
                            <>
                              <Trans>Phone</Trans>
                              <br />
                              <Trans>Email</Trans>
                            </>
                          ),
                          action: <Trans>Action</Trans>
                        }}
                        renderRow={({
                          databaseId,
                          name,
                          addresses,
                          mountainGroup,
                          phones,
                          email
                        }: Division) => ({
                          name,
                          mountainGroup: (
                            <Flex justifyContent="center">
                              {mountainGroup ? (
                                <PositiveIcon />
                              ) : (
                                <NegativeIcon />
                              )}
                            </Flex>
                          ),
                          phones: (
                            <>
                              <Box>
                                {phones
                                  .filter((a) => a && a.type === "MOBILE")
                                  .map((item) => item && item.number)[0] ||
                                  (phones && phones[0] && phones[0].number)}
                              </Box>
                              <Box>{email}</Box>
                            </>
                          ),
                          addresses: addresses
                            .filter((a) => a && a.type === "RESIDENCE")
                            .map((item, key) => (
                              <AddressView data={item} key={key} />
                            )),
                          action: (
                            <Link
                              to={`../employees?division.databaseId=${databaseId}&division.name=${name}`}
                              fontWeight="bold"
                            >
                              <Trans>Go to employees</Trans>
                            </Link>
                          )
                        })}
                        tableName="/contract/divisions"
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

export default Divisions;
