import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationDispense } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import Link from "../../../components/Link";
import Line from "../../../components/Line";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";
import { DATE_FORMAT } from "../../../constants/dateFormats";

import { MedicationDispenseDetails } from "./MedicationDispenseDetails";

type GeneralInfoProps = RouteComponentProps & {
  medicationDispense?: MedicationDispense;
};

const GeneralInfo = ({ medicationDispense }: GeneralInfoProps) => {
  if (isEmpty(medicationDispense)) return null;

  return (
    <Box p={5}>
      <TopBodyDefinitions medicationDispense={medicationDispense} />
      <Line />
      {!isEmpty(medicationDispense.details) && (
        <Box mb={4}>
          <Heading fontSize="1" fontWeight="normal" mb={5}>
            <Trans>Medications name</Trans>
          </Heading>
          <MedicationDispenseDetails details={medicationDispense.details} />
        </Box>
      )}
    </Box>
  );
};

type TopBodyDefinitionsProps = {
  medicationDispense: MedicationDispense;
};

const TopBodyDefinitions = ({
  medicationDispense
}: TopBodyDefinitionsProps) => {
  const { i18n } = useLingui();

  return (
    <DefinitionListView
      labelWidth="230px"
      labels={{
        medicationRequest: <Trans>Medical request</Trans>,
        dispensedAt: <Trans>Dispensed at</Trans>,
        legalEntity: <Trans id="Pharmacy">Legal entity</Trans>,
        division: <Trans id="Subdivision">Division</Trans>,
        dispensedBy: <Trans>Dispensed by</Trans>,
        party: <Trans>Employee account</Trans>,
        patientId: <Trans>Patient</Trans>,
        ...(medicationDispense.medicalProgram && {
          medicalProgram: <Trans>Medical program</Trans>
        }),
        ...(typeof medicationDispense.paymentAmount === "number" && {
          paymentAmount: <Trans>Payment amount</Trans>
        }),
        ...(medicationDispense.paymentId && {
          paymentId: <Trans>Payment ID</Trans>
        })
      }}
      data={{
        ...medicationDispense,
        medicationRequest: (
          <Box>
            <Link
              to={`/medication-requests/${paramToBase64(
                "MedicationRequest",
                medicationDispense.medicationRequestId
              )}`}
            >
              {medicationDispense.medicationRequestId}
            </Link>
          </Box>
        ),
        dispensedAt: dateFormatter(
          i18n.locale,
          DATE_FORMAT,
          medicationDispense.dispensedAt
        ),
        legalEntity: (
          <Box>
            <Link to={`/legal-entities/${medicationDispense.legalEntity.id}`}>
              {medicationDispense.legalEntity.name}
            </Link>
          </Box>
        ),
        division: (
          <Box>
            <Link
              to={`/legal-entities/${medicationDispense.legalEntity.id}/divisions/${medicationDispense.division.id}`}
            >
              {medicationDispense.division.name}
            </Link>
          </Box>
        ),
        dispensedBy: medicationDispense.dispensedBy,
        ...(medicationDispense.medicalProgram && {
          medicalProgram: (
            <Box>
              <Link
                to={`/medical-programs/${medicationDispense.medicalProgram.id}/`}
              >
                {medicationDispense.medicalProgram.name}
              </Link>
            </Box>
          )
        }),
        party: medicationDispense.party.databaseId,
        ...(typeof medicationDispense.paymentAmount === "number" && {
          paymentAmount: String(medicationDispense.paymentAmount)
        }),
        ...(medicationDispense.paymentId && {
          paymentId: medicationDispense.paymentId
        })
      }}
    />
  );
};

export default GeneralInfo;
