import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { DiagnosesGroupCodeConnection } from "@edenlabllc/graphql-schema";

import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import * as SearchField from "../../../components/SearchField";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import DiagnosesGroupDiagnosesTable from "./DiagnosesGroupDiagnosesTable";

type DiagnosesProps = RouteComponentProps<{
  id: string;
}>;

const Diagnoses = ({ id }: DiagnosesProps) => {
  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        return (
          <Query
            query={DiagnosesGroupCodesQuery}
            variables={{
              ...pagination(locationParams),
              filter: {
                diagnosesGroupId: id,
                ...locationParams.filter
              }
            }}
            fetchPolicy="network-only"
          >
            {({
              loading,
              data
            }: QueryResult<{
              diagnosesGroupCodes: DiagnosesGroupCodeConnection;
            }>) => {
              return (
                <LoadingOverlay loading={loading}>
                  <Box p={5}>
                    <SearchForm
                      initialValues={locationParams}
                      onSubmit={setLocationParams}
                      renderPrimary={PrimarySearchFields}
                    />
                    {isEmpty(data) ||
                    isEmpty(data.diagnosesGroupCodes) ||
                    isEmpty(data.diagnosesGroupCodes.nodes) ? (
                      <EmptyData />
                    ) : (
                      <>
                        <DiagnosesGroupDiagnosesTable
                          diagnosesGroupCodes={data.diagnosesGroupCodes.nodes}
                          tableName="diagnosesGroupCodes/search"
                        />
                        <Pagination
                          pageInfo={data.diagnosesGroupCodes.pageInfo}
                        />
                      </>
                    )}
                  </Box>
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  );
};

export default Diagnoses;

const PrimarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Code"
        render={({ translation }) => (
          <Field.Text
            name="filter.code"
            label={<Trans id="Find by Dictionary code" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.DiagnosesGroupSystem name="filter.system" />
    </Box>
  </Flex>
);

export const DiagnosesGroupCodesQuery = gql`
  query DiagnosesGroupCodesQuery(
    $filter: DiagnosesGroupCodesFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    diagnosesGroupCodes(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      nodes {
        id
        databaseId
        code
        system
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;
