const colors = {
  black: "#000",
  white: "#fff",
  gunPowder: "#484852",
  wallsOfSantorini: "#E9EDF1",
  silverCity: "#DFE3E9",
  shiningKnight: "#9AA0A9",
  blueberrySoda: "#7F8FA4",
  darkAndStormy: "#354052",
  redPigment: "#ED1C24",
  darkPastelGreen: "#1BB934",
  rockmanBlue: "#2EA2F8",
  bluePastel: "#4A90E2",
  darkMidnightBlue: "#003076",
  enchantedBlue: "#007796",
  boysenberryShadow: "#F1F3F9",
  hintOfCandela: "#D6DDE6",
  biroBlue: "#333C48",
  januaryDawn: "#DFE2E5",
  callaLily: "#E6EAEE",
  romanSilver: "#848C98",
  jacarandaLight: "#A8AAB7",
  orangePastel: "#F7981C",
  jupiter: "#E0E0E0",
  paleGrey: "#FAFBFC",
  lightImageTone: "#CED0DA",
  peterPan: "#27AA11",
  darkCharcoal: "#333333",
  lightRose: "#ffb5af"
};

export default colors;
