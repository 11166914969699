import React from "react";
import { gql } from "graphql-tag";

import {
  Maybe,
  Quantity as TQuantity,
  SimpleQuantity as TSimpleQuantity
} from "@edenlabllc/graphql-schema";

import Quantity from "./Quantity";

type SimpleQuantityProps = {
  simpleQuantity?: Maybe<TSimpleQuantity | TQuantity>;
};

export const SimpleQuantity = ({ simpleQuantity }: SimpleQuantityProps) => (
  <Quantity quantity={simpleQuantity} />
);

SimpleQuantity.fragments = {
  entry: gql`
    fragment SimpleQuantity on SimpleQuantity {
      code
      system
      unit
      value
    }
  `
};
