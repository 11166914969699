import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { cleanDeep } from "@edenlabllc/ehealth-utils";
import { PersonConnection } from "@edenlabllc/graphql-schema";

import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import filteredLocationParams from "../../../helpers/filteredLocationParams";

import PersonsTable from "./PersonsTable";
import {
  PrimarySearchFields,
  SecondarySearchFields,
  SearchButton
} from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Patient Search</Trans>
    </Heading>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          filter: { taxId, databaseId, identity, personal, status } = {}
        } = locationParams;
        const { number, type, ...documents } = identity || {};

        const filterPersonsParams = cleanDeep({
          taxId,
          databaseId,
          identity: {
            ...documents,
            document: number && {
              type: type,
              number
            }
          },
          personal,
          status
        });
        return (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
              searchButton={SearchButton}
            />
            <Query
              skip={
                isEmpty(taxId) &&
                isEmpty(databaseId) &&
                (isEmpty(identity) || isEmpty(personal))
              }
              fetchPolicy="network-only"
              query={SearchPersonsQuery}
              variables={{
                ...filteredLocationParams(locationParams),
                filter: filterPersonsParams
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ persons: PersonConnection }>) => {
                const pageInfo = data && data.persons && data.persons.pageInfo;
                const persons = isEmpty(data) ? [] : data.persons.nodes;

                return (
                  <LoadingOverlay loading={loading}>
                    <PersonsTable
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      persons={persons}
                    />
                    <Pagination pageInfo={pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export const SearchPersonsQuery = gql`
  query SearchPersonsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: PersonFilter!
    $orderBy: PersonOrderBy
  ) {
    persons(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...Persons
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PersonsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
