import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { DeviceDefinition } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";

type PackagingProps = RouteComponentProps & {
  packagingType: DeviceDefinition["packagingType"];
  packagingUnit: DeviceDefinition["packagingUnit"];
  packagingCount: DeviceDefinition["packagingCount"];
};

const Packaging = ({
  packagingType,
  packagingUnit,
  packagingCount
}: PackagingProps) => (
  <Box p={5}>
    <DefinitionListView
      labelWidth={160}
      labels={{
        packagingType: <Trans>Device definition packaging type</Trans>,
        packagingUnit: <Trans>Device definition packaging unit</Trans>,
        packagingCount: <Trans>Device definition packaging count</Trans>
      }}
      data={{
        packagingType: (
          <DictionaryValue
            name="device_definition_packaging_type"
            item={packagingType}
          />
        ),
        packagingUnit: (
          <DictionaryValue name="device_unit" item={packagingUnit} />
        ),
        packagingCount
      }}
    />
  </Box>
);

Packaging.fragments = {
  entry: gql`
    fragment DeviceDefinitionPackaging on DeviceDefinition {
      packagingType
      packagingUnit
      packagingCount
    }
  `
};

export default Packaging;
