import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Procedure as TProcedure } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import dateFormatter from "../../../helpers/dateFormatter";
import LoadingOverlay from "../../../components/LoadingOverlay";

import { MonitoringDetailsPageProps } from "../../../helpers/types";
import paramToBase64 from "../../../helpers/paramToBase64";
import STATUSES from "../../../helpers/statuses";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import DivisionReference from "./DivisionReference";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Reference from "./Reference";
import ReferenceList from "./ReferenceList";
import ReasonReferences from "./ReasonReferences";
import ServiceRequestCode from "./ServiceRequestCode";
import { LABEL_WIDTH } from "./Episode";

const Procedure = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="procedure"
  >
    <Box p={6}>
      <DetailsPageBreadcrumbs
        id={id}
        currentStepName={<Trans>Procedure details</Trans>}
        isClinicalMonitoring={isClinicalMonitoring}
      />
      <Query
        query={query!}
        fetchPolicy="network-only"
        variables={{
          ...queryVariables,
          id: itemId,
          ...(isClinicalMonitoring && { patientId })
        }}
      >
        {({
          loading,
          error,
          data = {}
        }: QueryResult<{ [key: string]: TProcedure }>) => {
          const procedure = isClinicalMonitoring
            ? data.impersonalProcedure
            : data.procedure;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(data) && !isEmpty(procedure) && !error && (
                  <>
                    <HeaderDefinitions
                      procedure={procedure}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId!}
                    />
                    <Box mt={4}>
                      <TopBodyDefinitions
                        procedure={procedure}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId!}
                      />
                      <ServiceRequestCode serviceRequestCode={procedure.code} />
                      {!isEmpty(procedure.encounter) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Encounter</Trans>}
                            linkPath={
                              isClinicalMonitoring
                                ? `../../../encounter/${patientId}/${paramToBase64(
                                    "Encounter",
                                    procedure.encounter.identifier.value
                                  )}`
                                : `../../encounter/${paramToBase64(
                                    "Encounter",
                                    procedure.encounter.identifier.value
                                  )}`
                            }
                            linkDisplayValue={
                              procedure.encounter.displayValue
                                ? procedure.encounter.displayValue
                                : procedure.encounter.identifier.value
                            }
                          />
                        </Box>
                      )}
                      {!isEmpty(procedure.recordedBy) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Recorded by</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              procedure.recordedBy.identifier.value
                            )}`}
                            linkDisplayValue={
                              procedure.recordedBy.displayValue
                                ? procedure.recordedBy.displayValue
                                : procedure.recordedBy.identifier.value
                            }
                          />
                        </Box>
                      )}
                      {!isEmpty(procedure.performer) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Performer</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              procedure.performer.identifier.value
                            )}`}
                            linkDisplayValue={
                              procedure.performer.displayValue
                                ? procedure.performer.displayValue
                                : procedure.performer.identifier.value
                            }
                          />
                        </Box>
                      )}
                      <DivisionReference
                        reference={procedure}
                        divisionFieldPath="division"
                        header={<Trans>Division</Trans>}
                      />
                      {!isEmpty(procedure.managingOrganization) && (
                        <Reference
                          fontSize={14}
                          header={<Trans>Managing organization</Trans>}
                          linkPath={`/legal-entities/${paramToBase64(
                            "LegalEntity",
                            procedure.managingOrganization.identifier.value
                          )}`}
                          linkDisplayValue={
                            procedure.managingOrganization.displayValue
                              ? procedure.managingOrganization.displayValue
                              : procedure.managingOrganization.identifier.value
                          }
                        />
                      )}
                      {!isEmpty(procedure.reasonReferences) && (
                        <>
                          <Box mt={4}>
                            <Text
                              fontSize={1}
                              fontWeight="bold"
                              color="darkCharcoal"
                            >
                              <Trans id="Procedure reason reference">
                                Reason reference
                              </Trans>
                            </Text>
                          </Box>
                          <ReasonReferences
                            reasonReferences={procedure.reasonReferences}
                            isClinicalMonitoring={isClinicalMonitoring}
                            patientId={patientId}
                          />
                        </>
                      )}
                      <BottomBodyDefinitions
                        procedure={procedure}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId!}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Box>
  </Ability>
);

export default Procedure;

type HeaderDefinitionsProps = {
  procedure: TProcedure;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  procedure,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(procedure)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              ...(procedure.status && {
                status: <Trans>Status</Trans>
              }),
              ...(procedure.statusReason && {
                statusReason: (
                  <Trans id="Procedure status reason">Status reason</Trans>
                )
              }),
              ...(procedure.explanatoryLetter && {
                explanatoryLetter: <Trans>Explanatory letter</Trans>
              })
            }}
            data={{
              ...procedure,
              status: (
                <DictionaryValue
                  name="eHealth/procedure_statuses"
                  item={procedure.status.toLowerCase()}
                />
              ),
              statusReason: (
                <CodeableConcept codeableConcept={procedure.statusReason} />
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "procedure",
              procedure.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

type TopBodyDefinitionsProps = {
  procedure: TProcedure;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const TopBodyDefinitions = ({
  procedure,
  isClinicalMonitoring,
  patientId
}: TopBodyDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(procedure)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        ...(!isEmpty(procedure.basedOn) && {
          basedOn: <Trans id="Procedure based on">Based on</Trans>
        }),
        performedDateTime: <Trans>Performed date time</Trans>,
        primarySource: <Trans>Primary source</Trans>,
        ...(!isEmpty(procedure.primarySource) && {
          primarySource: <Trans>Primary source</Trans>
        }),
        ...(!isEmpty(procedure.reportOrigin) && {
          reportOrigin: <Trans>Report origin</Trans>
        }),
        ...(!isEmpty(procedure.outcome) && { outcome: <Trans>Outcome</Trans> }),
        ...(!isEmpty(procedure.complicationDetails) && {
          complicationDetails: <Trans>Complication details</Trans>
        })
      }}
      data={{
        ...procedure,
        ...(!isEmpty(procedure.basedOn) && {
          basedOn: (
            <Reference
              fontSize={14}
              headless
              linkPath={
                isClinicalMonitoring
                  ? `../../../service-request/${patientId}/${paramToBase64(
                      "ServiceRequest",
                      procedure.basedOn.identifier.value
                    )}`
                  : `../../service-request/${paramToBase64(
                      "ServiceRequest",
                      procedure.basedOn.identifier.value
                    )}`
              }
              linkDisplayValue={
                procedure.basedOn.displayValue
                  ? procedure.basedOn.displayValue
                  : procedure.basedOn.identifier.value
              }
              linkWrapperProps={{
                mt: 0,
                ml: 0
              }}
            />
          )
        }),
        performedDateTime: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          procedure.performedDateTime
        ),
        // @ts-expect-error statuses boolean key
        primarySource: <Box>{STATUSES.YES_NO[procedure.primarySource]}</Box>,
        ...(!isEmpty(procedure.reportOrigin) && {
          reportOrigin: (
            <CodeableConcept codeableConcept={procedure.reportOrigin} />
          )
        }),
        ...(procedure.outcome && {
          outcome: <CodeableConcept codeableConcept={procedure.outcome} />
        }),
        ...(!isEmpty(procedure.complicationDetails) && {
          complicationDetails: (
            <ReferenceList
              referencesList={procedure.complicationDetails}
              pathSlug={
                isClinicalMonitoring
                  ? `../../../condition/${patientId}`
                  : "../../condition"
              }
              entityName="Condition"
              label={t`Condition`}
            />
          )
        })
      }}
    />
  );
};

type BottomBodyDefinitionsProps = {
  procedure: TProcedure;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const BottomBodyDefinitions = ({
  procedure,
  isClinicalMonitoring,
  patientId
}: BottomBodyDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(procedure)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          ...(procedure.note && {
            note: <Trans>Note</Trans>
          }),
          ...(!isEmpty(procedure.originEpisode) && {
            originEpisode: <Trans>Origin episode</Trans>
          }),
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          ...(procedure.note && {
            note: procedure.note
          }),
          ...(!isEmpty(procedure.originEpisode) && {
            originEpisode: (
              <Reference
                fontSize={14}
                headless
                linkPath={
                  isClinicalMonitoring
                    ? `../../../episode/${patientId}/${paramToBase64(
                        "Episode",
                        procedure.originEpisode.identifier.value
                      )}`
                    : `../../episode/${paramToBase64(
                        "Episode",
                        procedure.originEpisode.identifier.value
                      )}`
                }
                linkDisplayValue={
                  procedure.originEpisode.displayValue
                    ? procedure.originEpisode.displayValue
                    : procedure.originEpisode.identifier.value
                }
                linkWrapperProps={{
                  mt: 0,
                  ml: 0
                }}
              />
            )
          }),
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            procedure.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            procedure.updatedAt
          )
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

Procedure.fragments = {
  entry: gql`
    fragment Procedure on Procedure {
      id
      databaseId
      status
      statusReason {
        ...CodeableConcept
      }
      basedOn {
        ...Reference
      }
      explanatoryLetter
      code {
        ...ServiceRequestCode
      }
      encounter {
        ...Reference
      }
      performedDateTime
      recordedBy {
        ...Reference
      }
      primarySource
      reportOrigin {
        ...CodeableConcept
      }
      performer {
        ...Reference
      }
      division {
        ...Reference
      }
      managingOrganization {
        ...Reference
      }
      reasonReferences {
        ...Reference
      }
      outcome @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      complicationDetails @skip(if: $skipAdditionalFields) {
        ...Reference
      }
      note @skip(if: $skipAdditionalFields)
      originEpisode {
        ...Reference
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
    ${ServiceRequestCode.fragments.entry}
  `
};

export const ProcedureByIDQuery = gql`
  query ProcedureByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    procedure(id: $id, personId: $personId) {
      ...Procedure
    }
  }
  ${Procedure.fragments.entry}
`;
