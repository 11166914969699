import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { ProgramMedication } from "@edenlabllc/graphql-schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import Header from "./Header";
import GeneralInfo from "./GeneralInfo";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <Query query={ProgramMedicationQuery} variables={{ id }}>
    {({
      loading,
      data
    }: QueryResult<{ programMedication: ProgramMedication }>) => {
      if (isEmpty(data) || isEmpty(data.programMedication)) return null;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/program-medications">
                  <Trans>Search program medications</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Program medication details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Header id={id!} programMedication={data.programMedication} />
          </Box>

          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General info</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <GeneralInfo
                path="/"
                programMedication={data.programMedication}
              />
            </Router>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

const ProgramMedicationQuery = gql`
  query ProgramMedicationQuery($id: ID!) {
    programMedication(id: $id) {
      ...ProgramMedicationHeader
      ...ProgramMedicationGeneralInfo
    }
  }
  ${Header.fragments.entry}
  ${GeneralInfo.fragments.entry}
`;

export default Details;
