import React, { useState, useCallback } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Flex, Text } from "@rebass/emotion";
import { Config } from "react-awesome-query-builder";
import isEmpty from "lodash/isEmpty";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  Form,
  LocationParams,
  SUBMIT_ERROR,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  PositiveIcon,
  NegativeIcon,
  RemoveItemIcon
} from "@edenlabllc/ehealth-icons";
import { RuleConnection, RuleItem } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import EhealthQueryBuilder from "../../../../components/QueryBuilder";
import * as Field from "../../../../components/Field";
import {
  getInitTree,
  getTreeFromJson,
  getJsonStringFromTree
} from "../../../../components/QueryBuilder/helpers";
import Line from "../../../../components/Line";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import Popup from "../../../../components/Popup";
import SearchForm, { TLocationParams } from "../../../../components/SearchForm";
import Steps from "../../../../components/Steps";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";
import useConfig from "../../../../components/QueryBuilder/useConfig";

import filteredLocationParams from "../../../../helpers/filteredLocationParams";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

import { RuleEngineRulesQuery } from "../index";
import { PrimarySearchFields } from "../SearchFields";
import RuleEngineRulesTable from "../RuleEngineRulesTable";

import env from "../../../../env";

export const itemsComparators: any = {
  and: "Ta",
  or: "Або"
};

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <Ability action="write" resource="rule_engine_rule">
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill info</Trans>
        </Steps.Item>
        <Steps.Item to="./rules" state={state} disabled>
          <Trans>Configure rules</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>approve by EDS</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <GeneralForm path="/" />
      <Rules path="/rules/*" />
      <Confirmation path="/confirm" />
    </Router>
  </Ability>
);

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();

  return (
    <Box p={5}>
      <Form
        onSubmit={(data: {
          name: string;
          code: {
            code: string;
            system: string;
          };
          description: string;
        }) => {
          navigate!("./rules", {
            state: { ...state, createRuleEngineRule: data }
          });
        }}
        initialValues={state ? state.createRuleEngineRule : {}}
      >
        <Form.Spy>
          {({ values: { code = {} } = {} }: $TSFixMe) => (
            <>
              <Box width={1 / 2}>
                <Trans
                  id="Enter rule engine rule title"
                  render={({ translation }) => (
                    <Field.Text
                      name="name"
                      label={<Trans id="Rule engine rule title" />}
                      placeholder={translation}
                      maxLength={255}
                      showLengthHint
                    />
                  )}
                />
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required field="name" message={translation} />
                  )}
                />
              </Box>

              <Box width={1 / 2}>
                <DictionaryValue name="eHealth/rule_engine_dictionaries">
                  {(dict: DictionaryAllValuesJson) => (
                    <Field.Select
                      name="code.system"
                      label={<Trans id="Coding system" />}
                      placeholder={i18n._(t`Select option`)}
                      items={dict ? Object.keys(dict) : []}
                      itemToString={(item: string) =>
                        dict ? dict[item] : item
                      }
                      variant="select"
                    />
                  )}
                </DictionaryValue>
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required
                      field="code.system"
                      message={translation}
                    />
                  )}
                />
              </Box>

              <Box width={1 / 2}>
                <DictionaryValue name={code.system}>
                  {(dict: DictionaryAllValuesJson) => (
                    <Field.Select
                      name="code.code"
                      label={<Trans id="Code" />}
                      placeholder={i18n._(t`Select option`)}
                      items={dict ? Object.keys(dict) : []}
                      itemToString={(item: string) =>
                        dict ? dict[item] : item
                      }
                      variant="select"
                      disabled={!code.system}
                      filterOptions={{ keys: [(item: string) => dict[item]] }}
                    />
                  )}
                </DictionaryValue>
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required
                      field="code.code"
                      message={translation}
                    />
                  )}
                />
              </Box>

              <Box width={1 / 2}>
                <Trans
                  id="Enter description"
                  render={({ translation }) => (
                    <Field.Textarea
                      name="description"
                      label={<Trans id="Description" />}
                      placeholder={translation}
                      rows={5}
                      maxLength={255}
                      showLengthHint
                    />
                  )}
                />
              </Box>
            </>
          )}
        </Form.Spy>
        <Flex pt={5} mb={100}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!("../search")}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Next</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

const Rules = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const config = useConfig();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  const createRuleEngineRule = (state && state.createRuleEngineRule) || {};
  const setChooseValue = (items: RuleItem[]) => {
    navigate!(".", {
      state: {
        ...state,
        items: items.map((item) => ({
          description: item.description,
          value: {
            json: item.value.json,
            string: item.value.string
          }
        }))
      }
    });
  };

  return (
    <Box p={5}>
      <Flex justifyContent="space-between">
        <Box>
          <DefinitionListView
            labels={{
              name: <Trans>Rule engine rule title</Trans>,
              code: <Trans>Code</Trans>,
              description: <Trans>Description</Trans>
            }}
            data={{
              name: createRuleEngineRule.name,
              code: createRuleEngineRule.code && (
                <DictionaryValue
                  name={createRuleEngineRule.code.system}
                  item={createRuleEngineRule.code.code}
                />
              ),
              description: createRuleEngineRule.description || "-"
            }}
          />
        </Box>
        <Flex flexDirection="column" alignItems="flex-end">
          <Box mb={4}>
            <Button variant="green" onClick={toggle}>
              <Trans>Fill from existing rule engine rule</Trans>
            </Button>
          </Box>
          <ImportFile setChooseValue={setChooseValue} />
        </Flex>
      </Flex>
      <Line />
      {!config.fields ? (
        <LoadingOverlay loading={true} />
      ) : (
        <Router>
          <RuleItems path="/" />
          <RuleModalForm path="/:ruleNumber" config={config} />
        </Router>
      )}

      <Popup
        visible={isPopupVisible}
        onCancel={toggle}
        title={<Trans>Fill from existing rule engine rule</Trans>}
        hideOkButton={true}
        width={1000}
      >
        <LocationParams state={state}>
          {({ locationParams, setLocationParams }: TLocationParams) => (
            <Box mt={4}>
              <SearchForm
                initialValues={locationParams}
                onSubmit={setLocationParams}
                renderPrimary={PrimarySearchFields}
              />
              <Query
                fetchPolicy="cache-and-network"
                query={RuleEngineRulesQuery}
                variables={{
                  ...filteredLocationParams(locationParams),
                  orderBy: locationParams.orderBy
                    ? locationParams.orderBy
                    : "INSERTED_AT_DESC"
                }}
              >
                {({
                  loading,
                  data
                }: QueryResult<{
                  ruleEngineRules: RuleConnection;
                }>) => {
                  if (isEmpty(data) || isEmpty(data.ruleEngineRules))
                    return null;
                  const {
                    ruleEngineRules: { nodes: ruleEngineRules = [], pageInfo }
                  } = data;

                  return (
                    <LoadingOverlay loading={loading}>
                      <RuleEngineRulesTable
                        ruleEngineRules={ruleEngineRules}
                        onChoose={setChooseValue}
                        onClosePopup={toggle}
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                      />
                      <Pagination pageInfo={pageInfo} />
                    </LoadingOverlay>
                  );
                }}
              </Query>
            </Box>
          )}
        </LocationParams>
      </Popup>
    </Box>
  );
};

const RuleItems = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();

  return (
    <Form
      initialValues={state}
      onSubmit={(data: { items: RuleItem[]; itemsComparator: string }) => {
        let itemValidation;
        if (!(data.items && data.items.length)) {
          itemValidation = [
            {
              entry: "atleast.item.added",
              rules: [
                {
                  rule: "notAddedAtleastOneRuleItem"
                }
              ]
            }
          ];
        } else if (
          !data.items.every((item) => item && item.value && item.value.string)
        ) {
          itemValidation = [
            {
              entry: "rule.item.valid",
              rules: [
                {
                  rule: "notValidRuleItem"
                }
              ]
            }
          ];
        }
        if (itemValidation) {
          return { [SUBMIT_ERROR]: itemValidation };
        }

        return navigate!("../confirm", {
          state: {
            ...data
          }
        });
      }}
    >
      <Box width={1 / 3}>
        <Trans
          id="Choose items comparator"
          render={({ translation }) => (
            <Field.Select
              name="itemsComparator"
              label={<Trans id="Items comparator" />}
              placeholder={translation}
              items={Object.keys(itemsComparators)}
              itemToString={(item: string) =>
                itemsComparators[item] || translation
              }
              variant="select"
            />
          )}
        />
        <Trans
          id="Required field"
          render={({ translation }) => (
            <Validation.Required
              field="itemsComparator"
              message={translation}
            />
          )}
        />
      </Box>
      <Field.Array
        name="items"
        addText={<Trans>Add rule</Trans>}
        fields={({ name }: { name: any }) => {
          const number = name.match(/\d+/)[0];

          return (
            <Form.Spy>
              {({ values = {} }: $TSFixMe) => (
                <Flex
                  mx={-1}
                  alignItems="center"
                  justifyContent="space-between"
                  width={1}
                  mb={4}
                >
                  <Flex>
                    <Box px={1}>
                      {values.items &&
                      values.items[number] &&
                      values.items[number].value &&
                      values.items[number].value.string ? (
                        <PositiveIcon />
                      ) : (
                        <NegativeIcon />
                      )}
                    </Box>
                    <Box px={1}>
                      <Text fontSize={2}>
                        <Trans>Rule</Trans>
                        {` ${Number(number) + 1}`}
                        {values.items &&
                          values.items[number] &&
                          values.items[number].description &&
                          `: ${values.items[number].description}`}
                      </Text>
                    </Box>
                  </Flex>
                  <Box px={1} pb={1} mr={3}>
                    <Button
                      onClick={() =>
                        navigate!(number, {
                          state: values
                        })
                      }
                      type="reset"
                      variant="green"
                      width="200px"
                    >
                      <Trans>Details</Trans>
                    </Button>
                  </Box>
                </Flex>
              )}
            </Form.Spy>
          );
        }}
        removeButton={RemoveRuleItemButton}
        wrapperProps={{ alignItems: "center" }}
      />
      <Box>
        <Form.Error
          entry={{
            "atleast.item.added": {
              notAddedAtleastOneRuleItem: (
                <Trans>At least one rule item should be added</Trans>
              )
            },
            "rule.item.valid": {
              notValidRuleItem: <Trans>All rule item must be valid</Trans>
            }
          }}
          default={i18n._(t`Something went wrong. Please try again later`)}
        />
      </Box>
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
    </Form>
  );
};

type RemoveRuleItemButtonProps = {
  onClick: () => void;
};

const RemoveRuleItemButton = ({ onClick }: RemoveRuleItemButtonProps) => {
  const [isConfirmPopupVisible, setConfirmPopupVisibility] = useState(false);
  const confirmToggle = () => setConfirmPopupVisibility(!isConfirmPopupVisible);

  return (
    <Box pb={1} mb={4}>
      <Button variant="red" type="reset" onClick={confirmToggle}>
        <RemoveItemIcon />
      </Button>
      <Popup
        visible={isConfirmPopupVisible}
        onCancel={confirmToggle}
        onOk={() => {
          confirmToggle();
          onClick();
        }}
        okText={<Trans>Yes</Trans>}
        title={<Trans>Rule item will be deleted. Are you sure?</Trans>}
      />
    </Box>
  );
};

type RuleModalFormProps = RouteComponentProps<{
  config: Config;
  ruleNumber: string;
}>;

const RuleModalForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  ruleNumber,
  config
}: RuleModalFormProps) => {
  const [chosenValue, setChooseValue] = useState(
    state &&
      state.items &&
      state.items[ruleNumber!] &&
      state.items[ruleNumber!].value &&
      config!.fields
      ? getTreeFromJson(state.items[ruleNumber!].value.json, config!)
      : getInitTree()
  );
  const [isConfirmPopupVisible, setConfirmPopupVisibility] = useState(false);
  const confirmToggle = () => setConfirmPopupVisibility(!isConfirmPopupVisible);

  const onClose = useCallback(
    () => navigate!("../", { state }),
    [navigate, state]
  );

  const getUpdatedRules = (data: { items: RuleItem[] }) => {
    const updatedItems = state.items || [];
    const value = getJsonStringFromTree(chosenValue!, config!);
    updatedItems[ruleNumber!] = {
      value,
      description: data.items[Number(ruleNumber)].description
    };

    return updatedItems;
  };

  return (
    <Popup
      visible={true}
      onCancel={() => {
        const ruleString = getJsonStringFromTree(chosenValue!, config!).string;
        if (
          (!state.items[ruleNumber] && ruleString) ||
          (state.items[ruleNumber] &&
            state.items[ruleNumber].value &&
            state.items[ruleNumber].value.string !== ruleString)
        ) {
          confirmToggle();
        } else {
          onClose();
        }
      }}
      title={
        state.items && state.items[ruleNumber] ? (
          <Trans>Update rule</Trans>
        ) : (
          <Trans>Create rule</Trans>
        )
      }
      width={"calc(100% - 80px)"}
      textAlign="left"
      overflow="auto"
      formId="createRuleEngineRuleItem"
      okButtonProps={{
        type: "submit",
        variant: "orange"
      }}
    >
      <Form
        initialValues={state}
        onSubmit={(data: { items: RuleItem[]; itemsComparator: string }) => {
          return navigate!("../", {
            state: {
              ...data,
              items: getUpdatedRules(data)
            }
          });
        }}
        id="createRuleEngineRuleItem"
      >
        <Box mb={2}>
          <Trans
            id="Enter description"
            render={({ translation }) => (
              <Field.Text
                label={<Trans id="Description" />}
                name={`items[${ruleNumber}].description`}
                placeholder={translation}
              />
            )}
          />
          <Trans
            id="Required field"
            render={({ translation }) => (
              <Validation.Required
                field={`items[${ruleNumber}].description`}
                message={translation}
              />
            )}
          />
        </Box>
      </Form>
      <Box mb={6}>
        <EhealthQueryBuilder
          handleTreeChange={setChooseValue}
          tree={chosenValue}
          config={config!}
        />
      </Box>
      <Popup
        visible={isConfirmPopupVisible}
        onCancel={confirmToggle}
        onOk={() => {
          confirmToggle();
          onClose();
        }}
        okText={<Trans>Yes</Trans>}
        title={<Trans>All changes will be discarded. Are you sure?</Trans>}
      />
    </Popup>
  );
};

type ImportFileProps = {
  setChooseValue: (items: any) => void;
};

const ImportFile = ({ setChooseValue }: ImportFileProps) => {
  const [isFilePopupVisible, setFilePopupVisibility] = useState(false);
  const [isErrorPopupVisible, setErrorPopupVisibility] = useState(false);
  const [fileContent, setFileContent] = useState("");

  const triggerInput = () => {
    const element = document.getElementById("json-file");
    if (element) {
      element.nodeValue = "";
      element.click();
    }
  };

  const toggleFile = () => setFilePopupVisibility(!isFilePopupVisible);
  const toggleError = () => setErrorPopupVisibility(!isErrorPopupVisible);

  const handleOnChoose = () => {
    setChooseValue(JSON.parse(fileContent));
    toggleFile();
  };

  const handleImportFile = useCallback(() => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const file = document.getElementById("json-file").files[0];
    const reader = new FileReader();
    reader.onload = () => {
      try {
        // @ts-expect-error TS(2345): Argument of type 'string | ArrayBuffer | null' is ... Remove this comment to see the full error message
        JSON.parse(reader.result) && setFileContent(reader.result);
        toggleFile();
      } catch (e) {
        toggleError();
      }
    };
    reader.readAsText(file);
  }, []);

  return (
    <>
      <Box>
        <input
          type="file"
          id="json-file"
          onChange={handleImportFile}
          hidden={true}
          multiple={false}
          accept=".json"
        />
        <Button variant="blue" onClick={triggerInput}>
          <Trans>Import from file</Trans>
        </Button>
      </Box>
      <Popup
        visible={isFilePopupVisible}
        onCancel={toggleFile}
        title={
          <Trans>
            Filling from file will clear currently configured rules, are you
            sure want to proceed?
          </Trans>
        }
        okText={<Trans>Next</Trans>}
        cancelText={<Trans>Return</Trans>}
        onOk={handleOnChoose}
      />
      <Popup
        visible={isErrorPopupVisible}
        onCancel={toggleError}
        title={<Trans>File format is not supported</Trans>}
        cancelText={<Trans>Return</Trans>}
        hideOkButton={true}
      />
    </>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const [error, setError] = useState(null);
  const { createRuleEngineRule, items = [], itemsComparator } = state;
  const { name, code, description } = createRuleEngineRule;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Rule engine rule title</Trans>,
          system: <Trans>Coding system</Trans>,
          code: <Trans>Code</Trans>,
          description: <Trans>Description</Trans>,
          itemsComparator: <Trans id="Items comparator" />,
          value: <Trans>Value</Trans>
        }}
        data={{
          name,
          system: (
            <DictionaryValue
              name="eHealth/rule_engine_dictionaries"
              item={code.system}
            />
          ),
          code: <DictionaryValue name={code.system} item={code.code} />,
          description: description,
          itemsComparator: itemsComparators[itemsComparator],
          value: items.map((item: RuleItem, index: number) =>
            item ? (
              <Box mb={3}>
                <Box mb={1}>
                  <Text fontWeight="bold">
                    <Trans>Rule</Trans>
                    {` ${index + 1}`}
                    {item.description && `: ${item.description}`}
                  </Text>
                </Box>
                <Box>{item.value.string}</Box>
              </Box>
            ) : (
              "-"
            )
          )
        }}
        labelWidth="200px"
      />
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../rules", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation mutation={CreateRuleEngineRuleMutation}>
              {(createRuleEngineRule: MutationFunction) => {
                return (
                  <Box>
                    <Button
                      variant="green"
                      width={250}
                      onClick={async () => {
                        const { signedContent } = await signData({
                          name,
                          code,
                          items_comparator: itemsComparator,
                          items: items.map((item: RuleItem, index: number) => {
                            return { ...item, number: index + 1 };
                          }),
                          description
                        });

                        try {
                          await createRuleEngineRule({
                            variables: {
                              input: {
                                signedContent: {
                                  content: signedContent,
                                  encoding: "BASE64"
                                }
                              }
                            }
                          });
                          await navigate!("../../");
                        } catch (error) {
                          if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                            setError(getErrorMessage(error));
                          }
                        }
                      }}
                    >
                      <Trans>Approve by EDS</Trans>
                    </Button>
                  </Box>
                );
              }}
            </Mutation>
          )}
        </Signer.Parent>
        {error && (
          <UnpocessableEntityModalError errorMessage={error} isModalOpen />
        )}
      </Flex>
    </Box>
  );
};

export default Create;

const CreateRuleEngineRuleMutation = gql`
  mutation CreateRuleEngineRuleMutation($input: CreateRuleEngineRuleInput!) {
    createRuleEngineRule(input: $input) {
      ruleEngineRule {
        id
        databaseId
        name
        insertedAt
        isActive
      }
    }
  }
`;
