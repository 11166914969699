import * as React from "react";
import { gql } from "graphql-tag";
import { Plural, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import differenceInSeconds from "date-fns/difference_in_seconds";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  LegalEntityReorganizationJob,
  LegalEntityReorganizationJobConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type LegalEntityReorganizationJobTableProps = {
  legalEntityReorganizationJobs: LegalEntityReorganizationJobConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const LegalEntityReorganizationJobTable = ({
  legalEntityReorganizationJobs,
  locationParams,
  setLocationParams
}: LegalEntityReorganizationJobTableProps) => {
  const { i18n } = useLingui();

  return (
    <Table
      data={legalEntityReorganizationJobs}
      header={{
        databaseId: <Trans>ID</Trans>,
        reorganizedLegalEntityName: <Trans>Legal entity name</Trans>,
        reorganizedLegalEntityEdrpou: <Trans>EDRPOU</Trans>,
        reorganizationType: <Trans>Reorganization type</Trans>,
        startedAt: <Trans>Job Start Time</Trans>,
        executionTime: <Trans>Execution time</Trans>,
        status: <Trans>Status</Trans>,
        details: <Trans>Action</Trans>
      }}
      renderRow={({
        id,
        databaseId,
        reorganizedLegalEntity: {
          name: reorganizedLegalEntityName,
          edrpou: reorganizedLegalEntityEdrpou
        },
        reorganizationType,
        startedAt,
        endedAt,
        status
      }: LegalEntityReorganizationJob) => ({
        databaseId,
        reorganizedLegalEntityName,
        reorganizedLegalEntityEdrpou,
        reorganizationType: (
          <DictionaryValue
            name="LEGAL_ENTITY_REORGANIZATION_TYPES"
            item={reorganizationType}
          />
        ),
        startedAt:
          startedAt &&
          typeof startedAt === "string" &&
          dateFormatter(i18n.locale, DATE_TIME_FORMAT, startedAt),
        executionTime:
          !endedAt || !startedAt ? (
            "-"
          ) : (
            <Plural
              id="{0, plural, zero {# секунд} one {# секунда} few {# секунди} many {# секунд} other {# секунд}}"
              value={differenceInSeconds(endedAt, startedAt)}
              zero="# seconds"
              one="# second"
              few="# seconds"
              many="# seconds"
              other="# seconds"
            />
          ),
        status: <Badge type="JOBS" name={status} display="block" />,
        details: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Show details</Trans>
          </Link>
        )
      })}
      sortableFields={["startedAt"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams: SortingParams) =>
        setLocationParams({
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      tableName="legalEntityReorganizationJobs/search"
    />
  );
};

LegalEntityReorganizationJobTable.fragments = {
  entry: gql`
    fragment LegalEntityReorganizationJob on LegalEntityReorganizationJob {
      id
      databaseId
      startedAt
      endedAt
      status
      name
      reorganizationType
      reorganizedLegalEntity {
        edrpou
        name
      }
    }
  `
};

export default LegalEntityReorganizationJobTable;
