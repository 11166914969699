import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { Scalars, ProgramService } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";

import UpdateProgramServiceRequestAllowance from "./Mutations/UpdateRequestAllowance";
import DeactivateProgramServicePopup from "./Mutations/Deactivate";

type HeaderProgramServiceProps = {
  id: Scalars["ID"]["output"];
  databaseId: Scalars["UUID"];
  isActive: ProgramService["isActive"];
  requestAllowed: ProgramService["requestAllowed"];
};

const HeaderProgramService = ({
  id,
  databaseId,
  isActive,
  requestAllowed
}: HeaderProgramServiceProps) => (
  <Flex justifyContent="space-between" alignItems="flex-end">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>ID</Trans>,
          isActive: <Trans>Status</Trans>,
          requestAllowed: <Trans>Is request allowed</Trans>
        }}
        data={{
          databaseId,
          isActive: (
            <Badge
              type="ACTIVE_STATUS_M"
              name={isActive}
              variant={!isActive}
              minWidth={100}
            />
          ),
          requestAllowed: requestAllowed ? <PositiveIcon /> : <NegativeIcon />
        }}
        color="#7F8FA4"
        labelWidth="120px"
        marginBetween="auto"
      />
    </Box>
    {isActive && (
      <Ability action="write" resource="program_service">
        <Flex justifyContent="flex-end" flexWrap="wrap">
          <Box mt={2}>
            <UpdateProgramServiceRequestAllowance
              id={id}
              requestAllowed={requestAllowed}
            />
          </Box>
          <Box mt={2} ml={2}>
            <DeactivateProgramServicePopup id={id} />
          </Box>
        </Flex>
      </Ability>
    )}
  </Flex>
);

HeaderProgramService.fragments = {
  entry: gql`
    fragment HeaderProgramService on ProgramService {
      id
      databaseId
      isActive
      requestAllowed
    }
  `
};

export default HeaderProgramService;
