import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { ProgramDevice } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import Header from "./Header";
import GeneralInfo from "./GeneralInfo";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <Ability action="read" resource="program_device">
    <Query query={ProgramDeviceQuery} variables={{ id }}>
      {({ loading, data }: QueryResult<{ programDevice: ProgramDevice }>) => {
        if (isEmpty(data) || isEmpty(data.programDevice)) return null;
        const {
          databaseId,
          deviceRequestAllowed,
          carePlanActivityAllowed,
          isActive,
          insertedAt,
          ...details
        } = data.programDevice;

        return (
          <LoadingOverlay loading={loading}>
            <Box p={6}>
              <Box py={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/program-devices">
                    <Trans>Search program devices</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Program device details</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Header
                id={id!}
                databaseId={databaseId}
                isActive={isActive}
                deviceRequestAllowed={deviceRequestAllowed}
                carePlanActivityAllowed={carePlanActivityAllowed}
                insertedAt={insertedAt}
              />
            </Box>

            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General info</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>
            <Tabs.Content>
              <Router>
                <GeneralInfo path="/" isActive={isActive} details={details} />
              </Router>
            </Tabs.Content>
          </LoadingOverlay>
        );
      }}
    </Query>
  </Ability>
);

const ProgramDeviceQuery = gql`
  query ProgramDeviceQuery($id: ID!) {
    programDevice(id: $id) {
      ...ProgramDeviceHeader
      ...ProgramDeviceGeneralInfo
    }
  }
  ${Header.fragments.entry}
  ${GeneralInfo.fragments.entry}
`;

export default Details;
