import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  // @ts-expect-error types mismatch
  MedicalcompositionConnection,
  // @ts-expect-error types mismatch
  MedicalCompositionIntegrationConnection
} from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";
import Link from "../../../components/Link";
import DictionaryValue from "../../../components/DictionaryValue";

import GeneralInfo from "./GeneralInfo";
import ChangeStatusPopup from "./ChangeStatusPopup";
import SendToErlnPopup from "./SendToErlnPopup";
import CompositionChain from "./CompositionChain";
import IntegrationStatusTable from "./IntegrationStatusTable";

import env from "../../../env";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => {
  const requestParams = {
    id
  };

  return (
    <Query query={MedicalCompositionQuery} variables={requestParams}>
      {({
        data
      }: QueryResult<{ medicalComposition: MedicalcompositionConnection }>) => {
        const { medicalComposition = [] } = data || {};
        if (isEmpty(medicalComposition)) return null;
        const composition = medicalComposition.items[0];

        if (!composition || !composition.identifier) {
          return null;
        }

        return (
          <Query
            query={MedicalCompositionIntegrationQuery}
            variables={{ id: composition.identifier.value }}
          >
            {({
              loading,
              data
            }: QueryResult<{
              medicalCompositionIntegration: MedicalCompositionIntegrationConnection;
            }>) => {
              const { medicalCompositionIntegration = [] } = data || {};
              const integrationObject =
                loading || !medicalCompositionIntegration.length
                  ? {}
                  : medicalCompositionIntegration[0];
              const integrationStatus = integrationObject.integrationStatus;

              return (
                <LoadingOverlay loading={loading}>
                  <Box p={6}>
                    <Box py={10}>
                      <Breadcrumbs.List>
                        <Breadcrumbs.Item to="/medical-composition">
                          <Trans>Medical composition</Trans>
                        </Breadcrumbs.Item>
                        <Breadcrumbs.Item>
                          <Trans>Medical composition details</Trans>
                        </Breadcrumbs.Item>
                      </Breadcrumbs.List>
                    </Box>

                    <Flex>
                      <Box width={2 / 4}>
                        <DefinitionListView
                          labels={{
                            id: <Trans>ID</Trans>,
                            title: <Trans>Medical composition number</Trans>,
                            status: <Trans>Status</Trans>,
                            integration: <Trans>Integration status</Trans>
                          }}
                          data={{
                            id: composition.identifier.value,
                            title: composition.title,
                            status: (
                              <DictionaryValue
                                name="COMPOSITION_STATUS"
                                item={composition.status}
                              />
                            ),
                            integration: (
                              <DictionaryValue
                                name="COMPOSITION_PROCESSING_STATUS"
                                item={integrationStatus}
                              />
                            )
                          }}
                          color="#7F8FA4"
                        />
                      </Box>
                      <Box width={1 / 4} />
                      <Box width={0.75 / 4}>
                        <Flex
                          justifyContent="flex-end"
                          flexDirection="column"
                          style={{ gap: "10px" }}
                        >
                          <Link
                            is="a"
                            style={{ flex: 1 }}
                            href={`${env.REACT_APP_REST_API_URL}/admin/composition/${composition.identifier.value}/print`}
                            target={"_blank"}
                          >
                            <Button width={"100%"} variant="blue">
                              <Trans>Create extract</Trans>
                            </Button>
                          </Link>
                          <Ability action="write" resource="composition_admin">
                            <ChangeStatusPopup
                              data={composition}
                              locationParams={requestParams}
                              medicalCompositionQuery={MedicalCompositionQuery}
                            />
                            {composition.category.coding[0].code !==
                              "LIVE_BIRTH" && (
                              <SendToErlnPopup
                                composition={composition}
                                integrationData={integrationObject}
                                locationParams={requestParams}
                                medicalCompositionQuery={
                                  MedicalCompositionQuery
                                }
                              />
                            )}
                          </Ability>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>

                  <Tabs.Nav>
                    <Tabs.NavItem to="./">
                      <Trans>General info</Trans>
                    </Tabs.NavItem>
                    {composition.relatesTo[0] ? (
                      <Tabs.NavItem to="./related">
                        <Trans>Related Medical compositions</Trans>
                      </Tabs.NavItem>
                    ) : undefined}
                    {medicalCompositionIntegration.length ? (
                      <Tabs.NavItem to="./integration">
                        <Trans>Integration information</Trans>
                      </Tabs.NavItem>
                    ) : undefined}
                  </Tabs.Nav>
                  <Tabs.Content>
                    <Router>
                      <GeneralInfo path="/" data={composition} />
                      {composition.relatesTo[0] ? (
                        <CompositionChain path="/related" item={composition} />
                      ) : undefined}
                      {medicalCompositionIntegration.length ? (
                        <IntegrationStatusTable
                          path="/integration"
                          items={medicalCompositionIntegration}
                        />
                      ) : undefined}
                    </Router>
                  </Tabs.Content>
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const MedicalCompositionIntegrationQuery = gql`
  query MedicalCompositionIntegrationQuery($id: String!) {
    medicalCompositionIntegration(id: $id)
      @rest(
        path: "/admin/composition/:id/integrationData"
        type: "IntegrationStatus"
      ) {
      component
      type
      integrationStatus
      statusCode
      statusMessage
      taskStatus
      updatedAt
      details
    }
  }
`;

const MedicalCompositionQuery = gql`
  query MedicalCompositionQuery($id: String!) {
    medicalComposition(id: $id)
      @rest(
        type: "SearchPayload"
        path: "/admin/composition/search?offset=0&limit=1&id=:id"
      ) {
      items
      hasNext
    }
  }
`;

export default Details;
