import React from "react";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <Composer
        components={[
          <DictionaryValue name="device_definition_classification_type" />,
          ({
            render
          }: {
            render: (props: TransRenderProps) => React.ReactElement;
          }) => (
            <Trans
              id="All device definition classification types"
              render={render}
            />
          )
        ]}
      >
        {([dict, { translation }]: [
          DictionaryAllValuesJson,
          { translation: React.ReactNode }
        ]) => {
          return (
            <Field.Select
              name="filter.classificationType"
              label={<Trans id="Device definition classification type" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          );
        }}
      </Composer>
    </Box>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter device definition name"
        render={({ translation }) => (
          <Field.Text
            name="filter.deviceNames.name"
            label={<Trans id="Device definition name" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_M" />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter device definition model number"
          render={({ translation }) => (
            <Field.Text
              name="filter.modelNumber"
              label={<Trans id="Device definition model number" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter device definition manufacturer name"
          render={({ translation }) => (
            <Field.Text
              name="filter.manufacturerName"
              label={<Trans id="Device definition manufacturer name" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
