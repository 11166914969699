import React, { useReducer } from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { DropDownButton as PlusIcon } from "@edenlabllc/ehealth-icons";
import {
  Dictionary,
  DictionaryValueConnection,
  Scalars
} from "@edenlabllc/graphql-schema";

import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  SetSearchParams
} from "../../../components/SearchForm";
import { SearchFields } from "./SearchFields";
import { DictionaryValuesTable } from "./Table";
import Ability from "../../../components/Ability";
import { useAbility } from "../../../helpers/useAbility";
import CreateDictionaryValues from "./Mutations/CreateDictionaryValues";

type DictionaryValuesProps = {
  id: Scalars["ID"]["input"];
  databaseId: Scalars["ID"]["input"];
  values: DictionaryValueConnection["nodes"];
  allValues: Dictionary["allValues"];
  pageInfo?: DictionaryValueConnection["pageInfo"];
  isReadOnly: boolean;
  isActive: Dictionary["isActive"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  refetch: any;
};

export const DictionaryValues = ({
  refetch,
  locationParams,
  setLocationParams,
  id,
  databaseId,
  values,
  allValues,
  pageInfo,
  isReadOnly,
  isActive
}: RouteComponentProps & DictionaryValuesProps) => {
  const [isVisibleAddValue, toggle] = useReducer((v) => !v, false);
  const [dictionaryAbility] = useAbility("write", "dictionary");

  const action =
    !isReadOnly && isActive ? { action: <Trans>Action</Trans> } : {};

  return (
    <Box p={6}>
      {!isVisibleAddValue ? (
        <>
          <Flex justifyContent="space-between" color="darkAndStormy">
            <Trans>Search for a value</Trans>
            {!isReadOnly && isActive && (
              <Ability action="write" resource="dictionary">
                <Button variant="link" px={0} icon={PlusIcon} onClick={toggle}>
                  <Trans>Add value</Trans>
                </Button>
              </Ability>
            )}
          </Flex>

          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={() => <SearchFields allValues={allValues} />}
          />
          {values && values.length ? (
            <>
              <DictionaryValuesTable
                id={id}
                locationParams={locationParams}
                setLocationParams={setLocationParams}
                values={values}
                dictionaryAbility={dictionaryAbility}
                action={action}
              />
              <Pagination pageInfo={pageInfo} />
            </>
          ) : (
            <EmptyData />
          )}
        </>
      ) : (
        <CreateDictionaryValues
          allValues={allValues}
          dictionaryId={databaseId}
          toggle={toggle}
          refetch={refetch}
        />
      )}
    </Box>
  );
};
