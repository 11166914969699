import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import { Person } from "@edenlabllc/graphql-schema";

import { Heading, Table } from "./index";

type IdentityInfoProps = {
  data: Person[];
};

const IdentityInfo = ({ data }: IdentityInfoProps) => (
  <>
    <Heading>
      <Trans>Identity Information</Trans>
    </Heading>
    <Table
      header={{
        unzr: <Trans>Record ID in EDDR</Trans>,
        taxId: <Trans>INN</Trans>,
        noTaxId: <Trans>No tax ID</Trans>
      }}
      data={data}
      renderRow={({ noTaxId, ...content }: Person) => ({
        ...content,
        noTaxId: noTaxId ? <Trans>Yes</Trans> : <Trans>No</Trans>
      })}
    />
  </>
);

IdentityInfo.fragments = {
  entry: gql`
    fragment IdentityInfo on Person {
      unzr
      taxId
      noTaxId
    }
  `
};

export default IdentityInfo;
