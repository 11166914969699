import React from "react";
import isEmpty from "lodash/isEmpty";
import { Box } from "@rebass/emotion";
import { Trans, t, defineMessage } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { Reference, Activity } from "@edenlabllc/graphql-schema";

import Table from "../../../components/Table";

import paramToBase64 from "../../../helpers/paramToBase64";

import ReferenceSwitcher from "./ReferenceSwitcher";

type OutcomeReferencesProps = {
  outcomeReferences: Activity["outcomeReference"];
  patientId?: string;
  isClinicalMonitoring?: boolean;
  handleSetReferenceLink?: (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: Reference
  ) => string;
};

const OutcomeReferences = ({
  outcomeReferences,
  isClinicalMonitoring,
  patientId,

  handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: Reference
  ) =>
    isClinicalMonitoring
      ? `../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`
      : `../../${memoizedReferenceSlug}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`
}: OutcomeReferencesProps) => {
  if (isEmpty(outcomeReferences)) return null;

  const REFERENCE_TYPES = {
    values: {
      DiagnosticReport: i18n._(
        defineMessage({
          id: "OutcomeReferences Diagnostic report",
          message: t`Diagnostic report`
        })
      ),
      Encounter: t`Encounter`,
      Procedure: t`Procedure`,
      MedicationDispense: t`Medication dispense`
    }
  };

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={outcomeReferences}
        header={{
          reference: <Trans>Reference</Trans>
        }}
        renderRow={({ ...reference }: Reference) => ({
          reference: (
            <ReferenceSwitcher
              reference={reference}
              referenceTypes={REFERENCE_TYPES}
              handleSetReferenceLink={handleSetReferenceLink}
            />
          )
        })}
      />
    </Box>
  );
};

export default OutcomeReferences;
