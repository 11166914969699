import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Scalars, Division } from "@edenlabllc/graphql-schema";

import Badge from "../../../../components/Badge";
import DefinitionListView from "../../../../components/DefinitionListView";

type HeaderProps = {
  id: Scalars["ID"]["output"];
  databaseId: Scalars["UUID"];
  status: Division["status"];
  name: Division["name"];
};

const Header = ({ databaseId, status, name }: HeaderProps) => (
  <Flex justifyContent="space-between" alignItems="flex-end">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>Legal entity ID</Trans>,
          name: <Trans>Name</Trans>,
          status: <Trans>Status</Trans>
        }}
        data={{
          databaseId,
          name,
          status: <Badge name={status} type="DIVISIONS" minWidth={100} />
        }}
        color="#7F8FA4"
        labelWidth="120px"
      />
    </Box>
  </Flex>
);

Header.fragments = {
  entry: gql`
    fragment DivisionHeader on Division {
      id
      databaseId
      status
      name
    }
  `
};

export default Header;
