import React, { useCallback, useMemo } from "react";
import { Router, NavigateFn, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Flex, Heading } from "@rebass/emotion";

import {
  Form,
  Validation,
  Validations,
  Field as FieldListener,
  Modal,
  Switch
} from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { MedicalProgram } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";

import { MedicalProgramQuery } from "../index";

type UpdateMedicalProgramSettingsProps = {
  medicalProgramSettings: MedicalProgram["medicalProgramSettings"];
  id: string;
  type: string;
  navigate?: NavigateFn;
};

const UpdateMedicalProgramSettings = ({
  medicalProgramSettings,
  navigate,
  id,
  type
}: UpdateMedicalProgramSettingsProps) => {
  const onUpdate = useCallback(
    () =>
      // @ts-expect-error navigate
      navigate("./update", {
        medicalProgramSettings,
        id
      }),
    [navigate]
  );

  return (
    <>
      <Router>
        <UpdateForm
          path="/update"
          medicalProgramSettings={medicalProgramSettings}
          type={type}
        />
      </Router>
      <Ability action="write" resource="medical_program">
        <Mutation mutation={UpdateMedicalProgramSettingsMutation}>
          {() => (
            <Box>
              <Button variant="blue" onClick={onUpdate}>
                <Trans>Update medical program settings</Trans>
              </Button>
            </Box>
          )}
        </Mutation>
      </Ability>
    </>
  );
};

export default UpdateMedicalProgramSettings;

const UpdateMedicalProgramSettingsMutation = gql`
  mutation UpdateMedicalProgramMutation($input: UpdateMedicalProgramInput!) {
    updateMedicalProgram(input: $input) {
      medicalProgram {
        id
        medicalProgramSettings {
          carePlanRequired
          skipEmployeeValidation
          specialityTypesAllowed
          conditionsIcd10AmAllowed
          conditionsIcpc2Allowed
          providingConditionsAllowed
          multiMedicationDispenseAllowed
          skipMedicationDispenseSign
          requestNotificationDisabled
          skipContractProvisionVerify
          medicalProgramChangeOnDispenseAllowed
          licenseTypesAllowed
          patientCategoriesAllowed
          skipTreatmentPeriod
          employeeTypesToCreateRequest
          requestMaxPeriodDay
          skipRequestEmployeeDeclarationVerify
          skipRequestLegalEntityDeclarationVerify
          dispensePeriodDay
          requestAllowed
          dispenseAllowed
          deviceRequestAllowedCodeTypes
          skipDispenseDivisionDlsVerify
        }
      }
    }
  }
`;

type RemoveButtonProps = {
  onClick: () => void;
};

const RemoveButton = ({ onClick }: RemoveButtonProps) => (
  <Box width={1 / 9} alignSelf="start">
    <Box
      p={2}
      onClick={onClick}
      style={{ display: "inline-block", cursor: "pointer" }}
    >
      <RemoveItemIcon />
    </Box>
  </Box>
);

type CreateMedicalProgramSettingsFormProps = {
  name: string;
  dictionaryName: string;
};

const CreateMedicalProgramSettingsForm = ({
  name,
  dictionaryName
}: CreateMedicalProgramSettingsFormProps) => {
  const { i18n } = useLingui();

  return (
    <Flex width="100%">
      <Box mr={5} width="50%">
        <DictionaryValue
          name={dictionaryName}
          render={(dict: DictionaryAllValuesJson) => (
            <Field.Select
              name={name}
              placeholder={i18n._(t`Code`)}
              itemToString={(item: string) => item}
              items={Object.keys(dict)}
              filterOptions={{ keys: [(item: string) => item] }}
              isVirtualizedList
            />
          )}
        />
      </Box>
      <Box mr={5} width="50%">
        <DictionaryValue
          name={dictionaryName}
          render={(dict: DictionaryAllValuesJson) => (
            <Field.Select
              name={name}
              placeholder={i18n._(t`Name`)}
              itemToString={(item: string) => dict[item]}
              items={Object.keys(dict)}
              filterOptions={{ keys: [(item: string) => dict[item]] }}
              isVirtualizedList
            />
          )}
        />
        <Validations field={name}>
          <Validation.Required message={i18n._(t`Required field`)} />
          <Validation.Custom
            message={i18n._(t`This value is used more than once`)}
            options={({ value, allValues }: $TSFixMe) => {
              const duplicates = allValues[name.split("[")[0]].filter(
                (name: string) => name === value
              );

              return duplicates.length === 1;
            }}
          />
        </Validations>
      </Box>
    </Flex>
  );
};

type UpdateFormProps = RouteComponentProps<{
  medicalProgramSettings: MedicalProgram["medicalProgramSettings"];
  id: string;
  type: string;
}>;

export const UpdateForm = ({
  navigate,
  medicalProgramSettings,
  id,
  type
}: UpdateFormProps) => {
  const { i18n } = useLingui();
  const {
    carePlanRequired = false,
    skipEmployeeValidation = false,
    specialityTypesAllowed,
    conditionsIcd10AmAllowed,
    conditionsIcpc2Allowed,
    providingConditionsAllowed,
    multiMedicationDispenseAllowed = false,
    skipMedicationDispenseSign = false,
    requestNotificationDisabled = false,
    skipContractProvisionVerify = false,
    medicalProgramChangeOnDispenseAllowed,
    licenseTypesAllowed,
    patientCategoriesAllowed,
    skipTreatmentPeriod,
    employeeTypesToCreateRequest,
    requestMaxPeriodDay,
    skipRequestEmployeeDeclarationVerify,
    skipRequestLegalEntityDeclarationVerify,
    dispensePeriodDay,
    requestAllowed,
    dispenseAllowed,
    deviceRequestAllowedCodeTypes,
    skipDispenseDivisionDlsVerify
  } = medicalProgramSettings || {};

  const isMedication = type === "MEDICATION";
  const isDevice = type === "DEVICE";

  const virtualizedListProps = useMemo(
    () => ({
      isVirtualizedList: true,
      rowLoaderText: i18n._(t`Loading...`),
      searchPlaceholderText: i18n._(t`Search by code`),
      foundLabel: i18n._(t`Found:`),
      selectFieldPlaceholderText: i18n._(t`Choose Code`)
    }),
    []
  );

  return (
    <Mutation
      mutation={UpdateMedicalProgramSettingsMutation}
      refetchQueries={() => [
        {
          query: MedicalProgramQuery,
          variables: { id, isProgramSettingsEnabled: true }
        }
      ]}
    >
      {(updateMedicalProgramSettings: MutationFunction) => (
        <Modal
          backdrop
          width="70%"
          textAlign="left"
          id="updateMedicalProgramSettingsModal"
        >
          <Close onClick={() => navigate!("../")} />
          <Flex justifyContent="center">
            <Box mb={5}>
              <Heading as="h5" fontWeight="normal">
                <Trans>Update medical program settings</Trans>
              </Heading>
            </Box>
          </Flex>
          <Form
            id="updateMedicalProgramSettings"
            initialValues={{
              carePlanRequired,
              skipEmployeeValidation,
              specialityTypesAllowed,
              conditionsIcd10AmAllowed,
              conditionsIcpc2Allowed,
              providingConditionsAllowed,
              multiMedicationDispenseAllowed,
              skipMedicationDispenseSign,
              requestNotificationDisabled,
              skipContractProvisionVerify,
              medicalProgramChangeOnDispenseAllowed,
              licenseTypesAllowed,
              patientCategoriesAllowed,
              skipTreatmentPeriod,
              employeeTypesToCreateRequest,
              requestMaxPeriodDay,
              skipRequestEmployeeDeclarationVerify,
              skipRequestLegalEntityDeclarationVerify,
              dispensePeriodDay,
              requestAllowed,
              dispenseAllowed,
              deviceRequestAllowedCodeTypes,
              skipDispenseDivisionDlsVerify
            }}
            onSubmit={async (
              values: MedicalProgram["medicalProgramSettings"]
            ) => {
              const transformedValues = handleTransformValues(values);

              const {
                medicalProgramSettingsText,
                carePlanRequired,
                skipEmployeeValidation,
                specialityTypesAllowed,
                conditionsIcd10AmAllowed,
                conditionsIcpc2Allowed,
                providingConditionsAllowed,
                multiMedicationDispenseAllowed,
                skipMedicationDispenseSign,
                requestNotificationDisabled,
                skipContractProvisionVerify,
                medicalProgramChangeOnDispenseAllowed,
                licenseTypesAllowed,
                patientCategoriesAllowed,
                skipTreatmentPeriod,
                employeeTypesToCreateRequest,
                requestMaxPeriodDay,
                skipRequestEmployeeDeclarationVerify,
                skipRequestLegalEntityDeclarationVerify,
                dispensePeriodDay,
                requestAllowed,
                dispenseAllowed,
                deviceRequestAllowedCodeTypes,
                skipDispenseDivisionDlsVerify
              } = transformedValues;

              const input = {
                id,
                medicalProgramSettings: {
                  carePlanRequired,
                  skipEmployeeValidation,
                  ...(specialityTypesAllowed && { specialityTypesAllowed }),
                  ...(conditionsIcd10AmAllowed && { conditionsIcd10AmAllowed }),
                  ...(conditionsIcpc2Allowed && { conditionsIcpc2Allowed }),
                  ...((isMedication || isDevice) &&
                    providingConditionsAllowed && {
                      providingConditionsAllowed
                    }),
                  ...(isMedication && { multiMedicationDispenseAllowed }),
                  ...(isMedication && { skipMedicationDispenseSign }),
                  ...((isMedication || isDevice) && {
                    requestNotificationDisabled
                  }),
                  ...((isMedication || isDevice) && {
                    skipContractProvisionVerify
                  }),
                  ...(isMedication && {
                    medicalProgramChangeOnDispenseAllowed
                  }),
                  ...(isMedication && { licenseTypesAllowed }),
                  ...((isMedication || isDevice) && {
                    patientCategoriesAllowed
                  }),
                  ...((isDevice || isMedication) && { skipTreatmentPeriod }),
                  ...((isDevice || isMedication) &&
                    employeeTypesToCreateRequest && {
                      employeeTypesToCreateRequest
                    }),
                  ...((isDevice || isMedication) &&
                    requestMaxPeriodDay && { requestMaxPeriodDay }),
                  ...((isDevice || isMedication) && {
                    skipRequestEmployeeDeclarationVerify
                  }),
                  ...((isDevice || isMedication) && {
                    skipRequestLegalEntityDeclarationVerify
                  }),
                  ...((isDevice || isMedication) &&
                    dispensePeriodDay && { dispensePeriodDay }),
                  requestAllowed,
                  dispenseAllowed,
                  ...(isDevice && { deviceRequestAllowedCodeTypes }),
                  ...((isDevice || isMedication) && {
                    skipDispenseDivisionDlsVerify
                  })
                },
                medicalProgramSettingsText
              };

              await updateMedicalProgramSettings({
                variables: {
                  input
                }
              });

              navigate!("../");
            }}
          >
            <Flex flexDirection="column">
              <Box mt={3} ml={-2}>
                <Field.Checkbox
                  name="carePlanRequired"
                  label={<Trans>Care plan required</Trans>}
                />
              </Box>
              {(isMedication || isDevice) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipEmployeeValidation"
                    label={<Trans>Skip employee validations</Trans>}
                  />
                </Box>
              )}
              {(isDevice || isMedication) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipTreatmentPeriod"
                    label={
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            Create a medication request without period
                            restrictions
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            Create device request without period restrictions
                          </Trans>
                        }
                      />
                    }
                  />
                </Box>
              )}
              {(isDevice || isMedication) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipRequestEmployeeDeclarationVerify"
                    label={
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            Don't check declaration on employee level on create
                            medication request
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            Don't check declaration on employee level on create
                            device request
                          </Trans>
                        }
                      />
                    }
                  />
                </Box>
              )}
              {(isDevice || isMedication) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipRequestLegalEntityDeclarationVerify"
                    label={
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            Don't check declaration on legal entity level on
                            create medication request
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            Don't check declaration on legal entity level on
                            create device request
                          </Trans>
                        }
                      />
                    }
                  />
                </Box>
              )}
              {isMedication && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="multiMedicationDispenseAllowed"
                    label={
                      <Trans>
                        Partially dispense of medication request allowed
                      </Trans>
                    }
                  />
                </Box>
              )}
              {isMedication && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipMedicationDispenseSign"
                    label={
                      <Trans>
                        Digital sign on medication dispense is disabled
                      </Trans>
                    }
                  />
                </Box>
              )}
              {(isMedication || isDevice) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="requestNotificationDisabled"
                    label={
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            Patient notification on medication request is
                            disabled
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            Patient notification on device medication request is
                            disabled
                          </Trans>
                        }
                      />
                    }
                  />
                </Box>
              )}
              {(isMedication || isDevice) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipContractProvisionVerify"
                    label={
                      <Trans>
                        Don't check contract and medical program provision on
                        medication dispense
                      </Trans>
                    }
                  />
                </Box>
              )}
              {isMedication && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="medicalProgramChangeOnDispenseAllowed"
                    label={
                      <Trans>Medical program change on dispense allowed</Trans>
                    }
                  />
                </Box>
              )}
              <Box mt={3} ml={-2}>
                <Field.Checkbox
                  name="requestAllowed"
                  label={<Trans>Request allowed</Trans>}
                />
              </Box>
              <Box mt={3} ml={-2}>
                <Field.Checkbox
                  name="dispenseAllowed"
                  label={<Trans>Dispense allowed</Trans>}
                />
              </Box>
              {(isMedication || isDevice) && (
                <Box mt={3} ml={-2}>
                  <Field.Checkbox
                    name="skipDispenseDivisionDlsVerify"
                    label={<Trans>Skip dispense division DLS verify</Trans>}
                  />
                </Box>
              )}
              {(isDevice || isMedication) && (
                <Box mt={5} width={1 / 2}>
                  <Field.Number
                    name="dispensePeriodDay"
                    label={
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>Medication dispense period (days)</Trans>
                        }
                        DEVICE={<Trans>Device dispense period (in days)</Trans>}
                      />
                    }
                    placeholder={0}
                  />
                  <FieldListener
                    name="dispensePeriodDay"
                    subscription={{ value: true }}
                  >
                    {({ input: { value = "" } }) => (
                      <Validation.Custom
                        field="dispensePeriodDay"
                        options={({ value }: $TSFixMe) =>
                          !value ||
                          (parseInt(value) > 0 && Number.isInteger(+value))
                        }
                        message={i18n._(
                          t`Must be greater then zero positive integer number`
                        )}
                      />
                    )}
                  </FieldListener>
                </Box>
              )}
              {(isDevice || isMedication) && (
                <Box mt={3} width={1 / 2}>
                  <Field.Number
                    name="requestMaxPeriodDay"
                    label={
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>Medication request max period (days)</Trans>
                        }
                        DEVICE={
                          <Trans>
                            Device request max treatment period (in days)
                          </Trans>
                        }
                      />
                    }
                    placeholder={0}
                  />

                  <FieldListener
                    name="requestMaxPeriodDay"
                    subscription={{ value: true }}
                  >
                    {({ input: { value = "" } }) => (
                      <Validation.Custom
                        field="requestMaxPeriodDay"
                        options={({ value }: $TSFixMe) =>
                          !value ||
                          (parseInt(value) > 0 && Number.isInteger(+value))
                        }
                        message={i18n._(
                          t`Must be greater then zero positive integer number`
                        )}
                      />
                    )}
                  </FieldListener>
                </Box>
              )}
              {(isDevice || isMedication) && (
                <Box mt={3}>
                  <Line />
                  <Flex>
                    <Box mb={3}>
                      <Heading fontSize="1" fontWeight="normal">
                        <Switch
                          value={type}
                          MEDICATION={
                            <Trans>
                              Employee types who are allowed to create
                              medication requests
                            </Trans>
                          }
                          DEVICE={
                            <Trans>
                              Employee types allowed to create device requests
                            </Trans>
                          }
                        />
                      </Heading>
                    </Box>
                  </Flex>
                  <Field.Array
                    name="employeeTypesToCreateRequest"
                    addText={<Trans>Add</Trans>}
                    fields={({ name }) => (
                      <CreateMedicalProgramSettingsForm
                        name={name}
                        dictionaryName="EMPLOYEE_TYPE"
                      />
                    )}
                    removeButton={({ onClick }) => (
                      <RemoveButton onClick={onClick} />
                    )}
                    {...virtualizedListProps}
                  />
                </Box>
              )}
              {(isMedication || isDevice) && (
                <Box mt={3}>
                  <Line />
                  <Flex>
                    <Box mb={3}>
                      <Heading fontSize="1" fontWeight="normal">
                        <Trans>Allowed providing conditions</Trans>
                      </Heading>
                    </Box>
                  </Flex>
                  <Field.Array
                    name="providingConditionsAllowed"
                    addText={<Trans>Add</Trans>}
                    fields={(props) => (
                      <CreateMedicalProgramSettingsForm
                        {...props}
                        dictionaryName="PROVIDING_CONDITION"
                      />
                    )}
                    removeButton={({ onClick }) => (
                      <RemoveButton onClick={onClick} />
                    )}
                    {...virtualizedListProps}
                  />
                </Box>
              )}
              <Box mt={3}>
                <Line />
                <Flex>
                  <Box mb={3}>
                    <Heading fontSize="1" fontWeight="normal">
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            Speciality types allowed to create medication
                            requests or care plan activities
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            Speciality types allowed to create device requests
                          </Trans>
                        }
                        default={
                          <Trans>
                            Speciality types allowed to create care plan
                            activities
                          </Trans>
                        }
                      />
                    </Heading>
                  </Box>
                </Flex>
                <Field.Array
                  name="specialityTypesAllowed"
                  addText={<Trans>Add</Trans>}
                  fields={(props) => (
                    <CreateMedicalProgramSettingsForm
                      {...props}
                      dictionaryName="SPECIALITY_TYPE"
                    />
                  )}
                  removeButton={({ onClick }) => (
                    <RemoveButton onClick={onClick} />
                  )}
                  {...virtualizedListProps}
                />
              </Box>
              <Box mt={3}>
                <Line />
                <Flex>
                  <Box mb={3}>
                    <Heading fontSize="1" fontWeight="normal">
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            ICD10AM diagnoses allowed to create medication
                            requests or care plan activities
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            ICD10AM diagnoses allowed to create device requests
                          </Trans>
                        }
                        default={
                          <Trans>
                            ICD10AM diagnoses allowed to create care plan
                            activities
                          </Trans>
                        }
                      />
                    </Heading>
                  </Box>
                </Flex>
                <Field.Array
                  name="conditionsIcd10AmAllowed"
                  addText={<Trans>Add</Trans>}
                  fields={(props) => (
                    <CreateMedicalProgramSettingsForm
                      {...props}
                      dictionaryName="eHealth/ICD10_AM/condition_codes"
                    />
                  )}
                  removeButton={({ onClick }) => (
                    <RemoveButton onClick={onClick} />
                  )}
                  {...virtualizedListProps}
                />
              </Box>
              <Box mt={3}>
                <Line />
                <Flex>
                  <Box mb={3}>
                    <Heading fontSize="1" fontWeight="normal">
                      <Switch
                        value={type}
                        MEDICATION={
                          <Trans>
                            ICPC2 diagnoses allowed to create medication
                            requests or care plan activities
                          </Trans>
                        }
                        DEVICE={
                          <Trans>
                            ICPC2 diagnoses allowed to create device requests
                          </Trans>
                        }
                        default={
                          <Trans>
                            ICPC2 diagnoses allowed to create care plan
                            activities
                          </Trans>
                        }
                      />
                    </Heading>
                  </Box>
                </Flex>
                <Field.Array
                  name="conditionsIcpc2Allowed"
                  addText={<Trans>Add</Trans>}
                  fields={(props) => (
                    <CreateMedicalProgramSettingsForm
                      {...props}
                      dictionaryName="eHealth/ICPC2/condition_codes"
                    />
                  )}
                  removeButton={({ onClick }) => (
                    <RemoveButton onClick={onClick} />
                  )}
                  {...virtualizedListProps}
                />
              </Box>
              {isMedication && (
                <>
                  <Box mt={3}>
                    <Line />
                    <Flex>
                      <Box mb={3}>
                        <Heading fontSize="1" fontWeight="normal">
                          <Trans>
                            Legal entity license types allowed on dispense
                          </Trans>
                        </Heading>
                      </Box>
                    </Flex>
                    <Field.Array
                      name="licenseTypesAllowed"
                      addText={<Trans>Add</Trans>}
                      fields={(props) => (
                        <CreateMedicalProgramSettingsForm
                          {...props}
                          dictionaryName="LICENSE_TYPE"
                        />
                      )}
                      removeButton={({ onClick }) => (
                        <RemoveButton onClick={onClick} />
                      )}
                      {...virtualizedListProps}
                    />
                  </Box>
                </>
              )}
              {(isMedication || isDevice) && (
                <Box mt={3}>
                  <Line />
                  <Flex>
                    <Box mb={3}>
                      <Heading fontSize="1" fontWeight="normal">
                        <Trans>
                          Patient categories for whom care plan activity
                          creation are allowed
                        </Trans>
                      </Heading>
                    </Box>
                  </Flex>
                  <Field.Array
                    name="patientCategoriesAllowed"
                    addText={<Trans>Add</Trans>}
                    fields={(props) => (
                      <CreateMedicalProgramSettingsForm
                        {...props}
                        dictionaryName="eHealth/clinical_impression_patient_categories"
                      />
                    )}
                    removeButton={({ onClick }) => (
                      <RemoveButton onClick={onClick} />
                    )}
                    {...virtualizedListProps}
                  />
                </Box>
              )}
              {isDevice && (
                <Box mt={3}>
                  <Line />
                  <Flex>
                    <Box mb={3}>
                      <Heading fontSize="1" fontWeight="normal">
                        <Trans>
                          Definition of the device at the care plan's activity
                        </Trans>
                      </Heading>
                    </Box>
                  </Flex>
                  <Field.Array
                    name="deviceRequestAllowedCodeTypes"
                    addText={<Trans>Add</Trans>}
                    fields={(props) => (
                      <CreateMedicalProgramSettingsForm
                        {...props}
                        dictionaryName="device_interpretation_method"
                      />
                    )}
                    removeButton={({ onClick }) => (
                      <RemoveButton onClick={onClick} />
                    )}
                    {...virtualizedListProps}
                  />
                </Box>
              )}
              <Box mt={5}>
                <Field.Textarea
                  name="medicalProgramSettingsText"
                  placeholder={i18n._(t`Enter reason comment`)}
                  rows={5}
                  maxLength={500}
                  showLengthHint
                />
                <Validation.Required
                  field="medicalProgramSettingsText"
                  message="Required field"
                />
              </Box>
              <Flex justifyContent="center" as="footer">
                <Box mr={20}>
                  <Button
                    type="button"
                    variant="blue"
                    onClick={() => navigate!("../")}
                  >
                    <Trans>Return</Trans>
                  </Button>
                </Box>
                <Button
                  type="submit"
                  variant="red"
                  form="updateMedicalProgramSettings"
                >
                  <Trans>Update medical program settings</Trans>
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      )}
    </Mutation>
  );
};

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

const OPTIONAL_UPDATE_MEDICAL_PROGRAM_FIELDS = [
  "specialityTypesAllowed",
  "conditionsIcd10AmAllowed",
  "conditionsIcpc2Allowed",
  "providingConditionsAllowed",
  "skipEmployeeValidation",
  "multiMedicationDispenseAllowed",
  "skipMedicationDispenseSign",
  "requestNotificationDisabled",
  "skipContractProvisionVerify",
  "licenseTypesAllowed",
  "patientCategoriesAllowed",
  "skipTreatmentPeriod",
  "employeeTypesToCreateRequest",
  "requestMaxPeriodDay",
  "skipRequestEmployeeDeclarationVerify",
  "skipRequestLegalEntityDeclarationVerify",
  "dispensePeriodDay",
  "requestAllowed",
  "dispenseAllowed",
  "deviceRequestAllowedCodeTypes",
  "skipDispenseDivisionDlsVerify"
];

const handleTransformValues = (
  values: MedicalProgram["medicalProgramSettings"]
) =>
  Object.fromEntries(
    // @ts-expect-error TS(2769): No overload matches this call.
    Object.entries(values)
      .map(([key, value]) => {
        if (!OPTIONAL_UPDATE_MEDICAL_PROGRAM_FIELDS.includes(key)) {
          return [key, value];
        } else {
          if (value && typeof value === "string") {
            return [key, parseInt(value)];
          } else if (value && Array.isArray(value) && value.length === 0)
            return null;
          else {
            return [key, value];
          }
        }
      })
      .filter((item) => item)
  );
