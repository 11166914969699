const status = {
  bg: {
    states: {
      ACTIVE: { bg: "darkPastelGreen" },
      PROCESSED: { bg: "darkPastelGreen" },
      CLOSED: { bg: "silverCity", color: "blueberrySoda" },
      INACTIVE: { bg: "silverCity", color: "blueberrySoda" },
      REJECTED: { bg: "silverCity", color: "blueberrySoda" },
      TERMINATED: { bg: "silverCity", color: "blueberrySoda" },
      FAILED: { bg: "silverCity", color: "blueberrySoda" },
      FAILED_WITH_ERROR: { bg: "silverCity", color: "blueberrySoda" },
      REORGANIZED: { bg: "orangePastel", color: "blueberrySoda" },
      PENDING_VERIFICATION: { bg: "orangePastel" },
      PENDING: { bg: "orangePastel" },
      CONSUMED: { bg: "orangePastel" },
      NEW: { bg: "bluePastel" },
      IN_PROCESS: { bg: "orangePastel" },
      POSTPONE: { bg: "orangePastel" },
      DECLINED: { bg: "redPigment" },
      RESCUED: { bg: "redPigment" },
      DISMISSED: { bg: "redPigment" },
      EXPIRED: { bg: "redPigment" },
      ABORTED: { bg: "redPigment" },
      SUSPENDED: { bg: "redPigment" },
      APPROVED: { bg: "darkPastelGreen" },
      PENDING_NHS_SIGN: { bg: "shiningKnight", fontSize: 8 },
      SIGNED: { bg: "darkPastelGreen" },
      VERIFIED: { bg: "darkPastelGreen" },
      NHS_SIGNED: { bg: "darkAndStormy" },
      // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
      [true]: { bg: "redPigment" },
      // @ts-expect-error TS(2464): A computed property name must be of type 'string',... Remove this comment to see the full error message
      [false]: { bg: "darkPastelGreen" },
      IN_REVIEW: { bg: "bluePastel" },
      NOT_VERIFIED: { bg: "orangePastel" },
      VERIFICATION_NEEDED: { bg: "romanSilver", color: "blueberrySoda" },
      VERIFICATION_MANUAL_NEEDED: { bg: "romanSilver", color: "blueberrySoda" },
      VERIFICATION_AUTO_NEEDED: { bg: "romanSilver", color: "blueberrySoda" },
      COMPLETED: { bg: "bluePastel" },
      CHANGES_NEEDED: { bg: "lightRose" }
    }
  }
};

export default status;
