import React from "react";
import { gql } from "graphql-tag";
import { Router, RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { Division, Scalars } from "@edenlabllc/graphql-schema";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Tabs from "../../../../components/Tabs";

import { ITEMS_PER_PAGE } from "../../../../constants/pagination";
import { LEGAL_ENTITY_VALID_TYPE } from "../../../../constants/legalEntity";

import Header from "./Header";
import GeneralInfo from "./GeneralInfo";
import DivisionHealthcareServices from "./DivisionHealthcareServices";
import Equipment from "./Equipment";

type DivisionDetailsProps = RouteComponentProps<{
  divisionId: Scalars["ID"];
}>;

const filteredLocationParams = (divisionId: Scalars["ID"]) => ({
  id: divisionId,
  firstHealthcareServices: ITEMS_PER_PAGE[0],
  firstEquipment: ITEMS_PER_PAGE[0]
});

const DivisionDetails = ({ divisionId }: DivisionDetailsProps) => {
  return (
    <Query
      query={DivisionQuery}
      variables={filteredLocationParams(divisionId!)}
    >
      {({ loading, data }: QueryResult<{ division: Division }>) => {
        const { division } = data || {};
        if (isEmpty(division)) return null;
        const {
          id,
          databaseId,
          status,
          name,
          type,
          addresses,
          mountainGroup,
          phones,
          email,
          dlsVerified,
          legalEntity
        } = division;

        const isValidHealthcareServices =
          legalEntity && LEGAL_ENTITY_VALID_TYPE.includes(legalEntity.type);

        return (
          <LoadingOverlay loading={loading}>
            <Box p={6}>
              <Box py={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/legal-entities">
                    <Trans>Search legal entities</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item to="../">
                    <Trans>Legal entity details</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Divisions details</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Header
                id={id}
                databaseId={databaseId}
                status={status}
                name={name}
              />
            </Box>
            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General info</Trans>
              </Tabs.NavItem>
              {isValidHealthcareServices && (
                <Tabs.NavItem to="./healthcare-services">
                  <Trans>Healthcare Services</Trans>
                </Tabs.NavItem>
              )}
              <Tabs.NavItem to="./equipment">
                <Trans>Equipment</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>

            <Tabs.Content>
              <Router>
                <GeneralInfo
                  path="/"
                  type={type}
                  addresses={addresses}
                  mountainGroup={mountainGroup}
                  phones={phones}
                  email={email}
                  dlsVerified={dlsVerified}
                />
                <DivisionHealthcareServices path="/healthcare-services" />
                <Equipment path="/equipment" />
              </Router>
            </Tabs.Content>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export const DivisionQuery = gql`
  query DivisionQuery(
    $id: ID!
    $healthcareServicesFilter: HealthcareServiceFilter
    $healthcareServicesOrderBy: HealthcareServiceOrderBy
    $firstHealthcareServices: Int
    $beforeHealthcareServices: String
    $afterHealthcareServices: String
    $lastHealthcareServices: Int
    $equipmentFilter: EquipmentFilter
    $equipmentOrderBy: EquipmentOrderBy
    $firstEquipment: Int
    $beforeEquipment: String
    $afterEquipment: String
    $lastEquipment: Int
  ) {
    division(id: $id) {
      ...DivisionHeader
      ...DivisionGeneralInfo
      ...DivisionHealthcareServices
      ...Equipment
    }
  }
  ${Header.fragments.entry}
  ${GeneralInfo.fragments.entry}
  ${DivisionHealthcareServices.fragments.entry}
  ${Equipment.fragments!.entry}
`;

export default DivisionDetails;
