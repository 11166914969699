import React from "react";
import { gql } from "graphql-tag";
import { Box, Text } from "@rebass/emotion";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";

import { Period as TPeriod } from "@edenlabllc/graphql-schema";

import dateFormatter from "../../../helpers/dateFormatter";
import { FunctionComponentWithFragments } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type PeriodProps = {
  datePeriod: TPeriod;
  wrapperProps?: any;
};

const Period: FunctionComponentWithFragments<PeriodProps> = ({
  datePeriod: { end, start },
  wrapperProps = { mt: 2 }
}) => {
  const { i18n } = useLingui();

  return (
    <Box {...wrapperProps}>
      <Text fontSize={1}>
        <Trans>from</Trans>{" "}
        {dateFormatter(i18n.locale, DATE_TIME_FORMAT, start)} <Trans>to</Trans>{" "}
        {end ? <>{dateFormatter(i18n.locale, DATE_TIME_FORMAT, end)}</> : " - "}
      </Text>
    </Box>
  );
};

export default Period;

Period.fragments = {
  entry: gql`
    fragment Period on Period {
      end
      start
    }
  `
};
