import React from "react";
import { gql } from "graphql-tag";
import { Flex, Text, Box } from "@rebass/emotion";

import CodeableConcept from "../../../components/CodeableConcept";
import Link from "../../../components/Link";

import { LABEL_WIDTH } from "./Episode";

type ReferenceProps = {
  linkPath: string;
  linkDisplayValue: string | null;
  header?: React.ReactNode;
  fontSize?: number;
  customHeaderProps?: React.CSSProperties;
  headless?: boolean;
  wrapperProps?: { [key: string]: number };
  linkWrapperProps?: { [key: string]: number };
  code?: string | null;
  label?: string | null;
};

const Reference = ({
  header,
  fontSize = 12,
  customHeaderProps,
  headless,
  linkPath,
  linkDisplayValue,

  wrapperProps = {
    mt: 0,
    ml: 0
  },

  linkWrapperProps = {
    mt: 2,
    ml: 0
  },

  code = "",
  label = null
}: ReferenceProps) => {
  const headerProps = {
    width: LABEL_WIDTH,
    fontSize: fontSize,
    fontWeight: "bold",
    color: "darkCharcoal",
    ...customHeaderProps
  };
  return (
    <Box {...wrapperProps}>
      <Flex alignItems="flex-end">
        {!headless && header && <Text {...headerProps}>{header}</Text>}
        <Box {...linkWrapperProps}>
          {label && (
            <>
              {label}
              :&nbsp;
            </>
          )}
          <Link fontSize={fontSize} to={linkPath}>
            {code ? `${linkDisplayValue} (${code})` : linkDisplayValue}
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default Reference;

Reference.fragments = {
  entry: gql`
    fragment Reference on Reference {
      displayValue
      identifier {
        value
        type {
          ...CodeableConcept
        }
      }
    }
    ${CodeableConcept.fragments!.entry}
  `
};
