import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { DocumentNode, QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import { isEmpty, get } from "lodash";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import { MedicationDispenseConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

export const MEDICATION_DISPENSES_STATUSES: {
  values: { [key: string]: string };
} = {
  values: {
    new: t`New`,
    expired: t`Expired`,
    processed: t`Processed`,
    rejected: t`Rejected`
  }
};

type MedicationDispensesTabProps = RouteComponentProps<{
  id: string;
  predefinedMedicationRequestId: string;
  query: DocumentNode | undefined;
  patientId: string;
  isClinicalMonitoring?: boolean;
}>;

const MedicationDispensesTab = ({
  id,
  predefinedMedicationRequestId,
  query = MedicationDispensesQuery,
  isClinicalMonitoring = false,
  patientId
}: MedicationDispensesTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="medication_dispense"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            predefinedMedicationRequestId!,
            isClinicalMonitoring,
            patientId!
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: MedicationDispenseConnection }>) => {
            const medicationDispenses = isClinicalMonitoring
              ? data.impersonalMedicationDispenses
              : data.medicationDispenses;
            const pathSlug = isClinicalMonitoring
              ? predefinedMedicationRequestId
                ? `../../../medication-dispense/${patientId}`
                : `medication-dispense/${get(
                    locationParams,
                    "filter.patientId"
                  )}`
              : predefinedMedicationRequestId
                ? "../../medication-dispense"
                : "medication-dispense";

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() => (
                      <PrimarySearchFields
                        predefinedMedicationRequestId={
                          predefinedMedicationRequestId!
                        }
                        isClinicalMonitoring={isClinicalMonitoring}
                      />
                    )}
                    onResetSkipPagination
                  />
                </Box>
                {isEmpty(medicationDispenses) ||
                isEmpty(medicationDispenses.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>:{" "}
                        {medicationDispenses.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={medicationDispenses.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusValues={MEDICATION_DISPENSES_STATUSES.values}
                      tableName="medicationDispenses"
                      pathSlug={pathSlug}
                    />
                    <Pagination pageInfo={medicationDispenses.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default MedicationDispensesTab;

const MedicationDispensesQuery = gql`
  query MedicationDispensesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: MedicationDispenseFilter!
    $orderBy: MedicationDispenseOrderBy
  ) {
    medicationDispenses(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  predefinedMedicationRequestId: string,
  isClinicalMonitoring: boolean,
  patientId: string
) => {
  const {
    medicationDispensed,
    medicationRequestId,
    status,
    legalEntityId,
    divisionId,
    medicationDispenseId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(isClinicalMonitoring ? { patientId } : { personId: id }),
      ...restFilterParams,
      ...(medicationRequestId && {
        medicationRequestId: paramToBase64(
          "MedicationRequest",
          medicationRequestId
        )
      }),
      ...(predefinedMedicationRequestId && {
        medicationRequestId: paramToBase64(
          "MedicationRequest",
          predefinedMedicationRequestId
        )
      }),
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(legalEntityId && {
        legalEntityId: paramToBase64("LegalEntity", legalEntityId)
      }),
      ...(divisionId && {
        divisionId: paramToBase64("Division", divisionId)
      }),
      ...(medicationDispensed && {
        dispensed: `${medicationDispensed.from}/${medicationDispensed.to}`
      }),
      ...(medicationDispenseId && {
        id: paramToBase64("MedicationDispense", medicationDispenseId)
      })
    }
  };
};

type PrimarySearchFieldsProps = {
  predefinedMedicationRequestId: string;
  isClinicalMonitoring: boolean;
};

const PrimarySearchFields = ({
  predefinedMedicationRequestId,
  isClinicalMonitoring
}: PrimarySearchFieldsProps) => {
  const { i18n } = useLingui();
  return (
    <Flex flexDirection="column">
      {!predefinedMedicationRequestId && (
        <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      )}
      <Flex>
        {!predefinedMedicationRequestId && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter medication request ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.medicationRequestId"
                  label={<Trans id="Medication request ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.medicationRequestId"
              options={UUID_PATTERN}
              message="Invalid medication request ID"
            />
          </Box>
        )}
        <Box width={1 / 3} mt={3} px={1}>
          <Field.Select
            name="filter.status"
            label={<Trans id="Medication dispense status" />}
            placeholder={i18n._(t`Select status`)}
            items={Object.keys(MEDICATION_DISPENSES_STATUSES.values)}
            itemToString={(item: string) =>
              item ? i18n._(MEDICATION_DISPENSES_STATUSES.values[item]) : item
            }
            variant="select"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter pharmacy ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.legalEntityId"
                label={<Trans id="Pharmacy ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.legalEntityId"
            options={UUID_PATTERN}
            message="Invalid pharmacy ID"
          />
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter division ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.divisionId"
                label={<Trans id="Division ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.divisionId"
            options={UUID_PATTERN}
            message="Invalid division ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter dispensed period"
            render={({ translation }) => (
              <Field.RangePicker
                rangeNames={[
                  "filter.medicationDispensed.from",
                  "filter.medicationDispensed.to"
                ]}
                label={<Trans id="Dispensed period" />}
              />
            )}
          />
          <Form.Spy>
            {({ values = {} }: $TSFixMe) => {
              if (
                (values.filter &&
                  values.filter.medicationDispensed &&
                  values.filter.medicationDispensed.from) ||
                (values.filter &&
                  values.filter.medicationDispensed &&
                  values.filter.medicationDispensed.to)
              ) {
                return (
                  <>
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.medicationDispensed.from"
                          message={translation}
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.medicationDispensed.to"
                          message={translation}
                        />
                      )}
                    />
                  </>
                );
              }
              return null;
            }}
          </Form.Spy>
        </Box>
        {isClinicalMonitoring && (
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter medication dispense ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.medicationDispenseId"
                  label={<Trans id="Medication dispense ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.medicationDispenseId"
              options={UUID_PATTERN}
              message="Invalid medication dispense ID"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
