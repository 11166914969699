import React, { useCallback, useEffect, useState } from "react";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";

import { Field } from "@edenlabllc/ehealth-components";

type ItemType = {
  key: string;
  value: string;
};

type MultiDownshiftProps = {
  onChange?: (selectedItems: ItemType[]) => void;
  children: (params: any) => React.ReactElement;
  selectedItems?: ItemType[];
  onSelect?: (selectedItems: ItemType[]) => void;
  render?: (params: any) => React.ReactElement;
  withoutFormField?: boolean;
  itemToString?: ((item: ItemType) => string) | undefined;
  name?: string;
};

export const MultiDownshift = ({
  onSelect,
  onChange,
  children,
  render = children,
  withoutFormField,
  ...props
}: MultiDownshiftProps) => {
  const [selectedItems, setSelectedItems] = useState(props.selectedItems || []);
  let fieldOnChange: (items: ItemType[]) => void;

  const stateReducer = useCallback(
    (
      state: DownshiftState<ItemType>,
      changes: StateChangeOptions<ItemType>
    ) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
          return {
            ...changes,
            isOpen: true
          };
        default:
          return changes;
      }
    },
    []
  );

  const removeItem = useCallback(
    (item: ItemType) =>
      setSelectedItems((selectedItems: ItemType[]) =>
        selectedItems.filter((i: ItemType) => i.key !== item.key)
      ),
    [selectedItems]
  );

  const addSelectedItem = useCallback(
    (item: ItemType) =>
      setSelectedItems((selectedItems: ItemType[]) => [...selectedItems, item]),
    [selectedItems]
  );

  const getRemoveButtonProps = useCallback(
    ({ onClick, item, ...props }: $TSFixMe = {}) => ({
      onClick: (e: $TSFixMe) => {
        onClick && onClick(e);
        e.stopPropagation();
        removeItem(item);
      },
      ...props
    }),
    [removeItem]
  );

  const getStateAndHelpers = useCallback(
    (downshift: $TSFixMe, meta?: $TSFixMe) => ({
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      meta,
      ...downshift
    }),
    [removeItem, selectedItems, getRemoveButtonProps]
  );

  const handleSelection = useCallback(
    (selectedItem: ItemType) => {
      if (
        selectedItems.find((item: ItemType) => item.key === selectedItem.key)
      ) {
        removeItem(selectedItem);
      } else {
        addSelectedItem(selectedItem);
      }
      return selectedItems;
    },
    [onChange, onSelect, selectedItems]
  );

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedItems);
    }
    if (onChange) {
      onChange(selectedItems);
    }
    if (fieldOnChange) {
      fieldOnChange(selectedItems);
    }
  }, [selectedItems]);

  if (withoutFormField) {
    return (
      <Downshift
        {...props}
        stateReducer={stateReducer}
        onChange={handleSelection}
        selectedItem={selectedItems}
        children={(downshift) => render(getStateAndHelpers(downshift))}
      />
    );
  }

  return (
    <Field {...props}>
      {({ input, meta }: $TSFixMe) => {
        fieldOnChange = input.onChange;

        return (
          <Downshift
            {...props}
            stateReducer={stateReducer}
            onChange={handleSelection}
            selectedItem={selectedItems}
            children={(downshift) =>
              render(getStateAndHelpers(downshift, meta))
            }
          />
        );
      }}
    </Field>
  );
};

type SingleDownshiftProps = {
  children: (params: any) => React.ReactElement;
  render?: (params: any) => React.ReactElement;
  itemToString?: (item: ItemType) => string;
};

export const SingleDownshift = ({
  children,
  render = children,
  ...props
}: SingleDownshiftProps) => (
  <Field allowNull {...props}>
    {({
      input: { value, onChange, ...input },
      ...fieldRenderProps
    }: $TSFixMe) => (
      <Downshift
        {...props}
        selectedItem={value}
        onChange={onChange}
        children={(downshiftRenderProps) =>
          render({ ...downshiftRenderProps, ...fieldRenderProps, input })
        }
      />
    )}
  </Field>
);

type SingleDownshiftViewProps = {
  children?: (params: any) => React.ReactElement;
  onChange?: (selectedItems: ItemType[]) => void;
  value?: string;
  itemToString?: ((item: ItemType) => string) | undefined;
};

export const SingleDownshiftView = ({
  children,
  onChange,
  value,
  ...props
}: SingleDownshiftViewProps) => (
  <Downshift
    {...props}
    selectedItem={value}
    onChange={onChange}
    children={children}
  />
);
