import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";
import { isEmpty, debounce } from "lodash";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";
import { MedicalProgramConnection } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 4}>
        <Trans
          id="Enter medical program name"
          render={({ translation }) => (
            <Query
              query={SearchMedicalProgramsQuery}
              fetchPolicy="cache-first"
              variables={{
                skip: true
              }}
            >
              {({
                data,
                refetch: refetchMedicalProgram
              }: QueryResult<{
                medicalPrograms: MedicalProgramConnection;
              }>) => {
                const medicalPrograms =
                  data && data.medicalPrograms
                    ? data.medicalPrograms.nodes
                    : [];
                return (
                  <Field.Select
                    name="filter.name"
                    label={<Trans id="Medical program name" />}
                    placeholder={translation}
                    items={medicalPrograms!.map(
                      (medicalProgram) => medicalProgram && medicalProgram.name
                    )}
                    onInputValueChange={debounce(
                      (program, { selectedItem, inputValue }) =>
                        !isEmpty(program) &&
                        selectedItem !== inputValue &&
                        refetchMedicalProgram({
                          skip: false,
                          first: 20,
                          filter: { name: program }
                        }),
                      1000
                    )}
                  />
                );
              }}
            </Query>
          )}
        />
      </Box>
      <Box px={1} width={1 / 4}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.databaseId"
              label={<Trans id="Medical program ID" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
        <Trans
          id="Invalid ID"
          render={({ translation }) => (
            <Validation.Matches
              field="filter.databaseId"
              options={UUID_PATTERN}
              message={translation}
            />
          )}
        />
      </Box>
      <Box px={1} width={1 / 4}>
        <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_F" />
      </Box>
      <Box px={1} width={1 / 4}>
        <Composer
          components={[
            <DictionaryValue name="MEDICAL_PROGRAM_TYPE" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.type"
              label={<Trans id="Medical program type" />}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 4}>
        <Composer
          components={[
            <DictionaryValue name="FUNDING_SOURCE" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.fundingSource"
              label={<Trans id="Funding source" />}
              placeholder={translation}
              items={dict ? Object.keys(dict) : []}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 4}>
        <Composer
          components={[
            <DictionaryValue name="MR_BLANK_TYPES" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.mrBlankType"
              label={<Trans id="Type of Medication request blank" />}
              placeholder={translation}
              items={dict ? Object.keys(dict) : []}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
    </Flex>
  </>
);

const SearchMedicalProgramsQuery = gql`
  query SearchMedicalProgramsQuery(
    $first: Int
    $filter: MedicalProgramFilter
    $skip: Boolean! = false
  ) {
    medicalPrograms(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        name
      }
    }
  }
`;

export { PrimarySearchFields };
