import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import { Person } from "@edenlabllc/graphql-schema";

import AddressView from "../../../components/AddressView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";

import { Heading, Table, combineNestedData } from "./index";

type AddressInfoProps = {
  personAddresses: Person["addresses"] | any;
  masterPersonAddresses: Person["addresses"] | any;
  headerLabels?: any;
};

const AddressInfo = ({
  personAddresses,
  masterPersonAddresses,
  headerLabels
}: AddressInfoProps) => (
  <>
    <Heading>
      <Trans>Address</Trans>
    </Heading>
    <DictionaryValue name="ADDRESS_TYPE">
      {(addressesHeader: DictionaryAllValuesJson) => (
        <>
          {headerLabels && (
            <Table
              header={{
                id: null
              }}
              data={headerLabels}
              renderRow={({ id }) => ({
                id
              })}
              skipComparison
            />
          )}
          <Table
            header={addressesHeader}
            data={combineNestedData(
              personAddresses,
              masterPersonAddresses,
              addressesHeader
            )}
            renderRow={({ RESIDENCE, REGISTRATION }) => ({
              RESIDENCE: <AddressView data={RESIDENCE} />,
              REGISTRATION: <AddressView data={REGISTRATION} />
            })}
            hideEmptyFields
          />
        </>
      )}
    </DictionaryValue>
  </>
);

AddressInfo.fragments = {
  entry: gql`
    fragment AddressInfo on Person {
      addresses {
        ...Addresses
      }
    }
    ${AddressView.fragments.entry}
  `
};

export default AddressInfo;
