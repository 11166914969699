import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { loader } from "graphql.macro";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Heading, Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { getFullName } from "@edenlabllc/ehealth-utils";
import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { LegalEntity, LegalEntityConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import Table from "../../../components/Table";

import pagination from "../../../helpers/pagination";

import { ID_PATTERN } from "../../LegalEntities/Search";
import { PrimarySearchFields, LegalEntityItem } from "./SearchLEStep";

const SearchLegalEntitiesQuery = loader(
  "../../../graphql/SearchLegalEntitiesQuery.graphql"
);

const SuccessorLegalEntity = ({
  // @ts-expect-error location state
  location: { state },
  location,
  navigate
}: RouteComponentProps) => {
  const { reorganizationType, successorLegalEntites = [] } = state;

  const isEnableChoseLE = useMemo(
    () =>
      reorganizationType === "DIVIDING" || isEmpty(successorLegalEntites)
        ? true
        : successorLegalEntites.length === 0,
    [state]
  );

  const isLEChosen = useMemo(
    () => (legalEntity: Partial<LegalEntity>) =>
      isEmpty(
        state &&
          state.successorLegalEntites &&
          state.successorLegalEntites.length &&
          state.successorLegalEntites.find(
            (currLegalEntity: LegalEntity) =>
              currLegalEntity.databaseId === legalEntity.databaseId
          )
      ),
    [state]
  );

  const isNextStepDisabled = useMemo(() => {
    if (!state.successorLegalEntites || !state.successorLegalEntites.length) {
      return true;
    } else if (
      state.reorganizationType === "DIVIDING" &&
      state.successorLegalEntites.length < 2
    ) {
      return true;
    } else {
      return false;
    }
  }, [state]);

  return (
    <>
      <LocationParams state={state}>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const code = locationParams.filter && locationParams.filter.code;
          const regId = new RegExp(ID_PATTERN);
          const testID = code && regId.test(code);
          const sendCodeParams = testID
            ? { databaseId: code }
            : { edrpou: code };

          const filteredParams = {
            ...sendCodeParams,
            status: ["ACTIVE"],
            type: ["MSP", "PRIMARY_CARE"]
          };
          return (
            <>
              <Box mb={2}>
                <SearchForm
                  initialValues={locationParams}
                  onSubmit={setLocationParams}
                  renderPrimary={() => (
                    <PrimarySearchFields
                      codeFilterLabel={
                        <Trans id="Search successor legal entity" />
                      }
                      excludedOrganization={{
                        id: state.reorganizedLegalEntities[0].databaseId,
                        edrpou: state.reorganizedLegalEntities[0].edrpou
                      }}
                    />
                  )}
                  searchButton={() => (
                    <Button
                      variant="blue"
                      width={140}
                      ml={-1}
                      disabled={!isEnableChoseLE}
                    >
                      <Trans>Search</Trans>
                    </Button>
                  )}
                />
              </Box>
              {locationParams.filter && (
                <Query
                  query={SearchLegalEntitiesQuery}
                  variables={{
                    ...pagination(locationParams),
                    filter: !isEmpty(locationParams.filter)
                      ? filteredParams
                      : undefined
                  }}
                  fetchPolicy="network-only"
                >
                  {({
                    loading,
                    error,
                    data
                  }: QueryResult<{ legalEntities: LegalEntityConnection }>) => {
                    if (error || isEmpty(data) || isEmpty(data.legalEntities))
                      return null;
                    const {
                      legalEntities: { nodes: legalEntities = [], pageInfo }
                    } = data;

                    return (
                      <LoadingOverlay loading={loading}>
                        <Table
                          data={legalEntities}
                          header={{
                            databaseId: <Trans>Legal entity ID</Trans>,
                            name: <Trans>Legal entity name</Trans>,
                            edrpou: <Trans>EDRPOU</Trans>,
                            owner: <Trans>CEO</Trans>,
                            type: <Trans>Type</Trans>,
                            nhsVerified: <Trans>Verified by NZZU</Trans>,
                            status: <Trans>Status</Trans>,
                            action: <Trans>Action</Trans>
                          }}
                          renderRow={({
                            status,
                            nhsVerified,
                            owner,
                            type,
                            ...legalEntity
                          }: LegalEntity) => ({
                            ...legalEntity,
                            type: (
                              <DictionaryValue
                                name="LEGAL_ENTITY_TYPE"
                                item={type}
                              />
                            ),
                            owner:
                              !isEmpty(owner) &&
                              owner &&
                              getFullName(owner.party),
                            nhsVerified: (
                              <Flex justifyContent="center">
                                {nhsVerified ? (
                                  <PositiveIcon />
                                ) : (
                                  <NegativeIcon />
                                )}
                              </Flex>
                            ),
                            status: (
                              <Badge
                                name={status}
                                type="LEGALENTITY"
                                display="block"
                              />
                            ),
                            action: isLEChosen(legalEntity) ? (
                              <Button
                                mr={2}
                                onClick={() => {
                                  navigate!(
                                    `${location && location.pathname}`,
                                    {
                                      state: {
                                        ...state,
                                        ...(state &&
                                          state.successorLegalEntites && {
                                            successorLegalEntites: [
                                              ...state.successorLegalEntites,
                                              {
                                                status,
                                                nhsVerified,
                                                owner,
                                                type,
                                                ...legalEntity
                                              }
                                            ]
                                          }),
                                        // @ts-expect-error location state
                                        ...(!state ||
                                          (!state.successorLegalEntites && {
                                            successorLegalEntites: [
                                              {
                                                status,
                                                nhsVerified,
                                                owner,
                                                type,
                                                ...legalEntity
                                              }
                                            ]
                                          }))
                                      }
                                    }
                                  );
                                }}
                                variant="link"
                                type="reset"
                                width={140}
                              >
                                <Trans>Chose</Trans>
                              </Button>
                            ) : (
                              <Button variant="linkDisabled">
                                <Trans>Chosen</Trans>
                              </Button>
                            )
                          })}
                          tableName="legalEntity/Add"
                        />
                        <Pagination pageInfo={pageInfo} />
                      </LoadingOverlay>
                    );
                  }}
                </Query>
              )}
              {state && !isEmpty(state.successorLegalEntites) && (
                <Box mt={6}>
                  <Heading as="h1" fontWeight="normal" mb={6}>
                    <Trans>Added successors legal entities</Trans>
                  </Heading>
                  {state.successorLegalEntites.map(
                    (legalEntity: LegalEntity, index: number) => (
                      <>
                        <LegalEntityItem
                          legalEntity={legalEntity}
                          key={legalEntity.id}
                          navigate={navigate}
                          state={state}
                          location={location}
                          statePartName="successorLegalEntites"
                        />
                        {index <= state.successorLegalEntites.length - 1 && (
                          <Line />
                        )}
                      </>
                    )
                  )}
                </Box>
              )}
              {isEmpty(state.successorLegalEntites) && <Line />}
              <Flex>
                <Button
                  mr={2}
                  onClick={() => {
                    navigate!("../reorganization-data", {
                      state
                    });
                  }}
                  variant="blue"
                  type="reset"
                  width={140}
                >
                  <Trans>Return</Trans>
                </Button>
                <Button
                  variant="green"
                  width={140}
                  onClick={() => navigate!("../confirm", { state })}
                  disabled={isNextStepDisabled}
                >
                  <Trans>Next</Trans>
                </Button>
              </Flex>
            </>
          );
        }}
      </LocationParams>
    </>
  );
};

export default SuccessorLegalEntity;
