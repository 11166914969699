import React from "react";
import { Trans } from "@lingui/macro";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { DictionaryValueConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import Table, { SortingParams } from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

type TableProps = {
  childValues: DictionaryValueConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  dictionaryAbility: boolean;
};

export const ChildValuesTable = ({
  childValues,
  locationParams,
  setLocationParams
}: TableProps) => (
  <Table
    data={childValues}
    header={{
      databaseId: <Trans>ID</Trans>,
      code: <Trans>Key</Trans>,
      description: <Trans>Description</Trans>,
      isActive: <Trans>Status</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({ id, code, description, databaseId, isActive }) => {
      return {
        databaseId,
        code,
        description,
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        action: (
          <Link to={`../../${id}/values`} fontWeight="bold">
            <Trans>View</Trans>
          </Link>
        )
      };
    }}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="dictionary-child-values-table"
  />
);
