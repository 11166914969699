import React from "react";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
// @ts-expect-error types mismatch
import { MedicalcompositionConnection } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import Table from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";

type MedicalCompositionTable = {
  medicalComposition: MedicalcompositionConnection["items"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const MedicalCompositionTable = ({
  medicalComposition,
  locationParams,
  setLocationParams
}: MedicalCompositionTable) => {
  return (
    <Table
      data={medicalComposition}
      header={{
        databaseId: <Trans>ID</Trans>,
        title: <Trans>Medical composition number</Trans>,
        focus: <Trans>Medical composition focus</Trans>,
        type: <Trans>Type</Trans>,
        category: <Trans>Category</Trans>,
        status: <Trans>Status</Trans>,
        createdAt: <Trans>Created at</Trans>,
        startedAt: <Trans>Started</Trans>,
        endedAt: <Trans>Expiration date</Trans>,
        author: <Trans>Author</Trans>,
        custodian: <Trans>Custodian</Trans>,
        action: <Trans>Action</Trans>
      }}
      renderRow={({
        identifier,
        title,
        section,
        type,
        category,
        status,
        date,
        event,
        author,
        custodian,
        ...medicalComposition
      }) => ({
        ...medicalComposition,
        databaseId: <>{identifier.value}</>,
        title: title,
        focus: section.focus.value,
        type: (
          <DictionaryValue
            name="COMPOSITION_TYPES"
            item={type.coding[0].code}
          />
        ),
        category: (
          <DictionaryValue
            name="COMPOSITION_CATEGORIES"
            item={category.coding[0].code}
          />
        ),
        status: <DictionaryValue name={"COMPOSITION_STATUS"} item={status} />,
        createdAt: dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          },
          date
        ),
        startedAt: dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          },
          event.find((ev: $TSFixMe) => ev.period).period.start
        ),
        endedAt: dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          },
          event.find((ev: $TSFixMe) => ev.period).period.end
        ),
        author: <>{author.value}</>,
        custodian: custodian.value,
        action: (
          <Link to={`../${identifier.value}`} fontWeight="bold">
            <Trans>Details</Trans>
          </Link>
        )
      })}
      sortableFields={[]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      whiteSpaceNoWrap={[]}
      tableName="medicalComposition/search"
      hiddenFields=""
    />
  );
};

export default MedicalCompositionTable;
