import React from "react";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { gql } from "graphql-tag";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  Maybe,
  Medication,
  MedicationConnection,
  MedicationIngredient
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import Table, { SortingParams } from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type MedicationsTableProps = {
  medications: MedicationConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const MedicationsTable = ({
  medications,
  locationParams,
  setLocationParams
}: MedicationsTableProps) => (
  <Table
    data={medications}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Medication name</Trans>,
      innmDosageId: <Trans>InnmDosage ID</Trans>,
      innmDosageName: <Trans>InnmDosage name</Trans>,
      formPharm: <Trans>Pharmaceutical form</Trans>,
      manufacturerName: <Trans>Manufacturer name</Trans>,
      manufacturerCountry: <Trans>Manufacturer country</Trans>,
      isActive: <Trans>Status</Trans>,
      drlzSkuId: <Trans>Product ID in DRLZ</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({
      id,
      databaseId,
      insertedAt,
      isActive,
      drlzSkuId,
      name,
      ingredients,
      manufacturer,
      formPharm
    }: Medication) => {
      const [primaryIngredient] = ingredients.filter(
        (f: Maybe<MedicationIngredient>) => f && f.isPrimary
      );
      const innmDosageName =
        primaryIngredient && primaryIngredient.innmDosage.name;
      const innmDosageId =
        primaryIngredient && primaryIngredient.innmDosage.databaseId;

      return {
        databaseId,
        name,
        innmDosageId,
        innmDosageName,
        formPharm: formPharm && (
          <DictionaryValue name="FORM_PHARM_LIST" item={formPharm} />
        ),
        manufacturerName: manufacturer ? manufacturer.name : "-",
        manufacturerCountry: manufacturer ? (
          <DictionaryValue name="COUNTRY" item={manufacturer.country} />
        ) : (
          "-"
        ),
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        drlzSkuId,
        action: (
          <Link to={`../${id}`} fontWeight="bold">
            Деталі
          </Link>
        )
      };
    }}
    sortableFields={["insertedAt", "name"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="medication/search"
    hiddenFields="formPharm,innmDosageName,drlzSkuId"
  />
);

MedicationsTable.fragments = {
  entry: gql`
    fragment Medications on Medication {
      id
      databaseId
      name
      isActive
      ingredients {
        isPrimary
        innmDosage {
          databaseId
          name
        }
      }
      insertedAt
      manufacturer {
        country
        name
      }
      formPharm
      drlzSkuId
    }
  `
};

export default MedicationsTable;
