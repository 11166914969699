import React from "react";
import { Box } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { Maybe, Reference } from "@edenlabllc/graphql-schema";

import Table from "../../../components/Table";

import paramToBase64 from "../../../helpers/paramToBase64";

import ReferenceSwitcher from "./ReferenceSwitcher";

type ReasonReferencesProps = {
  reasonReferences?: Maybe<Maybe<Reference>[]>;
  isClinicalMonitoring?: boolean;
  patientId?: string;
  handleSetReferenceLink?: (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: Reference
  ) => string;
};

const ReasonReferences = ({
  reasonReferences,
  isClinicalMonitoring,
  patientId,

  handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: Reference
  ) =>
    isClinicalMonitoring
      ? `../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`
      : `../../${memoizedReferenceSlug}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`
}: ReasonReferencesProps) => {
  if (isEmpty(reasonReferences)) return null;

  const REFERENCE_TYPES = {
    values: {
      Observation: t`Observation`,
      Condition: t`Condition`,
      Procedure: t`Procedure`,
      DiagnosticReport: t`Diagnostic report`
    }
  };

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={reasonReferences}
        header={{
          reference: <Trans>Reference</Trans>
        }}
        renderRow={({ ...reference }) => ({
          reference: (
            <ReferenceSwitcher
              reference={reference}
              referenceTypes={REFERENCE_TYPES}
              handleSetReferenceLink={handleSetReferenceLink}
            />
          )
        })}
      />
    </Box>
  );
};

export default ReasonReferences;
