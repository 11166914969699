import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";
import { get } from "lodash";

import { NegativeIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  GlobalBudgetContract,
  GlobalBudgetContractConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../../components/Badge";
import FullName from "../../../../components/FullName";
import Link from "../../../../components/Link";
import Table, { SortingParams } from "../../../../components/Table";
import {
  SearchParams,
  SetSearchParams
} from "../../../../components/SearchForm";

import dateFormatter from "../../../../helpers/dateFormatter";

type GlobalBudgetTableProps = {
  globalBudgetContracts: GlobalBudgetContractConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const GlobalBudgetTable = ({
  globalBudgetContracts,
  locationParams,
  setLocationParams
}: GlobalBudgetTableProps) => (
  <Table
    data={globalBudgetContracts}
    header={{
      databaseId: <Trans>ID</Trans>,
      contractNumber: <Trans>Contract Number</Trans>,
      edrpou: <Trans>EDRPOU</Trans>,
      name: <Trans>Name of medical institution</Trans>,
      nhsSignerName: <Trans>Performer</Trans>,
      startDate: <Trans>The contract is valid with</Trans>,
      endDate: <Trans>The contract is valid for</Trans>,
      isSuspended: <Trans>Contract state</Trans>,
      insertedAt: <Trans>Added</Trans>,
      status: <Trans>Status</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      contractorLegalEntity,
      nhsSigner,
      startDate,
      endDate,
      isSuspended,
      status,
      insertedAt,
      ...globalBudgetContracts
    }: GlobalBudgetContract) => ({
      ...globalBudgetContracts,
      edrpou: get(contractorLegalEntity, "edrpou"),
      name: get(contractorLegalEntity, "edrData.name"),
      nhsSignerName: nhsSigner && <FullName party={nhsSigner.party} />,
      startDate: i18n.date(startDate),
      endDate: i18n.date(endDate),
      isSuspended: (
        <Flex justifyContent="center">
          <NegativeIcon
            fill={!isSuspended ? "#1BB934" : "#ED1C24"}
            stroke={!isSuspended ? "#1BB934" : "#ED1C24"}
          />
        </Flex>
      ),
      insertedAt: dateFormatter(
        i18n.locale,
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        },
        insertedAt
      ),
      status: <Badge type="CONTRACT" name={status} display="block" />,
      details: (
        <Link to={`./${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={[
      "status",
      "startDate",
      "endDate",
      "isSuspended",
      "insertedAt"
    ]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    tableName="contract/search"
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="insertedAt"
  />
);

GlobalBudgetTable.fragments = {
  entry: gql`
    fragment GlobalBudgetContracts on GlobalBudgetContract {
      id
      databaseId
      startDate
      endDate
      isSuspended
      contractNumber
      status
      insertedAt
      nhsSigner {
        id
        party {
          id
          ...FullName
        }
      }
      contractorLegalEntity {
        id
        name
        edrpou
        edrData {
          name
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

export default GlobalBudgetTable;
