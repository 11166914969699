import React from "react";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Form } from "@edenlabllc/ehealth-components";

import * as Field from "../../../components/Field";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";

import STATUSES from "../../../helpers/statuses";

export const PERSON_VERIFICATION_STATUSES = [
  "IN_REVIEW",
  "VERIFICATION_NEEDED"
];
export const PERSON_VERIFICATION_STATUSES_FOR_DRACS = [
  ...PERSON_VERIFICATION_STATUSES,
  "NOT_VERIFIED"
];

type PrimarySearchFieldsProps = {
  statuses: string[];
  isDracsTab?: boolean;
};

export const PrimarySearchFields = ({
  statuses,
  isDracsTab = false
}: PrimarySearchFieldsProps) => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 4}>
      <DictionaryValue name="PERSON_VERIFICATION_STATUSES">
        {(documentTypes: DictionaryAllValuesJson) => (
          <Trans
            id="Show all"
            render={({ translation }) => (
              <Field.Select
                name={`filter.${
                  isDracsTab
                    ? "dracsDeathVerificationStatus"
                    : "manualRulesVerificationStatus"
                }`}
                label={<Trans id="Verification status" />}
                items={Object.keys(documentTypes).filter((type) =>
                  statuses.includes(type)
                )}
                itemToString={(item: string) =>
                  documentTypes[item] || String(translation)
                }
                variant="select"
                emptyOption
                filterOptions={{
                  keys: [(item: string) => documentTypes[item]]
                }}
              />
            )}
          />
        )}
      </DictionaryValue>
    </Box>
    {isDracsTab && (
      <Box px={1} width={1 / 4}>
        <Trans
          id="Show all"
          render={({ translation }) => (
            <Field.Select
              name="filter.hasDeclaration"
              label={<Trans id="Is has declaration" />}
              items={Object.keys(STATUSES.YES_NO)}
              itemToString={(item) => STATUSES.YES_NO[item] || translation}
              variant="select"
              emptyOption
              filterOptions={{
                // @ts-expect-error statuses boolean key
                keys: [(item: boolean) => STATUSES.YES_NO[item]]
              }}
            />
          )}
        />
      </Box>
    )}
    {isDracsTab && (
      <Form.Spy>
        {({ values }: $TSFixMe) => {
          const status =
            values &&
            values.filter &&
            values.filter.dracsDeathVerificationStatus;
          return (
            <Box px={1} width={1 / 2}>
              <Field.RangeNumber
                rangeNames={[
                  "filter.dracsDeathUnverifiedAt.start",
                  "filter.dracsDeathUnverifiedAt.end"
                ]}
                label={<Trans id="Dracs death unverified, days" />}
                disabled={status !== "NOT_VERIFIED"}
              />
            </Box>
          );
        }}
      </Form.Spy>
    )}
  </Flex>
);
