import React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { loader } from "graphql.macro";
import { Box, Flex } from "@rebass/emotion";
import debounce from "lodash/debounce";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { normalizeName } from "@edenlabllc/ehealth-utils";
import { Validation } from "@edenlabllc/ehealth-components";
import { Settlement, SettlementConnection } from "@edenlabllc/graphql-schema";

import AddressView from "../../../components/AddressView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import { SearchParams } from "../../../components/SearchForm";

import STATUSES from "../../../helpers/statuses";
import { EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN } from "../../../constants/validationPatterns";

const SettlementsQuery = loader("../../../graphql/SettlementsQuery.graphql");

type PrimarySearchFieldsProps = {
  initialValues: SearchParams;
};

const PrimarySearchFields = ({
  initialValues: { addresses }
}: PrimarySearchFieldsProps) => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 2}>
        <Trans
          id="Legal entity EDRPOU or ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.code"
              label={<Trans id="Search legal entity by EDRPOU" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
              autoComplete="off"
            />
          )}
        />
        <Validation.Matches
          field="filter.code"
          options={EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN}
          message="Invalid number"
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 4}>
        <Trans
          id="Enter settlement"
          render={({ translation }) => (
            <Query
              query={SettlementsQuery}
              variables={{ ...addresses, skip: true }}
            >
              {({
                data,
                refetch: refetchSettlements
              }: QueryResult<{ settlements: SettlementConnection }>) => {
                const settlements =
                  data && data.settlements ? data.settlements.nodes : [];

                return (
                  <Field.Select
                    name="filter.addresses"
                    label={<Trans id="Settlement" />}
                    placeholder={translation}
                    items={settlements!}
                    filter={(item: Settlement[]) => item}
                    onInputValueChange={debounce(
                      (settlement, { selectedItem, inputValue }) => {
                        const selectedName =
                          selectedItem && selectedItem.name.toLowerCase();
                        const inputName =
                          inputValue && inputValue.toLowerCase();

                        return (
                          selectedName !== inputName &&
                          refetchSettlements({
                            skip: false,
                            first: 20,
                            filter: { name: settlement }
                          })
                        );
                      },
                      1000
                    )}
                    itemToString={(item: Settlement) =>
                      item && normalizeName(item.name)
                    }
                    renderItem={(address: Settlement) => (
                      <AddressView data={address} />
                    )}
                    filterOptions={{ keys: ["name"] }}
                  />
                );
              }}
            </Query>
          )}
        />
      </Box>

      <Box px={1} width={1 / 4}>
        <Composer
          components={[
            <DictionaryValue name="LEGAL_ENTITY_TYPE" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Show all" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.type"
              label={<Trans id="Legal entity type" />}
              placeholder={translation}
              items={Object.keys(dict).filter((key) => key !== "MIS")}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 4}>
        <Composer
          components={[
            <DictionaryValue name="LEGAL_ENTITY_STATUS" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="All statuses" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.nhsVerified"
              label={<Trans id="Verification status" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 4}>
        <Trans
          id="Select option"
          render={({ translation }) => (
            <Field.Select
              name="filter.nhsReviewed"
              label={<Trans id="NHS reviewed" />}
              items={Object.keys(STATUSES.YES_NO)}
              itemToString={(item: boolean) =>
                // @ts-expect-error statuses boolean key
                STATUSES.YES_NO[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                // @ts-expect-error statuses boolean key
                keys: [(item: boolean) => STATUSES.YES_NO[item]]
              }}
            />
          )}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields };
