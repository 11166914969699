import React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Scalars, LegalEntity } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button, { ButtonVariantType } from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type NHSVerifyProps = {
  id: Scalars["ID"];
  nhsVerified: LegalEntity["nhsVerified"];
  isVerificationActive: boolean;
};

const NHSVerify = ({
  id,
  nhsVerified,
  isVerificationActive
}: NHSVerifyProps) => {
  const [isVisible, setVisibilityState] = React.useState(false);
  const toggle = () => setVisibilityState(!isVisible);
  const variant: ButtonVariantType = nhsVerified ? "red" : "green";
  return (
    <Mutation mutation={NhsVerifyLegalEntityMutation}>
      {(nhsVerifyLegalEntity: MutationFunction) => (
        <>
          <NHSVerifyButton
            nhsVerified={nhsVerified}
            variant={variant}
            toggle={toggle}
          />
          <Popup
            visible={isVisible}
            okButtonProps={{
              variant,
              disabled: !isVerificationActive
            }}
            onCancel={toggle}
            title={<Title nhsVerified={nhsVerified} />}
            onOk={async () => {
              await nhsVerifyLegalEntity({
                variables: {
                  input: {
                    id,
                    nhsVerified: !nhsVerified
                  }
                }
              });
              toggle();
            }}
          />
        </>
      )}
    </Mutation>
  );
};

const Title = ({ nhsVerified }: { nhsVerified: LegalEntity["nhsVerified"] }) =>
  nhsVerified ? (
    <Trans>Cancel Verification</Trans>
  ) : (
    <Trans>Verification legal entity</Trans>
  );

type NHSVerifyButtonProps = {
  nhsVerified: LegalEntity["nhsVerified"];
  toggle: () => void;
  variant: ButtonVariantType;
};

const NHSVerifyButton = ({
  nhsVerified,
  toggle,
  variant
}: NHSVerifyButtonProps) =>
  nhsVerified ? (
    <Ability action="nhs_verify" resource="legal_entity">
      <Button variant={variant} onClick={toggle}>
        <Title nhsVerified={nhsVerified} />
      </Button>
    </Ability>
  ) : (
    <Button variant={variant} onClick={toggle}>
      <Title nhsVerified={nhsVerified} />
    </Button>
  );

const NhsVerifyLegalEntityMutation = gql`
  mutation NhsVerifyLegalEntityMutation($input: NhsVerifyLegalEntityInput!) {
    legalEntity: nhsVerifyLegalEntity(input: $input) {
      legalEntity {
        id
        nhsVerified
      }
    }
  }
`;

export default NHSVerify;
