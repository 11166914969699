import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Box, Heading, Text } from "@rebass/emotion";

import { DracsDeathAct } from "@edenlabllc/graphql-schema";

import AddressView from "../../../components/AddressView";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import Popup from "../../../components/Popup";

import paramToBase64 from "../../../helpers/paramToBase64";
import STATUSES from "../../../helpers/statuses";

type DracsDeathActPopupProps = {
  deathActId: string;
  isPopupVisible: boolean;
  toggle: () => void;
};

const DracsDeathActPopup = ({
  deathActId,
  isPopupVisible,
  toggle
}: DracsDeathActPopupProps) => {
  if (!deathActId) return null;

  return (
    <Query
      query={DracsDeathActQuery}
      fetchPolicy="network-only"
      variables={{ id: paramToBase64("DracsDeathAct", deathActId) }}
    >
      {({ data }: QueryResult<{ dracsDeathAct: DracsDeathAct }>) => {
        if (!(data && data.dracsDeathAct)) return null;
        return (
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={<Trans>Dracs death act details</Trans>}
            onOk={toggle}
            hideOkButton
            textAlign="left"
            overflow="auto"
            width={920}
          >
            <Box pb={5} pl={5} pr={5}>
              <DeathAct data={data.dracsDeathAct} />
              <GeneralInfo data={data.dracsDeathAct} />
              <Documents
                data={data.dracsDeathAct && data.dracsDeathAct.docSeizes}
              />
              <Address data={data.dracsDeathAct} />
            </Box>
          </Popup>
        );
      }}
    </Query>
  );
};

type DeathActProps = {
  data: DracsDeathAct;
  isDracsDeathCandidatesDetailsPopup?: boolean;
};

export const DeathAct = ({
  data,
  isDracsDeathCandidatesDetailsPopup
}: DeathActProps) => (
  <>
    <Heading fontSize="2" fontWeight="normal" mb={4} mt={4}>
      <Trans>Death act</Trans>
    </Heading>
    <DefinitionListView
      labelWidth={310}
      labels={{
        arRegDate: <Trans>AR registration date</Trans>,
        arRegNumber: <Trans>AR registration number</Trans>,
        opDate: <Trans>Last operation date</Trans>,
        arOpName: <Trans>Last operation name</Trans>,
        regNumb: <Trans>AR death book number</Trans>,
        composeDate: <Trans>AR compose date</Trans>,
        composeOrg: <Trans>Compose organization</Trans>,
        isRestore: <Trans>Is restore</Trans>,
        ...(isDracsDeathCandidatesDetailsPopup && {
          dateDeath: <Trans>Death date</Trans>
        })
      }}
      data={{
        arRegDate:
          data.arRegDate &&
          typeof data.arRegDate === "string" &&
          i18n.date(data.arRegDate),
        arRegNumber: data.arRegNumber || "-",
        opDate:
          data.opDate &&
          typeof data.opDate === "string" &&
          i18n.date(data.opDate),
        arOpName:
          (data.arOpName && STATUSES.DRACS_AR_OP_NAME[data.arOpName]) ||
          data.arOpName,
        regNumb: data.regNumb,
        composeDate: data.composeDate,
        composeOrg: data.composeOrg,
        isRestore: data.isRestore ? <Trans>Yes</Trans> : <Trans>No</Trans>,
        dateDeath: data.dateDeath
      }}
      fontSize={13}
    />
    <Line />
  </>
);

type GeneralInfoProps = {
  data: DracsDeathAct;
};

const GeneralInfo = ({ data }: GeneralInfoProps) => {
  let birthPlace = data.birthRegion ? `${data.birthRegion} область, ` : "";
  if (data.birthLocalityType && data.birthLocality) {
    birthPlace += `${data.birthLocalityType} ${data.birthLocality}`;
  } else if (data.birthDistrict) {
    birthPlace += data.birthDistrict;
  }

  return (
    <>
      <Heading fontSize="2" fontWeight="normal" mb={4} mt={4}>
        <Trans>General information</Trans>
      </Heading>
      <DefinitionListView
        labelWidth={310}
        labels={{
          firstName: <Trans>First name</Trans>,
          lastName: <Trans>Last name</Trans>,
          secondName: <Trans>Second name</Trans>,
          birthDate: <Trans>Date of birth</Trans>,
          birthCountry: <Trans>Country of birth</Trans>,
          birthSettlement: <Trans>Settlement of birth</Trans>,
          gender: <Trans>Gender</Trans>,
          numident: <Trans>Numident</Trans>,
          deathDate: <Trans>Death date</Trans>
        }}
        data={{
          firstName: data.name,
          lastName: data.surname,
          secondName: data.patronymic,
          birthDate: data.dateBirth,
          birthCountry:
            data.birthState && data.birthState !== "null"
              ? data.birthState
              : "-",
          birthSettlement: birthPlace,
          gender: (
            <DictionaryValue
              name="GENDER"
              item={data.sex === "1" ? "MALE" : "FEMALE"}
            />
          ),
          numident: data.numident || "-",
          deathDate: data.dateDeath
        }}
        fontSize={13}
      />
      <Line />
    </>
  );
};

type DocumentsProps = {
  data: DracsDeathAct["docSeizes"];
};

const Documents = ({ data = [] }: DocumentsProps) => {
  if (!(data && data.length)) return null;

  return (
    <>
      <Heading fontSize="2" fontWeight="normal" mb={4} mt={4}>
        <Trans>Documents</Trans>
      </Heading>
      {data.map((document: any, index: number) => {
        const docType =
          document.docType === "1" ? (
            <DictionaryValue name="DOCUMENT_TYPE" item="PASSPORT" />
          ) : (
            document.docName
          );

        return (
          <DefinitionListView
            key={index}
            labelWidth={310}
            labels={{
              document: docType
            }}
            data={{
              document: (
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    <Trans>Number</Trans>
                  </Text>
                  {document.seriesNumb}
                  <Text fontWeight="bold" mb={1} mt={1}>
                    <Trans>Date of issue</Trans>
                  </Text>
                  {document.docDate}
                  <Text fontWeight="bold" mb={1} mt={1}>
                    <Trans>Issued by</Trans>
                  </Text>
                  {document.docOrgName}
                </Box>
              )
            }}
            fontSize={13}
          />
        );
      })}
      <Line />
    </>
  );
};

type AddressProps = {
  data: DracsDeathAct;
};

const Address = ({ data }: AddressProps) => (
  <>
    <Heading fontSize="2" fontWeight="normal" mb={4} mt={4}>
      <Trans>Address</Trans>
    </Heading>
    <DefinitionListView
      labelWidth={310}
      labels={{
        address: <Trans>Accommodation/location/stay</Trans>
      }}
      data={{
        address:
          data.region || data.district || data.street ? (
            <AddressView
              data={{
                region: {
                  name:
                    data.region === "Київ" || data.region === "Севастополь"
                      ? ""
                      : data.region
                },
                district: {
                  name: data.district
                },
                type: data.localityType || "",
                name:
                  data.locality ||
                  ((data.region === "Київ" || data.region === "Севастополь") &&
                    data.region) ||
                  "",
                streetType: "",
                street: data.street,
                building: data.house,
                apartment: data.apartment
              }}
            />
          ) : (
            "-"
          )
      }}
      fontSize={13}
    />
  </>
);

export default DracsDeathActPopup;

export const DracsDeathActQuery = gql`
  query DracsDeathAct($id: ID!) {
    dracsDeathAct(id: $id) {
      apartment
      arOpName
      arRegDate
      arRegNumber
      birthDistrict
      birthLocality
      birthLocalityType
      birthRegion
      birthState
      composeDate
      composeOrg
      databaseId
      dateBirth
      dateDeath
      district
      docSeizes {
        docDate
        docName
        docOrgName
        docType
        seriesNumb
      }
      house
      id
      isRestore
      locality
      localityType
      name
      numident
      opDate
      patronymic
      regNumb
      region
      sex
      state
      street
      surname
    }
  }
`;
