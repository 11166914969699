import * as React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Medication } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type DeactivateProps = {
  id: string;
  name: Medication["name"];
};

const Deactivate = ({ id, name }: DeactivateProps) => {
  const [isVisible, setVisibilityState] = React.useState(false);
  const toggle = () => setVisibilityState(!isVisible);

  return (
    <Ability action="deactivate" resource="medication">
      <Mutation mutation={DeactivateMedication}>
        {(deactivateMedication: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="red">
              <Trans>Deactivate</Trans>
            </Button>
            <Popup
              visible={isVisible}
              onCancel={toggle}
              title={
                <>
                  <Trans>Deactivate</Trans> "{name}
                  "?
                </>
              }
              okText={<Trans>Deactivate</Trans>}
              onOk={async () => {
                await deactivateMedication({
                  variables: {
                    input: {
                      id
                    }
                  }
                });
                toggle();
              }}
            />
          </>
        )}
      </Mutation>
    </Ability>
  );
};

export default Deactivate;

const DeactivateMedication = gql`
  mutation DeactivateMedication($input: DeactivateMedicationInput!) {
    deactivateMedication(input: $input) {
      medication {
        id
        isActive
      }
    }
  }
`;
