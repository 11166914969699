import React, { Fragment } from "react";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  CodeableConcept as TCodeableConcept,
  Maybe
} from "@edenlabllc/graphql-schema";

import CodeableConcept from "../../../components/CodeableConcept";

type CodeableConceptListProps = {
  codeableConceptList?: Maybe<Array<Maybe<TCodeableConcept>>>;
  withCode?: boolean;
};

const CodeableConceptList = ({
  codeableConceptList,
  withCode
}: CodeableConceptListProps) => {
  if (isEmpty(codeableConceptList)) return null;

  return (
    <>
      {codeableConceptList &&
        codeableConceptList.map((reason, index) => (
          <Fragment key={index}>
            <Box mt={index === 0 ? 0 : 4}>
              <CodeableConcept codeableConcept={reason} withCode={withCode} />
            </Box>
          </Fragment>
        ))}
    </>
  );
};

export default CodeableConceptList;
