export const getErrorCode = (error: $TSFixMe) =>
  error.graphQLErrors &&
  error.graphQLErrors[0].extensions &&
  error.graphQLErrors[0].extensions.code &&
  error.graphQLErrors[0].extensions.code;

export const getErrorMessage = (error: $TSFixMe) => {
  const graphQLInputError =
    error.graphQLErrors &&
    error.graphQLErrors[0].extensions.exception &&
    error.graphQLErrors[0].extensions.exception.inputErrors[0];
  if (graphQLInputError) {
    if (graphQLInputError.message.includes("{")) {
      let message = graphQLInputError.message;
      for (const key in graphQLInputError.options) {
        if (typeof graphQLInputError.options[key] === "string") {
          message = message.replace(`{${key}}`, graphQLInputError.options[key]);
        }
      }
      return message;
    }
    return graphQLInputError.message;
  }

  if (error.graphQLErrors && error.graphQLErrors[0].message) {
    return error.graphQLErrors[0].message;
  }

  return error.message;
};
