import React from "react";
import { Router, Link, RouteComponentProps } from "@reach/router";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Flex, Box } from "@rebass/emotion";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import {
  LocationParams,
  Form,
  Validation
} from "@edenlabllc/ehealth-components";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { CapitationContractRequest } from "@edenlabllc/graphql-schema";

import Badge from "../../../../../components/Badge";
import Button from "../../../../../components/Button";
import DefinitionListView from "../../../../../components/DefinitionListView";
import * as Field from "../../../../../components/Field";
import Line from "../../../../../components/Line";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import Steps from "../../../../../components/Steps";
import { SetSearchParams } from "../../../../../components/SearchForm";
import Tooltip from "../../../../../components/Tooltip";

import env from "../../../../../env";

import { CapitationContractRequestQuery } from "../";

const DeclineContractRequestMutation = loader(
  "../../../../../graphql/DeclineContractRequestMutation.graphql"
);

type DeclineProps = RouteComponentProps<{
  id: string;
}>;

const Decline = ({
  id,
  location: {
    // @ts-expect-error location state
    state: { base }
  }
}: DeclineProps) => {
  return (
    <>
      <Box pt={5} px={5}>
        <Steps.List>
          <Steps.Item to="./" state={{ base }}>
            <Trans>Fill in fields</Trans>
          </Steps.Item>
          <Steps.Item to="./sign" state={{ base }} disabled={!base}>
            <Trans>Confirm with EDS</Trans>
          </Steps.Item>
        </Steps.List>
      </Box>
      <LocationParams>
        {({ setLocationParams }: { setLocationParams: SetSearchParams }) => (
          <Query query={CapitationContractRequestQuery} variables={{ id }}>
            {({
              loading,
              data
            }: QueryResult<{
              capitationContractRequest: CapitationContractRequest;
            }>) => {
              if (isEmpty(data) || isEmpty(data.capitationContractRequest))
                return null;
              const {
                status,
                databaseId,
                contractorLegalEntity: {
                  databaseId: legalEntityId,
                  name,
                  edrpou
                }
              } = data.capitationContractRequest;

              return (
                <LoadingOverlay loading={loading}>
                  <Box m={5}>
                    <OpacityBox>
                      <DefinitionListView
                        labels={{
                          databaseId: <Trans>Contract request ID</Trans>,
                          status: <Trans>Status</Trans>,
                          edrpou: <Trans>EDRPOU</Trans>,
                          name: <Trans>Name</Trans>,
                          legalEntityId: <Trans>Legal entity ID</Trans>
                        }}
                        data={{
                          databaseId,
                          status: (
                            <Badge
                              name={status}
                              type="CONTRACT_REQUEST"
                              minWidth={100}
                            />
                          ),
                          edrpou,
                          name,
                          legalEntityId
                        }}
                        color="#7F8FA4"
                        labelWidth="100px"
                      />
                    </OpacityBox>
                    <Line />
                    <Router>
                      <Reason path="/" />
                      <Sign path="/sign" />
                    </Router>
                  </Box>
                </LoadingOverlay>
              );
            }}
          </Query>
        )}
      </LocationParams>
    </>
  );
};

const Reason = ({
  navigate,
  location: {
    // @ts-expect-error location state
    state: { base }
  }
}: RouteComponentProps) => {
  return (
    <Flex>
      <Box width={460} pt={2}>
        <Form
          onSubmit={async ({ base }: { base: string }) => {
            await navigate!("./sign", { state: { base } });
          }}
          initialValues={{ base }}
        >
          <Trans
            id="Enter decline reason"
            render={({ translation }) => (
              <Field.Textarea
                name="base"
                rows={6}
                label={<Trans id="Decline reason" />}
                placeholder={translation}
                maxLength={3000}
                showLengthHint
              />
            )}
          />
          <Validation.Required field="base" message="Required field" />
          <Flex>
            <Box mr={3}>
              <Link to="../" state={base}>
                <Button variant="blue">
                  <Trans>Return</Trans>
                </Button>
              </Link>
            </Box>
            <Button variant="green" state={base}>
              <Trans>Next</Trans>
            </Button>
          </Flex>
        </Form>
      </Box>
    </Flex>
  );
};

type SignProps = RouteComponentProps<{
  id: string;
}>;

const Sign = ({
  id,
  navigate,
  location: {
    // @ts-expect-error location state
    state: { base }
  }
}: SignProps) => (
  <Query query={CapitationContractRequestQuery} variables={{ id }}>
    {({
      data
    }: QueryResult<{
      capitationContractRequest: CapitationContractRequest;
    }>) => {
      const toDeclineContent =
        data && data.capitationContractRequest
          ? data.capitationContractRequest.toDeclineContent
          : {};

      return (
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{ width: 640, height: 589 }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation
              mutation={DeclineContractRequestMutation}
              refetchQueries={() => [
                {
                  query: CapitationContractRequestQuery,
                  variables: { id }
                }
              ]}
            >
              {(declineContractRequest: MutationFunction) => (
                <>
                  <DefinitionListView
                    labels={{
                      base: <Trans>Decline reason</Trans>
                    }}
                    data={{
                      base
                    }}
                    labelWidth="300px"
                    marginBetween={2}
                    flexDirection="column"
                  />
                  <Flex mt={5}>
                    <Box mr={3}>
                      <Link to="../" state={{ base }}>
                        <Button variant="blue">
                          <Trans>Return</Trans>
                        </Button>
                      </Link>
                    </Box>
                    <Tooltip
                      component={() => (
                        <Button
                          variant="green"
                          onClick={async () => {
                            const { signedContent } = await signData({
                              ...toDeclineContent,
                              status_reason: base
                            });
                            await declineContractRequest({
                              variables: {
                                input: {
                                  id,
                                  signedContent: {
                                    content: signedContent,
                                    encoding: "BASE64"
                                  }
                                }
                              }
                            });
                            await navigate!("../../");
                          }}
                        >
                          <Trans>Approve by EDS</Trans>
                        </Button>
                      )}
                      content={toDeclineContent && toDeclineContent.text}
                    />
                  </Flex>
                </>
              )}
            </Mutation>
          )}
        </Signer.Parent>
      );
    }}
  </Query>
);

const OpacityBox = system({
  extend: Box,
  opacity: 0.5
});

export default Decline;
