import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box, Flex, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { Form, Validation } from "@edenlabllc/ehealth-components";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  Person,
  PersonDracsDeathCandidateConnection
} from "@edenlabllc/graphql-schema";

import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Steps from "../../../components/Steps";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import { Verify } from "../../PersonsVerifications/Mutations/DRACS/Verify";
import { RejectVerify } from "../../PersonsVerifications/Mutations/DRACS/RejectVerify";
import GeneralInfoPatient from "../../PatientMergeRequests/Details/GeneralInfoPatient";
import DocumentsInfo from "../../PatientMergeRequests/Details/DocumentsInfo";
import AddressInfo from "../../PatientMergeRequests/Details/AddressInfo";
import DracsDeathActPopup from "./DracsDeathActPopup";
import { PersonAndDeathActComparisonPopup } from "./PersonAndDeathActComparisonPopup";

type ProcessDracsProps = RouteComponentProps<{
  id: string;
}>;

export const ProcessDracs = ({
  id,
  // @ts-expect-error location state
  location: { state, pathname }
}: ProcessDracsProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Query query={PersonQuery} variables={{ id }}>
      {({ loading, data }: QueryResult<{ person: Person }>) => {
        if (isEmpty(data) || isEmpty(data.person)) return null;
        const { databaseId, firstName, secondName, lastName } = data.person;

        const fullName = getFullName({
          firstName,
          secondName,
          lastName
        });

        return (
          <LoadingOverlay loading={loading}>
            <Box pt={5} px={5}>
              <Steps.List>
                <Steps.Item to="./" state={state}>
                  <Trans>Choose death act</Trans>
                </Steps.Item>
                <Steps.Item to="./confirm" state={state} disabled>
                  <Trans>Confirm data</Trans>
                </Steps.Item>
              </Steps.List>

              <DefinitionListView
                labels={{
                  fullName: <Trans>Full name</Trans>,
                  personId: <Trans>Patient ID</Trans>,
                  deathAct: <Trans>Death act</Trans>
                }}
                data={{
                  fullName,
                  personId: databaseId,
                  ...(state &&
                    !!state.deathAct && {
                      deathAct: (
                        <Button variant="link" onClick={toggle} px={0} py={0}>
                          {state.deathAct}
                        </Button>
                      )
                    })
                }}
                labelWidth="200px"
              />

              {state && !!state.deathAct && (
                <DracsDeathActPopup
                  deathActId={state.deathAct}
                  isPopupVisible={isPopupVisible}
                  toggle={toggle}
                />
              )}

              {!pathname.includes("confirm") && (
                <Text mt={5} fontSize={14}>
                  <Trans>
                    Select a match from the list to compare with the patient's
                    personal information
                  </Trans>
                </Text>
              )}
              <Line />

              <Router>
                <CandidatesList
                  path="/"
                  personId={databaseId}
                  person={data && data.person}
                />
                <Confirmation path="/confirm" />
              </Router>
            </Box>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

type CandidatesListProps = RouteComponentProps & {
  personId: string;
  person: Person;
};

const CandidatesList = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  personId,
  person
}: CandidatesListProps) => {
  const [isComparisonPopupVisible, setComparisonPopupVisible] = useState(false);
  const [activePopupDeatchActId, setActivePopupDeatchActId] = useState(null);

  return (
    <>
      <Query
        query={PersonDracsDeathCandidatesQuery}
        variables={{
          first: ITEMS_PER_PAGE[0],
          filter: {
            personId,
            status: "NEW"
          }
        }}
        fetchPolicy="network-only"
      >
        {({
          loading,
          data
        }: QueryResult<{
          personDracsDeathCandidates: PersonDracsDeathCandidateConnection;
        }>) => {
          if (isEmpty(data) || isEmpty(data.personDracsDeathCandidates))
            return null;
          const {
            personDracsDeathCandidates: {
              nodes: personDracsDeathCandidates = [],
              pageInfo
            }
          } = data;

          return (
            <LoadingOverlay loading={loading}>
              <Form
                initialValues={state}
                onSubmit={async (data: {
                  deathAct: { [id: string]: boolean };
                }) => {
                  const { deathAct } = data;

                  const deathActId = Object.keys(deathAct || {}).filter(
                    (key) => deathAct[key]
                  )[0];

                  navigate!("./confirm", {
                    state: {
                      ...state,
                      ...(!!deathActId && {
                        deathAct: deathActId
                      })
                    }
                  });
                }}
              >
                <Form.Spy>
                  {({ values: { deathAct } }: $TSFixMe) => {
                    const choosenCandidates =
                      deathAct &&
                      Object.keys(deathAct).filter((key) => deathAct[key]);

                    return (
                      <>
                        {personDracsDeathCandidates &&
                        personDracsDeathCandidates.length < 1 ? (
                          <EmptyData height="auto" />
                        ) : (
                          <>
                            <Table
                              hidePagination
                              hideControls
                              data={personDracsDeathCandidates}
                              tableName="personDracsDeathCandidates"
                              header={{
                                action: <Trans>Choose</Trans>,
                                candidateId: <Trans>Candidate ID</Trans>,
                                score: <Trans>Сoincidence</Trans>,
                                insertedAt: <Trans>Inserted at</Trans>,
                                updatedAt: <Trans>Updated at</Trans>,
                                details: <Trans>Details</Trans>
                              }}
                              renderRow={({
                                databaseId,
                                score,
                                insertedAt,
                                updatedAt,
                                deathAct
                              }) => {
                                return {
                                  action: (
                                    <Field.Checkbox
                                      name={`deathAct.${
                                        deathAct && deathAct.databaseId
                                      }`}
                                      disabled={
                                        choosenCandidates &&
                                        choosenCandidates[0] &&
                                        deathAct.databaseId !==
                                          choosenCandidates[0]
                                      }
                                    />
                                  ),
                                  candidateId: databaseId,
                                  score,
                                  insertedAt: dateFormatter(
                                    i18n.locale,
                                    {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric"
                                    },
                                    insertedAt
                                  ),
                                  updatedAt: dateFormatter(
                                    i18n.locale,
                                    {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric"
                                    },
                                    updatedAt
                                  ),
                                  details: (
                                    <Link
                                      to="./"
                                      onClick={() => {
                                        setActivePopupDeatchActId(deathAct.id);
                                        setComparisonPopupVisible(true);
                                      }}
                                      fontWeight="bold"
                                    >
                                      <Trans>Show details</Trans>
                                    </Link>
                                  )
                                };
                              }}
                            />
                            <Pagination pageInfo={pageInfo} />
                          </>
                        )}

                        <Flex pt={5} mb={100}>
                          <Box mr={3}>
                            <Button
                              type="reset"
                              variant="blue"
                              width={140}
                              onClick={() => navigate!("../", { state })}
                            >
                              <Trans>Back</Trans>
                            </Button>
                          </Box>
                          <Box>
                            <Button variant="green" width={140}>
                              <Trans>Next</Trans>
                            </Button>
                          </Box>
                        </Flex>

                        {isComparisonPopupVisible && (
                          <PersonAndDeathActComparisonPopup
                            isVisible={isComparisonPopupVisible}
                            onCancel={() => setComparisonPopupVisible(false)}
                            person={person}
                            deathActId={activePopupDeatchActId}
                          />
                        )}
                      </>
                    );
                  }}
                </Form.Spy>
              </Form>
            </LoadingOverlay>
          );
        }}
      </Query>
    </>
  );
};

type ConfirmationProps = RouteComponentProps<{
  id: string;
}>;

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  id
}: ConfirmationProps) => {
  const [isStartVerifyModalVisible, setVerifyModalVisible] = useState(false);
  const [isRejectVerifyModalVisible, setRejectVerifyModalVisible] =
    useState(false);
  const [isCommentRequired, setIsCommentRequired] = useState(false);

  return (
    <Form
      validate={(values: { verificationComment: string }) => {
        const errors: any = {};
        if (isCommentRequired && !values.verificationComment) {
          errors.verificationComment = "Required field";
        }
        return errors;
      }}
    >
      <Form.Spy>
        {({
          values: { verificationComment } = { verificationComment: undefined }
        }: $TSFixMe) => (
          <>
            <Flex>
              {!!state.deathAct ? <PositiveIcon /> : <NegativeIcon />}
              <Text ml={2} mb={5} fontSize={14}>
                <Trans>The death of the patient is confirmed</Trans>
              </Text>
            </Flex>
            <Box mb={2}>
              <Trans
                id="Enter reason comment"
                render={({ translation }) => (
                  <Field.Textarea
                    name="verificationComment"
                    label={<Trans id="Comment" />}
                    placeholder={translation}
                    rows={10}
                    maxLength={300}
                    showLengthHint
                  />
                )}
              />
              {isCommentRequired && (
                <Trans
                  id="Required field"
                  render={() => (
                    <Validation.Required
                      field="verificationComment"
                      message="Required field"
                    />
                  )}
                />
              )}
            </Box>

            <Flex mt={5} justifyContent="space-between">
              <Box mr={3}>
                <Button
                  type="reset"
                  variant="blue"
                  width={140}
                  onClick={() =>
                    navigate!("../", {
                      state: {
                        deathAct: undefined
                      }
                    })
                  }
                >
                  <Trans>Back</Trans>
                </Button>
              </Box>
              <Box>
                <Flex>
                  <Box mb={3}>
                    <Button
                      width={220}
                      variant="orange"
                      disabled={!!state.deathAct}
                      onClick={() => {
                        setIsCommentRequired(true);
                        if (verificationComment) {
                          setRejectVerifyModalVisible(true);
                        }
                      }}
                    >
                      <Trans>Not verify</Trans>
                    </Button>
                  </Box>
                  <Box mb={3} ml={2}>
                    <Button
                      width={220}
                      variant="green"
                      onClick={() => {
                        setIsCommentRequired(false);
                        setVerifyModalVisible(true);
                      }}
                    >
                      <Trans>Verify</Trans>
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            {isStartVerifyModalVisible && (
              <Verify
                isVisible={isStartVerifyModalVisible}
                onCancel={() => setVerifyModalVisible(false)}
                personId={id!}
                navigate={navigate!}
                verificationComment={verificationComment}
                deathAct={state.deathAct}
              />
            )}
            {isRejectVerifyModalVisible && (
              <RejectVerify
                isVisible={isRejectVerifyModalVisible}
                onCancel={() => setRejectVerifyModalVisible(false)}
                personId={id!}
                navigate={navigate!}
                verificationComment={verificationComment}
              />
            )}
          </>
        )}
      </Form.Spy>
    </Form>
  );
};

export const PersonQuery = gql`
  query PersonQuery($id: ID!) {
    person(id: $id) {
      id
      databaseId
      taxId
      ...GeneralInfoPatient
      ...DocumentsInfo
      ...AddressInfo
    }
  }
  ${GeneralInfoPatient.fragments.entry}
  ${DocumentsInfo.fragments.entry}
  ${AddressInfo.fragments.entry}
`;

const PersonDracsDeathCandidatesQuery = gql`
  query PersonDracsDeathCandidatesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: PersonDracsDeathCandidateFilter
    $orderBy: PersonDracsDeathCandidateOrderBy
  ) {
    personDracsDeathCandidates(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        status
        insertedAt
        updatedAt
        score
        deathAct {
          id
          databaseId
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;
