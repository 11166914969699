import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search/";
import Details from "./Details/";
import NestedDetails from "./NestedDetails";

const Dictionaries = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Search path="search/*" />
    <Details path=":name/*" />
    <NestedDetails path=":name/:parentId/values/*" />
  </Router>
);

export default Dictionaries;
