import React from "react";
import Composer from "react-composer";
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <SearchField.Medication
        name="filter.medication"
        filteredParams={["name"]}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.INNMDosage
        name="filter.medication.innmDosages"
        filteredParams={["name"]}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter manufacturer name"
        render={({ translation }) => (
          <Field.Text
            name="filter.medication.manufacturer.name"
            label={<Trans id="Manufacturer name" />}
            placeholder={translation}
          />
        )}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.databaseId"
              label={<Trans id="Medication ID" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.medication.innmDosages.databaseId"
              label={<Trans id="INNM Dosage ID" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ATC code"
          render={({ translation }) => (
            <Field.Text
              name="filter.medication.atcCode"
              label={<Trans id="ATC Code" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_M" />
      </Box>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="MEDICATION_FORM" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="All forms" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.medication.form"
              label={<Trans id="Medication form" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="FORM_PHARM_LIST" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="All pharmaceutical forms" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => {
            const extraOptions: any = { null: i18n._(t`Missing value`) };

            return (
              <Field.Select
                name="filter.medication.formPharm"
                label={<Trans id="Pharmaceutical form" />}
                placeholder={translation}
                items={Object.keys(dict)}
                extraHeaderItems={Object.keys(extraOptions)}
                itemToString={(item: string) =>
                  dict[item] || extraOptions[item] || translation
                }
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.medication.drlzSkuId"
              label={<Trans id="Product ID in DRLZ register" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
