import { cleanDeep } from "@edenlabllc/ehealth-utils";

import { Maybe } from "@edenlabllc/graphql-schema";

import { ITEMS_PER_PAGE } from "../constants/pagination";

export type TOrderBy =
  | "INSERTED_AT_ASC"
  | "INSERTED_AT_DESC"
  | "STATUS_ASC"
  | "STATUS_DESC"
  | "DRACS_DEATH_UNVERIFIED_AT_ASC"
  | "DRACS_DEATH_UNVERIFIED_AT_DESC";

export type PaginationType = {
  first?: string | null;
  last?: string | null;
  after?: Maybe<string>;
  before?: Maybe<string>;
  orderBy?: TOrderBy;
};

const pagination = ({ first, last, after, before, orderBy }: PaginationType) =>
  cleanDeep({
    first: !first && !last ? ITEMS_PER_PAGE[0] : first && parseInt(first),
    last: last && parseInt(last),
    after,
    before,
    orderBy
  });

export default pagination;
