import React, { useReducer, useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";
import Composer from "react-composer";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { EditIcon } from "@edenlabllc/ehealth-icons";
import { DeviceDefinition } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

import { handleTranslateErrors } from "./AddDeviceName";

type UpdateDeviceNameProps = {
  id: DeviceDefinition["id"];
  deviceNames: DeviceDefinition["deviceNames"];
  index: number;
};

export const UpdateDeviceName = ({
  id,
  deviceNames,
  index
}: UpdateDeviceNameProps) => {
  const [isVisible, toggle] = useReducer((v) => !v, false);
  const { i18n } = useLingui();
  const [error, setError] = useState<string | null>(null);

  return (
    <Ability action="write" resource="device_definition">
      <Mutation mutation={UpdateDeviceDefinitionMutation}>
        {(updateDeviceDefinition: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="link">
              <Flex alignItems="center">
                <Box mr={2}>
                  <EditIcon width="16" height="14" />
                </Box>
                <Trans>Edit</Trans>
              </Flex>
            </Button>

            <Popup
              visible={isVisible}
              onCancel={toggle}
              title={
                <>
                  <Trans>Update device definition names</Trans>
                </>
              }
              okText={<Trans>Update</Trans>}
              okButtonProps={{ variant: "green" }}
              justifyButtons="left"
              formId="updateDeviceDefinitionForm"
            >
              <Form
                id="updateDeviceDefinitionForm"
                initialValues={deviceNames[index]}
                onSubmit={async ({
                  type,
                  name
                }: {
                  type: string;
                  name: string;
                }) => {
                  setError(null);

                  const updateDeviceNameByIndex = (
                    index: number,
                    updatedType: string,
                    updatedName: string
                  ) => {
                    if (index < 0 || index >= deviceNames.length) {
                      console.error("Invalid index");
                      return deviceNames;
                    }

                    const data = deviceNames.map((item) => ({
                      name: item && item.name,
                      type: item && item.type
                    }));

                    data[index] = {
                      type: updatedType,
                      name: updatedName
                    };

                    return data;
                  };

                  const updatedDeviceNames = updateDeviceNameByIndex(
                    index,
                    type,
                    name
                  );

                  try {
                    const { data } = await updateDeviceDefinition({
                      variables: {
                        input: {
                          id,
                          deviceNames: updatedDeviceNames
                        }
                      }
                    });
                    if (data.updateDeviceDefinition) {
                      toggle();
                    }
                  } catch (error) {
                    if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                      const errorTranslation = handleTranslateErrors(
                        getErrorMessage(error),
                        i18n
                      );
                      setError(errorTranslation);
                    }
                  }
                }}
              >
                <Flex mx={-1}>
                  <Box width={1 / 2} px={1}>
                    <Composer
                      components={[<DictionaryValue name="device_name_type" />]}
                    >
                      {([dict]: [DictionaryAllValuesJson]) => {
                        const translation = i18n._(t`Select option`);
                        return (
                          <Field.Select
                            name="type"
                            label={<Trans id="Device definition name type" />}
                            placeholder={translation}
                            items={Object.keys(dict)}
                            itemToString={(item: string) =>
                              dict[item] || translation
                            }
                            variant="select"
                            emptyOption
                            filterOptions={{
                              keys: [(item: string) => dict[item]]
                            }}
                          />
                        );
                      }}
                    </Composer>
                    <Validation.Required
                      field="type"
                      message="Required field"
                    />
                  </Box>
                  <Box width={1 / 2} px={1}>
                    <Trans
                      id="Enter device definition name description"
                      render={({ translation }) => (
                        <Field.Text
                          name="name"
                          label={
                            <Trans id="Device definition name description" />
                          }
                          placeholder={translation}
                        />
                      )}
                    />
                    <Validation.Required
                      field="name"
                      message="Required field"
                    />
                  </Box>
                </Flex>
              </Form>
            </Popup>

            {error && (
              <UnpocessableEntityModalError errorMessage={error} isModalOpen />
            )}
          </>
        )}
      </Mutation>
    </Ability>
  );
};

const UpdateDeviceDefinitionMutation = gql`
  mutation UpdateDeviceDefinitionMutation(
    $input: UpdateDeviceDefinitionInput!
  ) {
    updateDeviceDefinition(input: $input) {
      deviceDefinition {
        id
        deviceNames {
          name
          type
        }
      }
    }
  }
`;
