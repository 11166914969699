import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import {
  Division,
  Equipment as EquipmentType
} from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import EmptyData from "../../../../components/EmptyData";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import {
  SearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import { ITEMS_PER_PAGE } from "../../../../constants/pagination";
import { FunctionComponentWithFragments } from "../../../../helpers/types";

import { DivisionQuery } from "./Details";

const prepareQueryVariables = ({
  divisionId,
  locationParams
}: {
  locationParams: SearchParams;
  divisionId?: string;
}) => {
  const { first, last, before, after, filter } = locationParams;
  const { equipmentFilter: formEquipmentFilter } = filter || {};
  const firstEquipment =
    !first && !last ? ITEMS_PER_PAGE[0] : first ? parseInt(first) : undefined;
  const lastEquipment = last ? parseInt(last) : undefined;
  const equipmentFilter = Object.assign(formEquipmentFilter || {}, {
    status: "ACTIVE"
  });

  return {
    lastEquipment,
    firstEquipment,
    equipmentFilter,
    id: divisionId,
    beforeEquipment: before,
    afterEquipment: after,
    firstHealthcareServices: ITEMS_PER_PAGE[0]
  };
};

type EquipmentProps = RouteComponentProps<{
  divisionId: string;
}>;

const Equipment: FunctionComponentWithFragments<EquipmentProps> = ({
  divisionId
}) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Form.AutoSubmit
              onSubmit={(values: SearchParams) => setLocationParams(values)}
            />
            <Flex pt={5}>
              <Box px={1} width={1 / 4}>
                <Composer
                  components={[
                    <DictionaryValue name="EQUIPMENT_TYPE" />,
                    ({
                      render
                    }: {
                      render: (props: TransRenderProps) => React.ReactElement;
                    }) => <Trans id="Select option" render={render} />
                  ]}
                >
                  {([dict, { translation }]: [
                    DictionaryAllValuesJson,
                    { translation: React.ReactNode }
                  ]) => (
                    <Field.Select
                      name="filter.equipmentFilter.type"
                      label={<Trans id="Type" />}
                      placeholder={translation}
                      items={Object.keys(dict)}
                      itemToString={(item: string) =>
                        dict[item] || String(translation)
                      }
                      emptyOption
                      filterOptions={{ keys: [(item: string) => dict[item]] }}
                    />
                  )}
                </Composer>
              </Box>
            </Flex>
          </Form>
          <Query
            query={DivisionQuery}
            variables={prepareQueryVariables({ divisionId, locationParams })}
          >
            {({ loading, data }: QueryResult<{ division: Division }>) => {
              if (isEmpty(data)) return null;
              const { nodes: equipment = [], pageInfo } =
                data.division.equipment || {};

              return (
                <LoadingOverlay loading={loading}>
                  {equipment!.length > 0 ? (
                    <>
                      <Table
                        data={equipment}
                        header={{
                          name: <Trans>Name</Trans>,
                          type: <Trans>Type</Trans>,
                          externalId: <Trans>External id</Trans>,
                          udi: <Trans>Other identifiers</Trans>,
                          serialNumber: <Trans>Serial number</Trans>
                        }}
                        renderRow={({
                          name,
                          type,
                          externalId,
                          udi,
                          serialNumber
                        }: EquipmentType) => ({
                          name,
                          externalId,
                          serialNumber,
                          udi: (
                            <Box>
                              {udi &&
                                udi.map((item, index) => (
                                  <p key={index}>
                                    {item &&
                                      `${item.value} ${item.type} ${item.assignerName}`}
                                  </p>
                                ))}
                            </Box>
                          ),
                          type: (
                            <DictionaryValue
                              name="EQUIPMENT_TYPE"
                              item={type}
                            />
                          )
                        })}
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

Equipment.fragments = {
  entry: gql`
    fragment Equipment on Division {
      equipment(
        filter: $equipmentFilter
        first: $firstEquipment
        before: $beforeEquipment
        after: $afterEquipment
        last: $lastEquipment
        orderBy: $equipmentOrderBy
      ) {
        nodes {
          id
          name
          type
          externalId
          serialNumber
          udi {
            value
            type
            assignerName
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${Pagination.fragments.entry}
  `
};

export default Equipment;
