import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { Dictionary, DictionaryValue } from "@edenlabllc/graphql-schema";
import { LocationParams } from "@edenlabllc/ehealth-components";

import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";
import Pagination from "../../../components/Pagination";
import { TLocationParams } from "../../../components/SearchForm";
import filteredLocationParams from "../../../helpers/filteredLocationParams";
import { ChildValues } from "./ChildValues";

import { UpdateDictionaryValueDescription } from "./Mutations/UpdateDictionaryValueDescription";
import DeactivateValue from "./Mutations/DeactivateValue";
import ActivateValue from "./Mutations/ActivateValue";
import Ability from "../../../components/Ability";

type NestedDetailsProps = RouteComponentProps<{
  name: Dictionary["name"];
  parentId: DictionaryValue["parentId"];
}>;

const NestedDetails = ({ name, parentId }: NestedDetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Query
        query={DictionaryValueQuery}
        variables={{
          id: parentId,
          ...filteredLocationParams(locationParams)
        }}
      >
        {({
          loading,
          data,
          refetch
        }: QueryResult<{ dictionaryValue: DictionaryValue }>) => {
          if (isEmpty(data) || isEmpty(data.dictionaryValue)) return null;

          const {
            id,
            databaseId,
            dictionaryId,
            code,
            description,
            childValues,
            isActive,
            parent
          } = data.dictionaryValue;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/dictionaries">
                      <Trans>Dictionaries</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item to={`/dictionaries/${name}`}>
                      <Trans>Editing the dictionary</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Viewing a dictionary value</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <Flex justifyContent="space-between">
                  <Box>
                    {name ? (
                      <Box mb={20}>
                        <Text>{decodeURIComponent(name)}</Text>
                      </Box>
                    ) : (
                      ""
                    )}

                    <DefinitionListView
                      labels={{
                        databaseId: <Trans>ID</Trans>,
                        code: <Trans>Key</Trans>,
                        status: <Trans>Status</Trans>,
                        description: <Trans>Description</Trans>
                      }}
                      data={{
                        databaseId,
                        code: (
                          <Breadcrumbs.List>
                            {parent && parent.parent && parent.parent.id && (
                              <Breadcrumbs.Item
                                to={`../../${parent.parent.id}/values`}
                              >
                                {parent.parent.code}
                              </Breadcrumbs.Item>
                            )}
                            {parent && parent.id && (
                              <Breadcrumbs.Item
                                to={`../../${parent.id}/values`}
                              >
                                {parent.code}
                              </Breadcrumbs.Item>
                            )}
                            <Breadcrumbs.Item>
                              {code ? decodeURIComponent(code) : ""}
                            </Breadcrumbs.Item>
                          </Breadcrumbs.List>
                        ),
                        status: isActive ? (
                          <Flex alignItems="center">
                            <PositiveIcon />
                            <Text ml={2}>
                              <Trans>Active</Trans>
                            </Text>
                          </Flex>
                        ) : (
                          <Flex alignItems="center">
                            <NegativeIcon />
                            <Text ml={2}>
                              <Trans>Inactive</Trans>
                            </Text>
                          </Flex>
                        ),
                        description: (
                          <Flex alignItems="center">
                            <Box mr={2}>{description || "-"}</Box>
                            <Ability action="write" resource="dictionary">
                              <UpdateDictionaryValueDescription
                                id={id}
                                description={description}
                              />
                            </Ability>
                          </Flex>
                        )
                      }}
                      labelWidth="100px"
                    />
                  </Box>

                  <Ability action="write" resource="dictionary">
                    <Box ml={2}>
                      {isActive ? (
                        <DeactivateValue id={id} />
                      ) : (
                        <ActivateValue id={id} />
                      )}
                    </Box>
                  </Ability>
                </Flex>
              </Box>

              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>Nested values</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <ChildValues
                    path="/"
                    parentId={id}
                    dictionaryId={dictionaryId}
                    childValues={childValues ? childValues.nodes : undefined}
                    pageInfo={childValues ? childValues.pageInfo : undefined}
                    isActive={isActive}
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                    refetch={refetch}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

const DictionaryValueQuery = gql`
  query DictionaryValueQuery(
    $after: String
    $before: String
    $id: ID!
    $filter: DictionaryValueFilter
    $first: Int
    $last: Int
  ) {
    dictionaryValue(id: $id) {
      id
      dictionaryId
      databaseId
      parentId
      parent {
        id
        code
        parent {
          id
          code
        }
      }
      code
      isActive
      description
      childValues(
        first: $first
        last: $last
        before: $before
        after: $after
        filter: $filter
      ) {
        nodes {
          id
          databaseId
          code
          description
          isActive
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export default NestedDetails;
