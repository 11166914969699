import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Composer from "react-composer";
import { TransRenderProps } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import { isEmpty, debounce } from "lodash";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";
import { LegalEntity, LegalEntityConnection } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";

import {
  UUID_PATTERN,
  CYRILLIC_NAME,
  EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN
} from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <>
        <Trans
          id="Enter full name"
          render={({ translation }) => (
            <Field.Text
              name="filter.party.fullName"
              label={<Trans id="Full name" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
        <Validation.Matches
          field="filter.party.fullName"
          options={CYRILLIC_NAME}
          message="Invalid name"
        />
      </>
    </Box>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter legal entity name"
        render={({ translation }) => (
          <Query
            query={SearchLegalEntitiesQuery}
            fetchPolicy="cache-first"
            variables={{
              skip: true
            }}
          >
            {({
              data,
              refetch: refetchLegalEntities
            }: QueryResult<{ legalEntities: LegalEntityConnection }>) => {
              const legalEntities =
                data && data.legalEntities ? data.legalEntities.nodes : [];

              return (
                <Field.Select
                  name="filter.legalEntity.edrData.name"
                  label={<Trans id="Legal entity name" />}
                  placeholder={translation}
                  items={legalEntities!.map(
                    (legalEntity) =>
                      legalEntity &&
                      legalEntity.edrData &&
                      legalEntity.edrData.name
                  )}
                  filter={(items: LegalEntity[]) => items}
                  onInputValueChange={debounce(
                    (name, { selectedItem, inputValue }) =>
                      !isEmpty(name) &&
                      selectedItem !== inputValue &&
                      refetchLegalEntities({
                        skip: false,
                        first: 20,
                        filter: { edrData: { name } }
                      }),
                    1000
                  )}
                />
              );
            }}
          </Query>
        )}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter division name"
        render={({ translation }) => (
          <Field.Text
            name="filter.division.name"
            label={<Trans id="Division name" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <>
          <Trans
            id="Enter division ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.division.databaseId"
                label={<Trans id="Division ID" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
              />
            )}
          />
          <Validation.Matches
            field="filter.division.databaseId"
            options={UUID_PATTERN}
            message="Invalid ID"
          />
        </>
      </Box>
      <Box px={1} width={1 / 3}>
        <>
          <Trans
            id="Enter legal entity EDRPOU"
            render={({ translation }) => (
              <Field.Text
                name="filter.legalEntity.edrpou"
                label={<Trans id="Legal entity edrpou" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
                maxLength={10}
              />
            )}
          />
          <Validation.Matches
            field="filter.legalEntity.edrpou"
            options={EDRPOU_OR_PASSPORT_OR_LEGAL_ENTITY_ID_PATTERN}
            message="Invalid number"
          />
        </>
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="EMPLOYEE_TYPE" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.employeeType"
              label={<Trans id="Employee type" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="POSITION" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.position"
              label={<Trans id="Position" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 3}>
        <Field.RangePicker
          rangeNames={["filter.startDate.from", "filter.startDate.to"]}
          label={<Trans id="Start date" />}
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Composer
          components={[
            <DictionaryValue name="EMPLOYEE_STATUS" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.employeeStatus"
              label={<Trans id="Employee status" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item: string) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
    </Flex>
  </>
);

const SearchLegalEntitiesQuery = gql`
  query SearchLegalEntitiesQuery(
    $filter: LegalEntityFilter
    $first: Int
    $skip: Boolean = false
  ) {
    legalEntities(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        edrData {
          name
        }
      }
    }
  }
`;

export { PrimarySearchFields, SecondarySearchFields };
