import React, { useMemo, useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex, Text } from "@rebass/emotion";
import { sortBy, slice, isEmpty } from "lodash";

import { RemoveItemIcon, SearchIcon } from "@edenlabllc/ehealth-icons";
import { Form, LocationParams, Modal } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import {
  Dictionary,
  DiagnosesGroup,
  DictionaryValue,
  Maybe
} from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Link from "../../../../components/Link";
import * as SearchField from "../../../../components/SearchField";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import { ITEMS_PER_PAGE } from "../../../../constants/pagination";
import {
  DictionaryItem,
  convertDictionaryValuesArrayToObject
} from "../../../../components/DictionaryValue";

type DiagnosesModalFormProps = RouteComponentProps<{
  codeId: string;
}>;

const DiagnosesModalForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  codeId
}: DiagnosesModalFormProps) => {
  const onClose = useCallback(
    () => navigate!("../", { state }),
    [navigate, state]
  );
  const initDictSystem =
    state.diagnoses[codeId] && state.diagnoses[codeId].dictionaryName
      ? state.diagnoses[codeId].dictionaryName
      : "eHealth/ICD10_AM/condition_codes";

  return (
    <LocationParams state={state}>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Modal backdrop width="90%">
          <Close onClick={onClose} />
          <Form
            onSubmit={(data: Partial<DiagnosesGroup> & { system: string }) => {
              const { code, system, name } = data;
              setLocationParams({ code, system, name });
            }}
            initialValues={{
              ...state,
              system: initDictSystem,
              ...locationParams
            }}
          >
            <Box width={1 / 2}>
              <SearchField.DiagnosesGroupSystem name="system" />
            </Box>
            <Flex>
              <Box width={1 / 2}>
                <Trans
                  id="Enter code"
                  render={({ translation }) => (
                    <Field.Text
                      name="code"
                      label={<Trans id="Code" />}
                      placeholder={translation}
                      postfix={<SearchIcon color="silverCity" />}
                    />
                  )}
                />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Trans
                  id="Enter name"
                  render={({ translation }) => (
                    <Field.Text
                      name="name"
                      label={<Trans id="Name" />}
                      placeholder={translation}
                      postfix={<SearchIcon color="silverCity" />}
                    />
                  )}
                />
              </Box>
            </Flex>
            <Flex mx={-1} mt={4} justifyContent="flex-start">
              <Box px={1}>
                <Button variant="red" type="reset" onClick={onClose}>
                  <Trans>Close</Trans>
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="blue">
                  <Trans>Search</Trans>
                </Button>
              </Box>
            </Flex>
          </Form>
          <Query
            query={DictionaryByNameQuery}
            variables={{
              dictionaryName: locationParams.system || initDictSystem
            }}
          >
            {({ data }: QueryResult<{ dictionaryByName: Dictionary }>) => {
              if (isEmpty(data) || isEmpty(data.dictionaryByName)) return null;

              return (
                <DictionaryTable
                  dictionary={data.dictionaryByName}
                  state={state}
                  elementIndex={codeId}
                  locationParams={locationParams}
                  setLocationParams={setLocationParams}
                />
              );
            }}
          </Query>
        </Modal>
      )}
    </LocationParams>
  );
};

export default DiagnosesModalForm;

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

export const DictionaryByNameQuery = gql`
  query DictionaryByName($dictionaryName: String!) {
    dictionaryByName(dictionaryName: $dictionaryName) {
      id
      name
      allValues {
        code
        description
      }
    }
  }
`;

type DiagnosesType = {
  name: string;
  code: string;
  system?: string;
};

type DictionaryTableProps = {
  dictionary: Dictionary;
  state: {
    diagnoses: DiagnosesType[];
  };
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  elementIndex?: string;
};

const DictionaryTable = ({
  dictionary,
  state,
  elementIndex,
  locationParams,
  setLocationParams
}: DictionaryTableProps) => {
  const { code, name } = locationParams;

  const { name: dictionaryName } = dictionary;

  const foundDictionaryValues = useMemo(() => {
    const allValues = convertDictionaryValuesArrayToObject(
      dictionary.allValues
    );

    const dictionaryValues = Object.entries(allValues || []).map(
      ([code, name]) => {
        return { code, name };
      }
    );

    const sortedByCodeValues = sortBy(dictionaryValues, [
      "code"
    ]) as DiagnosesType[];

    return sortedByCodeValues.filter((item: DiagnosesType) => {
      return (
        item.code.startsWith(code ? code.toUpperCase() : "") &&
        item.name.toLowerCase().includes(name ? name.toLowerCase() : "")
      );
    });
  }, [dictionary, code, name]);
  const first = locationParams.firstDictionary
    ? parseInt(locationParams.firstDictionary)
    : ITEMS_PER_PAGE[0];

  const tableData = useMemo(
    () => slice(foundDictionaryValues, 0, first),
    [first, foundDictionaryValues]
  );

  return (
    <>
      <Table
        data={tableData}
        hidePagination
        header={{
          code: <Trans>Code</Trans>,
          name: <Trans>Name</Trans>,
          dictionaryName: <Trans>Dictionary name</Trans>,
          action: <Trans>Action</Trans>
        }}
        renderRow={({ code, name }: DiagnosesType) => {
          const { diagnoses } = state;

          const data = {
            ...state,
            diagnoses: diagnoses.map((item: DiagnosesType, index: number) => {
              if (elementIndex && index === parseInt(elementIndex))
                return { code, system: dictionaryName, name };
              else return item;
            })
          };

          const isSelected = diagnoses.find(
            (item: DiagnosesType) =>
              item && item.code === code && item.system === dictionaryName
          );

          return {
            code,
            name,
            dictionaryName,
            action: isSelected ? (
              <Text fontWeight="700" color="rgba(46, 162, 248, 0.5)">
                <Trans>Chosen</Trans>
              </Text>
            ) : (
              <Link to={`../`} state={data} fontWeight="bold">
                <Trans>Select</Trans>
              </Link>
            )
          };
        }}
      />
      {foundDictionaryValues.length > first && (
        <Box mt={4}>
          <Button
            variant="blue"
            onClick={() =>
              setLocationParams({
                ...locationParams,
                firstDictionary: String(first + ITEMS_PER_PAGE[0])
              })
            }
          >
            <Trans>Load more</Trans>
          </Button>
        </Box>
      )}
    </>
  );
};
