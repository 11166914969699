import * as React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  ForbiddenGroup,
  ForbiddenGroupConnection
} from "@edenlabllc/graphql-schema";

import Link from "../../../components/Link";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type ForbiddenGroupsTableProps = {
  forbiddenGroups: ForbiddenGroupConnection["nodes"];
  tableName?: string;
};

const ForbiddenGroupsTable = ({
  forbiddenGroups,
  tableName = "forbiddenGroups/search"
}: ForbiddenGroupsTableProps) => (
  <>
    <Table
      data={forbiddenGroups}
      header={{
        databaseId: <Trans>ID</Trans>,
        name: <Trans>Forbidden group name</Trans>,
        isActive: <Trans>Status</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>,
        action: <Trans>Action</Trans>
      }}
      renderRow={({
        databaseId,
        name,
        isActive,
        insertedAt,
        updatedAt,
        id
      }: ForbiddenGroup) => ({
        databaseId,
        name,
        isActive: (
          <Flex justifyContent="center">
            {isActive ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
        action: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>View</Trans>
          </Link>
        )
      })}
      tableName={tableName}
      hiddenFields="updatedAt"
    />
  </>
);

ForbiddenGroupsTable.fragments = {
  entry: gql`
    fragment ForbiddenGroups on ForbiddenGroup {
      id
      databaseId
      name
      isActive
      insertedAt
      updatedAt
    }
  `
};

export default ForbiddenGroupsTable;
