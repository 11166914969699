import * as React from "react";
import { gql } from "graphql-tag";
import { Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  EmployeeRequest,
  EmployeeRequestConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import FullName from "../../../components/FullName";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

type EmployeeRequestsTableProps = {
  employeeRequests: EmployeeRequestConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const EmployeeRequestsTable = ({
  employeeRequests,
  locationParams,
  setLocationParams
}: EmployeeRequestsTableProps) => (
  <Table
    data={employeeRequests}
    header={{
      databaseId: <Trans>ID</Trans>,
      fullName: <Trans>Name of employee</Trans>,
      taxId: <Trans>INN</Trans>,
      noTaxId: <Trans>No tax ID</Trans>,
      employeeType: <Trans>Employee type</Trans>,
      legalEntityName: <Trans>Legal entity name</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      email: <Trans>Email</Trans>,
      status: <Trans>Status</Trans>
    }}
    renderRow={({
      firstName,
      secondName,
      lastName,
      noTaxId,
      employeeType,
      legalEntity,
      status,
      insertedAt,
      ...employeeData
    }: EmployeeRequest) => {
      const { edrData } = legalEntity || {};
      return {
        ...employeeData,
        fullName: (
          <FullName
            party={{
              firstName,
              secondName,
              lastName
            }}
          />
        ),
        noTaxId: (
          <Flex justifyContent="center">
            {noTaxId ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        employeeType: (
          <DictionaryValue name="EMPLOYEE_TYPE" item={employeeType} />
        ),
        legalEntityName: edrData && edrData.name,
        status: (
          <Badge name={status} type="EMPLOYEE_REQUEST_STATUS" display="block" />
        ),
        insertedAt: i18n.date(insertedAt)
      };
    }}
    sortableFields={["fullName", "insertedAt", "status"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="employee-requests/search"
  />
);

EmployeeRequestsTable.fragments = {
  entry: gql`
    fragment EmployeeRequests on EmployeeRequest {
      id
      databaseId
      email
      employeeType
      firstName
      secondName
      lastName
      noTaxId
      taxId
      status
      insertedAt
      legalEntity {
        id
        name
        edrData {
          name
        }
      }
    }
  `
};

export default EmployeeRequestsTable;
