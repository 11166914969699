import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import { LegalEntity } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";

type EDRDataProps = RouteComponentProps<{
  edrData: LegalEntity["edrData"];
}>;

const EDRData = ({ edrData }: EDRDataProps) => {
  if (isEmpty(edrData)) return <EmptyData />;
  const {
    edrId,
    edrpou,
    insertedAt,
    isActive,
    kveds,
    legalForm,
    registrationAddress,
    state,
    updatedAt
  } = edrData;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          registrationAddress: <Trans>Registration address</Trans>
        }}
        data={{
          edrpou,
          registrationAddress:
            registrationAddress && registrationAddress.address
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          legalForm: <Trans>Form of managment</Trans>,
          kveds: <Trans>KVED</Trans>
        }}
        data={{
          legalForm: <DictionaryValue name="LEGAL_FORM" item={legalForm} />,
          kveds: (
            <DictionaryValue
              name="KVEDS"
              render={(dict: DictionaryAllValuesJson) => (
                <>
                  {kveds.map((kved, key, arr) => (
                    <React.Fragment key={key}>
                      {kved && ((kved.code && dict[kved.code]) || kved.code)}
                      {key !== arr.length - 1 && ", "}
                    </React.Fragment>
                  ))}
                </>
              )}
            />
          )
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          edrId: <Trans>EDR ID</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>,
          state: <Trans>Legal entity state</Trans>,
          isActive: <Trans>Is EDR data actual</Trans>
        }}
        data={{
          edrId,
          insertedAt: i18n.date(insertedAt),
          updatedAt: i18n.date(updatedAt),
          state: <DictionaryValue name="EDR_STATE" item={state} />,
          isActive: isActive ? <PositiveIcon /> : <NegativeIcon />
        }}
      />
    </Box>
  );
};

EDRData.fragments = {
  entry: gql`
    fragment LegalEntityEDRData on LegalEntity {
      edrData {
        databaseId
        edrId
        edrpou
        insertedAt
        isActive
        kveds {
          code
          isPrimary
        }
        legalForm
        name
        publicName
        registrationAddress {
          address
        }
        shortName
        state
        updatedAt
      }
    }
  `
};

export default EDRData;
