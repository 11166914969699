import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import { Service, ServiceConnection } from "@edenlabllc/graphql-schema";

import * as Field from "../Field";

type SearchServiceFieldProps = {
  name: string;
};

const SearchServiceField = ({ name }: SearchServiceFieldProps) => (
  <Trans
    id="Choose service"
    render={({ translation }) => (
      <Query
        query={GetServicesQuery}
        fetchPolicy="cache-first"
        variables={{
          skip: true
        }}
      >
        {({
          data,
          refetch: refetchServices
        }: QueryResult<{ services: ServiceConnection }>) => {
          const { services: { nodes: services = [] } = {} } = data || {};
          const itemToString = (item: {
            id: string;
            databaseId: string;
            name: string;
            code: string;
          }) => item && `${item.name} (${item.code})`;

          return (
            <Field.Select
              name={name}
              label={<Trans id="Service name" />}
              placeholder={translation}
              items={services!.map((service) => {
                const { id, databaseId, name, code } = service || {};
                return {
                  id,
                  databaseId,
                  name,
                  code
                };
              })}
              itemToString={itemToString}
              filter={(services: Service[]) => services}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  itemToString(selectedItem) !== inputValue &&
                  refetchServices({
                    skip: false,
                    first: 20,
                    filter: {
                      name,
                      isActive: true
                    }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const GetServicesQuery = gql`
  query GetServicesQuery(
    $first: Int
    $filter: ServiceFilter
    $skip: Boolean! = false
  ) {
    services(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        name
        code
      }
    }
  }
`;

export default SearchServiceField;
