import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

export const PROPERTIES_BOOLEAN_VALUES: { [key: string]: any } = {
  true: i18n._(t`Yes`),
  false: i18n._(t`No`)
};

export const DEVICE_DEFINITIONS_PROPERTIES_VALUE_TYPES: {
  [key: string]: any;
} = {
  value_integer: t`Integer`,
  value_decimal: t`Decimal`,
  value_boolean: t`Boolean`,
  value_string: t`String`
};
