import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { GlobalBudgetContractConnection } from "@edenlabllc/graphql-schema";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import SearchForm, { TLocationParams } from "../../../../components/SearchForm";
import Pagination from "../../../../components/Pagination";

import contractFormFilteredParams from "../../../../helpers/contractFormFilteredParams";
import pagination from "../../../../helpers/pagination";
import { CONTRACT_TYPES } from "../../../../constants/contracts";

import ContractsNav from "../../ContractsNav";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";
import GlobalBudgetTable from "./GlobalBudgetTable";

const GlobalBudgetContractsSearch = (_props: RouteComponentProps) => (
  <Box p={6}>
    <ContractsNav />
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { filter, first, last, after, before, orderBy } = locationParams;

        return (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              query={SearchGlobalBudgetContractsQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination({ first, last, after, before, orderBy }),
                filter: contractFormFilteredParams(
                  filter,
                  CONTRACT_TYPES.GLOBAL_BUDGET
                )
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{
                globalBudgetContracts: GlobalBudgetContractConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.globalBudgetContracts))
                  return null;
                const {
                  globalBudgetContracts: {
                    nodes: globalBudgetContracts = [],
                    pageInfo
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <>
                      <GlobalBudgetTable
                        globalBudgetContracts={globalBudgetContracts}
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export default GlobalBudgetContractsSearch;

const SearchGlobalBudgetContractsQuery = gql`
  query SearchGlobalBudgetContractsQuery(
    $filter: GlobalBudgetContractFilter
    $orderBy: GlobalBudgetContractOrderBy
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    globalBudgetContracts(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...GlobalBudgetContracts
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${GlobalBudgetTable.fragments.entry}
  ${Pagination.fragments.entry}
`;
