import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import isEmpty from "lodash/isEmpty";

import {
  Maybe,
  MedicationRegistryJob,
  MedicationRegistryTask
} from "@edenlabllc/graphql-schema";

import EmptyData from "../../../components/EmptyData";
import TasksTable from "../../../components/TasksTable";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  SetSearchParams
} from "../../../components/SearchForm";

import { PrimarySearchFields } from "./SearchFields";

const CREATE_MEDICATION_DATA_VALUE = "Create medication data";
const getTaskNameValue = (taskName: string) => {
  const data: { [key: string]: string } = {
    "Create medication data": i18n._(t`Create medication data`),
    "Update program medication": i18n._(t`Update program medication`),
    "Deactivate innm dosage": i18n._(t`Deactivate innm dosage`),
    "Deactivate brand": i18n._(t`Deactivate brand`),
    "Deactivate program medication": i18n._(t`Deactivate program medication`)
  };
  return data[taskName];
};

type TasksProps = RouteComponentProps & {
  tasks: MedicationRegistryJob["tasks"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const Tasks = ({ tasks, locationParams, setLocationParams }: TasksProps) => {
  const { nodes = [], pageInfo } = tasks || {};

  return (
    <>
      <SearchForm
        initialValues={locationParams}
        onSubmit={setLocationParams}
        renderPrimary={PrimarySearchFields}
      />
      {isEmpty(nodes) ? (
        <EmptyData my={5} mx={1} />
      ) : (
        <>
          <TasksTable
            tasks={nodes}
            locationParams={locationParams}
            setLocationParams={setLocationParams}
            taskName={({
              name,
              meta
            }: {
              name?: Maybe<string>;
              meta?: MedicationRegistryTask["meta"];
            }) => {
              const { databaseId, csvDataLine } = meta || {};
              return (
                <>
                  {name && getTaskNameValue(name)}
                  &nbsp;
                  {name === CREATE_MEDICATION_DATA_VALUE
                    ? csvDataLine
                    : databaseId}
                </>
              );
            }}
            tableName="medication-registry-job/tasks"
          />
          <Pagination pageInfo={pageInfo} />
        </>
      )}
    </>
  );
};

Tasks.fragments = {
  entry: gql`
    fragment MedicationRegistryTasks on MedicationRegistryTask {
      id
      databaseId
      name
      status
      insertedAt
      endedAt
      updatedAt
      meta {
        databaseId
        csvDataLine
      }
      error {
        message
      }
    }
  `
};

export default Tasks;
