import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { Link } from "@edenlabllc/ehealth-components";
import { DeviceDefinition } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";

import paramToBase64 from "../../../helpers/paramToBase64";

type GeneralInfoProps = RouteComponentProps & {
  externalId: DeviceDefinition["externalId"];
  description: DeviceDefinition["description"];
  partNumber: DeviceDefinition["partNumber"];
  classificationType: DeviceDefinition["classificationType"];
  modelNumber: DeviceDefinition["modelNumber"];
  manufacturerName: DeviceDefinition["manufacturerName"];
  manufacturerCountry: DeviceDefinition["manufacturerCountry"];
  parentId: DeviceDefinition["parentId"];
  note: DeviceDefinition["note"];
};

const GeneralInfo = ({
  externalId,
  description,
  partNumber,
  classificationType,
  modelNumber,
  manufacturerName,
  manufacturerCountry,
  parentId,
  note
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labelWidth={220}
      labels={{
        externalId: <Trans>External ID</Trans>,
        ...(description && {
          description: <Trans>Device definition description</Trans>
        }),
        ...(partNumber && {
          partNumber: <Trans>Device definition part number</Trans>
        }),
        classificationType: (
          <Trans>Device definition classification type</Trans>
        ),
        modelNumber: <Trans>Device definition model number</Trans>,
        manufacturerName: <Trans>Device definition manufacturer name</Trans>,
        manufacturerCountry: (
          <Trans>Device definition manufacturer country</Trans>
        ),
        ...(parentId && {
          parentId: <Trans>Device definition parent ID</Trans>
        }),
        ...(note && {
          note: <Trans>Comment</Trans>
        })
      }}
      data={{
        externalId,
        description,
        partNumber,
        classificationType: (
          <DictionaryValue
            name="device_definition_classification_type"
            item={classificationType}
          />
        ),
        modelNumber,
        manufacturerName,
        manufacturerCountry: (
          <DictionaryValue name="COUNTRY" item={manufacturerCountry} />
        ),
        parentId: (
          <Link
            to={`/device-definitions/${paramToBase64("DeviceDefinition", parentId)}`}
          >
            {parentId}
          </Link>
        ),
        note
      }}
    />
  </Box>
);

GeneralInfo.fragments = {
  entry: gql`
    fragment DeviceDefinitionGeneralInfo on DeviceDefinition {
      externalId
      description
      partNumber
      classificationType
      modelNumber
      manufacturerName
      manufacturerCountry
      parentId
      note
    }
  `
};

export default GeneralInfo;
