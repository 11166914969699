import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import createDecorator from "final-form-calculate";
import { Flex, Box } from "@rebass/emotion";

import { convertStringToBoolean, cleanDeep } from "@edenlabllc/ehealth-utils";
import {
  Form,
  Validation,
  Field as FieldListener
} from "@edenlabllc/ehealth-components";
import {
  MedicalProgram,
  Service,
  ServiceGroup
} from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";
import * as SearchField from "../../../../components/SearchField";
import { SearchParams } from "../../../../components/SearchForm";

import STATUSES from "../../../../helpers/statuses";

type CreateProgramServiceProps = {
  locationParams: SearchParams;
  searchProgramServicesQuery: DocumentNode;
};

const CreateProgramService = ({
  searchProgramServicesQuery,
  locationParams
}: CreateProgramServiceProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Box>
      <Mutation
        mutation={CreateProgramServiceMutation}
        refetchQueries={() => [
          {
            query: searchProgramServicesQuery,
            variables: locationParams
          }
        ]}
      >
        {(createProgramService: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="green">
              <Trans>Create program service</Trans>
            </Button>
            <Popup
              visible={isPopupVisible}
              onCancel={toggle}
              title={<Trans>Create program service</Trans>}
              okButtonProps={{ variant: "green" }}
              justifyButtons="left"
              formId="createProgramService"
            >
              <Form
                id="createProgramService"
                onSubmit={async ({
                  medicalProgram,
                  consumerPrice,
                  isRequestAllowed,
                  serviceId,
                  serviceGroupId,
                  description
                }: {
                  medicalProgram: MedicalProgram;
                  consumerPrice: string;
                  isRequestAllowed: string;
                  serviceId: Service;
                  serviceGroupId: ServiceGroup;
                  description: string;
                }) => {
                  const requestAllowed =
                    convertStringToBoolean(isRequestAllowed);
                  const price = parseInt(consumerPrice);

                  const createProgramServiceInput = cleanDeep({
                    medicalProgramId: medicalProgram && medicalProgram.id,
                    consumerPrice: price,
                    requestAllowed,
                    serviceId: serviceId && serviceId.id,
                    serviceGroupId: serviceGroupId && serviceGroupId.id,
                    description
                  });

                  await createProgramService({
                    variables: {
                      input: createProgramServiceInput
                    }
                  });
                  toggle();
                }}
                decorators={[resetServiceConnection]}
              >
                <Flex mx={-1}>
                  <Box px={1} width={1 / 2}>
                    <SearchField.MedicalProgram
                      name="medicalProgram"
                      filteredParams={["name", "id"]}
                      additionalFilters={{ isActive: true, type: "SERVICE" }}
                    />
                    <Validation.Required
                      field="medicalProgram"
                      message="Required field"
                    />
                  </Box>
                  <Box px={1} width={1 / 2}>
                    <Trans
                      id="Select option"
                      render={({ translation }) => (
                        <Field.Select
                          name="isRequestAllowed"
                          label={<Trans id="Is request allowed" />}
                          items={Object.keys(STATUSES.YES_NO)}
                          itemToString={(item: boolean) =>
                            // @ts-expect-error statuses boolean key
                            STATUSES.YES_NO[item] || translation
                          }
                          variant="select"
                          emptyOption
                          filterOptions={{
                            // @ts-expect-error statuses boolean key
                            keys: [(item: boolean) => STATUSES.YES_NO[item]]
                          }}
                        />
                      )}
                    />
                    <Validation.Required
                      field="isRequestAllowed"
                      message="Required field"
                    />
                  </Box>
                </Flex>
                <Flex mx={-1}>
                  <Box px={1} width={1 / 3}>
                    <Field.Number
                      name="consumerPrice"
                      label={<Trans id="Price" />}
                      placeholder="0 - 1 000 000"
                      postfix={<Trans id="uah" />}
                    />
                    <FieldListener
                      name="serviceGroupId"
                      subscription={{ value: true }}
                    >
                      {({ input: { value } }: $TSFixMe) =>
                        value ? null : (
                          <Validation.Required
                            field="consumerPrice"
                            message="Required field"
                          />
                        )
                      }
                    </FieldListener>
                  </Box>
                  <Box px={1} width={1 / 3}>
                    <SearchField.ComposedService name="serviceId" />
                  </Box>
                  <Box px={1} width={1 / 3}>
                    <SearchField.ComposedServiceGroup
                      name="serviceGroupId"
                      label={<Trans id="Service group" />}
                    />
                  </Box>
                </Flex>
                <Trans
                  id="Enter description"
                  render={({ translation }) => (
                    <Field.Textarea
                      name="description"
                      placeholder={translation}
                      rows={5}
                      maxLength={3000}
                      showLengthHint
                    />
                  )}
                />
              </Form>
            </Popup>
          </>
        )}
      </Mutation>
    </Box>
  );
};

const CreateProgramServiceMutation = gql`
  mutation CreateProgramServiceMutation($input: CreateProgramServiceInput!) {
    createProgramService(input: $input) {
      programService {
        id
      }
    }
  }
`;

const resetServiceConnection = createDecorator(
  {
    field: "serviceId",
    updates: {
      serviceGroupId: (value: string, allValues: $TSFixMe) => {
        const { serviceGroupId } = allValues || {};
        return value ? undefined : serviceGroupId;
      }
    }
  },
  {
    field: "serviceGroupId",
    updates: {
      serviceId: (value: string, allValues: $TSFixMe) => {
        const { serviceId } = allValues || {};
        return value ? undefined : serviceId;
      },
      consumerPrice: (value: string, allValues: $TSFixMe) => {
        const { consumerPrice } = allValues || {};
        return value ? undefined : consumerPrice;
      }
    }
  }
);

export default CreateProgramService;
