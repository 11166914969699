import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Box, Flex } from "@rebass/emotion";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import isEmpty from "lodash/isEmpty";

import { Form, SUBMIT_ERROR, Validation } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  DiagnosesGroupCode,
  CreateDiagnosesGroupInput
} from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import { ItemType } from "../../../../components/Field/SelectField";
import Line from "../../../../components/Line";
import Steps from "../../../../components/Steps";
import * as SearchField from "../../../../components/SearchField";
import Table from "../../../../components/Table";

import { DiagnosesGroupsQuery } from "../index";
import DiagnosesModalForm from "./DiagnosesModalForm";

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>Fill info</Trans>
        </Steps.Item>
        <Steps.Item to="./diagnoses" state={state} disabled>
          <Trans>Choose diagnoses</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Confirm</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <GeneralForm path="/" />
      <Diagnoses path="/diagnoses/*" />
      <Confirmation path="/confirm" />
    </Router>
  </>
);

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <Box p={5}>
    <Form
      onSubmit={(data: CreateDiagnosesGroupInput) => {
        navigate!("./diagnoses", {
          state: {
            ...state,
            createGroup: data
          }
        });
      }}
      initialValues={(state && state.createGroup) || null}
    >
      <Box width={1 / 2}>
        <Trans
          id="Enter group name"
          render={({ translation }) => (
            <Field.Text
              name="name"
              label={<Trans id="Group name" />}
              placeholder={translation}
              maxLength={100}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required field="name" message="Required field" />
          )}
        />
      </Box>
      <Box width={1 / 2}>
        <DictionaryValue
          name="DIAGNOSES_GROUP_CODES"
          render={(dict: DictionaryAllValuesJson) => (
            <Trans
              id="Choose diagnose group code"
              render={({ translation }) => (
                <Field.Select
                  variant="select"
                  name="code"
                  label={<Trans id="Group code" />}
                  placeholder={translation}
                  itemToString={(item: string) =>
                    (dict[item] && `${item} - ${dict[item]}`) ||
                    String(translation)
                  }
                  filterOptions={{ keys: [(item: ItemType) => item] }}
                  items={dict ? Object.keys(dict) : []}
                />
              )}
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required field="code" message="Required field" />
          )}
        />
      </Box>
      <Box width={1 / 2}>
        <Trans
          id="Group description"
          render={({ translation }) => (
            <Field.Textarea
              name="description"
              label={<Trans id="Description" />}
              placeholder={translation}
              rows={10}
              maxLength={1000}
              showLengthHint
            />
          )}
        />
      </Box>
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../search")}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
    </Form>
  </Box>
);

const Diagnoses = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { name, code, description } = state.createGroup;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Group name</Trans>,
          code: <Trans>Group code</Trans>,
          description: <Trans>Description</Trans>
        }}
        data={{ name, code, description }}
        labelWidth="200px"
      />
      <Line />
      <Router>
        <DiagnoseItems path="/" />
        <DiagnosesModalForm path=":codeId" />
      </Router>
    </Box>
  );
};

const DiagnoseItems = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();

  return (
    <Form
      initialValues={state}
      onSubmit={(data: { diagnoses: DiagnosesGroupCode[] }) => {
        if (isEmpty(data.diagnoses)) {
          const atleastOneItemIsChosen = [
            {
              entry: "atleast.item.chosen",
              rules: [
                {
                  rule: "notChosenAtleastOneDiagnose"
                }
              ]
            }
          ];
          return { [SUBMIT_ERROR]: atleastOneItemIsChosen };
        }

        navigate!("../confirm", {
          state: {
            ...data
          }
        });
      }}
    >
      <Box px={1}>
        <Field.Array
          name="diagnoses"
          addText={<Trans>Add diagnose</Trans>}
          removeText={<Trans>Delete</Trans>}
          fields={(props) => (
            <ArrayFieldValues {...props} navigate={navigate} />
          )}
          vertical
        />
      </Box>
      <Flex pt={5}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
      <Box>
        <Form.Error
          entry={{
            "atleast.item.chosen": {
              notChosenAtleastOneDiagnose: i18n._(
                t`At least one diagnose should be chosen`
              )
            }
          }}
          default={i18n._(t`Something went wrong. Please try again later`)}
        />
      </Box>
    </Form>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { name, code, description } = state.createGroup;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Group name</Trans>,
          code: <Trans>Group code</Trans>,
          description: <Trans>Description</Trans>
        }}
        data={{ name, code, description }}
        labelWidth="200px"
      />
      <Line />
      <Box mb={6}>
        <Trans>Chosen diagnoses</Trans>
        <Table
          data={state.diagnoses}
          hideControls
          hidePagination
          header={{
            code: <Trans>Code</Trans>,
            name: <Trans>Name</Trans>,
            system: <Trans>Dictionary name</Trans>
          }}
          renderRow={({
            code,
            name,
            system
          }: DiagnosesGroupCode & { name: string }) => ({
            code,
            name,
            system
          })}
        />
      </Box>
      <Flex pt={5} mb={100}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../diagnoses", { state })}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Mutation
          mutation={CreateDiagnosesGroupMutation}
          refetchQueries={() => [
            {
              query: DiagnosesGroupsQuery,
              variables: { first: 10 }
            }
          ]}
        >
          {(createDiagnosesGroup: MutationFunction) => {
            return (
              <Box>
                <Button
                  variant="green"
                  width={250}
                  onClick={async () => {
                    await createDiagnosesGroup({
                      variables: {
                        input: {
                          name,
                          code,
                          description,
                          diagnosesGroupCodes: state.diagnoses.map(
                            (d: DiagnosesGroupCode) => ({
                              code: d.code,
                              system: d.system
                            })
                          )
                        }
                      }
                    });
                    await navigate!("../../");
                  }}
                >
                  <Trans>Add</Trans>
                </Button>
              </Box>
            );
          }}
        </Mutation>
      </Flex>
    </Box>
  );
};

export default Create;

const CreateDiagnosesGroupMutation = gql`
  mutation CreateDiagnosesGroupMutation($input: CreateDiagnosesGroupInput!) {
    createDiagnosesGroup(input: $input) {
      diagnosesGroup {
        id
        databaseId
        name
        description
        insertedAt
        isActive
      }
    }
  }
`;

type ArrayFieldValuesProps = RouteComponentProps & {
  name: any;
};

const ArrayFieldValues = ({ name, navigate }: ArrayFieldValuesProps) => {
  const { i18n } = useLingui();

  return (
    <Box>
      <Form.Spy>
        {({ values }: $TSFixMe) => (
          <Box
            onClick={() => navigate!(name.match(/\d+/)[0], { state: values })}
          >
            <Flex mx={-1}>
              <>
                <Box width={1 / 4}>
                  <Field.Select
                    name={`${name}.code`}
                    label={<Trans id="Code" />}
                    placeholder={i18n._(t`Diagnoses group element code`)}
                    items={[]}
                    itemToString={(item) => item}
                    emptyOption
                    iconComponent={() => <SearchIcon color="silverCity" />}
                  />
                </Box>
                <Box width={1 / 4} ml={4}>
                  <Field.Text
                    label={<Trans id="Name" />}
                    name={`${name}.name`}
                    placeholder={i18n._(t`Enter name`)}
                  />
                </Box>
                <Box width={1 / 3} ml={4}>
                  <SearchField.DiagnosesGroupSystem
                    name={`${name}.system`}
                    disabled
                  />
                </Box>
              </>
            </Flex>
          </Box>
        )}
      </Form.Spy>
      <Validation.Required field={`${name}.code`} message="Required field" />
      <Validation.Required field={`${name}.name`} message="Required field" />
      <Validation.Required field={`${name}.system`} message="Required field" />
    </Box>
  );
};
