import React, { useCallback, useState } from "react";
import { NavigateFn, Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { format } from "date-fns";
import { Box, Flex, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  Form,
  LocationParams,
  SUBMIT_ERROR,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  fieldNameDenormalizer,
  convertObjectKeys,
  getFullName
} from "@edenlabllc/ehealth-utils";
import { Person } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Steps from "../../../../components/Steps";
import { TLocationParams } from "../../../../components/SearchForm";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

import filteredLocationParams from "../../../../helpers/filteredLocationParams";
import dateFormatter from "../../../../helpers/dateFormatter";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";
import { DATE_FORMAT } from "../../../../constants/dateFormats";
import { AUTH_METHODS_FILTER_DEFAULTS } from "../../../../constants/filterValues";

import { PersonQuery } from "../";
import UserInfo from "../UserInfo";

import env from "../../../../env";

export const defaultError = [
  {
    entry: "default"
  }
];

export const deathDateError = [
  {
    entry: "deathDate.invalid",
    rules: [
      {
        rule: "deathDateInvalid"
      }
    ]
  }
];

type DeactivatePersonPageProps = RouteComponentProps<{
  id: string;
}>;

const DeactivatePersonPage = ({
  // @ts-expect-error location state
  location: { state },
  id
}: DeactivatePersonPageProps) => (
  <Ability action="deactivate" resource="person">
    <LocationParams>
      {({ locationParams }: TLocationParams) => {
        const searchVariables = locationParams.filter
          ? filteredLocationParams(locationParams)
          : {
              ...filteredLocationParams(locationParams),
              ...AUTH_METHODS_FILTER_DEFAULTS
            };
        const variables = {
          id,
          ...searchVariables
        };
        return (
          <Query
            query={PersonQuery}
            fetchPolicy="network-only"
            variables={variables}
          >
            {({ data }: QueryResult<{ person: Person }>) => {
              if (isEmpty(data)) return null;
              const { person } = data;

              return (
                <>
                  <Box pt={5} px={6} width={2 / 3}>
                    <Steps.List>
                      <Steps.Item to="./" state={state}>
                        <Trans>Make changes</Trans>
                      </Steps.Item>
                      <Steps.Item to="./confirm" state={state} disabled>
                        <Trans id="approve by EDS">Approve by EDS</Trans>
                      </Steps.Item>
                    </Steps.List>
                    <Text fontSize={1} mb={5}>
                      <Trans>Deactivating of person</Trans>
                    </Text>
                    <DefinitionListView
                      labels={{
                        fullName: <Trans>Full name</Trans>,
                        databaseId: <Trans>Patient ID</Trans>
                      }}
                      data={{
                        fullName: <Text>{getFullName(person)}</Text>,
                        databaseId: data.person.databaseId
                      }}
                      color="#7F8FA4"
                      labelWidth="120px"
                    />
                    <Line />
                    <Router>
                      <DeactivateForm path="/" person={person} />
                      <Confirmation path="/confirm" person={person} />
                    </Router>
                  </Box>
                </>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  </Ability>
);

export default DeactivatePersonPage;

const DeactivatePerson = gql`
  mutation DeactivatePerson($input: DeactivatePersonInput!) {
    deactivatePerson(input: $input) {
      person {
        ...UserInfo
      }
    }
  }
  ${UserInfo.fragments.entry}
`;

type DeactivateFormProps = RouteComponentProps<{
  navigate: NavigateFn;
  person: Person;
}>;

const DeactivateForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  person
}: DeactivateFormProps) => {
  const { i18n } = useLingui();

  const handleCheckDeathDate = useCallback(
    (deathDate: string, birthDate: string) => {
      const dateToCkeck = new Date(deathDate);
      if (
        dateToCkeck > new Date() ||
        dateToCkeck < new Date("1900-01-01") ||
        dateToCkeck < new Date(birthDate)
      ) {
        return true;
      }

      return false;
    },
    []
  );

  return (
    <Form
      initialValues={{
        ...person,
        nhsRequestNumber: "",
        nhsRequestComment: "",
        ...state
      }}
      onSubmit={async (
        person: Partial<Person> & {
          nhsRequestNumber: string;
          nhsRequestComment: string;
        }
      ) => {
        if (
          person.deathDate &&
          handleCheckDeathDate(person.deathDate, person.birthDate)
        ) {
          return {
            [SUBMIT_ERROR]: deathDateError
          };
        }

        navigate!("./confirm", {
          state: {
            ...person
          }
        });
      }}
    >
      <Box width={1 / 2} mr={2} mt={1}>
        <Field.DatePicker
          name="deathDate"
          label={<Trans id="Date of death" />}
          minDate={format(person && person.birthDate, "YYYY-MM-DD")}
          maxDate={Date.now()}
        />
      </Box>
      <Box width={1 / 2} mt={1}>
        <Trans
          id="Enter application number"
          render={({ translation }) => (
            <Field.Text
              name="nhsRequestNumber"
              label={<Trans id="Application number" />}
              placeholder={translation}
              maxLength={50}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field="nhsRequestNumber"
              message="Required field"
            />
          )}
        />
      </Box>
      <Box width={2 / 3} mt={1}>
        <Trans
          id="Reason of deactivation person"
          render={({ translation }) => (
            <Field.Textarea
              name="nhsRequestComment"
              label={<Trans id="Specify the basis of deactivation person" />}
              placeholder={translation}
              rows={10}
              maxLength={3000}
              showLengthHint
            />
          )}
        />
        <Trans
          id="Required field"
          render={() => (
            <Validation.Required
              field="nhsRequestComment"
              message="Required field"
            />
          )}
        />
      </Box>
      <Flex pt={5}>
        <Box mr={3}>
          <Button
            type="reset"
            variant="blue"
            width={140}
            onClick={() => navigate!("../")}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Button variant="green" width={140}>
            <Trans>Next</Trans>
          </Button>
        </Box>
      </Flex>
      <Form.Error
        entry={{
          "deathDate.invalid": {
            deathDateInvalid: i18n._(t`Death date is not correct`)
          }
        }}
        default={i18n._(t`Something went wrong. Please try again later`)}
      />
    </Form>
  );
};

type ConfirmationProps = RouteComponentProps<{
  person: Person;
}>;

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  person
}: ConfirmationProps) => {
  const { i18n } = useLingui();
  const [error, setError] = useState(null);

  const { nhsRequestNumber, nhsRequestComment, deathDate } = state;

  return (
    <Signer.Parent
      url={env.REACT_APP_SIGNER_URL}
      features={{
        width: 640,
        height: 589
      }}
    >
      {/* @ts-expect-error signData */}
      {({ signData }) => (
        <Mutation
          mutation={DeactivatePerson}
          refetchQueries={() => [
            {
              query: PersonQuery,
              variables: { first: 10, id: person && person.id }
            }
          ]}
        >
          {(deactivatePerson: MutationFunction) => (
            <>
              <Box mt={1}>
                <DefinitionListView
                  labels={{
                    nhsRequestNumber: <Trans>Application number</Trans>,
                    nhsRequestComment: <Trans>Basis of deactivation</Trans>,
                    deathDate: <Trans>Date of death</Trans>
                  }}
                  data={{
                    nhsRequestComment,
                    nhsRequestNumber,
                    deathDate: dateFormatter(
                      i18n.locale,
                      DATE_FORMAT,
                      deathDate
                    )
                  }}
                  labelWidth="200px"
                />
              </Box>
              <Flex pt={5} mb={6}>
                <Box mr={3}>
                  <Button
                    type="reset"
                    variant="blue"
                    width={140}
                    onClick={() =>
                      navigate!("../", {
                        state: state
                      })
                    }
                  >
                    <Trans>Back</Trans>
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="green"
                    onClick={async () => {
                      setError(null);
                      const dataToSign = convertObjectKeys(
                        {
                          personId: person && person.databaseId,
                          nhsRequestNumber: nhsRequestNumber,
                          nhsRequestComment: nhsRequestComment,
                          ...(deathDate && {
                            deathDate: deathDate
                          })
                        },
                        fieldNameDenormalizer
                      );

                      const { signedContent } = await signData(dataToSign);

                      try {
                        const { data } = await deactivatePerson({
                          variables: {
                            input: {
                              signedContent: {
                                content: signedContent,
                                encoding: "BASE64"
                              }
                            }
                          }
                        });

                        if (
                          data.deactivatePerson &&
                          data.deactivatePerson.person &&
                          !isEmpty(data.deactivatePerson.person)
                        ) {
                          navigate!("../../");
                        }
                      } catch (error) {
                        if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                          setError(getErrorMessage(error));
                        }
                      }
                    }}
                  >
                    <Trans>Approve by EDS</Trans>
                  </Button>
                </Box>
              </Flex>
              {error && (
                <UnpocessableEntityModalError
                  errorMessage={error}
                  isModalOpen
                />
              )}
            </>
          )}
        </Mutation>
      )}
    </Signer.Parent>
  );
};
