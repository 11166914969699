import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box } from "@rebass/emotion";

import { DiagnosesGroup } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type GeneralInfoProps = RouteComponentProps & {
  diagnosesGroup: DiagnosesGroup;
};

const GeneralInfo = ({ diagnosesGroup }: GeneralInfoProps) => {
  const { deactivationReason, insertedAt, updatedAt, name, description, code } =
    diagnosesGroup;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Group name</Trans>,
          code: <Trans>Group code</Trans>,
          description: <Trans>Description</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>,
          deactivationReason: <Trans>Deactivation reason</Trans>
        }}
        data={{
          name,
          code,
          description,
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
          deactivationReason
        }}
      />
    </Box>
  );
};

export default GeneralInfo;
