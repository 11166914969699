import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { SearchIcon } from "@edenlabllc/ehealth-icons";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

export const SearchFields = () => (
  <>
    <Flex mx={-1} pt={5}>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter key"
          render={({ translation }) => (
            <Field.Text
              name="filter.code"
              label={<Trans id="Key" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
      </Box>
      <Box px={1} width={2 / 3}>
        <Trans
          id="Enter description"
          render={({ translation }) => (
            <Field.Text
              name="filter.description"
              label={<Trans id="Description" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_M" />
      </Box>
    </Flex>
  </>
);
