import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box, Flex } from "@rebass/emotion";

import { ProgramDevice } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_FORMAT } from "../../../constants/dateFormats";

import UpdateProgramDevice from "./Mutations/Update";

type HeaderProps = {
  id: ProgramDevice["id"];
  databaseId: ProgramDevice["databaseId"];
  isActive: ProgramDevice["isActive"];
  deviceRequestAllowed: ProgramDevice["deviceRequestAllowed"];
  carePlanActivityAllowed: ProgramDevice["carePlanActivityAllowed"];
  insertedAt: ProgramDevice["insertedAt"];
};

const Header = ({
  id,
  databaseId,
  isActive,
  deviceRequestAllowed,
  carePlanActivityAllowed,
  insertedAt
}: HeaderProps) => (
  <Flex justifyContent="space-between" alignItems="flex-start">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>Participant ID</Trans>,
          isActive: <Trans>Status</Trans>
        }}
        data={{
          databaseId,
          isActive: (
            <Badge
              type="ACTIVE_STATUS_M"
              name={isActive}
              variant={!isActive}
              minWidth={100}
            />
          )
        }}
        color="#7F8FA4"
        labelWidth="230px"
      />
      <UpdateProgramDevice
        id={id}
        isActive={isActive}
        deviceRequestAllowed={deviceRequestAllowed}
        carePlanActivityAllowed={carePlanActivityAllowed}
        isDefinitionListView
      />
      <Box mt={20}>
        <DefinitionListView
          labels={{
            insertedAt: <Trans>Inserted at</Trans>
          }}
          data={{
            insertedAt: dateFormatter(i18n.locale, DATE_FORMAT, insertedAt)
          }}
          color="#7F8FA4"
          labelWidth="230px"
        />
      </Box>
    </Box>
    <Ability action="write" resource="program_device">
      <UpdateProgramDevice
        id={id}
        isActive={isActive}
        deviceRequestAllowed={deviceRequestAllowed}
        carePlanActivityAllowed={carePlanActivityAllowed}
      />
    </Ability>
  </Flex>
);

Header.fragments = {
  entry: gql`
    fragment ProgramDeviceHeader on ProgramDevice {
      id
      databaseId
      isActive
      deviceRequestAllowed
      carePlanActivityAllowed
      insertedAt
    }
  `
};

export default Header;
