import * as React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";

import { DictionaryValue } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

type DeactivateValueProps = {
  id: DictionaryValue["id"];
};

const DeactivateValue = ({ id }: DeactivateValueProps) => {
  const { i18n } = useLingui();
  const [isVisible, toggle] = React.useReducer((v) => !v, false);
  const [error, setError] = React.useState<string | null>(null);

  return (
    <Mutation mutation={DeactivateDictionaryValue}>
      {(deactivateDictionaryValue: MutationFunction) => (
        <>
          <Button onClick={toggle} variant="red">
            <Trans>Deactivate</Trans>
          </Button>
          <Popup
            visible={isVisible}
            onCancel={toggle}
            title={
              <>
                <Trans>Deactivate</Trans>?
              </>
            }
            okText={<Trans>Deactivate</Trans>}
            onOk={async () => {
              setError(null);

              try {
                const { data } = await deactivateDictionaryValue({
                  variables: {
                    input: {
                      id
                    }
                  }
                });
                if (data.deactivateDictionaryValue) {
                  toggle();
                }
              } catch (error) {
                if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                  const errorTranslation = handleTranslateErrors(
                    getErrorMessage(error),
                    i18n
                  );
                  setError(errorTranslation);
                }
              }
            }}
          />
          {error && (
            <UnpocessableEntityModalError errorMessage={error} isModalOpen />
          )}
        </>
      )}
    </Mutation>
  );
};

export default DeactivateValue;

const DeactivateDictionaryValue = gql`
  mutation DeactivateDictionaryValue($input: DeactivateDictionaryValueInput!) {
    deactivateDictionaryValue(input: $input) {
      dictionaryValue {
        id
        isActive
      }
    }
  }
`;

const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    default:
      return errorMessage;
  }
};
