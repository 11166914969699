import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { MedicationRequestStatus } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";

import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => {
  return (
    <Flex mx={-1} flexDirection="column">
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter medication request number"
            render={({ translation }) => (
              <Field.Text
                name="filter.requestNumber"
                label={<Trans id="Medication request number" />}
                placeholder={translation}
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <DictionaryValue
            name="eHealth/medication_request_statuses"
            render={(dict: DictionaryAllValuesJson) => (
              <Trans
                id="Select status"
                render={({ translation }) => (
                  <Field.Select
                    variant="select"
                    name="filter.status"
                    label={<Trans id="Medication request status" />}
                    placeholder={translation}
                    itemToString={(item: MedicationRequestStatus) =>
                      dict[item] || String(translation)
                    }
                    filterOptions={{
                      keys: [(item: MedicationRequestStatus) => item]
                    }}
                    items={Object.keys(dict)}
                    emptyOption
                  />
                )}
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter medication request created"
            render={({ translation }) => (
              <Field.RangePicker
                rangeNames={[
                  "filter.medicationRequestCreated.from",
                  "filter.medicationRequestCreated.to"
                ]}
                label={<Trans id="Medication request created" />}
              />
            )}
          />
          <Form.Spy>
            {({ values = {} }: $TSFixMe) => {
              if (
                (values.filter &&
                  values.filter.medicationRequestCreated &&
                  values.filter.medicationRequestCreated.from) ||
                (values.filter &&
                  values.filter.medicationRequestCreated &&
                  values.filter.medicationRequestCreated.to)
              ) {
                return (
                  <>
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.medicationRequestCreated.from"
                          message={translation}
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={({ translation }) => (
                        <Validation.Required
                          field="filter.medicationRequestCreated.to"
                          message={translation}
                        />
                      )}
                    />
                  </>
                );
              }
              return null;
            }}
          </Form.Spy>
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter employee ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.employeeId"
                label={<Trans id="Employee ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.employeeId"
            options={UUID_PATTERN}
            message="Invalid employee ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter patient hash"
            render={({ translation }) => (
              <Field.Text
                name="filter.patientId"
                label={<Trans id="Patient ID" />}
                placeholder={translation}
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter legal entity ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.legalEntityId"
                label={<Trans id="Legal entity ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.legalEntityId"
            options={UUID_PATTERN}
            message="Invalid legal entity ID"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

const SecondarySearchFields = () => (
  <Form.Spy>
    {({ values = {} }: $TSFixMe) => (
      <Flex mx={-1} flexDirection="column">
        <Flex>
          <Box px={1} width={1 / 3} mt={3}>
            <Trans
              id="Enter ID"
              render={({ translation }) => (
                <Field.Text
                  name="filter.medicalProgramId"
                  label={<Trans id="Medical program ID" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Matches
              field="filter.medicalProgramId"
              options={UUID_PATTERN}
              message="Invalid ID"
            />
          </Box>
        </Flex>
        <Flex>
          <Box px={1} width={1 / 3} mt={3}>
            <Field.RangePicker
              rangeNames={["filter.startedAt.from", "filter.startedAt.to"]}
              label={<Trans id="Medication request started at" />}
            />
            {values.filter &&
              values.filter.startedAt &&
              (values.filter.startedAt.from || values.filter.startedAt.to) && (
                <>
                  <Validation.Required
                    field="filter.startedAt.from"
                    message="Required field"
                  />
                  <Validation.Required
                    field="filter.startedAt.to"
                    message="Required field"
                  />
                </>
              )}
          </Box>
          <Box px={1} width={1 / 3} mt={3}>
            <Field.RangePicker
              rangeNames={["filter.endedAt.from", "filter.endedAt.to"]}
              label={<Trans id="Medication request ended at" />}
            />
            {values.filter &&
              values.filter.endedAt &&
              (values.filter.endedAt.from || values.filter.endedAt.to) && (
                <>
                  <Validation.Required
                    field="filter.endedAt.from"
                    message="Required field"
                  />
                  <Validation.Required
                    field="filter.endedAt.to"
                    message="Required field"
                  />
                </>
              )}
          </Box>
        </Flex>
        <Flex>
          <Box px={1} width={1 / 3} mt={3}>
            <Field.RangePicker
              rangeNames={[
                "filter.dispenseValidFrom.from",
                "filter.dispenseValidFrom.to"
              ]}
              label={<Trans id="Dispense valid from" />}
            />
            {values.filter &&
              values.filter.dispenseValidFrom &&
              (values.filter.dispenseValidFrom.from ||
                values.filter.dispenseValidFrom.to) && (
                <>
                  <Validation.Required
                    field="filter.dispenseValidFrom.from"
                    message="Required field"
                  />
                  <Validation.Required
                    field="filter.dispenseValidFrom.to"
                    message="Required field"
                  />
                </>
              )}
          </Box>
          <Box px={1} width={1 / 3} mt={3}>
            <Field.RangePicker
              rangeNames={[
                "filter.dispenseValidTo.from",
                "filter.dispenseValidTo.to"
              ]}
              label={<Trans id="Dispense valid to" />}
            />
            {values.filter &&
              values.filter.dispenseValidTo &&
              (values.filter.dispenseValidTo.from ||
                values.filter.dispenseValidTo.to) && (
                <>
                  <Validation.Required
                    field="filter.dispenseValidTo.from"
                    message="Required field"
                  />
                  <Validation.Required
                    field="filter.dispenseValidTo.to"
                    message="Required field"
                  />
                </>
              )}
          </Box>
        </Flex>
      </Flex>
    )}
  </Form.Spy>
);

export { PrimarySearchFields, SecondarySearchFields };
