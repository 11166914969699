/*eslint-disable*/ module.exports = {
  messages: {
    "% of discount": "% знижки",
    "AR compose date": "Дата складання АЗ",
    "AR death book number": "Номер АЗ (в книзі державної реєстрації смертей)",
    "AR registration date": "Дата АЗ в Реєстрі (реєстраційна)",
    "AR registration number": "Номер АЗ в реєстрі (реєстраційний)",
    "ATC Code": "АТX код",
    "ATC Codes": "АТX коди",
    "About myself": "Про співробітника",
    "Accommodation/location/stay": "Проживання/розташування/перебування",
    "Account number": "Номер рахунку",
    Accounts: "Облікові записи",
    Accreditation: "Акредитація",
    Action: "Дія",
    "Action references": "Перелік інтервенцій",
    Actions: "Дії",
    Activate: "Активувати",
    "Activate Legal Entity": "Активувати заклад",
    Active: "Активний",
    "Active auth methods": "Діючі методи аутентифікації",
    "Active device definition with the same classification_type, manufacturer_name, model_number, packaging_count, part_number already exists":
      "Медичний виріб з комбінацією полів “Тип виробу“, “Виробник медичного виробу“, “Модель медичного виробу“, “Кількість в упаковці” та “Номер виробу за каталогом” вже існує",
    "Active licence": "Активна",
    "Active roles": "Активні ролі",
    Activities: "Активності",
    Activity: "Активність",
    "Activity ID": "ID Активності",
    "Activity kind": "Вид активності",
    "Activity reason reference": "Причини активності",
    "Activity status": "Статус активності",
    "Actual retail amount": "Фактична роздрібна ціна реалізації упаковки, грн",
    "Actual retail price": "Фактична роздрібна ціна за одиницю, грн",
    Add: "Додати",
    "Add ATC Code": "Додати АТX код",
    "Add additional ingredient": "Додати другорядну складову",
    "Add address": "Додати адресу",
    "Add another": "Додати ще",
    "Add code": "Додати стан",
    "Add confidant persons": "Додати довірену особу",
    "Add device definition names": "Додати назви медичних виробів",
    "Add diagnose": "Додати діагноз",
    "Add document": "Додати документ",
    "Add elements": "Додати елементи",
    "Add employee": "Додати співробітника",
    "Add ingredient": "Додати складову",
    "Add name": "Додати назву",
    "Add new medical program": "Додати нову медичну програму",
    "Add participant": "Додати учасника",
    "Add phone": "Додати номер телефону",
    "Add program": "Додати програму",
    "Add properties": "Додати характеристику виробу",
    "Add relationship document": "Додати документ про повноваження",
    "Add rule": "Додати правило",
    "Add service": "Додати сервіс",
    "Add service to the group": "Додати сервіс у групу",
    "Add service/service group": "Додати сервіс/групу сервісів",
    "Add tag": "Додати тег",
    "Add value": "Додати значення",
    Added: "Додано",
    "Added reorganized legal entity": "Додано заклад, що буде реорганізовано",
    "Added successors legal entities": "Додано заклади, що є правонаступниками",
    "Adding authentication method": "Додавання методу аутентифікації",
    "Additional ingredient": "Другорядна складова",
    Address: "Адреса",
    "Address type residence is used more than once":
      "Адреса з типом проживання використовується більше одного разу",
    Addresses: "Адреси",
    "Admit source": "Підстава",
    "Adult age": "Дорослий вік",
    Age: "Вік",
    Alias: "Назва методу",
    "All changes will be discarded. Are you sure?":
      "Всі зміни правила будуть скасовані. Ви впевнені?",
    "All codes": "Всі коди",
    "All contracts": "Всі договори",
    "All device definition classification types": "Всі типи виробу",
    "All dictionaries": "Всі словники",
    "All forms": "Всі типи лікарських форм",
    "All kinds": "Всі види активностей",
    "All pharmaceutical forms": "Всі фармацевтичні форми",
    "All reorganization type": "Всі типи",
    "All rule item must be valid": "Всі правила мають бути коректно заповнені",
    "All statuses": "Всі статуси",
    "All systems": "Всі системи",
    "All types": "Всі типи",
    "Allergy intolerance": "Сигнальна позначка",
    "Allergy intolerance ID": "ID Сигнальної позначки",
    "Allergy intolerance details": "Деталі сигнальної позначки",
    "Allergy intolerance onset date": "Дата початку",
    "Allergy intolerances": "Сигнальні позначки",
    "AllergyIntolerance verification status": "Cтатус достовірності",
    "Allow Request": "Надати дозвіл",
    "Allow Request for group": "Надати дозвіл на направлення для групи",
    "Allow Request for program service":
      "Надати дозвіл на направлення для програми послуг",
    "Allow Request for service": "Надати дозвіл на направлення для сервісу",
    "Allowed providing conditions": "Дозволені умови надання медичних послуг",
    Amount: "Кількість",
    "Application number": "Номер звернення",
    "Applications patients merge": "Заяви на об’єднання",
    "Applies to": "Застосовується до",
    "Approval expires at": "Термін дії дозволу",
    "Approval of the declaration": "Затвердження декларації",
    Approve: "Затвердити",
    "Approve by EDS": "Підписати з КЕП",
    "Approve the declaration": "Затвердити декларацію",
    Archive: "Архів",
    "Archiving Date": "Дата архівування",
    "Asserted date": "Дата внесення",
    Asserter: "Медичний працівник",
    "Assignee id": "ID користувача",
    "At least one code or service should be chosen":
      "Необхідно обрати хоча б один елемент для сервісу або коду",
    "At least one diagnose should be chosen":
      "Необхідно обрати хоча б один діагноз",
    "At least one innm should be is primary":
      "Хоча б одна складова має бути основною",
    "At least one rule item should be added":
      "Повинно бути створене хоча б одне правило",
    "Atc Codes": "АТX коди",
    "Attention! After declaration termination this action can not be canceled":
      "Увага! Після розірвання декларації, цю дію не можна буде скасувати",
    "Attention! After the dismiss, this action can not be canceled":
      "Увага! Після звільнення цю дію не можна буде скасувати",
    "Attention! After the termination of the agreement, this action can not be canceled":
      "Увага! Після розірвання договору, цю дію не можна буде скасувати",
    "Attention, legal entity was reorganized":
      "Увага, заклад було реорганізовано",
    "Attestation date": "Дата отримання диплому",
    "Attestation name": "Учбовий заклад",
    "Authentication method": "Метод аутентифікації",
    "Authentication method type": "Тип методу аутентифікації",
    "Authentication methods": "Методи аутентифікації",
    Author: "Автор",
    Authority: "Автор протоколу",
    "Authority that issued": "Орган, що видав",
    "Authorization through third person": "Авторизація через третю персону",
    "Authorize method": "Метод автентифікації",
    "Available Time": "Графік доступності",
    "BI URL": "BI URL",
    Back: "Повернутись",
    Bank: "Банк",
    "Bank Code (MFO)": "Код банку (МФО)",
    "Based on": "Діє на основі",
    "Based on service request": "На основі направлення",
    "Based on service request ID": "На основі направлення",
    Basis: "Підстава",
    "Basis of creation": "Причина створення",
    "Basis of deactivation": "Підстава деактивації",
    "Basis of group creation": "Причина створення",
    "Basis of group deactivation": "Причина деактивації",
    Beneficiary: "Вигодонабувач",
    "Beneficiary recipient Code": "Код одержувача бюджетних коштів",
    "Billing date": "Дата формування капітаційного звіту, день місяця",
    "Birth settlement": "Місце народження",
    "Black list users": "Заблоковані користувачі",
    Blocked: "Заблокований",
    "Body site": "Частина тіла",
    "Body sites": "Частина тіла",
    Boolean: "Логічний тип",
    Bounds: "Межі періодичності",
    Building: "Будівля",
    "Business rules engine ID": "ID набору правил",
    "By innm dosage": "За лікарською формою",
    "By medication": "За торгівельним найменуванням",
    "By quantity": "На кількість",
    "By the pattern of time": "За шаблоном часу",
    CEO: "Керівник",
    "Cancel Verification": "Скасувати верифікацію",
    "Cancel approval": "Відмінити дозвіл",
    "Cancellation reason": "Причина відміни",
    Cancelled: "Відмінений",
    "Cancelled status": "Відмінена",
    "Candidate ID": "ID кандидата",
    "Candidate details": "Деталі кандидата",
    "Candidates patients merge": "Кандидати на об’єднання",
    "Capitation contract max period":
      "Максимальна кількість днів, на яку може бути укладено договір капітації",
    "Capitation type": "Договір з закладом",
    "Care manager": "Медичний працівник",
    "Care plan": "План лікування",
    "Care plan ID": "ID плану лікування",
    "Care plan based on": "На основі плану лікування",
    "Care plan details": "Деталі плану лікування",
    "Care plan part of": "Ссилається на план лікування",
    "Care plan period date": "Дата періоду плану лікування",
    "Care plan required": "Наявність плану лікування обов'язкова",
    "Care plan requisition": "Номер плану лікування",
    "Care plan status": "Статус плану лікування",
    "Care plans": "Плани лікування",
    "Care plans Addresses - Describes diagnoses": "Діагнози",
    Categories: "Категорії",
    Category: "Категорія",
    Certificate: "Реєстраційне посвідчення",
    "Certificate <0/>number": "Номер <0/>реєстраційного посвідчення",
    "Certificate expired At": "Дата закінчення реєстраційного посвідчення",
    "Certificate expired at": "Дата закінчення реєстраційного посвідчення",
    "Certificate number": "Номер реєстраційного посвідчення",
    Change: "Змінити",
    "Change authentication method": "Зміна методу аутентифікації",
    "Change medical composition status": "Змінити статус МВ",
    "Change status reason": "Причина зміни статусу",
    "Change status reason description": "Опис причини зміни статусу",
    "Change version": "Оновлена версія",
    "Changes history": "Деталі зміни",
    "Changes in personal data and statuses":
      "Зміни у персональних даних та статусах",
    Check: "Далі",
    Choose: "Обрати",
    "Choose Code": "Оберіть стани",
    "Choose INNM": "Оберіть МНН",
    "Choose INNM original name": "Оберіть оригінальну назву",
    "Choose a pending request or get the new one to get started":
      "Оберіть заяву зі списку або отримайте нову для початку роботи",
    "Choose assignee": "Оберіть виконавця",
    "Choose authentication method": "Виберіть метод аутентифікації",
    "Choose base": "Оберіть підставу",
    "Choose codes": "Оберіть стани",
    "Choose death act": "Оберіть акт смерті",
    "Choose device definition": "Виберіть медичний виріб",
    "Choose diagnose group code": "Оберіть код групи діагнозів",
    "Choose diagnoses": "Оберіть діагнози",
    "Choose dismiss reason": "Оберіть причину звільнення",
    "Choose file type": "Оберіть тип файлу",
    "Choose items comparator": "Виберіть оператор",
    "Choose medical program": "Оберіть медичну програму",
    "Choose medication name": "Введіть торгівельне найменування",
    "Choose payment method": "Оберіть cпосіб",
    "Choose reimbursement type": "Виберіть тип реімбурсації",
    "Choose service": "Оберіть сервіс",
    "Choose service group": "Оберіть групу сервісів",
    "Choose services": "Оберіть сервіси",
    "Choose signer": "Виберіть підписанта",
    "Choose status reason": "Виберіть причину",
    Chose: "Обрати",
    "Chose codes": "Оберіть стани",
    "Chose services": "Оберіть сервіси",
    Chosen: "Обрано",
    "Chosen codes": "Обрані стани",
    "Chosen diagnoses": "Обрані діагнози",
    "Chosen services": "Обрані сервіси",
    Ciphers: "Коди",
    Class: "Клас",
    "Clinical monitoring": "Клінічний моніторинг",
    "Clinical status": "Клінічний статус",
    Close: "Закрити",
    "Close legal entity": "Закрити заклад",
    "Closing summary": "Заключення при закритті епізоду",
    Code: "Код",
    "Code filters": "Стани",
    Codes: "Стани",
    "Coding system": "Система кодування",
    Comment: "Коментар",
    Completed: "Виконаний",
    "Completed status": "Завершена",
    "Completed with": "Чим погашене",
    "Complication details": "Ускладнення",
    Component: "Компонент",
    Components: "Компоненти",
    "Compose organization": "Орган, що склав АЗ",
    Conclusion: "Заключення",
    "Conclusion author": "Автор заключення",
    "Conclusion code": "Код діагнозу",
    Condition: "Діагноз",
    "Condition details": "Деталі діагнозу",
    "Condition's encounter ID": "ID взаємодії",
    Conditions: "Діагнози",
    "Confidant Persons": "Довірені особи",
    "Confidant persons": "Довірені особи",
    Configuration: "Конфігурація системи",
    "Configure rules": "Налаштуйте правила",
    Confirm: "Підтвердіть дані",
    "Confirm data": "Підтвердіть дані",
    "Confirm with EDS": "Підтвердіть з ЕЦП",
    Confirmation: "Підтвердження",
    "Congratulations on the administrative system Ehealth!":
      "Вітаємо в адміністративній системі Ehealth!",
    "Consumer price": "Роздрібна ціна за упаковку",
    "Consumer price per unit": "Роздрібна ціна за одиницю",
    "Contact information": "Контактна інформація",
    Container: "Контейнер",
    Context: "Взаємодія",
    "Contract Amount": "Сума контракту",
    "Contract ID": "ID договору",
    "Contract Number": "Номер договору",
    "Contract Price": "Ціна договору",
    "Contract Request status": "Статус заяви",
    "Contract end date": "Дата закінчення",
    "Contract inserted date": "Дата додавання",
    "Contract request ID": "ID заяви",
    "Contract request databaseID": "ID заяви на укладення договору",
    "Contract request details": "Деталі заяви",
    "Contract request list": "Перелік заяв на укладення договору",
    "Contract requests": "Заяви на укладення договору",
    "Contract requests list": "Перелік заяв",
    "Contract start date": "Дата укладення",
    "Contract state": "Стан договору",
    "Contract status": "Статус договору",
    Contractors: "Підрядники",
    Contracts: "Договори",
    Contributor: "Співавтори",
    Count: "Кількість разів",
    "Count max": "Максимальна кількість разів",
    Country: "Країна",
    "Country of birth": "Країна народження",
    Create: "Створити",
    "Create INNM": "Cтворити МНН",
    "Create INNM Dosage": "Створити лікарську форму",
    "Create Request Approval": "Створити дозвіл пацієнта",
    "Create a medication request without period restrictions":
      "Створення рецепту без обмежень за періодом",
    "Create conclusion": "Сформувати заключення",
    "Create device": "Створити медичний виріб",
    "Create device definition": "Створення медичного виробу",
    "Create device definitions": "Cтворити медичний виріб",
    "Create device request without period restrictions":
      "Створення рецепту на медичний виріб без обмежень за періодом",
    "Create diagnoses group": "Створити групу",
    "Create employee": "Додати співробітника",
    "Create extract": "Сформувати витяг",
    "Create forbidden group": "Створити групу",
    "Create justification": "Вказати підставу",
    "Create medication": "Створити торгівельне найменування",
    "Create medication data": "Створення даних медикамента, номер рядка",
    "Create new Authentication Method": "Створити новий метод аутентифікації",
    "Create new authentication method": "Створити новий метод аутентифікації",
    "Create program device": "Створення учасника програми медичних виробів",
    "Create program medication by brand":
      "Створення учасника медичної програми за торгівельним найменуванням",
    "Create program medication by innm dosage":
      "Створення учасника медичної програми за лікарською формою",
    "Create program service": "Додати учасника",
    "Create related legal entity": "Створення підпорядкування закладу",
    "Create rule": "Створити правило",
    "Create rules engine rule": "Створити набір",
    "Create service": "Створити сервіс",
    "Create service group": "Створити групу сервісів",
    "Created at": "Дата створення",
    "Creation reason": "Причина створення",
    Criticality: "Ризик розвитку важких станів",
    "Current condition code": "Код діагнозу",
    "Current diagnoses": "Поточні діагнози",
    "Current diagnosis code": "Код поточного діагнозу",
    "Current observation code": "Код обстеження",
    Custodian: "Медзаклад",
    "DLS Verification": "Наявність ліцензії",
    DRACS: "ДРАЦСГ",
    "DRACS death status": "Стан синхронізації ДРАЦСГ",
    "DRACS unverified": "Не верифіковано ДРАЦСГ",
    "DRACS verification comment": "Коментар до статусу верифікації ДРАЦСГ",
    "DRACS verification status": "Статус верифікації за ДРАЦСГ",
    "DRACS verification status reason": "Причина статусу верифікації ДРАЦСГ",
    DRFO: "ДРФО",
    "DRFO synced at": "Синхронізовано ДРФО",
    "DRFO table identifier": "Ідентифікатор запису таблиці даних ДРФО",
    "DRFO unverified": "Не верифіковано ДРФО",
    "DRFO verification result": "Результат верифікації ДРФО",
    "DRFO verification status": "Статус верифікації за ДРФО",
    "DRFO verification status reason": "Причина статусу верифікації ДРФО",
    "Daily amount": "Добова кількість",
    "Daily dosage": "Добова доза",
    Data: "Дані",
    Date: "Поставлений",
    "Date ended": "Дата завершення дії",
    "Date of birth": "Дата народження",
    "Date of birthdate": "Дата народження",
    "Date of death": "Дата смерті",
    "Date of expiration": "Дата закінчення",
    "Date of immunization": "Дата",
    "Date of issue": "Дата видачі",
    "Date of issued": "Дата видачі",
    "Date started": "Дата початку дії",
    "Day of week": "Дні події",
    Deactivate: "Деактивувати",
    "Deactivate Authentication Method": "Деактивувати метод аутентифікації",
    "Deactivate authentication method": "Деактивувати метод аутентифікації",
    "Deactivate brand": "Деактивація торгівельного найменуваня",
    "Deactivate diagnoses group": "Деактивувати групу діагнозів",
    "Deactivate elements": "Деактивувати елементи",
    "Deactivate group": "Деактивувати групу",
    "Deactivate innm dosage": "Деактивація лікарської форми",
    "Deactivate medical program": "Деактивувати медичну програму",
    "Deactivate program medication": "Деактивація учасника програми",
    "Deactivate program service": "Деактивувати програму послуг",
    "Deactivate related entities":
      "Модифікація співробітників, договору та декларацій закладу",
    "Deactivate rules engine rule": "Деактивувати набір",
    "Deactivating of person": "Деактивація пацієнта",
    "Deactivation reason": "Причина деактивації",
    "Deactivation reason hint": "Введіть причину деактивації набору",
    "Death act": "Акт смерті",
    "Death date": "Дата смерті",
    "Death date is not correct": "Невірна дата смерті",
    Decimal: "Десяткове число",
    "Declaration Details": "Деталі декларації",
    "Declaration ID": "ID декларації",
    "Declaration limit": "Максимальна кількість декларацій на особу (лікаря)",
    "Declaration number": "Номер декларації",
    "Declaration request ID": "ID запиту",
    "Declaration request expiration (days)":
      "Термін дії запиту на створення декларації (дні)",
    "Declaration search": "Пошук декларації",
    "Declaration start date": "Декларація діє з",
    "Declaration term (years)": "Термін дії декларації (роки)",
    "Declaration termination": "Розірвання декларації",
    "Declaration termination reason description": "Опис причини розірвання",
    "Declaration valid from": "Декларація діє з",
    Declarations: "Декларації",
    "Declarations limit": "Ліміт кількості декларацій",
    "Declarations termination jobs": "Розірвання декларацій",
    "Decline declaration": "Відхилити декларацію",
    "Decline reason": "Причина відхилення",
    "Declining declaration": "Відхилення декларації",
    "Definition of the device at the care plan's activity":
      "Визначення медичного виробу в активності плану лікування",
    Degree: "Ступінь",
    Delete: "Видалити",
    "Delete address": "Видалити адресу",
    "Delete confidant person": "Видалити довірену особу",
    "Delete document": "Видалити документ",
    "Delete phone": "Видалити телефон",
    "Delete relationship document": "Видалити документ про повноваження",
    "Delete service": "Видалити сервіс",
    "Denumerator unit": "Тип упаковки",
    Description: "Опис",
    Details: "Деталі",
    "Details of the change": "Деталі зміни",
    "Details of the contract": "Деталі договору",
    "Details of the medication dispense": "Деталі погашення рецепту",
    "Details of the patient": "Деталі пацієнта",
    "Device care plan activity allowed": "Дозвіл на створення активності ПЛ",
    "Device definition ID": "ID медичного виробу",
    "Device definition classification type": "Тип виробу",
    "Device definition description": "Загальний опис мед виробу",
    "Device definition has active Program devices":
      "Медичний виріб має активного учасника медичної програми. Необхідно спочатку деактивувати його",
    "Device definition manufacturer country": "Країна-виробник виробу",
    "Device definition manufacturer name": "Виробник медичного виробу",
    "Device definition model number": "Модель медичного виробу",
    "Device definition name": "Назва медичного виробу",
    "Device definition name description": "Опис назви",
    "Device definition name type": "Тип назви",
    "Device definition packaging count": "Кількість в упаковці",
    "Device definition packaging type": "Тип упаковки",
    "Device definition packaging unit": "Одиниці",
    "Device definition parent ID": "Батьківський виріб",
    "Device definition part number": "Номер виробу за каталогом",
    "Device definition properties type": "Тип властивостей",
    "Device definition properties value": "Значення властивостей",
    "Device definition properties value type": "Тип значення властивостей",
    "Device definition registered name": "Зареєстрована назва медичного виробу",
    "Device definitions": "Медичні вироби",
    "Device definitions details": "Деталі медичного виробу",
    "Device dispense period (in days)":
      "Період погашення рецепту на медичний виріб (дні)",
    "Device registry jobs": "Завантаження реєстрів медичних виробів",
    "Device request allowed": "Дозвіл на створення призначень",
    "Device request max treatment period (in days)":
      "Максимальний період курсу лікування за рецептом на медичний виріб (дні)",
    "Diagnose code search": "Пошук за кодом діагнозу",
    Diagnoses: "Діагнози",
    "Diagnoses code": "Код діагнозу",
    "Diagnoses group ID": "ID групи діагнозів",
    "Diagnoses group element code": "Введіть код",
    "Diagnoses groups": "Групи діагнозів",
    "Diagnoses groups details": "Деталі групи діагнозів",
    "Diagnoses history": "Історія діагнозів",
    "Diagnosis confirmation status": "Статус підтвердження діагнозу",
    "Diagnostic report": "Деталі діагностичного звіту",
    "Diagnostic report ID": "ID Діагностичного звіту",
    "Diagnostic report based on": "На основі направлення",
    "Diagnostic report category": "Категорії",
    "Diagnostic report details": "Деталі діагностичного звіту",
    "Diagnostic report issued": "Час звіту",
    "Diagnostic report issued date": "Час звіту",
    "Diagnostic reports": "Діагностичні звіти",
    Dictionaries: "Словники",
    Dictionary: "Словник",
    "Dictionary name": "Назва словника",
    "Dictionary type": "Словник",
    "Digital sign on medication dispense is disabled":
      "Цифровий підпис при погашенні рецепту вимкнено",
    Dimensions: "Кількість вимірів",
    "Diploma number": "Диплом",
    "Disability case number": "Номер випадку непрацездатності",
    "Disallow Request": "Зняти дозвіл",
    "Disallow Request for group": "Зняти дозвіл на направлення з групи",
    "Disallow Request for program service":
      "Зняти дозвіл на направлення з програми послуг",
    "Disallow Request for service": "Зняти дозвіл на направлення з сервісу",
    "Discharge department": "Вид стаціонарного відділення",
    "Discharge disposition": "Результат лікування",
    "Discount amount": "Знижка",
    "Discount amount with currency": "Знижка, грн",
    Dismiss: "Звільнити",
    "Dismiss employee": "Звільнення співробітника",
    "Dismiss reason": "Причина звільнення",
    "Dispense allowed": "Дозвіл на створення погашення рецептів",
    "Dispense valid from": "Відпуск дійсний з",
    "Dispense valid to": "Відпуск дійсний по",
    "Dispensed at": "Дата погашення",
    "Dispensed by": "Погашено співробітником",
    "Dispensed period": "Дата погашення",
    District: "Район",
    Division: "Відділення",
    "Division ID": "ID відділення",
    "Division and Services": "Відділення та послуги",
    "Division name": "Назва відділення",
    Divisions: "Відділення",
    "Divisions details": "Деталі відділення",
    "Do not perform": "Активність заборонена до виконання",
    "Do you want to resend medical composition to erln":
      "Ви хочете повторно надіслати вибраний медичний висновок в ЕРЛН?",
    Doctor: "Лікар",
    Doctors: "Лікарі",
    "Document number": "Номер документу",
    Documents: "Документи",
    "Documents confirming the credentials of the confidant":
      "Документи, що підтверджують повноваження представника",
    "Does not match the signer drfo":
      "РНОКПП користувача не співпадає з переданим в КЕП",
    "Don't check contract and medical program provision on medication dispense":
      "Не перевіряти наявність контракту та забезпечення програми при погашенні рецепту",
    "Don't check declaration on employee level on create device request":
      "Не перевіряти наявність декларації на рівні співробітника при створенні рецепту на медичний виріб",
    "Don't check declaration on employee level on create medication request":
      "Не перевіряти наявність декларації на рівні співробітника при створенні рецепту",
    "Don't check declaration on legal entity level on create device request":
      "Не перевіряти наявність декларації на рівні медичного закладу при створенні рецепту на медичний виріб",
    "Don't check declaration on legal entity level on create medication request":
      "Не перевіряти наявність декларації на рівні медичного закладу при створенні рецепту",
    Dosage: "Дозування",
    "Dose quantity": "Доза",
    "Dose sequence": "Порядковий номер дози",
    "Dracs death act details": "Деталі акту смерті",
    "Dracs death unverified, days": "Не верифіковано ДРАЦСГ, днів",
    "Drag 'n' drop file here, or click to select file":
      "Перетягніть файл сюди або клацніть, щоб вибрати файл",
    "Duration max": "Максимальна тривалість події",
    "Duration unit": "Одиниця тривалості",
    "EDR Data": "Дані з ЄДР",
    "EDR ID": "ЄДР ID",
    "EDR verified": "Запис в ЄДР",
    EDRPOU: "ЄДРПОУ",
    "EDRPOU or Contract number": "ЄДРПОУ або Номер договору",
    "ELN close date": "Дата закриття ЕЛН",
    "ELN number": "Єдиний реєстраційний номер ЕЛН",
    "ELN open date": "Дата відкриття ЕЛН",
    "ELN registration date": "Дата та час реєстрації ЕЛН",
    Edit: "Редагувати",
    "Edit authentication method": "Редагувати метод аутентифікації",
    "Edit comment": "Редагувати коментар",
    "Editing reason": "Причина редагування",
    "Editing the dictionary": "Редагування словника",
    Education: "Освіта",
    "Education and Qualification": "Навчання та кваліфікація",
    "Effective at": "Актуальний час",
    Email: "Email",
    "Emergency Contact": "Контакт для екстреного зв’язку",
    "Emergency contact": "Контакт для екстреного зв’язку",
    "Emergency contact should have atleast one phone number":
      "Контакт для екстреного зв’язку повинен містити хоча б один номер телефону",
    Employee: "Медичний працівник",
    "Employee ID": "ID лікаря",
    "Employee Requests": "Співробітники на розгляді",
    "Employee account": "Обліковий запис співробітника",
    "Employee account details": "Інформація про обліковий запис співробітника",
    "Employee details": "Інформація про співробітника",
    "Employee email": "Email співробітника",
    "Employee has been inserted at": "Співробітник доданий",
    "Employee name": "ПІБ співробітника",
    "Employee party ID": "ID облікового запису співробітника",
    "Employee request expiration (days)":
      "Термін дії запиту на створення співробітника (дні)",
    "Employee status": "Статус",
    "Employee type": "Тип співробітника",
    "Employee types allowed to create device requests":
      "Типи співробітників, яким дозволено створення рецептів на медичні вироби",
    "Employee types who are allowed to create medication requests":
      "Типи співробітників, яким дозволено створення рецептів",
    Employees: "Співробітники",
    "Employment end date": "Дата завершення роботи",
    "Employment start date": "Дата початку роботи",
    Encounter: "Взаємодія",
    "Encounter ID": "ID взаємодії",
    "Encounter date end": "Кінець періоду взаємодії",
    "Encounter date start": "Початок періоду взаємодії",
    "Encounter details": "Деталі взаємодії",
    "Encounter end date": "Дата закінчення взаємодії",
    "Encounter start date": "Дата початку взаємодії",
    "Encounter status": "Cтатус взаємодії",
    Encounters: "Взаємодії",
    "End date": "Дата завершення",
    "End date field": "Діє до",
    "End date must be greater than start date":
      "Дата закінчення має бути більшою ніж дата початку",
    "End date of the contract": "Кінцева дата дії договору",
    "End date of the declaration": "Кінцева дата дії декларації",
    Ended: "Дата завершення",
    "Ended at": "Час завершення задачі",
    "Enter ATC code": "Введіть АТX код",
    "Enter Code": "Введіть код",
    "Enter Group name": "Введіть назву групи",
    "Enter ID": "Введіть ID",
    "Enter ID or Declaration number": "Введіть ID або номер декларації",
    "Enter INN": "Введіть ІПН",
    "Enter INNM": "Введіть МНН",
    "Enter INNM Dosage ID": "введіть ID лікарської форми",
    "Enter INNM ID": "Введіть ID МНН",
    "Enter INNM dosage name": "Введіть назву лікарської форми",
    "Enter OTP code": "Введіть OTP код",
    "Enter SCTID": "Введіть SCTID",
    "Enter Service Code": "Введіть код сервісу",
    "Enter Service Group Code": "Введіть код групи сервісів",
    "Enter Service Group Name": "Введіть назву групи сервісів",
    "Enter Service Name": "Введіть назву сервісу",
    "Enter activity ID": "Введіть ID активності",
    "Enter allergy intolerance ID": "Введіть ID сигнальної позначки",
    "Enter allergy intolerance onset date": "введіть дату початку",
    "Enter apartment": "Введіть номер квартири",
    "Enter application number": "Введіть номер звернення",
    "Enter authentication method name":
      "Введіть назву аутентифікаційного методу",
    "Enter authority name": "Введіть назву органу",
    "Enter based on service request ID": "введіть ID направлення",
    "Enter birth settlement": "Введіть місце народження",
    "Enter building": "Введіть номер будинку",
    "Enter care plan ID": "Введіть ID плану лікування",
    "Enter care plan period date": "введіть дату періоду плану лікування",
    "Enter care plan requisition": "введіть номер плану лікування",
    "Enter certificate number": "Введіть номер реєстраційного посвідчення",
    "Enter city": "Введіть місто",
    "Enter code": "Введіть код",
    "Enter comment": "Введіть коментар",
    "Enter conclusion comment": "Введіть коментар до заключення",
    "Enter condition ID": "Введіть ID діагнозу",
    "Enter condition's encounter ID": "введіть ID взаємодії",
    "Enter country of birth": "Введіть країну народження",
    "Enter current condition code": "введіть код діагнозу",
    "Enter current diagnosis code": "введіть Код поточного діагнозу",
    "Enter current observation code": "введіть код обстеження",
    "Enter declaration number": "Введіть номер декларації",
    "Enter decline reason": "Вкажіть причину відхилення",
    "Enter description": "Введіть опис",
    "Enter device definition manufacturer name":
      "Введіть інформацію про виробника виробу",
    "Enter device definition model number": "Введіть номер моделі виробу",
    "Enter device definition name": "Введіть назву медичного виробу",
    "Enter device definition name description": "Введіть опис назви",
    "Enter device definition packaging count": "Введіть кількість в упаковці",
    "Enter device definition parent ID":
      "Введіть посилання на батьківський виріб",
    "Enter device definition part number": "Введіть номер виробу за каталогом",
    "Enter device definition properties value": "Введіть значення властивостей",
    "Enter diagnose code": "Введіть код",
    "Enter diagnostic report ID": "Введіть ID діагностичного звіту",
    "Enter diagnostic report issued date": "введіть час звіту",
    "Enter dispensed period": "введіть дату погашення",
    "Enter district name": "Введіть назву району",
    "Enter division ID": "Введіть ID відділення",
    "Enter division name": "Введіть назву відділення",
    "Enter document number": "Введіть номер документу",
    "Enter email": "Введіть email адресу",
    "Enter employee ID": "введіть ID медичного працівника",
    "Enter encounter ID": "введіть ID взаємодії",
    "Enter encounter ID ": "введіть ID взаємодії",
    "Enter episode ID": "введіть ID епізоду",
    "Enter episode period": "введіть період епізоду",
    "Enter first name": "Введіть ім’я",
    "Enter full name": "Введіть повне ім’я",
    "Enter group": "Введіть назву",
    "Enter group deactivation reason": "Введіть причину деактивації групи",
    "Enter group name": "Введіть назву групи",
    "Enter immunization ID": "Введіть ID щеплення",
    "Enter incoming referral ID": "введіть ID направлення",
    "Enter ingredient name": "Введіть назву складової",
    "Enter issued by": "Ввведіть ким видано",
    "Enter key": "Введіть ключ",
    "Enter last name": "Введіть прізвище",
    "Enter legal entity EDRPOU": "Введіть ЄДРПОУ закладу",
    "Enter legal entity ID": "введіть ID медичного закладу",
    "Enter legal entity name": "Введіть назву закладу",
    "Enter managing organization ID": "введіть ID медичного закладу",
    "Enter manufacturer": "Введіть виробника",
    "Enter manufacturer name": "Введіть назву виробника",
    "Enter master person ID": "Введіть ID мастер персони",
    "Enter medical composition focus": "Введіть, кому виданий МВ",
    "Enter medical composition type": "Виберіть тип",
    "Enter medical program name": "Введіть назву медичної програми",
    "Enter medication dispense ID": "Введіть ID погашення рецепту",
    "Enter medication name": "Назва",
    "Enter medication request ID": "введіть ID рецепту",
    "Enter medication request created": "введіть дату створення рецепту",
    "Enter medication request number": "введіть номер рецепту",
    "Enter model number": "Введіть інформацію про модель виробу",
    "Enter name": "Введіть назву",
    "Enter name or code": "Введіть назву або код",
    "Enter number": "Введіть номер",
    "Enter observation ID": "Введіть ID обстеження",
    "Enter observation's encounter ID": "введіть ID взаємодії",
    "Enter onset date": "введіть дату початку",
    "Enter origin episode ID": "введіть ID первинного епізоду",
    "Enter original name": "Введіть оригінальну назву",
    "Enter patient hash": "Введіть хеш пацієнта",
    "Enter patient id": "Введіть ID пацієнта",
    "Enter person ID": "Введіть ID персони",
    "Enter pharmacy ID": "введіть ID аптеки",
    "Enter phone number": "Введіть номер телефону",
    "Enter procedure ID": "Введіть ID процедури",
    "Enter procedure's encounter ID": "введіть ID взаємодії",
    "Enter product ID in DRLZ register": "Введіть ID продукту в ДРЛЗ реєстрі",
    "Enter program name": "Введіть назву медичної програми",
    "Enter reason comment": "Введіть коментар",
    "Enter registry number": "Введіть номер реєстру",
    "Enter requester legal entity ID":
      "введіть ID закладу ініціатора направлення",
    "Enter resource ID": "Введіть ID медичного запису",
    "Enter rule engine rule title": "Введіть назву набору",
    "Enter second name": "Введіть ім’я по-батькові",
    "Enter secret word": "Введіть секретне слово",
    "Enter service ID": "введіть ID сервісу",
    "Enter service request ID": "введіть ID направлення",
    "Enter service request requisition": "введіть номер направлення",
    "Enter settlement": "Введіть населений пункт",
    "Enter settlement name": "Введіть назву населеного пункту",
    "Enter settlement of birth": "Введіть місце народження",
    "Enter short name": "Введіть коротку назву",
    "Enter signer": "Введіть підписанта",
    "Enter sms url": "Введіть посилання",
    "Enter status": "Введіть статус",
    "Enter street name": "Введіть назву вулиці",
    "Enter tax ID": "Введіть ІПН",
    "Enter terminate declaration reason":
      "Вкажіть опис причини розірвання декларації",
    "Enter the number": "Введіть номер",
    "Enter third person full name": "Введіть повне ім'я третьої персони",
    "Enter unzr": "Введіть ID Запису в ЄДДР",
    "Enter used by legal entity ID":
      "введіть ID закладу, що використав направлення",
    "Enter vaccine code": "введіть код вакцини",
    "Enter value": "Введіть значення",
    "Enter zip code": "Введіть поштовий код",
    "Entity type": "Тип суб'єкта",
    Episode: "Епізод",
    "Episode ID": "ID епізоду",
    "Episode details": "Деталі епізоду",
    "Episode period": "Період епізоду",
    "Episode status": "Cтатус епізоду",
    Episodes: "Епізоди",
    Equipment: "Обладнання",
    "Error Message": "Текст помилки",
    "Error occured. Try again later": "Сталася помилка. Спробуйте пізніше",
    "Estimated payment amount": "Сума доплати за упаковку",
    "Estimated payment amount per unit": "Сума доплати за одиницю",
    Event: "Дата та час події",
    "Every confidant person should have atleast one phone number":
      "Кожна довірена особа повинна мати хоча б один номер телефону",
    Evidence: "Доказ",
    Evidences: "Докази",
    "Example: Order of the Cabinet of Ministers of Ukraine # 73465 ":
      "Приклад: Наказ КМУ № 73465",
    "Execution time": "Час виконання задачі",
    "Exit persons verification changes history":
      "Вийти з історії змін верифікації пацієнта",
    "Exit the editing mode": "Вийти з режиму редагування",
    "Exit the justification history": "Вийти з історії підстав",
    "Exit the patient approvals": "Вийти з дозволів пацієнта",
    "Exit the practical monitor": "Вийти з фактичного моніторингу",
    "Expiration date": "Дата закінчення",
    Expired: "Прострочений",
    "Expires at": "Термін дії підстави",
    "Explanatory letter": "Пояснення причини",
    "Export to file": "Зберегти в файл",
    "External ID": "ID третьої системи",
    "External id": "Зовнішній ідентифікатор",
    Facility: "Заклад",
    Factor: "Фактор",
    "Family doctor declaration limit":
      "Максимальна кількість декларацій для спеціальності Сімейний лікар",
    "File format is not supported": "Формат файлу не підтримується",
    "File size more than 1MB": "Розмір файлу не має перевищувати 1 Мб",
    "File type": "Тип файлу",
    "Fill from existing rule engine rule": "Заповнити з існуючого набору",
    "Fill in fields": "Дозаповніть поля",
    "Fill in the form": "Заповніть форму",
    "Fill info": "Заповніть інформацію",
    "Filling from existing rule engine rule will clear currently configured rules, are you sure want to proceed?":
      "Заповнення з існуючого набору замінить вже налаштовані правила. Ви дійсно бажаєте продовжити?",
    "Filling from file will clear currently configured rules, are you sure want to proceed?":
      "Заповнення з файлу замінить вже налаштовані правила. Ви дійсно бажаєте продовжити?",
    "Filter by Tag": "Фільтрувати за тегом",
    "Filter, subject or title should be set":
      "Для виконання пошуку заповніть хоча б одне з обов`язкових полів",
    "Find Legal entity": "Знайдіть заклад",
    "Find Successor Legal Entity": "Знайдіть заклад правонаступник",
    "Find by Dictionary code": "Знайти за кодом зі словника",
    "Find by divison": "Знайти за відділенням",
    "Find dictionary": "Знайти словник",
    "Find division": "Знайти відділення",
    "Find group": "Знайти групу",
    "Find legal entity": "Знайти заклад",
    "Find program": "Знайти програму",
    "Find related legal entity": "Знайти підпорядкований заклад",
    "First name": "Ім’я",
    "For dividing reorganization type two or more successor legal entities needs to be selected":
      'Для реорганізації з типом "Поділ" необхідно обрати два або більше закладів, що є правонаступниками',
    "Forbidden Group details": "Деталі забороненої групи",
    "Forbidden Groups": "Заборонені групи",
    "Forbidden group": "Заборонена група",
    "Forbidden group ID": "ID забороненої групи",
    "Forbidden group Service ID": "ID сервісу",
    "Forbidden group element code": "Введіть код",
    "Forbidden group name": "Назва групи",
    "Forbidden group sms url": "Посилання на пам'ятку для смс",
    "Forbidden groups": "Заборонені групи",
    ForbiddenGroups: "Заборонені групи",
    Form: "Форма",
    "Form of managment": "Форма господарювання",
    "Found:": "Знайдено:",
    Frequency: "Частота подій за період",
    "Frequency max": "Максимальна частота подій за період",
    "Frequency period": "Період частоти подій",
    From: "з",
    "Full name": "ПІБ",
    "Funding source": "Джерело фінансування",
    Gender: "Стать",
    "General info": "Загальна інформація",
    "General information": "Загальна інформація",
    "General verification status": "Загальний статус верифікації",
    "Get the new Request": "Отримати нову заяву",
    "Go to employees": "Перейти до працівників",
    "Go to the main legal entity": "Перейти до основного закладу",
    Goal: "Мета",
    "Group ID": "ID групи",
    "Group code": "Код групи",
    "Group deactivation": "Деактивація групи",
    "Group description": "Опис групи",
    "Group name": "Назва групи",
    "Healthcare Services": "Медичні послуги",
    "Healthcare service category": "Категорія послуги",
    "Healthcare service type": "Тип послуги",
    Hide: "Сховати",
    High: "Верхній діапазон",
    History: "Історія",
    Hospitalization: "Госпіталізація",
    "ICD10AM diagnoses allowed to create care plan activities":
      "Діагнози ICD10AM, з якими дозволено створення активностей плану лікування",
    "ICD10AM diagnoses allowed to create device requests":
      "Діагнози ICD10AM, з якими дозволено створення рецептів на медичні вироби",
    "ICD10AM diagnoses allowed to create medication requests or care plan activities":
      "Діагнози ICD10AM, з якими дозволено створення рецептів або активностей плану лікування",
    "ICPC2 diagnoses allowed to create care plan activities":
      "Діагнози ICPC2, з якими дозволено створення активностей плану лікування",
    "ICPC2 diagnoses allowed to create device requests":
      "Діагнози ICPC2, з якими дозволено створення рецептів на медичні вироби",
    "ICPC2 diagnoses allowed to create medication requests or care plan activities":
      "Діагнози ICPC2, з якими дозволено створення рецептів або активностей плану лікування",
    ID: "ID",
    INN: "ІПН",
    INNM: "МНН",
    "INNM Dosage ID": "ID лікарської форми",
    "INNM dosage ID": "ID лікарської форми",
    "INNM dosage details": "Деталі лікарської форми",
    "INNM dosage name": "Назва лікарської форми",
    "INNM dosages": "Лікарські форми",
    "INNM original name": "Оригінальна назва",
    "INNM quantity": "Кількість лікарської форми",
    INNMs: "МНН",
    "Identity Information": "Ідентифікаційна інформація",
    "Identity information": "Ідентифікаційна інформація",
    Immunization: "Щеплення",
    "Immunization ID": "ID Щеплення",
    "Immunization date": "Дата щеплення",
    "Immunization details": "Деталі щеплення",
    "Immunization expiration date": "Термін придатності",
    "Immunization status": "Статус щеплення",
    Immunizations: "Щеплення",
    "Import from file": "Завантажити з файлу",
    "In progress status": "В процессі",
    Inactive: "Неактивний",
    "Inactive licence": "Неактивна",
    "Included to groups": "Входить у групи",
    "Incoming referral": "Направлення",
    "Incoming referral ID": "ID направлення",
    "Ingredient ID": "ID складової",
    "Ingredient name": "Назва складової",
    Ingredients: "Складові",
    "Initial date of the contract": "Початкова дата дії договору",
    "Initial date of the declaration": "Початкова дата дії декларації",
    Innm: "МНН",
    "Innm dosage": "Лікарська форма",
    "InnmDosage ID": "ID лікарської форми",
    "InnmDosage name": "Лікарська форма",
    "Inserted at": "Додано",
    "Inserted by": "Змінено користувачем",
    "Institution name": "Навчальний заклад",
    Integer: "Ціле число",
    "Integration child identifier":
      "Ідентифікатор створеної ідентифікованої дитини",
    "Integration information": "Інтеграційна інформація",
    "Integration message": "Повідомлення",
    "Integration result": "Результат інтеграції",
    "Integration status": "Інтеграційний статус",
    "Integration update date": "Дата оновлення стану інтеграційного процесу",
    Intent: "Намір",
    Interpretation: "Інтерпретація",
    "Invalid ID": "Невірний ID",
    "Invalid birth date": "Невірна дата народження",
    "Invalid fields": "Помилки у файлі",
    "Invalid format": "Невірний формат",
    "Invalid number": "Некоректний номер",
    "Invalid percentage discount": "Некоректний % знижки",
    "Invalid phone number": "Некоректний номер телефону",
    "Invalid value of tax id": "Невірний формат ІПН",
    "Invalid verification code": "Некоректний верифікаційний код",
    "Is Dosage Form dosed": "Дозована лікарська форма",
    "Is EDR data actual": "Дані з ЄДР актуальні",
    "Is accident":
      "Чи цей випадок пов’язаний із професійною діяльністю працівника?",
    "Is active": "Активний",
    "Is composition": "Послуга пакетна",
    "Is employee active": "Працівник активний",
    "Is force renew": "Чи це новий випадок непрацездатності?",
    "Is foreign treatment": "Чи відбувалось лікування за кордоном?",
    "Is has declaration": "Наявна декларація",
    "Is intoxicated": "Чи виявлено алкогольну, наркотичну інтоксикацію?",
    "Is primary": "Основна",
    "Is request allowed": "Дозвіл на направлення",
    "Is restore": 'Ознака "Поновлений"',
    Issued: "Час обстеження",
    "Issued at": "Дата видачі",
    "Issued by": "Виданий",
    "Issued date": "Дата отримання",
    "Issued to": "Видана на",
    "It is impossible to sign the contract request, because the legal entity is inactive.":
      "Підписання неможливе, оскільки заклад неактивний.",
    "It is impossible to update the contract request, because the legal entity is inactive.":
      "Оновлення заяви неможливе, оскільки заклад неактивний.",
    "It is impossible to update the contract request, because the pharmacy is inactive.":
      "Оновлення заяви неможливе, оскільки аптека неактивна.",
    "Items comparator": "Оператор логічного поєднання правил",
    "Job ID": "ID задачі",
    "Job Start Time": "Час старту задачі",
    "Job details": "Деталі задачі",
    "Job information": "Інформація про роботу",
    "Job name": "Назва завдання",
    "Job start time": "Час старту задачі",
    "Job status": "Статус задачі",
    Jobs: "Задачі в процесі виконання",
    "Justification history": "Підстави пацієнта",
    "Justification reason type": "Тип підстави",
    KVED: "КВЕДи",
    Key: "Ключ",
    Kind: "Вид активності",
    "Last name": "Прізвище",
    "Last occurrence": "Остання поява",
    "Last operation date": "Дата останньої операції з АЗ",
    "Last operation name": "Найменування останньої операції з АЗ",
    "Leave a Comment": "Залишити коментар",
    "Legal Entities Reorganization": "Реорганізація закладів",
    "Legal Entity residence address": "Фактична адреса",
    "Legal entities": "Заклади",
    "Legal entity": "Заклад",
    "Legal entity EDRPOU or ID": "ЄДРПОУ або ID закладу",
    "Legal entity ID": "ID закладу",
    "Legal entity deactivate jobs": "Закриття закладів",
    "Legal entity details": "Деталі закладу",
    "Legal entity edrpou": "ЄДРПОУ закладу",
    "Legal entity is already selected on other step":
      "Заклад вже обрано на іншому кроці",
    "Legal entity license types allowed on dispense":
      "Типи ліцензій закладу необхідні для погашення рецепту",
    "Legal entity name": "Назва закладу",
    "Legal entity relation": "Договори реорганізованих закладів",
    "Legal entity reorganization jobs": "Реорганізація закладів",
    "Legal entity state": "Статус закладу",
    "Legal entity type": "Тип закладу",
    License: "Ліцензія",
    "License number": "Номер ліцензії",
    "License type": "Тип ліцензії",
    Licenses: "Ліцензії",
    "List of contracts": "Перелік договорів",
    "List of medical programs": "Перелік мед. програм",
    "List the conditions": "Перерахуйте умови (за наявності)",
    "Load more": "Завантажити більше",
    "Loading...": "Завантаження...",
    Location: "Місце надання послуг",
    "Location reference": "Місце надання послуг",
    "Lot number": "Серійний номер",
    Low: "Нижній діапазон",
    "Lower limit": "Нижня межа",
    "MC search": "Пошук МВ",
    "Main ingredient": "Складова з активною речовиною",
    "Main legal entity": "Основний заклад",
    "Main substance": "Основна речовина",
    "Make changes": "Внесіть зміни",
    "Managing organization": "Медичний заклад",
    "Managing organization ID": "ID медичного закладу",
    "Manual rules": "Ручні правила",
    "Manual unverified": "Не верифіковано за ручними правилами",
    "Manual verification comment":
      "Коментар до статусу верифікації за ручними правилами",
    "Manual verification status": "Статус верифікації за ручними правилами",
    "Manual verification status reason":
      "Причина статусу верифікації за ручними правилами",
    Manufacturer: "Виробник",
    "Manufacturer country": "Країна виробника",
    "Manufacturer name": "Назва виробника",
    "Master person": "Мастер персона",
    "Master person ID": "ID мастер персони",
    "Master person id": "ID мастер персони",
    "Max daily count": "Максимальна добова кількість застосування",
    "Max daily dosage": "Максимальна добова доза",
    "Maximum quantity for dispense of per one Medication request":
      "Максимальна кількість для відпуску в одному рецепті",
    "Medical composition": "Медичні висновки",
    "Medical composition change reason": "Причина зміни статусу",
    "Medical composition details": "Деталі медичного висновку",
    "Medical composition focus": "Особа, якій видано МВ",
    "Medical composition number": "Номер МВ",
    "Medical composition relation":
      "Тип посилання на повʼязаний МВ - Ідентифікатор повʼязаного МВ",
    "Medical composition relation type": "тип зв‘язку",
    "Medical composition search": "ПОШУК МЕДИЧНОГО ВИСНОВКУ",
    "Medical event name": "Назва медичного запису",
    "Medical institutions inpatient care": "Інші пакети",
    "Medical institutions primary care": "Капітація",
    "Medical program": "Медична програма",
    "Medical program ID": "ID медичної програми",
    "Medical program change on dispense allowed":
      "Зміну медичної програми при погашенні рецепту дозволено",
    "Medical program details": "Деталі медичної програми",
    "Medical program name": "Назва медичної програми",
    "Medical program type": "Тип медичної програми",
    "Medical programs": "Медичні програми",
    "Medical request": "Рецепт",
    Medication: "Лікарська форма",
    "Medication ID": "ID торгівельного найменування",
    "Medication Quantity": "Продано",
    "Medication care plan activity allowed":
      "Дозвіл на створення первинного призначення плану лікування",
    "Medication details": "Деталі медикамента",
    "Medication dispense": "Погашення рецепту",
    "Medication dispense ID": "ID Погашення рецепту",
    "Medication dispense allowed": "Допускається відпуск ліків",
    "Medication dispense details": "Лікарські засоби",
    "Medication dispense period": "Період дії відпуску рецепта",
    "Medication dispense period (days)": "Період погашення рецепту (дні)",
    "Medication dispense quantity": "Кількість відпущених ліків, одиниць",
    "Medication dispense status": "Статус погашення рецепту",
    "Medication dispenses": "Погашення рецептів",
    "Medication form": "Форма",
    "Medication manufacturer": "Виробник",
    "Medication name": "Торгівельне найменування",
    "Medication quantity": "Кількість препарату",
    "Medication registry jobs": "Завантаження реєстрів медикаментів",
    "Medication request": "Рецепт",
    "Medication request ID": "ID рецепту",
    "Medication request allowed": "Дозвіл на створення рецептів",
    "Medication request created": "Дата створення рецепту",
    "Medication request details": "Деталі рецепту",
    "Medication request ended at": "Дата закінчення",
    "Medication request legal entity": "Медичний заклад",
    "Medication request max period (days)":
      "Максимальний період курсу лікування за рецептом (дні)",
    "Medication request number": "Номер рецепту",
    "Medication request reject reason": "Причина відхилення",
    "Medication request request ID": "Запит на рецепт",
    "Medication request started at": "Дата початку",
    "Medication request status": "Статус рецепту",
    "Medication requests": "Рецепти",
    Medications: "Медикаменти",
    "Medications name": "Торгівельні найменування",
    Medicines: "Медикаменти",
    Merge: "Об’єднати",
    "Merge Legal entity reason": "Підстава підпорядкування",
    "Merge Merge Request": "Підтвердити об’єднання",
    "Merge Request ID": "ID заяви",
    "Merge history": "Історія дедублікації",
    "Merge legal entity":
      "Модифікація співробітників та декларацій, реорганізація закладу та підпорядкування",
    "Merge person": "Деактивована персона",
    Method: "Метод обстеження",
    Miscellaneous: "Інші умови",
    "Miscellaneous (depending on the type of medical service)":
      "Інше (залежно від виду медичних послуг)",
    "Missing value": "Відсутнє значення",
    Mixture: "Склад",
    "Mobile number": "Мобільний номер",
    "Mountain region": "Гірський регіон",
    "Must be greater than zero": "Значення має бути більше нуля",
    "Must be greater then zero positive integer number":
      "Має бути більшим за нуль додатним цілим числом",
    "Must be zero or positive number":
      "Значення має дорівнювати або бути більше нуля",
    NEW: "НОВА",
    "NHS Verified": " Верифікований НСЗУ",
    "NHS reviewed": "Опрацьовано НСЗУ",
    "NHS verified": "Верифікований НСЗУ",
    Name: "Назва",
    "Name of authentication method": "Назва методу аутентифікації",
    "Name of employee": "ПІБ працівника",
    "Name of medical institution": "Назва закладу",
    Names: "Назви",
    "Nested values": "Вкладені значення",
    New: "Новий",
    "Newborn date": "Дата народження дитини",
    "Newborn gender": "Стать дитини",
    Next: "Далі",
    No: "Ні",
    "No info": "Інформація відсутня",
    "No tax ID": "Відмова від ІПН",
    "Non EDR data": "Додаткова інформація надана закладом",
    "Not found": "Не знайдено",
    "Not given": "Не проведено",
    "Not verify": "Не верифікувати",
    Note: "Примітки",
    Number: "Номер",
    "Number end must be greater than number from":
      "Кінець періоду повинен бути більшим за початок",
    "Number of persons served by legal entity":
      "Кількість осіб, що обслуговуються закладом",
    "Number start must be less than number end":
      "Початок періоду повинен бути меншим за кінець",
    "Numerator unit": "Одиниці",
    "Numerator value": "Кількість",
    Numident: "РНОКПП",
    "OTP code": "OTP код",
    Observation: "Деталі обстеження",
    "Observation ID": "ID Обстеження",
    "Observation based on": "На основі направлення",
    "Observation code": "Код обстеження",
    "Observation details": "Деталі обстеження",
    "Observation period": "Час обстеження",
    "Observation status": "Статус обстеження",
    "Observation's encounter ID": "ID взаємодії",
    Observations: "Обстеження",
    Occurrence: "Виконати",
    Offset: "Хвилин до/після події",
    Ok: "Ок",
    "Onset date": "Дата початку",
    "Onset datetime": "Дата початку",
    "Order Date": "Дата наказу",
    "Order Number": "Номер наказу",
    Origin: "Початкове значення",
    "Origin episode": "Первинний епізод",
    "Origin episode ID": "ID первинного епізоду",
    Other: "Інше",
    "Other identifiers": "Інші ідентифікатори",
    Outcome: "Результат проведення процедури",
    "Outcome codeable concept": "Результати",
    "Outcome reference": "Призначення",
    "Own auth method third person": "Власний метод третьої персони",
    "Own authentication method": "Власний метод аутенифікації",
    "Own phone number": "Власний номер аутентифікаціі ",
    Owner: "Власник",
    PIB: "ПІБ",
    PROCESSED: "ОПРАЦЬОВАНО",
    Package: "Упаковка",
    "Package minimum quantity": "Мінімальна кількість в упаковці",
    "Package quantity": "Кількість в упаковці",
    Packaging: "Пакування",
    "Pair ID": "ID пари",
    "Paper referral": "Паперове направлення",
    "Parent ID": "ID батьківського значення",
    "Parent code": "Батьківське значення",
    "Parent group": "Батьківська група",
    "Partially dispense of medication request allowed":
      "Часткове погашення рецепту дозволено",
    "Participant ID": "ID учасника",
    "Participant status": "Статус учасника",
    Party: "Інформація про співробітника",
    "Party ID": "ID особи",
    "Party full name": "ФІО особи",
    "Party under processing": "Співробітник на опрацюванні",
    "Party verification": "Верифікація облікових записів співробітників",
    Passport: "Паспорт",
    Patient: "Пацієнт",
    "Patient ID": "ID пацієнта",
    "Patient IPN": "ІПН пацієнта",
    "Patient Name": "ПІБ пацієнта",
    "Patient Search": "Пошук пацієнтів",
    "Patient approvals": "Дозволи пацієнта",
    "Patient categories for whom care plan activity creation are allowed":
      "Категорії пацієнта, з якими дозволено створення активностей плану лікування",
    "Patient hash": "Хеш пацієнта",
    "Patient instruction": "Інструкція для пацієнта",
    "Patient notification on device medication request is disabled":
      "Сповіщення пацієнта при операціях з рецептом на медичний виріб вимкнено",
    "Patient notification on medication request is disabled":
      "Сповіщення пацієнта при операціях з рецептом вимкнено",
    "Patient's details": "Деталі пацієнта",
    "Patients Merge": "Об’єднання пацієнтів",
    "Patients data editing": "Редагування даних пацієнта",
    "Payment ID": "Ідентифікатор оплати",
    "Payment amount": "Загальна сума до сплати",
    "Payment method": "Спосіб оплати",
    "Pediatrician declaration limit":
      "Максимальна кількість декларацій для спеціальності Педіатр",
    "Pending declarations": "Декларації на розгляді",
    "Pending employees": "Співробітники на розгляді",
    Perform: "Обрати",
    "Performed date time": "Дата та час",
    Performer: "Виконавець",
    "Performer ID": "ID Користувача",
    "Performer full name": "ПІБ Користувача",
    "Performer type": "Спеціальність виконавця",
    Period: "Період",
    "Period max": "Максимальний період частоти подій",
    "Period unit": "Одиниця періоду частоти подій",
    "Permanent unit": "Штатна одиниця",
    "Permitted resources": "Дозволені медичні записи",
    "Person ID": "ID персони",
    "Person age is wrong": "Вік людини неправильний",
    "Person can't use himself as third person":
      "Особа не може бути в себе у аутентифікаціних методах у якості третьої персони",
    "Person deactivation": "Деактивація пацієнта",
    "Person id": "ID персони",
    "Person should have atleast one address":
      "Персона повинна мати хоча б одну адресу",
    "Person should have atleast one document":
      "Персона повинна мати хоча б один документ",
    "Person should have atleast one phone number":
      "Персона повинна мати хоча б один номер телефону",
    "Person should have atleast one recidence address":
      "Персона повинна мати хоча б одну адерсу проживання",
    "Person status": "Статус персони",
    "Person under processing": "Пацієнт на опрацюванні",
    "Person verification changes history": "Історія змін верифікації пацієнта",
    "Person waived of tax ID": "Персона відмовилася від ІПН",
    "Person's documents": "Документи паціента",
    "Personal information": "Особиста інформація",
    Persons: "Пацієнти",
    "Persons deactivation jobs": "Деактивація паціентів",
    "Pharmaceutical form": "Фармацевтична форма",
    Pharmacies: "Реімбурсація",
    Pharmacy: "Аптека",
    "Pharmacy ID": "ID аптеки",
    Phone: "Телефон",
    "Phone number": "Номер телефону",
    "Phone number auth limit":
      "Гранична кількість декларацій зареєстрованих на один телефон",
    "Phone number does not match third person's phone number":
      "Номер телефону не відповідає номеру телефону третьої особи",
    "Phone number should be specified. Third person has OTP method":
      "Необхідно вказати номер телефону. Третя особа має OTP метод",
    "Phone type": "Тип",
    Phones: "Телефони",
    "Place of birth": "Місце народження",
    "Place of receipt": "Місце отримання",
    Position: "Посада",
    Postpone: "Відкласти",
    "Postpone Merge Request": "Відкласти рішення",
    "Practical monitoring": "Фактичний моніторинг",
    "Pre-admission identifier": "Ідентифікатор попередньої госпіталізації",
    "Preferred way communication": "Бажаний метод комунікації",
    "Preferred way of communication": "Бажаний метод комунікації",
    "Prescription creation": "Дозвіл на створення рецептів",
    Prescriptions: "Призначення",
    "Previous request ID": "ID попередньої заяви",
    "Previous version": "Попередня версія",
    Price: "Ціна",
    Primary: "Діюча речовина",
    "Primary Confidant Persons": "Основна довірена особа",
    "Primary confidant person's documents":
      "Документи основної довіреної особи",
    "Primary confidant person's relationship documents, which confirms the authority":
      "Документи основної довіреної особи, що підтверджують повноваження представника",
    "Primary source": "Первинне джерело даних",
    Print: "Роздрукувати",
    Priority: "Пріорітет",
    Procedure: "Процедура",
    "Procedure ID": "ID Процедури",
    "Procedure based on": "На основі направлення",
    "Procedure details": "Деталі процедури",
    "Procedure reason reference": "Посилання на причину",
    "Procedure status": "Статус процедури",
    "Procedure status reason": "Причина статусу",
    "Procedure's encounter ID": "ID взаємодії",
    Procedures: "Процедури",
    Processed: "Оброблено",
    "Product ID in DRLZ": "ID продукту в ДРЛЗ",
    "Product ID in DRLZ register": "ID продукту в ДРЛЗ реєстрі",
    Program: "Медична програма",
    "Program Participants": "Учасники програм",
    "Program device ID": "ID учасника",
    "Program device details": "Деталі учасника програми медичних виробів",
    "Program device end date": "Діє по",
    "Program device start date": "Діє з",
    "Program device status": "Статус",
    "Program devices": "Учасники медичних програм з видачі медичних виробів",
    "Program medication ID": "ID учасника програми",
    "Program medication details": "Деталі учасника медичної програми",
    "Program medication end date": "Діє по",
    "Program medication start date": "Діє з",
    "Program medications": "Учасники медичних програм з видачі медикаментів",
    "Program processing status": "Статус обробки за програмою",
    "Program service details": "Деталі програми послуг",
    "Program services": "Учасники медичних програм з надання послуг",
    Properties: "Характеристики мед виробу",
    "Property type": "Тип власності",
    "Provide justification": "Вкажіть підставу",
    "Providing Condition": "Умови надання",
    "Qualification type": "Тип кваліфікації",
    Qualifications: "Кваліфікації",
    Quantity: "Загальна кількість",
    "Query builder result": "Результат",
    Rank: "Ступінь",
    "Re-admission": "Ознака повторної госпіталізації",
    Reactions: "Реакції",
    "Realization reasons": "Причини проведення",
    Reason: "Причина",
    "Reason code": "Діагнози",
    "Reason for change settings": "Причина зміни налаштувань",
    "Reason of activation contract": "Причина активації",
    "Reason of adding": "Причина додавання елементів",
    "Reason of creation": "Причина створення",
    "Reason of deactivation": "Причина деактивації елементів",
    "Reason of deactivation contract": "Причина призупинення",
    "Reason of deactivation person": "Підстава деактивації",
    "Reason of editing": "Причина редагування",
    "Reason of group deactivation": "Причина деактивації групи",
    "Reason of practical monitoring": "Підстава перегляду",
    "Reason of termination": "Причина розірвання",
    "Reason reference": "Причини",
    "Reason status": "Причина статусу",
    "Reason type": "Тип підстави",
    Reasons: "Причини звернення",
    "Reasons not given": "Причини не проведення",
    "Reception Address": "Адреса приймальні",
    "Reception address": "Адреса приймальної",
    Recipes: "Рецепти",
    "Record ID in EDDR": "ID Запису в ЄДДР",
    "Record type": "Тип запису",
    "Recorded by": "Записано співробітником",
    Reference: "Посилання",
    "Reference ranges": "Референтні значення",
    Refresh: "Оновити",
    Region: "Область",
    "Register type": "Тип реєстру",
    Registers: "Реєстри",
    "Registers entries": "Записи реєстру",
    Registration: "Реєстрація",
    "Registration address": "Адреса реєстрації",
    "Registry number": "Номер реєстру",
    "Reimbursement amount": "Сума відшкодування",
    "Reimbursement amount for the package of the medical product":
      "Розмір відшкодування за упаковку лікарського засобу",
    "Reimbursement amount per unit of the medical product":
      "Розмір відшкодування за одиницю лікарського засобу",
    "Reimbursement amount with currency": "Сума відшкодування, грн",
    "Reimbursement contract max period":
      "Максимальна кількість днів, на яку може бути укладено договір реімбурсації",
    "Reimbursement contract type": "Договір з аптекою",
    "Reimbursement daily count":
      "Розмір відшкодування добової дози медичного виробу",
    "Reimbursement daily dosage":
      "Розмір відшкодування добової дози лікарського засобу",
    "Reimbursement daily dosage update": "Розмір відшкодування добової дози",
    "Reimbursement percentage discount": "% знижки",
    "Reimbursement report": "Звіт",
    "Reimbursement type": "Тип реімбурсації",
    Reject: "Відхилити",
    "Reject verification of Patient's personal data":
      "Відмова у верифікації персональних даних пацієнта",
    Rejected: "Відхилений",
    "Rejected at": "Відхилено",
    "Rejected by": "Відхилив користувач",
    "Related Medical compositions": "Повʼязані МВ",
    "Related legal entity": "Підпорядкований заклад",
    "Relation type": "Тип довіреної особи",
    "Relationship Documents": "Документи про повноваження",
    "Relationship documents": "Документи про повноваження",
    "Remaining quantity": "Кількість, що залишилася",
    "Reorganization Type": "Тип реорганізації",
    "Reorganization data": "Дані реорганізації",
    "Reorganization reason": "Підстава для проведення реорганізації",
    "Reorganization reason date": "Дата юридичного рішення з реорганізації",
    "Reorganization type": "Тип реорганізації",
    "Reorganization with type TRANSFORMATION is not allowed for selected legal entity":
      'Реорганізація з типом "Перетворення" не дозволена для обраного закладу',
    "Reorganize legal entity": "Реорганізація закладу",
    "Reorganized Legal Entity edrpou": "ЄДРПОУ реорганізованого закладу",
    "Reorganized Legal Entity name": "Назва реорганізованого закладу",
    "Reorganized legal entity": "Заклад, що реорганізовується",
    Repeat: "Періодичність",
    "Report origin": "Походження запису",
    Reports: "Звіти",
    "Request Approval": "Запросити дозвіл",
    "Request allowed": "Дозвіл на створення рецептів",
    "Request details": "Деталі заяви",
    "Request number": "Номер",
    "Request status": "Статус заяви",
    "Requester employee": "Ініціатор",
    "Requester legal entity": "Медичний заклад ініціатора",
    "Requester legal entity ID": "ID закладу ініціатора направлення",
    "Required field": "Обов'язкове поле",
    Requisition: "Номер",
    "Resend SMS": "Перевідправити СМС",
    "Resend medical composition": "Повторно надіслати в ЕРЛН",
    Reset: "Скинути пошук",
    "Reset Authentication Methods": "Скинути методи аутентифікації",
    "Reset authentication method": "Скинути метод аутентифікації",
    "Reset authentication method for person with id":
      "Скинути метод аутентифікації для особи з ID",
    "Reset persons authentication method":
      "Скинути метод аутентифікації у паціентів",
    "Reset persons authentication method jobs":
      "Задачі по скиданню методу аутентифікації у паціентів",
    "Reset registers": "Завантажити файл для оновлення реєстру",
    "Reset the sign of refusal and ID": "Скинути ІПН та ознаку відмови від ІПН",
    "Residence Address": "Фактична адреса",
    "Residence address": "Адреса проживання",
    "Resource ID": "ID медичного запису",
    "Resource Type": "Об'єкт",
    "Resource type": "Об'єкт",
    "Result interpreter": "Інтерпретатор результату",
    Return: "Повернутися",
    Role: "Роль",
    Route: "Шлях введення",
    Rule: "Правило",
    "Rule engine rule": "Набір механізму правил",
    "Rule engine rule ID": "ID набору",
    "Rule engine rule details": "Деталі набору механізмів правил",
    "Rule engine rule title": "Назва набору",
    "Rule engine rules": "Набори механізмів правил",
    "Rule item will be deleted. Are you sure?":
      "Правило буде видалене. Ви впевнені?",
    "Rule tab": "Правила",
    SCTID: "SCTID",
    "SCTID of INNM": "МНН SCTID",
    Save: "Зберегти",
    "Save and Return": "Зберегти та повернутися",
    Scheduled: "Заплановано до виконання",
    "Scheduled status": "Запланована",
    "Science degree": "Наукова ступінь",
    Search: "Шукати",
    "Search by INNM ID": "Пошук за ID МНН",
    "Search by SCTID": "Пошук за SCTID",
    "Search by code": "Пошук за кодом",
    "Search by declaration number": "Пошук за номером декларації",
    "Search by name": "Пошук за назвою",
    "Search candidates merge requests": "Пошук кандидатів на об'єнання",
    "Search conclusions": "Пошук заключень",
    "Search contract": "Пошук договору",
    "Search contract request": "Пошук заяви",
    "Search for Declarations": "Пошук декларацій",
    "Search for a value": "Пошук значення",
    "Search legal entities": "Пошук закладів",
    "Search legal entity by EDRPOU": "Пошук закладу за ЄДРПОУ",
    "Search program devices": "Список учасників медичних програм",
    "Search program medications": "Список учасників медичних програм",
    "Search reorganized legal entity": "Знайти реорганізований заклад",
    "Search reorganized legal entity steps":
      "Знайти заклад, що буде реорганізовано",
    "Search successor legal entity": "Знайти заклад, що є правонаступником",
    "Second name": "По-батькові",
    "Secondary Confidant Persons": "Додаткова довірена особа",
    "Secondary confidant person's documents":
      "Документи додаткової довіреної особи",
    "Secondary confidant person's relationship documents, which confirms the authority":
      "Документи додаткової довіреної особи, що підтверджують повноваження представника",
    "Secret word": "Секретне слово",
    Select: "Обрати",
    "Select a match from the list to compare with the party's personal information":
      "Оберіть кандидата зі списку для порівняння з особистою інформацією співробітника",
    "Select a match from the list to compare with the patient's personal information":
      "Оберіть кандидата зі списку для порівняння з особистою інформацією пацієнта",
    "Select a tag": "Виберіть тег",
    "Select category": "Оберіть категорію",
    "Select conclusion": "Виберіть заключення",
    "Select document type": "Тип документу",
    "Select entity type.": "Оберіть тип суб'єкта",
    "Select key": "Виберіть ключ",
    "Select kind": "Виберіть вид активності",
    "Select option": "Оберіть опцію",
    "Select parent code": "Виберіть батьківське значення",
    "Select person status": "Оберіть статус",
    "Select reason": "Виберіть статус",
    "Select resource type": "Оберіть об'єкт",
    "Select status": "Оберіть статус",
    "Select tag from list": "Оберіть тег зі списку",
    "Select the menu item on the left to get started!":
      "Оберіть пункт меню ліворуч, щоб почати роботу!",
    "Sell amount": "Вартість продажу",
    "Sell price": "Вартість одиниці",
    "Send medical composition": "Надіслати в ЕРЛН",
    "Separate legal entity":
      "Модифікація співробітників, договору та декларацій закладу",
    "Serial number": "Серійний номер",
    Series: "Етап вакцинації",
    "Series doses": "Кількість доз по протоколу",
    Service: "Сервіс",
    "Service Code": "Знайти за кодом сервісу",
    "Service Group": "Група сервісів",
    "Service Group Code": "Знайти за кодом групи сервісів",
    "Service Group ID": "ID сервіс групи",
    "Service Group Name": "Назва групи сервісів",
    "Service ID": "ID сервісу",
    "Service Name": "Назва сервісу",
    "Service Request requisition": "Номер направлення",
    "Service code": "Код сервісу",
    "Service details": "Деталі сервісу",
    "Service filters": "Сервіси",
    "Service group": "Група сервісів",
    "Service group ID": "ID групи сервісів",
    "Service group details": "Деталі групи сервісів",
    "Service group filters": "Група сервісів",
    "Service group name": "Назва групи сервісів",
    "Service groups": "Групи сервісів",
    "Service name": "Назва сервісу",
    "Service or service group ID": "ID сервісу або групи сервісів",
    "Service request": "Направлення",
    "Service request ID": "ID Направлення",
    "Service request details": "Деталі направленння",
    "Service request expiration date": "Діє до",
    "Service request performer": "Медичний заклад виконавця",
    "Service request status": "Статус направлення",
    "Service requests": "Направлення",
    Services: "Сервіси",
    Settings: "Налаштування",
    Settlement: "Населений пункт",
    "Settlement of birth": "Місце народження",
    "Settlement type": "Тип населеного пункта",
    Severity: "Ступінь тяжкості стану",
    "Short name": "Коротка назва",
    Show: "Показати",
    "Show all": "Показати всі",
    "Show all filters": "Показати всі фільтри",
    "Show declarations": "Перейти до декларацій",
    "Show detailed information": "Показати детальну інформацію",
    "Show details": "Показати деталі",
    "Show division": "Показати відділення",
    "Show person verification history": "Переглянути історію верифікації",
    "Show printout form": "Дивитись друковану форму",
    Sign: "Підписати",
    "Signatory from the Customers side": "Підписант зі сторони Замовника",
    "Signed at declaration": "Дата підписання декларації",
    "Signer ID": "ID підписанта",
    "Signer base": "Підстава",
    "Signer name": "Ім'я підписанта",
    "Signing by EDS and seal": "Підписати, наклавши ЕЦП та Печатку",
    Site: "Місце введення",
    "Skip dispense division DLS verify":
      "Не перевіряти наявність ДЛС ліцензії при погашенні рецепту (або призначення на мед. виріб)",
    "Skip employee validations": "Не перевіряти співробітніків",
    "Something went wrong. Please try again later":
      "Щось пішло не так. Спробуйте пізніше",
    Specialities: "Спеціальності",
    Speciality: "Спеціальність",
    "Speciality level": "Категорія",
    "Speciality type": "Тип спеціальності",
    "Speciality types allowed to create care plan activities":
      "Типи спеціальностей, з якими дозволено створення активностей плану лікування",
    "Speciality types allowed to create device requests":
      "Типи спеціальностей, з якими дозволено створення рецептів на медичні вироби",
    "Speciality types allowed to create medication requests or care plan activities":
      "Типи спеціальностей, з якими дозволено створення рецептів або активностей плану лікування",
    Specialty: "Спеціальність",
    "Specify the Reorganization Data": "Вкажіть дані",
    "Specify the basis": "Вкажіть підставу",
    "Specify the basis of deactivation person": "Вкажіть підставу деактивації",
    "Specify the merge reason": "Вкажіть підставу підпорядкування",
    "Specify the reason of practical monitoring": "Введіть підставу перегляду",
    "Specify the reorganization reason":
      "Вкажіть підставу для проведення реорганізації",
    Split: "Відхилити",
    "Split Merge Request": "Відхилити заяву",
    Stage: "Стадія захворювання",
    "Start date": "Дата початку роботи",
    "Start date must be earlier than end date":
      "Дата початку має бути меншою ніж дата закінчення",
    "Start verification": "Розпочати верифікацію",
    Started: "Дата початку",
    "Started at": "Час старту задачі",
    "Stationary number": "Стаціонарний номер",
    Statistic: "Статистика",
    Status: "Статус",
    "Status Comment": "Коментар до статусу",
    "Status group": "Статус групи",
    "Status groups element": "Статус елемента групи",
    "Status history": "Історія статусів",
    "Status is changing": "процесс зміни мв",
    "Status loading": "Запит відправлено, сторінка оновлюється",
    "Status of Healthcare Service": "Статус виду медичної послуги",
    "Status of confirmation": "Cтатус підтвердження",
    "Status reason": "Причина зміни договору",
    "Status reason comment": "Коментар щодо зміни договору",
    "StatusHistory status reason": "Причина статусу",
    "Storage location": "Місце зберігання",
    Street: "Вулиця",
    "Street type": "Тип вулиці",
    String: "Текст",
    Subdivision: "Підрозділ",
    Subdivisions: "Підрозділи",
    Subgroups: "Підгрупи",
    "Subscriber Name": "ПІБ підписанта",
    "Successor legal entity": "Заклад, що є правонаступником",
    "Such person doesn't exist": "Такої персони не існує",
    "Such person isn't active": "Така персона неактивна",
    "Supporting info": "Супровідна інформація",
    "Suspend Legal Entity": "Призупинити заклад",
    "Suspend contract": "Призупинити договір",
    Suspended: "Призупинений",
    Synchronized: "Синхронізовано",
    Tags: "Теги",
    "Target diseases": "Протидія загрозам",
    "Task meta": "Деталі задачі",
    "Task type": "Тип задачі",
    "Tax ID": "ІПН",
    "Tax ID existance": "Наявність ІПН",
    "Terminate contract": "Розірвати договір",
    "Terminate declaration": "Розірвати декларацію",
    Terminated: "Припинений",
    "Terms of service": "Умови супроводження",
    Text: "Текст",
    "The city of the conclusion of the contract": "Місто укладення договору",
    "The contract is valid for": "Договір діє по",
    "The contract is valid with": "Договір діє з",
    "The death of the patient is confirmed": "Смерть пацієнта підтверджено",
    "The header row must contain three fields: type, number, death_date":
      "Перший рядок файлу має містити обов'язкові поля 'type' і 'number', та може містити опціональне поле 'death_date'",
    "The record does not contain changes to personal data":
      "Запис не містить змін по персональним даним",
    "Therapist declaration limit":
      "Максимальна кількість декларацій для спеціальності Терапевт",
    "There are no documents": "Документи відсутні",
    "Third person": "Третя персона",
    "Third person ID": "ID Третьої персони",
    "Third person details": "Деталі третої персони",
    "Third person full name": "Повне ім'я третьої персони",
    "Third person must be adult": "Третя особа має бути повнолітньою",
    "Third person must have auth method either OTP or OFFLINE":
      "Третя особа повинна мати метод аутентифікації: OTP або OFFLINE",
    "Third person's documents": "Документи третьої персони",
    "This confidant person type is used more than once":
      "Тип третьої особи використаний більше одног разу",
    "This document type is used more than once":
      "Тип документу використано більше одного разу",
    "This fiduciary person is present more than 6 times in the system":
      "Ця довірена особа присутня в системі більше 6 разів",
    "This number is used more than once":
      "Номер телефону використано більше одного разу",
    "This third person is already used as auth method":
      "Обрана третя особа вже використовується як метод авторизації",
    "This type is used more than once":
      "Тип телефону використано більше одного разу",
    "This value is used more than once": "Це значення вже використовується",
    "Time of day": "Час дня події",
    Title: "Заголовок",
    To: "по",
    "To phone number": "На номер телефону",
    "To process": "Опрацювати",
    'To see the services, click on "Show division"':
      'Для ознайомлення з послугами натисніть "Показати відділення"',
    "Total found": "Усього знайдено",
    "Trade name": "Торгівельне найменування",
    "Transform legal entity": "Модифікація договору та реорганізація закладу",
    Trash: "Видалити",
    "Trash Merge Request": "Підтвердити видалення",
    "Treatment violation": "Чи встановлено порушення режиму лікування?",
    "Treatment violation date": "Дата порушення режиму лікування",
    Type: "Тип",
    "Type of Medication request blank": "Тип рецептурного бланку",
    "Type of medication program request blank":
      "Тип рецептурного бланку програми",
    UNZR: "ID Запису в ЄДДР",
    Units: "Одиниці",
    "Unsuspend contract": "Активувати договір",
    Unverified: "Не верифіковано",
    Update: "Оновити",
    "Update Authentication Method": "Оновити метод аутентифікації",
    "Update contract": "Оновити договір",
    "Update device definition names": "Оновити назви медичних виробів",
    "Update medical program settings": "Оновити параметри програми",
    "Update program medication": "Оновлення учасника програми",
    "Update reimbursement data": "Оновити параметри реімбурсації",
    "Update rule": "Оновити правило",
    Updated: "Оновлено",
    "Updated at": "Оновлено",
    "Updating employee": "Оновлення співробітника",
    Upload: "Завантажити",
    "Upper limit": "Верхня межа",
    "Used by employee": "Використаний співробітником",
    "Used by legal entity": "Використаний медичним закладом",
    "Used by legal entity ID": " ID закладу, що використав направлення",
    "User ID": "ID користувача",
    Users: "Користувачі",
    "Vaccination protocols": "Протокол вакцинації",
    "Vaccine code": "Код вакцини",
    "Valid to date": "Дійсний до",
    "Validate Date": "Термін дії",
    "Validation failed": "Помилка перевірки",
    Value: "Значення",
    "Value is not unique by 'code'": "Значення з таким ключем вже існує",
    "Values are not unique by 'type'.": "Назва типу медичного виробу вже існує",
    Verification: "Верифікація",
    "Verification NZZU": "Верифікація НСЗУ",
    "Verification comment": "Коментар до статусу верифікації",
    "Verification legal entity": "Верифікувати заклад",
    "Verification of Party's personal data":
      "Верифікація персональних даних співробітника",
    "Verification of Patient's personal data":
      "Верифікація персональних даних пацієнта",
    "Verification of patients": "Верифікація пацієнтів",
    "Verification reason": "Причина статусу верифікації",
    "Verification request expiration (days)":
      "Кількість днів до автоматичного затвердження декларації",
    "Verification result": "Результат верифікації",
    "Verification status": "Статус верифікації",
    Verified: "Верифіковано",
    "Verified by NZZU": "Верифікований НСЗУ",
    Verify: "Верифікувати",
    "Verify approval": "Верифікувати дозвіл",
    View: "Переглянути",
    "Viewing a dictionary value": "Перегляд значення словника",
    "Visit ID": "ID візиту",
    'Warning! <0/>After setting of verification status to "Not verified", this action cannot be reversed':
      'Увага! Після встановлення статусу "Не верифіковано" цю дію не можна буде скасувати',
    'Warning! <0/>After setting of verification status to "Verified", this action cannot be reversed':
      'Увага! Після встановлення статусу "Верифіковано" цю дію не можна буде скасувати',
    "Warning! Common access politics for some medical events which contains elements listed below will be restored!":
      "Увага! Звичайний доступ до деяких медичних записів, що містять елементи перелічені нижче, буде відновлено!",
    "Warning! Common access politics for some medical events which contains elements listed below will not work!":
      "Увага! Звичайний доступ до деяких медичних записів, що містять елементи перелічені нижче, буде заборонений!",
    "Warning! Forbidden group and all nested items will be deactivated!":
      "Увага! Заборонену групу та всі її елементи буде деактивовано! Звичайний доступ до деяких медичних записів, що містять елементи перелічені нижче, буде відновлено!",
    "Warning! Legal entities reorganization is an irreversible procedure, be sure to check the correctness of the data entered before confirmation":
      "Увага! Реорганізація закладів є незворотною процедурою, обов’язково перевірте правильність введених даних перед підтвердженням",
    "Warning!<0/>After confirmation, appropriate authentication method will be deactivated.":
      "Увага!<0/>Після підтвердження відповідний метод автентифікації буде деактивовано",
    "Warning!<0/>After confirmation, this authentication method will be created.":
      "Увага!<0/>Після підтвердження буде створено новий метод автентифікації.",
    "Warning!<0/>After confirmation, your own authentication method will be changed to the uncertain. All third party authentication methods will become invalid.":
      "Увага! <0/> Після підтвердження, власний метод аутентифікації буде змінено на невизначений. Усі методи аутентифікації через третю персону стануть недіючими",
    "Warning!<0/>By approving the request, CONFIRMS the validity of their own intentions, and also that the content of the transaction is in accordance with your authority, accepted and signed personally by you.":
      "Увага!<0/>Затверджуючи запит, ПІДТВЕРДЖУЄТЕ дійсність власних намірів , а також що зміст правочину ВІДПОВІДАЄ ВАШІЇЙ ВОЛІ, ПРИЙНЯТИЙ ТА ПІДПИСАНИЙ ОСОБИСТО ВАМИ.",
    Website: "Веб-сторінка",
    When: "Період часу настання події",
    "Wholesale price": "Оптово-відпускна ціна за упаковку",
    "Wholesale price per unit": "Оптово-відпускна ціна за одиницю",
    "Work schedule": "Графік роботи",
    "Working experience": "Досвід роботи",
    Yes: "Так",
    "ZIP code": "Поштовий індекс",
    apartment: "Квартира",
    "approve by EDS": "Підтвердіть з КЕП",
    area: "область",
    building: "будівля",
    "contract of {0} legal entity": ["Договори ", ["0"], " закладу"],
    country: "країна",
    duration: "Тривалість події",
    edit: "змінити",
    email: "Електронна адреса",
    from: "з",
    "from service group": "з групи сервісів",
    includes: "містить",
    mg: "мг",
    "phone number": "номер телефону",
    region: "район",
    settlement: "місто",
    "settlement type": "тип населеного пункту",
    street: "вулиця",
    "street type": "тип вулиці",
    till: "до",
    to: "по",
    type: "тип",
    uah: "грн",
    zip: "поштовий індекс",
    "{0, plural, zero {# секунд} one {# секунда} few {# секунди} many {# секунд} other {# секунд}}":
      [
        [
          "0",
          "plural",
          {
            zero: ["#", " секунд"],
            one: ["#", " секунда"],
            few: ["#", " секунди"],
            many: ["#", " секунд"],
            other: ["#", " секунд"]
          }
        ]
      ],
    "{0}": [["0"]],
    "{item}": [["item"]],
    "Рецепти legacy": "Рецепти legacy",
    "Сertainty status": "Статус достовірності",
    Сoincidence: "Співпадіння",
    "Сondition ID": "ID Діагнозу"
  }
};
