import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";

export default system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2,
    cursor: "pointer"
  }
);
