import React from "react";
import { matchSorter } from "match-sorter";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";

import { MultiDownshift } from "./DownshiftField";
import Dropdown from "../Dropdown";
import * as MultiSelectView from "./MultiSelectView";
import * as FieldView from "./FieldView";
import * as InputView from "./InputView";
import ErrorTranslation from "./ErrorTranslation";

const MIN_SEARCH_LENGTH = 3;

export type ItemType = {
  key: string;
  value: string;
};

export type ItemTypes = ItemType[];

type MultiSelectProps = {
  onChange?: (items: ItemTypes) => void;
  onInputValueChange?: (searchString: string) => void;
  selectedItems?: ItemTypes;
  items: ItemTypes;
  renderItem?: (item: ItemType) => string | ItemType;
  withoutFormField?: boolean;
  hideErrors?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  hint?: string;
  filter?: (
    items: ItemTypes,
    inputValue: string,
    filterOptions?: any
  ) => ItemTypes;
  warning?: string;
  name?: string;
  filterOptions?: any;
};

const MultiSelect = ({
  label,
  hint,
  warning,
  items = [],
  placeHolder = "Вибрати",
  name,
  filterOptions,
  filter = matchSorter,
  renderItem = (item: ItemType) => item,
  hideErrors = false,
  withoutFormField = false,
  onInputValueChange,
  disabled,
  ...props
}: MultiSelectProps) => (
  <MultiDownshift
    itemToString={() => ""}
    name={name}
    withoutFormField={withoutFormField}
    {...props}
  >
    {({
      getRootProps,
      getInputProps,
      getRemoveButtonProps,
      removeItem,
      isOpen,
      inputValue,
      selectedItems,
      getItemProps,
      toggleMenu,
      highlightedIndex,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      meta: { active, errored, error } = {}
    }: $TSFixMe) => (
      <FieldView.Wrapper
        {...getRootProps({
          refKey: "ref"
        })}
      >
        {label && (
          <FieldView.Header>
            <FieldView.Label>{label}</FieldView.Label>
            {hint && <FieldView.Message>{hint}</FieldView.Message>}
          </FieldView.Header>
        )}
        <InputView.Border
          position="relative"
          variant={errored && !hideErrors && "errored"}
        >
          {selectedItems.map((item: ItemType) => (
            <MultiSelectView.SelectedItem key={item.key || item}>
              {renderItem(item)}
              {!disabled && (
                <MultiSelectView.RemoveItem {...getRemoveButtonProps({ item })}>
                  <RemoveItemIcon />
                </MultiSelectView.RemoveItem>
              )}
            </MultiSelectView.SelectedItem>
          ))}
          {!disabled && (
            <InputView.Content
              {...getInputProps({
                is: "input",
                px: 2,
                width: 0,
                placeholder: selectedItems.length > 0 ? "" : placeHolder,
                onFocus: toggleMenu,
                onKeyUp(event: React.KeyboardEvent) {
                  if (event.key === "Backspace" && !inputValue) {
                    removeItem(selectedItems[selectedItems.length - 1]);
                  }
                  if (
                    onInputValueChange &&
                    inputValue &&
                    inputValue.length >= MIN_SEARCH_LENGTH
                  ) {
                    onInputValueChange(inputValue);
                  }
                }
              })}
            />
          )}
          {isOpen && (
            <MultiSelectView.List>
              {filter(items, inputValue, filterOptions).map(
                (item, index) =>
                  !selectedItems.find(
                    (selectedItem: ItemType) => selectedItem.key === item.key
                  ) && (
                    <Dropdown.Item
                      {...getItemProps({
                        key: index,
                        index,
                        item,
                        on: highlightedIndex === index ? "true" : ""
                      })}
                    >
                      {renderItem(item)}
                    </Dropdown.Item>
                  )
              )}
            </MultiSelectView.List>
          )}
        </InputView.Border>

        {!hideErrors && (
          <FieldView.Footer>
            <FieldView.Message variant={errored && "errored"}>
              {errored ? <ErrorTranslation error={error} /> : warning}
            </FieldView.Message>
          </FieldView.Footer>
        )}
      </FieldView.Wrapper>
    )}
  </MultiDownshift>
);

export default MultiSelect;
