import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicalProgram } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type GeneralInfoProps = RouteComponentProps & {
  name: MedicalProgram["name"];
  insertedAt: MedicalProgram["insertedAt"];
  type: MedicalProgram["type"];
  fundingSource: MedicalProgram["fundingSource"];
  mrBlankType: MedicalProgram["mrBlankType"];
};

const GeneralInfo = ({
  name,
  insertedAt,
  type,
  fundingSource,
  mrBlankType
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        name: <Trans>Medical program name</Trans>,
        type: <Trans>Type</Trans>,
        fundingSource: <Trans>Funding source</Trans>,
        ...(!isEmpty(mrBlankType) && {
          mrBlankType: <Trans>Type of Medication request blank</Trans>
        }),
        insertedAt: <Trans>Inserted at</Trans>
      }}
      data={{
        name,
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        type: <DictionaryValue name="MEDICAL_PROGRAM_TYPE" item={type} />,
        fundingSource: (
          <DictionaryValue name="FUNDING_SOURCE" item={fundingSource} />
        ),
        mrBlankType: (
          <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
        )
      }}
    />
  </Box>
);

GeneralInfo.fragments = {
  entry: gql`
    fragment MedicalProgramGeneralInfo on MedicalProgram {
      name
      insertedAt
      type
      fundingSource
      mrBlankType
    }
  `
};

export default GeneralInfo;
