import React, { useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import createDecorator from "final-form-calculate";
import Composer from "react-composer";
import { Box, Flex, Text } from "@rebass/emotion";
import { isEqual, isEmpty, get } from "lodash";

import {
  Form,
  LocationParams,
  SUBMIT_ERROR,
  Validations,
  Validation
} from "@edenlabllc/ehealth-components";
import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  getFullName,
  convertObjectKeys,
  fieldNameDenormalizer,
  filterObjectKeys,
  cleanDeep
} from "@edenlabllc/ehealth-utils";
import {
  Address as TAddress,
  ConfidantPerson,
  Maybe,
  Person,
  PersonDocument,
  Phone
} from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Steps from "../../../../components/Steps";
import { TLocationParams } from "../../../../components/SearchForm";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

import filteredLocationParams from "../../../../helpers/filteredLocationParams";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";
import {
  PERSON_NAME_PATTERN,
  PERSON_TAX_ID_PATTERN,
  UNZR_PATTERN,
  ADDRESS_ITEM_PATTERN,
  SECRET_WORD_PATTERN,
  EMAIL_PATTERN
} from "../../../../constants/validationPatterns";
import { AUTH_METHODS_FILTER_DEFAULTS } from "../../../../constants/filterValues";

import UserInfo from "../../Details/UserInfo";
import { PersonQuery } from "../../Details";
import PhonesForm from "./PhonesForm";
import ConfidantPersonsForm from "./ConfidantPersonsForm";
import DocumentsForm from "./DocumentsForm";
import AddressesForm from "./AddressesForm";
import DocumentsTable from "./DocumentsTable";
import PhonesTable from "./PhonesTable";
import ConfidantPersonsTable from "./ConfidantPersonsTable";
import TableDiff from "./TableDiff";
import {
  defaultError,
  handleProcessEditFormErrors
} from "./editPersonFormErrors";

import env from "../../../../env";

type EditPersonProps = RouteComponentProps<{
  id: string;
}>;

type AddressWithIdentifier = Maybe<TAddress> & {
  identifier: {
    index: number;
    slug: string;
  };
};

type TPersonWithAddressIdentifier = Omit<Person, "addresses"> & {
  addresses: AddressWithIdentifier[];
};

const EditPerson = ({
  // @ts-expect-error location state
  location: { state },
  id
}: EditPersonProps) => {
  const { i18n } = useLingui();
  const ADDRESS_SLUG = i18n._(t`Address`);

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => {
        const searchVariables = locationParams.filter
          ? filteredLocationParams(locationParams)
          : {
              ...filteredLocationParams(locationParams),
              ...AUTH_METHODS_FILTER_DEFAULTS
            };
        const variables = {
          id,
          ...searchVariables
        };
        return (
          <Query
            query={PersonQuery}
            fetchPolicy="network-only"
            variables={variables}
          >
            {({ data }: QueryResult<{ person: Person }>) => {
              if (isEmpty(data)) return null;
              const personWithAddressesIDS = {
                ...data.person,
                addresses: data.person.addresses
                  ? data.person.addresses.map((address, index) => ({
                      ...address,
                      identifier: {
                        index: index + 1,
                        slug: ADDRESS_SLUG
                      }
                    }))
                  : []
              };

              return (
                <>
                  <Box pt={5} px={6}>
                    <Steps.List>
                      <Steps.Item to="./" state={state}>
                        <Trans>Make changes</Trans>
                      </Steps.Item>
                      <Steps.Item to="./confirm" state={state} disabled>
                        <Trans>approve by EDS</Trans>
                      </Steps.Item>
                    </Steps.List>
                  </Box>
                  <Router>
                    {/* @ts-ignore */}
                    <EditForm path="/" person={personWithAddressesIDS} />
                    <Confirmation path="/confirm" />
                  </Router>
                </>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  );
};

export default EditPerson;

type EditFormProps = RouteComponentProps & {
  person: TPersonWithAddressIdentifier;
};

const EditForm = ({
  person,
  navigate,
  // @ts-expect-error location state
  location: { state }
}: EditFormProps) => {
  const { i18n } = useLingui();

  return (
    <Form
      decorators={[resetTaxId, resetRefusalAndID]}
      initialValues={{
        ...(state.incomingPerson ? state.incomingPerson : person),
        nhsRequestNumber: "",
        nhsRequestComment: "",
        ...state
      }}
      onSubmit={(
        data: Person & {
          nhsRequestNumber: string;
          nhsRequestComment: string;
          resetSignRefusalAndID: boolean;
        }
      ) => {
        const dataWithFlattenAddressesList = handleFlattAddresses(data);
        const result: $TSFixMe = handleProcessEditFormErrors(
          // @ts-ignore
          dataWithFlattenAddressesList
        );
        if (result && result[`FINAL_FORM/form-error`]) return result;
        try {
          navigate!("./confirm", {
            state: {
              ...dataWithFlattenAddressesList,
              secondName:
                data.secondName === undefined
                  ? null
                  : data.secondName && data.secondName,
              noTaxId: data.noTaxId === null ? false : data.noTaxId,
              ...(data.resetSignRefusalAndID && { noTaxId: null }),
              incomingPerson: person
            }
          });
        } catch (error) {
          return { [SUBMIT_ERROR]: defaultError };
        }
      }}
    >
      <Box p={6}>
        <Box mb={6}>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Text fontSize={1}>
              <Trans>Patients data editing</Trans>
            </Text>
            <Button
              variant="link"
              alignItems="start"
              px="0"
              py="0"
              icon={RemoveItemIcon}
              type="reset"
              onClick={() => navigate!("../")}
            >
              <Trans>Exit the editing mode</Trans>
            </Button>
          </Flex>
        </Box>
        <DefinitionListView
          labels={{
            fullName: <Trans>Full name</Trans>,
            databaseId: <Trans>Patient ID</Trans>
          }}
          data={{
            databaseId: person.databaseId,
            fullName: getFullName(person)
          }}
          color="#7F8FA4"
          labelWidth="120px"
        />
        <Line />
        <Text fontSize={1}>
          <Trans>Patient's details</Trans>
        </Text>
        <Flex flexWrap="wrap" justifyContent="flex-start" mt={6}>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter first name"
              render={({ translation }) => (
                <Field.Text
                  name="firstName"
                  label={<Trans id="First name" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required field="firstName" message={translation} />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field="firstName"
                  options={PERSON_NAME_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter last name"
              render={({ translation }) => (
                <Field.Text
                  name="lastName"
                  label={<Trans id="Last name" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required field="lastName" message={translation} />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field="lastName"
                  options={PERSON_NAME_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter second name"
              render={({ translation }) => (
                <Field.Text
                  name="secondName"
                  label={<Trans id="Second name" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field="secondName"
                  options={PERSON_NAME_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={1 / 4} mr={2}>
            <Composer
              components={[
                <DictionaryValue name="GENDER" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                DictionaryAllValuesJson,
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="gender"
                  label={<Trans id="Gender" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  itemToString={(item: string) =>
                    dict[item] || String(translation)
                  }
                  variant="select"
                />
              )}
            </Composer>
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required field="gender" message={translation} />
              )}
            />
          </Box>
          <Box width={1 / 4} mr={2}>
            <Field.DatePicker
              name="birthDate"
              label={<Trans id="Date of birthdate" />}
              minDate="1900-01-01"
              maxDate={Date.now()}
            />
            <Trans
              id="Invalid birth date"
              render={({ translation }) => (
                <Validations field="birthDate">
                  <Validation.Required message="Required field" />
                  <Validation.BirthDate message={translation} />
                </Validations>
              )}
            />
          </Box>
          <Box width={1 / 4} mr={2}>
            <Trans
              id="Enter country of birth"
              render={({ translation }) => (
                <Field.Text
                  name="birthCountry"
                  label={<Trans id="Country of birth" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required
                  field="birthCountry"
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={1 / 4} mr={2}>
            <Trans
              id="Enter settlement of birth"
              render={({ translation }) => (
                <Field.Text
                  name="birthSettlement"
                  label={<Trans id="Settlement of birth" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required
                  field="birthSettlement"
                  message={translation}
                />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field="birthSettlement"
                  options={ADDRESS_ITEM_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={1 / 4} mr={2}>
            <Trans
              id="Enter secret word"
              render={({ translation }) => (
                <Field.Text
                  name="secret"
                  label={<Trans id="Secret word" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required field="secret" message={translation} />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field="secret"
                  options={SECRET_WORD_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter email"
              render={({ translation }) => (
                <Field.Text
                  name="email"
                  label={<Trans id="email" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Invalid format"
              render={({ translation }) => (
                <Validation.Matches
                  field="email"
                  options={EMAIL_PATTERN}
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={1 / 4} mr={2}>
            <Composer
              components={[
                <DictionaryValue name="PREFERRED_WAY_COMMUNICATION" />,
                ({
                  render
                }: {
                  render: (props: TransRenderProps) => React.ReactElement;
                }) => <Trans id="Select option" render={render} />
              ]}
            >
              {([dict, { translation }]: [
                DictionaryAllValuesJson,
                { translation: React.ReactNode }
              ]) => (
                <Field.Select
                  name="preferredWayCommunication"
                  label={<Trans id="Preferred way communication" />}
                  placeholder={translation}
                  items={Object.keys(dict)}
                  itemToString={(item: string) =>
                    dict[item] || String(translation)
                  }
                  variant="select"
                />
              )}
            </Composer>
          </Box>
          <Box width={1}>
            <Field.Array
              name="phones"
              addText={<Trans>Add phone</Trans>}
              removeText={<Trans>Delete phone</Trans>}
              fields={PhonesForm}
              vertical
            />
          </Box>
          <Line />
          <Box mt={1} width={1}>
            <Text fontSize={1}>
              <Trans>Identity information</Trans>
            </Text>
          </Box>
          <Form.Spy>
            {({ values }: $TSFixMe) => (
              <>
                <Box width={0.45} mt={6} mr={2}>
                  <Trans
                    id="Enter tax ID"
                    render={({ translation }) => (
                      <Field.Text
                        name="taxId"
                        label={<Trans id="Tax ID" />}
                        placeholder={translation}
                        disabled={
                          Boolean(get(values, "noTaxId")) ||
                          Boolean(get(values, "resetSignRefusalAndID"))
                        }
                      />
                    )}
                  />
                  <Trans
                    id="Invalid value of tax id"
                    render={({ translation }) => (
                      <Validation.Matches
                        field="taxId"
                        options={PERSON_TAX_ID_PATTERN}
                        message={translation}
                      />
                    )}
                  />
                </Box>
                <Box width={0.45} mt={6} mr={2}>
                  <Trans
                    id="UNZR"
                    render={({ translation }) => (
                      <Field.Text
                        name="unzr"
                        label={<Trans id="Enter unzr" />}
                        placeholder={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Invalid format"
                    render={({ translation }) => (
                      <Validation.Matches
                        field="unzr"
                        options={UNZR_PATTERN}
                        message={translation}
                      />
                    )}
                  />
                </Box>
                <Box width={1 / 4}>
                  <Field.Checkbox
                    label={<Trans id="Person waived of tax ID" />}
                    name="noTaxId"
                    disabled={Boolean(get(values, "resetSignRefusalAndID"))}
                  />
                </Box>
              </>
            )}
          </Form.Spy>

          <Box width={1} mt={4}>
            <Box width={1 / 3}>
              <Field.Checkbox
                label={<Trans id="Reset the sign of refusal and ID" />}
                name="resetSignRefusalAndID"
              />
            </Box>
          </Box>
          <Line />
          <Text fontSize={1}>
            <Trans>Addresses</Trans>
          </Text>
          <Box width={1} mt={6} mr={2}>
            <Field.Array
              name="addresses"
              addText={<Trans>Add address</Trans>}
              removeText={<Trans>Delete address</Trans>}
              fields={AddressesForm}
              vertical
              fieldsProps={person}
            />
          </Box>
          <Line />
          <Box width={1} mb={6}>
            <Text fontSize={1}>
              <Trans>Documents</Trans>
            </Text>
          </Box>
          <Box width={1}>
            <Field.Array
              name="documents"
              addText={<Trans>Add document</Trans>}
              removeText={<Trans>Delete document</Trans>}
              fields={DocumentsForm}
              vertical
            />
          </Box>
          <Line />
          <Box width={1} mb={6}>
            <Text fontSize={1}>
              <Trans>Emergency contact</Trans>
            </Text>
          </Box>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter first name"
              render={({ translation }) => (
                <Field.Text
                  name="emergencyContact.firstName"
                  label={<Trans id="First name" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required
                  field="emergencyContact.firstName"
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter last name"
              render={({ translation }) => (
                <Field.Text
                  name="emergencyContact.lastName"
                  label={<Trans id="Last name" />}
                  placeholder={translation}
                />
              )}
            />
            <Trans
              id="Required field"
              render={({ translation }) => (
                <Validation.Required
                  field="emergencyContact.lastName"
                  message={translation}
                />
              )}
            />
          </Box>
          <Box width={0.45} mr={2}>
            <Trans
              id="Enter second name"
              render={({ translation }) => (
                <Field.Text
                  name="emergencyContact.secondName"
                  label={<Trans id="Second name" />}
                  placeholder={translation}
                />
              )}
            />
          </Box>
          <Box width={1}>
            <Field.Array
              name="emergencyContact.phones"
              addText={<Trans>Add phone</Trans>}
              removeText={<Trans>Delete phone</Trans>}
              fields={PhonesForm}
              vertical
            />
          </Box>
          <Line />
          <Box width={1} mb={6}>
            <Text fontSize={1}>
              <Trans>Confidant persons</Trans>
            </Text>
          </Box>
          <Box width={1}>
            <Field.Array
              name="confidantPersons"
              addText={<Trans>Add confidant persons</Trans>}
              removeText={<Trans>Delete confidant person</Trans>}
              fields={(props) => (
                <ConfidantPersonsForm person={person} {...props} />
              )}
              vertical
            />
          </Box>
          <Line />
        </Flex>
        <Box width={5 / 9} mt={6}>
          <Trans
            id="Enter application number"
            render={({ translation }) => (
              <Field.Text
                name="nhsRequestNumber"
                label={<Trans id="Application number" />}
                placeholder={translation}
                maxLength={50}
                showLengthHint
              />
            )}
          />
          <Trans
            id="Required field"
            render={({ translation }) => (
              <Validation.Required
                field="nhsRequestNumber"
                message={translation}
              />
            )}
          />
        </Box>
        <Box width={5 / 9} mt={6}>
          <Trans
            id="Reason of editing"
            render={({ translation }) => (
              <Field.Textarea
                name="nhsRequestComment"
                label={<Trans id="Specify the basis" />}
                placeholder={translation}
                rows={10}
                maxLength={3000}
                showLengthHint
              />
            )}
          />
          <Trans
            id="Required field"
            render={({ translation }) => (
              <Validation.Required
                field="nhsRequestComment"
                message={translation}
              />
            )}
          />
        </Box>
        <Flex pt={5}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!("../")}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Check</Trans>
            </Button>
          </Box>
        </Flex>
        <Form.Error
          entry={{
            "person.phones.empty": {
              personPhonesEmpty: i18n._(
                t`Person should have atleast one phone number`
              )
            },
            "person.addresses.empty": {
              personAddressesEmpty: i18n._(
                t`Person should have atleast one address`
              )
            },
            "person.addresses.recidence.empty": {
              personAddressesRecidenceEmpty: i18n._(
                t`Person should have atleast one recidence address`
              )
            },
            "person.documents.empty": {
              personDocumentsEmpty: i18n._(
                t`Person should have atleast one document`
              )
            },
            "person.emergency.contact.phones.empty": {
              personEmergencyContactPhonesEmpty: i18n._(
                t`Emergency contact should have atleast one phone number`
              )
            },
            "person.confidant.persons.phones.empty": {
              personConfidantPersonsPhonesEmpty: i18n._(
                t`Every confidant person should have atleast one phone number`
              )
            }
          }}
          default={i18n._(t`Something went wrong. Please try again later`)}
        />
      </Box>
    </Form>
  );
};

const resetTaxId = createDecorator({
  field: "noTaxId",
  updates: {
    taxId: (noTaxId: $TSFixMe, allValues: $TSFixMe) =>
      typeof noTaxId === "boolean" && !noTaxId ? allValues.taxId : null
  }
});

const resetRefusalAndID = createDecorator({
  field: "resetSignRefusalAndID",
  updates: {
    taxId: (resetSignRefusalAndID: $TSFixMe, allValues: $TSFixMe) =>
      typeof resetSignRefusalAndID === "boolean" && resetSignRefusalAndID
        ? null
        : allValues.taxId,
    noTaxId: (resetSignRefusalAndID: $TSFixMe, allValues: $TSFixMe) =>
      typeof resetSignRefusalAndID === "boolean" && resetSignRefusalAndID
        ? null
        : typeof resetSignRefusalAndID === "boolean" && !resetSignRefusalAndID
          ? allValues.noTaxId
          : false
  }
});

const handleFlattAddresses = (
  data: Person & {
    nhsRequestNumber: string;
    nhsRequestComment: string;
    resetSignRefusalAndID: boolean;
  }
) => ({
  ...data,
  addresses: data.addresses
    ? data.addresses.map((address) => {
        const { settlement, ...addressProps } = address || {};
        return {
          ...addressProps,
          ...(typeof settlement === "object"
            ? {
                // @ts-expect-error types mismatch
                settlementId: settlement && settlement.databaseId,
                // @ts-expect-error types mismatch
                settlement: settlement && settlement.name
              }
            : { settlement })
        };
      })
    : []
});

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();
  const [error, setError] = useState(null);
  const ADDRESS_SLUG = i18n._(t`Address`);

  return (
    <Box p={6}>
      <Box mb={6}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Text fontSize={1}>
            <Trans>Patients data editing</Trans>
          </Text>
          <Button
            variant="link"
            alignItems="start"
            px="0"
            py="0"
            icon={RemoveItemIcon}
            type="reset"
            onClick={() => navigate!("../../")}
          >
            <Trans>Exit the editing mode</Trans>
          </Button>
        </Flex>
      </Box>
      <DefinitionListView
        labels={{
          fullName: <Trans>Full name</Trans>,
          databaseId: <Trans>Patient ID</Trans>
        }}
        data={{
          fullName: getFullName(state.incomingPerson),
          databaseId: state.databaseId
        }}
        color="#7F8FA4"
        labelWidth="120px"
      />
      <Line />
      <Signer.Parent
        url={env.REACT_APP_SIGNER_URL}
        features={{
          width: 640,
          height: 589
        }}
      >
        {/* @ts-expect-error signData */}
        {({ signData }) => (
          <Mutation
            mutation={UpdatePerson}
            refetchQueries={() => [
              {
                query: PersonQuery,
                variables: { first: 10, id: state.id }
              }
            ]}
          >
            {(updatePerson: MutationFunction) => {
              const { incomingPerson, resetSignRefusalAndID, ...restState } =
                state;

              const {
                authenticationMethods: nextAuthenticationMethods,
                addresses: nextAddresses,
                documents: nextDocuments,
                phones: nextPhones,
                emergencyContact: nextEmergencyContact,
                taxId: nextTaxId,
                noTaxId: nextNoTaxId,
                unzr: nextUnzr,
                confidantPersons: nextConfidantPersons,
                key,
                updatedAt: nextPersonUpdatedAt,
                nhsRequestComment: nextNhsRequestComment,
                nhsRequestNumber: nextNhsRequestNumber,
                ...nextPerson
              } = restState;
              const nextPersonsIdentityInfo = {
                taxId: nextTaxId,
                noTaxId: nextNoTaxId,
                unzr: nextUnzr
              };
              const {
                authenticationMethods: prevAuthenticationMethods,
                addresses: prevAddresses,
                documents: prevDocuments,
                phones: prevPhones,
                emergencyContact: prevEmergencyContact,
                taxId: prevTaxId,
                noTaxId: prevNoTaxId,
                unzr: prevUnzr,
                confidantPersons: prevConfidantPersons,
                updatedAt,
                nhsRequestComment: prevNhsRequestComment,
                nhsRequestNumber: prevNhsRequestNumber,
                ...prevPerson
              } = incomingPerson;
              const prevPersonsIdentityInfo = {
                taxId: prevTaxId,
                noTaxId: prevNoTaxId,
                unzr: prevUnzr
              };

              const nextAddressesWithIdentifier = nextAddresses.map(
                ({ identifier = {}, ...nextAddress }) => {
                  return {
                    ...nextAddress,
                    ...(isEmpty(identifier)
                      ? {
                          identifier: {
                            index: incomingPerson.addresses.length + 1,
                            slug: ADDRESS_SLUG
                          }
                        }
                      : { identifier })
                  };
                }
              );

              return (
                <Box>
                  {!isEqual(nextPerson, prevPerson) && (
                    <Box>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>General information</Trans>
                      </Text>
                      <TableDiff
                        header={{
                          firstName: <Trans>First name</Trans>,
                          lastName: <Trans>Last name</Trans>,
                          secondName: <Trans>Second name</Trans>,
                          birthDate: <Trans>Date of birth</Trans>,
                          birthCountry: <Trans>Country of birth</Trans>,
                          secret: <Trans>Secret word</Trans>,
                          birthSettlement: <Trans>Settlement of birth</Trans>,
                          gender: <Trans>Gender</Trans>,
                          email: <Trans>Email</Trans>,
                          preferredWayCommunication: (
                            <Trans>Preferred way of communication</Trans>
                          )
                        }}
                        data={[prevPerson, nextPerson]}
                        renderRow={({
                          birthDate,
                          gender,
                          phones,
                          preferredWayCommunication,
                          ...content
                        }: Person) => ({
                          ...content,
                          gender: (
                            <DictionaryValue name="GENDER" item={gender} />
                          ),
                          preferredWayCommunication: (
                            <DictionaryValue
                              name="PREFERRED_WAY_COMMUNICATION"
                              item={preferredWayCommunication}
                            />
                          ),
                          birthDate:
                            birthDate &&
                            typeof birthDate === "string" &&
                            i18n.date(birthDate)
                        })}
                        hideEmptyFields
                      />
                    </Box>
                  )}
                  {!isEqual(
                    prevPersonsIdentityInfo,
                    nextPersonsIdentityInfo
                  ) && (
                    <Box mt={6}>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>Identity information</Trans>
                      </Text>
                      <TableDiff
                        header={{
                          unzr: <Trans>Record ID in EDDR</Trans>,
                          taxId: <Trans>INN</Trans>,
                          noTaxId: <Trans>No tax ID</Trans>
                        }}
                        data={[
                          prevPersonsIdentityInfo,
                          nextPersonsIdentityInfo
                        ]}
                        renderRow={({ noTaxId, taxId, unzr }: Person) => ({
                          taxId: taxId ? taxId : <Trans>Missing value</Trans>,
                          unzr: unzr ? unzr : <Trans>Missing value</Trans>,
                          noTaxId:
                            noTaxId === null ? (
                              <Trans>Missing value</Trans>
                            ) : Boolean(noTaxId) ? (
                              <Trans>Yes</Trans>
                            ) : (
                              <Trans>No</Trans>
                            )
                        })}
                      />
                    </Box>
                  )}
                  {!isEqual(prevPhones, nextPhones) && (
                    <Box mt={6}>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>Phones</Trans>
                      </Text>
                      <DictionaryValue name="PHONE_TYPE">
                        {(phonesHeader: DictionaryAllValuesJson) => (
                          <PhonesTable
                            header={phonesHeader}
                            person={prevPhones}
                            masterPerson={nextPhones}
                          />
                        )}
                      </DictionaryValue>
                    </Box>
                  )}
                  {!isEqual(prevDocuments, nextDocuments) && (
                    <Box mt={6}>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>Documents</Trans>
                      </Text>
                      <DictionaryValue name="DOCUMENT_TYPE">
                        {(documentsHeader: DictionaryAllValuesJson) => (
                          <DocumentsTable
                            header={documentsHeader}
                            person={prevDocuments}
                            masterPerson={nextDocuments}
                          />
                        )}
                      </DictionaryValue>
                    </Box>
                  )}
                  {!isEqual(nextAddressesWithIdentifier, prevAddresses) && (
                    <Box mt={6}>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>Addresses</Trans>
                      </Text>
                      <TableDiff
                        header={handleSetAddressesHeaders(
                          nextAddressesWithIdentifier,
                          ADDRESS_SLUG
                        )}
                        data={combineNestedData(
                          prevAddresses,
                          nextAddressesWithIdentifier,
                          handleSetAddressesHeaders(
                            nextAddressesWithIdentifier,
                            ADDRESS_SLUG
                          )
                        )}
                        renderRow={(rowData: { [key: string]: TAddress }) => {
                          return Object.entries(rowData).reduce(
                            (accum, [key, value]) => {
                              return {
                                ...accum,
                                [key]: <Address data={value} />
                              };
                            },
                            {}
                          );
                        }}
                        hideEmptyFields
                      />
                    </Box>
                  )}
                  {!isEqual(prevEmergencyContact, nextEmergencyContact) && (
                    <Box mt={6}>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>Emergency Contact</Trans>
                      </Text>
                      <TableDiff
                        header={{
                          firstName: <Trans>First name</Trans>,
                          lastName: <Trans>Last name</Trans>,
                          secondName: <Trans>Second name</Trans>,
                          birthDate: <Trans>Date of birth</Trans>,
                          birthCountry: <Trans>Country of birth</Trans>,
                          birthSettlement: <Trans>Settlement of birth</Trans>,
                          gender: <Trans>Gender</Trans>,
                          email: <Trans>Email</Trans>,
                          phones: <Trans>Phones</Trans>,
                          preferredWayCommunication: (
                            <Trans>Preferred way of communication</Trans>
                          )
                        }}
                        data={[prevEmergencyContact, nextEmergencyContact]}
                        renderRow={({ phones, ...content }) => ({
                          ...content,
                          phones: <Phones data={phones} />
                        })}
                        hideEmptyFields
                      />
                    </Box>
                  )}
                  {!isEqual(prevConfidantPersons, nextConfidantPersons) && (
                    <Box mt={6}>
                      <Text fontWeight="bold" mb={2} fontSize={1}>
                        <Trans>Confidant persons</Trans>
                      </Text>
                      <DictionaryValue name="CONFIDANT_PERSON_TYPE">
                        {(confidantPersonsHeader: DictionaryAllValuesJson) => {
                          return (
                            <ConfidantPersonsTable
                              header={confidantPersonsHeader}
                              person={prevConfidantPersons}
                              masterPerson={nextConfidantPersons}
                            />
                          );
                        }}
                      </DictionaryValue>
                    </Box>
                  )}
                  <Box mt={6}>
                    <DefinitionListView
                      labels={{
                        nhsRequestNumber: <Trans>Application number</Trans>,
                        nhsRequestComment: <Trans>Editing reason</Trans>
                      }}
                      data={{
                        nhsRequestNumber: state.nhsRequestNumber,
                        nhsRequestComment: state.nhsRequestComment
                      }}
                      labelWidth="200px"
                    />
                  </Box>

                  <Flex mt={6}>
                    <Box mr={3}>
                      <Button
                        type="reset"
                        variant="blue"
                        width={140}
                        onClick={() => navigate!("../", { state })}
                      >
                        <Trans>Back</Trans>
                      </Button>
                    </Box>
                    <Button
                      variant="green"
                      width={250}
                      onClick={async () => {
                        setError(null);
                        const filteredState = filterObjectKeys(
                          restState,
                          [
                            "__typename",
                            "key",
                            "authenticationMethods",
                            "incomingPerson",
                            "verificationStatus",
                            "verificationDetails",
                            "resetSignRefusalAndID"
                          ],
                          true
                        );

                        const dataToSign = convertObjectKeys(
                          {
                            person: handlePrepareToSignContent(
                              filteredState,
                              resetSignRefusalAndID
                            )
                          },
                          fieldNameDenormalizer
                        );

                        const { signedContent } = await signData(dataToSign);
                        try {
                          const { data } = await updatePerson({
                            variables: {
                              input: {
                                signedContent: {
                                  content: signedContent,
                                  encoding: "BASE64"
                                },
                                updatedAtForValidation: updatedAt
                              }
                            }
                          });

                          if (
                            data.updatePerson &&
                            data.updatePerson.person &&
                            !isEmpty(data.updatePerson.person)
                          ) {
                            await navigate!("../../");
                          }
                        } catch (error) {
                          if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                            setError(getErrorMessage(error));
                          }
                        }
                      }}
                    >
                      <Trans>Approve by EDS</Trans>
                    </Button>
                    {error && (
                      <UnpocessableEntityModalError
                        errorMessage={error}
                        isModalOpen
                      />
                    )}
                  </Flex>
                </Box>
              );
            }}
          </Mutation>
        )}
      </Signer.Parent>
    </Box>
  );
};

const UpdatePerson = gql`
  mutation UpdatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person {
        ...UserInfo
      }
    }
  }
  ${UserInfo.fragments.entry}
`;

export const combineNestedData = (
  person: TAddress[],
  masterPerson: AddressWithIdentifier[],
  header: DictionaryAllValuesJson
) => {
  const types = Object.keys(header);
  return [
    convertTypeToKey(person, types),
    convertTypeToKey(masterPerson, types)
  ];
};

const convertTypeToKey = (
  data: TAddress[] | AddressWithIdentifier[] = [],
  types: string[]
) =>
  types.reduce(
    (summary, item) => ({
      ...summary,
      [item]: data.find(
        ({ identifier }: any) =>
          identifier && item === `${identifier.slug} №${identifier.index}`
      )
    }),
    {}
  );

const handleSetAddressesHeaders = (
  nextAddressesWithIdentifier: AddressWithIdentifier[],
  slug: string
) => {
  const maxIndex = nextAddressesWithIdentifier.reduce((maxInd, item) => {
    if (item.identifier.index > maxInd) {
      return item.identifier.index;
    }
    return maxInd;
  }, 0);

  let addressKeysList: string[] = [];
  for (let i = 0; i < maxIndex; i++) {
    addressKeysList = [...addressKeysList, `${slug} №${i + 1}`];
  }

  return addressKeysList.reduce((accum, key) => {
    return { ...accum, [key]: key };
  }, {});
};

type DocumentProps = {
  data: PersonDocument;
};

export const Document = ({ data }: DocumentProps) => {
  const { i18n } = useLingui();

  return data ? (
    <Box pb={2}>
      <DefinitionListView
        labels={{
          number: <Trans>Number</Trans>,
          issuedAt: <Trans>Issued at</Trans>,
          issuedBy: <Trans>Issued by</Trans>,
          expirationDate: <Trans>Expiration date</Trans>
        }}
        data={{
          ...data,
          issuedAt: data.issuedAt && i18n.date(data.issuedAt),
          expirationDate: data.expirationDate && i18n.date(data.expirationDate)
        }}
        labelWidth="120px"
        marginBottom={0}
      />
    </Box>
  ) : null;
};

type AddressProps = {
  data: TAddress;
};

const Address = ({ data }: AddressProps) =>
  data ? (
    <Box pb={2}>
      <DefinitionListView
        labels={{
          zip: <Trans>zip</Trans>,
          apartment: <Trans>apartment</Trans>,
          area: <Trans>area</Trans>,
          building: <Trans>building</Trans>,
          country: <Trans>country</Trans>,
          region: <Trans>region</Trans>,
          settlement: <Trans>settlement</Trans>,
          settlementType: <Trans>settlement type</Trans>,
          street: <Trans>street</Trans>,
          streetType: <Trans>street type</Trans>,
          type: <Trans>type</Trans>,
          phones: <Trans>Phones</Trans>
        }}
        data={{
          ...data,
          ...(data.country && {
            country: <DictionaryValue name="COUNTRY" item={data.country} />
          }),
          ...(data.settlementType && {
            settlementType: (
              <DictionaryValue
                name="SETTLEMENT_TYPE"
                item={data.settlementType}
              />
            )
          }),
          ...(data.streetType && {
            streetType: (
              <DictionaryValue name="STREET_TYPE" item={data.streetType} />
            )
          }),
          ...(data.type && {
            type: <DictionaryValue name="ADDRESS_TYPE" item={data.type} />
          })
        }}
        labelWidth="120px"
        marginBottom={0}
      />
    </Box>
  ) : null;

type PhonesProps = {
  data: { [key: string]: Phone };
};

export const Phones = ({ data }: PhonesProps) => {
  if (isEmpty(data)) return null;

  return (
    <>
      {Object.entries(data)
        .reduce((acc: $TSFixMe, [key, val]) => {
          return [...acc, { ...val }];
        }, [])
        .map(({ type, number }: $TSFixMe) => {
          return (
            <Box pb={2}>
              <Composer components={[<DictionaryValue name="PHONE_TYPE" />]}>
                {([dict]: [DictionaryAllValuesJson]) => {
                  return (
                    <DefinitionListView
                      labels={{
                        type: <Trans>Type</Trans>,
                        number: <Trans>Number</Trans>
                      }}
                      data={{
                        type: dict[type],
                        number: number
                      }}
                      labelWidth="120px"
                      marginBottom={0}
                    />
                  );
                }}
              </Composer>
            </Box>
          );
        })}
    </>
  );
};

const handlePrepareToSignContent = (
  dataToSign: $TSFixMe,
  resetSignRefusalAndID: boolean
) => {
  const {
    id,
    databaseId,
    secret,
    deathDate,
    insertedAt,
    updatedAt,
    status,
    preferredWayCommunication,
    confidantPersons,
    documents,
    addresses,
    secondName,
    ...restDataToSign
  } = dataToSign;

  let confidantPersonWONullValues;
  if (!isEmpty(confidantPersons)) {
    confidantPersonWONullValues = confidantPersons.map(
      (confidantPerson: ConfidantPerson) =>
        cleanDeep(confidantPerson, { nullableValues: false })
    );
  }

  return {
    ...(resetSignRefusalAndID && { taxId: null, noTaxId: null }),
    ...{ secondName: isEmpty(secondName) ? null : secondName },
    id: databaseId,
    ...cleanDeep(restDataToSign, { nullableValues: false }),
    ...(secret && { secret: secret }),
    ...{
      documents: documents.map(
        ({ expirationDate, ...document }: PersonDocument) => ({
          ...document,
          ...(expirationDate && { expirationDate })
        })
      )
    },
    addresses: cleanDeep(addresses).map((address: TAddress) =>
      filterObjectKeys(address, ["identifier"])
    ),
    ...(preferredWayCommunication && {
      preferredWayCommunication: preferredWayCommunication.toLowerCase()
    }),
    ...(!isEmpty(confidantPersonWONullValues) && {
      confidantPerson: confidantPersonWONullValues.map(
        ({
          documents,
          relationshipDocuments,
          preferredWayCommunication,
          ...confidantPerson
        }: ConfidantPerson) => ({
          ...(preferredWayCommunication && {
            preferredWayCommunication: preferredWayCommunication.toLowerCase()
          }),
          ...(documents && {
            documentsPerson: documents.map(
              ({ expirationDate, ...document }: $TSFixMe) => ({
                ...document,
                ...(expirationDate && { expirationDate })
              })
            )
          }),
          ...(relationshipDocuments && {
            documentsRelationship: relationshipDocuments.map(
              ({ expirationDate, ...document }: $TSFixMe) => ({
                ...document,
                ...(expirationDate && { expirationDate })
              })
            )
          }),
          ...confidantPerson
        })
      )
    })
  };
};
