import React from "react";
import { Mutation } from "@apollo/client/react/components";
import {
  MutationFunction,
  MutationResult
} from "@apollo/client/react/types/types";
import { Trans } from "@lingui/macro";
import { Heading, Text, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  SUBMIT_ERROR,
  Validation
} from "@edenlabllc/ehealth-components";

import * as Field from "../../../components/Field";
import Popup from "../../../components/Popup";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import { defaultError } from "../../../constants/submitErrors";

import { PersonQuery } from "../../Persons/Details";
import { UpdatePersonManualRulesVerificationStatusMutation } from "./Verify";

type RejectVerifyProps = {
  isVisible: boolean;
  onCancel: () => void;
  personId?: string;
};

const RejectVerify = ({ isVisible, onCancel, personId }: RejectVerifyProps) => (
  <LocationParams>
    {({ locationParams }: TLocationParams) => (
      <Popup
        visible={isVisible}
        okText={<Trans>Not verify</Trans>}
        onCancel={onCancel}
        formId="verifyPerson"
        okButtonProps={{ variant: "orange", width: 220 }}
        cancelButtonProps={{ variant: "blue", width: 220 }}
      >
        <Mutation
          mutation={UpdatePersonManualRulesVerificationStatusMutation}
          refetchQueries={() => [
            {
              query: PersonQuery,
              variables: {
                id: personId,
                ...pagination(locationParams)
              }
            }
          ]}
        >
          {(
            updatePersonManualRulesVerificationStatus: MutationFunction,
            { error }: MutationResult
          ) => (
            <Form
              onSubmit={async ({
                verificationComment
              }: {
                verificationComment: string;
              }) => {
                const { data } =
                  await updatePersonManualRulesVerificationStatus({
                    variables: {
                      input: {
                        personId,
                        manualRulesVerificationStatus: "NOT_VERIFIED",
                        verificationComment
                      }
                    }
                  });
                if (
                  error ||
                  isEmpty(data.updatePersonManualRulesVerificationStatus)
                ) {
                  return { [SUBMIT_ERROR]: defaultError };
                }

                onCancel();
              }}
              initialValues={{ verificationComment: "" }}
              id="verifyPerson"
            >
              <Heading as="h1" fontWeight="normal" mb={6}>
                <Trans>Reject verification of Patient's personal data</Trans>
              </Heading>
              <Text mb={2}>
                <Trans>
                  Warning! <br />
                  After setting of verification status to "Not verified", this
                  action cannot be reversed
                </Trans>
              </Text>
              <Box mb={2}>
                <Trans
                  id="Reason of editing"
                  render={({ translation }) => (
                    <Field.Textarea
                      name="verificationComment"
                      label={<Trans id="Specify the basis" />}
                      placeholder={translation}
                      rows={10}
                      maxLength={300}
                      showLengthHint
                    />
                  )}
                />
                <Trans
                  id="Required field"
                  render={() => (
                    <Validation.Required
                      field="verificationComment"
                      message="Required field"
                    />
                  )}
                />
              </Box>
            </Form>
          )}
        </Mutation>
      </Popup>
    )}
  </LocationParams>
);

export default RejectVerify;
