import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { InnmDosage, Scalars } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import DefinitionListView from "../../../components/DefinitionListView";

import Deactivate from "./Mutations/Deactivate";

type HeaderProps = {
  id: Scalars["ID"];
  databaseId: Scalars["UUID"];
  name: InnmDosage["name"];
  form: InnmDosage["form"];
  isActive: InnmDosage["isActive"];
};

const Header = ({ id, databaseId, name, form, isActive }: HeaderProps) => (
  <Flex justifyContent="space-between" alignItems="flex-start">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>INNM dosage ID</Trans>,
          name: <Trans>Name</Trans>,
          form: <Trans>Form</Trans>,
          isActive: <Trans>Status</Trans>
        }}
        data={{
          databaseId,
          name,
          form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
          isActive: (
            <Badge
              type="ACTIVE_STATUS_F"
              name={isActive}
              variant={!isActive}
              minWidth={100}
            />
          )
        }}
        color="#7F8FA4"
        labelWidth="120px"
      />
    </Box>
    {isActive && <Deactivate id={id} name={name} />}
  </Flex>
);

Header.fragments = {
  entry: gql`
    fragment INNMDosageHeader on INNMDosage {
      id
      databaseId
      name
      form
      isActive
    }
  `
};

export default Header;
