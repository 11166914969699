import { Maybe, Scalars } from "@edenlabllc/graphql-schema";

const getConclusionData = (
  patientId: string,
  code: Scalars["String"]["input"],
  referenceId: Scalars["UUID"],
  displayValue?: Maybe<Scalars["String"]>,
  text?: Maybe<Scalars["String"]>
) => {
  return {
    patientId,
    resource: {
      displayValue: displayValue || null,
      identifier: {
        type: {
          coding: {
            code,
            system: "eHealth/resources"
          },
          text: text || null
        },
        value: referenceId
      }
    }
  };
};

export default getConclusionData;
