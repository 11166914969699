import _toConsumableArray from "/app/node_modules/.pnpm/@babel+runtime@7.0.0/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
export default function (data, name) {
  var status = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "status";
  var nameCheckInArr = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "name";
  var arrWithStatus = data.reduce(function (prev, item) {
    return !item[status] ? prev : [].concat(_toConsumableArray(prev), [item]);
  }, []);
  var lastFromArray = arrWithStatus.length > 1;
  var nameCheck = !lastFromArray && arrWithStatus[0][nameCheckInArr];
  return lastFromArray || nameCheck !== name;
}