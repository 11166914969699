import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Party, PartyConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import FullName from "../../../components/FullName";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import {
  drfoVerificationStatus,
  dracsVerificationStatus
} from "../../../helpers/partyVerificationStatus";
import { dateDaysDiff } from "../../../helpers/dateHelpers";

type EmployeesTableProps = {
  unverifiedParties: PartyConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const PartiesTable = ({
  unverifiedParties,
  locationParams,
  setLocationParams
}: EmployeesTableProps) => {
  if (!(unverifiedParties && unverifiedParties.length))
    return (
      <Box pt={4}>
        <EmptyData />
      </Box>
    );

  return (
    <Table
      data={unverifiedParties}
      header={{
        databaseId: <Trans>ID</Trans>,
        partyFullName: <Trans>Full name</Trans>,
        taxId: <Trans>INN</Trans>,
        noTaxId: <Trans>No tax ID</Trans>,
        verificationStatus: <Trans>General verification status</Trans>,
        drfoVerificationStatus: <Trans>DRFO verification status</Trans>,
        dracsVerificationStatus: <Trans>DRACS verification status</Trans>,
        dracsDeathUnverifiedAt: <Trans>Dracs death unverified, days</Trans>,
        details: <Trans>Details</Trans>
      }}
      renderRow={({
        id,
        databaseId,
        noTaxId,
        taxId,
        verificationStatus,
        verificationDetails,
        ...party
      }: Party) => {
        return {
          databaseId,
          partyFullName: <FullName party={party} />,
          taxId,
          noTaxId: (
            <Flex justifyContent="center">
              {noTaxId ? <PositiveIcon /> : <NegativeIcon />}
            </Flex>
          ),
          verificationStatus: (
            <Badge
              type="STATUS_VERIFICATION"
              name={verificationStatus}
              display="block"
            />
          ),
          drfoVerificationStatus: (
            <Badge
              type="STATUS_VERIFICATION"
              name={drfoVerificationStatus(
                verificationDetails
                  ? verificationDetails.drfo.verificationStatus
                  : ""
              )}
              display="block"
            />
          ),
          dracsVerificationStatus: (
            <Badge
              type="STATUS_VERIFICATION"
              name={dracsVerificationStatus(
                verificationDetails
                  ? verificationDetails.dracsDeath.verificationStatus
                  : ""
              )}
              display="block"
            />
          ),
          dracsDeathUnverifiedAt:
            verificationDetails &&
            verificationDetails.dracsDeath &&
            verificationDetails.dracsDeath.unverifiedAt
              ? dateDaysDiff(verificationDetails.dracsDeath.unverifiedAt)
              : "-",
          details: (
            <Link to={`../${id}`} fontWeight="bold">
              <Trans>Show details</Trans>
            </Link>
          )
        };
      }}
      sortableFields={[]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams: SortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      whiteSpaceNoWrap={["databaseId"]}
      tableName="unverifiedParties/search"
    />
  );
};

PartiesTable.fragments = {
  entry: gql`
    fragment Parties on Party {
      id
      databaseId
      taxId
      noTaxId
      ...FullName
      verificationStatus
      verificationDetails {
        dracsDeath {
          unverifiedAt
          verificationStatus
        }
        drfo {
          verificationStatus
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

export default PartiesTable;
