import React, { useMemo } from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloQueryResult, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, get, isEmpty } from "lodash";

import { Validation, Form } from "@edenlabllc/ehealth-components";
import {
  RootQueryTypeDistrictsArgs,
  DistrictConnection
} from "@edenlabllc/graphql-schema";

import * as Field from "../Field";
import { ADDRESS_ITEM_PATTERN } from "../../constants/validationPatterns";

type DistrictFieldProps = {
  name: string;
  regionName: string;
};

const DistrictField = ({ name, regionName }: DistrictFieldProps) => {
  return (
    <Trans
      id="Enter district name"
      render={({ translation }) => (
        <Form.Spy>
          {({ values }: $TSFixMe) => {
            const currRegionVal = get(values, regionName);

            return (
              <Query
                query={DistrictQuery}
                fetchPolicy="cache-first"
                variables={{
                  first: 20,
                  filter: {
                    region: currRegionVal
                  }
                }}
              >
                {({
                  data,
                  error,
                  refetch
                }: QueryResult<{ districts: DistrictConnection }>) => {
                  if (error || isEmpty(data) || isEmpty(data.districts)) {
                    return (
                      <>
                        <Field.Text
                          name={name}
                          label={<Trans id="District" />}
                          placeholder={translation}
                        />
                        <Trans
                          id="Invalid format"
                          render={({ translation }) => (
                            <Validation.Matches
                              field={name}
                              options={ADDRESS_ITEM_PATTERN}
                              message={translation}
                            />
                          )}
                        />
                      </>
                    );
                  }

                  return (
                    <>
                      <DistrictSelect
                        refetch={refetch}
                        districts={data.districts.nodes || []}
                        name={name}
                        translation={translation}
                        region={currRegionVal}
                      />
                      <Trans
                        id="Invalid format"
                        render={({ translation }) => (
                          <Validation.Matches
                            field={name}
                            options={ADDRESS_ITEM_PATTERN}
                            message={translation}
                          />
                        )}
                      />
                    </>
                  );
                }}
              </Query>
            );
          }}
        </Form.Spy>
      )}
    />
  );
};

export default DistrictField;

type DistrictSelectProps = {
  districts: DistrictConnection["nodes"];
  refetch: (variables: RootQueryTypeDistrictsArgs) => Promise<
    ApolloQueryResult<{
      districts: DistrictConnection;
    }>
  >;
  name: string;
  translation: React.ReactNode;
  region: string;
};

const DistrictSelect = ({
  districts,
  refetch,
  name,
  translation,
  region
}: DistrictSelectProps) => {
  const districtsList = useMemo(
    () => districts!.map((district) => district && district.name),
    [districts, region]
  );

  return (
    <Field.Select
      name={name}
      label={<Trans id="District" />}
      placeholder={translation}
      items={districtsList}
      itemToString={(item: string) => (item ? item : "")}
      onInputValueChange={debounce(
        (districtName, { selectedItem, inputValue }) => {
          return (
            !isEmpty(districtName) &&
            selectedItem !== inputValue &&
            refetch({
              first: 20,
              filter: {
                name: inputValue,
                region: region
              }
            })
          );
        },
        300
      )}
    />
  );
};

const DistrictQuery = gql`
  query DistrictQuery($first: Int, $filter: DistrictFilter) {
    districts(filter: $filter, first: $first) {
      nodes {
        id
        name
      }
    }
  }
`;
