import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { gql } from "graphql-tag";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Heading, Box, Flex } from "@rebass/emotion";

import { LocationParams, Switch } from "@edenlabllc/ehealth-components";
import { Party, PartyVerificationDetail } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";
import Link from "../../../components/Link";

import dateFormatter from "../../../helpers/dateFormatter";
import pagination from "../../../helpers/pagination";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { PartyQuery } from "./index";
import DracsDeathActPopup from "../../Persons/Details/DracsDeathActPopup";
import { TLocationParams } from "../../../components/SearchForm";

type VerificationDetailsProps = RouteComponentProps & {
  party: Party;
};

const VerificationDetails = ({ party }: VerificationDetailsProps) => {
  if (!party.verificationDetails) return <EmptyData />;
  const drfo = party.verificationDetails.drfo;
  const dracsDeath = party.verificationDetails.dracsDeath;

  return (
    <Box p={5}>
      <Drfo data={drfo} />
      <Dracs data={dracsDeath} id={party.id} />
    </Box>
  );
};

type DrfoProps = {
  data: PartyVerificationDetail["drfo"];
};

const Drfo = ({ data }: DrfoProps) => {
  if (!data) return null;

  return (
    <>
      <Heading fontSize="1" fontWeight="normal" pb={5}>
        <Trans>DRFO</Trans>
      </Heading>
      <DefinitionListView
        labelWidth={200}
        labels={{
          status: <Trans>Status</Trans>,
          reason: <Trans>Reason</Trans>,
          result: <Trans>Verification result</Trans>,
          synchronized: <Trans>Synchronized</Trans>,
          unverifiedAt: <Trans>Unverified</Trans>
        }}
        data={{
          status: (
            <Badge
              type="STATUS_VERIFICATION"
              name={drfoVerificationStatus(data.verificationStatus)}
              minWidth={160}
            />
          ),
          reason: (
            <DictionaryValue
              name="PERSON_VERIFICATION_STATUS_REASONS"
              item={data.verificationReason}
            />
          ),
          result: <DictionaryValue name="DRFO_RESULT" item={data.result} />,
          synchronized: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            data.syncedAt
          ),
          ...(data.unverifiedAt && {
            unverifiedAt: dateFormatter(
              i18n.locale,
              DATE_TIME_FORMAT,
              data.unverifiedAt
            )
          })
        }}
      />
      <Line />
    </>
  );
};

type DracsProps = {
  data: PartyVerificationDetail["dracsDeath"];
  id: string;
};

const Dracs = ({ data, id }: DracsProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <Box>
          <Heading fontSize="1" fontWeight="normal" pb={5}>
            <Trans>DRACS</Trans>
          </Heading>
          <Flex>
            <Box width={1}>
              <DefinitionListView
                labelWidth={200}
                labels={{
                  status: <Trans>Status</Trans>,
                  reason: <Trans>Reason</Trans>,
                  comment: <Trans>Comment</Trans>,
                  unverifiedAt: <Trans>Unverified</Trans>,
                  deathAct: <Trans>Death act</Trans>
                }}
                data={{
                  status: (
                    <Badge
                      type="STATUS_VERIFICATION"
                      name={dracsVerificationStatus(data.verificationStatus)}
                      minWidth={160}
                    />
                  ),
                  reason: (
                    <DictionaryValue
                      name="PERSON_VERIFICATION_STATUS_REASONS"
                      item={data.verificationReason}
                    />
                  ),
                  comment: data.verificationComment || "-",
                  ...(data.unverifiedAt && {
                    unverifiedAt: dateFormatter(
                      i18n.locale,
                      DATE_TIME_FORMAT,
                      data.unverifiedAt
                    )
                  }),
                  deathAct: data.dracsDeathActId ? (
                    <Button variant="link" onClick={toggle} px={0} py={0}>
                      {data.dracsDeathActId}
                    </Button>
                  ) : (
                    "-"
                  )
                }}
              />
            </Box>
            <Ability action="verify" resource="employee">
              <Box width={1}>
                <Flex justifyContent="flex-end" width={1}>
                  <Switch
                    value={data.verificationStatus}
                    NOT_VERIFIED={
                      <Mutation
                        mutation={
                          UpdatePartyDracsDeathVerificationStatusMutation
                        }
                        refetchQueries={() => [
                          {
                            query: PartyQuery,
                            variables: {
                              id,
                              ...pagination(locationParams)
                            }
                          }
                        ]}
                      >
                        {(
                          updatePartyDracsDeathVerificationStatus: MutationFunction
                        ) => (
                          <Box mb={3}>
                            <Button
                              width={220}
                              variant="blue"
                              onClick={async () => {
                                await updatePartyDracsDeathVerificationStatus({
                                  variables: {
                                    input: {
                                      partyId: id,
                                      dracsDeathVerificationStatus: "IN_REVIEW",
                                      dracsDeathVerificationReason: "MANUAL"
                                    }
                                  }
                                });
                              }}
                            >
                              <Trans>Start verification</Trans>
                            </Button>
                          </Box>
                        )}
                      </Mutation>
                    }
                    IN_REVIEW={
                      <Flex>
                        <Link to="./process-dracs">
                          <Button width={220} variant="blue">
                            <Trans>To process</Trans>
                          </Button>
                        </Link>
                      </Flex>
                    }
                  />
                </Flex>
              </Box>
            </Ability>
          </Flex>

          <DracsDeathActPopup
            deathActId={data.dracsDeathActId || ""}
            isPopupVisible={isPopupVisible}
            toggle={toggle}
          />
        </Box>
      )}
    </LocationParams>
  );
};

export default VerificationDetails;

VerificationDetails.fragments = {
  entry: gql`
    fragment VerificationDetails on Party {
      verificationDetails {
        dracsDeath {
          unverifiedAt
          verificationComment
          verificationReason
          verificationStatus
          dracsDeathActId
        }
        drfo {
          result
          syncedAt
          unverifiedAt
          verificationReason
          verificationStatus
        }
      }
    }
  `
};

export const drfoVerificationStatus = (verificationStatus: string) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED":
      return "VERIFICATION_AUTO_NEEDED";

    default:
      return verificationStatus;
  }
};

export const dracsVerificationStatus = (verificationStatus: string) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED":
      return "VERIFICATION_AUTO_NEEDED";

    default:
      return verificationStatus;
  }
};

export const UpdatePartyDracsDeathVerificationStatusMutation = gql`
  mutation UpdatePartyDracsDeathVerificationStatus(
    $input: UpdatePartyDracsDeathVerificationStatusInput!
  ) {
    updatePartyDracsDeathVerificationStatus(input: $input) {
      party {
        id
      }
    }
  }
`;
