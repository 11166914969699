import { Config } from "react-awesome-query-builder";

import { useGetFields } from "./getFields";
import eHealthConfig from "./config";

const useConfig = () => {
  const fields = useGetFields();
  // @ts-ignore
  const config: Config = {
    ...eHealthConfig,
    fields
  };
  return config;
};

export default useConfig;
