import React from "react";
import { Flex, Box } from "@rebass/emotion";

import { Maybe, Reference as TReference } from "@edenlabllc/graphql-schema";

import paramToBase64 from "../../../helpers/paramToBase64";

import Reference from "./Reference";

type ReferenceListProps = {
  referencesList?: Maybe<Array<Maybe<TReference>>>;
  pathSlug: string;
  entityName: string;
  label?: string;
};

const ReferenceList = ({
  referencesList,
  pathSlug,
  entityName,
  label
}: ReferenceListProps) => (
  <Flex flexDirection="column">
    {referencesList &&
      referencesList.map((reference: Maybe<TReference>, index: number) => (
        <Box key={index}>
          <Reference
            fontSize={14}
            headless
            linkWrapperProps={{
              mt: index === 0 ? 0 : 2
            }}
            linkPath={`${pathSlug}/${paramToBase64(
              entityName,
              reference && reference.identifier.value
            )}`}
            linkDisplayValue={
              reference
                ? reference.displayValue
                  ? reference.displayValue
                  : reference.identifier.value
                : ""
            }
            label={label}
          />
        </Box>
      ))}
  </Flex>
);

export default ReferenceList;
