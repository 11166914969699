import React from "react";
import { useParams } from "@reach/router";
import Composer from "react-composer";
import { t, Trans } from "@lingui/macro";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";

import { Validation } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import STATUSES from "../../../helpers/statuses";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => {
  const { i18n } = useLingui();
  const params = useParams();
  const isBrand = params.type === "brand";

  return (
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Field.Text
          name="filter.databaseId"
          label={<Trans>Program medication ID</Trans>}
          placeholder={i18n._(t`Enter ID`)}
          postfix={<SearchIcon color="silverCity" />}
          autoComplete="off"
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <SearchField.MedicalProgram
          name="filter.medicalProgram"
          filteredParams={["name"]}
          additionalFilters={{ isActive: true, type: "MEDICATION" }}
        />
      </Box>
      {isBrand && (
        <Box px={1} width={1 / 3}>
          <SearchField.Medication
            name="filter.medication"
            filteredParams={["name"]}
          />
        </Box>
      )}
    </Flex>
  );
};

const SecondarySearchFields = () => {
  const { i18n } = useLingui();
  const params = useParams();
  const isBrand = params.type === "brand";

  return (
    <>
      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <SearchField.Status
            name="filter.isActive"
            status="ACTIVE_STATUS_M"
            label={<Trans>Participant status</Trans>}
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Field.Text
            name="filter.medicalProgramId"
            label={<Trans>Medical program ID</Trans>}
            placeholder={i18n._(t`Enter ID`)}
            postfix={<SearchIcon color="silverCity" />}
          />
          <Validation.Matches
            field="filter.medicalProgramId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
        {isBrand && (
          <Box px={1} width={1 / 3}>
            <Field.Text
              name="filter.medicationId"
              label={<Trans>Medication ID</Trans>}
              placeholder={i18n._(t`Enter ID`)}
              postfix={<SearchIcon color="silverCity" />}
            />
            <Validation.Matches
              field="filter.medicationId"
              options={UUID_PATTERN}
              message="Invalid number"
            />
          </Box>
        )}
      </Flex>

      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <Field.Select
            name="filter.medicationRequestAllowed"
            label={<Trans>Prescription creation</Trans>}
            items={Object.keys(STATUSES.MEDICATION_REQUEST_ALLOWED)}
            itemToString={(item: boolean) =>
              // @ts-expect-error statuses boolean key
              STATUSES.MEDICATION_REQUEST_ALLOWED[item] ||
              i18n._(t`Select option`)
            }
            variant="select"
            emptyOption
            filterOptions={{
              keys: [
                (item: boolean) =>
                  // @ts-expect-error statuses boolean key
                  STATUSES.MEDICATION_REQUEST_ALLOWED[item]
              ]
            }}
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Composer
            components={[
              <DictionaryValue name="MR_BLANK_TYPES" />,
              ({
                render
              }: {
                render: (props: TransRenderProps) => React.ReactElement;
              }) => <Trans render={render}>Select option</Trans>
            ]}
          >
            {([dict, { translation }]: [
              DictionaryAllValuesJson,
              { translation: React.ReactNode }
            ]) => (
              <Field.Select
                name="filter.medicalProgram.mrBlankType"
                label={<Trans>Type of medication program request blank</Trans>}
                placeholder={translation}
                items={dict ? Object.keys(dict) : []}
                itemToString={(item: string) =>
                  dict[item] || String(translation)
                }
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            )}
          </Composer>
        </Box>
        <Box px={1} width={1 / 3}>
          <Field.Text
            name="filter.innmDosages.name"
            label={<Trans>INNM dosage name</Trans>}
            placeholder={i18n._(t`Enter INNM dosage name`)}
            postfix={<SearchIcon color="silverCity" />}
            autoComplete="off"
          />
        </Box>
      </Flex>

      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <DictionaryValue name="REIMBURSEMENT_TYPE">
            {(reimbursementTypes: DictionaryAllValuesJson) => (
              <Field.Select
                name="filter.reimbursementType"
                label={<Trans>Reimbursement type</Trans>}
                items={Object.keys(reimbursementTypes)}
                itemToString={(item: string) => reimbursementTypes[item] || ""}
                variant="select"
                placeholder={i18n._(t`Select option`)}
              />
            )}
          </DictionaryValue>
        </Box>
        <Box px={1} width={1 / 3}>
          <Field.Text
            name="filter.registryNumber"
            label={<Trans>Registry number</Trans>}
            placeholder={i18n._(t`Enter registry number`)}
            postfix={<SearchIcon color="silverCity" />}
            autoComplete="off"
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Field.Text
            name="filter.innmDosages.databaseId"
            label={<Trans>INNM dosage ID</Trans>}
            placeholder={i18n._(t`Enter ID`)}
            postfix={<SearchIcon color="silverCity" />}
          />
          <Validation.Matches
            field="filter.innmDosages.databaseId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
      </Flex>
    </>
  );
};

export { PrimarySearchFields, SecondarySearchFields };
