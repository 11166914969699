import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import { getPhones } from "@edenlabllc/ehealth-utils";
import { Person } from "@edenlabllc/graphql-schema";

import { Heading, Table } from "./index";

type EmergencyContactInfoProps = {
  data: Person["emergencyContact"][];
};

const EmergencyContactInfo = ({ data }: EmergencyContactInfoProps) => (
  <>
    <Heading>
      <Trans>Emergency Contact</Trans>
    </Heading>
    <Table
      header={{
        firstName: <Trans>First name</Trans>,
        lastName: <Trans>Last name</Trans>,
        secondName: <Trans>Second name</Trans>,
        phones: <Trans>Phones</Trans>
      }}
      data={data}
      renderRow={({ phones = [], ...name }) => ({
        ...name,
        phones: phones.length > 0 && getPhones(phones)
      })}
    />
  </>
);

EmergencyContactInfo.fragments = {
  entry: gql`
    fragment EmergencyContactInfo on Person {
      emergencyContact {
        firstName
        lastName
        secondName
        phones {
          type
          number
        }
      }
    }
  `
};

export default EmergencyContactInfo;
