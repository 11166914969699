import React, { useReducer } from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { DropDownButton as PlusIcon } from "@edenlabllc/ehealth-icons";
import {
  DictionaryValue,
  DictionaryValueConnection
} from "@edenlabllc/graphql-schema";

import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  SetSearchParams
} from "../../../components/SearchForm";
import Ability from "../../../components/Ability";
import { useAbility } from "../../../helpers/useAbility";
import { SearchFields } from "./SearchFields";
import { ChildValuesTable } from "./Table";

import CreateDictionaryValues from "./Mutations/CreateDictionaryValues";

type ChildValuesProps = {
  parentId: DictionaryValue["id"];
  dictionaryId: DictionaryValue["dictionaryId"];
  childValues: DictionaryValueConnection["nodes"];
  pageInfo?: DictionaryValueConnection["pageInfo"];
  isActive: DictionaryValue["isActive"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  refetch: any;
};

export const ChildValues = ({
  refetch,
  locationParams,
  setLocationParams,
  parentId,
  dictionaryId,
  childValues,
  pageInfo,
  isActive
}: RouteComponentProps & ChildValuesProps) => {
  const [isVisibleAddValue, toggle] = useReducer((v) => !v, false);
  const [dictionaryAbility] = useAbility("write", "dictionary");

  return (
    <Box p={6}>
      {!isVisibleAddValue ? (
        <>
          <Flex justifyContent="space-between" color="darkAndStormy">
            <Trans>Search for a value</Trans>
            {isActive && (
              <Ability action="write" resource="dictionary">
                <Button variant="link" px={0} icon={PlusIcon} onClick={toggle}>
                  <Trans>Add value</Trans>
                </Button>
              </Ability>
            )}
          </Flex>
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={SearchFields}
          />
          {childValues && childValues.length ? (
            <>
              <ChildValuesTable
                locationParams={locationParams}
                setLocationParams={setLocationParams}
                childValues={childValues}
                dictionaryAbility={dictionaryAbility}
              />
              <Pagination pageInfo={pageInfo} />
            </>
          ) : (
            <EmptyData />
          )}
        </>
      ) : (
        <CreateDictionaryValues
          parentId={parentId}
          dictionaryId={dictionaryId}
          toggle={toggle}
          refetch={refetch}
        />
      )}
    </Box>
  );
};
