import React from "react";
import { useParams } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  ProgramMedication,
  ProgramMedicationConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Dosage from "../../../components/Dosage";
import Link from "../../../components/Link";
import Price from "../../../components/Price";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

type ProgramMedicationsTableProps = {
  programMedications: ProgramMedicationConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const ProgramMedicationsTable = ({
  programMedications,
  locationParams,
  setLocationParams,
  tableName = "programMedications/search"
}: ProgramMedicationsTableProps) => {
  const params = useParams();
  const isBrand = params.type === "brand";

  return (
    <Table
      data={programMedications}
      header={{
        databaseId: <Trans>Participant ID</Trans>,
        medicalProgramId: <Trans>Medical program ID</Trans>,
        medicalProgramName: <Trans>Medical program name</Trans>,
        mrBlankType: <Trans>Type of medication program request blank</Trans>,
        ...(isBrand && {
          medicationId: <Trans>Medication ID</Trans>,
          medicationName: <Trans>Medication name</Trans>
        }),
        medicationForm: <Trans>Medication form</Trans>,
        ...(isBrand && {
          medicationManufacturer: <Trans>Medication manufacturer</Trans>
        }),
        reimbursementAmount: <Trans>Reimbursement amount</Trans>,
        maxDailyDosage: <Trans>Max daily dosage</Trans>,
        isActive: <Trans>Status</Trans>,
        medicationRequestAllowed: <Trans>Medication request allowed</Trans>,
        registryNumber: <Trans>Registry number</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        startDate: <Trans>Program medication start date</Trans>,
        endDate: <Trans>Program medication end date</Trans>,
        details: <Trans>Details</Trans>
      }}
      renderRow={({
        id,
        databaseId,
        isActive,
        insertedAt,
        registryNumber,
        startDate,
        endDate,

        medicalProgram: {
          databaseId: medicalProgramId,
          name: medicalProgramName,
          mrBlankType
        },

        medication: {
          databaseId: medicationId,
          name: medicationName,
          form: medicationForm,
          manufacturer: medicationManufacturer,
          container: medicationContainer
        },

        reimbursement: { reimbursementAmount },
        maxDailyDosage,
        medicationRequestAllowed
      }: ProgramMedication) => {
        return {
          databaseId,
          medicalProgramId,
          medicalProgramName,
          mrBlankType: mrBlankType && (
            <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
          ),
          ...(isBrand && {
            medicationId,
            medicationName
          }),
          medicationForm: (
            <DictionaryValue name="MEDICATION_FORM" item={medicationForm} />
          ),
          ...(isBrand && {
            medicationManufacturer: medicationManufacturer && (
              <>
                {medicationManufacturer.name}, {medicationManufacturer.country}
              </>
            )
          }),
          isActive: (
            <Badge
              type="ACTIVE_STATUS_M"
              name={isActive}
              variant={!isActive}
              display="block"
            />
          ),
          medicationRequestAllowed: (
            <Flex justifyContent="center">
              {medicationRequestAllowed ? <PositiveIcon /> : <NegativeIcon />}
            </Flex>
          ),
          reimbursementAmount: <Price amount={reimbursementAmount} />,
          maxDailyDosage: medicationContainer ? (
            <Dosage
              dosage={maxDailyDosage}
              denumeratorUnit={medicationContainer.numeratorUnit}
            />
          ) : (
            "-"
          ),
          startDate: startDate ? i18n.date(startDate) : "-",
          endDate: endDate ? i18n.date(endDate) : "-",
          registryNumber: registryNumber ? registryNumber : "-",
          insertedAt: i18n.date(insertedAt),
          details: (
            <Link to={`../../${id}`} fontWeight="bold">
              <Trans>Show details</Trans>
            </Link>
          )
        };
      }}
      sortableFields={["insertedAt"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams: SortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      whiteSpaceNoWrap={["medicalProgramId"]}
      tableName={tableName}
    />
  );
};

ProgramMedicationsTable.fragments = {
  entry: gql`
    fragment ProgramMedications on ProgramMedication {
      id
      databaseId
      isActive
      insertedAt
      startDate
      endDate
      registryNumber
      reimbursement {
        type
        reimbursementAmount
      }
      medicalProgram {
        id
        databaseId
        name
        isActive
        insertedAt
        updatedAt
        mrBlankType
      }
      medication {
        id
        databaseId
        name
        form
        manufacturer {
          name
          country
        }
        isActive
        container {
          numeratorUnit
        }
      }
      medicationRequestAllowed
      maxDailyDosage
    }
  `
};

export default ProgramMedicationsTable;
