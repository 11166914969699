import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  ProgramDevice,
  ProgramDeviceConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import Price from "../../../components/Price";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import { getProgramDeviceName } from "../../../helpers/deviceDefinition";
import DictionaryValue from "../../../components/DictionaryValue";

type ProgramDevicesTableProps = {
  programDevices: ProgramDeviceConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const ProgramDevicesTable = ({
  programDevices,
  locationParams,
  setLocationParams,
  tableName = "programDevices/search"
}: ProgramDevicesTableProps) => (
  <Table
    data={programDevices}
    header={{
      databaseId: <Trans>Participant ID</Trans>,
      medicalProgramId: <Trans>Medical program ID</Trans>,
      medicalProgramName: <Trans>Medical program name</Trans>,
      deviceId: <Trans>Device definition ID</Trans>,
      deviceName: <Trans>Device definition registered name</Trans>,
      deviceManufacturer: <Trans>Device definition manufacturer name</Trans>,
      deviceModel: <Trans>Device definition model number</Trans>,
      reimbursementAmount: <Trans>Reimbursement amount</Trans>,
      maxDailyCount: <Trans>Max daily count</Trans>,
      isActive: <Trans>Status</Trans>,
      deviceRequestAllowed: <Trans>Device request allowed</Trans>,
      carePlanActivityAllowed: <Trans>Device care plan activity allowed</Trans>,
      registryNumber: <Trans>Registry number</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      startDate: <Trans>Program device start date</Trans>,
      endDate: <Trans>Program device end date</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      databaseId,
      isActive,
      insertedAt,
      registryNumber,
      startDate,
      endDate,

      medicalProgram: {
        databaseId: medicalProgramId,
        name: medicalProgramName
      },

      deviceDefinition: {
        databaseId: deviceId,
        deviceNames,
        manufacturerName,
        manufacturerCountry,
        modelNumber
      },

      reimbursement: { reimbursementAmount },
      maxDailyCount,
      deviceRequestAllowed,
      carePlanActivityAllowed
    }: ProgramDevice) => {
      return {
        databaseId,
        medicalProgramId,
        medicalProgramName,
        deviceId,
        deviceName: getProgramDeviceName(deviceNames),
        deviceManufacturer: (
          <>
            {manufacturerName},{" "}
            <DictionaryValue name="COUNTRY" item={manufacturerCountry} />
          </>
        ),
        deviceModel: modelNumber,
        reimbursementAmount: <Price amount={reimbursementAmount} />,
        maxDailyCount,
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        deviceRequestAllowed: (
          <Flex justifyContent="center">
            {deviceRequestAllowed ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        carePlanActivityAllowed: (
          <Flex justifyContent="center">
            {carePlanActivityAllowed ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        registryNumber: registryNumber ? registryNumber : "-",
        insertedAt: i18n.date(insertedAt),
        startDate: startDate ? i18n.date(startDate) : "-",
        endDate: endDate ? i18n.date(endDate) : "-",
        details: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Show details</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={[]}
    tableName={tableName}
  />
);

ProgramDevicesTable.fragments = {
  entry: gql`
    fragment ProgramDevices on ProgramDevice {
      id
      databaseId
      isActive
      insertedAt
      startDate
      endDate
      registryNumber
      reimbursement {
        type
        reimbursementAmount
      }
      medicalProgram {
        id
        databaseId
        name
        isActive
        insertedAt
        updatedAt
        mrBlankType
      }
      deviceDefinition {
        id
        databaseId
        deviceNames {
          name
          type
        }
        manufacturerName
        manufacturerCountry
        modelNumber
      }
      deviceRequestAllowed
      carePlanActivityAllowed
      maxDailyCount
    }
  `
};

export default ProgramDevicesTable;
