import React, { useMemo } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";
import { pick, isEmpty } from "lodash";

import { Signer } from "@edenlabllc/ehealth-react-iit-digital-signature";
import {
  LocationParams,
  Form,
  Validation,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";
import {
  fieldNameDenormalizer,
  convertObjectKeys
} from "@edenlabllc/ehealth-utils";
import {
  ForbiddenGroup,
  ForbiddenGroupCode,
  Maybe
} from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue from "../../../../components/DictionaryValue";
import EmptyData from "../../../../components/EmptyData";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import Notification from "../../../../components/Notification";
import * as SearchField from "../../../../components/SearchField";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Steps from "../../../../components/Steps";
import Table from "../../../../components/Table";

import { ForbiddenGroupQuery } from "../../Details";
import { SearchForbiddenGroupsQuery } from "../index";

import env from "../../../../env";

type DeactivateGroupElementProps = RouteComponentProps<{
  id: string;
}>;

const DeactivateGroupElement = ({
  // @ts-expect-error location state
  location: { state, pathname },
  id
}: DeactivateGroupElementProps) => (
  <LocationParams>
    {({ locationParams }: TLocationParams) => {
      const { filter = {} } = locationParams || {};
      const {
        forbiddenGroupCodesFilter = {},
        forbiddenGroupServicesFilter = {}
      } = filter;

      return (
        <Query
          query={ForbiddenGroupQuery}
          variables={{
            id,
            first: 400,
            forbiddenGroupCodesFilter,
            forbiddenGroupServicesFilter
          }}
          fetchPolicy="network-only"
        >
          {({ data }: QueryResult<{ forbiddenGroup: ForbiddenGroup }>) => {
            if (isEmpty(data) || isEmpty(data.forbiddenGroup)) return null;

            return (
              <>
                {pathname.includes("confirm") && (
                  <Box m={-2}>
                    <Notification variant="orange">
                      <Trans>
                        Warning! Common access politics for some medical events
                        which contains elements listed below will be restored!
                      </Trans>
                    </Notification>
                  </Box>
                )}
                <Box pt={5} px={5}>
                  <Steps.List>
                    <Steps.Item to="./" state={state}>
                      <Trans>Choose codes</Trans>
                    </Steps.Item>
                    <Steps.Item to="./services" state={state}>
                      <Trans>Choose services</Trans>
                    </Steps.Item>
                    <Steps.Item to="./confirm" state={state} disabled>
                      <Trans>Confirm data</Trans>
                    </Steps.Item>
                  </Steps.List>
                  <DefinitionListView
                    labels={{
                      name: <Trans>Forbidden group name</Trans>,
                      creationReason: <Trans>Basis of creation</Trans>
                    }}
                    data={{
                      name: data.forbiddenGroup.name,
                      creationReason: data.forbiddenGroup.creationReason
                    }}
                    labelWidth="200px"
                  />
                  <Line />
                  <Router>
                    <Codes
                      path="/"
                      codes={data.forbiddenGroup.forbiddenGroupCodes}
                    />
                    <Services
                      path="/services"
                      services={data.forbiddenGroup.forbiddenGroupServices}
                    />
                    <Confirmation
                      path="/confirm"
                      forbiddenGroup={data.forbiddenGroup}
                    />
                  </Router>
                </Box>
              </>
            );
          }}
        </Query>
      );
    }}
  </LocationParams>
);

export default DeactivateGroupElement;

type CodesProps = RouteComponentProps & {
  codes: ForbiddenGroup["forbiddenGroupCodes"];
};

const Codes = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  codes
}: CodesProps) => {
  const codesList = useMemo(
    () =>
      codes &&
      codes.nodes &&
      codes.nodes.length &&
      codes.nodes.filter(
        (forbiddenGroupCode: Maybe<ForbiddenGroupCode>) =>
          forbiddenGroupCode && forbiddenGroupCode.isActive
      ),
    [codes]
  );

  return (
    <LocationParams state={state}>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Form
          initialValues={state}
          onSubmit={(data: { deactivateCodeIds: string[] }) => {
            const { deactivateCodeIds } = data;

            navigate!("./services", {
              state: {
                ...data,
                deactivateCodeIds:
                  handleRemoveObjectFalseValues(deactivateCodeIds)
              }
            });
          }}
        >
          <Trans>Chose codes</Trans>
          <Box my={5}>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={CodesPrimarySearchFields}
              renderSecondary={() => (
                <CodesSecondarySearchFields
                  skipFieldsList={["filter.forbiddenGroupCodesFilter.isActive"]}
                />
              )}
            />
          </Box>
          {codesList && codesList.length ? (
            <Box px={1}>
              <Table
                data={codesList}
                hideControls
                hidePagination
                header={{
                  action: <Trans>Choose</Trans>,
                  code: <Trans>Code</Trans>,
                  name: <Trans>Name</Trans>,
                  system: <Trans>Dictionary name</Trans>
                }}
                renderRow={({ code, system, databaseId }) => ({
                  action: (
                    <Field.Checkbox name={`deactivateCodeIds.${databaseId}`} />
                  ),
                  code,
                  name: <DictionaryValue name={system} item={code} />,
                  system
                })}
              />
            </Box>
          ) : (
            <EmptyData height="auto" />
          )}
          <Flex pt={5} mb={100}>
            <Box mr={3}>
              <Button
                type="reset"
                variant="blue"
                width={140}
                onClick={() => navigate!("../", { state })}
              >
                <Trans>Back</Trans>
              </Button>
            </Box>
            <Box>
              <Button variant="green" width={140}>
                <Trans>Next</Trans>
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </LocationParams>
  );
};

type ServicesProps = RouteComponentProps & {
  services: ForbiddenGroup["forbiddenGroupServices"];
};

const Services = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  services
}: ServicesProps) => {
  const { i18n } = useLingui();
  const servicesList = useMemo(
    () =>
      services &&
      services.nodes &&
      services.nodes.length &&
      services.nodes.filter((service) => service && service.isActive),
    [services]
  );

  return (
    <LocationParams state={state}>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Form
          initialValues={{
            ...state,
            ...(locationParams.fieldType
              ? {
                  fieldType: locationParams.fieldType
                }
              : { fieldType: FIELD_TYPES.SERVICE })
          }}
          onSubmit={(data: {
            deactivateServiceIds: string[];
            deactivateCodeIds: string[];
          }) => {
            const { deactivateServiceIds } = data;
            const deactivateServiceIdsTruthly =
              handleRemoveObjectFalseValues(deactivateServiceIds);
            if (
              isEmpty(deactivateServiceIdsTruthly) &&
              isEmpty(data.deactivateCodeIds)
            ) {
              const atleastOneItemIsChosen = [
                {
                  entry: "atleast.item.chosen",
                  rules: [
                    {
                      rule: "notChosenAtleastOneCodeOrService"
                    }
                  ]
                }
              ];
              return { [SUBMIT_ERROR]: atleastOneItemIsChosen };
            }
            navigate!("../confirm", {
              state: {
                ...data,
                deactivateServiceIds: deactivateServiceIdsTruthly
              }
            });
          }}
        >
          <Trans>Chose services</Trans>
          <Box my={5}>
            <Form.Spy>
              {({ values: { fieldType } }: $TSFixMe) => (
                <>
                  <Flex mx={-1} flexWrap="wrap" alignItems="center">
                    <Box px={1} width={1 / 2}>
                      <Trans
                        id="Type"
                        render={({ translation }) => (
                          <Field.Select
                            name="fieldType"
                            items={Object.values(FIELD_TYPES)}
                            itemToString={(item: string) =>
                              i18n._(item) || item
                            }
                            label={<Trans id="Type" />}
                            placeholder={translation}
                            variant="select"
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Box my={5}>
                    <SearchForm
                      initialValues={locationParams}
                      onSubmit={(values: SearchParams) =>
                        setLocationParams({
                          ...values,
                          fieldType
                        })
                      }
                      renderPrimary={() => (
                        <ServicesPrimarySearchFields fieldType={fieldType} />
                      )}
                      renderSecondary={() => (
                        <ServicesSecondarySearchFields
                          fieldType={fieldType}
                          skipFieldsList={[
                            "filter.forbiddenGroupServicesFilter.isActive"
                          ]}
                        />
                      )}
                    />
                  </Box>
                </>
              )}
            </Form.Spy>
          </Box>
          {servicesList && servicesList.length ? (
            <Box px={1}>
              <Table
                data={servicesList}
                hideControls
                hidePagination
                header={{
                  action: <Trans>Choose</Trans>,
                  code: <Trans>Code</Trans>,
                  name: <Trans>Name</Trans>,
                  system: <Trans>Type</Trans>
                }}
                renderRow={({ service, serviceGroup, databaseId }) => {
                  let code;
                  let name;

                  if (!isEmpty(service)) {
                    code = service.code;
                    name = service.name;
                  } else if (!isEmpty(serviceGroup)) {
                    code = serviceGroup.code;
                    name = serviceGroup.name;
                  }

                  return {
                    action: (
                      <Field.Checkbox
                        name={`deactivateServiceIds.${databaseId}`}
                      />
                    ),
                    code,
                    name,
                    system: isEmpty(service) ? (
                      <Trans>Service group</Trans>
                    ) : (
                      <Trans>Service</Trans>
                    )
                  };
                }}
              />
            </Box>
          ) : (
            <EmptyData height="auto" />
          )}
          <Flex pt={5} mb={100}>
            <Form.Spy>
              {({ values = {} }: $TSFixMe) => (
                <Box mr={3}>
                  <Button
                    type="reset"
                    variant="blue"
                    width={140}
                    onClick={() =>
                      navigate!("../", {
                        state: {
                          ...state,
                          ...values,
                          deactivateServiceIds: handleRemoveObjectFalseValues(
                            values.deactivateServiceIds
                          )
                        }
                      })
                    }
                  >
                    <Trans>Back</Trans>
                  </Button>
                </Box>
              )}
            </Form.Spy>

            <Box>
              <Button variant="green" width={140}>
                <Trans>Next</Trans>
              </Button>
            </Box>
          </Flex>
          <Box>
            <Form.Error
              entry={{
                "atleast.item.chosen": {
                  notChosenAtleastOneCodeOrService: i18n._(
                    t`At least one code or service should be chosen`
                  )
                }
              }}
              default={i18n._(t`Something went wrong. Please try again later`)}
            />
          </Box>
        </Form>
      )}
    </LocationParams>
  );
};

type ConfirmationProps = RouteComponentProps & {
  forbiddenGroup?: ForbiddenGroup;
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state },
  forbiddenGroup
}: ConfirmationProps) => {
  const { deactivateServiceIds, deactivateCodeIds } = state;

  return (
    <>
      {!isEmpty(deactivateCodeIds) && (
        <Box mb={6}>
          <Trans>Chosen codes</Trans>
          <Table
            data={
              forbiddenGroup &&
              forbiddenGroup.forbiddenGroupCodes &&
              forbiddenGroup.forbiddenGroupCodes.nodes &&
              forbiddenGroup.forbiddenGroupCodes.nodes.filter((code) =>
                Object.keys(deactivateCodeIds).includes(code && code.databaseId)
              )
            }
            hideControls
            hidePagination
            header={{
              code: <Trans>Code</Trans>,
              name: <Trans>Name</Trans>,
              system: <Trans>Dictionary name</Trans>
            }}
            renderRow={({ code, system }) => ({
              code,
              name: <DictionaryValue name={system} item={code} />,
              system
            })}
          />
          <Line />
        </Box>
      )}
      {!isEmpty(deactivateServiceIds) && (
        <Box>
          <Trans>Chosen services</Trans>
          <Table
            data={
              forbiddenGroup &&
              forbiddenGroup.forbiddenGroupServices &&
              forbiddenGroup.forbiddenGroupServices.nodes &&
              forbiddenGroup.forbiddenGroupServices.nodes.filter(
                (serviceItem) =>
                  Object.keys(deactivateServiceIds).includes(
                    serviceItem && serviceItem.databaseId
                  )
              )
            }
            hideControls
            hidePagination
            header={{
              code: <Trans>Code</Trans>,
              name: <Trans>Name</Trans>,
              system: <Trans>Type</Trans>
            }}
            renderRow={({ service, serviceGroup }) => {
              let code;
              let name;

              if (!isEmpty(service)) {
                code = service.code;
                name = service.name;
              } else if (!isEmpty(serviceGroup)) {
                code = serviceGroup.code;
                name = serviceGroup.name;
              }
              return {
                code,
                name,
                system: isEmpty(service) ? (
                  <Trans>Service group</Trans>
                ) : (
                  <Trans>Service</Trans>
                )
              };
            }}
          />
          <Line />
        </Box>
      )}
      <Box mt={6}>
        <Signer.Parent
          url={env.REACT_APP_SIGNER_URL}
          features={{
            width: 640,
            height: 589
          }}
        >
          {/* @ts-expect-error signData */}
          {({ signData }) => (
            <Mutation
              mutation={ForbiddenGroupDeactivateItemsMutation}
              refetchQueries={() => [
                {
                  query: SearchForbiddenGroupsQuery,
                  variables: { first: 10 }
                }
              ]}
            >
              {(deactivateForbiddenGroupItems: MutationFunction) => (
                <Form
                  onSubmit={async ({
                    deactivationReason
                  }: {
                    deactivationReason: string;
                  }) => {
                    const dataToSign = handlePrepareToSignContent(
                      forbiddenGroup && forbiddenGroup.databaseId,
                      deactivateCodeIds,
                      deactivateServiceIds,
                      deactivationReason
                    );

                    const { signedContent } = await signData(dataToSign);
                    await deactivateForbiddenGroupItems({
                      variables: {
                        input: {
                          signedContent: {
                            content: signedContent,
                            encoding: "BASE64"
                          }
                        }
                      }
                    });
                    navigate!("../../");
                  }}
                >
                  <Box>
                    <Trans
                      id="Reason of deactivation"
                      render={({ translation }) => (
                        <Field.Textarea
                          name="deactivationReason"
                          label={<Trans id="Specify the basis" />}
                          placeholder={translation}
                          rows={10}
                          maxLength={1000}
                          showLengthHint
                        />
                      )}
                    />
                    <Trans
                      id="Required field"
                      render={() => (
                        <Validation.Required
                          field="deactivationReason"
                          message="Required field"
                        />
                      )}
                    />
                  </Box>
                  <Flex pt={5} mb={100}>
                    <Box mr={3}>
                      <Button
                        type="reset"
                        variant="blue"
                        width={140}
                        onClick={() => navigate!("../services", { state })}
                      >
                        <Trans>Back</Trans>
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="green" width={250}>
                        <Trans>Approve by EDS</Trans>
                      </Button>
                    </Box>
                  </Flex>
                </Form>
              )}
            </Mutation>
          )}
        </Signer.Parent>
      </Box>
    </>
  );
};

const ForbiddenGroupDeactivateItemsMutation = gql`
  mutation ForbiddenGroupDeactivateItemsMutation(
    $input: DeactivateForbiddenGroupItemsInput!
  ) {
    deactivateForbiddenGroupItems(input: $input) {
      forbiddenGroup {
        id
        databaseId
        name
        creationReason
        insertedAt
        isActive
        forbiddenGroupServices(first: 400) {
          nodes {
            databaseId
            creation_reason
            isActive
            service {
              id
              databaseId
              name
              code
            }
            serviceGroup {
              id
              databaseId
              name
              code
            }
          }
        }
        forbiddenGroupCodes(first: 400) {
          nodes {
            id
            databaseId
            isActive
            insertedAt
            code
            system
          }
        }
      }
    }
  }
`;

type ServicesPrimarySearchFieldsProps = {
  fieldType:
    | string
    | {
        id: string;
      };
};

const ServicesPrimarySearchFields = ({
  fieldType
}: ServicesPrimarySearchFieldsProps) => {
  const fieldTypeName =
    typeof fieldType === "string" ? fieldType : fieldType.id;

  return (
    <Flex mx={-1} flexWrap="wrap">
      <Box px={1} width={1 / 2}>
        {fieldTypeName === FIELD_TYPES.SERVICE ? (
          <Trans
            id="Enter Service Name"
            render={({ translation }) => (
              <Field.Text
                name="filter.forbiddenGroupServicesFilter.service.name"
                label={<Trans id="Service Name" />}
                placeholder={translation}
                autoComplete="off"
              />
            )}
          />
        ) : (
          <Trans
            id="Enter Service Group Name"
            render={({ translation }) => (
              <Field.Text
                name="filter.forbiddenGroupServicesFilter.serviceGroup.name"
                label={<Trans id="Service Group Name" />}
                placeholder={translation}
                autoComplete="off"
              />
            )}
          />
        )}
      </Box>
    </Flex>
  );
};

export const FIELD_TYPES = {
  SERVICE: "Service",
  SERVICE_GROUP: "Service Group"
};

type ServicesSecondarySearchFieldsProps = {
  fieldType:
    | string
    | {
        id: string;
      };
  skipFieldsList: string[];
};

const ServicesSecondarySearchFields = ({
  fieldType,
  skipFieldsList = []
}: ServicesSecondarySearchFieldsProps) => {
  const fieldTypeName =
    typeof fieldType === "string" ? fieldType : fieldType.id;

  return (
    <Flex mx={-1} flexWrap="wrap">
      <Box px={1} width={1 / 2}>
        {fieldTypeName === FIELD_TYPES.SERVICE
          ? !skipFieldsList.includes(
              "filter.forbiddenGroupServicesFilter.service.code"
            ) && (
              <Trans
                id="Enter Service Code"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.forbiddenGroupServicesFilter.service.code"
                    label={<Trans id="Service Code" />}
                    placeholder={translation}
                    autoComplete="off"
                  />
                )}
              />
            )
          : !skipFieldsList.includes(
              "filter.forbiddenGroupServicesFilter.serviceGroup.code"
            ) && (
              <Trans
                id="Enter Service Group Code"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.forbiddenGroupServicesFilter.serviceGroup.code"
                    label={<Trans id="Service Group Code" />}
                    placeholder={translation}
                    autoComplete="off"
                  />
                )}
              />
            )}
      </Box>
      <Box px={1} width={1 / 2}>
        {fieldTypeName === FIELD_TYPES.SERVICE
          ? !skipFieldsList.includes(
              "filter.forbiddenGroupServicesFilter.service.databaseId"
            ) && (
              <Trans
                id="Forbidden group Service ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.forbiddenGroupServicesFilter.service.databaseId"
                    label={<Trans id="Service ID" />}
                    placeholder={translation}
                    autoComplete="off"
                  />
                )}
              />
            )
          : !skipFieldsList.includes(
              "filter.forbiddenGroupServicesFilter.serviceGroup.databaseId"
            ) && (
              <Trans
                id="Forbidden group Service ID"
                render={({ translation }) => (
                  <Field.Text
                    name="filter.forbiddenGroupServicesFilter.serviceGroup.databaseId"
                    label={<Trans id="Service Group ID" />}
                    placeholder={translation}
                    autoComplete="off"
                  />
                )}
              />
            )}
      </Box>
      {!skipFieldsList.includes(
        "filter.forbiddenGroupServicesFilter.isActive"
      ) && (
        <Box width={1 / 2} px={1}>
          <SearchField.Status
            name="filter.forbiddenGroupServicesFilter.isActive"
            status="ACTIVE_STATUS_M"
            label={<Trans id="Status groups element" />}
          />
        </Box>
      )}
    </Flex>
  );
};

export const CodesPrimarySearchFields = () => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      <Trans
        id="Enter Code"
        render={({ translation }) => (
          <Field.Text
            name="filter.forbiddenGroupCodesFilter.code"
            label={<Trans id="Find by Dictionary code" />}
            placeholder={translation}
            autoComplete="off"
          />
        )}
      />
    </Box>
  </Flex>
);

export const CodesSecondarySearchFields = ({
  skipFieldsList = []
}: {
  skipFieldsList: string[];
}) => (
  <Flex mx={-1} flexWrap="wrap">
    {!skipFieldsList.includes("filter.forbiddenGroupCodesFilter.isActive") && (
      <Box width={1 / 2} px={1}>
        <SearchField.Status
          name="filter.forbiddenGroupCodesFilter.isActive"
          status="ACTIVE_STATUS_M"
          label={<Trans id="Status groups element" />}
        />
      </Box>
    )}
    {!skipFieldsList.includes("filter.forbiddenGroupCodesFilter.system") && (
      <Box width={1 / 2} px={1}>
        <SearchField.ForbiddenGroupSystem
          name="filter.forbiddenGroupCodesFilter.system"
          systemType="code"
        />
      </Box>
    )}
  </Flex>
);

const handlePrepareToSignContent = (
  id: string,
  deactivateCodeIds: string[],
  deactivateServiceIds: string[],
  deactivationReason: string
) => {
  const toSignContent = {
    id,
    deactivationReason,
    ...(deactivateServiceIds &&
      !isEmpty(deactivateServiceIds) && {
        forbiddenGroupServiceIds: Object.keys(deactivateServiceIds)
      }),
    ...(deactivateCodeIds &&
      !isEmpty(deactivateCodeIds) && {
        forbiddenGroupCodeIds: Object.keys(deactivateCodeIds)
      })
  };
  return convertObjectKeys(toSignContent, fieldNameDenormalizer);
};

const handleRemoveObjectFalseValues = (targetObject: $TSFixMe) => {
  if (isEmpty(targetObject)) return {};
  else {
    return pick(
      targetObject,
      Object.keys(targetObject).filter((item) => targetObject[item])
    );
  }
};
