import React from "react";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { gql } from "graphql-tag";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  DeviceDefinition,
  DeviceDefinitionConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import Table, { SortingParams } from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type DeviceDefinitionsTableProps = {
  deviceDefinitions: DeviceDefinitionConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const DeviceDefinitionsTable = ({
  deviceDefinitions,
  locationParams,
  setLocationParams
}: DeviceDefinitionsTableProps) => (
  <Table
    data={deviceDefinitions}
    header={{
      databaseId: <Trans>ID</Trans>,
      classificationType: <Trans>Device definition classification type</Trans>,
      deviceNames: <Trans>Device definition name</Trans>,
      modelNumber: <Trans>Device definition model number</Trans>,
      isActive: <Trans>Status</Trans>,
      manufacturerName: <Trans>Device definition manufacturer name</Trans>,
      manufacturerCountry: (
        <Trans>Device definition manufacturer country</Trans>
      ),
      insertedAt: <Trans>Inserted at</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({
      id,
      databaseId,
      classificationType,
      deviceNames,
      modelNumber,
      insertedAt,
      isActive,
      manufacturerName,
      manufacturerCountry
    }: DeviceDefinition) => {
      const deviceNamesList =
        deviceNames && deviceNames.map((item) => item && item.name).join(", ");

      return {
        databaseId,
        classificationType: (
          <DictionaryValue
            name="device_definition_classification_type"
            item={classificationType}
          />
        ),
        deviceNames: deviceNamesList,
        modelNumber,
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        manufacturerName,
        manufacturerCountry: manufacturerCountry && (
          <DictionaryValue name="COUNTRY" item={manufacturerCountry} />
        ),
        action: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Details</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["insertedAt", "classificationType", "modelNumber"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="device-definitions/search"
    hiddenFields="insertedAt,manufacturerCountry"
  />
);

DeviceDefinitionsTable.fragments = {
  entry: gql`
    fragment DeviceDefinition on DeviceDefinition {
      id
      databaseId
      classificationType
      deviceNames {
        name
        type
      }
      modelNumber
      manufacturerName
      manufacturerCountry
      isActive
      insertedAt
    }
  `
};

export default DeviceDefinitionsTable;
