import * as React from "react";
import { gql } from "graphql-tag";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  parseSortingParams,
  stringifySortingParams,
  getFullName,
  formatDate
} from "@edenlabllc/ehealth-utils";
import { Maybe, Person, PersonConnection } from "@edenlabllc/graphql-schema";

import AuthMethodsList from "../../../components/AuthMethodsList";
import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import Table, { SortingParams } from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import { dateDaysDiff } from "../../../helpers/dateHelpers";

import {
  handleCalculateVerificationStatus,
  dracsVerificationStatus,
  drfoVerificationStatus
} from "../Details";

type PersonsTableProps = {
  persons: PersonConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
  ableChosePerson?: boolean;
  handleChosePerson?: (
    person: Partial<Person> & {
      fullName: string;
      phoneNumber?: Maybe<string> | undefined;
    }
  ) => void;
  fromVerificationStatusPage?: boolean;
};

const PersonsTable = ({
  persons,
  locationParams,
  setLocationParams,
  tableName = "persons/search",
  ableChosePerson,
  handleChosePerson,
  fromVerificationStatusPage
}: PersonsTableProps) => {
  if (!(persons && persons.length))
    return (
      <Box pt={4}>
        <EmptyData />
      </Box>
    );

  return (
    <Table
      data={persons}
      header={{
        fullName: <Trans>Patient Name</Trans>,
        birthDate: <Trans>Date of birth</Trans>,
        taxId: <Trans>INN</Trans>,
        unzr: <Trans>Record ID in EDDR</Trans>,
        authenticationMethods: <Trans>Authentication method</Trans>,
        insertedAt: <Trans>Added</Trans>,
        updatedAt: <Trans>Updated</Trans>,
        status: <Trans>Status</Trans>,
        verificationStatus: <Trans>General verification status</Trans>,
        drfoVerificationStatus: <Trans>DRFO verification status</Trans>,
        manualVerificationStatus: <Trans>Manual verification status</Trans>,
        dracsVerificationStatus: <Trans>DRACS verification status</Trans>,
        ...(tableName === "unverifiedPersons/search/dracs" && {
          dracsDeathUnverifiedAt: <Trans>Dracs death unverified, days</Trans>
        }),
        action: <Trans>Action</Trans>
      }}
      renderRow={({
        id,
        birthDate,
        taxId,
        unzr,
        authenticationMethods,
        insertedAt,
        updatedAt,
        status,
        verificationStatus,
        verificationDetails,
        ...person
      }: Person) => ({
        ...person,
        fullName: getFullName(person),
        birthDate: formatDate(birthDate),
        taxId: taxId || "—",
        unzr: unzr || "—",
        insertedAt: dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          insertedAt
        ),
        updatedAt: dateFormatter(
          i18n.locale,
          {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          },
          updatedAt
        ),
        authenticationMethods: (
          <AuthMethodsList
            data={authenticationMethods && authenticationMethods.nodes}
          />
        ),
        status: (
          <Badge type="ACTIVE_INACTIVE_M" name={status} display="block" />
        ),
        verificationStatus: (
          <Badge
            type="STATUS_VERIFICATION"
            name={verificationStatus}
            display="block"
          />
        ),
        drfoVerificationStatus: (
          <Badge
            type="STATUS_VERIFICATION"
            name={drfoVerificationStatus(
              verificationDetails
                ? verificationDetails.drfo.verificationStatus
                : ""
            )}
            display="block"
          />
        ),
        manualVerificationStatus: (
          <Badge
            type="STATUS_VERIFICATION"
            name={handleCalculateVerificationStatus(
              verificationDetails
                ? verificationDetails.manualRules.verificationStatus
                : "",
              verificationDetails
                ? verificationDetails.manualRules.verificationReason
                : ""
            )}
            display="block"
          />
        ),
        dracsVerificationStatus: (
          <Badge
            type="STATUS_VERIFICATION"
            name={dracsVerificationStatus(
              verificationDetails
                ? verificationDetails.dracsDeath.verificationStatus
                : "",
              verificationDetails
                ? verificationDetails.dracsDeath.verificationReason
                : ""
            )}
            display="block"
          />
        ),
        dracsDeathUnverifiedAt:
          verificationDetails &&
          verificationDetails.dracsDeath &&
          verificationDetails.dracsDeath.unverifiedAt
            ? dateDaysDiff(verificationDetails.dracsDeath.unverifiedAt)
            : "-",
        action: (
          <Flex flexDirection="column">
            <Link
              to={`/persons/${id}`}
              state={{ fromVerificationStatusPage }}
              fontWeight="bold"
            >
              <Trans>Details</Trans>
            </Link>
            {ableChosePerson && (
              <Box ml={20}>
                <Button
                  variant={status === "INACTIVE" ? "linkDisabled" : "link"}
                  disabled={status === "INACTIVE"}
                  alignItems="start"
                  px="0"
                  py="0"
                  type="reset"
                  onClick={() =>
                    handleChosePerson &&
                    handleChosePerson({
                      ...person,
                      fullName: getFullName(person),
                      ...(!isEmpty(
                        findOtpTypeAuthMethodPhone(authenticationMethods)
                      ) && {
                        phoneNumber: findOtpTypeAuthMethodPhone(
                          authenticationMethods
                        )
                      })
                    })
                  }
                >
                  <Trans>Choose</Trans>
                </Button>
              </Box>
            )}
          </Flex>
        )
      })}
      sortableFields={["birthDate", "insertedAt", "dracsDeathUnverifiedAt"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams: SortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      tableName={tableName}
      hiddenFields="drfoVerificationStatus,manualVerificationStatus,dracsVerificationStatus"
    />
  );
};

PersonsTable.fragments = {
  entry: gql`
    fragment Persons on Person {
      id
      databaseId
      firstName
      lastName
      secondName
      birthDate
      unzr
      taxId
      insertedAt
      status
      updatedAt
      verificationStatus
      verificationDetails {
        dracsDeath {
          unverifiedAt
          verificationReason
          verificationStatus
        }
        drfo {
          verificationReason
          verificationStatus
        }
        manualRules {
          verificationReason
          verificationStatus
        }
      }
      authenticationMethods(
        first: 50
        filter: { isEnded: false, type: ["OTP", "OFFLINE", "NA"] }
      ) {
        nodes {
          type
          phoneNumber
        }
      }
    }
  `
};

export default PersonsTable;

const findOtpTypeAuthMethodPhone = (
  authenticationMethods: Person["authenticationMethods"]
) => {
  if (isEmpty(authenticationMethods) || isEmpty(authenticationMethods.nodes))
    return null;
  const authenticationMethod = authenticationMethods!.nodes!.find(
    (authenticationMethod) =>
      authenticationMethod && authenticationMethod.type === "OTP"
  );

  return !isEmpty(authenticationMethod)
    ? authenticationMethod.phoneNumber
    : null;
};
