import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, Validation, Validations } from "@edenlabllc/ehealth-components";
import { cleanDeep } from "@edenlabllc/ehealth-utils";
import {
  GlobalParameters,
  UpdateGlobalParametersInput
} from "@edenlabllc/graphql-schema";

import Ability from "../../components/Ability";
import Button from "../../components/Button";
import * as Field from "../../components/Field";
import LoadingOverlay from "../../components/LoadingOverlay";

const Configuration = (_props: RouteComponentProps) => (
  <Box m={5}>
    <Heading as="h1" fontWeight="normal" mb={5}>
      <Trans>Configuration</Trans>
    </Heading>
    <Query query={GlobalParametersQuery}>
      {({
        loading,
        data
      }: QueryResult<{ globalParameters: GlobalParameters }>) => {
        if (isEmpty(data) || isEmpty(data.globalParameters)) return null;

        return (
          <LoadingOverlay loading={loading}>
            <Mutation
              mutation={UpdateGlobalParametersMutation}
              refetchQueries={() => [
                {
                  query: GlobalParametersQuery
                }
              ]}
            >
              {(updateGlobalParameters: MutationFunction) => (
                <Form
                  onSubmit={async ({
                    biUrl,
                    ...values
                  }: UpdateGlobalParametersInput) => {
                    const digitizedItems = Object.keys(values).reduce(
                      (result, item) => ({
                        ...result,
                        // @ts-ignore
                        [item]: parseInt(values[item]) || undefined
                      }),
                      {}
                    );

                    const input = cleanDeep({
                      biUrl,
                      ...digitizedItems
                    });

                    await updateGlobalParameters({
                      variables: {
                        input
                      }
                    });
                  }}
                  initialValues={data.globalParameters}
                >
                  <Box width={1 / 3}>
                    <Field.Number
                      name="declarationTerm"
                      label={<Trans id="Declaration term (years)" />}
                      placeholder={100}
                    />
                    <Validations field="declarationTerm">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="declarationLimit"
                      label={<Trans id="Declaration limit" />}
                      placeholder={2000}
                    />
                    <Validations field="declarationLimit">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="declarationRequestExpiration"
                      label={
                        <Trans id="Declaration request expiration (days)" />
                      }
                      placeholder={3}
                    />
                    <Validations field="declarationRequestExpiration">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="employeeRequestExpiration"
                      label={<Trans id="Employee request expiration (days)" />}
                      placeholder={30}
                    />
                    <Validations field="employeeRequestExpiration">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="verificationRequestExpiration"
                      label={
                        <Trans id="Verification request expiration (days)" />
                      }
                      placeholder={1}
                    />
                    <Validations field="verificationRequestExpiration">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="adultAge"
                      label={<Trans id="Adult age" />}
                      placeholder={18}
                    />
                    <Validations field="adultAge">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="billingDate"
                      label={<Trans id="Billing date" />}
                      placeholder={2}
                    />
                    <Validations field="billingDate">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="medicationDispensePeriod"
                      label={<Trans id="Medication dispense period" />}
                      placeholder={10}
                    />
                    <Validations field="medicationDispensePeriod">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="phoneNumberAuthLimit"
                      label={<Trans id="Phone number auth limit" />}
                      placeholder={500}
                    />
                    <Validations field="phoneNumberAuthLimit">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Text
                      name="biUrl"
                      label={<Trans id="BI URL" />}
                      placeholder="https://app.powerbi.com/view?r={report_id}"
                    />
                    <Validation.Required
                      field="biUrl"
                      message="Required field"
                    />
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="pediatricianDeclarationLimit"
                      label={<Trans id="Pediatrician declaration limit" />}
                      placeholder={2000}
                    />
                    <Validations field="pediatricianDeclarationLimit">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="therapistDeclarationLimit"
                      label={<Trans id="Therapist declaration limit" />}
                      placeholder={2000}
                    />
                    <Validations field="therapistDeclarationLimit">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="familyDoctorDeclarationLimit"
                      label={<Trans id="Family doctor declaration limit" />}
                      placeholder={2000}
                    />
                    <Validations field="familyDoctorDeclarationLimit">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="reimbursementContractMaxPeriodDay"
                      label={<Trans id="Reimbursement contract max period" />}
                      placeholder={365}
                    />
                    <Validations field="reimbursementContractMaxPeriodDay">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Box width={1 / 3}>
                    <Field.Number
                      name="capitationContractMaxPeriodDay"
                      label={<Trans id="Capitation contract max period" />}
                      placeholder={365}
                    />
                    <Validations field="capitationContractMaxPeriodDay">
                      <Validation.Required message="Required field" />
                      <Validation.ZeroOrPositive message="Must be zero or positive number" />
                    </Validations>
                  </Box>
                  <Ability action="write" resource="global_parameters">
                    <Box mt={5}>
                      <Form.Spy>
                        {({ initialValues, values }: $TSFixMe) => {
                          const isFormPristine =
                            JSON.stringify(values) ===
                            JSON.stringify(initialValues);
                          return (
                            <Button variant="green" disabled={isFormPristine}>
                              <Trans>Refresh</Trans>
                            </Button>
                          );
                        }}
                      </Form.Spy>
                    </Box>
                  </Ability>
                </Form>
              )}
            </Mutation>
          </LoadingOverlay>
        );
      }}
    </Query>
  </Box>
);

Configuration.fragments = {
  entry: gql`
    fragment GlobalParameters on GlobalParameters {
      adultAge
      billingDate
      declarationRequestExpiration
      employeeRequestExpiration
      verificationRequestExpiration
      declarationLimit
      declarationTerm
      phoneNumberAuthLimit
      medicationDispensePeriod
      biUrl
      pediatricianDeclarationLimit
      therapistDeclarationLimit
      familyDoctorDeclarationLimit
      reimbursementContractMaxPeriodDay
      capitationContractMaxPeriodDay
    }
  `
};

const GlobalParametersQuery = gql`
  query GlobalParametersQuery {
    globalParameters {
      ...GlobalParameters
    }
  }
  ${Configuration.fragments.entry}
`;

const UpdateGlobalParametersMutation = gql`
  mutation UpdateGlobalParametersMutation(
    $input: UpdateGlobalParametersInput!
  ) {
    updateGlobalParameters(input: $input) {
      globalParameters {
        ...GlobalParameters
      }
    }
  }
  ${Configuration.fragments.entry}
`;

export default Configuration;
