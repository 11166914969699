import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { EmergencyContact as EmergencyContactType } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";

import { FunctionComponentWithFragments } from "../../../helpers/types";

import { getDataByType } from "./index";

type EmergencyContactProps = RouteComponentProps & {
  data: EmergencyContactType | undefined;
  holderPadding?: number;
};

const EmergencyContact: FunctionComponentWithFragments<
  EmergencyContactProps
> = ({ data, holderPadding = 5 }) => {
  if (!data) return null;

  const { firstName, secondName, lastName, phones } = data;
  const [mobilePhone] = getDataByType("MOBILE", phones);
  const [landLinePhone] = getDataByType("LAND_LINE", phones);

  return (
    <Box p={holderPadding}>
      <DefinitionListView
        labelWidth={200}
        labels={{
          fullName: <Trans>PIB</Trans>,
          mobilePhone: <Trans>Mobile number</Trans>,
          landLinePhone: <Trans>Stationary number</Trans>
        }}
        data={{
          fullName: getFullName({ firstName, secondName, lastName }),
          mobilePhone: mobilePhone && mobilePhone.number,
          landLinePhone: landLinePhone && landLinePhone.number
        }}
      />
    </Box>
  );
};

export default EmergencyContact;

EmergencyContact.fragments = {
  entry: gql`
    fragment EmergencyContact on Person {
      emergencyContact {
        firstName
        lastName
        secondName
        phones {
          type
          number
        }
      }
    }
  `
};
