import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Heading, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { loader } from "graphql.macro";
import { QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";

import { Dictionary } from "@edenlabllc/graphql-schema";

import LoadingOverlay from "../components/LoadingOverlay";

const DictionaryByNameQuery = loader(
  "../graphql/DictionaryByNameQuery.graphql"
);

const Home = (_props: RouteComponentProps) => (
  // this Query wrapper is workaround to trigger redirect on sign-in if user not authorized ¯\_(ツ)_/¯
  <Query
    query={DictionaryByNameQuery}
    fetchPolicy="network-only"
    variables={{ dictionaryName: "GENDER" }}
  >
    {({
      loading,
      error
    }: QueryResult<{
      dictionaryByName: Dictionary;
    }>) => {
      if (loading || error) return null;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Heading as="h1" fontWeight="normal" mb={4}>
              <Trans>
                Congratulations on the administrative system Ehealth!
              </Trans>
            </Heading>
            <Text>
              <Trans>Select the menu item on the left to get started!</Trans>
            </Text>
          </Box>
        </LoadingOverlay>
      );
    }}
  </Query>
);

export default Home;
