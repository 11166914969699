import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { LegalEntity, Speciality, Maybe } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";

type OwnerProps = RouteComponentProps & {
  owner: LegalEntity["owner"];
};

const Owner = ({ owner }: OwnerProps) => {
  if (!owner) return null;
  const { party, databaseId, position, additionalInfo: doctor } = owner;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          party: <Trans>PIB</Trans>,
          speciality: <Trans>Specialty</Trans>,
          position: <Trans>Position</Trans>
        }}
        data={{
          party: getFullName(party),
          speciality: doctor && doctor.specialities && (
            <DictionaryValue
              name="SPECIALITY_TYPE"
              render={(dict: DictionaryAllValuesJson) => (
                <>
                  {doctor.specialities &&
                    doctor.specialities.map(
                      (
                        speciality: Maybe<Speciality>,
                        index: number,
                        array: Maybe<Speciality>[]
                      ) => (
                        <React.Fragment key={index}>
                          {speciality && dict[speciality.speciality]}
                          {array.length - 1 !== index && ", "}
                        </React.Fragment>
                      )
                    )}
                </>
              )}
            />
          ),

          position: <DictionaryValue name="POSITION" item={position} />
        }}
      />
      <DefinitionListView
        labels={{ databaseId: "Id" }}
        data={{ databaseId }}
        color="blueberrySoda"
      />
    </Box>
  );
};

Owner.fragments = {
  entry: gql`
    fragment LegalEntityOwner on LegalEntity {
      owner {
        id
        databaseId
        position
        startDate
        endDate
        status
        employeeType
        party {
          id
          firstName
          lastName
          secondName
        }
        additionalInfo {
          specialities {
            speciality
          }
        }
      }
    }
  `
};

export default Owner;
