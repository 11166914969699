import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { Form, LocationParams, Modal } from "@edenlabllc/ehealth-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams,
  cleanDeep,
  convertStringToBoolean
} from "@edenlabllc/ehealth-utils";
import { InnmDosage, InnmDosageConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../../../components/Badge";
import Button from "../../../../components/Button";
import DictionaryValue from "../../../../components/DictionaryValue";
import Dosage from "../../../../components/Dosage";
import EmptyData from "../../../../components/EmptyData";
import Link from "../../../../components/Link";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table, { SortingParams } from "../../../../components/Table";

import filteredLocationParams from "../../../../helpers/filteredLocationParams";

import {
  PrimarySearchFields,
  SecondarySearchFields
} from "../../../INNMDosages/Search/SearchFields";

type InnmModalFormProps = RouteComponentProps<{
  innmId: string;
}>;

const InnmModalForm = ({
  innmId,
  navigate,
  // @ts-expect-error location state
  location: { state }
}: InnmModalFormProps) => {
  const onClose = () => navigate!("../", { state });

  return (
    <LocationParams state={state}>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { filter: { innmdosage, isActive, ...params } = {} } =
          locationParams;

        const innmDosagesFilter = cleanDeep({
          ...params,
          name: innmdosage && innmdosage.name,
          isActive: convertStringToBoolean(isActive)
        });

        return (
          <Modal backdrop width="90%">
            <Close onClick={onClose} />
            <Form onSubmit={setLocationParams} initialValues={locationParams}>
              <PrimarySearchFields />
              <SecondarySearchFields />
              <Flex mx={-1} mt={4} justifyContent="flex-start">
                <Box px={1}>
                  <Button variant="red" onClick={onClose}>
                    <Trans>Close</Trans>
                  </Button>
                </Box>
                <Box px={1}>
                  <Button variant="blue">
                    <Trans>Search</Trans>
                  </Button>
                </Box>
                {!isEmpty(locationParams.filter) && (
                  <Box px={1}>
                    <Button
                      variant="link"
                      icon={RemoveItemIcon}
                      px={1}
                      type="reset"
                      onClick={() => {
                        setLocationParams({
                          ...locationParams,
                          filter: {},
                          searchRequest: null
                        });
                      }}
                    >
                      <Trans>Reset</Trans>
                    </Button>
                  </Box>
                )}
              </Flex>
            </Form>
            <Query
              query={SearchINNMDosagesQuery}
              fetchPolicy="network-only"
              variables={{
                ...filteredLocationParams(locationParams),
                filter: innmDosagesFilter
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ innmDosages: InnmDosageConnection }>) => (
                <LoadingOverlay loading={loading}>
                  {isEmpty(data) ||
                  isEmpty(data.innmDosages) ||
                  isEmpty(data.innmDosages.nodes) ? (
                    <Box m={7}>
                      <EmptyData height={100} />
                    </Box>
                  ) : (
                    <>
                      <INNMDosagesTable
                        locationParams={locationParams}
                        innmDosages={data.innmDosages.nodes}
                        setLocationParams={setLocationParams}
                        innmId={innmId!}
                        state={state}
                      />
                      <Pagination pageInfo={data.innmDosages.pageInfo} />
                    </>
                  )}
                </LoadingOverlay>
              )}
            </Query>
          </Modal>
        );
      }}
    </LocationParams>
  );
};

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

type INNMDosagesTableProps = {
  innmDosages: InnmDosageConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  innmId: string;
  state: any;
  tableName?: string;
};

const INNMDosagesTable = ({
  innmDosages,
  locationParams,
  setLocationParams,
  tableName = "innmDosages/search",
  innmId,
  state
}: INNMDosagesTableProps) => (
  <Table
    data={innmDosages}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Name</Trans>,
      form: <Trans>Medication form</Trans>,
      dailyDosage: <Trans>Daily dosage</Trans>,
      isActive: <Trans>Status</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={(innmDosage: InnmDosage) => {
      const { form, isActive, insertedAt, dailyDosage } = innmDosage;
      const data = prepareUpdateInnm(state, innmId, innmDosage);

      return {
        ...innmDosage,
        form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
        dailyDosage: <Dosage dosage={dailyDosage} />,
        isActive: (
          <Badge
            type="ACTIVE_STATUS_F"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        insertedAt: i18n.date(insertedAt),
        details: (
          <Link to={`../`} state={data} fontWeight="bold">
            <Trans>Select</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["form", "name", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName={tableName}
    hiddenFields="dailyDosage"
  />
);

INNMDosagesTable.fragments = {
  entry: gql`
    fragment INNMDosages on INNMDosage {
      id
      databaseId
      name
      form
      dailyDosage
      isActive
      insertedAt
    }
  `
};

export const SearchINNMDosagesQuery = gql`
  query SearchINNMDosagesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: INNMDosageFilter
    $orderBy: INNMDosageOrderBy
    $skip: Boolean! = false
  ) {
    innmDosages(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...INNMDosages
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${INNMDosagesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

const prepareUpdateInnm = (
  state: any,
  innmId: string,
  innmDosage: InnmDosage
) => {
  const { createMedicationIngredients: ingredientsData } = state || {};
  const { ingredients: ingredientsFields = [] } = ingredientsData || {};

  const ingredients = ingredientsFields.map((item: $TSFixMe, key: $TSFixMe) => {
    const flag = key === parseInt(innmId);
    return flag ? { ...item, innmDosage } : item;
  });

  return {
    ...state,
    createMedicationIngredients: {
      ...ingredientsData,
      ingredients
    }
  };
};

export default InnmModalForm;
