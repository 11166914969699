import {
  formatDateTimeInterval,
  convertStringToBoolean,
  formatDateInterval
} from "@edenlabllc/ehealth-utils";

import pagination from "./pagination";
import { SearchParams } from "../components/SearchForm";

const filteredLocationParams = (
  params: SearchParams = {},
  skip?: { skip: boolean }
) => {
  const { filter = {}, first, last, before, after, orderBy } = params;
  const {
    isActive,
    party,
    startDate: { from, to } = {},
    date: { insertedAtFrom, insertedAtTo } = {},
    employeeStatus,
    status,
    legalEntityId,
    service,
    serviceGroup,
    requestAllowed,
    medication,
    isEnded,
    forbiddenGroupCode,
    forbiddenGroupService,
    ...restFilters
  } = filter;

  return {
    ...skip,
    ...pagination({ first, last, before, after, orderBy }),
    filter: {
      ...restFilters,
      ...medication,
      isActive: convertStringToBoolean(isActive),
      isEnded: convertStringToBoolean(isEnded),
      party: party
        ? {
            ...party,
            noTaxId: convertStringToBoolean(party.noTaxId)
          }
        : undefined,
      insertedAt: formatDateTimeInterval(insertedAtFrom, insertedAtTo),
      startDate: formatDateInterval(from, to),
      status: employeeStatus || status,
      legalEntityId: legalEntityId ? legalEntityId.id : undefined,
      requestAllowed: convertStringToBoolean(requestAllowed),
      service: service ? { databaseId: service.databaseId } : undefined,
      serviceGroup: serviceGroup
        ? { databaseId: serviceGroup.databaseId }
        : undefined,
      forbiddenGroupCode: forbiddenGroupCode
        ? {
            ...forbiddenGroupCode,
            isActive: forbiddenGroupCode.isActive
              ? convertStringToBoolean(forbiddenGroupCode.isActive)
              : undefined
          }
        : undefined,
      forbiddenGroupService: forbiddenGroupService
        ? {
            ...forbiddenGroupService,
            isActive: forbiddenGroupService.isActive
              ? convertStringToBoolean(forbiddenGroupService.isActive)
              : undefined
          }
        : undefined,
      ...(medication
        ? {
            ...(medication.formPharm
              ? { formPharm: null }
              : { formPharm: medication.formPharm })
          }
        : undefined),
      parentCode: undefined // should be omited from query filter
    }
  };
};

export default filteredLocationParams;
