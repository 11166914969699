import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";

import system from "@edenlabllc/ehealth-system-components";
import { ContractRequest } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../../components/DictionaryValue";
import DefinitionListView from "../../../../components/DefinitionListView";
import FullName from "../../../../components/FullName";
import Line from "../../../../components/Line";
import Price from "../../../../components/Price";

type GeneralInfoCapitationProps = RouteComponentProps & {
  endDate: ContractRequest["endDate"];
  startDate: ContractRequest["startDate"];
  nhsSigner: ContractRequest["nhsSigner"];
  issueCity: ContractRequest["issueCity"];
  statusReason: ContractRequest["statusReason"];
  nhsSignerBase: ContractRequest["nhsSignerBase"];
  // @ts-expect-error types mismatch
  nhsContractPrice: ContractRequest["nhsContractPrice"];
  nhsPaymentMethod: ContractRequest["nhsPaymentMethod"];
  // @ts-expect-error types mismatch
  contractorRmspAmount: ContractRequest["contractorRmspAmount"];
};

const GeneralInfoCapitation = ({
  endDate,
  startDate,
  nhsSigner,
  issueCity,
  statusReason,
  nhsSignerBase,
  nhsContractPrice,
  nhsPaymentMethod,
  contractorRmspAmount
}: GeneralInfoCapitationProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        nhsSignerName: <Trans>Signer name</Trans>,
        nhsSignerBase: <Trans>Signer base</Trans>,
        nhsContractPrice: <Trans>Contract Price</Trans>,
        nhsPaymentMethod: <Trans>Payment method</Trans>,
        issueCity: <Trans>The city of the conclusion of the contract</Trans>
      }}
      data={{
        nhsSignerName: nhsSigner && <FullName party={nhsSigner.party} />,
        nhsSignerBase,
        nhsContractPrice: <Price amount={nhsContractPrice} />,
        nhsPaymentMethod: nhsPaymentMethod && (
          <DictionaryValue
            name="CONTRACT_PAYMENT_METHOD"
            item={nhsPaymentMethod}
          />
        ),
        issueCity
      }}
    />
    {nhsSigner && <Line />}
    <DefinitionListView
      labels={{
        startDate: <Trans>Initial date of the contract</Trans>,
        endDate: <Trans>End date of the contract</Trans>
      }}
      data={{
        startDate: i18n.date(startDate),
        endDate: i18n.date(endDate)
      }}
    />
    <Line />
    <DefinitionListView
      labels={{
        contractorRmspAmount: (
          <Trans>Number of persons served by legal entity</Trans>
        )
      }}
      data={{
        contractorRmspAmount: (
          <>
            {contractorRmspAmount}
            <Grey>(станом на 01.01.2018)</Grey>
          </>
        )
      }}
    />
    {statusReason && (
      <>
        <Line />
        <DefinitionListView
          labels={{ statusReason: <Trans>Status Comment</Trans> }}
          data={{ statusReason }}
        />
      </>
    )}
  </Box>
);

const Grey = system(
  {
    color: "blueberrySoda"
  },
  "color"
);

GeneralInfoCapitation.fragments = {
  entry: gql`
    fragment GeneralInfoCapitation on CapitationContractRequest {
      startDate
      endDate
      nhsPaymentMethod
      nhsSignerBase
      nhsSigner {
        id
        party {
          id
          ...FullName
        }
      }
      issueCity
      statusReason
      nhsContractPrice
      contractorRmspAmount
    }
    ${FullName.fragments.entry}
  `
};

export default GeneralInfoCapitation;
