import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { differenceInCalendarDays } from "date-fns";
import { Box, Flex } from "@rebass/emotion";

import {
  Form,
  Validations,
  Validation,
  Field as FieldListener
} from "@edenlabllc/ehealth-components";
import { Scalars, ProgramMedication } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";

import { PERCENT_PATTERN } from "../../../../constants/validationPatterns";

import { UpdateProgramMedicationMutation } from "./Update";

const MONEY_REGEXP = /^[0-9]*\.?([0-9]{1,2}$)/;
const MONEY_REGEXP_4_DIGITS_AFTER_COMMA = /^[0-9]*\.?([0-9]{1,4}$)/;

type UpdateReimbursementDataProps = {
  id: Scalars["ID"]["input"];
  reimbursement: ProgramMedication["reimbursement"];
  startDate: ProgramMedication["startDate"];
  endDate: ProgramMedication["endDate"];
  registryNumber: ProgramMedication["registryNumber"];
  consumerPrice: ProgramMedication["consumerPrice"];
  reimbursementDailyDosage: ProgramMedication["reimbursementDailyDosage"];
  wholesalePrice: ProgramMedication["wholesalePrice"];
  estimatedPaymentAmount: ProgramMedication["estimatedPaymentAmount"];
  medication: ProgramMedication["medication"];
};

const UpdateReimbursementData = ({
  id,
  reimbursement,
  startDate,
  endDate,
  registryNumber,
  consumerPrice,
  reimbursementDailyDosage,
  wholesalePrice,
  estimatedPaymentAmount,
  medication
}: UpdateReimbursementDataProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const { i18n } = useLingui();
  const toggle = () => setPopupVisibility(!isPopupVisible);

  const { type, reimbursementAmount, percentageDiscount } = reimbursement;
  const isPercentage = type === "PERCENTAGE";
  const isBrand = medication.type === "BRAND";

  return (
    <Box>
      <Button variant="blue" onClick={toggle}>
        <Trans>Update reimbursement data</Trans>
      </Button>
      <Mutation mutation={UpdateProgramMedicationMutation}>
        {(updateProgramMedication: MutationFunction) => (
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={<Trans>Update reimbursement data</Trans>}
            okButtonProps={{ variant: "green" }}
            okText={<Trans>Update</Trans>}
            justifyButtons="left"
            formId="addServiceToGroup"
          >
            <Form
              id="addServiceToGroup"
              initialValues={{
                reimbursementAmount,
                date: { startDate, endDate },
                registryNumber,
                consumerPrice,
                reimbursementDailyDosage,
                wholesalePrice,
                estimatedPaymentAmount,
                percentageDiscount: String(percentageDiscount)
              }}
              onSubmit={async ({
                reimbursementAmount,
                percentageDiscount,
                // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
                date: { startDate, endDate } = {},
                registryNumber,
                consumerPrice,
                reimbursementDailyDosage,
                wholesalePrice,
                estimatedPaymentAmount
              }: {
                reimbursementAmount: string;
                percentageDiscount: string;
                data: {
                  startDate: string;
                  endDate: string;
                };
                registryNumber: string;
                consumerPrice: string;
                reimbursementDailyDosage: string;
                wholesalePrice: string;
                estimatedPaymentAmount: string;
              }) => {
                toggle();
                await updateProgramMedication({
                  variables: {
                    input: {
                      id,
                      startDate: startDate || null,
                      endDate: endDate || null,
                      registryNumber: registryNumber || null,
                      reimbursement: {
                        ...(reimbursementAmount && {
                          reimbursementAmount: parseFloat(reimbursementAmount)
                        }),
                        percentageDiscount: parseFloat(percentageDiscount)
                      },
                      ...(consumerPrice !== null && {
                        consumerPrice: parseFloat(consumerPrice)
                      }),
                      ...(reimbursementDailyDosage !== null && {
                        reimbursementDailyDosage: parseFloat(
                          reimbursementDailyDosage
                        )
                      }),
                      ...(wholesalePrice !== null && {
                        wholesalePrice: parseFloat(wholesalePrice)
                      }),
                      ...(estimatedPaymentAmount !== null && {
                        estimatedPaymentAmount: parseFloat(
                          estimatedPaymentAmount
                        )
                      })
                    }
                  }
                });
              }}
            >
              <Flex mx={-1}>
                <Box px={1} width={1 / 2}>
                  <Field.Text
                    name="reimbursementAmount"
                    value={reimbursementAmount}
                    label={<Trans>Reimbursement amount</Trans>}
                    placeholder="0 - 1 000 000"
                    postfix={<Trans>uah</Trans>}
                    disabled={isPercentage}
                  />
                  {!isPercentage && (
                    <>
                      <FieldListener
                        name="reimbursementAmount"
                        subscription={{ value: true }}
                      >
                        {({ input: { value = "" } }) =>
                          !MONEY_REGEXP.test(value) && (
                            <Validation.Custom
                              field="reimbursementAmount"
                              message="Invalid format"
                            />
                          )
                        }
                      </FieldListener>
                      <Validation.Required
                        field="reimbursementAmount"
                        message="Required field"
                      />
                    </>
                  )}
                </Box>
                <Box px={1} width={1 / 2}>
                  <Field.Number
                    name="percentageDiscount"
                    label={<Trans>% of discount</Trans>}
                    placeholder="0 - 100"
                  />
                  <Validations field="percentageDiscount">
                    <Validation.Required message="Required field" />
                    <Validation.Matches
                      field="percentageDiscount"
                      options={PERCENT_PATTERN}
                      message={i18n._(t`Invalid percentage discount`)}
                    />
                  </Validations>
                </Box>
              </Flex>
              <Flex mx={-1}>
                <Box px={1} width={1 / 2}>
                  <Field.Text
                    name="registryNumber"
                    label={<Trans>Registry number</Trans>}
                    placeholder={i18n._(t`Enter registry number`)}
                    autoComplete="off"
                  />
                </Box>
              </Flex>
              <Flex mx={-1}>
                <Box px={1} width={1 / 2}>
                  <Field.DatePicker
                    name="date.startDate"
                    label={<Trans>Start date</Trans>}
                    minDate="1900-01-01"
                  />
                  <FieldListener name="date" subscription={{ value: true }}>
                    {({
                      // @ts-expect-error
                      input: { value: { startDate, endDate } = {} }
                    }: $TSFixMe) =>
                      startDate && endDate ? (
                        <Validation.Custom
                          field="date.startDate"
                          options={() => {
                            const differenceInDays = differenceInCalendarDays(
                              new Date(endDate),
                              new Date(startDate)
                            );
                            return differenceInDays >= 0;
                          }}
                          message="Start date must be earlier than end date"
                        />
                      ) : null
                    }
                  </FieldListener>
                </Box>
                <Box px={1} width={1 / 2}>
                  <Field.DatePicker
                    name="date.endDate"
                    label={<Trans>End date</Trans>}
                    minDate="1900-01-01"
                  />
                </Box>
              </Flex>
              <Flex mx={-1}>
                <Box px={1} width={1 / 2}>
                  <Field.Number
                    name="consumerPrice"
                    value={consumerPrice}
                    label={
                      isBrand ? (
                        <Trans>Consumer price</Trans>
                      ) : (
                        <Trans>Consumer price per unit</Trans>
                      )
                    }
                    placeholder="0 - 1 000 000"
                    postfix={<Trans>uah</Trans>}
                    allowNull
                  />
                  <FieldListener
                    name="consumerPrice"
                    subscription={{ value: true }}
                  >
                    {({ input: { value = "" } }) =>
                      !!value &&
                      !MONEY_REGEXP.test(value) && (
                        <Validation.Custom
                          field="consumerPrice"
                          message="Invalid format"
                        />
                      )
                    }
                  </FieldListener>
                </Box>
                <Box px={1} width={1 / 2}>
                  <Field.Number
                    name="reimbursementDailyDosage"
                    label={
                      <Trans id="Reimbursement daily dosage update">
                        Reimbursement daily dosage
                      </Trans>
                    }
                    placeholder="0 - 1 000 000"
                    postfix={<Trans>uah</Trans>}
                  />
                  <FieldListener
                    name="reimbursementDailyDosage"
                    subscription={{ value: true }}
                  >
                    {({ input: { value = "" } }) =>
                      !!value &&
                      !MONEY_REGEXP_4_DIGITS_AFTER_COMMA.test(value) && (
                        <Validation.Custom
                          field="reimbursementDailyDosage"
                          message="Invalid format"
                        />
                      )
                    }
                  </FieldListener>
                </Box>
              </Flex>
              <Flex mx={-1}>
                <Box px={1} width={1 / 2}>
                  <Field.Number
                    name="wholesalePrice"
                    label={
                      isBrand ? (
                        <Trans>Wholesale price</Trans>
                      ) : (
                        <Trans>Wholesale price per unit</Trans>
                      )
                    }
                    placeholder="0 - 1 000 000"
                    postfix={<Trans>uah</Trans>}
                  />
                  <FieldListener
                    name="wholesalePrice"
                    subscription={{ value: true }}
                  >
                    {({ input: { value = "" } }) =>
                      !!value &&
                      !MONEY_REGEXP.test(value) && (
                        <Validation.Custom
                          field="wholesalePrice"
                          message="Invalid format"
                        />
                      )
                    }
                  </FieldListener>
                </Box>
                <Box px={1} width={1 / 2}>
                  <Field.Number
                    name="estimatedPaymentAmount"
                    label={
                      isBrand ? (
                        <Trans>Estimated payment amount</Trans>
                      ) : (
                        <Trans>Estimated payment amount per unit</Trans>
                      )
                    }
                    placeholder="0 - 1 000 000"
                    postfix={<Trans>uah</Trans>}
                  />
                  <FieldListener
                    name="estimatedPaymentAmount"
                    subscription={{ value: true }}
                  >
                    {({ input: { value = "" } }) =>
                      !!value &&
                      !MONEY_REGEXP.test(value) && (
                        <Validation.Custom
                          field="estimatedPaymentAmount"
                          message="Invalid format"
                        />
                      )
                    }
                  </FieldListener>
                </Box>
              </Flex>
            </Form>
          </Popup>
        )}
      </Mutation>
    </Box>
  );
};

export default UpdateReimbursementData;
