import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Hospitalization as THospitalization,
  Encounter as TEncounter,
  Reference as TReference
} from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import CodeableConceptList from "./CodeableConceptList";
import Collapsable from "../../../components/Collapsable";
import Coding from "../../../components/Coding";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Line from "../../../components/Line";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";
import {
  FunctionComponentWithFragments,
  MonitoringDetailsPageProps
} from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import ServiceRequestCodeList from "./ServiceRequestCodeList";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import DivisionReference from "./DivisionReference";
import Reference from "./Reference";
import Diagnosis from "./Diagnosis";
import { LABEL_WIDTH } from "./Episode";
import SupportingInfo from "./SupportingInfo";
import { PaperReferral } from "./PaperReferral";
import ServiceRequestCode from "./ServiceRequestCode";

const Encounter = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => {
  const handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) =>
    isClinicalMonitoring
      ? `../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`
      : `../../${memoizedReferenceSlug}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`;

  return (
    <Ability
      action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
      resource="encounter"
    >
      <Box p={6}>
        <DetailsPageBreadcrumbs
          id={id}
          currentStepName={<Trans>Encounter details</Trans>}
          isClinicalMonitoring={isClinicalMonitoring}
        />
        <Query
          query={query!}
          fetchPolicy="network-only"
          variables={{
            ...queryVariables,
            id: itemId,
            ...(isClinicalMonitoring && { patientId })
          }}
        >
          {({
            loading,
            error,
            data = {}
          }: QueryResult<{ [key: string]: TEncounter }>) => {
            const encounter = isClinicalMonitoring
              ? data.impersonalEncounter
              : data.encounter;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  {!isEmpty(data) && !isEmpty(encounter) && !error && (
                    <>
                      <HeaderDefinitions
                        encounter={encounter}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId!}
                      />
                      <TopBodyDefinitions encounter={encounter} />
                      {!isEmpty(encounter.episode) && (
                        <Reference
                          fontSize={14}
                          header={<Trans>Episode</Trans>}
                          linkPath={
                            isClinicalMonitoring
                              ? `../../../episode/${patientId}/${paramToBase64(
                                  "Episode",
                                  encounter.episode.identifier.value
                                )}`
                              : `../../episode/${paramToBase64(
                                  "Episode",
                                  encounter.episode.identifier.value
                                )}`
                          }
                          linkDisplayValue={
                            encounter.episode.displayValue
                              ? encounter.episode.displayValue
                              : encounter.episode.identifier.value
                          }
                        />
                      )}
                      <Box mt={4}>
                        <DefinitionListView
                          fontSize={14}
                          labels={{
                            visit: <Trans>Visit ID</Trans>
                          }}
                          data={{
                            visit:
                              encounter && encounter.visit
                                ? encounter.visit.identifier.value
                                : "-"
                          }}
                          labelWidth={LABEL_WIDTH}
                        />
                      </Box>
                      {!isEmpty(encounter.performer) && (
                        <Box mt={2}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Performer</Trans>}
                            linkPath={`/employees/${paramToBase64(
                              "Employee",
                              encounter.performer.identifier.value
                            )}`}
                            linkDisplayValue={
                              encounter.performer.displayValue
                                ? encounter.performer.displayValue
                                : encounter.performer.identifier.value
                            }
                          />
                        </Box>
                      )}
                      {!isEmpty(encounter.diagnoses) && (
                        <Box mt={4}>
                          <Text
                            fontSize={1}
                            fontWeight="bold"
                            color="darkCharcoal"
                          >
                            <Trans>Diagnoses</Trans>
                          </Text>
                          <Diagnosis
                            diagnosis={encounter.diagnoses}
                            isClinicalMonitoring={isClinicalMonitoring}
                            patientId={patientId}
                          />
                        </Box>
                      )}
                      <Box mt={4}>
                        <MiddleBodyDefinitions encounter={encounter} />
                      </Box>
                      <DivisionReference
                        reference={encounter}
                        divisionFieldPath="division"
                        header={<Trans>Division</Trans>}
                      />
                      {!isEmpty(encounter.incomingReferral) && (
                        <Box mt={4}>
                          <Reference
                            fontSize={14}
                            header={<Trans>Incoming referral</Trans>}
                            linkPath={
                              isClinicalMonitoring
                                ? `../../../service-request/${patientId}/${paramToBase64(
                                    "ServiceRequest",
                                    encounter.incomingReferral.identifier.value
                                  )}`
                                : `../../service-request/${paramToBase64(
                                    "ServiceRequest",
                                    encounter.incomingReferral.identifier.value
                                  )}`
                            }
                            linkDisplayValue={
                              encounter.incomingReferral.displayValue
                                ? encounter.incomingReferral.displayValue
                                : encounter.incomingReferral.identifier.value
                            }
                          />
                        </Box>
                      )}
                      {!isEmpty(encounter.paperReferral) && (
                        <Box mt={4}>
                          <Text
                            fontSize={1}
                            fontWeight="bold"
                            color="darkCharcoal"
                          >
                            <Trans>Paper referral</Trans>
                          </Text>
                          <PaperReferral
                            paperReferral={encounter.paperReferral}
                            dateFormat={DATE_TIME_FORMAT}
                          />
                        </Box>
                      )}
                      <Hospitalization
                        hospitalization={encounter.hospitalization}
                        titleProps={{
                          fontSize: 1,
                          fontWeight: "bold",
                          mr: 2,
                          color: "darkCharcoal"
                        }}
                        title={<Trans>Hospitalization</Trans>}
                      />
                      {!isEmpty(encounter.supportingInfo) && (
                        <Collapsable
                          title={
                            <Text color="darkCharcoal">
                              <Trans>Supporting info</Trans>
                            </Text>
                          }
                        >
                          <SupportingInfo
                            supportingInfo={encounter.supportingInfo}
                            handleSetReferenceLink={handleSetReferenceLink}
                          />
                        </Collapsable>
                      )}
                      <Box mt={4}>
                        <BottomBodyDefinitions encounter={encounter} />
                      </Box>
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
        <Conclusions
          isClinicalMonitoring={isClinicalMonitoring}
          patientId={patientId!}
          itemId={itemId!}
        />
      </Box>
    </Ability>
  );
};

export default Encounter;

type HeaderDefinitionsProps = {
  encounter: TEncounter;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  encounter,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(encounter)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>,
              explanatoryLetter: <Trans>Explanatory letter</Trans>,
              ...(encounter.cancellationReason && {
                cancellationReason: <Trans>Cancellation reason</Trans>
              })
            }}
            data={{
              ...encounter,
              status: (
                <DictionaryValue
                  name="eHealth/encounter_statuses"
                  item={encounter.status.toLowerCase()}
                />
              ),
              explanatoryLetter: encounter.explanatoryLetter,
              cancellationReason: (
                <CodeableConcept
                  codeableConcept={encounter.cancellationReason}
                />
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "encounter",
              encounter.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({ encounter }: { encounter: TEncounter }) => {
  const { i18n } = useLingui();
  if (isEmpty(encounter)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        type: <Trans>Type</Trans>,
        class: <Trans>Class</Trans>,
        dateFrom: <Trans>Encounter start date</Trans>,
        dateTo: <Trans>Encounter end date</Trans>
      }}
      data={{
        ...encounter,
        type: <CodeableConcept codeableConcept={encounter.type} />,
        class: <Coding coding={encounter.class} />,
        dateFrom: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          encounter.period.start
        ),
        dateTo: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          encounter.period.end
        )
      }}
      labelWidth={LABEL_WIDTH}
    />
  );
};

const MiddleBodyDefinitions = ({ encounter }: { encounter: TEncounter }) => {
  if (isEmpty(encounter)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        ...(encounter.priority && {
          priority: <Trans>Priority</Trans>
        }),
        ...(!isEmpty(encounter.reasons) && {
          reasons: <Trans>Reasons</Trans>
        }),
        ...(!isEmpty(encounter.actions) && {
          actions: <Trans>Actions</Trans>
        }),
        ...(!isEmpty(encounter.actionReferences) && {
          actionReferences: <Trans>Action references</Trans>
        })
      }}
      data={{
        priority: <CodeableConcept codeableConcept={encounter.priority} />,
        reasons: (
          <CodeableConceptList
            codeableConceptList={encounter.reasons}
            withCode
          />
        ),
        actions: (
          <CodeableConceptList
            codeableConceptList={encounter.actions}
            withCode
          />
        ),
        actionReferences: (
          <ServiceRequestCodeList
            referencesList={encounter.actionReferences}
            pathSlug="/services"
          />
        )
      }}
      labelWidth={LABEL_WIDTH}
    />
  );
};

const BottomBodyDefinitions = ({ encounter }: { encounter: TEncounter }) => {
  const { i18n } = useLingui();
  if (isEmpty(encounter)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        ...(encounter.prescriptions && {
          prescriptions: <Trans>Prescriptions</Trans>
        }),
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>
      }}
      data={{
        prescriptions: encounter.prescriptions,
        insertedAt: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          encounter.insertedAt
        ),
        updatedAt: dateFormatter(
          i18n.locale,
          DATE_TIME_FORMAT,
          encounter.updatedAt
        )
      }}
      labelWidth={LABEL_WIDTH}
    />
  );
};

type HospitalizationProps = {
  hospitalization: TEncounter["hospitalization"];
  title: React.ReactNode;
  titleProps: React.CSSProperties | any;
};

const Hospitalization: FunctionComponentWithFragments<HospitalizationProps> = ({
  hospitalization,
  title,
  titleProps
}) => {
  if (isEmpty(hospitalization)) return null;

  return (
    <>
      <Text {...titleProps}>{title}</Text>
      <Box mt={-4}>
        <Table
          hidePagination
          hideControls
          data={[{ ...hospitalization }]}
          header={{
            admitSource: <Trans>Admit source</Trans>,
            dischargeDisposition: <Trans>Discharge disposition</Trans>,
            dischargeDepartment: <Trans>Discharge department</Trans>,
            preAdmissionIdentifier: <Trans>Pre-admission identifier</Trans>,
            reAdmission: <Trans>Re-admission</Trans>
          }}
          renderRow={({
            admitSource,
            dischargeDisposition,
            dischargeDepartment,
            reAdmission,
            ...restData
          }: THospitalization) => ({
            ...restData,
            admitSource: <CodeableConcept codeableConcept={admitSource} />,
            dischargeDisposition: (
              <CodeableConcept codeableConcept={dischargeDisposition} />
            ),
            dischargeDepartment: (
              <CodeableConcept codeableConcept={dischargeDepartment} />
            ),
            reAdmission: <CodeableConcept codeableConcept={reAdmission} />
          })}
        />
      </Box>
    </>
  );
};

Hospitalization.fragments = {
  entry: gql`
    fragment Hospitalization on Hospitalization {
      admitSource {
        ...CodeableConcept
      }
      destination {
        ...Reference
      }
      dischargeDepartment {
        ...CodeableConcept
      }
      dischargeDisposition {
        ...CodeableConcept
      }
      preAdmissionIdentifier
      reAdmission {
        ...CodeableConcept
      }
    }
  `
};

Encounter.fragments = {
  entry: gql`
    fragment Encounter on Encounter {
      id
      databaseId
      status
      type {
        ...CodeableConcept
      }
      class {
        ...Coding
      }
      period {
        end
        start
      }
      episode {
        ...Reference
      }
      visit {
        ...Reference
      }
      performer {
        ...Reference
      }
      diagnoses {
        ...Diagnosis
      }
      priority {
        ...CodeableConcept
      }
      reasons {
        ...CodeableConcept
      }
      actions {
        ...CodeableConcept
      }
      actionReferences {
        ...ServiceRequestCode
      }
      division {
        ...Reference
      }
      incomingReferral {
        ...Reference
      }
      paperReferral {
        ...PaperReferral
      }
      prescriptions @skip(if: $skipAdditionalFields)
      hospitalization {
        ...Hospitalization
      }
      explanatoryLetter
      cancellationReason {
        ...CodeableConcept
      }
      supportingInfo @skip(if: $skipAdditionalFields) {
        ...Reference
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Coding.fragments.entry}
    ${Reference.fragments.entry}
    ${Diagnosis.fragments.entry}
    ${PaperReferral.fragments.entry}
    ${Hospitalization.fragments.entry}
    ${ServiceRequestCode.fragments.entry}
  `
};

export const EncounterByIDQuery = gql`
  query EncounterByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    encounter(id: $id, personId: $personId) {
      ...Encounter
    }
  }
  ${Encounter.fragments.entry}
`;
