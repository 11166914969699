import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import Cookie from "js-cookie";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import { LocationParams } from "@edenlabllc/ehealth-components";
import { ApprovalConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import DetailsPageBreadcrumbs from "../Details/DetailsPageBreadcrumbs";
import Create from "./Create";
import ApprovalsTable from "./ApprovalsTable";

type PatientApprovalsProps = RouteComponentProps<{
  id: string;
}>;

const PatientApprovals = ({ navigate, id }: PatientApprovalsProps) => {
  const { userId } = Cookie.getJSON("meta");

  return (
    <Ability action="read" resource="approval">
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <Box p={6}>
            <DetailsPageBreadcrumbs
              id={id}
              currentStepName={<Trans>Patient approvals</Trans>}
            />
            <Query
              query={PatientApprovalsQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination(locationParams),
                orderBy: locationParams.orderBy
                  ? locationParams.orderBy
                  : "INSERTED_AT_DESC",
                filter: {
                  personId: id,
                  grantedResourceType: "FORBIDDEN_GROUP",
                  userId
                }
              }}
            >
              {({
                loading,
                data,
                refetch
              }: QueryResult<{ approvals: ApprovalConnection }>) => {
                return (
                  <Box mt={4}>
                    <Flex mb={2} flexDirection="column">
                      <Box mt={2} mb={2} alignSelf="flex-end">
                        <Button
                          variant="link"
                          px="0"
                          py="0"
                          icon={RemoveItemIcon}
                          type="reset"
                          onClick={() => navigate!("../")}
                        >
                          <Trans>Exit the patient approvals</Trans>
                        </Button>
                      </Box>
                      <Ability action="create" resource="approval">
                        <Box mt={4} alignSelf="flex-end">
                          <Create id={id!} refetch={refetch} />
                        </Box>
                      </Ability>
                    </Flex>

                    <ApprovalsTable
                      personId={id!}
                      loading={loading}
                      data={data}
                      setLocationParams={setLocationParams}
                      locationParams={locationParams}
                      refetch={refetch}
                    />
                  </Box>
                );
              }}
            </Query>
          </Box>
        )}
      </LocationParams>
    </Ability>
  );
};

export default PatientApprovals;

const PatientApprovalsQuery = gql`
  query PatientApprovalsQuery(
    $after: String
    $before: String
    $filter: ApprovalFilter!
    $first: Int
    $last: Int
    $orderBy: ApprovalOrderBy = INSERTED_AT_ASC
    $skip: Boolean! = false
  ) {
    approvals(
      after: $after
      before: $before
      filter: $filter
      first: $first
      last: $last
      orderBy: $orderBy
    ) @skip(if: $skip) {
      nodes {
        authenticationMethodCurrent {
          type
          phoneNumber
        }
        id
        databaseId
        grantedTo {
          id
          databaseId
          party {
            id
            firstName
            secondName
            lastName
          }
        }
        grantedResources {
          id
          name
        }
        isVerified
        expiresAt
        insertedAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
