import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

// @ts-expect-error types mismatch
import { MedicalComposition } from "@edenlabllc/graphql-schema";

import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import { Spinner } from "../../../components/LoadingOverlay";
import Table from "../../../components/Table";
import UnpocessableEntityModalError from "../../../components/UnpocessableEntityModalError";

import dateFormatter from "../../../helpers/dateFormatter";

const useResolveChain = (originalMedicalComposition: MedicalComposition) => {
  const hardLimit = 10;
  const [chain, setChain] = useState<$TSFixMe>([]);
  const [hasNext, setHasNext] = useState(false);
  const [error, setError] = useState(null);
  const [getComposition] = useLazyQuery(MedicalCompositionRecursiveQuery);
  const [chainLoading, setChainLoading] = useState(false);
  const callNextRef = useRef();
  const counterRef = useRef(hardLimit);

  useEffect(() => {
    const tempChainHolder: $TSFixMe = [];
    const queryNext: $TSFixMe = (parentComposition: MedicalComposition) => {
      if (counterRef.current <= 0) {
        setHasNext(true);
        return tempChainHolder;
      }

      counterRef.current -= 1;
      return getComposition({
        variables: {
          id: parentComposition.relatesTo[0].targetIdentifier.value
        }
      })
        .then(({ data: { query } }) => {
          const composition = {
            ...query.items[0],
            relatedBy: [
              {
                code: parentComposition.relatesTo[0].code,
                targetIdentifier: {
                  type: {
                    coding: [
                      {
                        code: "composition",
                        system: "eHealth/resources"
                      }
                    ]
                  },
                  value: parentComposition.identifier.value
                }
              }
            ]
          };
          tempChainHolder.push(composition);
          if (composition.relatesTo[0]) {
            return queryNext(composition);
          } else {
            setHasNext(false);
            return tempChainHolder;
          }
        })
        .catch((e) => {
          setError(e);
        });
    };
    callNextRef.current = queryNext;

    if (originalMedicalComposition.relatesTo[0]) {
      setChainLoading(true);
      queryNext(originalMedicalComposition).then((fetchedItems: $TSFixMe) => {
        setChain([...chain, ...fetchedItems]);
        setChainLoading(false);
      });
    }
  }, []);

  return {
    chainedItems: chain,
    hasNext: hasNext,
    error,
    loading: chainLoading,
    next: () => {
      if (chainLoading || !hasNext) {
        return;
      }
      setChainLoading(true);
      counterRef.current = hardLimit;
      // @ts-ignore
      return callNextRef
        .current(chain[chain.length - 1])
        .then((fetchedItems: $TSFixMe) => {
          setChain([...fetchedItems]);
        })
        .finally(() => {
          setChainLoading(false);
        });
    }
  };
};

type CompositionChainProps = RouteComponentProps<{
  item: $TSFixMe;
}>;

const CompositionChain = ({ item }: CompositionChainProps) => {
  const { hasNext, next, chainedItems, loading, error } = useResolveChain(item);
  return (
    <Flex
      flexDirection={"column"}
      style={{ position: "relative", minHeight: "100px" }}
      p={5}
    >
      {loading && <Spinner loading={loading} />}
      {chainedItems.length ? (
        <Table
          data={chainedItems}
          header={{
            type: <Trans>Medical composition relation type</Trans>,
            databaseId: <Trans>ID</Trans>,
            startedAt: <Trans>Started</Trans>,
            endedAt: <Trans>Expiration date</Trans>,
            action: <Trans>Action</Trans>
          }}
          renderRow={({ identifier, event, relatedBy }) => ({
            databaseId: <>{identifier.value}</>,
            type: (
              <DictionaryValue
                name={"COMPOSITION_RELATION_CODE"}
                item={relatedBy[0] ? relatedBy[0].code : ""}
              />
            ),
            startedAt: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              },
              event.find((ev: $TSFixMe) => ev.period).period.start
            ),
            endedAt: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              },
              event.find((ev: $TSFixMe) => ev.period).period.end
            ),
            action: (
              <Link to={`../../${identifier.value}`} fontWeight="bold">
                <Trans>Details</Trans>
              </Link>
            )
          })}
          sortableFields={[]}
          // sortingParams={[]}
          whiteSpaceNoWrap={[]}
          hidePagination={true}
          tableName="medicalComposition/chain"
          hiddenFields=""
        />
      ) : (
        <></>
      )}
      {hasNext && !loading && (
        <Button
          mt={5}
          onClick={next}
          variant="blue"
          style={{ alignSelf: "center" }}
        >
          <Trans>Next</Trans>
        </Button>
      )}
      {error && (
        <UnpocessableEntityModalError errorMessage={error} isModalOpen />
      )}
    </Flex>
  );
};

export default CompositionChain;

const MedicalCompositionRecursiveQuery = gql`
  query MedicalCompositionQuery($id: String!) {
    query(id: $id)
      @rest(
        type: "SearchPayload"
        path: "/admin/composition/search?offset=0&limit=1&id=:id"
      ) {
      items
      hasNext
    }
  }
`;
