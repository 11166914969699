import * as React from "react";
import { gql } from "graphql-tag";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Maybe } from "@edenlabllc/graphql-schema";

import dateFormatter from "../helpers/dateFormatter";

import Badge from "./Badge";
import Table, { SortingParams } from "./Table";
import { SearchParams, SetSearchParams } from "./SearchForm";
import { DATE_TIME_FORMAT } from "../constants/dateFormats";

type TaskTableItemType = any; // DeclarationsTerminationTask | Task | LegalEntityReorganizationTask | MedicationRegistryTask | PersonsDeactivationTask;

type TasksTableProps = {
  tasks: Maybe<Maybe<TaskTableItemType>[]>;
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName: string;
  taskName?: ({ name, meta }: { name?: Maybe<string>; meta?: any }) => any;
  taskMeta?: (data: { meta: any }) => React.ReactElement;
};

const TasksTable = ({
  tasks,
  locationParams,
  setLocationParams,
  tableName,
  taskName: TaskName,
  taskMeta: TaskMeta
}: TasksTableProps) => {
  const { i18n } = useLingui();
  const withMeta = TaskMeta && { meta: <Trans>Task meta</Trans> };

  return (
    <Table
      data={tasks}
      header={{
        databaseId: <Trans>ID</Trans>,
        name: <Trans>Name</Trans>,
        ...withMeta,
        priority: <Trans>Priority</Trans>,
        status: <Trans>Status</Trans>,
        insertedAt: <Trans>Inserted at</Trans>,
        updatedAt: <Trans>Updated at</Trans>,
        endedAt: <Trans>Ended at</Trans>,
        errorMessage: <Trans>Error Message</Trans>
      }}
      renderRow={({
        name,
        insertedAt,
        updatedAt,
        status,
        requestAllowed,
        endedAt,
        meta,
        error,
        ...taskData
      }: TaskTableItemType) => {
        const errorMessage = error && error.message;
        return {
          ...taskData,
          name: TaskName ? <TaskName meta={meta} name={name} /> : name,
          meta: TaskMeta && <TaskMeta meta={meta} />,
          errorMessage,
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
          endedAt: endedAt
            ? dateFormatter(i18n.locale, DATE_TIME_FORMAT, endedAt)
            : "-",
          status: <Badge type="TASKS" name={status} display="block" />
        };
      }}
      sortableFields={["status", "insertedAt"]}
      sortingParams={parseSortingParams(locationParams.orderBy)}
      onSortingChange={(sortingParams: SortingParams) =>
        setLocationParams({
          ...locationParams,
          orderBy: stringifySortingParams(sortingParams)
        })
      }
      whiteSpaceNoWrap={["databaseId"]}
      hiddenFields="databaseId,priority,updatedAt,errorMessage"
      tableName={tableName}
    />
  );
};

TasksTable.fragments = {
  entry: gql`
    fragment JobTasks on Task {
      id
      databaseId
      name
      priority
      status
      insertedAt
      endedAt
      updatedAt
    }
  `
};

export default TasksTable;
