import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Immunization as TImmunization } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";
import STATUSES from "../../../helpers/statuses";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import CodeableConceptList from "./CodeableConceptList";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Reference from "./Reference";
import VaccinationProtocol from "./VaccinationProtocol";
import Quantity from "./Quantity";
import { LABEL_WIDTH } from "./Episode";

const Immunization = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="immunization"
  >
    <Box p={6}>
      <DetailsPageBreadcrumbs
        id={id}
        currentStepName={<Trans>Immunization details</Trans>}
        isClinicalMonitoring={isClinicalMonitoring}
      />
      <Query
        query={query!}
        fetchPolicy="network-only"
        variables={{
          ...queryVariables,
          id: itemId,
          ...(isClinicalMonitoring && { patientId })
        }}
      >
        {({
          loading,
          error,
          data = {}
        }: QueryResult<{ [key: string]: TImmunization }>) => {
          const immunization = isClinicalMonitoring
            ? data.impersonalImmunization
            : data.immunization;

          return (
            <LoadingOverlay loading={loading}>
              <Box mt={4}>
                {!isEmpty(immunization) && !error && (
                  <>
                    <HeaderDefinitions
                      immunization={immunization}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId!}
                    />
                    <Box mt={4}>
                      <TopBodyDefinitions immunization={immunization} />
                    </Box>
                    {!isEmpty(immunization.context) && (
                      <Box mt={2}>
                        <Reference
                          fontSize={14}
                          header={<Trans>Context</Trans>}
                          linkPath={
                            isClinicalMonitoring
                              ? `../../../encounter/${patientId}/${paramToBase64(
                                  "Encounter",
                                  immunization.context.identifier.value
                                )}`
                              : `../../encounter/${paramToBase64(
                                  "Encounter",
                                  immunization.context.identifier.value
                                )}`
                          }
                          linkDisplayValue={
                            immunization.context.displayValue
                              ? immunization.context.displayValue
                              : immunization.context.identifier.value
                          }
                        />
                      </Box>
                    )}
                    {!isEmpty(immunization.performer) && (
                      <Box mt={2}>
                        <Reference
                          fontSize={14}
                          header={<Trans>Performer</Trans>}
                          linkPath={`/employees/${paramToBase64(
                            "Employee",
                            immunization.performer.identifier.value
                          )}`}
                          linkDisplayValue={
                            immunization.performer.displayValue
                              ? immunization.performer.displayValue
                              : immunization.performer.identifier.value
                          }
                        />
                      </Box>
                    )}
                    <Explanation explanation={immunization.explanation} />
                    <VaccinationProtocols
                      vaccinationProtocols={immunization.vaccinationProtocols}
                    />
                    <BottomBodyDefinitions
                      immunization={immunization}
                      isClinicalMonitoring={isClinicalMonitoring}
                      patientId={patientId!}
                    />
                  </>
                )}
              </Box>
            </LoadingOverlay>
          );
        }}
      </Query>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Box>
  </Ability>
);

export default Immunization;

type HeaderDefinitionsProps = {
  immunization: TImmunization;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  immunization,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(immunization)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              ...(immunization.status && {
                status: <Trans>Status</Trans>
              })
            }}
            data={{
              ...immunization,
              status: (
                <DictionaryValue
                  name="eHealth/immunization_statuses"
                  item={immunization.status.toLowerCase()}
                />
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "immunization",
              immunization.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

const TopBodyDefinitions = ({
  immunization
}: {
  immunization: TImmunization;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(immunization)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labels={{
        notGiven: <Trans>Not given</Trans>,
        vaccineCode: <Trans>Vaccine code</Trans>,
        date: <Trans>Date of immunization</Trans>,
        primarySource: <Trans>Primary source</Trans>,
        ...(immunization.reportOrigin && {
          reportOrigin: <Trans>Report origin</Trans>
        }),
        ...(immunization.manufacturer && {
          manufacturer: <Trans>Manufacturer</Trans>
        }),
        ...(immunization.lotNumber && { lotNumber: <Trans>Lot number</Trans> }),
        ...(immunization.expirationDate && {
          expirationDate: (
            <Trans id="Immunization expiration date">Expiration date</Trans>
          )
        }),
        ...(immunization.site && {
          site: <Trans>Site</Trans>
        }),
        ...(immunization.route && {
          route: <Trans>Route</Trans>
        }),
        ...(immunization.doseQuantity && {
          doseQuantity: <Trans>Dose quantity</Trans>
        })
      }}
      data={{
        ...immunization,
        // @ts-expect-error statuses boolean key
        notGiven: <Box>{STATUSES.YES_NO[immunization.notGiven]}</Box>,
        vaccineCode: (
          <CodeableConcept
            codeableConcept={immunization.vaccineCode}
            withCode
          />
        ),
        date: dateFormatter(i18n.locale, DATE_TIME_FORMAT, immunization.date),
        // @ts-expect-error statuses boolean key
        primarySource: <Box>{STATUSES.YES_NO[immunization.primarySource]}</Box>,
        ...(immunization.reportOrigin && {
          reportOrigin: (
            <CodeableConcept codeableConcept={immunization.reportOrigin} />
          )
        }),
        ...(immunization.expirationDate && {
          expirationDate: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            immunization.expirationDate
          )
        }),
        ...(immunization.site && {
          site: <CodeableConcept codeableConcept={immunization.site} />
        }),
        ...(immunization.route && {
          route: <CodeableConcept codeableConcept={immunization.route} />
        }),
        ...(immunization.doseQuantity && {
          doseQuantity: <Quantity quantity={immunization.doseQuantity} />
        })
      }}
      labelWidth={LABEL_WIDTH}
    />
  );
};

const VaccinationProtocols = ({
  vaccinationProtocols
}: {
  vaccinationProtocols: TImmunization["vaccinationProtocols"];
}) => {
  if (isEmpty(vaccinationProtocols)) return null;

  return (
    <Box mt={4}>
      <Text
        fontSize={1}
        fontWeight="bold"
        width={LABEL_WIDTH}
        color="darkCharcoal"
        mb={4}
      >
        <Trans>Vaccination protocols</Trans>
      </Text>
      {vaccinationProtocols!.map((vaccinationProtocol, index) => (
        <Box ml={4} mb={vaccinationProtocols!.length > 0 ? 4 : 0} key={index}>
          <VaccinationProtocol
            vaccinationProtocol={vaccinationProtocol}
            labelWidth={LABEL_WIDTH}
          />
        </Box>
      ))}
    </Box>
  );
};

const Explanation = ({
  explanation
}: {
  explanation: TImmunization["explanation"];
}) => {
  if (isEmpty(explanation)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          ...(!isEmpty(explanation.reasons) && {
            reasons: <Trans>Realization reasons</Trans>
          }),
          ...(!isEmpty(explanation.reasonsNotGiven) && {
            reasonsNotGiven: <Trans>Reasons not given</Trans>
          })
        }}
        data={{
          ...(!isEmpty(explanation.reasons) && {
            reasons: (
              <CodeableConceptList codeableConceptList={explanation.reasons} />
            )
          }),
          ...(!isEmpty(explanation.reasonsNotGiven) && {
            reasonsNotGiven: (
              <CodeableConceptList
                codeableConceptList={explanation.reasonsNotGiven}
              />
            )
          })
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

type ReactionsProps = {
  reactions: TImmunization["reactions"];
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const Reactions = ({
  reactions,
  isClinicalMonitoring,
  patientId
}: ReactionsProps) => {
  if (isEmpty(reactions)) return null;

  return (
    <>
      {reactions!.map((reaction, index) => {
        return reaction ? (
          <Box mb={2}>
            <Flex>
              <Text width={100} color="darkCharcoal">
                <Trans>Observations</Trans>:
              </Text>
              <Reference
                key={index}
                fontSize={14}
                linkPath={
                  isClinicalMonitoring
                    ? `../../../observation/${patientId}/${paramToBase64(
                        "Observation",
                        reaction.detail.identifier.value
                      )}`
                    : `../../observation/${paramToBase64(
                        "Observation",
                        reaction.detail.identifier.value
                      )}`
                }
                linkDisplayValue={
                  reaction.detail.displayValue
                    ? reaction.detail.displayValue
                    : reaction.detail.identifier.value
                }
                linkWrapperProps={{ mt: 0 }}
              />
            </Flex>
          </Box>
        ) : null;
      })}
    </>
  );
};

type BottomBodyDefinitionsProps = {
  immunization: TImmunization;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const BottomBodyDefinitions = ({
  immunization,
  isClinicalMonitoring,
  patientId
}: BottomBodyDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(immunization)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          ...(!isEmpty(immunization.reactions) && {
            reactions: <Trans>Reactions</Trans>
          }),
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            immunization.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            immunization.updatedAt
          ),
          ...(!isEmpty(immunization.reactions) && {
            reactions: (
              <Reactions
                reactions={immunization.reactions}
                isClinicalMonitoring={isClinicalMonitoring}
                patientId={patientId}
              />
            )
          })
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

Immunization.fragments = {
  entry: gql`
    fragment Immunization on Immunization {
      id
      databaseId
      status
      notGiven
      vaccineCode {
        ...CodeableConcept
      }
      context {
        ...Reference
      }
      date
      primarySource
      reportOrigin {
        ...CodeableConcept
      }
      manufacturer @skip(if: $skipAdditionalFields)
      lotNumber @skip(if: $skipAdditionalFields)
      expirationDate
      site @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      route @skip(if: $skipAdditionalFields) {
        ...CodeableConcept
      }
      doseQuantity @skip(if: $skipAdditionalFields) {
        ...Quantity
      }
      performer {
        ...Reference
      }
      explanation @skip(if: $skipAdditionalFields) {
        reasons {
          ...CodeableConcept
        }
        reasonsNotGiven {
          ...CodeableConcept
        }
      }
      reactions @skip(if: $skipAdditionalFields) {
        detail {
          ...Reference
        }
      }
      vaccinationProtocols @skip(if: $skipAdditionalFields) {
        ...VaccinationProtocol
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
    ${Quantity.fragments.entry}
    ${VaccinationProtocol.fragments.entry}
  `
};

export const ImmunizationByIDQuery = gql`
  query ImmunizationByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    immunization(id: $id, personId: $personId) {
      ...Immunization
    }
  }
  ${Immunization.fragments.entry}
`;
