import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { ContractRequest } from "@edenlabllc/graphql-schema";

import FullName from "../../../../components/FullName";
import DefinitionListView from "../../../../components/DefinitionListView";
import Line from "../../../../components/Line";
import LinkComponent from "../../../../components/Link";

type LegalEntityCapitationProps = RouteComponentProps & {
  contractorBase: ContractRequest["contractorBase"];
  contractorOwner: ContractRequest["contractorOwner"];
  contractorPaymentDetails: ContractRequest["contractorPaymentDetails"];
  contractorLegalEntity: ContractRequest["contractorLegalEntity"];
};

const LegalEntityCapitation = ({
  contractorBase,
  contractorOwner,
  contractorPaymentDetails: { bankName, mfo, payerAccount },
  contractorLegalEntity: {
    databaseId: legalEntityDatabaseId,
    id: legalEntityId,
    edrpou,
    edrData
  }
}: LegalEntityCapitationProps) => {
  const { name, registrationAddress } = edrData || {};
  const { zip, country, address } = registrationAddress || {};
  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          name: <Trans>Name</Trans>,
          addresses: <Trans>Address</Trans>
        }}
        data={{
          name,
          edrpou,
          addresses: (
            <>
              {zip}, {country}, {address}
            </>
          )
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          legalEntityId: <Trans>Legal entity ID</Trans>
        }}
        data={{
          legalEntityId: (
            <LinkComponent to={`/legal-entities/${legalEntityId}`}>
              {legalEntityDatabaseId}
            </LinkComponent>
          )
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          fullName: <Trans>Subscriber Name</Trans>,
          contractorBase: <Trans>Based on</Trans>
        }}
        data={{
          fullName: contractorOwner && (
            <FullName party={contractorOwner.party} />
          ),
          contractorBase: contractorBase
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          ownerId: <Trans>Signer ID</Trans>
        }}
        data={{
          ownerId: contractorOwner && contractorOwner.databaseId
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          bankName: <Trans>Bank</Trans>,
          mfo: <Trans>Bank Code (MFO)</Trans>,
          payerAccount: <Trans>Account number</Trans>
        }}
        data={{
          bankName,
          mfo,
          payerAccount
        }}
      />
    </Box>
  );
};

LegalEntityCapitation.fragments = {
  entry: gql`
    fragment LegalEntityCapitation on CapitationContractRequest {
      contractorBase
      contractorOwner {
        id
        databaseId
        party {
          id
          ...FullName
        }
      }
      contractorPaymentDetails {
        bankName
        mfo
        payerAccount
      }
      contractorLegalEntity {
        id
        name
        databaseId
        status
        edrpou
        edrData {
          name
          registrationAddress {
            zip
            country
            address
          }
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

export default LegalEntityCapitation;
