import React from "react";
import { NavigateFn, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { loader } from "graphql.macro";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { BooleanValue } from "react-values";
import { Flex, Box, Text, Heading as Title } from "@rebass/emotion";
import { isEqual, isEmpty } from "lodash";

import system from "@edenlabllc/ehealth-system-components";
import { Form, Modal } from "@edenlabllc/ehealth-components";
import {
  Maybe,
  MergeRequest,
  Person,
  Party,
  PersonDocument
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {
  TableRoot,
  TableBodyComponent,
  TableCell,
  TableRow,
  HeaderData
} from "../../../components/Table";

import { isValidDate } from "../../../helpers/dateHelpers";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import GeneralInfoPatient from "./GeneralInfoPatient";
import IdentityInfo from "./IdentityInfo";
import PhonesInfo from "./PhonesInfo";
import AuthenticationMethodsInfo from "./AuthenticationMethodsInfo";
import DocumentsInfo from "./DocumentsInfo";
import AddressInfo from "./AddressInfo";
import EmergencyContactInfo from "./EmergencyContactInfo";
import ConfidantPersonsPatientMergeRequests from "./ConfidantPersonsPatientMergeRequests";
import DeclarationsInfo from "./DeclarationsInfo";
import { DictionaryAllValuesJson } from "../../../components/DictionaryValue";

const UpdateMergeRequestMutation = loader(
  "../../../graphql/UpdateMergeRequestMutation.graphql"
);

export const AUTH_METHODS_TYPES = {
  THIRD_PERSON: "THIRD_PERSON",
  OTP: "OTP",
  OFFLINE: "OFFLINE",
  NA: "NA"
};
const AUTH_METHODS_FILTER_DEFAULTS = {
  isEnded: false,
  type: [...Object.values(AUTH_METHODS_TYPES)]
};
const AUTH_METHODS_PAGINATION_DEFAULTS = 50;

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id, navigate }: DetailsProps) => (
  <Query
    query={MergeRequestQuery}
    variables={{
      id,
      filter: AUTH_METHODS_FILTER_DEFAULTS,
      first: AUTH_METHODS_PAGINATION_DEFAULTS
    }}
  >
    {({ loading, data }: QueryResult<{ mergeRequest: MergeRequest }>) => {
      if (
        isEmpty(data) ||
        isEmpty(data.mergeRequest) ||
        isEmpty(data.mergeRequest.manualMergeCandidate)
      )
        return null;
      const {
        mergeRequest: {
          status,
          manualMergeCandidate: {
            mergeCandidate: {
              databaseId: databaseMergeCandidateId,
              person,
              masterPerson
            }
          }
        }
      } = data;
      const personAuthenticationMethods =
        (person &&
          person.authenticationMethods &&
          person.authenticationMethods.nodes) ||
        [];
      const masterPersonAuthenticationMethods =
        (masterPerson &&
          masterPerson.authenticationMethods &&
          masterPerson.authenticationMethods.nodes) ||
        [];

      const authData = [
        {
          ownAuthMethod: personAuthenticationMethods.filter(
            (item) => item && item.type !== AUTH_METHODS_TYPES.THIRD_PERSON
          ),
          thirdPesonAuthMethods: personAuthenticationMethods.filter(
            (item) => item && item.type === AUTH_METHODS_TYPES.THIRD_PERSON
          )
        },
        {
          ownAuthMethod: masterPersonAuthenticationMethods.filter(
            (item) => item && item.type !== AUTH_METHODS_TYPES.THIRD_PERSON
          ),
          thirdPesonAuthMethods: masterPersonAuthenticationMethods.filter(
            (item) => item && item.type === AUTH_METHODS_TYPES.THIRD_PERSON
          )
        }
      ];

      const mergeRequestData = [person, masterPerson];

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box px={3} py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/applications-patient-merge-requests">
                  <Trans>Patients Merge</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Request details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Box px={3}>
                <DefinitionListView
                  labels={{
                    status: <Trans>Request status</Trans>,
                    pairId: <Trans>Pair ID</Trans>
                  }}
                  data={{
                    pairId: databaseMergeCandidateId,
                    status: (
                      <Badge
                        name={status}
                        type="PATIENT_MERGE_REQUEST"
                        minWidth={100}
                      />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="120px"
                />
              </Box>
            </Flex>

            {person && masterPerson && (
              <>
                <GeneralInfoPatient data={mergeRequestData} />
                <IdentityInfo data={mergeRequestData} />
                <PhonesInfo data={mergeRequestData} />
                <AuthenticationMethodsInfo data={authData} />
                <DocumentsInfo person={person} masterPerson={masterPerson} />
                <AddressInfo
                  personAddresses={person.addresses}
                  masterPersonAddresses={masterPerson.addresses}
                />
                <EmergencyContactInfo
                  data={[
                    person.emergencyContact,
                    masterPerson.emergencyContact
                  ]}
                />
                <ConfidantPersonsPatientMergeRequests
                  person={person.confidantPersons}
                  masterPerson={masterPerson.confidantPersons}
                />
                <DeclarationsInfo data={mergeRequestData} />

                <Flex my={5} justifyContent="space-between">
                  <Flex>
                    <Box mr={4}>
                      <Popup
                        variant="light"
                        buttonText={<Trans>Trash</Trans>}
                        title={<Trans>Trash Merge Request</Trans>}
                        status="TRASH"
                        id={id}
                        navigate={navigate}
                      />
                    </Box>

                    <Popup
                      variant="blue"
                      buttonText={<Trans>Postpone</Trans>}
                      title={<Trans>Postpone Merge Request</Trans>}
                      status="POSTPONE"
                      id={id}
                      navigate={navigate}
                      disabled={status === "POSTPONE"}
                    />
                  </Flex>

                  <Flex>
                    <Box mr={4}>
                      <Popup
                        variant="red"
                        buttonText={<Trans>Split</Trans>}
                        title={<Trans>Split Merge Request</Trans>}
                        status="SPLIT"
                        id={id}
                        navigate={navigate}
                      />
                    </Box>
                    <Popup
                      variant="green"
                      buttonText={<Trans>Merge</Trans>}
                      title={<Trans>Merge Merge Request</Trans>}
                      status="MERGE"
                      id={id}
                      navigate={navigate}
                    />
                  </Flex>
                </Flex>
              </>
            )}
          </Box>
        </LoadingOverlay>
      );
    }}
  </Query>
);

const MergeRequestQuery = gql`
  query MergeRequestQuery(
    $id: ID!
    $filter: PersonAuthenticationMethodFilter
    $first: Int
  ) {
    mergeRequest(id: $id) {
      id
      databaseId
      status
      comment
      manualMergeCandidate {
        id
        databaseId
        mergeCandidate {
          id
          databaseId
          person {
            id
            databaseId
            insertedAt
            updatedAt
            status
            firstName
            lastName
            secondName
            birthDate
            birthCountry
            birthSettlement
            gender
            email
            preferredWayCommunication
            ...IdentityInfo
            ...AuthenticationMethodsInfo
            ...DocumentsInfo
            ...AddressInfo
            ...PhonesInfo
            ...EmergencyContactInfo
            ...ConfidantPersonsPatientMergeRequests
          }
          masterPerson {
            id
            databaseId
            insertedAt
            updatedAt
            status
            firstName
            lastName
            secondName
            birthDate
            birthCountry
            birthSettlement
            gender
            email
            preferredWayCommunication
            ...IdentityInfo
            ...AuthenticationMethodsInfo
            ...DocumentsInfo
            ...AddressInfo
            ...PhonesInfo
            ...EmergencyContactInfo
            ...ConfidantPersonsPatientMergeRequests
          }
        }
      }
    }
  }
  ${IdentityInfo.fragments.entry}
  ${PhonesInfo.fragments.entry}
  ${AuthenticationMethodsInfo.fragments.entry}
  ${DocumentsInfo.fragments.entry}
  ${EmergencyContactInfo.fragments.entry}
  ${ConfidantPersonsPatientMergeRequests.fragments.entry}
  ${AddressInfo.fragments.entry}
`;

type DocumentsTableProps = {
  header: DictionaryAllValuesJson;
  person: Person["documents"] | Party["documents"];
  masterPerson: Person["documents"];
};

export const DocumentsTable = ({
  header,
  person,
  masterPerson,
  ...props
}: DocumentsTableProps) => (
  <Table
    header={header}
    data={combineNestedData(person, masterPerson, header)}
    renderRow={(
      { ...documents }: { [key: string]: PersonDocument },
      mismatch: boolean
    ) => {
      const [row] = Object.entries(documents).map(([key, value]) => ({
        [key]: <Document data={value} mismatch={mismatch} />
      }));
      return row;
    }}
    {...props}
    hideEmptyFields
  />
);

type DocumentProps = {
  data: PersonDocument;
  mismatch: boolean;
};

const Document = ({ data, mismatch }: DocumentProps) =>
  data ? (
    <Box pb={2}>
      <DefinitionListView
        labels={{
          number: <Trans>Number</Trans>,
          issuedAt: <Trans>Issued at</Trans>,
          issuedBy: <Trans>Issued by</Trans>
        }}
        data={{
          ...data,
          issuedAt:
            data.issuedAt && isValidDate(data.issuedAt)
              ? i18n.date(data.issuedAt)
              : data.issuedAt
        }}
        labelWidth="100px"
        marginBottom={0}
        color={mismatch && "redPigment"}
      />
    </Box>
  ) : null;

type TableProps = {
  renderRow: (tableData: any, mismatch: boolean) => { [key: string]: any };
  header: HeaderData;
  data: any;
  skipComparison?: boolean;
  hideEmptyFields?: boolean;
};

export const Table = ({
  renderRow,
  header,
  data,
  skipComparison,
  hideEmptyFields
}: TableProps) => {
  const transformedData = (
    header: HeaderData,
    [firstValue, secondValue]: { [key: string]: any }[],
    hideEmptyFields: boolean | undefined
  ) => {
    const person = !isEmpty(firstValue) ? firstValue : {};
    const masterPerson = !isEmpty(secondValue) ? secondValue : {};

    return Object.entries(header).reduce(
      (summary: $TSFixMe, [key, translation]) => {
        const data = { [key]: [translation, person[key], masterPerson[key]] };
        if (hideEmptyFields) {
          if (person[key] || masterPerson[key]) {
            return [...summary, data];
          } else return summary;
        } else return [...summary, data];
      },
      []
    );
  };

  return (
    <TableRoot fontSize={1}>
      <TableBodyComponent>
        {transformedData(header, data, hideEmptyFields).map(
          (item: $TSFixMe, index: number) => (
            <TableRow key={`row-${index}`}>
              {Object.entries(item).map(
                ([key, values]: [key: string, values: any]) =>
                  values.map((value: any, index: number) => {
                    const mismatch =
                      !skipComparison && !isEqual(values[1], values[2]);
                    const row = renderRow({ [key]: value }, mismatch);
                    return (
                      <TableCell
                        key={`${key}-${index}`}
                        mismatch={mismatch}
                        variant="horizontal"
                        wordBreak
                      >
                        {index ? row[key] : value}
                      </TableCell>
                    );
                  })
              )}
            </TableRow>
          )
        )}
      </TableBodyComponent>
    </TableRoot>
  );
};

type PopupProps = {
  variant: "light" | "green" | "blue" | "red";
  buttonText: React.ReactNode;
  title: React.ReactNode;
  status: "TRASH" | "POSTPONE" | "SPLIT" | "MERGE";
  disabled?: boolean;
  id?: string;
  navigate?: NavigateFn;
};

const Popup = ({
  id,
  variant,
  buttonText,
  title,
  status,
  disabled,
  navigate
}: PopupProps) => {
  return (
    <BooleanValue>
      {({ value: opened, toggle }: $TSFixMe) => (
        <>
          <Button
            width={120}
            variant={variant}
            disabled={disabled}
            onClick={toggle}
          >
            {buttonText}
          </Button>
          {opened && (
            <Modal width={760} backdrop>
              <Title as="h1" fontWeight="normal" mb={6}>
                {title}
              </Title>
              <Mutation
                mutation={UpdateMergeRequestMutation}
                refetchQueries={() => [
                  {
                    query: MergeRequestQuery,
                    variables: {
                      id,
                      first: ITEMS_PER_PAGE[0]
                    }
                  }
                ]}
              >
                {(updateMergeRequest: MutationFunction) => (
                  <Form
                    onSubmit={async ({ comment }: { comment: string }) => {
                      await updateMergeRequest({
                        variables: { input: { id, status, comment } }
                      });
                      navigate!("/applications-patient-merge-requests/search");
                    }}
                  >
                    <Trans
                      id="Enter comment"
                      render={({ translation }) => (
                        <Field.Textarea
                          name="comment"
                          placeholder={translation}
                          rows={5}
                          maxLength={3000}
                          showLengthHint
                        />
                      )}
                    />
                    <Flex justifyContent="left">
                      <Box mr={20}>
                        <Button type="reset" variant="light" onClick={toggle}>
                          <Trans>Return</Trans>
                        </Button>
                      </Box>
                      <Button type="submit" width={120} variant="blue">
                        {buttonText}
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Mutation>
            </Modal>
          )}
        </>
      )}
    </BooleanValue>
  );
};

const convertTypeToKey = (data: any = [], types: string[]) =>
  types.reduce(
    (summary, item) => ({
      ...summary,
      [item]:
        data && data.find((d: Maybe<PersonDocument>) => d && d.type === item)
    }),
    {}
  );

export const combineNestedData = (
  person: Person["documents"] | Person["addresses"] | Party["documents"],
  masterPerson: Person["documents"] | Person["addresses"],
  header: DictionaryAllValuesJson
) => {
  const types = Object.keys(header);
  return [
    convertTypeToKey(person, types),
    convertTypeToKey(masterPerson, types)
  ];
};

export const Heading = system(
  {
    is: Text,
    fontSize: 2,
    fontWeight: "normal",
    mt: 4,
    mb: 4
  },
  "fontSize",
  "fontWeight",
  "space"
);

export default Details;
