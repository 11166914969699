import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { DeviceRegistryJobConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import DeviceRegistryJobsTable from "./DeviceRegistryJobsTable";
import { PrimarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Ability action="read" resource="device_registry_job">
    <Box p={6}>
      <Heading as="h1" fontWeight="normal" mb={6}>
        <Trans>Device registry jobs</Trans>
      </Heading>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
            />
            <Query
              query={DeviceRegistryJobsQuery}
              fetchPolicy="network-only"
              variables={filteredLocationParams(locationParams)}
            >
              {({
                loading,
                data
              }: QueryResult<{
                deviceRegistryJobs: DeviceRegistryJobConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.deviceRegistryJobs))
                  return null;
                const {
                  deviceRegistryJobs: {
                    nodes: deviceRegistryJobs = [],
                    pageInfo
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <DeviceRegistryJobsTable
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      deviceRegistryJobs={deviceRegistryJobs}
                    />
                    <Pagination pageInfo={pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        )}
      </LocationParams>
    </Box>
  </Ability>
);

const DeviceRegistryJobsQuery = gql`
  query DeviceRegistryJobsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: DeviceRegistryJobFilter!
    $orderBy: DeviceRegistryJobOrderBy
  ) {
    deviceRegistryJobs(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...DeviceRegistryJobs
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${DeviceRegistryJobsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
