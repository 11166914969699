import React from "react";
import { NavigateFn } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Scalars } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

type DeactivateProps = {
  id: Scalars["ID"]["output"];
  navigate: NavigateFn;
};

const Deactivate = ({ id, navigate }: DeactivateProps) => {
  const [isVisible, setVisibilityState] = React.useState(false);
  const toggle = () => setVisibilityState(!isVisible);

  return (
    <Mutation mutation={DeactivateLegalEntityMutation}>
      {(deactivateLegalEntity: MutationFunction) => (
        <>
          <Button variant="red" onClick={toggle}>
            <Trans>Close legal entity</Trans>
          </Button>
          <Popup
            visible={isVisible}
            okButtonProps={{ type: "submit", variant: "red" }}
            onCancel={toggle}
            title={<Trans>Close legal entity</Trans>}
            onOk={async () => {
              await deactivateLegalEntity({
                variables: {
                  input: {
                    id
                  }
                }
              });
              await navigate("/legal-entity-deactivate-jobs/search");
            }}
          />
        </>
      )}
    </Mutation>
  );
};

const DeactivateLegalEntityMutation = gql`
  mutation DeactivateLegalEntityMutation($input: DeactivateLegalEntityInput!) {
    deactivateLegalEntity(input: $input) {
      legalEntityDeactivationJob {
        id
      }
    }
  }
`;

export default Deactivate;
