import React from "react";
import styled from "@emotion/styled";

import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";

type RemoveButtonProps = {
  onClick: () => void;
};

export const RemoveButton = ({ onClick }: RemoveButtonProps) => (
  <IconHolder onClick={onClick}>
    <RemoveItemIcon />
  </IconHolder>
);

const IconHolder = styled.div`
  padding: 10px;
  align-self: center;
  font-size: 0;
  cursor: pointer;

  &:hover {
    color: #f00;
  }
`;
