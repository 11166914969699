import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  PersonMergeHistoryRecord,
  PersonMergeHistoryRecordConnection
} from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Table, { SortingParams } from "../../../components/Table";
import EmptyData from "../../../components/EmptyData";
import { TLocationParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import pagination from "../../../helpers/pagination";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type PersonMergeHistoryProps = RouteComponentProps<{
  id: string;
  personDatabaseId: string;
}>;

const PersonMergeHistory = ({
  id: personId,
  personDatabaseId
}: PersonMergeHistoryProps) => {
  const { i18n } = useLingui();

  return (
    <Ability action="read" resource="person">
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const variables = {
            filter: {
              personId
            },
            ...pagination(locationParams)
          };
          return (
            <Query
              query={PersonMergeHistoryQuery}
              fetchPolicy="network-only"
              variables={variables}
            >
              {({
                data,
                loading
              }: QueryResult<{
                personMergeHistoryRecords: PersonMergeHistoryRecordConnection;
              }>) => {
                if (
                  isEmpty(data) ||
                  isEmpty(data.personMergeHistoryRecords.nodes)
                )
                  return <EmptyData />;

                const { pageInfo } = data.personMergeHistoryRecords;
                return (
                  <LoadingOverlay loading={loading}>
                    <Box p={6}>
                      <Text mb={1}>
                        <Trans>Merge history</Trans>
                      </Text>
                      <Table
                        data={data.personMergeHistoryRecords.nodes}
                        header={{
                          databaseId: <Trans>ID</Trans>,
                          masterPersonDatabaseId: <Trans>Master person</Trans>,
                          mergePersonDatabaseId: <Trans>Merge person</Trans>,
                          insertedAt: <Trans>Inserted at</Trans>
                        }}
                        renderRow={({
                          databaseId,
                          insertedAt,
                          masterPersonId,
                          mergePersonId,
                          masterPersonDatabaseId,
                          mergePersonDatabaseId
                        }: PersonMergeHistoryRecord) => ({
                          databaseId,
                          masterPersonDatabaseId:
                            masterPersonDatabaseId === personDatabaseId ? (
                              <Text>{masterPersonDatabaseId}</Text>
                            ) : (
                              <Link to={`../../${masterPersonId}`}>
                                {masterPersonDatabaseId}
                              </Link>
                            ),
                          mergePersonDatabaseId:
                            mergePersonDatabaseId === personDatabaseId ? (
                              <Text>{mergePersonDatabaseId}</Text>
                            ) : (
                              <Link to={`../../${mergePersonId}`}>
                                {mergePersonDatabaseId}
                              </Link>
                            ),
                          insertedAt: dateFormatter(
                            i18n.locale,
                            DATE_TIME_FORMAT,
                            insertedAt
                          )
                        })}
                        sortableFields={["insertedAt"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        tableName="personMergeHistory/search"
                      />
                      <Pagination pageInfo={pageInfo} />
                    </Box>
                  </LoadingOverlay>
                );
              }}
            </Query>
          );
        }}
      </LocationParams>
    </Ability>
  );
};

export default PersonMergeHistory;

const PersonMergeHistoryQuery = gql`
  query PersonMergeHistoryQuery(
    $filter: PersonMergeHistoryRecordFilter!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: PersonMergeHistoryRecordOrderBy
  ) {
    personMergeHistoryRecords(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      nodes {
        id
        databaseId
        insertedAt
        updatedAt
        masterPersonId
        masterPersonDatabaseId
        mergePersonId
        mergePersonDatabaseId
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;
