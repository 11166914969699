import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import {
  PositiveIcon,
  SearchIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import {
  GlobalBudgetContract,
  MedicalProgram
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Table, { SortingParams } from "../../../components/Table";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import { GlobalBudgetContractQuery } from "./Details";

type MedicalProgramsProps = RouteComponentProps<{
  id: string;
}>;

const MedicalPrograms = ({ id }: MedicalProgramsProps) => {
  return (
    <Box>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const { first, last, after, before, name } = locationParams;
          return (
            <>
              <Form initialValues={locationParams} onSubmit={setLocationParams}>
                <Box px={5} pt={5} width={460}>
                  <Trans
                    id="Enter program name"
                    render={({ translation }) => (
                      <Field.Text
                        name="name"
                        label={<Trans id="Find program" />}
                        placeholder={translation}
                        postfix={<SearchIcon color="silverCity" />}
                      />
                    )}
                  />
                </Box>
              </Form>
              <Query
                query={GlobalBudgetContractQuery}
                variables={{
                  id,
                  ...pagination({ first, last, after, before }),
                  medicalProgramFilter: { name }
                }}
              >
                {({
                  loading,
                  data
                }: QueryResult<{
                  globalBudgetContract: GlobalBudgetContract;
                }>) => {
                  if (
                    isEmpty(data) ||
                    isEmpty(data.globalBudgetContract) ||
                    isEmpty(data.globalBudgetContract.medicalPrograms)
                  )
                    return null;
                  const {
                    globalBudgetContract: {
                      medicalPrograms: { nodes: medicalPrograms = [], pageInfo }
                    }
                  } = data;

                  return (
                    <LoadingOverlay loading={loading}>
                      {medicalPrograms!.length > 0 ? (
                        <>
                          <Table
                            data={medicalPrograms}
                            header={{
                              databaseId: <Trans>ID</Trans>,
                              name: <Trans>Medical program name</Trans>,
                              isActive: <Trans>Status</Trans>,
                              type: <Trans>Type</Trans>,
                              medicationRequestAllowed: (
                                <Trans>Medication request allowed</Trans>
                              ),
                              medicationDispenseAllowed: (
                                <Trans>Medication dispense allowed</Trans>
                              ),
                              action: <Trans>Action</Trans>
                            }}
                            renderRow={({
                              id,
                              isActive,
                              name,
                              type,
                              medicationRequestAllowed,
                              medicationDispenseAllowed,
                              ...medicalProgram
                            }: MedicalProgram) => ({
                              ...medicalProgram,
                              name,
                              isActive: (
                                <Badge
                                  type="ACTIVE_STATUS_F"
                                  name={isActive}
                                  variant={!isActive}
                                  display="block"
                                />
                              ),
                              type: (
                                <DictionaryValue
                                  name="MEDICAL_PROGRAM_TYPE"
                                  item={type}
                                />
                              ),
                              medicationRequestAllowed: (
                                <Flex justifyContent="center">
                                  {medicationRequestAllowed ? (
                                    <PositiveIcon />
                                  ) : (
                                    <NegativeIcon />
                                  )}
                                </Flex>
                              ),
                              medicationDispenseAllowed: (
                                <Flex justifyContent="center">
                                  {medicationDispenseAllowed ? (
                                    <PositiveIcon />
                                  ) : (
                                    <NegativeIcon />
                                  )}
                                </Flex>
                              ),
                              action: (
                                <Link
                                  to={`../../../../medical-programs/${id}`}
                                  fontWeight="bold"
                                >
                                  <Trans>Details</Trans>
                                </Link>
                              )
                            })}
                            sortableFields={["name", "insertedAt"]}
                            sortingParams={parseSortingParams(
                              locationParams.orderBy
                            )}
                            onSortingChange={(sortingParams: SortingParams) =>
                              setLocationParams({
                                ...locationParams,
                                orderBy: stringifySortingParams(sortingParams)
                              })
                            }
                            whiteSpaceNoWrap={["databaseId"]}
                            tableName="medicalPrograms/search"
                            hiddenFields="medicationDispenseAllowed,medicationRequestAllowed"
                          />
                          <Pagination pageInfo={pageInfo} />
                        </>
                      ) : (
                        <EmptyData />
                      )}
                    </LoadingOverlay>
                  );
                }}
              </Query>
            </>
          );
        }}
      </LocationParams>
    </Box>
  );
};

export default MedicalPrograms;

MedicalPrograms.fragments = {
  entry: gql`
    fragment MedicalProgram on GlobalBudgetContract {
      medicalPrograms(
        first: $first
        before: $before
        after: $after
        last: $last
        filter: $medicalProgramFilter
      ) {
        nodes {
          ... on MedicalProgram {
            id
            databaseId
            name
            isActive
            medicationDispenseAllowed
            medicationDispenseAllowedText
            medicationRequestAllowed
            medicationRequestAllowedText
            type
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `
};
