import React, { useMemo } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client/react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import styled from "@emotion/styled";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { EditIcon } from "@edenlabllc/ehealth-icons";
import { Person, Scalars } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import OnRouteChange from "../../../components/OnRouteChange";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import dateFormatter from "../../../helpers/dateFormatter";
import filteredLocationParams from "../../../helpers/filteredLocationParams";
import { useAbility } from "../../../helpers/useAbility";
import { AUTH_METHODS_FILTER_DEFAULTS } from "../../../constants/filterValues";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import AuthInfo, { clearFilterParamsPersonQuery } from "./AuthMethods";
import UserInfo from "./UserInfo";
import VerificationDetails from "./VerificationDetails";
import PersonDataHistory from "./PersonDataHistory";
import PersonMergeHistory from "./PersonMergeHistory";
import DeclarationsInfo from "./Declarations";
import EmergencyContact from "./EmergencyContact";
import PersonConfidantPersons from "./PersonConfidantPersons";
import PersonDocuments from "./PersonDocuments";

const PRACTICAL_MONITOR_SCOPE_PATTERN = new RegExp(/:practical_monitor$/);

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"]["output"];
}>;

const Details = ({ id, navigate, location }: DetailsProps) => {
  const [deactivatePersonAbility, scopes] = useAbility("deactivate", "person");
  const [editPersonAbility] = useAbility("write_nhs", "person_request");

  const hasAtLeastOnePracticalMonitorScope = useMemo(
    () =>
      scopes.some((scope: string) =>
        PRACTICAL_MONITOR_SCOPE_PATTERN.test(scope)
      ),
    [scopes, PRACTICAL_MONITOR_SCOPE_PATTERN]
  );

  const { state }: { state: any } = location!;

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => {
        const searchVariables = locationParams.filter
          ? filteredLocationParams(locationParams)
          : {
              ...filteredLocationParams(locationParams),
              ...AUTH_METHODS_FILTER_DEFAULTS
            };

        const variables = {
          id,
          ...searchVariables,
          filter: clearFilterParamsPersonQuery(searchVariables.filter)
        };

        return (
          <Query
            query={PersonQuery}
            fetchPolicy="network-only"
            variables={variables}
          >
            {({ loading, data }: QueryResult<{ person: Person }>) => {
              const {
                databaseId,
                status,
                verificationStatus,
                emergencyContact,
                authenticationMethods,
                confidantPersons,
                insertedAt,
                updatedAt
              } = (data && data.person) || {};

              return (
                <LoadingOverlay loading={loading}>
                  <Box p={6}>
                    <Box mb={10}>
                      <Breadcrumbs.List>
                        {state && state.fromVerificationStatusPage ? (
                          <Breadcrumbs.Item to="/persons-verifications">
                            <Trans>Verification of patients</Trans>
                          </Breadcrumbs.Item>
                        ) : (
                          <Breadcrumbs.Item to="/persons">
                            <Trans>Patient Search</Trans>
                          </Breadcrumbs.Item>
                        )}
                        <Breadcrumbs.Item>
                          <Trans>Details of the patient</Trans>
                        </Breadcrumbs.Item>
                      </Breadcrumbs.List>
                    </Box>
                    <Flex>
                      <Box width={1 / 3}>
                        <DefinitionListView
                          labels={{
                            databaseId: <Trans>Patient ID</Trans>,
                            status: <Trans>Status</Trans>,
                            verificationStatus: (
                              <Trans>Verification status</Trans>
                            ),
                            insertedAt: <Trans>Inserted at</Trans>,
                            updatedAt: <Trans>Updated at</Trans>
                          }}
                          data={{
                            databaseId,
                            status: (
                              <Badge
                                name={status}
                                type="ACTIVE_INACTIVE_M"
                                minWidth={190}
                                maxWidth={190}
                              />
                            ),
                            verificationStatus: (
                              <Badge
                                type="STATUS_VERIFICATION"
                                name={verificationStatus}
                                minWidth={190}
                                maxWidth={190}
                              />
                            ),
                            insertedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              insertedAt
                            ),
                            updatedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              updatedAt
                            )
                          }}
                          color="#7F8FA4"
                          labelWidth="160px"
                        />
                      </Box>
                      <Box width={2 / 3}>
                        <Flex flexDirection="column" alignItems="flex-end">
                          {editPersonAbility && (
                            <Box mb={3}>
                              <Flex
                                alignItems="flex-end"
                                flexDirection="column"
                                justifyContent="flex-start"
                              >
                                {status === "ACTIVE" && (
                                  <Button
                                    alignItems="start"
                                    variant="none"
                                    border="none"
                                    px="0"
                                    py="0"
                                    onClick={() => {
                                      // @ts-ignore
                                      navigate("./edit-person", {
                                        state: {},
                                        id: id
                                      });
                                    }}
                                  >
                                    <Flex alignItems="center">
                                      <EditIcon width="16" height="14" />

                                      <Description>
                                        <Trans>Edit</Trans>
                                      </Description>
                                    </Flex>
                                  </Button>
                                )}
                              </Flex>
                            </Box>
                          )}
                          {deactivatePersonAbility && status === "ACTIVE" && (
                            <Box mt={3} mb={3}>
                              <Button
                                width={220}
                                variant="red"
                                onClick={() => navigate!("./deactivate-person")}
                              >
                                <Trans>Deactivate</Trans>
                              </Button>
                            </Box>
                          )}
                          {hasAtLeastOnePracticalMonitorScope && (
                            <Box>
                              <Button
                                width={220}
                                variant="blue"
                                onClick={() =>
                                  navigate!("./practical-monitoring")
                                }
                              >
                                <Trans>Practical monitoring</Trans>
                              </Button>
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                  <Tabs.Nav>
                    <Tabs.NavItem to="./">
                      <Trans>Personal information</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./verification">
                      <Trans>Verification</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./auth">
                      <Trans>Authentication method</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./declarations">
                      <Trans>Declarations</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./emergency-contact">
                      <Trans>Emergency Contact</Trans>
                    </Tabs.NavItem>
                    <Tabs.NavItem to="./confidant-persons">
                      <Trans>Confidant Persons</Trans>
                    </Tabs.NavItem>
                    <Ability action="read" resource="person_documents">
                      <Tabs.NavItem to="./person-documents">
                        <Trans>Documents</Trans>
                      </Tabs.NavItem>
                    </Ability>
                    <Ability action="read" resource="person_history">
                      <Tabs.NavItem to="./history">
                        <Trans>History</Trans>
                      </Tabs.NavItem>
                    </Ability>
                    <Ability action="read" resource="person">
                      <Tabs.NavItem to="./merge-history">
                        <Trans>Merge history</Trans>
                      </Tabs.NavItem>
                    </Ability>
                  </Tabs.Nav>
                  <Tabs.Content>
                    <Router>
                      <UserInfo path="/" data={data && data.person} />
                      <VerificationDetails
                        path="verification"
                        data={
                          data && data.person && data.person.verificationDetails
                        }
                        id={id!}
                        navigate={navigate}
                      />
                      <AuthInfo
                        path="auth"
                        databaseId={!isEmpty(data) && databaseId}
                        authInfo={
                          authenticationMethods
                            ? authenticationMethods.nodes
                            : []
                        }
                        status={!isEmpty(data) ? status : undefined}
                        personId={id}
                      />
                      <DeclarationsInfo path="declarations" />
                      <EmergencyContact
                        data={!isEmpty(data) ? emergencyContact : undefined}
                        path="emergency-contact"
                      />
                      <PersonConfidantPersons
                        data={!isEmpty(data) ? confidantPersons : undefined}
                        path="confidant-persons"
                      />
                      <PersonDocuments path="person-documents" />
                      <PersonDataHistory
                        path="history"
                        authInfo={
                          authenticationMethods
                            ? authenticationMethods.nodes
                            : undefined
                        }
                      />
                      <PersonMergeHistory
                        path="merge-history"
                        personDatabaseId={databaseId}
                      />
                    </Router>
                    <OnRouteChange />
                  </Tabs.Content>
                </LoadingOverlay>
              );
            }}
          </Query>
        );
      }}
    </LocationParams>
  );
};

export default Details;

export const getDataByType = (type: string, arr: any, key = "type") => {
  return arr ? arr.filter((t: any) => t[key] === type) : "";
};

export const PersonQuery = gql`
  query PersonQuery(
    $id: ID!
    $filter: PersonAuthenticationMethodFilter
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    person(id: $id) {
      ...UserInfo
      ...AuthMethods
      ...PersonConfidantPersons
      ...EmergencyContact
      ...VerificationDetails
    }
  }
  ${AuthInfo.fragments.entry}
  ${VerificationDetails.fragments.entry}
  ${PersonConfidantPersons.fragments.entry}
  ${EmergencyContact.fragments!.entry}
  ${UserInfo.fragments.entry}
`;

const Description = styled.span`
  margin-left: 10px;
  color: #2ea2f8;
  font-size: 12px;
  font-weight: 700;
`;

export const handleCalculateVerificationStatus = (
  verificationStatus?: string,
  verificationReason?: string
) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED" &&
      verificationReason === "RULES_TRIGGERED":
      return "VERIFICATION_MANUAL_NEEDED";

    case verificationStatus === "VERIFICATION_NEEDED" &&
      (verificationReason === "RULES_PASSED" ||
        verificationReason === "INITIAL"):
      return "VERIFICATION_AUTO_NEEDED";

    case verificationStatus === "VERIFICATION_NEEDED" &&
      verificationReason !== "RULES_PASSED" &&
      verificationReason !== "RULES_TRIGGERED":
      return "VERIFICATION_NEEDED";

    default:
      return verificationStatus;
  }
};

export const dracsVerificationStatus = (
  verificationStatus: string,
  verificationReason: string
) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED" &&
      (verificationReason === "MANUAL_CONFIRMED" ||
        verificationReason === "MANUAL_NOT_CONFIRMED"):
      return "VERIFICATION_MANUAL_NEEDED";

    case verificationStatus === "VERIFICATION_NEEDED":
      return "VERIFICATION_AUTO_NEEDED";

    default:
      return verificationStatus;
  }
};

export const handleCalculateDracsVerificationStatus = (
  verificationStatus: string,
  verificationReason: string
) => {
  switch (true) {
    case (verificationStatus === "VERIFICATION_NEEDED" &&
      (verificationReason === "MANUAL_CONFIRMED" ||
        verificationReason === "MANUAL_NOT_CONFIRMED")) ||
      (verificationStatus === "NOT_VERIFIED" &&
        (verificationReason === "AUTO_OFFLINE" ||
          verificationReason === "AUTO_ONLINE")):
      return "VERIFICATION_MANUAL_NEEDED";

    default:
      return verificationStatus;
  }
};

export const drfoVerificationStatus = (verificationStatus: string) => {
  switch (true) {
    case verificationStatus === "VERIFICATION_NEEDED":
      return "VERIFICATION_AUTO_NEEDED";

    default:
      return verificationStatus;
  }
};
