import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Switch } from "@edenlabllc/ehealth-components";
import {
  Repeat as TRepeat,
  Timing as TTiming
} from "@edenlabllc/graphql-schema";

import CodeableConcept from "../../../components/CodeableConcept";
import DefinitionListView from "../../../components/DefinitionListView";

import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import ValueDateTime from "./ValueDateTime";
import ValueString from "./ValueString";
import Period from "./Period";
import { LABEL_WIDTH } from "./Episode";
import { SimpleRange } from "./SimpleRange";
import { SimpleQuantity } from "./SimpleQuantity";

export const Timing = ({ timing }: { timing: TTiming }) => {
  if (isEmpty(timing)) return null;

  return (
    <>
      <DefinitionListView
        fontSize={14}
        labelWidth={LABEL_WIDTH}
        labels={{
          ...(!isEmpty(timing.code) && {
            code: <Trans id="By the pattern of time">Code</Trans>
          }),
          ...(!isEmpty(timing.event) && {
            event: <Trans>Event</Trans>
          })
        }}
        data={{
          ...(!isEmpty(timing.code) && {
            code: <CodeableConcept codeableConcept={timing.code} />
          }),
          ...(!isEmpty(timing.event) && {
            event:
              timing.event &&
              timing.event.map((event, i) => (
                <ValueDateTime
                  key={i}
                  valueDateTime={event}
                  dateTimeFormat={DATE_TIME_FORMAT}
                />
              ))
          })
        }}
      />

      {!isEmpty(timing.repeat) && (
        <>
          <Box mb={4}>
            <Text color="darkCharcoal" fontSize={14} fontWeight="bold">
              <Trans>Repeat</Trans>
            </Text>
          </Box>
          <Repeat repeat={timing.repeat} />
        </>
      )}
    </>
  );
};

const Bounds = ({ bounds }: { bounds: any }) => {
  if (isEmpty(bounds)) return null;
  const { __typename } = bounds;

  return (
    <Switch
      value={__typename}
      Period={<Period datePeriod={bounds} wrapperProps={{ mt: 0 }} />}
      SimpleQuantity={<SimpleQuantity simpleQuantity={bounds} />}
      SimpleRange={<SimpleRange simpleRange={bounds} />}
    />
  );
};

Bounds.fragments = {
  entry: gql`
    fragment Bounds on Bounds {
      ... on SimpleQuantity {
        ...SimpleQuantity
      }
      ... on Period {
        ...Period
      }
      ... on SimpleRange {
        ...SimpleRange
      }
    }
    ${SimpleQuantity.fragments.entry}
    ${Period.fragments!.entry}
    ${SimpleRange.fragments.entry}
  `
};

const Repeat = ({ repeat }: { repeat: TRepeat }) => {
  if (isEmpty(repeat)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        ...(!isEmpty(repeat.bounds) && { bounds: <Trans>Bounds</Trans> }),
        ...(repeat.count && { count: <Trans>Count</Trans> }),
        ...(repeat.countMax && { countMax: <Trans>Count max</Trans> }),
        ...(repeat.duration && { duration: <Trans>duration</Trans> }),
        ...(repeat.durationMax && { durationMax: <Trans>Duration max</Trans> }),
        ...(repeat.durationUnit && {
          durationUnit: <Trans>Duration unit</Trans>
        }),
        ...(repeat.frequency && {
          frequency: <Trans>Frequency</Trans>
        }),
        ...(repeat.frequencyMax && {
          frequencyMax: <Trans>Frequency max</Trans>
        }),
        ...(repeat.period && {
          period: <Trans id="Frequency period">Period</Trans>
        }),
        ...(repeat.periodMax && {
          periodMax: <Trans>Period max</Trans>
        }),
        ...(repeat.periodUnit && {
          periodUnit: <Trans>Period unit</Trans>
        }),
        ...(!isEmpty(repeat.dayOfWeek) && {
          dayOfWeek: <Trans>Day of week</Trans>
        }),
        ...(!isEmpty(repeat.timeOfDay) && {
          timeOfDay: <Trans>Time of day</Trans>
        }),
        ...(!isEmpty(repeat.when) && {
          when: <Trans>When</Trans>
        }),
        ...(repeat.offset && { offset: <Trans>Offset</Trans> })
      }}
      data={{
        ...repeat,
        ...(!isEmpty(repeat.dayOfWeek) && {
          dayOfWeek: repeat!.dayOfWeek!.map((day, index) => (
            <ValueString valueString={day} key={index} />
          ))
        }),
        ...(!isEmpty(repeat.timeOfDay) && {
          timeOfDay: repeat!.timeOfDay!.map((time, index) => (
            <ValueString valueString={time} key={index} />
          ))
        }),
        ...(!isEmpty(repeat.when) && {
          when: repeat!.timeOfDay!.map((item, index) => (
            <ValueString valueString={item} key={index} />
          ))
        }),
        ...(!isEmpty(repeat.bounds) && {
          bounds: <Bounds bounds={repeat.bounds} />
        })
      }}
    />
  );
};

Repeat.fragments = {
  entry: gql`
    fragment Repeat on Repeat {
      bounds {
        ...Bounds
      }
      count
      countMax
      duration
      durationMax
      durationUnit
      frequency
      frequencyMax
      period
      periodMax
      periodUnit
      dayOfWeek
      timeOfDay
      when
      offset
    }
    ${Bounds.fragments.entry}
  `
};

Timing.fragments = {
  entry: gql`
    fragment Timing on Timing {
      code {
        ...CodeableConcept
      }
      event
      repeat {
        ...Repeat
      }
    }
    ${CodeableConcept.fragments!.entry}
    ${Repeat.fragments.entry}
  `
};
