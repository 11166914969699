import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box, Text, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import { AllergyIntoleranceConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { MonitoringTabProps } from "../../../helpers/types";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

const AllergyIntolerancesTab = ({
  id,
  query = AllergyIntolerancesQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="allergy_intolerance"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: AllergyIntoleranceConnection }>) => {
            const allergyIntolerances = isClinicalMonitoring
              ? data.impersonalAllergyIntolerances
              : data.allergyIntolerances;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {isEmpty(allergyIntolerances) ||
                isEmpty(allergyIntolerances.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>:{" "}
                        {allergyIntolerances.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={allergyIntolerances.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusSystem="eHealth/allergy_intolerance_verification_statuses"
                      tableName="allergyIntolerances"
                      customHeaders={{
                        status: <Trans>Сertainty status</Trans>
                      }}
                      pathSlug={
                        isClinicalMonitoring
                          ? `allergy-intolerance/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "allergy-intolerance"
                      }
                    />
                    <Pagination pageInfo={allergyIntolerances.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default AllergyIntolerancesTab;

const AllergyIntolerancesQuery = gql`
  query AllergyIntolerancesQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: AllergyIntoleranceFilter!
    $orderBy: AllergyIntoleranceOrderBy
  ) {
    allergyIntolerances(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status: verificationStatus
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    allergyIntoleranceOnsetDate,
    encounterId,
    allergyIntoleranceId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(encounterId && {
        encounterId: paramToBase64("Encounter", encounterId)
      }),
      ...(allergyIntoleranceOnsetDate && {
        onsetDateTime: `${allergyIntoleranceOnsetDate.from}/${allergyIntoleranceOnsetDate.to}`
      }),
      ...(allergyIntoleranceId && {
        id: paramToBase64("AllergyIntolerance", allergyIntoleranceId)
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => (
  <Flex flexDirection="column">
    <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
    <Flex>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter code"
          render={({ translation }) => (
            <Field.Text
              name="filter.code"
              label={<Trans id="Code" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter encounter ID "
          render={({ translation }) => (
            <Field.Text
              name="filter.encounterId"
              label={<Trans id="Encounter ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.encounterId"
          options={UUID_PATTERN}
          message="Invalid encounter ID "
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter allergy intolerance onset date"
          render={({ translation }) => (
            <Field.RangePicker
              rangeNames={[
                "filter.allergyIntoleranceOnsetDate.from",
                "filter.allergyIntoleranceOnsetDate.to"
              ]}
              label={<Trans id="Allergy intolerance onset date" />}
            />
          )}
        />
        <Form.Spy>
          {({ values = {} }: $TSFixMe) => {
            if (
              (values.filter &&
                values.filter.allergyIntoleranceOnsetDate &&
                values.filter.allergyIntoleranceOnsetDate.from) ||
              (values.filter &&
                values.filter.allergyIntoleranceOnsetDate &&
                values.filter.allergyIntoleranceOnsetDate.to)
            ) {
              return (
                <>
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.allergyIntoleranceOnsetDate.from"
                        message={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.allergyIntoleranceOnsetDate.to"
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }
            return null;
          }}
        </Form.Spy>
      </Box>
    </Flex>
    {isClinicalMonitoring && (
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter allergy intolerance ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.allergyIntoleranceId"
                label={<Trans id="Allergy intolerance ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.allergyIntoleranceId"
            options={UUID_PATTERN}
            message="Invalid allergy intolerance ID"
          />
        </Box>
      </Flex>
    )}
  </Flex>
);
