import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Form } from "@edenlabllc/ehealth-components";
import { Scalars, ProgramService } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";

import { ActionType } from "../../../../helpers/types";

type UpdateProgramServiceRequestAllowanceProps = {
  id: Scalars["ID"]["input"];
  requestAllowed: ProgramService["requestAllowed"];
};

const UpdateProgramServiceRequestAllowance = ({
  id,
  requestAllowed
}: UpdateProgramServiceRequestAllowanceProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  const action: ActionType = requestAllowed
    ? {
        name: <Trans>Disallow Request</Trans>,
        description: <Trans>Disallow Request for program service</Trans>,
        variant: "orange"
      }
    : {
        name: <Trans>Allow Request</Trans>,
        description: <Trans>Allow Request for program service</Trans>,
        variant: "green"
      };

  return (
    <Mutation mutation={UpdateProgramServiceMutation}>
      {(updateProgramService: MutationFunction) => (
        <>
          <Button onClick={toggle} variant={action.variant}>
            {action.name}
          </Button>
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={<>{action.description}?</>}
            okText={action.name}
            formId="updateRequestAllowance"
          >
            <Form
              id="updateRequestAllowance"
              errorPosition="center"
              onSubmit={async () => {
                await updateProgramService({
                  variables: {
                    input: {
                      id,
                      requestAllowed: !requestAllowed
                    }
                  }
                });
                toggle();
              }}
            />
          </Popup>
        </>
      )}
    </Mutation>
  );
};

export const UpdateProgramServiceMutation = gql`
  mutation UpdateProgramServiceMutation($input: UpdateProgramServiceInput!) {
    updateProgramService(input: $input) {
      programService {
        id
        description
        requestAllowed
      }
    }
  }
`;

export default UpdateProgramServiceRequestAllowance;
