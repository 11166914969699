import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Router } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { CapitationContractRequest } from "@edenlabllc/graphql-schema";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Tabs from "../../../../components/Tabs";

import Update from "./Mutations/Update";
import Approve from "./Mutations/Approve";
import Decline from "./Mutations/Decline";
import PrintOutContent from "./Mutations/PrintOutContent";
import HeaderCapitation from "./HeaderCapitation";
import GeneralInfoCapitation from "./GeneralInfoCapitation";
import LegalEntityCapitation from "./LegalEntityCapitation";
import EmployeesCapitation from "./EmployeesCapitation";
import Divisions from "./Divisions";
import DocumentsCapitation from "./DocumentsCapitation";
import ExternalContractors from "./ExternalContractors";

const CapitationContractRequestsDetails = (_props: RouteComponentProps) => (
  <Router>
    <Details path=":id/*" />
    <Update path=":id/update/*" />
    <Approve path=":id/approve/*" />
    <Decline path=":id/decline/*" />
    <PrintOutContent path=":id/print-out-content/*" />
  </Router>
);

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <Query
    query={CapitationContractRequestQuery}
    fetchPolicy="network-only"
    variables={{ id }}
  >
    {({
      loading,
      data
    }: QueryResult<{
      capitationContractRequest: CapitationContractRequest;
    }>) => {
      if (isEmpty(data) || isEmpty(data.capitationContractRequest)) return null;
      const {
        databaseId,
        status,
        assignee,
        printoutContent,
        startDate,
        endDate,
        nhsSigner,
        nhsSignerBase,
        nhsContractPrice,
        nhsPaymentMethod,
        issueCity,
        contractorRmspAmount,
        contractorLegalEntity,
        contractorOwner,
        contractorBase,
        contractorPaymentDetails,
        contractorDivisions,
        contractorEmployeeDivisions,
        externalContractors,
        attachedDocuments,
        previousRequest,
        statusReason
      } = data.capitationContractRequest;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/contract-requests">
                  <Trans>Contract requests list</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Contract request details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <HeaderCapitation
              id={id!}
              databaseId={databaseId}
              status={status}
              printoutContent={printoutContent}
              assignee={assignee}
              previousRequest={previousRequest}
            />
          </Box>

          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General information</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./legal-entity">
              <Trans>Legal entity</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./divisions">
              <Trans>Division</Trans>
            </Tabs.NavItem>
            {contractorEmployeeDivisions && (
              <Tabs.NavItem to="./employees">
                <Trans>Doctors</Trans>
              </Tabs.NavItem>
            )}
            <Tabs.NavItem to="./external-contractors">
              <Trans>Contractors</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./documents">
              <Trans>Documents</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <GeneralInfoCapitation
                path="/"
                startDate={startDate}
                endDate={endDate}
                nhsSigner={nhsSigner}
                nhsSignerBase={nhsSignerBase}
                nhsContractPrice={nhsContractPrice}
                nhsPaymentMethod={nhsPaymentMethod}
                issueCity={issueCity}
                contractorRmspAmount={contractorRmspAmount}
                statusReason={statusReason}
              />
              <LegalEntityCapitation
                path="/legal-entity"
                contractorOwner={contractorOwner}
                contractorBase={contractorBase}
                contractorLegalEntity={contractorLegalEntity}
                contractorPaymentDetails={contractorPaymentDetails}
              />
              <Divisions
                path="/divisions"
                contractorDivisions={contractorDivisions}
              />
              <EmployeesCapitation
                path="/employees"
                contractorEmployeeDivisions={contractorEmployeeDivisions}
              />
              <ExternalContractors
                path="/external-contractors"
                externalContractors={externalContractors}
              />
              <DocumentsCapitation
                path="/documents"
                attachedDocuments={attachedDocuments}
              />
            </Router>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

export const CapitationContractRequestQuery = gql`
  query CapitationContractRequestQuery($id: ID!) {
    capitationContractRequest(id: $id) {
      ...HeaderCapitation
      ...GeneralInfoCapitation
      ...LegalEntityCapitation
      ...Divisions
      ...EmployeesCapitation
      ...ExternalContractors
      ...DocumentsCapitation
      miscellaneous
      toApproveContent
      toDeclineContent
      toSignContent
    }
  }
  ${HeaderCapitation.fragments.entry}
  ${GeneralInfoCapitation.fragments.entry}
  ${LegalEntityCapitation.fragments.entry}
  ${Divisions.fragments.entry}
  ${EmployeesCapitation.fragments.entry}
  ${ExternalContractors.fragments.entry}
  ${DocumentsCapitation.fragments.entry}
`;

export default CapitationContractRequestsDetails;
