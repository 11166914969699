import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { ProgramServiceConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import ProgramServicesTable from "./ProgramServicesTable";
import CreateProgramService from "./Mutations/CreateProgramService";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <>
          <Flex justifyContent="space-between" alignItems="flex-start" mb={6}>
            <Box>
              <Heading as="h1" fontWeight="normal" mb={4}>
                <Trans>Program services</Trans>
              </Heading>
            </Box>
            <Ability action="write" resource="program_service">
              <CreateProgramService
                locationParams={filterLocationParams(locationParams)}
                searchProgramServicesQuery={SearchProgramServicesQuery}
              />
            </Ability>
          </Flex>
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={PrimarySearchFields}
            renderSecondary={SecondarySearchFields}
          />
          <Query
            query={SearchProgramServicesQuery}
            fetchPolicy="network-only"
            variables={filterLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{ programServices: ProgramServiceConnection }>) => {
              if (isEmpty(data) || isEmpty(data.programServices)) return null;
              const {
                programServices: { nodes: programServices, pageInfo }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  <ProgramServicesTable
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                    programServices={programServices}
                  />
                  <Pagination pageInfo={pageInfo} />
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      )}
    </LocationParams>
  </Box>
);

const filterLocationParams = (locationParams: SearchParams) => {
  const { filter = {} } = locationParams;
  const medicalProgramId =
    filter.medicalProgram && filter.medicalProgram.databaseId;

  return filteredLocationParams({
    ...locationParams,
    filter: {
      ...filter,
      medicalProgram: medicalProgramId
        ? { databaseId: medicalProgramId }
        : undefined
    }
  });
};

const SearchProgramServicesQuery = gql`
  query SearchProgramServicesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: ProgramServiceFilter
    $orderBy: ProgramServiceOrderBy
  ) {
    programServices(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...ProgramServices
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProgramServicesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
