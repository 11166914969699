import * as React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Innm, InnmConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";

import Deactivate from "./Mutations/Deactivate";

type INNMsTableProps = {
  innms: InnmConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const INNMsTable = ({
  innms,
  locationParams,
  setLocationParams,
  tableName = "INNMs/search"
}: INNMsTableProps) => (
  <Table
    data={innms}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>INNM</Trans>,
      nameOriginal: <Trans>INNM original name</Trans>,
      sctid: <Trans>SCTID</Trans>,
      isActive: <Trans>Status</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      deactivate: <Trans>Action</Trans>
    }}
    renderRow={({ id, insertedAt, isActive, ...innm }: Innm) => ({
      ...innm,
      insertedAt: dateFormatter(
        i18n.locale,
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        },
        insertedAt
      ),
      isActive: (
        <Badge
          type="ACTIVE_STATUS_F"
          name={isActive}
          variant={!isActive}
          display="block"
        />
      ),
      deactivate: <Deactivate id={id} name={innm.name} isActive={isActive} />
    })}
    sortableFields={["insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName={tableName}
  />
);

INNMsTable.fragments = {
  entry: gql`
    fragment INNMs on INNM {
      id
      databaseId
      name
      sctid
      nameOriginal
      isActive
      insertedAt
    }
  `
};

export default INNMsTable;
