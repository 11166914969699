import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box } from "@rebass/emotion";

import { Rule } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type GeneralInfoProps = RouteComponentProps & {
  ruleEngine: Rule;
};

const GeneralInfo = ({ ruleEngine }: GeneralInfoProps) => {
  const {
    description,
    code,
    deactivationReason,
    insertedAt,
    name,
    isActive,
    updatedAt
  } = ruleEngine;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Rule engine rule title</Trans>,
          system: <Trans>Coding system</Trans>,
          code: <Trans>Code</Trans>,
          ...(description && {
            description: <Trans>Description</Trans>
          }),
          insertedAt: <Trans>Inserted at</Trans>,
          ...(!isActive && {
            updatedAt: <Trans>Updated at</Trans>
          }),
          ...(deactivationReason && {
            deactivationReason: <Trans>Deactivation reason</Trans>
          })
        }}
        data={{
          name,
          system: (
            <DictionaryValue
              name="eHealth/rule_engine_dictionaries"
              item={code.system}
            />
          ),
          code: <DictionaryValue name={code.system} item={code.code} />,
          description,
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
          deactivationReason
        }}
      />
    </Box>
  );
};

export default GeneralInfo;
