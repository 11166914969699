import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import {
  CapitationContractRequest,
  ContractorEmployeeDivision
} from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../../components/DictionaryValue";
import EmptyData from "../../../../components/EmptyData";
import FullName from "../../../../components/FullName";
import Table from "../../../../components/Table";

type EmployeesCapitationProps = RouteComponentProps & {
  contractorEmployeeDivisions: CapitationContractRequest["contractorEmployeeDivisions"];
};

const EmployeesCapitation = ({
  contractorEmployeeDivisions
}: EmployeesCapitationProps) =>
  contractorEmployeeDivisions && contractorEmployeeDivisions.length > 0 ? (
    <Table
      data={contractorEmployeeDivisions}
      header={{
        databaseId: <Trans>ID</Trans>,
        divisionName: <Trans>Division name</Trans>,
        employeeName: <Trans>Name of employee</Trans>,
        speciality: <Trans>Specialty</Trans>,
        staffUnits: <Trans>Permanent unit</Trans>,
        declarationLimit: <Trans>Declarations limit</Trans>
      }}
      renderRow={({
        employee: { databaseId, party, additionalInfo },
        division: { name: divisionName },
        ...contractorEmployeeDivisions
      }: ContractorEmployeeDivision) => ({
        databaseId,
        divisionName,
        employeeName: <FullName party={party} />,
        speciality: (
          <DictionaryValue
            name="SPECIALITY_TYPE"
            item={
              additionalInfo &&
              additionalInfo.specialities &&
              additionalInfo.specialities.find(
                (item) => item && item.specialityOfficio
              )!.speciality
            }
          />
        ),
        ...contractorEmployeeDivisions
      })}
      tableName="/capitation-contract-requests/employees"
      whiteSpaceNoWrap={["databaseId"]}
    />
  ) : (
    <EmptyData />
  );

EmployeesCapitation.fragments = {
  entry: gql`
    fragment EmployeesCapitation on CapitationContractRequest {
      contractorEmployeeDivisions {
        employee {
          id
          databaseId
          party {
            id
            ...FullName
          }
          additionalInfo {
            specialities {
              speciality
              specialityOfficio
            }
          }
        }
        staffUnits
        declarationLimit
        division {
          id
          name
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

export default EmployeesCapitation;
