import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Form } from "@edenlabllc/ehealth-components";
import { MedicationRequestConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";
import Pagination from "../../../components/Pagination";

import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";

import MedicationRequestsTable from "./MedicationRequestsTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Ability action="read" resource="medication_request_admin">
    <Box p={6}>
      <Heading as="h1" fontWeight="normal" mb={6}>
        <Trans>Medication requests</Trans>
      </Heading>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const params = prepareParamsToQuery(locationParams);

          return (
            <Query
              query={AdminMedicationRequestsQuery}
              fetchPolicy="network-only"
              variables={params}
              skip={!Object.keys(params.filter).length}
            >
              {({
                data,
                loading
              }: QueryResult<{
                adminMedicationRequests: MedicationRequestConnection;
              }>) => {
                return (
                  <LoadingOverlay loading={loading}>
                    <SearchForm
                      initialValues={locationParams}
                      onSubmit={setLocationParams}
                      renderPrimary={() => <PrimarySearchFields />}
                      renderSecondary={() => <SecondarySearchFields />}
                      onResetSkipPagination
                      searchButton={() => (
                        <Form.Spy>
                          {({ values: { filter = {} } }: $TSFixMe) => (
                            <Button
                              variant="blue"
                              disabled={!Object.keys(filter).length}
                            >
                              <Trans>Search</Trans>
                            </Button>
                          )}
                        </Form.Spy>
                      )}
                    />
                    {isEmpty(data) ||
                    isEmpty(data.adminMedicationRequests) ||
                    isEmpty(data.adminMedicationRequests.nodes) ? (
                      <Box pt={4}>
                        <EmptyData mx={2} />
                      </Box>
                    ) : (
                      <Box mb={6}>
                        <MedicationRequestsTable
                          data={data.adminMedicationRequests.nodes}
                          locationParams={locationParams}
                          setLocationParams={setLocationParams}
                          tableName="adminMedicationRequests"
                        />
                        <Pagination
                          pageInfo={data.adminMedicationRequests.pageInfo}
                        />
                      </Box>
                    )}
                  </LoadingOverlay>
                );
              }}
            </Query>
          );
        }}
      </LocationParams>
    </Box>
  </Ability>
);

export default Search;

const AdminMedicationRequestsQuery = gql`
  query AdminMedicationRequestsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: AdminMedicationRequestFilter!
    $orderBy: MedicationRequestOrderBy
  ) {
    adminMedicationRequests(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        ...MedicationRequests
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${MedicationRequestsTable.fragments.entry}
`;

const prepareParamsToQuery = (locationParams: SearchParams) => {
  const {
    medicationRequestCreated,
    employeeId,
    legalEntityId,
    medicalProgramId,
    startedAt,
    endedAt,
    dispenseValidFrom,
    dispenseValidTo,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...restFilterParams,
      ...(medicationRequestCreated && {
        created: `${medicationRequestCreated.from}/${medicationRequestCreated.to}`
      }),
      ...(employeeId && {
        employeeId: paramToBase64("Employee", employeeId)
      }),
      ...(legalEntityId && {
        legalEntityId: paramToBase64("LegalEntity", legalEntityId)
      }),
      ...(medicalProgramId && {
        medicalProgramId: paramToBase64("MedicalProgram", medicalProgramId)
      }),
      ...(startedAt && {
        startedAt: `${startedAt.from}/${startedAt.to}`
      }),
      ...(endedAt && {
        endedAt: `${endedAt.from}/${endedAt.to}`
      }),
      ...(dispenseValidFrom && {
        dispenseValidFrom: `${dispenseValidFrom.from}/${dispenseValidFrom.to}`
      }),
      ...(dispenseValidTo && {
        dispenseValidTo: `${dispenseValidTo.from}/${dispenseValidTo.to}`
      })
    }
  };
};
