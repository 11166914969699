import { useMemo } from "react";
import get from "lodash/get";

import {
  capitalize,
  fieldNameNormalizer,
  replaceSymbolFromTo
} from "@edenlabllc/ehealth-utils";
import { Reference } from "@edenlabllc/graphql-schema";

export const useMemoizedReference = (reference: Reference) => {
  const [memoizedReferenceType, memoizedReferenceSlug] = useMemo(
    () => getReferenceProps(reference),
    [reference]
  );

  return [memoizedReferenceType, memoizedReferenceSlug];
};

export const getReferenceProps = (reference: Reference) => {
  const {
    identifier: { type }
  } = reference;
  const referenceType = get(type, "coding[0].code");

  if (referenceType === "episode_of_care") {
    return ["Episode", "episode"];
  }

  return [
    capitalize(fieldNameNormalizer(referenceType)),
    replaceSymbolFromTo(referenceType, "_", "-")
  ];
};
