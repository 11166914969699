import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { EmployeeConnection, Employee } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Table from "../../../components/Table";
import Pagination from "../../../components/Pagination";

type EmployeesProps = RouteComponentProps & {
  data: EmployeeConnection;
  loading: boolean;
};

const Employees = ({ data, loading }: EmployeesProps) => {
  const { nodes: employees = [], pageInfo } = data;

  return (
    <LoadingOverlay loading={loading}>
      {employees && employees.length > 0 ? (
        <Box p={5}>
          <Table
            data={employees}
            header={{
              databaseId: <Trans>ID</Trans>,
              legalEntityName: <Trans>Legal entity name</Trans>,
              divisionName: <Trans>Division name</Trans>,
              position: <Trans>Position</Trans>,
              employeeType: <Trans>Employee type</Trans>,
              details: <Trans>Details</Trans>
            }}
            renderRow={({
              id,
              position,
              employeeType,
              legalEntity,
              division,
              ...employeeData
            }: Employee) => ({
              ...employeeData,
              legalEntityName: (
                <Link to={`/legal-entities/${legalEntity.id}`}>
                  {legalEntity.name}
                </Link>
              ),
              divisionName: division ? (
                <Link
                  to={`/legal-entities/${legalEntity.id}/divisions/${division.id}`}
                >
                  {division.name}
                </Link>
              ) : (
                "-"
              ),
              position: <DictionaryValue name="POSITION" item={position} />,
              employeeType: (
                <DictionaryValue name="EMPLOYEE_TYPE" item={employeeType} />
              ),
              details: (
                <Link to={`/employees/${id}`} fontWeight="bold">
                  <Trans>Show details</Trans>
                </Link>
              )
            })}
          />
          <Pagination pageInfo={pageInfo} />
        </Box>
      ) : (
        <EmptyData />
      )}
    </LoadingOverlay>
  );
};

Employees.fragments = {
  entry: gql`
    fragment Employees on Party {
      employees(
        first: $first
        orderBy: $orderBy
        before: $before
        after: $after
        last: $last
        filter: $filter
      ) {
        nodes {
          id
          databaseId
          position
          employeeType
          division {
            id
            databaseId
            name
          }
          legalEntity {
            id
            databaseId
            name
            edrData {
              name
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${Pagination.fragments.entry}
  `
};

export default Employees;
