import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicalProgram, Scalars } from "@edenlabllc/graphql-schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import Header from "./Header";
import GeneralInfo from "./GeneralInfo";
import Settings from "./Settings";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"]["output"];
}>;

const Details = ({ id }: DetailsProps) => {
  return (
    <Query
      query={MedicalProgramQuery}
      variables={{
        id,
        isProgramSettingsEnabled: true
      }}
    >
      {({ loading, data }: QueryResult<{ medicalProgram: MedicalProgram }>) => {
        if (isEmpty(data)) return null;
        const {
          databaseId,
          name,
          isActive,
          insertedAt,
          type,
          medicalProgramSettings,
          medicalProgramSettingsText,
          fundingSource,
          mrBlankType
        } = data.medicalProgram || {};

        return (
          <LoadingOverlay loading={loading}>
            <Box p={6}>
              <Box py={10}>
                <Breadcrumbs.List>
                  <Breadcrumbs.Item to="/medical-programs">
                    <Trans>Medical programs</Trans>
                  </Breadcrumbs.Item>
                  <Breadcrumbs.Item>
                    <Trans>Medical program details</Trans>
                  </Breadcrumbs.Item>
                </Breadcrumbs.List>
              </Box>
              <Header id={id!} databaseId={databaseId} isActive={isActive} />
            </Box>
            <Tabs.Nav>
              <Tabs.NavItem to="./">
                <Trans>General info</Trans>
              </Tabs.NavItem>
              <Tabs.NavItem to="./settings">
                <Trans>Settings</Trans>
              </Tabs.NavItem>
            </Tabs.Nav>
            <Tabs.Content>
              <Router>
                <GeneralInfo
                  path="/"
                  name={name}
                  type={type}
                  insertedAt={insertedAt}
                  fundingSource={fundingSource}
                  mrBlankType={mrBlankType}
                />
                <Settings
                  path="/settings/*"
                  medicalProgramSettings={medicalProgramSettings}
                  type={type}
                  id={id}
                  isActive={isActive}
                  medicalProgramSettingsText={medicalProgramSettingsText}
                />
              </Router>
            </Tabs.Content>
          </LoadingOverlay>
        );
      }}
    </Query>
  );
};

export const MedicalProgramQuery = gql`
  query MedicalProgramQuery($id: ID!, $isProgramSettingsEnabled: Boolean!) {
    medicalProgram(id: $id) {
      id
      ...MedicalProgramHeader
      ...MedicalProgramGeneralInfo
      ...MedicalProgramSettings @include(if: $isProgramSettingsEnabled)
    }
  }
  ${Header.fragments.entry}
  ${GeneralInfo.fragments.entry}
  ${Settings.fragments.entry}
`;

export default Details;
