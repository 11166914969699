import React, { useReducer, useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";
import { I18n } from "@lingui/core";

import { Form } from "@edenlabllc/ehealth-components";
import { DictionaryValue } from "@edenlabllc/graphql-schema";

import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";
import Link from "../../../../components/Link";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

type UpdateDictionaryValueDescriptionProps = {
  id: DictionaryValue["id"];
  description: DictionaryValue["description"];
};

export const UpdateDictionaryValueDescription = ({
  id,
  description
}: UpdateDictionaryValueDescriptionProps) => {
  const [isVisible, toggle] = useReducer((v) => !v, false);
  const { i18n } = useLingui();
  const [error, setError] = useState<string | null>(null);

  return (
    <Mutation mutation={UpdateDictionaryValueDescriptionMutation}>
      {(updateDictionaryValueDescription: MutationFunction) => (
        <>
          <Link is="a" onClick={toggle}>
            <Trans>edit</Trans>
          </Link>

          <Popup
            visible={isVisible}
            onCancel={toggle}
            title={<Trans>Description</Trans>}
            okText={<Trans>Update</Trans>}
            okButtonProps={{ variant: "green" }}
            justifyButtons="left"
            formId="updateDictionaryValueDescriptionForm"
          >
            <Form
              id="updateDictionaryValueDescriptionForm"
              initialValues={{ description }}
              onSubmit={async ({ description = "" }) => {
                setError(null);

                try {
                  const { data } = await updateDictionaryValueDescription({
                    variables: {
                      input: {
                        id,
                        description
                      }
                    }
                  });
                  if (data.updateDictionaryValue) {
                    toggle();
                  }
                } catch (error) {
                  if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                    const errorTranslation = handleTranslateErrors(
                      getErrorMessage(error),
                      i18n
                    );
                    setError(errorTranslation);
                  }
                }
              }}
            >
              <Flex mx={-1}>
                <Box width={1} px={1}>
                  <Trans
                    id="Enter description"
                    render={({ translation }) => (
                      <Field.Textarea
                        name="description"
                        placeholder={translation}
                        rows={5}
                        maxLength={3000}
                        showLengthHint
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Form>
          </Popup>

          {error && (
            <UnpocessableEntityModalError errorMessage={error} isModalOpen />
          )}
        </>
      )}
    </Mutation>
  );
};

const UpdateDictionaryValueDescriptionMutation = gql`
  mutation UpdateDictionaryValueDescriptionMutation(
    $input: UpdateDictionaryValueInput!
  ) {
    updateDictionaryValue(input: $input) {
      dictionaryValue {
        id
        description
      }
    }
  }
`;

const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    default:
      return errorMessage;
  }
};
