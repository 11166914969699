import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Reference as TReference,
  ServiceRequest as TServiceRequest
} from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import Collapsable from "../../../components/Collapsable";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Line from "../../../components/Line";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { MonitoringDetailsPageProps } from "../../../helpers/types";
import paramToBase64 from "../../../helpers/paramToBase64";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import DivisionReference from "./DivisionReference";
import IntervalOrOpenDate from "./IntervalOrOpenDate";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Reference from "./Reference";
import ReferenceSwitcher from "./ReferenceSwitcher";
import ReasonReferences from "./ReasonReferences";
import StatusHistory from "./StatusHistory";
import ServiceRequestCode from "./ServiceRequestCode";
import { LABEL_WIDTH } from "./Episode";

const ServiceRequest = ({
  query,
  queryVariables,
  itemId,
  id,
  patientId,
  isClinicalMonitoring = false
}: MonitoringDetailsPageProps) => {
  const handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) => {
    switch (memoizedReferenceType) {
      case "EpisodeOfCare":
        return isClinicalMonitoring
          ? `../../../episode/${patientId}/${paramToBase64(
              "Episode",
              reference.identifier.value
            )}`
          : `../../episode/${paramToBase64(
              "Episode",
              reference.identifier.value
            )}`;
      default:
        return isClinicalMonitoring
          ? `../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
              memoizedReferenceType,
              reference.identifier.value
            )}`
          : `../../${memoizedReferenceSlug}/${paramToBase64(
              memoizedReferenceType,
              reference.identifier.value
            )}`;
    }
  };

  return (
    <Ability
      action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
      resource="service_request"
    >
      <Box p={6}>
        <DetailsPageBreadcrumbs
          id={id}
          currentStepName={<Trans>Service request details</Trans>}
          isClinicalMonitoring={isClinicalMonitoring}
        />
        <Query
          query={query!}
          fetchPolicy="network-only"
          variables={{
            ...queryVariables,
            id: itemId,
            ...(isClinicalMonitoring && { patientId })
          }}
        >
          {({
            loading,
            error,
            data = {}
          }: QueryResult<{ [key: string]: TServiceRequest }>) => {
            const serviceRequest = isClinicalMonitoring
              ? data.impersonalServiceRequest
              : data.serviceRequest;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  {!isEmpty(serviceRequest) && !error && (
                    <>
                      <HeaderDefinitions
                        serviceRequest={serviceRequest}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId!}
                      />
                      <Box mt={4}>
                        <TopBodyDefinitions
                          serviceRequest={serviceRequest}
                          isClinicalMonitoring={isClinicalMonitoring}
                        />
                        <ServiceRequestCode
                          serviceRequestCode={serviceRequest.code}
                        />
                        {!isEmpty(serviceRequest.context) && (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Context</Trans>}
                              linkPath={
                                isClinicalMonitoring
                                  ? `../../../encounter/${patientId}/${paramToBase64(
                                      "Encounter",
                                      serviceRequest.context.identifier.value
                                    )}`
                                  : `../../encounter/${paramToBase64(
                                      "Encounter",
                                      serviceRequest.context.identifier.value
                                    )}`
                              }
                              linkDisplayValue={
                                serviceRequest.context.displayValue
                                  ? serviceRequest.context.displayValue
                                  : serviceRequest.context.identifier.value
                              }
                            />
                          </Box>
                        )}
                        {!isEmpty(serviceRequest.occurrence) && (
                          <Box mt={2}>
                            <IntervalOrOpenDate
                              intervalOrOpenDate={serviceRequest.occurrence}
                              header={<Trans>Occurrence</Trans>}
                              dateTimePrefix={
                                <Text mr={1}>
                                  <Trans>till</Trans>
                                </Text>
                              }
                            />
                          </Box>
                        )}
                        {!isEmpty(serviceRequest.requesterEmployee) && (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Requester employee</Trans>}
                              linkPath={`/employees/${paramToBase64(
                                "Employee",
                                serviceRequest.requesterEmployee.identifier
                                  .value
                              )}`}
                              linkDisplayValue={
                                serviceRequest.requesterEmployee.displayValue
                                  ? serviceRequest.requesterEmployee
                                      .displayValue
                                  : serviceRequest.requesterEmployee.identifier
                                      .value
                              }
                            />
                          </Box>
                        )}
                        {!isEmpty(serviceRequest.requesterLegalEntity) && (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Requester legal entity</Trans>}
                              linkPath={`/legal-entities/${paramToBase64(
                                "LegalEntity",
                                serviceRequest.requesterLegalEntity.identifier
                                  .value
                              )}`}
                              linkDisplayValue={
                                serviceRequest.requesterLegalEntity.displayValue
                                  ? serviceRequest.requesterLegalEntity
                                      .displayValue
                                  : serviceRequest.requesterLegalEntity
                                      .identifier.value
                              }
                            />
                          </Box>
                        )}
                        {!isEmpty(serviceRequest.usedByEmployee) && (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Used by employee</Trans>}
                              linkPath={`/employees/${paramToBase64(
                                "Employee",
                                serviceRequest.usedByEmployee.identifier.value
                              )}`}
                              linkDisplayValue={
                                serviceRequest.usedByEmployee.displayValue
                                  ? serviceRequest.usedByEmployee.displayValue
                                  : serviceRequest.usedByEmployee.identifier
                                      .value
                              }
                            />
                          </Box>
                        )}
                        {!isEmpty(serviceRequest.usedByLegalEntity) && (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={<Trans>Used by legal entity</Trans>}
                              linkPath={`/legal-entities/${paramToBase64(
                                "LegalEntity",
                                serviceRequest.usedByLegalEntity.identifier
                                  .value
                              )}`}
                              linkDisplayValue={
                                serviceRequest.usedByLegalEntity.displayValue
                                  ? serviceRequest.usedByLegalEntity
                                      .displayValue
                                  : serviceRequest.usedByLegalEntity.identifier
                                      .value
                              }
                            />
                          </Box>
                        )}
                        <Box mt={4}>
                          <DefinitionListView
                            fontSize={14}
                            labelWidth={LABEL_WIDTH}
                            labels={{
                              ...(serviceRequest.performerType && {
                                performerType: <Trans>Performer type</Trans>
                              })
                            }}
                            data={{
                              ...(serviceRequest.performerType && {
                                performerType: (
                                  <CodeableConcept
                                    codeableConcept={
                                      serviceRequest.performerType
                                    }
                                  />
                                )
                              })
                            }}
                          />
                        </Box>
                        {!isEmpty(serviceRequest.performer) && (
                          <Box mt={2}>
                            <Reference
                              fontSize={14}
                              header={
                                <Trans id="Service request performer">
                                  Performer
                                </Trans>
                              }
                              linkPath={`/legal-entities/${paramToBase64(
                                "LegalEntity",
                                serviceRequest.performer.identifier.value
                              )}`}
                              linkDisplayValue={
                                serviceRequest.performer.displayValue
                                  ? serviceRequest.performer.displayValue
                                  : serviceRequest.performer.identifier.value
                              }
                            />
                          </Box>
                        )}
                        <DivisionReference
                          reference={serviceRequest}
                          divisionFieldPath="locationReference"
                          header={<Trans>Location reference</Trans>}
                        />
                        <CompletedWith
                          reference={serviceRequest.completedWith}
                          isClinicalMonitoring={isClinicalMonitoring}
                          patientId={patientId!}
                        />
                        {!isEmpty(serviceRequest.reasonReference) && (
                          <Collapsable
                            title={
                              <Text color="darkCharcoal">
                                <Trans>Reason reference</Trans>
                              </Text>
                            }
                          >
                            <ReasonReferences
                              reasonReferences={serviceRequest.reasonReference}
                              isClinicalMonitoring={isClinicalMonitoring}
                              patientId={patientId}
                            />
                          </Collapsable>
                        )}
                        {!isEmpty(serviceRequest.permittedResources) && (
                          <Collapsable
                            title={
                              <Text color="darkCharcoal">
                                <Trans>Permitted resources</Trans>
                              </Text>
                            }
                          >
                            <PermittedResources
                              permittedResources={
                                serviceRequest.permittedResources
                              }
                              isClinicalMonitoring={isClinicalMonitoring}
                              patientId={patientId!}
                            />
                          </Collapsable>
                        )}
                        {!isEmpty(serviceRequest.supportingInfo) && (
                          <Collapsable
                            title={
                              <Text color="darkCharcoal">
                                <Trans>Supporting info</Trans>
                              </Text>
                            }
                          >
                            <SupportingInfo
                              supportingInfo={serviceRequest.supportingInfo}
                              handleSetReferenceLink={handleSetReferenceLink}
                            />
                          </Collapsable>
                        )}
                        {!isEmpty(serviceRequest.statusHistory) && (
                          <Collapsable title={<Trans>Status history</Trans>}>
                            <StatusHistory
                              statusHistory={serviceRequest.statusHistory}
                              statusDictionaryName="service_request_statuses"
                            />
                          </Collapsable>
                        )}
                        <BottomBodyDefinitions
                          serviceRequest={serviceRequest}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
      </Box>
      <Conclusions
        isClinicalMonitoring={isClinicalMonitoring}
        patientId={patientId!}
        itemId={itemId!}
      />
    </Ability>
  );
};

export default ServiceRequest;

type HeaderDefinitionsProps = {
  serviceRequest: TServiceRequest;
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  serviceRequest,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  if (isEmpty(serviceRequest)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>,
              ...(serviceRequest.programProcessingStatus && {
                programProcessingStatus: (
                  <Trans>Program processing status</Trans>
                )
              }),
              ...(serviceRequest.statusReason && {
                statusReason: <Trans>Reason status</Trans>
              }),
              ...(serviceRequest.explanatoryLetter && {
                explanatoryLetter: <Trans>Explanatory letter</Trans>
              })
            }}
            data={{
              ...serviceRequest,
              status: (
                <DictionaryValue
                  name="service_request_statuses"
                  item={serviceRequest.status.toLowerCase()}
                />
              ),
              ...(serviceRequest.programProcessingStatus && {
                programProcessingStatus: (
                  <DictionaryValue
                    name="service_request_processing_statuses"
                    item={serviceRequest.programProcessingStatus.toLowerCase()}
                  />
                )
              }),
              statusReason: (
                <CodeableConcept
                  codeableConcept={serviceRequest.statusReason}
                />
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId,
              "service_request",
              serviceRequest.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

type TopBodyDefinitionsProps = {
  serviceRequest: TServiceRequest;
  isClinicalMonitoring?: boolean;
};

const TopBodyDefinitions = ({
  serviceRequest,
  isClinicalMonitoring
}: TopBodyDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(serviceRequest)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        ...(!isClinicalMonitoring && {
          requisition: <Trans>Requisition</Trans>
        }),
        intent: <Trans>Intent</Trans>,
        ...(serviceRequest.priority && {
          priority: <Trans>Priority</Trans>
        }),
        ...(serviceRequest.category && {
          category: <Trans>Category</Trans>
        }),
        ...(!isEmpty(serviceRequest.program) && {
          program: <Trans>Program</Trans>
        }),
        expirationDate: (
          <Trans id="Service request expiration date">Expiration date</Trans>
        )
      }}
      data={{
        ...serviceRequest,
        intent: (
          <DictionaryValue
            name="SERVICE_REQUEST_INTENT"
            item={serviceRequest.intent.toLowerCase()}
          />
        ),
        ...(serviceRequest.priority && {
          priority: (
            <DictionaryValue
              name="SERVICE_REQUEST_PRIORITY"
              item={serviceRequest.priority.toLowerCase()}
            />
          )
        }),
        ...(serviceRequest.category && {
          category: (
            <CodeableConcept codeableConcept={serviceRequest.category} />
          )
        }),
        ...(serviceRequest.program && {
          program: (
            <Box mt={-10}>
              <Reference
                fontSize={14}
                headless
                linkPath={`/medical-programs/${serviceRequest.program.id}`}
                linkDisplayValue={serviceRequest.program.name}
              />
            </Box>
          ),
          expirationDate: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            serviceRequest.expirationDate
          )
        })
      }}
    />
  );
};

const BottomBodyDefinitions = ({
  serviceRequest
}: {
  serviceRequest: TServiceRequest;
}) => {
  const { i18n } = useLingui();
  if (isEmpty(serviceRequest)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          ...(serviceRequest.patientInstruction && {
            patientInstruction: <Trans>Patient instruction</Trans>
          }),
          ...(serviceRequest.note && {
            note: <Trans>Note</Trans>
          }),
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          ...(serviceRequest.patientInstruction && {
            patientInstruction: serviceRequest.patientInstruction
          }),
          ...(serviceRequest.note && {
            note: serviceRequest.note
          }),
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            serviceRequest.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            serviceRequest.updatedAt
          )
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

type CompletedWithProps = {
  reference: TServiceRequest["completedWith"];
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const CompletedWith = ({
  reference,
  isClinicalMonitoring,
  patientId
}: CompletedWithProps) => {
  if (isEmpty(reference)) return null;

  const REFERENCE_TYPES = {
    values: {
      Encounter: t`Encounter`,
      DiagnosticReport: t`Diagnostic report`,
      Procedure: t`Procedure`
    }
  };

  const handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) =>
    isClinicalMonitoring
      ? `../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`
      : `../../${memoizedReferenceSlug}/${paramToBase64(
          memoizedReferenceType,
          reference.identifier.value
        )}`;

  return (
    <Box>
      <DefinitionListView
        fontSize={14}
        labelWidth={LABEL_WIDTH}
        labels={{
          reference: <Trans>Completed with</Trans>
        }}
        data={{
          reference: (
            <ReferenceSwitcher
              reference={reference}
              referenceTypes={REFERENCE_TYPES}
              handleSetReferenceLink={handleSetReferenceLink}
            />
          )
        }}
      />
    </Box>
  );
};

type PermittedResourcesProps = {
  permittedResources: TServiceRequest["permittedResources"];
  patientId: string;
  isClinicalMonitoring?: boolean;
};

const PermittedResources = ({
  permittedResources,
  isClinicalMonitoring,
  patientId
}: PermittedResourcesProps) => {
  if (isEmpty(permittedResources)) return null;

  const REFERENCE_TYPES = {
    values: {
      DiagnosticReport: t`Diagnostic report`,
      EpisodeOfCare: t`Episode`
    }
  };

  const handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) => {
    switch (memoizedReferenceType) {
      case "EpisodeOfCare":
        return isClinicalMonitoring
          ? `../../../episode/${patientId}/${paramToBase64(
              "Episode",
              reference.identifier.value
            )}`
          : `../../episode/${paramToBase64(
              "Episode",
              reference.identifier.value
            )}`;
      default:
        return isClinicalMonitoring
          ? `../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
              memoizedReferenceType,
              reference.identifier.value
            )}`
          : `../../${memoizedReferenceSlug}/${paramToBase64(
              memoizedReferenceType,
              reference.identifier.value
            )}`;
    }
  };

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={permittedResources}
        header={{
          reference: <Trans>Reference</Trans>
        }}
        renderRow={({ ...reference }) => ({
          reference: (
            <ReferenceSwitcher
              reference={reference}
              referenceTypes={REFERENCE_TYPES}
              handleSetReferenceLink={handleSetReferenceLink}
            />
          )
        })}
      />
    </Box>
  );
};

type SupportingInfoProps = {
  supportingInfo: TServiceRequest["supportingInfo"];
  handleSetReferenceLink: (
    type: string,
    slug: string,
    reference: TReference
  ) => string;
};

const SupportingInfo = ({
  supportingInfo,
  handleSetReferenceLink
}: SupportingInfoProps) => {
  if (isEmpty(supportingInfo)) return null;

  const REFERENCE_TYPES = {
    values: {
      Observation: t`Observation`,
      DiagnosticReport: t`Diagnostic report`,
      Condition: t`Condition`,
      EpisodeOfCare: t`Episode`,
      AllergyIntolerance: t`Allergy intolerance`,
      Immunization: t`Immunization`,
      Procedure: t`Procedure`
    }
  };

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={supportingInfo}
        header={{
          reference: <Trans>Reference</Trans>
        }}
        renderRow={({ ...reference }) => ({
          reference: (
            <ReferenceSwitcher
              reference={reference}
              referenceTypes={REFERENCE_TYPES}
              handleSetReferenceLink={handleSetReferenceLink}
            />
          )
        })}
      />
    </Box>
  );
};

ServiceRequest.fragments = {
  entry: gql`
    fragment ServiceRequest on ServiceRequest {
      id
      databaseId
      status
      requisition
      statusReason {
        ...CodeableConcept
      }
      explanatoryLetter
      programProcessingStatus
      intent
      priority
      category {
        ...CodeableConcept
      }
      code {
        ...ServiceRequestCode
      }
      program {
        id
        name
      }
      expirationDate
      context {
        ...Reference
      }
      occurrence {
        ...IntervalOrOpenDate
      }
      requesterEmployee {
        ...Reference
      }
      requesterLegalEntity {
        ...Reference
      }
      usedByEmployee {
        ...Reference
      }
      usedByLegalEntity {
        ...Reference
      }
      performerType {
        ...CodeableConcept
      }
      performer {
        ...Reference
      }
      locationReference {
        ...Reference
      }
      reasonReference {
        ...Reference
      }
      permittedResources {
        ...Reference
      }
      supportingInfo {
        ...Reference
      }
      completedWith {
        ...Reference
      }
      patientInstruction @skip(if: $skipAdditionalFields)
      note @skip(if: $skipAdditionalFields)
      statusHistory {
        ...StatusHistory
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
    ${IntervalOrOpenDate.fragments.entry}
    ${StatusHistory.fragments.entry}
    ${ServiceRequestCode.fragments.entry}
  `
};

export const ServiceRequestByIDQuery = gql`
  query ServiceRequestByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    serviceRequest(id: $id, personId: $personId) {
      ...ServiceRequest
    }
  }
  ${ServiceRequest.fragments.entry}
`;
