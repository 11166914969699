import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import { ConditionConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";
import Pagination from "../../../components/Pagination";

import { MonitoringTabProps } from "../../../helpers/types";
import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

const ConditionsTab = ({
  id,
  query = ConditionsQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="condition"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: ConditionConnection }>) => {
            const conditions = isClinicalMonitoring
              ? data.impersonalConditions
              : data.conditions;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                  {isEmpty(conditions) || isEmpty(conditions.nodes) ? (
                    <EmptyData mx={2} />
                  ) : (
                    <Box mb={6} p={2}>
                      <Box>
                        <Text>
                          <Trans>Total found</Trans>: {conditions.totalEntries}
                        </Text>
                      </Box>
                      <SearchResultTable
                        data={conditions.nodes}
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                        statusSystem="eHealth/condition_verification_statuses"
                        tableName="conditions"
                        customHeaders={{
                          status: <Trans>Status of confirmation</Trans>
                        }}
                        pathSlug={
                          isClinicalMonitoring
                            ? `condition/${
                                locationParams.filter &&
                                locationParams.filter.patientId
                              }`
                            : "condition"
                        }
                        isClinicalMonitoring={isClinicalMonitoring}
                      />
                      <Pagination pageInfo={conditions.pageInfo} />
                    </Box>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default ConditionsTab;

const ConditionsQuery = gql`
  query ConditionsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ConditionFilter!
    $orderBy: ConditionOrderBy
  ) {
    conditions(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status: verificationStatus
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const { conditionOnsetDate, encounterId, conditionId, ...restFilterParams } =
    locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(encounterId && {
        encounterId: paramToBase64("Encounter", encounterId)
      }),
      ...(conditionOnsetDate && {
        onsetDate: `${conditionOnsetDate.from}/${conditionOnsetDate.to}`
      }),
      ...(conditionId && {
        id: paramToBase64("Condition", conditionId)
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => (
  <Flex flexDirection="column">
    <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
    <Flex>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter current condition code"
          render={({ translation }) => (
            <Field.Text
              name="filter.code"
              label={<Trans id="Current condition code" />}
              placeholder={translation}
            />
          )}
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter condition's encounter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.encounterId"
              label={<Trans id="Condition's encounter ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.encounterId"
          options={UUID_PATTERN}
          message="Invalid encounter ID"
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter onset date"
          render={() => (
            <Field.RangePicker
              rangeNames={[
                "filter.conditionOnsetDate.from",
                "filter.conditionOnsetDate.to"
              ]}
              label={<Trans id="Onset date" />}
            />
          )}
        />
        <Form.Spy>
          {({ values = {} }: $TSFixMe) => {
            if (
              (values.filter &&
                values.filter.conditionOnsetDate &&
                values.filter.conditionOnsetDate.from) ||
              (values.filter &&
                values.filter.conditionOnsetDate &&
                values.filter.conditionOnsetDate.to)
            ) {
              return (
                <>
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.conditionOnsetDate.from"
                        message={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.conditionOnsetDate.to"
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }
            return null;
          }}
        </Form.Spy>
      </Box>
    </Flex>
    {isClinicalMonitoring && (
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter condition ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.conditionId"
                label={<Trans id="Сondition ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.conditionId"
            options={UUID_PATTERN}
            message="Invalid condition ID"
          />
        </Box>
      </Flex>
    )}
  </Flex>
);
