import React from "react";
import { Router, RouteComponentProps, Link } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import printIframe from "print-iframe";
import { loader } from "graphql.macro";
import format from "date-fns/format";
import { Trans } from "@lingui/macro";
import { Flex, Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { LocationParams, Switch } from "@edenlabllc/ehealth-components";
import {
  PrinterIcon,
  DefaultImageIcon,
  PositiveIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import {
  getFullName,
  formatWorkingHours,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ReimbursementContractRequest,
  MedicalProgram,
  Division
} from "@edenlabllc/graphql-schema";

import AddressView from "../../../components/AddressView";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";
import LinkComponent from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ModalAssigneeSearch from "../../../components/ModalAssigneeSearch";
import Tabs from "../../../components/Tabs";
import Table, { SortingParams } from "../../../components/Table";
import { TLocationParams } from "../../../components/SearchForm";

import WEEK_DAYS from "../../../helpers/weekDays";
import STATUSES from "../../../helpers/statuses";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import Approve from "./Approve";
import Update from "./Update";
import Decline from "./Decline";
import PrintOutContent from "./PrintOutContent";

const ReimbursementContractRequestQuery = loader(
  "../../../graphql/ReimbursementContractRequestQuery.graphql"
);

const ReimbursementContractRequestDetails = (_props: RouteComponentProps) => (
  <Router>
    <Details path=":id/*" />
    <Update path=":id/update/*" />
    <Approve path=":id/approve/*" />
    <Decline path=":id/decline/*" />
    <PrintOutContent path=":id/print-out-content/*" />
  </Router>
);

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id }: DetailsProps) => (
  <Query
    query={ReimbursementContractRequestQuery}
    variables={{ id, first: ITEMS_PER_PAGE[0] }}
  >
    {({
      data
    }: QueryResult<{
      reimbursementContractRequest: ReimbursementContractRequest;
    }>) => {
      if (isEmpty(data) || isEmpty(data.reimbursementContractRequest))
        return null;
      const {
        databaseId,
        status,
        assignee,
        printoutContent: content,
        startDate,
        endDate,
        nhsSigner,
        nhsSignerBase,
        nhsPaymentMethod,
        issueCity,
        contractorLegalEntity,
        contractorOwner,
        contractorBase,
        contractorPaymentDetails,
        contractorDivisions,
        attachedDocuments,
        previousRequest,
        statusReason
      } = data.reimbursementContractRequest;
      const { party = "" } = assignee ? assignee : {};

      return (
        <>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/contract-requests/reimbursement">
                  <Trans>Contract requests list</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Contract request details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Flex justifyContent="space-between">
              <Box>
                <DefinitionListView
                  labels={{
                    id: <Trans>Contract request ID</Trans>,
                    previousRequestId: <Trans>Previous request ID</Trans>,
                    status: <Trans>Status</Trans>,
                    assignee: <Trans>Performer</Trans>
                  }}
                  data={{
                    id: databaseId,
                    previousRequestId: previousRequest && (
                      <LinkComponent
                        to={`/contract-requests/reimbursement/${previousRequest.id}`}
                      >
                        {previousRequest.databaseId}
                      </LinkComponent>
                    ),
                    status: (
                      <Badge
                        name={status}
                        type="CONTRACT_REQUEST"
                        minWidth={100}
                      />
                    ),
                    assignee: (
                      <Switch
                        value={status}
                        NEW={
                          <ModalAssigneeSearch
                            submitted={getFullName(party)}
                            id={id}
                            query={ReimbursementContractRequestQuery}
                          />
                        }
                        IN_PROCESS={
                          <ModalAssigneeSearch
                            submitted={getFullName(party)}
                            id={id}
                            query={ReimbursementContractRequestQuery}
                          />
                        }
                        default={assignee && getFullName(party)}
                      />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="100px"
                />
              </Box>
              <Switch
                value={status}
                IN_PROCESS={
                  <Flex alignItems="flex-end">
                    <Flex>
                      <Box mr={2}>
                        <Link to="decline">
                          <Button variant="red">
                            <Trans>Reject</Trans>
                          </Button>
                        </Link>
                      </Box>
                      <Link to="update">
                        <Button variant="green">
                          <Trans>Approve</Trans>
                        </Button>
                      </Link>
                    </Flex>
                  </Flex>
                }
                PENDING_NHS_SIGN={
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <PrintButton content={content} />
                    <Link to="./print-out-content">
                      <Button variant="green">
                        <Trans>Sign</Trans>
                      </Button>
                    </Link>
                  </Flex>
                }
                NHS_SIGNED={<PrintButton content={content} />}
                SIGNED={<PrintButton content={content} />}
              />
            </Flex>
          </Box>

          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General information</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./legal-entity">
              <Trans>Pharmacy</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./divisions">
              <Trans>Subdivisions</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./medical-programs">
              <Trans>Medical programs</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./documents">
              <Trans>Documents</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <GeneralInfo
                path="/"
                startDate={startDate}
                endDate={endDate}
                nhsSigner={nhsSigner}
                nhsSignerBase={nhsSignerBase}
                nhsPaymentMethod={nhsPaymentMethod}
                issueCity={issueCity}
                statusReason={statusReason}
              />
              <LegalEntity
                path="/legal-entity"
                contractorOwner={contractorOwner}
                contractorBase={contractorBase}
                contractorLegalEntity={contractorLegalEntity}
                contractorPaymentDetails={contractorPaymentDetails}
              />
              <Divisions
                path="/divisions"
                contractorDivisions={contractorDivisions}
              />
              <Documents
                path="/documents"
                attachedDocuments={attachedDocuments}
              />
              <MedicalPrograms path="/medical-programs" />
            </Router>
          </Tabs.Content>
        </>
      );
    }}
  </Query>
);

type GeneralInfoProps = RouteComponentProps & {
  nhsSigner: ReimbursementContractRequest["nhsSigner"];
  nhsSignerBase: ReimbursementContractRequest["nhsSignerBase"];
  nhsPaymentMethod: ReimbursementContractRequest["nhsPaymentMethod"];
  issueCity: ReimbursementContractRequest["issueCity"];
  startDate: ReimbursementContractRequest["startDate"];
  endDate: ReimbursementContractRequest["endDate"];
  statusReason: ReimbursementContractRequest["statusReason"];
};

const GeneralInfo = ({
  nhsSigner,
  nhsSignerBase,
  nhsPaymentMethod,
  issueCity,
  startDate,
  endDate,
  statusReason
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        nhsSignerName: <Trans>Signer name</Trans>,
        nhsSignerBase: <Trans>Signer base</Trans>,
        nhsPaymentMethod: <Trans>Payment method</Trans>,
        issueCity: <Trans>The city of the conclusion of the contract</Trans>
      }}
      data={{
        nhsSignerName: nhsSigner ? getFullName(nhsSigner.party) : null,
        nhsSignerBase,
        nhsPaymentMethod: nhsPaymentMethod && (
          <DictionaryValue
            name="CONTRACT_PAYMENT_METHOD"
            item={nhsPaymentMethod}
          />
        ),
        issueCity
      }}
    />
    {nhsSigner && <Line />}
    <DefinitionListView
      labels={{
        startDate: <Trans>Initial date of the contract</Trans>,
        endDate: <Trans>End date of the contract</Trans>
      }}
      data={{
        startDate: format(startDate, "DD.MM.YYYY"),
        endDate: format(endDate, "DD.MM.YYYY")
      }}
    />
    {statusReason && (
      <>
        <Line />
        <DefinitionListView
          labels={{
            statusReason: <Trans>Status Comment</Trans>
          }}
          data={{
            statusReason
          }}
        />
      </>
    )}
  </Box>
);

type LegalEntityProps = RouteComponentProps & {
  contractorBase: ReimbursementContractRequest["contractorBase"];
  contractorOwner: ReimbursementContractRequest["contractorOwner"];
  contractorPaymentDetails: ReimbursementContractRequest["contractorPaymentDetails"];
  contractorLegalEntity: ReimbursementContractRequest["contractorLegalEntity"];
};

const LegalEntity = ({
  contractorBase,
  contractorOwner,
  contractorPaymentDetails,
  contractorLegalEntity
}: LegalEntityProps) => {
  const { bankName, mfo, payerAccount } = contractorPaymentDetails || {};
  const {
    databaseId: legalEntityDatabaseId,
    id: legalEntityId,
    edrpou,
    edrData
  } = contractorLegalEntity || {};
  const { name, registrationAddress } = edrData || {};
  const { zip, country, address } = registrationAddress || {};

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          edrpou: <Trans>EDRPOU</Trans>,
          name: <Trans>Name</Trans>,
          addresses: <Trans>Address</Trans>
        }}
        data={{
          name,
          edrpou,
          addresses: (
            <>
              {zip}, {country}, {address}
            </>
          )
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          legalEntityId: "ID аптеки"
        }}
        data={{
          legalEntityId: (
            <LinkComponent to={`/legal-entities/${legalEntityId}`}>
              {legalEntityDatabaseId}
            </LinkComponent>
          )
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          fullName: <Trans>Subscriber Name</Trans>,
          contractorBase: <Trans>Based on</Trans>
        }}
        data={{
          fullName: contractorOwner && getFullName(contractorOwner.party),
          contractorBase: contractorBase
        }}
      />
      <DefinitionListView
        color="blueberrySoda"
        labels={{
          ownerId: <Trans>Signer ID</Trans>
        }}
        data={{
          ownerId: contractorOwner && contractorOwner.databaseId
        }}
      />
      <Line />
      <DefinitionListView
        labels={{
          bankName: <Trans>Bank</Trans>,
          mfo: <Trans>Bank Code (MFO)</Trans>,
          payerAccount: <Trans>Account number</Trans>
        }}
        data={{
          bankName,
          mfo,
          payerAccount
        }}
      />
    </Box>
  );
};

type MedicalProgramsProps = RouteComponentProps<{
  id: string;
}>;

const MedicalPrograms = ({ id }: MedicalProgramsProps) => (
  <Box>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        return (
          <>
            <Query
              query={ReimbursementContractRequestQuery}
              variables={{
                id
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{
                reimbursementContractRequest: ReimbursementContractRequest;
              }>) => (
                <LoadingOverlay loading={loading}>
                  {isEmpty(data) ||
                  isEmpty(data.reimbursementContractRequest) ||
                  isEmpty(data.reimbursementContractRequest.medicalPrograms) ? (
                    <EmptyData />
                  ) : (
                    <>
                      <Table
                        hidePagination
                        data={data.reimbursementContractRequest.medicalPrograms}
                        header={{
                          databaseId: <Trans>ID</Trans>,
                          name: <Trans>Medical program name</Trans>,
                          isActive: <Trans>Status</Trans>,
                          type: <Trans>Type</Trans>,
                          medicationRequestAllowed: (
                            <Trans>Medication request allowed</Trans>
                          ),
                          medicationDispenseAllowed: (
                            <Trans>Medication dispense allowed</Trans>
                          ),
                          action: <Trans>Action</Trans>
                        }}
                        renderRow={({
                          id,
                          isActive,
                          name,
                          type,
                          medicationRequestAllowed,
                          medicationDispenseAllowed,
                          ...medicalProgram
                        }: MedicalProgram) => ({
                          ...medicalProgram,
                          name,
                          isActive: (
                            <Badge
                              type="ACTIVE_STATUS_F"
                              name={isActive}
                              variant={!isActive}
                              display="block"
                            />
                          ),
                          type: (
                            <DictionaryValue
                              name="MEDICAL_PROGRAM_TYPE"
                              item={type}
                            />
                          ),
                          medicationRequestAllowed: (
                            <Flex justifyContent="center">
                              {medicationRequestAllowed ? (
                                <PositiveIcon />
                              ) : (
                                <NegativeIcon />
                              )}
                            </Flex>
                          ),
                          medicationDispenseAllowed: (
                            <Flex justifyContent="center">
                              {medicationDispenseAllowed ? (
                                <PositiveIcon />
                              ) : (
                                <NegativeIcon />
                              )}
                            </Flex>
                          ),
                          action: (
                            <LinkComponent
                              to={`../../../../medical-programs/${id}`}
                              fontWeight="bold"
                            >
                              <Trans>Details</Trans>
                            </LinkComponent>
                          )
                        })}
                        sortableFields={["name", "insertedAt"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        whiteSpaceNoWrap={["databaseId"]}
                        tableName="medicalPrograms/search"
                        hiddenFields="medicationDispenseAllowed,medicationRequestAllowed"
                      />
                    </>
                  )}
                </LoadingOverlay>
              )}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

type DivisionsProps = RouteComponentProps & {
  contractorDivisions: ReimbursementContractRequest["contractorDivisions"];
};

const Divisions = ({ contractorDivisions }: DivisionsProps) =>
  !isEmpty(contractorDivisions) ? (
    <Table
      data={contractorDivisions}
      header={{
        name: <Trans>Division name</Trans>,
        addresses: <Trans>Address</Trans>,
        phones: (
          <>
            <Trans>Phone</Trans>
            <br />
            <Trans>Email</Trans>
          </>
        ),
        dlsVerified: <Trans>DLS Verification</Trans>,
        workingHours: <Trans>Work schedule</Trans>
      }}
      renderRow={({
        name,
        addresses = [],
        workingHours,
        phones = [],
        email,
        dlsVerified
      }: Division) => ({
        name,
        phones: (
          <>
            <Box>
              {phones
                .filter((a) => a && a.type === "MOBILE")
                .map((item) => item && item.number)[0] ||
                (phones && phones[0] && phones[0].number)}
            </Box>
            <Box>{email}</Box>
          </>
        ),
        dlsVerified: (
          // @ts-expect-error statuses boolean key
          <Flex justifyContent="center">{STATUSES.YES_NO[dlsVerified]}</Flex>
        ),
        workingHours:
          workingHours &&
          formatWorkingHours(WEEK_DAYS, workingHours).map(
            ({ day, hours }: { day: string; hours: string[][] }) => (
              <Box pb={2}>
                {day}: {hours.map((i) => i.join("-")).join(", ")}
              </Box>
            )
          ),
        addresses: addresses
          .filter((a) => a && a.type === "RESIDENCE")
          .map((item, key) => <AddressView data={item} key={key} />)
      })}
      tableName="/reimbursement-contract-requests/divisions"
      hiddenFields="workingHours"
      hidePagination
    />
  ) : (
    <EmptyData />
  );

type DocumentsProps = RouteComponentProps & {
  attachedDocuments: ReimbursementContractRequest["attachedDocuments"];
};

const Documents = ({ attachedDocuments }: DocumentsProps) => {
  if (isEmpty(attachedDocuments)) return <EmptyData />;

  return (
    <>
      {attachedDocuments.map((document) => (
        <Box m="2">
          <SaveLink href={document && document.url} target="_blank">
            <Box m={1} color="shiningKnight">
              <DefaultImageIcon />
            </Box>
            <Text color="rockmanBlue" lineHeight="1">
              <DictionaryValue
                name="CONTRACT_DOCUMENT"
                item={document && document.type}
              />
            </Text>
          </SaveLink>
        </Box>
      ))}
    </>
  );
};

type PrintButtonProps = {
  content: ReimbursementContractRequest["printoutContent"];
};

const PrintButton = ({ content }: PrintButtonProps) => (
  <Wrapper color="shiningKnight" onClick={() => printIframe(content)}>
    <Text color="rockmanBlue" fontWeight="bold" mr={1} fontSize="0">
      <Trans>Show printout form</Trans>
    </Text>
    <PrinterIcon />
  </Wrapper>
);

const Wrapper = system(
  {
    extend: Flex
  },
  { cursor: "pointer" },
  "color"
);

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none",
    fontSize: 14
  }
);

export default ReimbursementContractRequestDetails;
