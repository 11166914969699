import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import { Person, Party } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";

import { Heading, DocumentsTable, Table } from "./index";

type DocumentsInfoProps = {
  person: Person | Party;
  masterPerson: Person | any;
  additionalHeaders?: any;
  headerLabels?: any;
};

const DocumentsInfo = ({
  person,
  masterPerson,
  additionalHeaders = {},
  headerLabels
}: DocumentsInfoProps) => (
  <>
    <Heading>
      <Trans>Documents</Trans>
    </Heading>
    <DictionaryValue name="DOCUMENT_TYPE">
      {(documentsHeader: DictionaryAllValuesJson) => (
        <>
          {headerLabels && (
            <Table
              header={{
                id: null
              }}
              data={headerLabels}
              renderRow={({ id }) => ({
                id
              })}
              skipComparison
            />
          )}
          <DocumentsTable
            header={{ ...documentsHeader, ...additionalHeaders }}
            person={person.documents}
            masterPerson={masterPerson.documents}
          />
        </>
      )}
    </DictionaryValue>
  </>
);

DocumentsInfo.fragments = {
  entry: gql`
    fragment DocumentsInfo on Person {
      documents {
        type
        number
        issuedAt
        issuedBy
      }
    }
  `
};

export default DocumentsInfo;
