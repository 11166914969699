import React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloQueryResult, MutationFunction } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import system from "@edenlabllc/ehealth-system-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  DictionaryValue,
  DictionaryValueConnection,
  Scalars
} from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import paramToBase64 from "../../../../helpers/paramToBase64";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

type CreateDictionaryValuesProps = {
  parentId: Scalars["ID"]["input"];
  dictionaryId: Scalars["ID"]["input"];
  toggle: () => any;
  refetch: () => Promise<
    ApolloQueryResult<{ dictionaryValue: DictionaryValueConnection }>
  >;
};

type DictionaryValuesItem = {
  code: DictionaryValue["code"];
  description: DictionaryValue["description"];
  parentId: DictionaryValue["parentId"];
  dictionaryId: DictionaryValue["dictionaryId"];
};

type FormDataValues = {
  values: Partial<DictionaryValuesItem>[];
};

const CreateDictionaryValues = ({
  parentId,
  dictionaryId,
  toggle,
  refetch
}: CreateDictionaryValuesProps) => {
  const { i18n } = useLingui();
  const [error, setError] = React.useState<string | null>(null);

  return (
    <Mutation mutation={CreateDictionaryValuesMutation}>
      {(createDictionaryValues: MutationFunction) => {
        const submitMutationForm = async (data: FormDataValues) => {
          setError(null);

          const prepareData = (data: Partial<DictionaryValuesItem>[]) => {
            data.forEach((item: Partial<DictionaryValuesItem>) => {
              item.parentId = parentId;
              item.dictionaryId = paramToBase64("Dictionary", dictionaryId);
            });

            return data;
          };

          const dictionaryValues = prepareData(data.values);

          try {
            const response = await createDictionaryValues({
              variables: {
                input: {
                  dictionaryValues
                }
              }
            });
            if (
              response &&
              response.data &&
              response.data.createDictionaryValues
            ) {
              toggle();
              refetch();
            }
          } catch (error) {
            if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
              const errorTranslation = handleTranslateErrors(
                getErrorMessage(error),
                i18n
              );
              setError(errorTranslation);
            }
          }
        };

        return (
          <>
            <Form
              onSubmit={({
                ...data
              }: {
                values: Partial<DictionaryValuesItem>[];
              }) => {
                submitMutationForm(data);
              }}
              initialValues={{
                values: [{ code: "", description: "" }]
              }}
            >
              <Field.Array
                name="values"
                addText={<Trans id="Add another" />}
                removeButton={({ onClick }) => <RemoveIcon onClick={onClick} />}
                fields={({ name }: { name: string }) => (
                  <Trans
                    id="Enter value"
                    render={({ translation }) => (
                      <Flex mx={-1} key={name} width="100%">
                        <Box px={1} width={1 / 3}>
                          <Field.Text
                            name={`${name}.code`}
                            label={<Trans id="Key" />}
                            placeholder={translation}
                            parse={(value: string) => value.trim()}
                          />
                          <Validation.Required
                            field={`${name}.code`}
                            message="Required field"
                          />
                        </Box>
                        <Box px={1} width={2 / 3}>
                          <Field.Text
                            name={`${name}.description`}
                            label={<Trans id="Description" />}
                            placeholder={translation}
                          />
                          <Validation.Required
                            field={`${name}.description`}
                            message="Required field"
                          />
                        </Box>
                      </Flex>
                    )}
                  />
                )}
              />
              <Flex mx={-2} pt={5}>
                <Box px={2}>
                  <Button variant="blue" onClick={toggle}>
                    <Trans id="Return" />
                  </Button>
                </Box>
                <Box px={2}>
                  <Button variant="green">
                    <Trans id="Save and Return" />
                  </Button>
                </Box>
              </Flex>
            </Form>

            {error && (
              <UnpocessableEntityModalError errorMessage={error} isModalOpen />
            )}
          </>
        );
      }}
    </Mutation>
  );
};

const CreateDictionaryValuesMutation = gql`
  mutation CreateDictionaryValuesMutation(
    $input: CreateDictionaryValuesInput!
  ) {
    createDictionaryValues(input: $input) {
      dictionaryValues {
        id
        code
        description
        databaseId
        isActive
      }
    }
  }
`;

const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    case "Value is not unique by 'code'":
      return i18n._(t`Value is not unique by 'code'`);
    default:
      return errorMessage;
  }
};

const RemoveIcon = system(
  {
    extend: RemoveItemIcon,
    color: "redPigment",
    ml: 2
  },
  {
    alignSelf: "center"
  },
  "color",
  "space"
);

export default CreateDictionaryValues;
