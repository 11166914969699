import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { loader } from "graphql.macro";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Declaration, Person, Scalars } from "@edenlabllc/graphql-schema";

import AddressView from "../../../components/AddressView";
import Badge from "../../../components/Badge";
import * as Field from "../../../components/Field";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import pagination from "../../../helpers/pagination";
import {
  DECLARATION_SEARCH_PATTERN,
  DECLARATION_ID_PATTERN
} from "../../../constants/declarationSearchPatterns";

const PersonDeclarationsQuery = loader(
  "../../../graphql/PersonDeclarationsQuery.graphql"
);

type DeclarationsInfoProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const DeclarationsInfo = ({ id }: DeclarationsInfoProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const {
        first,
        last,
        after,
        before,
        orderBy,
        filter: { declarationSearch } = {}
      } = locationParams;

      const isRequestById =
        declarationSearch &&
        new RegExp(DECLARATION_ID_PATTERN).test(declarationSearch);
      const declarationRequest =
        !isEmpty(declarationSearch) &&
        (isRequestById
          ? { declarationId: declarationSearch }
          : { declarationNumber: declarationSearch });

      return (
        <>
          <SearchDeclarationsForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
          />
          <Query
            query={PersonDeclarationsQuery}
            variables={{
              id,
              ...pagination({ first, last, after, before, orderBy }),
              filter: { ...declarationRequest }
            }}
          >
            {({ loading, data }: QueryResult<{ person: Person }>) => {
              const pageInfo =
                data &&
                data.person &&
                data.person.declarations &&
                data.person.declarations.pageInfo;
              const declarations =
                isEmpty(data) || !data.person.declarations
                  ? []
                  : data.person.declarations.nodes;
              return (
                <LoadingOverlay loading={loading}>
                  {declarations!.length > 0 && (
                    <>
                      <Table
                        data={declarations}
                        header={{
                          databaseId: <Trans>Declaration ID</Trans>,
                          declarationNumber: <Trans>Declaration number</Trans>,
                          startDate: <Trans>Declaration valid from</Trans>,
                          name: <Trans>Legal entity</Trans>,
                          edrpou: <Trans>EDRPOU</Trans>,
                          divisionName: <Trans>Division name</Trans>,
                          address: <Trans>Address</Trans>,
                          status: <Trans>Status</Trans>,
                          action: <Trans>Action</Trans>
                        }}
                        renderRow={({
                          id: declarationId,
                          databaseId,
                          declarationNumber,
                          startDate,
                          legalEntity: { edrpou, name, addresses },
                          division: { name: divisionName },
                          status
                        }: Declaration) => {
                          const [residenceAddress] =
                            (addresses &&
                              addresses.filter(
                                (a) => a && a.type === "RESIDENCE"
                              )) ||
                            [];
                          return {
                            databaseId,
                            declarationNumber,
                            name,
                            edrpou,
                            divisionName,
                            startDate: i18n.date(startDate),
                            address: residenceAddress && (
                              <AddressView data={residenceAddress} />
                            ),
                            status: (
                              <Badge
                                name={status}
                                type="DECLARATION"
                                display="block"
                              />
                            ),
                            action: (
                              <Link
                                to={`/declarations/${declarationId}`}
                                fontWeight="bold"
                              >
                                <Trans>Show details</Trans>
                              </Link>
                            )
                          };
                        }}
                        sortableFields={["startDate", "status"]}
                        sortingParams={parseSortingParams(orderBy)}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        whiteSpaceNoWrap={["databaseId"]}
                        hiddenFields="databaseId"
                        tableName="person-details/declarations"
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

export default DeclarationsInfo;

type SearchDeclarationsFormProps = {
  initialValues: SearchParams;
  onSubmit: SetSearchParams;
};

const SearchDeclarationsForm = ({
  initialValues,
  onSubmit
}: SearchDeclarationsFormProps) => {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      <Flex>
        <Box px={5} pt={5} width={460}>
          <Trans
            id="Enter ID or Declaration number"
            render={({ translation }) => (
              <Field.Text
                name="filter.declarationSearch"
                label={<Trans id="Declaration search" />}
                placeholder={translation}
                postfix={<SearchIcon color="silverCity" />}
              />
            )}
          />
          <Validation.Matches
            field="filter.declarationSearch"
            options={DECLARATION_SEARCH_PATTERN}
            message="Invalid number"
          />
        </Box>
      </Flex>
      <input type="submit" hidden />
    </Form>
  );
};
