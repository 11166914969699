import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { DictionaryConnection } from "@edenlabllc/graphql-schema";

import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import DictionariesTable from "./DictionariesTable";
import { PrimarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Dictionaries</Trans>
    </Heading>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <>
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={PrimarySearchFields}
          />
          <Query
            query={SearchDictionariesQuery}
            variables={filteredLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{ dictionaries: DictionaryConnection }>) => {
              if (isEmpty(data) || isEmpty(data.dictionaries)) return null;
              const {
                dictionaries: { nodes: dictionaries = [], pageInfo }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  <DictionariesTable dictionaries={dictionaries} />
                  <Pagination pageInfo={pageInfo} />
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      )}
    </LocationParams>
  </Box>
);

export default Search;

const SearchDictionariesQuery = gql`
  query SearchDictionariesQuery(
    $filter: DictionaryFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    dictionaries(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        ...Dictionaries
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${DictionariesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;
