import React from "react";
import { NavigateFn, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams, Modal } from "@edenlabllc/ehealth-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Medication, MedicationConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../components/Badge";
import Button from "../../components/Button";
import Close from "../../components/Close";
import DictionaryValue from "../../components/DictionaryValue";
import EmptyData from "../../components/EmptyData";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Table, { SortingParams } from "../../components/Table";
import {
  TLocationParams,
  SearchParams,
  SetSearchParams
} from "../../components/SearchForm";

import dateFormatter from "../../helpers/dateFormatter";
import filteredLocationParams from "../../helpers/filteredLocationParams";
import { DATE_TIME_FORMAT } from "../../constants/dateFormats";

import {
  PrimarySearchFields,
  SecondarySearchFields
} from "../Medications/Search/SearchFields";

type MedicationModalFormProps = RouteComponentProps<{
  navigate: NavigateFn;
}>;

const MedicationModalForm = ({ navigate }: MedicationModalFormProps) => {
  const onClose = () => navigate!("../");

  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Modal backdrop width="90%">
          <Close onClick={onClose} />
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <PrimarySearchFields />
            <SecondarySearchFields />
            <Flex mx={-1} mt={4} justifyContent="flex-start">
              <Box px={1}>
                <Button variant="red" onClick={onClose}>
                  <Trans>Close</Trans>
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="blue">
                  <Trans>Search</Trans>
                </Button>
              </Box>
              {!isEmpty(locationParams.filter) && (
                <Box px={1}>
                  <Button
                    variant="link"
                    icon={RemoveItemIcon}
                    px={1}
                    type="reset"
                    onClick={() => {
                      setLocationParams({
                        ...locationParams,
                        filter: {},
                        searchRequest: null
                      });
                    }}
                  >
                    <Trans>Reset</Trans>
                  </Button>
                </Box>
              )}
            </Flex>
          </Form>
          <Query
            query={SearchMedicationsQuery}
            fetchPolicy="network-only"
            variables={filteredLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{ medications: MedicationConnection }>) => (
              <LoadingOverlay loading={loading}>
                {isEmpty(data && data.medications.nodes) ? (
                  <Box m={7}>
                    <EmptyData height={100} />
                  </Box>
                ) : (
                  <>
                    <MedicationsTable
                      locationParams={locationParams}
                      medications={data && data.medications.nodes}
                      setLocationParams={setLocationParams}
                    />
                    <Pagination pageInfo={data && data.medications.pageInfo} />
                  </>
                )}
              </LoadingOverlay>
            )}
          </Query>
        </Modal>
      )}
    </LocationParams>
  );
};

type MedicationsTableProps = {
  medications: MedicationConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const MedicationsTable = ({
  medications,
  locationParams,
  setLocationParams
}: MedicationsTableProps) => (
  <Table
    data={medications}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Medication name</Trans>,
      innmDosageId: <Trans>InnmDosage ID</Trans>,
      innmDosageName: <Trans>InnmDosage name</Trans>,
      manufacturerName: <Trans>Manufacturer name</Trans>,
      manufacturerCountry: <Trans>Manufacturer country</Trans>,
      isActive: <Trans>Status</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      packageMinQty: <Trans>Package minimum quantity</Trans>,
      packageQty: <Trans>Package quantity</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={(medication: Medication) => {
      const {
        databaseId,
        insertedAt,
        isActive,
        name,
        ingredients,
        manufacturer,
        packageQty,
        packageMinQty
      } = medication;
      const { name: manufacturerName, country: manufacturerCountry } =
        manufacturer || {};
      const [primaryIngredient] = ingredients.filter((f) => f && f.isPrimary);
      const innmDosageName =
        primaryIngredient && primaryIngredient.innmDosage.name;
      const innmDosageId =
        primaryIngredient && primaryIngredient.innmDosage.databaseId;
      return {
        databaseId,
        name,
        innmDosageId,
        innmDosageName,
        manufacturerName,
        packageQty,
        packageMinQty,
        manufacturerCountry: (
          <DictionaryValue name="COUNTRY" item={manufacturerCountry} />
        ),
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        action: (
          <Link to={`../`} state={{ medication }} fontWeight="bold">
            <Trans>Select</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["insertedAt", "name"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="medication/search"
    hiddenFields="innmDosageName"
  />
);

MedicationsTable.fragments = {
  entry: gql`
    fragment MedicationsProgramMedications on Medication {
      id
      databaseId
      name
      isActive
      packageQty
      packageMinQty
      ingredients {
        isPrimary
        innmDosage {
          databaseId
          name
        }
      }
      insertedAt
      manufacturer {
        country
        name
      }
    }
  `
};

const SearchMedicationsQuery = gql`
  query SearchMedicationsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: MedicationFilter
    $orderBy: MedicationOrderBy
    $skip: Boolean = false
  ) {
    medications(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...MedicationsProgramMedications
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${MedicationsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default MedicationModalForm;
