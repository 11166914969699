import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import {
  getFullName,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ContractorEmployeeDivision,
  GlobalBudgetContract
} from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Table, { SortingParams } from "../../../components/Table";
import Pagination from "../../../components/Pagination";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import { GlobalBudgetContractQuery } from "./Details";

type EmployeesProps = RouteComponentProps<{
  id: string;
}>;

const Employees = ({ id }: EmployeesProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => {
      const { first, last, after, before, orderBy, division } = locationParams;
      return (
        <>
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Box px={5} pt={5} width={460}>
              <Trans
                id="Enter division name"
                render={({ translation }) => (
                  <Field.Text
                    name="division.name"
                    label={<Trans id="Find by divison" />}
                    placeholder={translation}
                    postfix={<SearchIcon color="silverCity" />}
                  />
                )}
              />
            </Box>
          </Form>
          <Query
            query={GlobalBudgetContractQuery}
            variables={{
              id,
              ...pagination({ first, last, after, before, orderBy }),
              employeeFilter: { division }
            }}
          >
            {({
              loading,
              data
            }: QueryResult<{ globalBudgetContract: GlobalBudgetContract }>) => {
              if (
                isEmpty(data) ||
                isEmpty(data.globalBudgetContract) ||
                isEmpty(data.globalBudgetContract.contractorEmployeeDivisions)
              )
                return null;
              const {
                globalBudgetContract: {
                  contractorEmployeeDivisions: {
                    nodes: contractorEmployeeDivisions = [],
                    pageInfo
                  }
                }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  {contractorEmployeeDivisions!.length > 0 ? (
                    <>
                      <Table
                        data={contractorEmployeeDivisions}
                        header={{
                          databaseId: <Trans>ID</Trans>,
                          divisionName: <Trans>Division name</Trans>,
                          employeeName: <Trans>Name of employee</Trans>,
                          speciality: <Trans>Specialty</Trans>,
                          staffUnits: <Trans>Permanent unit</Trans>,
                          declarationLimit: <Trans>Declarations limit</Trans>
                        }}
                        renderRow={({
                          employee: { databaseId, party, additionalInfo },
                          division: { name: divisionName },
                          ...contractorEmployeeDivisions
                        }: ContractorEmployeeDivision) => ({
                          databaseId,
                          divisionName,
                          employeeName: getFullName(party),
                          speciality: (
                            <DictionaryValue
                              name="SPECIALITY_TYPE"
                              item={
                                additionalInfo &&
                                additionalInfo.specialities &&
                                additionalInfo.specialities.find(
                                  (item) => item && item.specialityOfficio
                                )!.speciality
                              }
                            />
                          ),
                          ...contractorEmployeeDivisions
                        })}
                        sortableFields={["staffUnits", "declarationLimit"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        tableName="/contract/employees"
                        whiteSpaceNoWrap={["databaseId"]}
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  ) : (
                    <EmptyData />
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      );
    }}
  </LocationParams>
);

export default Employees;
