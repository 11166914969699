import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { DeviceDefinition } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import { PROPERTIES_BOOLEAN_VALUES } from "../constants";

type PropertiesProps = RouteComponentProps & {
  properties: DeviceDefinition["properties"];
};

const Properties = ({ properties }: PropertiesProps) => {
  const { i18n } = useLingui();

  return (
    <Box p={5}>
      {properties &&
        properties.map((item, index: number) => {
          const type = item && item.type;
          const value =
            (item && item.valueString) ||
            (item && item.valueInteger) ||
            (item && item.valueDecimal) ||
            (item &&
              item.valueBoolean !== undefined &&
              item.valueBoolean !== null &&
              i18n._(PROPERTIES_BOOLEAN_VALUES[item.valueBoolean.toString()]));

          return (
            <React.Fragment key={`${type}-${value}`}>
              {index !== 0 && <Line />}
              <DefinitionListView
                labelWidth={200}
                labels={{
                  type: <Trans>Device definition properties type</Trans>,
                  value: <Trans>Device definition properties value</Trans>
                }}
                data={{
                  type: (
                    <DictionaryValue name="device_properties" item={type} />
                  ),
                  value
                }}
              />
            </React.Fragment>
          );
        })}
    </Box>
  );
};

Properties.fragments = {
  entry: gql`
    fragment DeviceDefinitionProperties on DeviceDefinition {
      properties {
        type
        valueBoolean
        valueDecimal
        valueInteger
        valueString
      }
    }
  `
};

export default Properties;
