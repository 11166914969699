import * as React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";

import {
  Dictionary,
  DictionaryValue as DictionaryValueType,
  Maybe
} from "@edenlabllc/graphql-schema";

const DictionaryByNameQuery = loader(
  "../graphql/DictionaryByNameQuery.graphql"
);

type DictionaryValueProps = {
  name: string;
  item?: Maybe<string> | Maybe<number>;
  children?: (data: { value: string }) => React.ReactNode;
  render?: any;
  withCode?: boolean;
};

const DictionaryValue = ({
  name = "",
  item = "",
  children,
  render = children,
  withCode = false
}: DictionaryValueProps) => (
  <Query
    fetchPolicy="cache-first"
    query={DictionaryByNameQuery}
    variables={{ dictionaryName: name, skip: !name }}
  >
    {({
      loading,
      error,
      data
    }: QueryResult<{ dictionaryByName: Dictionary }>) => {
      if (loading || error) return null;
      const { dictionaryByName } = data || {};

      const values =
        dictionaryByName &&
        convertDictionaryValuesArrayToObject(dictionaryByName.allValues);

      let value =
        typeof render !== "function" && values
          ? item && values[item]
            ? values[item]
            : item
          : values;

      if (withCode) {
        value = `${value} (${item})`;
      }

      return typeof render === "function"
        ? render(value)
        : value || <Trans>Not found</Trans>;
    }}
  </Query>
);

export default DictionaryValue;

export type DictionaryItem = {
  id: DictionaryValueType["id"];
  code: DictionaryValueType["code"];
  description: DictionaryValueType["description"];
};

export type DictionaryAllValuesJson = Record<string, string>;

export const convertDictionaryValuesArrayToObject = (
  array: Maybe<Maybe<DictionaryValueType>[]> | undefined
): DictionaryAllValuesJson | undefined => {
  if (!array) return;

  return array.reduce(
    (obj: DictionaryAllValuesJson, item: Maybe<DictionaryValueType>) => {
      if (!item) return {};

      if (item && item.code && item.description) {
        obj[item.code] = item.description;
      }
      return obj;
    },
    {}
  );
};
