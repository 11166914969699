import React from "react";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Text } from "@rebass/emotion";

import { Person, DracsDeathAct } from "@edenlabllc/graphql-schema";

import Popup from "../../../components/Popup";

import { toDashedDateFormat } from "../../../helpers/dateFormatter";

import GeneralInfoPatient from "../../PatientMergeRequests/Details/GeneralInfoPatient";
import DocumentsInfo from "../../PatientMergeRequests/Details/DocumentsInfo";
import AddressInfo from "../../PatientMergeRequests/Details/AddressInfo";
import { DeathAct, DracsDeathActQuery } from "./DracsDeathActPopup";

type PersonAndDeathActComparisonPopupProps = {
  isVisible: boolean;
  onCancel: () => void;
  person: Person;
  deathActId: string | null;
};

export const PersonAndDeathActComparisonPopup = ({
  isVisible,
  onCancel,
  person,
  deathActId
}: PersonAndDeathActComparisonPopupProps) => {
  return (
    <Popup
      title={<Trans>Candidate details</Trans>}
      visible={isVisible}
      onCancel={onCancel}
      hideOkButton
      textAlign="left"
      overflow="auto"
      width={920}
    >
      <Query query={DracsDeathActQuery} variables={{ id: deathActId }}>
        {({ data }: QueryResult<{ dracsDeathAct: DracsDeathAct }>) => {
          const dracsDeathAct = data && data.dracsDeathAct;
          if (!dracsDeathAct) return null;
          const { dracsDeathAct: { docSeizes = [] } = {} } = data || {};
          const generalInfo = {
            firstName: dracsDeathAct.name,
            secondName: dracsDeathAct.patronymic,
            lastName: dracsDeathAct.surname,
            birthDate:
              dracsDeathAct.dateBirth &&
              toDashedDateFormat(dracsDeathAct.dateBirth),
            birthCountry: dracsDeathAct.birthState,
            birthSettlement:
              dracsDeathAct.birthDistrict || dracsDeathAct.birthRegion,
            gender: dracsDeathAct.sex === "1" ? "MALE" : "FEMALE",
            taxId: dracsDeathAct.numident
          };

          const remappedDocSeizes = (docSeizes || []).map((item: any) => ({
            type: item.docType === "1" ? "PASSPORT" : "OTHER",
            ...(item.docName && {
              docName: item.docName
            }),
            number: item.seriesNumb,
            issuedAt: toDashedDateFormat(item.docDate),
            issuedBy: item.docOrgName
          }));

          const documentsInfo = { documents: remappedDocSeizes };
          const personAddresses = person.addresses.map((address) => {
            const { zip, ...rest } = address || {};
            return rest;
          });

          const dracsDeathActAddresses = [
            {
              type: "RESIDENCE",
              country: dracsDeathAct.state,
              area: dracsDeathAct.region,
              region: dracsDeathAct.district,
              settlement: dracsDeathAct.locality,
              settlementType: dracsDeathAct.localityType,
              settlementId: "CITY",
              streetType: "AVENUE",
              street: dracsDeathAct.street,
              building: dracsDeathAct.house,
              apartment: dracsDeathAct.apartment
            }
          ];
          const generalInfoComparisonData = [person, generalInfo];

          const additionalHeaders = remappedDocSeizes.reduce(
            (prev: any, current: any) => {
              if (current.docName && current.type === "OTHER")
                return { ...prev, [current.type]: current.docName };
              return;
            },
            {}
          );

          const TABLE_HEADER_LABELS = [
            {
              id: (
                <Text fontWeight="bold">
                  <Trans>Person under processing</Trans>
                </Text>
              )
            },
            {
              id: (
                <Text fontWeight="bold">
                  <Trans>Death act</Trans>
                </Text>
              )
            }
          ];

          return (
            <>
              <DeathAct
                data={dracsDeathAct}
                isDracsDeathCandidatesDetailsPopup
              />
              <GeneralInfoPatient
                data={generalInfoComparisonData}
                isDracsDeathCandidatesDetailsPopup
                headerLabels={TABLE_HEADER_LABELS}
              />
              <DocumentsInfo
                person={person}
                masterPerson={documentsInfo}
                additionalHeaders={additionalHeaders}
                headerLabels={TABLE_HEADER_LABELS}
              />
              <Box mb={5}>
                <AddressInfo
                  personAddresses={personAddresses}
                  masterPersonAddresses={dracsDeathActAddresses}
                  headerLabels={TABLE_HEADER_LABELS}
                />
              </Box>
            </>
          );
        }}
      </Query>
    </Popup>
  );
};
