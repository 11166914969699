import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { gql } from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Box, Heading } from "@rebass/emotion";
import { Trans, Plural } from "@lingui/macro";
import { i18n } from "@lingui/core";
import differenceInSeconds from "date-fns/difference_in_seconds";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  PersonsAuthResetJob,
  PersonsAuthResetJobConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import filteredLocationParams from "../../../helpers/filteredLocationParams";
import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { PrimarySearchFields } from "./SearchFields";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Reset persons authentication method jobs</Trans>
    </Heading>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { orderBy } = locationParams;
        return (
          <>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
            />
            <Query
              query={ResetPersonsAuthMethodJobsQuery}
              fetchPolicy="network-only"
              variables={filteredLocationParams(locationParams)}
            >
              {({
                loading,
                data
              }: QueryResult<{
                personsAuthResetJobs: PersonsAuthResetJobConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.personsAuthResetJobs))
                  return null;
                const {
                  personsAuthResetJobs: {
                    nodes: personsAuthResetJobs = [],
                    pageInfo
                  }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <Table
                      data={personsAuthResetJobs}
                      header={{
                        databaseId: <Trans>ID</Trans>,
                        name: <Trans>Job name</Trans>,
                        startedAt: <Trans>Started at</Trans>,
                        executionTime: <Trans>Execution time</Trans>,
                        status: <Trans>Job status</Trans>,
                        details: <Trans>Details</Trans>
                      }}
                      renderRow={({
                        id,
                        databaseId,
                        status,
                        startedAt,
                        endedAt
                      }: PersonsAuthResetJob) => ({
                        databaseId,
                        name: (
                          <Trans>Reset persons authentication method</Trans>
                        ),
                        startedAt: dateFormatter(
                          i18n.locale,
                          DATE_TIME_FORMAT,
                          startedAt
                        ),
                        executionTime:
                          status === "PENDING" || !endedAt || !startedAt ? (
                            "-"
                          ) : (
                            <Plural
                              id="{0, plural, zero {# секунд} one {# секунда} few {# секунди} many {# секунд} other {# секунд}}"
                              value={differenceInSeconds(endedAt, startedAt)}
                              zero="# seconds"
                              one="# second"
                              few="# seconds"
                              many="# seconds"
                              other="# seconds"
                            />
                          ),
                        status: (
                          <Badge type="JOBS" name={status} display="block" />
                        ),
                        details: (
                          <Link to={`../${id}`} fontWeight="bold">
                            <Trans>Show details</Trans>
                          </Link>
                        )
                      })}
                      sortableFields={["startedAt"]}
                      sortingParams={parseSortingParams(orderBy)}
                      onSortingChange={(sortingParams: SortingParams) =>
                        setLocationParams({
                          ...locationParams,
                          orderBy: stringifySortingParams(sortingParams)
                        })
                      }
                      tableName="persons-auth-reset-jobs/search"
                    />
                    <Pagination pageInfo={pageInfo} />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

const ResetPersonsAuthMethodJobsQuery = gql`
  query ResetPersonsAuthMethodJobsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: PersonsAuthResetJobFilter
    $orderBy: PersonsAuthResetJobOrderBy
  ) {
    personsAuthResetJobs(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        id
        databaseId
        name
        status
        startedAt
        endedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export default Search;
