import React from "react";
import { gql } from "graphql-tag";
import { isEmpty } from "lodash";
import { Trans } from "@lingui/macro";

import { Maybe, PersonAuthenticationMethod } from "@edenlabllc/graphql-schema";

import AuthMethodsList from "../../../components/AuthMethodsList";
import Link from "../../../components/Link";

import { Heading, Table } from "./index";

type AuthenticationMethods = {
  ownAuthMethod: Maybe<PersonAuthenticationMethod>[];
  thirdPesonAuthMethods: Maybe<PersonAuthenticationMethod>[];
};

type AuthenticationMethodsInfoProps = {
  data: AuthenticationMethods[];
};

const AuthenticationMethodsInfo = ({
  data
}: AuthenticationMethodsInfoProps) => (
  <>
    <Heading>
      <Trans>Authentication methods</Trans>
    </Heading>
    <Table
      data={data}
      header={{
        ownAuthMethod: <Trans>Own authentication method</Trans>,
        thirdPesonAuthMethods: <Trans>Authorization through third person</Trans>
      }}
      renderRow={({
        ownAuthMethod,
        thirdPesonAuthMethods
      }: AuthenticationMethods) => ({
        ownAuthMethod:
          ownAuthMethod && ownAuthMethod.length ? (
            <AuthMethodsList data={ownAuthMethod} />
          ) : (
            "-"
          ),
        thirdPesonAuthMethods:
          thirdPesonAuthMethods && thirdPesonAuthMethods.length
            ? thirdPesonAuthMethods.map((item) => {
                if (isEmpty(item) || isEmpty(item.thirdPerson)) return "-";
                return (
                  <p>
                    <Link
                      to={`/persons/${item.thirdPerson.id}`}
                      fontWeight="bold"
                    >
                      <Trans>Third person details</Trans>
                    </Link>
                  </p>
                );
              })
            : "-"
      })}
    />
  </>
);

AuthenticationMethodsInfo.fragments = {
  entry: gql`
    fragment AuthenticationMethodsInfo on Person {
      authenticationMethods(filter: $filter, first: $first) {
        nodes {
          type
          phoneNumber
          thirdPerson {
            id
          }
        }
      }
    }
  `
};

export default AuthenticationMethodsInfo;
