import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { DeviceDefinitionConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import EmptyData from "../../../components/EmptyData";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import filteredLocationParams from "../../../helpers/filteredLocationParams";
import DeviceDefinitionsTable from "./DeviceDefinitionsTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = ({ navigate }: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <>
          <Flex justifyContent="space-between" alignItems="flex-start" mb={6}>
            <Box>
              <Heading as="h1" fontWeight="normal" mb={4}>
                <Trans>Device definitions</Trans>
              </Heading>
            </Box>
            <Ability action="write" resource="device_definition">
              <Box>
                <Button onClick={() => navigate!("../create")} variant="green">
                  <Trans>Create device definitions</Trans>
                </Button>
              </Box>
            </Ability>
          </Flex>
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={PrimarySearchFields}
            renderSecondary={SecondarySearchFields}
          />
          <Query
            query={SearchDeviceDefinitionsQuery}
            fetchPolicy="network-only"
            variables={filteredLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{
              deviceDefinitions: DeviceDefinitionConnection;
            }>) => {
              return (
                <LoadingOverlay loading={loading}>
                  {isEmpty(data) ||
                  isEmpty(data.deviceDefinitions) ||
                  isEmpty(data.deviceDefinitions.nodes) ? (
                    <EmptyData />
                  ) : (
                    <>
                      <DeviceDefinitionsTable
                        locationParams={locationParams}
                        deviceDefinitions={data.deviceDefinitions.nodes}
                        setLocationParams={setLocationParams}
                      />
                      <Pagination pageInfo={data.deviceDefinitions.pageInfo} />
                    </>
                  )}
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      )}
    </LocationParams>
  </Box>
);

const SearchDeviceDefinitionsQuery = gql`
  query SearchDeviceDefinitionsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: DeviceDefinitionFilter
    $orderBy: DeviceDefinitionOrderBy
    $skip: Boolean = false
  ) {
    deviceDefinitions(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...DeviceDefinition
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${DeviceDefinitionsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
