import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";

import Search from "./Search";
import Details from "./Details";
import Create from "./Search/Mutations/Create";

const DeviceDefinitions = ({ uri }: RouteComponentProps) => (
  <Router>
    <Redirect from="/" to={`${uri}/search`} />
    <Create path="create/*" />
    <Search path="search/*" />
    <Details path=":id/*" />
  </Router>
);

export default DeviceDefinitions;
