import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { MedicationRequest } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import FullName from "../../../components/FullName";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";
import dateFormatter from "../../../helpers/dateFormatter";

import GeneralInfo from "./GeneralInfo";

type DetailsProps = RouteComponentProps<{
  id: string;
}>;

const Details = ({ id, uri }: DetailsProps) => {
  const { i18n } = useLingui();

  return (
    <Ability action="details" resource="medication_request_admin">
      <Query
        query={AdminMedicationRequestByIDQuery}
        fetchPolicy="network-only"
        variables={{ id }}
      >
        {({
          loading,
          data
        }: QueryResult<{ adminMedicationRequest: MedicationRequest }>) => {
          const medicationRequest = data && data.adminMedicationRequest;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/medication-requests">
                      <Trans>Medication requests</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Medication request details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>

                {!isEmpty(medicationRequest) && (
                  <DefinitionListView
                    labels={{
                      databaseId: <Trans>ID</Trans>,
                      status: <Trans>Status</Trans>,
                      insertedAt: <Trans>Inserted at</Trans>,
                      updatedAt: <Trans>Updated at</Trans>
                    }}
                    data={{
                      ...medicationRequest,
                      status: (
                        <Badge
                          name={medicationRequest.status}
                          dictionary="eHealth/medication_request_statuses"
                          minWidth={100}
                        />
                      ),
                      insertedAt: dateFormatter(
                        i18n.locale,
                        DATE_TIME_FORMAT,
                        medicationRequest.createdAt
                      ),
                      updatedAt: dateFormatter(
                        i18n.locale,
                        DATE_TIME_FORMAT,
                        medicationRequest.updatedAt
                      )
                    }}
                    color="#7F8FA4"
                    labelWidth="120px"
                  />
                )}
              </Box>

              <Tabs.Nav>
                <Tabs.NavItem to={"./general-info"}>
                  <Trans>General info</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>

              <Tabs.Content>
                <Router>
                  <Redirect from="/" to={`${uri}/general-info`} />
                  <GeneralInfo
                    path="general-info"
                    medicationRequest={medicationRequest}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    </Ability>
  );
};

export default Details;

export const AdminMedicationRequestByIDQuery = gql`
  query AdminMedicationRequestByIDQuery($id: ID!) {
    adminMedicationRequest(id: $id) {
      id
      databaseId
      status
      requestNumber
      intent
      isBlocked
      category
      legalEntity {
        databaseId
        name
        id
      }
      division {
        databaseId
        name
        id
      }
      employee {
        databaseId
        party {
          id
          ...FullName
        }
        id
      }
      medication {
        databaseId
        name
        id
      }
      medicationQty
      medicationRequestRequestsId
      medicalProgram {
        databaseId
        name
        id
      }
      dispenseValidFrom
      dispenseValidTo
      createdAt
      startedAt
      endedAt
      patientId
      rejectReason
      rejectedAt
      rejectedBy
      createdAt
      updatedAt
    }
  }
  ${FullName.fragments.entry}
`;
