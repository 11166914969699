import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Text } from "@rebass/emotion";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { ProgramDevice } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

type UpdateProgramDeviceProps = {
  id: ProgramDevice["id"];
  isActive: ProgramDevice["isActive"];
  deviceRequestAllowed: ProgramDevice["deviceRequestAllowed"];
  carePlanActivityAllowed: ProgramDevice["carePlanActivityAllowed"];
  isDefinitionListView?: boolean;
};

const UpdateProgramDevice = ({
  id,
  isActive,
  deviceRequestAllowed,
  carePlanActivityAllowed,
  isDefinitionListView = false
}: UpdateProgramDeviceProps) => {
  const [error, setError] = useState(null);

  return (
    <>
      <Mutation mutation={UpdateProgramDeviceMutation}>
        {(updateProgramDevice: MutationFunction) => (
          <>
            {!isDefinitionListView && (
              <Box>
                <Button
                  variant={isActive ? "red" : "blue"}
                  disabled={
                    isActive &&
                    (deviceRequestAllowed || carePlanActivityAllowed)
                  }
                  onClick={async () => {
                    try {
                      await updateProgramDevice({
                        variables: {
                          input: {
                            id,
                            isActive: !isActive
                          }
                        }
                      });
                    } catch (error) {
                      if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                        setError(getErrorMessage(error));
                      }
                    }
                  }}
                >
                  {isActive ? (
                    <Trans>Deactivate</Trans>
                  ) : (
                    <Trans>Activate</Trans>
                  )}
                </Button>
              </Box>
            )}
            {isDefinitionListView && (
              <Box>
                <DefinitionListView
                  labels={{
                    deviceRequestAllowed: <Trans>Device request allowed</Trans>,
                    carePlanActivityAllowed: (
                      <Trans>Device care plan activity allowed</Trans>
                    )
                  }}
                  data={{
                    deviceRequestAllowed: (
                      <Permission
                        allowed={deviceRequestAllowed}
                        isActive={isActive}
                        onClick={async () => {
                          await updateProgramDevice({
                            variables: {
                              input: {
                                id,
                                deviceRequestAllowed: !deviceRequestAllowed
                              }
                            }
                          });
                        }}
                      />
                    ),
                    carePlanActivityAllowed: (
                      <Permission
                        allowed={carePlanActivityAllowed}
                        isActive={isActive}
                        onClick={async () => {
                          await updateProgramDevice({
                            variables: {
                              input: {
                                id,
                                carePlanActivityAllowed:
                                  !carePlanActivityAllowed
                              }
                            }
                          });
                        }}
                      />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="230px"
                />
              </Box>
            )}
          </>
        )}
      </Mutation>
      {error && (
        <UnpocessableEntityModalError
          errorMessage={error}
          isModalOpen
          handleClearError={() => setError(null)}
        />
      )}
    </>
  );
};

type PermissionProps = {
  allowed: boolean;
  isActive: boolean;
  onClick: () => void;
};

const Permission = ({ allowed, isActive, onClick }: PermissionProps) => (
  <Box>
    <Flex align="center">
      <Box>{allowed ? <PositiveIcon /> : <NegativeIcon />}</Box>
      <Ability action="write" resource="program_device">
        <Button
          variant="none"
          border="none"
          px="0"
          py="0"
          onClick={onClick}
          disabled={!isActive}
        >
          <Text
            color={isActive ? "rockmanBlue" : "silverCity"}
            fontWeight="bold"
            ml={1}
          >
            {allowed ? (
              <Trans>Disallow Request</Trans>
            ) : (
              <Trans>Allow Request</Trans>
            )}
          </Text>
        </Button>
      </Ability>
    </Flex>
  </Box>
);

export const UpdateProgramDeviceMutation = gql`
  mutation UpdateProgramDeviceMutation($input: UpdateProgramDeviceInput!) {
    updateProgramDevice(input: $input) {
      programDevice {
        id
        isActive
        deviceRequestAllowed
        startDate
        endDate
        registryNumber
        reimbursement {
          reimbursementAmount
          percentageDiscount
        }
        wholesalePrice
        consumerPrice
        estimatedPaymentAmount
        reimbursementDailyCount
        carePlanActivityAllowed
      }
    }
  }
`;

export default UpdateProgramDevice;
