import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { getFullName } from "@edenlabllc/ehealth-utils";
import { Conclusion, ConclusionConnection } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../components/DictionaryValue";
import ExpandableText from "../../components/ExpandableText";
import FullName from "../../components/FullName";
import Line from "../../components/Line";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import { TLocationParams } from "../../components/SearchForm";

import dateFormatter from "../../helpers/dateFormatter";
import fromBase64toUUID from "../../helpers/fromBase64toUUID";
import pagination from "../../helpers/pagination";
import { DATE_TIME_FORMAT } from "../../constants/dateFormats";

const FULL_SCREEN_HEIGHT_LIMIT = 1000;

type ConclusionsProps = {
  patientId: string;
  itemId: string;
  isClinicalMonitoring: boolean;
};

const Conclusions = ({
  isClinicalMonitoring,
  patientId,
  itemId
}: ConclusionsProps) => {
  const { i18n } = useLingui();
  if (!isClinicalMonitoring) return null;

  const headerProps = {
    fontSize: 14,
    fontWeight: "bold",
    color: "darkCharcoal"
  };

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <Query
          query={ConclusionsQuery}
          fetchPolicy="network-only"
          variables={{
            ...pagination(locationParams),
            orderBy: locationParams.orderBy
              ? locationParams.orderBy
              : "INSERTED_AT_DESC",
            filter: {
              patientId,
              resourceId: fromBase64toUUID(itemId)
            }
          }}
        >
          {({
            data,
            loading
          }: QueryResult<{ conclusions: ConclusionConnection }>) => {
            if (isEmpty(data) || isEmpty(data.conclusions)) return null;
            const {
              conclusions: { nodes = [] }
            } = data;
            if (nodes!.length === 0) return null;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  <Line />
                  <Text {...headerProps}>
                    <Trans>Conclusion</Trans>
                  </Text>
                  <Table
                    data={nodes}
                    header={{
                      conclusionType: <Trans>Conclusion</Trans>,
                      insertedByParty: <Trans>Conclusion author</Trans>,
                      comment: <Trans>Comment</Trans>,
                      insertedAt: <Trans>Added</Trans>
                    }}
                    renderRow={({
                      conclusionType,
                      comment,
                      insertedAt,
                      insertedByParty
                    }: Conclusion) => ({
                      conclusionType: (
                        <DictionaryValue
                          name="eHealth/clinical_monitoring_conclusion"
                          item={conclusionType.toLowerCase()}
                        />
                      ),
                      insertedByParty:
                        insertedByParty && getFullName(insertedByParty),
                      comment: (
                        <ExpandableText
                          text={comment}
                          fixedAlign
                          fullScreenHeight={
                            comment.length > FULL_SCREEN_HEIGHT_LIMIT
                          }
                        />
                      ),
                      insertedAt: dateFormatter(
                        i18n.locale,
                        DATE_TIME_FORMAT,
                        insertedAt
                      )
                    })}
                  />
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  );
};

export default Conclusions;

const ConclusionsQuery = gql`
  query ConclusionsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ConclusionFilter!
    $orderBy: ConclusionOrderBy
  ) {
    conclusions(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        conclusionType
        comment
        insertedAt
        insertedByParty {
          id
          ...FullName
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${FullName.fragments.entry}
`;
