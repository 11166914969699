import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Scalars, MedicalProgram } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Badge from "../../../components/Badge";
import DefinitionListView from "../../../components/DefinitionListView";

import Deactivate from "./Mutations/Deactivate";

type HeaderProps = {
  id: Scalars["ID"]["output"];
  isActive: MedicalProgram["isActive"];
  databaseId: Scalars["UUID"];
};

const Header = ({ id, databaseId, isActive }: HeaderProps) => (
  <Flex justifyContent="space-between" alignItems="flex-end">
    <Box>
      <DefinitionListView
        labels={{
          databaseId: <Trans>ID</Trans>,
          status: <Trans>Status</Trans>
        }}
        data={{
          databaseId,
          status: (
            <Badge
              type="ACTIVE_STATUS_F"
              name={isActive}
              variant={!isActive}
              minWidth={100}
            />
          )
        }}
        color="#7F8FA4"
        labelWidth="100px"
      />
    </Box>
    <Flex justifyContent="flex-end" flexWrap="wrap">
      {isActive && (
        <Ability action="deactivate" resource="medical_program">
          <Deactivate id={id} isActive={isActive} />
        </Ability>
      )}
    </Flex>
  </Flex>
);

Header.fragments = {
  entry: gql`
    fragment MedicalProgramHeader on MedicalProgram {
      databaseId
      isActive
      medicalProgramSettingsText
    }
  `
};

export default Header;
