import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { convertStringToBoolean } from "@edenlabllc/ehealth-utils";
import { InnmDosageConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import INNMDosagesTable from "./INNMDosagesTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = ({ navigate }: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          filter: { innmdosage, isActive, dosageFormIsDosed, ...params } = {}
        } = locationParams;

        const innmDosagesFilter = {
          ...params,
          name: innmdosage && innmdosage.name,
          isActive: convertStringToBoolean(isActive),
          dosageFormIsDosed:
            dosageFormIsDosed === "null"
              ? null
              : convertStringToBoolean(dosageFormIsDosed)
        };

        return (
          <>
            <Flex justifyContent="space-between" alignItems="flex-start" mb={6}>
              <Box>
                <Heading as="h1" fontWeight="normal" mb={4}>
                  <Trans>INNM dosages</Trans>
                </Heading>
              </Box>
              <Ability action="write" resource="innm_dosage">
                <Box>
                  <Button
                    onClick={() => navigate!("../create")}
                    variant="green"
                  >
                    <Trans>Create INNM Dosage</Trans>
                  </Button>
                </Box>
              </Ability>
            </Flex>
            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              query={SearchINNMDosagesQuery}
              fetchPolicy="network-only"
              variables={{
                ...filteredLocationParams(locationParams),
                filter: innmDosagesFilter
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ innmDosages: InnmDosageConnection }>) => {
                if (isEmpty(data) || isEmpty(data.innmDosages)) return null;
                const {
                  innmDosages: { nodes: innmDosages = [], pageInfo }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    <>
                      <INNMDosagesTable
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                        innmDosages={innmDosages}
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export const SearchINNMDosagesQuery = gql`
  query SearchINNMDosagesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: INNMDosageFilter
    $orderBy: INNMDosageOrderBy
    $skip: Boolean! = false
  ) {
    innmDosages(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...INNMDosages
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${INNMDosagesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
