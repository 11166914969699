import React, { useReducer, useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import { DropDownButton as PlusIcon } from "@edenlabllc/ehealth-icons";
import { DeviceDefinition } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";
import { NamesForm } from "../../Search/Mutations/Create";
import { RemoveButton } from "../../../../components/RemoveButton";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

type AddDeviceNameProps = {
  id: DeviceDefinition["id"];
  deviceNames: DeviceDefinition["deviceNames"];
};

export const AddDeviceName = ({ id, deviceNames }: AddDeviceNameProps) => {
  const { i18n } = useLingui();
  const [isVisible, toggle] = useReducer((v) => !v, false);
  const [error, setError] = useState<string | null>(null);

  return (
    <Ability action="write" resource="device_definition">
      <Mutation mutation={AddDeviceDefinitionMutation}>
        {(updateDeviceDefinition: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="link" mt={30} ml={-4}>
              <Flex alignItems="center">
                <Box mr={2}>
                  <PlusIcon width={16} height={16} />
                </Box>
                <Trans>Add name</Trans>
              </Flex>
            </Button>
            <Popup
              visible={isVisible}
              onCancel={toggle}
              title={
                <>
                  <Trans>Add device definition names</Trans>
                </>
              }
              okText={<Trans>Add name</Trans>}
              okButtonProps={{ variant: "green" }}
              justifyButtons="left"
              formId="addDeviceDefinitionForm"
              textAlign="left"
            >
              <Form
                id="addDeviceDefinitionForm"
                initialValues={{
                  deviceNames: [{ type: "", name: "" }]
                }}
                onSubmit={async ({ ...data }) => {
                  setError(null);

                  const existingDeviceNames = deviceNames.map((item) => ({
                    type: item && item.type,
                    name: item && item.name
                  }));

                  const mergedData = [
                    ...existingDeviceNames,
                    ...data.deviceNames
                  ];

                  try {
                    toggle();
                    await updateDeviceDefinition({
                      variables: {
                        input: {
                          id,
                          deviceNames: mergedData
                        }
                      }
                    });
                  } catch (error) {
                    if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                      const errorTranslation = handleTranslateErrors(
                        getErrorMessage(error),
                        i18n
                      );
                      setError(errorTranslation);
                    }
                  }
                }}
              >
                <Box mb={30}>
                  <Field.Array
                    name="deviceNames"
                    addText={<Trans>Add name</Trans>}
                    removeButton={({ onClick }) => (
                      <RemoveButton onClick={onClick} />
                    )}
                    fields={NamesForm}
                    firstItemPinned
                  />
                </Box>
              </Form>
            </Popup>

            {error && (
              <UnpocessableEntityModalError errorMessage={error} isModalOpen />
            )}
          </>
        )}
      </Mutation>
    </Ability>
  );
};

const AddDeviceDefinitionMutation = gql`
  mutation AddDeviceDefinitionMutation($input: UpdateDeviceDefinitionInput!) {
    updateDeviceDefinition(input: $input) {
      deviceDefinition {
        id
        deviceNames {
          name
          type
        }
      }
    }
  }
`;

export const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    case "Values are not unique by 'type'.":
      return i18n._(t`Values are not unique by 'type'.`);
    default:
      return errorMessage;
  }
};
