import React, { useState, useCallback } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloQueryResult, MutationFunction } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import {
  Form,
  SUBMIT_ERROR,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import { Approval, ApprovalConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import * as Field from "../../../components/Field";
import Popup from "../../../components/Popup";

type ActionsProps = {
  isVerified: Approval["isVerified"];
  id: string;
  personId: string;
  refetch: () => Promise<ApolloQueryResult<{ approvals: ApprovalConnection }>>;
  authenticationMethodCurrent: Approval["authenticationMethodCurrent"] | any;
  expiresAt: Approval["expiresAt"];
};

const Actions = ({
  isVerified,
  id,
  personId,
  refetch,
  authenticationMethodCurrent: { phoneNumber, type },
  expiresAt
}: ActionsProps) => {
  const { i18n } = useLingui();

  const [isVisible, setVisibilityState] = useState<{
    verifyPopup: boolean;
    sendSmsPopup: boolean;
    cancelPopup: boolean;
  }>({
    verifyPopup: false,
    sendSmsPopup: false,
    cancelPopup: false
  });
  const toggle = (popup: "verifyPopup" | "sendSmsPopup" | "cancelPopup") =>
    setVisibilityState({
      ...isVisible,
      [popup]: !isVisible[popup]
    });

  const handleError = useCallback((errorMessage: string) => {
    switch (errorMessage) {
      case "Invalid verification code":
        return [
          {
            entry: "approval.verify.invalid.code",
            rules: [
              {
                rule: "invalidVerificationCode"
              }
            ]
          }
        ];
      default:
        return [
          {
            entry: "default"
          }
        ];
    }
  }, []);

  return (
    <Flex flexDirection="column">
      {!isVerified && (
        <Box mb={2} alignSelf="center">
          <Mutation mutation={VerifyApproval}>
            {(verifyApproval: MutationFunction) => (
              <>
                <Button
                  variant="link"
                  disabled={isVisible["verifyPopup"]}
                  py="1"
                  px="1"
                  onClick={() => toggle("verifyPopup")}
                >
                  <Trans>Verify</Trans>
                </Button>
                <Popup
                  visible={isVisible["verifyPopup"]}
                  onCancel={() => toggle("verifyPopup")}
                  title={<Trans>Verify approval</Trans>}
                  okText={<Trans>Verify</Trans>}
                  formId="verifyApproval"
                >
                  <Flex justifyContent="center">
                    <Box width={1 / 2} mb={4}>
                      <Form
                        id="verifyApproval"
                        onSubmit={async ({ code }: { code: string }) => {
                          try {
                            await verifyApproval({
                              variables: {
                                input: {
                                  id,
                                  personId,
                                  ...(phoneNumber && { code: Number(code) })
                                }
                              }
                            });
                          } catch (err: $TSFixMe) {
                            const { graphQLErrors } = err;
                            return {
                              [SUBMIT_ERROR]: handleError(
                                graphQLErrors[0] ? graphQLErrors[0].message : ""
                              )
                            };
                          }
                          toggle("verifyPopup");
                          refetch();
                        }}
                      >
                        {phoneNumber && (
                          <>
                            <Box mb={6}>
                              <Trans>To phone number</Trans> {phoneNumber}
                            </Box>
                            <Field.Text
                              name="code"
                              label={<Trans id="OTP code" />}
                              placeholder={i18n._(t`Enter OTP code`)}
                            />
                            <Validations field="code">
                              <Validation.Required message="Required field" />
                            </Validations>
                          </>
                        )}
                        <Form.Error
                          entry={{
                            "approval.verify.invalid.code": {
                              invalidVerificationCode: i18n._(
                                t`Invalid verification code`
                              )
                            }
                          }}
                          default={i18n._(
                            t`Something went wrong. Please try again later`
                          )}
                        />
                      </Form>
                    </Box>
                  </Flex>
                </Popup>
              </>
            )}
          </Mutation>
        </Box>
      )}
      {!isVerified &&
        phoneNumber &&
        (type === "OTP" || type === "THIRD_PERSON") && (
          <Box mb={2} alignSelf="center">
            <Mutation mutation={ResendSmsApproval}>
              {(resendSmsApproval: MutationFunction) => (
                <>
                  <Button
                    variant="link"
                    disabled={isVisible["sendSmsPopup"]}
                    py="1"
                    px="1"
                    onClick={() => toggle("sendSmsPopup")}
                  >
                    <Trans>Resend SMS</Trans>
                  </Button>
                  <Popup
                    visible={isVisible["sendSmsPopup"]}
                    onCancel={() => toggle("sendSmsPopup")}
                    title={<Trans>Resend SMS</Trans>}
                    okText={<Trans>Resend SMS</Trans>}
                    onOk={async () => {
                      await resendSmsApproval({
                        variables: {
                          input: {
                            id,
                            personId
                          }
                        }
                      });
                      toggle("sendSmsPopup");
                    }}
                  >
                    <Box mb={6}>
                      <Trans>To phone number</Trans> {phoneNumber}
                    </Box>
                  </Popup>
                </>
              )}
            </Mutation>
          </Box>
        )}
      {(!isVerified || (isVerified && new Date(expiresAt) <= new Date())) && (
        <Ability action="cancel" resource="approval">
          <Box alignSelf="center">
            <Mutation mutation={CancelApproval}>
              {(cancelApproval: MutationFunction) => (
                <>
                  <Button
                    variant="link"
                    disabled={isVisible["cancelPopup"]}
                    py="1"
                    px="1"
                    onClick={() => toggle("cancelPopup")}
                  >
                    <Trans>Cancel approval</Trans>
                  </Button>
                  <Popup
                    visible={isVisible["cancelPopup"]}
                    onCancel={() => toggle("cancelPopup")}
                    title={<Trans>Cancel approval</Trans>}
                    okText={<Trans>Cancel approval</Trans>}
                    onOk={async () => {
                      await cancelApproval({
                        variables: {
                          input: {
                            id,
                            personId
                          }
                        }
                      });
                      toggle("cancelPopup");
                      refetch();
                    }}
                  />
                </>
              )}
            </Mutation>
          </Box>
        </Ability>
      )}
    </Flex>
  );
};

export default Actions;

const VerifyApproval = gql`
  mutation VerifyApproval($input: VerifyApprovalInput!) {
    verifyApproval(input: $input) {
      approval {
        id
      }
    }
  }
`;

const ResendSmsApproval = gql`
  mutation ResendSmsApproval($input: ResendSmsApprovalInput!) {
    resendSmsApproval(input: $input) {
      result
    }
  }
`;

const CancelApproval = gql`
  mutation CancelApproval($input: CancelApprovalInput!) {
    cancelApproval(input: $input) {
      approval {
        id
      }
    }
  }
`;
