import { gql } from "graphql-tag";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { Party, Person } from "@edenlabllc/graphql-schema";

type FullNameProps = {
  party: Partial<Party> | Person;
};

const FullName = ({ party }: FullNameProps) => getFullName(party);

FullName.fragments = {
  entry: gql`
    fragment FullName on Party {
      firstName
      secondName
      lastName
    }
  `
};

export default FullName;
