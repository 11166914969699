import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { MedicationDispenseStatus } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";

import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1} flexDirection="column">
    <Flex>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.id"
              label={<Trans id="Medication dispense ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.id"
          options={UUID_PATTERN}
          message="Invalid medication dispense ID"
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter medication request ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.medicationRequestId"
              label={<Trans id="Medication request ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.medicationRequestId"
          options={UUID_PATTERN}
          message="Invalid medication request ID"
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <DictionaryValue
          name="eHealth/medication_dispense_statuses"
          render={(dict: DictionaryAllValuesJson) => (
            <Trans
              id="Select status"
              render={({ translation }) => (
                <Field.Select
                  variant="select"
                  name="filter.status"
                  label={<Trans id="Medication dispense status" />}
                  placeholder={translation}
                  itemToString={(item: MedicationDispenseStatus) =>
                    dict[item] || String(translation)
                  }
                  filterOptions={{
                    keys: [(item: MedicationDispenseStatus) => item]
                  }}
                  items={Object.keys(dict)}
                  emptyOption
                />
              )}
            />
          )}
        />
      </Box>
    </Flex>
    <Flex>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter pharmacy ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.legalEntityId"
              label={<Trans id="Pharmacy ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.legalEntityId"
          options={UUID_PATTERN}
          message="Invalid pharmacy ID"
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter division ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.divisionId"
              label={<Trans id="Division ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.divisionId"
          options={UUID_PATTERN}
          message="Invalid division ID"
        />
      </Box>
      <Box px={1} width={1 / 3} mt={3}>
        <Trans
          id="Enter dispensed period"
          render={({ translation }) => (
            <Field.RangePicker
              rangeNames={[
                "filter.medicationDispensed.from",
                "filter.medicationDispensed.to"
              ]}
              label={<Trans id="Dispensed period" />}
            />
          )}
        />
        <Form.Spy>
          {({ values = {} }: $TSFixMe) => {
            if (
              (values.filter &&
                values.filter.medicationDispensed &&
                values.filter.medicationDispensed.from) ||
              (values.filter &&
                values.filter.medicationDispensed &&
                values.filter.medicationDispensed.to)
            ) {
              return (
                <>
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.medicationDispensed.from"
                        message={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.medicationDispensed.to"
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }
            return null;
          }}
        </Form.Spy>
      </Box>
    </Flex>
  </Flex>
);

export { PrimarySearchFields };
