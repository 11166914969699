import React from "react";
import Composer from "react-composer";
import { gql } from "graphql-tag";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";

import { getPhones } from "@edenlabllc/ehealth-utils";
import { ConfidantPerson, Maybe, Person } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";

import { Heading, Table, DocumentsTable } from "./index";

type ConfidantPersonsPatientMergeRequestsProps = {
  person: Person["confidantPersons"];
  masterPerson: Person["confidantPersons"];
};

const ConfidantPersonsPatientMergeRequests = ({
  person,
  masterPerson
}: ConfidantPersonsPatientMergeRequestsProps) => {
  const getRelationTypes = (person: Person["confidantPersons"]) => ({
    primary:
      person &&
      person.find(
        (item: Maybe<ConfidantPerson>) =>
          item && item.relationType === "PRIMARY"
      ),

    secondary:
      person &&
      person.find(
        (item: Maybe<ConfidantPerson>) =>
          item && item.relationType === "SECONDARY"
      )
  });

  const master = getRelationTypes(masterPerson);
  const slave = getRelationTypes(person);

  return (
    <>
      {(master.primary || slave.primary) && (
        <>
          <Heading>
            <Trans>Primary Confidant Persons</Trans>
          </Heading>
          <ConfidantPersonsPair
            person={slave.primary}
            masterPerson={master.primary}
          />
        </>
      )}

      {(master.secondary || slave.secondary) && (
        <>
          <Heading>
            <Trans>Secondary Confidant Persons</Trans>
          </Heading>
          <ConfidantPersonsPair
            person={slave.secondary}
            masterPerson={master.secondary}
          />
        </>
      )}
    </>
  );
};

type ConfidantPersonsPairProps = {
  person?: Maybe<ConfidantPerson>;
  masterPerson?: Maybe<ConfidantPerson>;
};

const ConfidantPersonsPair = ({
  person,
  masterPerson
}: ConfidantPersonsPairProps) => (
  <>
    <Table
      header={{
        relationType: <Trans>Relation type</Trans>,
        firstName: <Trans>First name</Trans>,
        lastName: <Trans>Last name</Trans>,
        secondName: <Trans>Second name</Trans>,
        birthDate: <Trans>Date of birth</Trans>,
        birthCountry: <Trans>Country of birth</Trans>,
        birthSettlement: <Trans>Settlement of birth</Trans>,
        gender: <Trans>Gender</Trans>,
        email: <Trans>Email</Trans>,
        unzr: <Trans>Record ID in EDDR</Trans>,
        taxId: <Trans>INN</Trans>,
        phones: <Trans>Phones</Trans>,
        preferredWayCommunication: <Trans>Preferred way of communication</Trans>
      }}
      data={[person || {}, masterPerson || {}]}
      renderRow={({
        relationType,
        phones,
        birthDate,
        gender,
        ...person
      }: ConfidantPerson) => {
        return {
          ...person,
          relationType: (
            <DictionaryValue name="CONFIDANT_PERSON_TYPE" item={relationType} />
          ),
          birthDate:
            birthDate && typeof birthDate === "string" && i18n.date(birthDate),
          gender: <DictionaryValue name="GENDER" item={gender} />,
          phones: phones && phones.length > 0 && getPhones(phones)
        };
      }}
    />
    <Composer
      components={[
        <DictionaryValue name="DOCUMENT_TYPE" />,
        <DictionaryValue name="DOCUMENT_RELATIONSHIP_TYPE" />
      ]}
    >
      {([
        documentsHeader,
        documentsRelationshipHeader
      ]: DictionaryAllValuesJson[]) => (
        <>
          <DocumentsTable
            header={documentsHeader}
            person={person && person.documents}
            masterPerson={masterPerson && masterPerson.documents}
          />
          <DocumentsTable
            header={documentsRelationshipHeader}
            person={person && person.relationshipDocuments}
            masterPerson={masterPerson && masterPerson.relationshipDocuments}
          />
        </>
      )}
    </Composer>
  </>
);

ConfidantPersonsPatientMergeRequests.fragments = {
  entry: gql`
    fragment ConfidantPersonsPatientMergeRequests on Person {
      confidantPersons {
        relationType
        firstName
        lastName
        secondName
        birthDate
        birthCountry
        birthSettlement
        gender
        unzr
        taxId
        email
        documents {
          type
          number
          issuedAt
          issuedBy
        }
        relationshipDocuments {
          type
          number
          issuedAt
          issuedBy
        }
        phones {
          type
          number
        }
        preferredWayCommunication
      }
    }
  `
};

export default ConfidantPersonsPatientMergeRequests;
