import * as React from "react";
import { gql } from "graphql-tag";
import { Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Service, Maybe, ServiceConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type ServicesTableProps = {
  services: ServiceConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  deleteServiceHeader?: { [key: string]: any };
  deleteServiceFromGroup?: (data: {
    serviceId: string;
    serviceName: string;
  }) => React.ReactElement;
  tableName?: string;
};

const ServicesTable = ({
  services,
  locationParams,
  setLocationParams,
  deleteServiceHeader,
  deleteServiceFromGroup: DeleteServiceFromGroup,
  tableName = "services-table/search"
}: ServicesTableProps) => (
  <Table
    data={services || []}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Name</Trans>,
      code: <Trans>Code</Trans>,
      category: <Trans>Category</Trans>,
      isActive: <Trans>Status</Trans>,
      requestAllowed: <Trans>Is request allowed</Trans>,
      isComposition: <Trans>Is composition</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      updatedAt: <Trans>Updated at</Trans>,
      ...deleteServiceHeader,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      name,
      insertedAt,
      updatedAt,
      isActive,
      requestAllowed,
      isComposition,
      category,
      ...serviceData
    }: Service) => ({
      ...serviceData,
      name,
      category: <DictionaryValue name="SERVICE_CATEGORY" item={category} />,
      insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
      updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
      isActive: (
        <Badge
          type="ACTIVE_STATUS_M"
          name={isActive}
          variant={!isActive}
          display="block"
        />
      ),
      requestAllowed: <StatusIcon status={requestAllowed} />,
      isComposition: <StatusIcon status={isComposition} />,
      action: DeleteServiceFromGroup && (
        <DeleteServiceFromGroup serviceId={id} serviceName={name} />
      ),
      details: (
        <Link to={`/services/${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["name", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="updatedAt"
    tableName={tableName}
  />
);

const StatusIcon = ({ status }: { status?: Maybe<boolean> }) => (
  <Flex justifyContent="center">
    {status ? <PositiveIcon /> : <NegativeIcon />}
  </Flex>
);

ServicesTable.fragments = {
  entry: gql`
    fragment Services on Service {
      id
      databaseId
      name
      code
      category
      insertedAt
      updatedAt
      isActive
      requestAllowed
      isComposition
    }
  `
};

export default ServicesTable;
