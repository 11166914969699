import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import {
  DeviceDefinitionFilter,
  DeviceDefinition,
  DeviceDefinitionConnection
} from "@edenlabllc/graphql-schema";

import * as Field from "../Field";

import onlyValidKeys from "../../helpers/onlyValidKeys";
import { getProgramDeviceName } from "../../helpers/deviceDefinition";

type FieldParamsProps = {
  style?: { [key: string]: string };
  disabled?: boolean;
  iconComponent?: () => React.ReactElement;
};

type SearchDeviceDefinitionField = {
  name: string;
  additionalFilters?: DeviceDefinitionFilter;
  filteredParams: string[];
  fieldParams?: FieldParamsProps;
};

const SearchDeviceDefinitionField = ({
  name,
  additionalFilters,
  filteredParams,
  fieldParams
}: SearchDeviceDefinitionField) => (
  <Trans
    id="Choose device definition"
    render={({ translation }) => (
      <Query
        query={DeviceDefinitionsQuery}
        fetchPolicy="cache-first"
        variables={{
          skip: true
        }}
      >
        {({
          data,
          refetch: refetchDeviceDefinitions
        }: QueryResult<{ deviceDefinitions: DeviceDefinitionConnection }>) => {
          const { deviceDefinitions: { nodes: deviceDefinitions = [] } = {} } =
            data || {};
          const itemToString = (item: DeviceDefinition) =>
            item && getProgramDeviceName(item.deviceNames);
          const filteredItems = deviceDefinitions!.map((item) =>
            onlyValidKeys(item, filteredParams)
          );

          return (
            <Field.Select
              {...fieldParams}
              name={name}
              label={<Trans id="Device definition name" />}
              placeholder={translation}
              items={filteredItems}
              itemToString={itemToString}
              filter={(items: DeviceDefinition[]) => items}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  itemToString(selectedItem) !== inputValue &&
                  refetchDeviceDefinitions({
                    skip: false,
                    first: 20,
                    filter: {
                      ...additionalFilters,
                      name
                    }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const DeviceDefinitionsQuery = gql`
  query DeviceDefinitionsQuery(
    $first: Int
    $filter: DeviceDefinitionFilter
    $skip: Boolean! = false
  ) {
    deviceDefinitions(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        deviceNames {
          name
          type
        }
      }
    }
  }
`;

export default SearchDeviceDefinitionField;
