import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Flex } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import {
  ForbiddenGroupServiceConnection,
  Maybe
} from "@edenlabllc/graphql-schema";

import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import ForbiddenGroupServicesTable from "./ForbiddenGroupServicesTable";

const FIELD_TYPES = {
  SERVICE: t`Service`,
  SERVICE_GROUP: t`Service Group`
};

type ServicesProps = RouteComponentProps & {
  forbiddenGroupServices: Maybe<ForbiddenGroupServiceConnection> | undefined;
};

const Services = ({ forbiddenGroupServices }: ServicesProps) => {
  const { i18n } = useLingui();
  if (
    isEmpty(forbiddenGroupServices) ||
    isEmpty(forbiddenGroupServices.nodes)
  ) {
    return <EmptyData />;
  }
  const { pageInfo } = forbiddenGroupServices;

  return (
    <Box p={5}>
      <Form
        id="forbiddenGroupfieldTypeForm"
        initialValues={{ fieldType: FIELD_TYPES.SERVICE }}
      >
        <Form.Spy>
          {({ values: { fieldType } = { fieldType: undefined } }: $TSFixMe) => (
            <>
              <Flex mx={-1} flexWrap="wrap" alignItems="center">
                <Box px={1} width={1 / 2}>
                  <Trans
                    id="Type"
                    render={({ translation }) => (
                      <Field.Select
                        name="fieldType"
                        items={Object.values(FIELD_TYPES)}
                        itemToString={(item: string) => i18n._(item) || item}
                        label={<Trans id="Type" />}
                        placeholder={translation}
                        variant="select"
                      />
                    )}
                  />
                </Box>
              </Flex>
              <LocationParams>
                {({ locationParams, setLocationParams }: TLocationParams) => (
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() => (
                      <PrimarySearchFields fieldType={fieldType} />
                    )}
                    renderSecondary={() => (
                      <SecondarySearchFields fieldType={fieldType} />
                    )}
                  />
                )}
              </LocationParams>
            </>
          )}
        </Form.Spy>
      </Form>
      <ForbiddenGroupServicesTable
        forbiddenGroupServices={forbiddenGroupServices.nodes}
        tableName="forbiddenGroupServices/search"
      />
      <Pagination pageInfo={pageInfo} />
    </Box>
  );
};

export default Services;

type PrimarySearchFieldsProps = {
  fieldType: string;
};

export const PrimarySearchFields = ({
  fieldType
}: PrimarySearchFieldsProps) => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      {fieldType === FIELD_TYPES.SERVICE ? (
        <Trans
          id="Enter Service Name"
          render={({ translation }) => (
            <Field.Text
              name="filter.forbiddenGroupServicesFilter.service.name"
              label={<Trans id="Service Name" />}
              placeholder={translation}
              autoComplete="off"
            />
          )}
        />
      ) : (
        <Trans
          id="Enter Service Group Name"
          render={({ translation }) => (
            <Field.Text
              name="filter.forbiddenGroupServicesFilter.serviceGroup.name"
              label={<Trans id="Service Group Name" />}
              placeholder={translation}
              autoComplete="off"
            />
          )}
        />
      )}
    </Box>
  </Flex>
);

type SecondarySearchFieldsProps = {
  fieldType: string;
};

export const SecondarySearchFields = ({
  fieldType
}: SecondarySearchFieldsProps) => (
  <Flex mx={-1} flexWrap="wrap">
    <Box px={1} width={1 / 2}>
      {fieldType === FIELD_TYPES.SERVICE ? (
        <Trans
          id="Enter Service Code"
          render={({ translation }) => (
            <Field.Text
              name="filter.forbiddenGroupServicesFilter.service.code"
              label={<Trans id="Service Code" />}
              placeholder={translation}
              autoComplete="off"
            />
          )}
        />
      ) : (
        <Trans
          id="Enter Service Group Code"
          render={({ translation }) => (
            <Field.Text
              name="filter.forbiddenGroupServicesFilter.serviceGroup.code"
              label={<Trans id="Service Group Code" />}
              placeholder={translation}
              autoComplete="off"
            />
          )}
        />
      )}
    </Box>
    <Box px={1} width={1 / 2}>
      {fieldType === FIELD_TYPES.SERVICE ? (
        <Trans
          id="Forbidden group Service ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.forbiddenGroupServicesFilter.service.databaseId"
              label={<Trans id="Service ID" />}
              placeholder={translation}
              autoComplete="off"
            />
          )}
        />
      ) : (
        <Trans
          id="Forbidden group Service ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.forbiddenGroupServicesFilter.serviceGroup.databaseId"
              label={<Trans id="Service Group ID" />}
              placeholder={translation}
              autoComplete="off"
            />
          )}
        />
      )}
    </Box>
    <Box width={1 / 2} px={1}>
      <SearchField.Status
        name="filter.forbiddenGroupServicesFilter.isActive"
        status="ACTIVE_STATUS_M"
        label={<Trans id="Status groups element" />}
      />
    </Box>
  </Flex>
);
