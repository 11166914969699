import React, { useMemo, useState } from "react";
import { Box, Heading, Flex, Text } from "@rebass/emotion";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
  CellMeasurerProps
} from "react-virtualized";
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import isEmpty from "lodash/isEmpty";

import styled from "@emotion/styled/macro";
import { Form, Field } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Maybe } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";

const MAX_CONTAINER_HEIGHT = 470;
const ROW_HEIGHT = 47;

type VirtualizedCodesListProps = {
  title: React.ReactNode;
  data: Maybe<string>[];
  dictionaryName: string;
};

export const VirtualizedCodesList = ({
  title,
  data,
  dictionaryName
}: VirtualizedCodesListProps) => {
  if (isEmpty(data)) return null;

  const [filterValue, setFilterValue] = useState("");

  const filteredList = useMemo(
    () =>
      data &&
      data.filter(
        (item) =>
          item && item.toLowerCase().includes((filterValue || "").toLowerCase())
      ),
    [filterValue, data]
  );

  const handleSearch = (e: $TSFixMe) => {
    setFilterValue(e.code);
  };

  const cache = new CellMeasurerCache({
    defaultHeight: ROW_HEIGHT,
    fixedWidth: true
  });

  type RowProps = {
    key: string;
    index: number;
    isScrolling: boolean;
    style: React.CSSProperties;
    parent: CellMeasurerProps["parent"];
  };

  const renderRow = ({ key, index, isScrolling, style, parent }: RowProps) => {
    if (isScrolling)
      return (
        <EmptyRow key={key} style={style}>
          <Cell>
            <Trans>Loading...</Trans>
          </Cell>
        </EmptyRow>
      );

    return (
      // @ts-expect-error
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          // @ts-expect-error
          <BodyRow key={key} style={style} ref={registerChild}>
            <Cell title={filteredList[index] || ""}>{filteredList[index]}</Cell>
            {/* @ts-expect-error */}
            <Cell flex={1}>
              <DictionaryValue
                name={dictionaryName}
                item={filteredList[index]}
              />
            </Cell>
          </BodyRow>
        )}
      </CellMeasurer>
    );
  };

  const isSearchVisible = data.length > 10;

  return (
    <>
      <Line />
      <Heading fontSize="0" fontWeight="bold" mb={3}>
        {title}
      </Heading>
      <Box mb={5}>
        {isSearchVisible && (
          <Flex mt={3} mb={3} justifyContent="end">
            <Box width={1 / 2}>
              <Form onSubmit={() => null}>
                <Form.AutoSubmit onSubmit={handleSearch} />
                <Field.Text
                  name="code"
                  placeholder={i18n._(t`Search by code`)}
                  postfix={<SearchIcon color="silverCity" />}
                />
              </Form>
            </Box>
            <Box width={1 / 2}>
              <Text textAlign="right" color="#bbb" fontSize={12}>
                <Trans>Found:</Trans>
                &nbsp;
                {!!filterValue &&
                  filteredList.length > 0 &&
                  `${filteredList.length} / `}
                {data.length}
              </Text>
            </Box>
          </Flex>
        )}

        <HeaderRow>
          <HeaderCell>
            <Trans>Code</Trans>
          </HeaderCell>
          <HeaderCell>
            <Trans>Name</Trans>
          </HeaderCell>
        </HeaderRow>

        {filteredList.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            style={{
              height: Math.min(
                MAX_CONTAINER_HEIGHT,
                ROW_HEIGHT * (filteredList && filteredList.length)
              ),
              width: "100%"
            }}
          >
            {/* @ts-expect-error */}
            <AutoSizer>
              {({ width, height }) => (
                // @ts-expect-error
                <List
                  width={width}
                  height={height}
                  rowCount={filteredList.length}
                  rowRenderer={renderRow}
                  rowHeight={cache.rowHeight}
                  deferredMeasurementCache={cache}
                />
              )}
            </AutoSizer>
          </div>
        )}
      </Box>
    </>
  );
};

const HeaderRow = styled.div`
  display: flex;
  background-image: linear-gradient(0deg, #f2f4f7 0%, #ffffff 100%);
  font-size: 12px;
  user-select: none;
  color: #7f8fa4;
  border: 1px solid #e0e0e0;
`;

const BodyRow = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid rgb(224, 224, 224);
  border-top: none;

  &:nth-of-type(2n) {
    background-color: #fcfcfc;
  }

  &:hover {
    background-color: #f3fdff;
  }
`;

const EmptyRow = styled(BodyRow)`
  align-items: center;
  justify-content: center;
`;

const Cell = styled.div`
  display: flex;
  height: inherit;
  flex: 1;
  overflow: hidden;
  padding: 16px 20px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  align-items: center;
  text-align: left;
  vertical-align: middle;
  min-width: 180px;
  & + & {
    flex: 8;
    border-left: 1px solid rgb(224, 224, 224);
  }
`;

const HeaderCell = styled(Cell)`
  position: relative;
  padding: 14px 20px;
  cursor: default;
  color: rgb(146, 148, 153);
  font-weight: 700;
  text-shadow:
    rgb(255 255 255) 1px 1px 2px,
    rgb(146 148 153) 0px 0px 0px;
  & + & {
    border-left: 1px solid rgb(224, 224, 224);
  }
`;
