import React from "react";
import { gql } from "graphql-tag";
import { Router, RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { Party, Scalars } from "@edenlabllc/graphql-schema";

import Breadcrumbs from "../../../components/Breadcrumbs";
import LoadingOverlay from "../../../components/LoadingOverlay";
import OnRouteChange from "../../../components/OnRouteChange";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import pagination from "../../../helpers/pagination";

import HeaderParty from "./HeaderParty";
import GeneralInfoParty from "./GeneralInfoParty";
import VerificationDetails from "./VerificationDetails";
import Employees from "./Employees";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams }: TLocationParams) => (
      <Query
        query={PartyQuery}
        variables={{
          id,
          ...pagination(locationParams)
        }}
        fetchPolicy="network-only"
      >
        {({ loading, data }: QueryResult<{ party: Party }>) => {
          if (isEmpty(data) || isEmpty(data.party)) return null;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/unverified-parties">
                      <Trans>Accounts</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Employee account details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <HeaderParty party={data.party} />
              </Box>
              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>General info</Trans>
                </Tabs.NavItem>
                <Tabs.NavItem to="./verification">
                  <Trans>Verification</Trans>
                </Tabs.NavItem>
                <Tabs.NavItem to="./employees">
                  <Trans>Employees</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <GeneralInfoParty path="/" party={data.party} />
                  <VerificationDetails path="verification" party={data.party} />
                  <Employees
                    path="employees"
                    data={data.party.employees!}
                    loading={loading}
                  />
                </Router>
                <OnRouteChange />
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

export const PartyQuery = gql`
  query PartyQuery(
    $id: ID!
    $orderBy: EmployeeOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: EmployeeFilter
  ) {
    party(id: $id) {
      id
      ...HeaderParty
      ...GeneralInfoParty
      ...VerificationDetails
      ...Employees
    }
  }
  ${HeaderParty.fragments.entry}
  ${GeneralInfoParty.fragments.entry}
  ${VerificationDetails.fragments.entry}
  ${Employees.fragments.entry}
`;

export default Details;
