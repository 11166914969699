import * as React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";

import { Dictionary, DictionaryConnection } from "@edenlabllc/graphql-schema";

import Link from "../../../components/Link";
import Table from "../../../components/Table";
import DictionaryValue from "../../../components/DictionaryValue";

type DictionariesTableProps = {
  dictionaries: DictionaryConnection["nodes"];
  tableName?: string;
};

const DictionariesTable = ({
  dictionaries,
  tableName = "dictionaries/search"
}: DictionariesTableProps) => (
  <Table
    data={dictionaries}
    header={{
      name: <Trans>Dictionary name</Trans>,
      labels: <Trans>Tags</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({ labels = [], name }: Dictionary) => ({
      name,
      labels:
        labels &&
        labels.map((label: string | null, index: number) => (
          <React.Fragment key={label}>
            {index !== 0 && ", "}
            <span title={label || undefined}>
              <DictionaryValue name="DICTIONARY_LABELS" item={label} />
            </span>
          </React.Fragment>
        )),
      action: (
        <Link to={name && `../${encodeURIComponent(name)}`} fontWeight="bold">
          <Trans>View</Trans>
        </Link>
      )
    })}
    tableName={tableName}
  />
);

DictionariesTable.fragments = {
  entry: gql`
    fragment Dictionaries on Dictionary {
      id
      name
      labels
    }
  `
};

export default DictionariesTable;
