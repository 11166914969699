import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import {
  DeclarationsTerminationJob,
  Scalars
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import Tabs from "../../../components/Tabs";
import { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import Tasks from "./Tasks";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id }: DetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Query
        query={DeclarationsTerminationJobQuery}
        variables={{ id, ...filteredLocationParams(locationParams) }}
      >
        {({
          loading,
          data
        }: QueryResult<{
          declarationsTerminationJob: DeclarationsTerminationJob;
        }>) => {
          if (isEmpty(data) || isEmpty(data.declarationsTerminationJob))
            return null;
          const { databaseId, status, meta, tasks } =
            data.declarationsTerminationJob;
          const { reasonDescription, registerType } = meta || {};

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/declarations-termination-jobs">
                      <Trans>Declarations termination jobs</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Job details</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>Job ID</Trans>,
                    status: <Trans>Status</Trans>,
                    registerType: <Trans>Register type</Trans>,
                    reasonDescription: <Trans>Reason</Trans>
                  }}
                  data={{
                    databaseId,
                    reasonDescription,
                    status: <Badge type="JOBS" name={status} minWidth={100} />,
                    registerType: registerType && (
                      <DictionaryValue
                        name="REGISTER_TYPE"
                        item={registerType}
                      />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="140px"
                />
              </Box>
              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>Job details</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <Tasks
                    path="/"
                    tasks={tasks}
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

const DeclarationsTerminationJobQuery = gql`
  query DeclarationsTerminationJobQuery(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: TaskFilter
    $orderBy: TaskOrderBy
  ) {
    declarationsTerminationJob(id: $id) {
      id
      databaseId
      status
      meta {
        reasonDescription
        registerType
      }
      tasks(
        first: $first
        last: $last
        before: $before
        after: $after
        filter: $filter
        orderBy: $orderBy
      ) {
        nodes {
          ...DeclarationsTerminationTasks
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${Tasks.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Details;
