import * as React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import { InnmDosage, InnmDosageConnection } from "@edenlabllc/graphql-schema";

import * as Field from "../Field";
import onlyValidKeys from "../../helpers/onlyValidKeys";

type SearchINNMDosageFieldProps = {
  name: string;
  filteredParams: string[];
};

const SearchINNMDosageField = ({
  name,
  filteredParams
}: SearchINNMDosageFieldProps) => (
  <Trans
    id="Enter INNM dosage name"
    render={({ translation }) => (
      <Query
        query={INNMDosagesQuery}
        fetchPolicy="cache-first"
        variables={{ skip: true }}
      >
        {({
          data,
          refetch: refetchINNMDosages
        }: QueryResult<{ innmDosages: InnmDosageConnection }>) => {
          const { innmDosages: { nodes: innmDosages = [] } = {} } = data || {};
          const itemToString = (item: InnmDosage) => item && item.name;
          const filteredItems = innmDosages!.map((item) =>
            onlyValidKeys(item, filteredParams)
          );
          return (
            <Field.Select
              name={name}
              label={<Trans id="INNM dosage name" />}
              placeholder={translation}
              items={filteredItems}
              itemToString={itemToString}
              filter={(innmDosages: InnmDosage[]) => innmDosages}
              onInputValueChange={debounce(
                (name, { selectedItem, inputValue }) =>
                  !isEmpty(name) &&
                  (selectedItem && selectedItem.name) !== inputValue &&
                  refetchINNMDosages({
                    skip: false,
                    first: 20,
                    filter: { name }
                  }),
                300
              )}
            />
          );
        }}
      </Query>
    )}
  />
);

const INNMDosagesQuery = gql`
  query INNMDosagesQuery(
    $first: Int
    $filter: INNMDosageFilter
    $skip: Boolean! = false
  ) {
    innmDosages(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        name
      }
    }
  }
`;

export default SearchINNMDosageField;
