import React from "react";
import { Trans } from "@lingui/macro";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  Dictionary,
  DictionaryValueConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import Table, { SortingParams } from "../../../components/Table";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";

type DictionaryValuesTableProps = {
  id: Dictionary["id"];
  values: DictionaryValueConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  dictionaryAbility: boolean;
  action?: object;
};

export const DictionaryValuesTable = ({
  values,
  locationParams,
  setLocationParams,
  dictionaryAbility,
  action
}: DictionaryValuesTableProps) => (
  <Table
    data={values}
    header={{
      databaseId: <Trans>ID</Trans>,
      code: <Trans>Key</Trans>,
      description: <Trans>Description</Trans>,
      parentCode: <Trans>Parent code</Trans>,
      parentId: <Trans>Parent ID</Trans>,
      isActive: <Trans>Status</Trans>,
      ...(dictionaryAbility && {
        ...action
      })
    }}
    renderRow={({
      id,
      code,
      description,
      parentId,
      parent,
      databaseId,
      isActive
    }) => {
      return {
        databaseId,
        code,
        description,
        parentId,
        ...(parent &&
          parent.code && {
            parentCode: parent.code
          }),
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        action: (
          <Link to={`./${id}/values`} fontWeight="bold">
            <Trans>Details</Trans>
          </Link>
        )
      };
    }}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId", "parentId"]}
    tableName="dictionary-values-table"
    hiddenFields="parentId"
  />
);
