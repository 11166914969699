import React, { useState } from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { DocumentNode, MutationFunction } from "@apollo/client";
import createDecorator from "final-form-calculate";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import Composer from "react-composer";
import get from "lodash/get";

import { Form, Validations, Validation } from "@edenlabllc/ehealth-components";
import { CreateMedicalProgramInput } from "@edenlabllc/graphql-schema";

import Button from "../../../components/Button";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import Popup from "../../../components/Popup";

import { SearchParams } from "../../../components/SearchForm";
import { CYRILLIC_MEDICAL_PROGRAM_NAME } from "../../../constants/validationPatterns";

type CreateMedicalProgramPopupProps = {
  locationParams: SearchParams;
  medicalProgramsQuery: DocumentNode;
};

const CreateMedicalProgramPopup = ({
  locationParams,
  medicalProgramsQuery
}: CreateMedicalProgramPopupProps) => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Box>
      <Mutation
        mutation={CreateMedicalProgramMutation}
        refetchQueries={() => [
          {
            query: medicalProgramsQuery,
            variables: locationParams
          }
        ]}
      >
        {(createMedicalProgram: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="green">
              <Trans>Add program</Trans>
            </Button>
            <Popup
              visible={isPopupVisible}
              onCancel={toggle}
              title={<Trans>Add new medical program</Trans>}
              okButtonProps={{ variant: "green" }}
              okText={<Trans>Add program</Trans>}
              justifyButtons="left"
              formId="createMedicalProgram"
            >
              <Form
                id="createMedicalProgram"
                onSubmit={async (name: CreateMedicalProgramInput) => {
                  await createMedicalProgram({
                    variables: {
                      input: name
                    }
                  });
                  toggle();
                }}
                decorators={[checkFundingSource]}
              >
                <Form.Spy>
                  {({ values }: $TSFixMe) => {
                    const currMedicalProgramType = get(values, "type");

                    return (
                      <>
                        <Trans
                          id="Enter program name"
                          render={({ translation }) => (
                            <Field.Text
                              name="name"
                              label={<Trans id="Name" />}
                              placeholder={translation}
                              maxLength={100}
                              showLengthHint
                            />
                          )}
                        />
                        <Validations field="name">
                          <Validation.Required message="Required field" />
                          <Validation.Matches
                            options={CYRILLIC_MEDICAL_PROGRAM_NAME}
                            message="Invalid name"
                          />
                        </Validations>
                        <Box width={3 / 5}>
                          <Composer
                            components={[
                              <DictionaryValue name="MEDICAL_PROGRAM_TYPE" />,
                              ({
                                render
                              }: {
                                render: (
                                  props: TransRenderProps
                                ) => React.ReactElement;
                              }) => <Trans id="Select option" render={render} />
                            ]}
                          >
                            {([dict, { translation }]: [
                              DictionaryAllValuesJson,
                              { translation: React.ReactNode }
                            ]) => (
                              <Field.Select
                                name="type"
                                label={<Trans id="Medical program type" />}
                                placeholder={translation}
                                items={Object.keys(dict)}
                                itemToString={(item: string) =>
                                  dict[item] || String(translation)
                                }
                                variant="select"
                                emptyOption
                                filterOptions={{
                                  keys: [(item: string) => dict[item]]
                                }}
                              />
                            )}
                          </Composer>
                          <Validation.Required
                            field="type"
                            message="Required field"
                          />
                        </Box>
                        {currMedicalProgramType === "MEDICATION" && (
                          <Box width={3 / 5}>
                            <Composer
                              components={[
                                <DictionaryValue name="MR_BLANK_TYPES" />,
                                ({
                                  render
                                }: {
                                  render: (
                                    props: TransRenderProps
                                  ) => React.ReactElement;
                                }) => (
                                  <Trans id="Select option" render={render} />
                                )
                              ]}
                            >
                              {([dict, { translation }]: [
                                DictionaryAllValuesJson,
                                { translation: React.ReactNode }
                              ]) => (
                                <Field.Select
                                  name="mrBlankType"
                                  label={
                                    <Trans id="Type of Medication request blank" />
                                  }
                                  placeholder={translation}
                                  items={dict ? Object.keys(dict) : []}
                                  itemToString={(item: string) =>
                                    dict[item] || String(translation)
                                  }
                                  variant="select"
                                  emptyOption
                                  filterOptions={{
                                    keys: [(item: string) => dict[item]]
                                  }}
                                />
                              )}
                            </Composer>
                            <Validation.Required
                              field="mrBlankType"
                              message="Required field"
                            />
                          </Box>
                        )}
                        <Box width={3 / 5} mb={4}>
                          <Composer
                            components={[
                              <DictionaryValue name="FUNDING_SOURCE" />,
                              ({
                                render
                              }: {
                                render: (
                                  props: TransRenderProps
                                ) => React.ReactElement;
                              }) => <Trans id="Select option" render={render} />
                            ]}
                          >
                            {([dict, { translation }]: [
                              DictionaryAllValuesJson,
                              { translation: React.ReactNode }
                            ]) => (
                              <Field.Select
                                name="fundingSource"
                                label={<Trans id="Funding source" />}
                                placeholder={translation}
                                items={dict ? Object.keys(dict) : []}
                                itemToString={(item: string) =>
                                  dict[item] || String(translation)
                                }
                                variant="select"
                                emptyOption
                                filterOptions={{
                                  keys: [(item: string) => dict[item]]
                                }}
                                disabled={currMedicalProgramType === "DEVICE"}
                              />
                            )}
                          </Composer>
                          <Validation.Required
                            field="fundingSource"
                            message="Required field"
                          />
                        </Box>
                      </>
                    );
                  }}
                </Form.Spy>
              </Form>
            </Popup>
          </>
        )}
      </Mutation>
    </Box>
  );
};

const checkFundingSource = createDecorator({
  field: "type",
  updates: {
    fundingSource: (type: string) => (type === "DEVICE" ? "NHS" : undefined),
    mrBlankType: () => undefined
  }
});

const CreateMedicalProgramMutation = gql`
  mutation CreateMedicalProgramMutation($input: CreateMedicalProgramInput!) {
    createMedicalProgram(input: $input) {
      medicalProgram {
        id
      }
    }
  }
`;

export default CreateMedicalProgramPopup;
