import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box, Flex, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  LegalEntity,
  LegalEntityLicense,
  Scalars
} from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import { SearchParams, TLocationParams } from "../../../components/SearchForm";
import Table from "../../../components/Table";

import { ITEMS_PER_PAGE } from "../../../constants/pagination";

import NHSVerify from "./Mutations/NHSVerify";
import NHSComment from "./Mutations/NHSComment";
import { LegalEntityQuery } from "./";

type LicensesProps = RouteComponentProps<{
  id: Scalars["ID"];
  // @ts-expect-error types mismatch
  isVerificationActive: LegalEntity["isVerificationActive"];
  accreditation: LegalEntity["accreditation"];
  nhsVerified: LegalEntity["nhsVerified"];
  nhsComment: LegalEntity["nhsComment"];
}>;

const Licenses = ({
  id,
  isVerificationActive,
  accreditation,
  nhsVerified,
  nhsComment
}: LicensesProps) => {
  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          first,
          last,
          after,
          before,
          filter: { licenseFilter } = {}
        } = locationParams;

        return (
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <Form.AutoSubmit
              onSubmit={(values: SearchParams) => setLocationParams(values)}
            />
            <Box p={5}>
              {!isEmpty(accreditation) && (
                <>
                  <Heading fontSize="1" fontWeight="normal" mb={5}>
                    <Trans>Accreditation</Trans>
                  </Heading>
                  <DefinitionListView
                    labels={{
                      category: <Trans>Category</Trans>,
                      validateDate: <Trans>Validate Date</Trans>,
                      orderDate: <Trans>Order Date</Trans>,
                      orderNo: <Trans>Order Number</Trans>
                    }}
                    data={{
                      ...accreditation,
                      category: accreditation.category && (
                        <DictionaryValue
                          name="ACCREDITATION_CATEGORY"
                          item={accreditation.category}
                        />
                      ),
                      validateDate: (
                        <>
                          <Trans>From</Trans>{" "}
                          {accreditation &&
                            accreditation.issuedDate &&
                            i18n.date(accreditation.issuedDate)}{" "}
                          {accreditation.expiryDate ? (
                            <>
                              <Trans>To</Trans>{" "}
                              {i18n.date(accreditation.expiryDate)}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ),
                      orderDate:
                        accreditation && accreditation.orderDate
                          ? i18n.date(accreditation.orderDate)
                          : "-"
                    }}
                  />
                  <Line />
                </>
              )}

              <Query
                query={LegalEntityQuery}
                variables={{
                  id,
                  firstLicenses:
                    !first && !last
                      ? ITEMS_PER_PAGE[0]
                      : first
                        ? parseInt(first)
                        : undefined,
                  lastLicenses: last ? parseInt(last) : undefined,
                  beforeLicenses: before,
                  afterLicenses: after,
                  licenseFilter: {
                    ...licenseFilter
                  },
                  firstMergedFromLegalEntities: ITEMS_PER_PAGE[0],
                  firstHealthcareServices: ITEMS_PER_PAGE[0],
                  firstDivisions: ITEMS_PER_PAGE[0]
                }}
              >
                {({
                  loading,
                  data
                }: QueryResult<{ legalEntity: LegalEntity }>) => {
                  if (
                    isEmpty(data) ||
                    isEmpty(data.legalEntity) ||
                    isEmpty(data.legalEntity.licenses)
                  )
                    return null;
                  const {
                    legalEntity: {
                      licenses: { nodes: licenses = [], pageInfo }
                    }
                  } = data;

                  return (
                    <LoadingOverlay loading={loading}>
                      {licenses!.length > 0 ? (
                        <>
                          <Heading fontSize="1" fontWeight="normal" mb={5}>
                            <Trans>Licenses</Trans>
                          </Heading>

                          <Table
                            data={licenses}
                            header={{
                              licenseNumber: <Trans>License number</Trans>,
                              licenseType: <Trans>License type</Trans>,
                              whatLicensed: <Trans>Issued to</Trans>,
                              issuedDate: <Trans>Date of issue</Trans>,
                              issuedBy: <Trans>Authority that issued</Trans>,
                              validateDate: <Trans>Validate Date</Trans>,
                              orderNo: <Trans>Order Number</Trans>,
                              primary: <Trans>Is primary</Trans>
                            }}
                            renderRow={({
                              activeFromDate,
                              expiryDate,
                              issuedDate,
                              licenseType,
                              ...license
                            }: LegalEntityLicense) => ({
                              validateDate: (
                                <>
                                  <Trans>From</Trans>
                                  {activeFromDate &&
                                    i18n.date(activeFromDate)}{" "}
                                  {expiryDate ? (
                                    <>
                                      <Trans>To</Trans> {i18n.date(expiryDate)}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ),
                              issuedDate: issuedDate
                                ? i18n.date(issuedDate)
                                : "-",
                              primary: (
                                <Flex justifyContent="center">
                                  {license.isPrimary ? (
                                    <PositiveIcon />
                                  ) : (
                                    <NegativeIcon />
                                  )}
                                </Flex>
                              ),
                              licenseType: (
                                <DictionaryValue
                                  name="LICENSE_TYPE"
                                  item={licenseType}
                                />
                              ),
                              ...license
                            })}
                            tableName="legal-entities/licenses"
                          />
                          <Pagination pageInfo={pageInfo} />
                          <Line />
                        </>
                      ) : (
                        <EmptyData />
                      )}
                    </LoadingOverlay>
                  );
                }}
              </Query>

              <OpacityBox mt={5} opacity={isVerificationActive ? 1 : 0.5}>
                <Heading fontSize="1" fontWeight="normal" mb={5}>
                  <Trans>Verification</Trans>
                </Heading>
                <DefinitionListView
                  labels={{
                    nhsVerified: <Trans>Verification NZZU</Trans>
                  }}
                  data={{
                    nhsVerified: nhsVerified ? (
                      <PositiveIcon />
                    ) : (
                      <NegativeIcon />
                    )
                  }}
                />
                <Box mt={3} mb={4}>
                  <NHSVerify
                    id={id!}
                    isVerificationActive={isVerificationActive}
                    nhsVerified={nhsVerified}
                  />
                </Box>
                <NHSComment id={id!} nhsComment={nhsComment} />
              </OpacityBox>
            </Box>
          </Form>
        );
      }}
    </LocationParams>
  );
};

const OpacityBox = system(
  {
    extend: Box,
    opacity: 1
  },
  "opacity"
);

Licenses.fragments = {
  entry: gql`
    fragment LegalEntityLicense on LegalEntity {
      nhsComment
      licenses(
        first: $firstLicenses
        last: $lastLicenses
        before: $beforeLicenses
        after: $afterLicenses
        filter: $licenseFilter
      ) {
        nodes {
          licenseNumber
          licenseType
          issuedBy
          issuedDate
          expiryDate
          activeFromDate
          whatLicensed
          orderNo
          isPrimary
        }
        pageInfo {
          ...PageInfo
        }
      }
      accreditation {
        category
        issuedDate
        expiryDate
        orderNo
        orderDate
      }
    }
    ${Pagination.fragments.entry}
  `
};

export default Licenses;
