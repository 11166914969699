import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { Trans } from "@lingui/macro";
import Composer from "react-composer";
import isEmpty from "lodash/isEmpty";
import { debounce, uniqBy } from "lodash";

import { ServiceGroup } from "@edenlabllc/graphql-schema";

import * as Field from "../Field";

type ComposedSearchServiceGroupFieldProps = {
  name: string;
  label?: React.ReactNode;
};

const ComposedSearchServiceGroupField = ({
  name,
  label
}: ComposedSearchServiceGroupFieldProps) => (
  <Trans
    id="Enter name or code"
    render={({ translation }) => {
      const getParentGroupQuery = ({ render }: $TSFixMe) => (
        <Query
          query={GetServiceGroupsQuery}
          children={render}
          fetchPolicy="cache-first"
          variables={{
            skip: true
          }}
        />
      );

      return (
        <Composer components={[getParentGroupQuery, getParentGroupQuery]}>
          {([
            {
              data: {
                serviceGroups: { nodes: serviceGroupsByName = [] } = {}
              } = {},
              refetch: refetchServiceGroupsByName
            },
            {
              data: {
                serviceGroups: { nodes: serviceGroupsByCode = [] } = {}
              } = {},
              refetch: refetchServiceGroupsByCode
            }
          ]: $TSFixMe) => {
            const serviceGroups = uniqBy(
              [...serviceGroupsByName, ...serviceGroupsByCode],
              "id"
            );

            const itemToString = (item: {
              id: string;
              name: string;
              code: string;
            }) => item && `${item.name} (${item.code})`;

            return (
              <Field.Select
                name={name}
                label={label || <Trans id="Parent group" />}
                placeholder={translation}
                items={serviceGroups.map(({ id, name, code }) => ({
                  id,
                  name,
                  code
                }))}
                itemToString={itemToString}
                filter={(serviceGroups: ServiceGroup[]) => serviceGroups}
                onInputValueChange={debounce(
                  async (userInput, { selectedItem, inputValue }) => {
                    if (
                      !isEmpty(userInput) &&
                      itemToString(selectedItem) !== inputValue
                    ) {
                      await refetchServiceGroupsByName({
                        skip: false,
                        first: 20,
                        filter: { name: userInput }
                      });
                      await refetchServiceGroupsByCode({
                        skip: false,
                        first: 20,
                        filter: { code: userInput }
                      });
                    }
                  },
                  300
                )}
              />
            );
          }}
        </Composer>
      );
    }}
  />
);

const GetServiceGroupsQuery = gql`
  query GetServiceGroupsQuery(
    $first: Int
    $filter: ServiceGroupFilter
    $skip: Boolean! = false
  ) {
    serviceGroups(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        name
        code
      }
    }
  }
`;

export default ComposedSearchServiceGroupField;
