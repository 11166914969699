import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { EmployeeConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";
import LoadingOverlay from "../../../components/LoadingOverlay";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import EmployeesTable from "./EmployeesTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = ({ navigate }: RouteComponentProps) => {
  const onCreate = useCallback(() => navigate!("../create"), [navigate]);

  return (
    <Box p={6}>
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <>
            <Flex justifyContent="space-between" alignItems="flex-start" mb={6}>
              <Box>
                <Heading as="h1" fontWeight="normal" mb={4}>
                  <Trans>Employees</Trans>
                </Heading>
              </Box>
              <Ability action="write" resource="employee_request">
                <Box>
                  <Button onClick={onCreate} variant="green">
                    <Trans>Create employee</Trans>
                  </Button>
                </Box>
              </Ability>
            </Flex>

            <SearchForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
              renderPrimary={PrimarySearchFields}
              renderSecondary={SecondarySearchFields}
            />
            <Query
              query={SearchEmployeesQuery}
              fetchPolicy="network-only"
              variables={filteredLocationParams(locationParams)}
            >
              {({
                loading,
                data
              }: QueryResult<{ employees: EmployeeConnection }>) => {
                if (isEmpty(data)) return null;
                const { nodes: employees = [], pageInfo } = data.employees;

                return (
                  <LoadingOverlay loading={loading}>
                    <>
                      <EmployeesTable
                        employees={employees}
                        locationParams={locationParams}
                        setLocationParams={setLocationParams}
                      />
                      <Pagination pageInfo={pageInfo} />
                    </>
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        )}
      </LocationParams>
    </Box>
  );
};

const SearchEmployeesQuery = gql`
  query SearchEmployeesQuery(
    $filter: EmployeeFilter
    $orderBy: EmployeeOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    employees(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...Employees
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${EmployeesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
