import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex } from "@rebass/emotion";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Employee, EmployeeConnection } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import FullName from "../../../components/FullName";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

type EmployeesTableProps = {
  employees: EmployeeConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const EmployeesTable = ({
  employees,
  locationParams,
  setLocationParams
}: EmployeesTableProps) => (
  <Table
    data={employees}
    header={{
      databaseId: <Trans>ID</Trans>,
      partyFullName: <Trans>Name of employee</Trans>,
      position: <Trans>Position</Trans>,
      startDate: <Trans>Start date</Trans>,
      employeeType: <Trans>Employee type</Trans>,
      legalEntityName: <Trans>Legal entity name</Trans>,
      divisionName: <Trans>Division name</Trans>,
      status: <Trans>Status</Trans>,
      isActive: <Trans>Is employee active</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      party: { verificationDetails, ...party } = {},
      position,
      employeeType,
      startDate,
      legalEntity,
      division,
      status,
      isActive,
      ...employeeData
    }: Employee) => {
      const { edrData, name } = legalEntity || {};
      return {
        ...employeeData,
        partyFullName: <FullName party={party} />,
        position: <DictionaryValue name="POSITION" item={position} />,
        employeeType: (
          <DictionaryValue name="EMPLOYEE_TYPE" item={employeeType} />
        ),
        startDate: i18n.date(startDate),
        legalEntityName: (edrData && edrData.name) || name,
        divisionName: division && division.name,
        isActive: (
          <Flex justifyContent="center">
            {isActive ? <PositiveIcon /> : <NegativeIcon />}
          </Flex>
        ),
        status: <DictionaryValue name="EMPLOYEE_STATUS" item={status} />,
        details: (
          <Link to={`../${id}`} fontWeight="bold">
            <Trans>Show details</Trans>
          </Link>
        )
      };
    }}
    sortableFields={[
      "partyFullName",
      "legalEntityName",
      "divisionName",
      "status",
      "employeeType"
    ]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="employees/search"
    hiddenFields="drfoVerificationStatus,dracsVerificationStatus"
  />
);

EmployeesTable.fragments = {
  entry: gql`
    fragment Employees on Employee {
      id
      databaseId
      position
      startDate
      endDate
      status
      employeeType
      isActive
      party {
        id
        ...FullName
      }
      additionalInfo {
        specialities {
          speciality
          specialityOfficio
        }
      }
      division {
        id
        databaseId
        name
      }
      legalEntity {
        id
        databaseId
        name
        edrData {
          name
        }
      }
    }
    ${FullName.fragments.entry}
  `
};

export default EmployeesTable;
