import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { matchSorter } from "match-sorter";
import {
  PropGetters,
  ControllerStateAndHelpers,
  GetInputPropsOptions
} from "downshift";

import { ChevronBottomIcon } from "@edenlabllc/ehealth-icons";

import Dropdown from "../Dropdown";
import { List, DropdownButton } from "./MultiSelectView";
import * as FieldView from "./FieldView";
import * as InputView from "./InputView";
import { SingleDownshift, SingleDownshiftView } from "./DownshiftField";
import ErrorTranslation from "./ErrorTranslation";

type PortalProps = {
  children: React.ReactNode;
  styles: React.CSSProperties;
};

const Portal = ({ children, styles }: PortalProps) => {
  const portal = document.getElementById("portal");

  return (
    portal &&
    ReactDOM.createPortal(
      <div
        style={{
          ...(styles && styles)
        }}
      >
        {children}
      </div>,
      portal
    )
  );
};

/**
 *
 * @example
 *
 * ```jsx
 * <Form {...props}>
 *   <Field.Select items={doctors} name="doctors" disabled />
 *   <Form.Submit block>Далі</Form.Submit>
 * </Form>
 * ```
 *
 * For passing an Array of Objects to the items prop,
 * you must specify the key in renderItem, itemToString and, for custom filtering, in filterOptions.
 *
 * @example below will be filtering by doctors.name key and show the doctors.name value in the select list
 * const doctors = [{name: "John Doe", speciality: "Family Doctor", clinic: "Doe & Doe"}, {...}]
 * <Field.Select
 *   items={doctors}
 *   name="doctors"
 *   renderItem={item => item.name }
 *   itemToString={item => {
 *     if (!item) return "";
 *     return variantof item === "string"
 *       ? item
 *       : item.name
 *   }}
 *   filterOptions={
 *    { keys: ["name"] }
 *   }
 * />
 *
 * Optional prop "variant" with possible value "select"
 * Without "variant" select works with search field and filter in List
 * With variant="select" select works like classic select field, without search and filtering, and with gradient background
 */

// export type ItemType = string
//   | { key: string, value: string }
//   | { id: string, name: string, code?: string}
//   | {
//     party: { firstName: string, secondName: string, lastName: string },
//     id: string,
//     databaseId: string
//   }
export type ItemType = any;

type FilterType = (
  items: ItemType[],
  inputValue: string,
  filterOptions?: { keys: string[] }
) => ItemType[];

type SelectFieldProps = {
  name: string;
  items?: ItemType[];
  label?: React.ReactNode;
  hint?: string;
  warning?: string;
  extraHeaderItems?: ItemType[];
  variant?: string;
  filterOptions?: { keys: unknown[] };
  filter?: FilterType;
  hideErrors?: boolean;
  iconComponent?: () => React.ReactElement;
  itemToString?: (item: ItemType) => string;
  renderItem?: (item: ItemType) => ItemType | React.ReactNode;
  emptyOption?: boolean;
  placeholder?: React.ReactNode;
  disabled?: boolean;
  onInputValueChange?: (
    inputValue: string,
    stateAndHelpers: ControllerStateAndHelpers<ItemType>
  ) => void;
  size?: string;
  sendForm?: string;
  style?: { [key: string]: string };
  onClick?: () => void;
  autoFocus?: boolean;
  postfix?: React.ReactNode;
  isVirtualizedList?: boolean;
  disabledSelected?: string[];
};

const SelectField = ({
  label,
  hint,
  warning,
  items = [],
  extraHeaderItems,
  variant,
  filterOptions,
  filter = matchSorter,
  hideErrors = false,
  iconComponent: Icon = ChevronBottomIcon,
  itemToString = (i: ItemType) => (i == null ? "" : String(i)),
  renderItem = itemToString,
  emptyOption,
  isVirtualizedList,
  disabledSelected,
  ...props
}: SelectFieldProps) => (
  <SingleDownshift itemToString={itemToString} {...props}>
    {({
      getRootProps,
      getInputProps,
      getToggleButtonProps,
      getItemProps,
      isOpen,
      highlightedIndex,
      selectedItem,
      clearSelection,
      input,
      inputValue,
      meta
    }) => (
      <Select
        {...getRootProps({ refKey: "refParent" })}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        getToggleButtonProps={getToggleButtonProps}
        getItemProps={getItemProps}
        isOpen={isOpen}
        highlightedIndex={highlightedIndex}
        selectedItem={selectedItem}
        clearSelection={clearSelection}
        input={input}
        inputValue={inputValue}
        meta={meta}
        label={label}
        hint={hint}
        variant={variant}
        emptyOption={emptyOption}
        iconComponent={Icon}
        items={items}
        extraHeaderItems={extraHeaderItems}
        itemToString={itemToString}
        renderItem={renderItem}
        filterOptions={filterOptions}
        filter={filter}
        hideErrors={hideErrors}
        warning={warning}
        isVirtualizedList={isVirtualizedList}
        disabledSelected={disabledSelected}
      />
    )}
  </SingleDownshift>
);

type SelectFieldViewProps = {
  onChange: (item: ItemType) => void;
  items: ItemType[];
  filter?: (
    items: ItemType[],
    inputValue: string,
    filterOptions?: { keys: string[] }
  ) => ItemType[];
  hideErrors?: boolean;
  iconComponent?: () => React.ReactElement;
  itemToString?: ((item: any) => string) | undefined;
  renderItem?: (item: ItemType) => string | ItemType;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  variant?: string;
  isVirtualizedList?: boolean;
};

export const SelectFieldView = ({
  items = [],
  filter = matchSorter,
  hideErrors = false,
  iconComponent: Icon = ChevronBottomIcon,
  itemToString = (i: ItemType) => (i == null ? "" : String(i)),
  renderItem = itemToString,
  ...restSelectProps
}: SelectFieldViewProps) => (
  <SingleDownshiftView itemToString={itemToString} {...restSelectProps}>
    {({ getRootProps, ...downshiftRenderProps }: $TSFixMe) => (
      <Select
        {...getRootProps({ refKey: "refParent" })}
        {...downshiftRenderProps}
        {...restSelectProps}
        getRootProps={getRootProps}
        hideErrors={hideErrors}
        filter={filter}
        items={items}
      />
    )}
  </SingleDownshiftView>
);

type SelectProps = PropGetters<ItemType> & {
  input: GetInputPropsOptions;
  meta: {
    error?:
      | string
      | {
          message: string;
          options: any;
        };
    errored?: boolean;
  };
  isOpen: boolean;
  value: string;
  placeholder: string;
  selectedItem: ItemType;
  clearSelection: () => void;
  disabled: boolean;
  inputValue: string;
  label: React.ReactNode;
  hint: string;
  variant: string;
  emptyOption: boolean;
  iconComponent: () => React.ReactElement;
  itemToString?: ((item: any) => string) | undefined;
  items: ItemType[];
  extraHeaderItems?: ItemType[];
  renderItem: (item: ItemType) => string | ItemType;
  filterOptions: { keys: string[] };
  filter: FilterType;
  hideErrors: boolean;
  warning: string;
  isVirtualizedList?: boolean;
  disabledSelected?: string[];
};

export const Select = ({
  getRootProps,
  getInputProps,
  getToggleButtonProps,
  getItemProps,
  isOpen,
  value,
  placeholder,
  selectedItem,
  clearSelection,
  disabled: inputDisabled,
  inputValue,
  input: { onFocus, onBlur, size, disabled, ...input } = {
    value,
    placeholder,
    disabled: inputDisabled
  },
  meta: { errored, error } = {},
  label,
  hint,
  variant,
  emptyOption,
  iconComponent: Icon = ChevronBottomIcon,
  items = [],
  extraHeaderItems = [],
  itemToString = (i: $TSFixMe) => (i == null ? "" : String(i)),
  renderItem = itemToString,
  filterOptions,
  filter = matchSorter,
  hideErrors = false,
  warning,
  isVirtualizedList,
  disabledSelected
}: SelectProps) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const btnRef = React.useRef<HTMLInputElement>();
  const [btnStyles, setBtnStyles] = React.useState<React.CSSProperties>({});
  if (isVirtualizedList) {
    const modal = document.getElementById("updateMedicalProgramSettingsModal");
    const getPos = React.useCallback(() => {
      // setTimeout wrapper with 500ms needed to correctly calculate initial dropdown button position:
      setTimeout(() => {
        btnRef.current &&
          setBtnStyles({
            position: "fixed",
            zIndex: 11,
            top:
              btnRef.current.getBoundingClientRect().top +
              btnRef.current.clientHeight,
            left: btnRef.current.getBoundingClientRect().left,
            width: btnRef.current.offsetParent
              ? btnRef.current.offsetParent.clientWidth - 1
              : 0
          });
      }, 500);
    }, []);

    // Trigger scroll to correctly calculate initial dropdown button position:
    React.useLayoutEffect(() => {
      modal &&
        btnRef.current &&
        modal.scrollTo(0, btnRef.current.getBoundingClientRect().top + 1);
    }, []);

    React.useLayoutEffect(() => {
      modal && modal.addEventListener("scroll", getPos);

      return () => {
        modal && modal.removeEventListener("scroll", getPos);
      };
    }, [btnStyles]);
  }

  useEffect(() => {
    if (isVirtualizedList) {
      if (isOpen) {
        inputRef.current && inputRef.current.focus();
      } else {
        inputRef.current && inputRef.current.blur();
      }
    }
  }, [isOpen]);

  const ddList = isOpen && (
    <List>
      {emptyOption && (
        <Dropdown.Item
          {...getItemProps({
            key: "emptyOption",
            index: 0,
            item: "",
            isSelected: selectedItem === ""
          })}
        >
          {renderItem("")}
        </Dropdown.Item>
      )}
      {extraHeaderItems.length > 0 &&
        extraHeaderItems.map((item, index) => (
          <Dropdown.Item
            {...getItemProps({
              key: `extraHeaderItem-${index}`,
              index: emptyOption ? index + 1 : index,
              item,
              isSelected: selectedItem === item
            })}
          >
            {renderItem(item)}
          </Dropdown.Item>
        ))}
      {filter(
        items,
        !variant && inputValue ? inputValue : "",
        filterOptions
      ).map((item, index) => {
        let adjustedIndex = index;
        if (emptyOption) {
          adjustedIndex += 1;
        }
        if (extraHeaderItems.length > 0) {
          adjustedIndex += extraHeaderItems.length;
        }

        return (
          <Dropdown.Item
            {...getItemProps({
              key: adjustedIndex,
              index: adjustedIndex,
              item,
              isSelected: selectedItem === item,
              disabled: disabledSelected && disabledSelected.includes(item)
            })}
          >
            {renderItem(item)}
          </Dropdown.Item>
        );
      })}
    </List>
  );

  return (
    <FieldView.Wrapper {...getRootProps({ refKey: "ref" })}>
      {label && (
        <FieldView.Header>
          <FieldView.Label>{label}</FieldView.Label>
          {hint && <FieldView.Message>{hint}</FieldView.Message>}
        </FieldView.Header>
      )}

      <InputView.Border
        position="relative"
        flexWrap="wrap"
        variant={errored && "errored"}
        ref={btnRef}
      >
        <InputView.Content
          {...getInputProps({
            is: "input",
            pl: 2,
            pr: 4,
            width: 0,
            disabled,
            ...input,
            variant,
            onChange: (e: $TSFixMe) => {
              if (e.target.value === "") {
                clearSelection();
              }
            }
          })}
          ref={inputRef}
        />
        <DropdownButton
          {...getToggleButtonProps({
            open: isOpen,
            disabled,
            // @ts-ignore
            variant,
            type: "div"
          })}
        >
          <Icon />
        </DropdownButton>

        {isVirtualizedList ? (
          <Portal styles={btnStyles}>{ddList}</Portal>
        ) : (
          ddList
        )}
      </InputView.Border>

      {!hideErrors && (
        <FieldView.Footer>
          <FieldView.Message variant={errored && "errored"}>
            {errored && error ? <ErrorTranslation error={error} /> : warning}
          </FieldView.Message>
        </FieldView.Footer>
      )}
    </FieldView.Wrapper>
  );
};

export default SelectField;
