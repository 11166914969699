import React, { useCallback } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { convertStringToBoolean, cleanDeep } from "@edenlabllc/ehealth-utils";
import { ProgramDeviceConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import Button from "../../../components/Button";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import ProgramDevicesTable from "./ProgramDevicesTable";
import { PrimarySearchFields, SecondarySearchFields } from "./SearchFields";

const Search = ({ navigate }: RouteComponentProps) => {
  const onAddParticipant = useCallback(
    () => navigate!("../create"),
    [navigate]
  );

  return (
    <Ability action="read" resource="program_device">
      <Box p={6}>
        <LocationParams>
          {({ locationParams, setLocationParams }: TLocationParams) => {
            const {
              filter: {
                isActive,
                deviceRequestAllowed,
                medicalProgram,
                medicalProgramId,
                startDate,
                endDate,
                ...params
              } = {}
            } = locationParams;

            const medicalProgramFilter: any = {
              medicalProgram: medicalProgram && {
                name: medicalProgram.name
              }
            };

            if (medicalProgramId) {
              medicalProgramFilter.medicalProgram = {
                ...medicalProgram,
                databaseId: medicalProgramId
              };
            }

            const programDevicesFilter = cleanDeep({
              ...params,
              ...medicalProgramFilter,
              isActive: convertStringToBoolean(isActive),
              deviceRequestAllowed:
                convertStringToBoolean(deviceRequestAllowed),
              ...(startDate && {
                startDate: `${startDate.from}/${startDate.to}`
              }),
              ...(endDate && {
                endDate: `${endDate.from}/${endDate.to}`
              })
            });

            return (
              <>
                <Flex
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={6}
                >
                  <Box>
                    <Heading as="h1" fontWeight="normal" mb={4}>
                      <Trans>Program devices</Trans>
                    </Heading>
                  </Box>
                  <Ability action="write" resource="program_device">
                    <Box>
                      <Button onClick={onAddParticipant} variant="green">
                        <Trans>Add participant</Trans>
                      </Button>
                    </Box>
                  </Ability>
                </Flex>
                <SearchForm
                  initialValues={locationParams}
                  onSubmit={setLocationParams}
                  renderPrimary={PrimarySearchFields}
                  renderSecondary={SecondarySearchFields}
                />
                <Query
                  query={SearchProgramDevicesQuery}
                  fetchPolicy="network-only"
                  variables={{
                    ...filteredLocationParams(locationParams),
                    filter: programDevicesFilter
                  }}
                >
                  {({
                    loading,
                    data
                  }: QueryResult<{
                    programDevices: ProgramDeviceConnection;
                  }>) => {
                    return (
                      <LoadingOverlay loading={loading}>
                        <>
                          <ProgramDevicesTable
                            locationParams={locationParams}
                            setLocationParams={setLocationParams}
                            programDevices={
                              data && data.programDevices
                                ? data.programDevices.nodes
                                : []
                            }
                          />
                          {data && data.programDevices && (
                            <Pagination
                              pageInfo={data.programDevices.pageInfo}
                            />
                          )}
                        </>
                      </LoadingOverlay>
                    );
                  }}
                </Query>
              </>
            );
          }}
        </LocationParams>
      </Box>
    </Ability>
  );
};

const SearchProgramDevicesQuery = gql`
  query SearchProgramDevicesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: ProgramDeviceFilter
    $orderBy: ProgramDeviceOrderBy
  ) {
    programDevices(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...ProgramDevices
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ProgramDevicesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
