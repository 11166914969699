import React, { useState } from "react";
import { MutationFunction } from "@apollo/client";
import { Mutation } from "@apollo/client/react/components";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";
import { RouteComponentProps, Router } from "@reach/router";
import { Box, Flex, Heading, Text } from "@rebass/emotion";
import { gql } from "graphql-tag";
import { get, isEmpty } from "lodash";
import Composer from "react-composer";

import {
  Form,
  Switch,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { CreateDeviceDefinitionInput } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import { RemoveButton } from "../../../../components/RemoveButton";
import Steps from "../../../../components/Steps";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";
import { UUID_PATTERN } from "../../../../constants/validationPatterns";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";
import STATUSES from "../../../../helpers/statuses";
import {
  DEVICE_DEFINITIONS_PROPERTIES_VALUE_TYPES,
  PROPERTIES_BOOLEAN_VALUES
} from "../../constants";

const Create = ({
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="./" state={state}>
          <Trans>General info</Trans>
        </Steps.Item>
        <Steps.Item to="./confirm" state={state} disabled>
          <Trans>Confirm</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>
    <Router>
      <GeneralForm path="/" />
      <Confirmation path="/confirm" />
    </Router>
  </>
);

const GeneralForm = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();
  const { createDeviceDefinitionGeneral } = state || {};

  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Create device</Trans>
      </Heading>
      <Form
        onSubmit={({ ...data }: CreateDeviceDefinitionInput) => {
          navigate!("./confirm", {
            state: {
              ...state,
              createDeviceDefinitionGeneral: {
                ...data
              }
            }
          });
        }}
        initialValues={
          createDeviceDefinitionGeneral || {
            deviceNames: [{ type: "", name: "" }]
          }
        }
      >
        <Flex mx={-1}>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter name"
              render={({ translation }) => (
                <Field.Text
                  name="description"
                  label={<Trans id="Device definition description" />}
                  placeholder={translation}
                />
              )}
            />
          </Box>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter device definition part number"
              render={({ translation }) => (
                <Field.Text
                  name="partNumber"
                  label={<Trans id="Device definition part number" />}
                  placeholder={translation}
                />
              )}
            />
          </Box>
        </Flex>

        <Flex mx={-1}>
          <Box width={2 / 5} px={1}>
            <Composer
              components={[
                <DictionaryValue name="device_definition_classification_type" />
              ]}
            >
              {([dict]: [DictionaryAllValuesJson]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="classificationType"
                    label={<Trans id="Device definition classification type" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required
              field="classificationType"
              message="Required field"
            />
          </Box>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter model number"
              render={({ translation }) => (
                <Field.Text
                  name="modelNumber"
                  label={<Trans id="Device definition model number" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Required field="modelNumber" message="Required field" />
          </Box>
        </Flex>

        <Flex mx={-1}>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter device definition manufacturer name"
              render={({ translation }) => (
                <Field.Text
                  name="manufacturerName"
                  label={<Trans id="Device definition manufacturer name" />}
                  placeholder={translation}
                />
              )}
            />
            <Validation.Required
              field="manufacturerName"
              message="Required field"
            />
          </Box>
          <Box width={2 / 5} px={1}>
            <Composer components={[<DictionaryValue name="COUNTRY" />]}>
              {([dict]: [DictionaryAllValuesJson]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="manufacturerCountry"
                    label={
                      <Trans id="Device definition manufacturer country" />
                    }
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required
              field="manufacturerCountry"
              message="Required field"
            />
          </Box>
        </Flex>

        <Flex mx={-1}>
          <Box width={2 / 5} px={1}>
            <Trans
              id="Enter device definition parent ID"
              render={({ translation }) => (
                <Field.Text
                  name="parentId"
                  label={<Trans id="Device definition parent ID" />}
                  placeholder={translation}
                  postfix={<SearchIcon color="silverCity" />}
                />
              )}
            />
            <Validation.Matches
              field="parentId"
              options={UUID_PATTERN}
              message="Invalid ID"
            />
          </Box>
        </Flex>
        <Flex mx={-1}>
          <Box width={2 / 5} px={1}>
            <Field.Textarea
              name="note"
              rows={6}
              label={<Trans id="Comment" />}
              maxLength={3000}
              showLengthHint
            />
          </Box>
        </Flex>

        <Line />
        <Text mb={5}>
          <Trans>Names</Trans>
        </Text>
        <Field.Array
          name="deviceNames"
          addText={<Trans>Add name</Trans>}
          removeButton={({ onClick }) => <RemoveButton onClick={onClick} />}
          fields={NamesForm}
          firstItemPinned
        />

        <Line />
        <Text mb={5}>
          <Trans>Packaging</Trans>
        </Text>
        <Flex mx={-1}>
          <Box px={1} width={2 / 5}>
            <Composer
              components={[
                <DictionaryValue name="device_definition_packaging_type" />
              ]}
            >
              {([dict]: [DictionaryAllValuesJson]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="packagingType"
                    label={<Trans id="Device definition packaging type" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required
              field="packagingType"
              message="Required field"
            />
          </Box>
          <Box px={1} width={2 / 5}>
            <Trans
              id="Enter device definition packaging count"
              render={({ translation }) => (
                <Field.Number
                  name="packagingCount"
                  label={<Trans id="Device definition packaging count" />}
                  placeholder={translation}
                />
              )}
            />
            <Validations field={"packagingCount"}>
              <Validation.Required message="Required field" />
              <Validation.PositiveFloat
                message={i18n._(t`Must be greater than zero`)}
              />
            </Validations>
          </Box>
        </Flex>
        <Flex mx={-1}>
          <Box px={1} width={2 / 5}>
            <Composer components={[<DictionaryValue name="device_unit" />]}>
              {([dict]: [DictionaryAllValuesJson]) => {
                const translation = i18n._(t`Select option`);
                return (
                  <Field.Select
                    name="packagingUnit"
                    label={<Trans id="Device definition packaging unit" />}
                    placeholder={translation}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || translation}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                );
              }}
            </Composer>
            <Validation.Required
              field="packagingUnit"
              message="Required field"
            />
          </Box>
        </Flex>

        <Line />
        <Text mb={5}>
          <Trans>Properties</Trans>
        </Text>
        <Field.Array
          name="properties"
          addText={<Trans>Add properties</Trans>}
          removeButton={({ onClick }) => <RemoveButton onClick={onClick} />}
          fields={PropertiesForm}
        />

        <Flex pt={5} mb={100}>
          <Box mr={3}>
            <Button
              type="reset"
              variant="blue"
              width={140}
              onClick={() => navigate!("../search")}
            >
              <Trans>Back</Trans>
            </Button>
          </Box>
          <Box>
            <Button variant="green" width={140}>
              <Trans>Next</Trans>
            </Button>
          </Box>
        </Flex>
      </Form>
    </Box>
  );
};

type NamesFormProps = {
  name: string;
};

export const NamesForm = ({ name }: NamesFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Box px={1} width={2 / 5}>
        <Composer components={[<DictionaryValue name="device_name_type" />]}>
          {([dict]: [DictionaryAllValuesJson]) => {
            const translation = i18n._(t`Select option`);
            return (
              <Field.Select
                name={`${name}.type`}
                label={<Trans id="Device definition name type" />}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || translation}
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            );
          }}
        </Composer>
        <Validations field={`${name}.type`}>
          <Validation.Required message="Required field" />
          <Validation.Custom
            options={({
              value,
              allValues: { deviceNames }
            }: {
              value: string;
              allValues: {
                deviceNames: Array<{
                  type: string;
                  name: string;
                }>;
              };
            }) => {
              const duplicates = deviceNames
                ? deviceNames.filter((item) => item && item.type === value)
                : [];
              return duplicates.length === 1;
            }}
            message={i18n._(t`This value is used more than once`)}
          />
        </Validations>
      </Box>
      <Box px={1} width={2 / 5}>
        <Trans
          id="Enter device definition name description"
          render={({ translation }) => (
            <Field.Text
              name={`${name}.name`}
              label={<Trans id="Device definition name description" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Required field={`${name}.name`} message="Required field" />
      </Box>
    </>
  );
};

type PropertiesFormProps = {
  name: string;
};

const PropertiesForm = ({ name }: PropertiesFormProps) => {
  const { i18n } = useLingui();

  return (
    <>
      <Form.Spy>
        {({ values }: $TSFixMe) => {
          const currentType = get(values, `${name}.type`);
          const currentValueType = get(values, `${name}.valueType`);

          return (
            <>
              <Box px={1} width={2 / 6}>
                <Composer
                  components={[<DictionaryValue name="device_properties" />]}
                >
                  {([dict]: [DictionaryAllValuesJson]) => {
                    const translation = i18n._(t`Select option`);
                    return (
                      <Field.Select
                        name={`${name}.type`}
                        label={<Trans id="Device definition properties type" />}
                        placeholder={translation}
                        items={Object.keys(dict)}
                        itemToString={(item: string) =>
                          dict[item] || translation
                        }
                        variant="select"
                        emptyOption
                        filterOptions={{ keys: [(item: string) => dict[item]] }}
                      />
                    );
                  }}
                </Composer>
                <Validation.Required
                  field={`${name}.type`}
                  message="Required field"
                />
              </Box>
              {currentType && (
                <Box px={1} width={2 / 6}>
                  <Trans
                    id="Select option"
                    render={({ translation }) => (
                      <Field.Select
                        name={`${name}.valueType`}
                        label={
                          <Trans id="Device definition properties value type" />
                        }
                        items={Object.keys(
                          DEVICE_DEFINITIONS_PROPERTIES_VALUE_TYPES
                        )}
                        itemToString={(item: string) =>
                          i18n._(
                            DEVICE_DEFINITIONS_PROPERTIES_VALUE_TYPES[item] ||
                              translation
                          )
                        }
                        variant="select"
                        filterOptions={{
                          keys: [
                            (item: string) =>
                              DEVICE_DEFINITIONS_PROPERTIES_VALUE_TYPES[item]
                          ]
                        }}
                      />
                    )}
                  />
                  <Validation.Required
                    field={`${name}.valueType`}
                    message="Required field"
                  />
                </Box>
              )}
              {currentType && currentValueType && (
                <Box px={1} width={2 / 6}>
                  <Switch
                    value={currentValueType}
                    value_integer={
                      <>
                        <Trans
                          id="Enter device definition properties value"
                          render={({ translation }) => (
                            <Field.Number
                              name={`${name}.value`}
                              label={
                                <Trans id="Device definition properties value" />
                              }
                              placeholder={translation}
                            />
                          )}
                        />
                        <Validations field={`${name}.value`}>
                          <Validation.Required message="Required field" />
                          <Validation.ZeroOrPositive message="Must be zero or positive number" />
                        </Validations>
                      </>
                    }
                    value_decimal={
                      <>
                        <Trans
                          id="Enter device definition properties value"
                          render={({ translation }) => (
                            <Field.Number
                              name={`${name}.value`}
                              label={
                                <Trans id="Device definition properties value" />
                              }
                              placeholder={translation}
                            />
                          )}
                        />
                        <Validations field={`${name}.value`}>
                          <Validation.Required message="Required field" />
                          <Validation.PositiveFloat
                            message={<Trans id="Must be greater than zero" />}
                          />
                        </Validations>
                      </>
                    }
                    value_boolean={
                      <>
                        <Trans
                          id="Select option"
                          render={({ translation }) => (
                            <Field.Select
                              name={`${name}.value`}
                              label={
                                <Trans id="Device definition properties value" />
                              }
                              items={Object.keys(STATUSES.YES_NO)}
                              itemToString={(index: number) =>
                                STATUSES.YES_NO[index] || translation
                              }
                              variant="select"
                              filterOptions={{
                                keys: [
                                  (index: number) => STATUSES.YES_NO[index]
                                ]
                              }}
                            />
                          )}
                        />
                        <Validation.Required
                          field={`${name}.value`}
                          message="Required field"
                        />
                      </>
                    }
                    default={
                      <>
                        <Trans
                          id="Enter device definition properties value"
                          render={({ translation }) => (
                            <Field.Text
                              name={`${name}.value`}
                              label={
                                <Trans id="Device definition properties value" />
                              }
                              placeholder={translation}
                            />
                          )}
                        />
                        <Validation.Required
                          field={`${name}.value`}
                          message="Required field"
                        />
                      </>
                    }
                  />
                </Box>
              )}
            </>
          );
        }}
      </Form.Spy>
    </>
  );
};

const Confirmation = ({
  navigate,
  // @ts-expect-error location state
  location: { state }
}: RouteComponentProps) => {
  const { i18n } = useLingui();
  const [error, setError] = useState<string | null>(null);
  const { createDeviceDefinitionGeneral } = state || {};

  if (!createDeviceDefinitionGeneral) return null;

  const {
    classificationType,
    description,
    deviceNames,
    manufacturerCountry,
    manufacturerName,
    modelNumber,
    note,
    packagingCount,
    packagingType,
    packagingUnit,
    parentId,
    partNumber,
    properties
  } = createDeviceDefinitionGeneral;

  const convertedProperties = properties && convertProperties(properties);

  return (
    <Box p={5}>
      <Heading as="h1" fontWeight="normal" mb={5}>
        <Trans>Create device definition</Trans>
      </Heading>
      <DefinitionListView
        labels={{
          description: <Trans id="Device definition description" />,
          partNumber: <Trans id="Device definition part number" />,
          classificationType: (
            <Trans id="Device definition classification type" />
          ),
          modelNumber: <Trans id="Device definition model number" />,
          manufacturerName: <Trans id="Device definition manufacturer name" />,
          manufacturerCountry: (
            <Trans id="Device definition manufacturer country" />
          ),
          parentId: <Trans id="Device definition parent ID" />,
          note: <Trans id="Comment" />
        }}
        data={{
          ...(description && {
            description
          }),
          ...(partNumber && {
            partNumber
          }),
          classificationType: (
            <DictionaryValue
              name="device_definition_classification_type"
              item={classificationType}
            />
          ),
          modelNumber,
          manufacturerName,
          manufacturerCountry: (
            <DictionaryValue name="COUNTRY" item={manufacturerCountry} />
          ),
          ...(parentId && {
            parentId
          }),
          ...(note && {
            note
          })
        }}
        labelWidth="250px"
      />

      <Line />
      <Text mb={5}>
        <Trans>Names</Trans>
      </Text>
      <DefinitionListView
        labels={{
          field: <Trans id="Device definition name type" />
        }}
        data={{
          field: (
            <b>
              <Trans id="Device definition name description" />
            </b>
          )
        }}
        labelWidth="250px"
      />
      {deviceNames.map(({ type, name }: { type: string; name: string }) => (
        <Box key={type} pb={4}>
          <DefinitionListView
            labels={{
              field: <DictionaryValue name="device_name_type" item={type} />
            }}
            data={{
              field: name
            }}
            labelWidth="250px"
          />
        </Box>
      ))}

      <Line />
      <Text mb={5}>
        <Trans>Packaging</Trans>
      </Text>
      <DefinitionListView
        labels={{
          packagingType: <Trans id="Device definition packaging type" />,
          packagingUnit: <Trans id="Device definition packaging unit" />,
          packagingCount: <Trans id="Device definition packaging count" />
        }}
        data={{
          packagingType: (
            <DictionaryValue
              name="device_definition_packaging_type"
              item={packagingType}
            />
          ),
          packagingUnit: (
            <DictionaryValue name="device_unit" item={packagingUnit} />
          ),
          packagingCount
        }}
        labelWidth="250px"
      />

      {!isEmpty(convertedProperties) && (
        <>
          <Line />
          <Text mb={5}>
            <Trans>Properties</Trans>
          </Text>
          <DefinitionListView
            labels={{
              field: <Trans id="Device definition properties type" />
            }}
            data={{
              field: (
                <b>
                  <Trans id="Device definition properties value" />
                </b>
              )
            }}
            labelWidth="250px"
          />
          {convertedProperties.map(
            ({
              type,
              valueString,
              valueInteger,
              valueDecimal,
              valueBoolean
            }: {
              type: string;
              valueInteger?: number;
              valueDecimal?: number;
              valueBoolean?: boolean;
              valueString?: string;
            }) => {
              const value =
                valueString ||
                valueInteger ||
                valueDecimal ||
                (valueBoolean !== undefined &&
                  i18n._(PROPERTIES_BOOLEAN_VALUES[valueBoolean.toString()]));

              return (
                <Box key={`${type}-${value}`} pb={4}>
                  <DefinitionListView
                    labels={{
                      field: (
                        <DictionaryValue name="device_properties" item={type} />
                      )
                    }}
                    data={{
                      field: value
                    }}
                    labelWidth="250px"
                  />
                </Box>
              );
            }
          )}
        </>
      )}

      <Flex mt={5}>
        <Box mr={3}>
          <Button
            variant="blue"
            width={140}
            onClick={() => {
              navigate!("../", {
                state
              });
            }}
          >
            <Trans>Back</Trans>
          </Button>
        </Box>
        <Box>
          <Mutation mutation={CreateDeviceDefinitionMutation}>
            {(createDeviceDefinition: MutationFunction) => (
              <>
                <Button
                  variant="green"
                  width={140}
                  onClick={async () => {
                    setError(null);

                    try {
                      const { data } = await createDeviceDefinition({
                        variables: {
                          input: {
                            ...createDeviceDefinitionGeneral,
                            packagingCount: Number(
                              createDeviceDefinitionGeneral.packagingCount
                            ),
                            properties: !isEmpty(properties)
                              ? convertedProperties
                              : undefined
                          }
                        }
                      });
                      if (data.createDeviceDefinition) {
                        await navigate!("../../search");
                      }
                    } catch (error) {
                      if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                        const errorTranslation = handleTranslateErrors(
                          getErrorMessage(error),
                          i18n
                        );
                        setError(errorTranslation);
                      }
                    }
                  }}
                >
                  <Trans>Add</Trans>
                </Button>

                {error && (
                  <UnpocessableEntityModalError
                    errorMessage={error}
                    isModalOpen
                  />
                )}
              </>
            )}
          </Mutation>
        </Box>
      </Flex>
    </Box>
  );
};

type PropertyType = {
  type: string;
  valueType: string;
  value: string;
};

type ConvertedPropertyType =
  | {
      type: string;
      valueInteger: number;
    }
  | {
      type: string;
      valueDecimal: number;
    }
  | {
      type: string;
      valueBoolean: boolean;
    }
  | {
      type: string;
      valueString: string;
    };

const convertProperties = (data: PropertyType[]): ConvertedPropertyType[] => {
  return data.map(({ type, valueType, value }) => {
    switch (valueType) {
      case "value_integer":
        return { type, valueInteger: parseInt(value, 10) };
      case "value_decimal":
        return { type, valueDecimal: parseFloat(value) };
      case "value_boolean":
        return { type, valueBoolean: JSON.parse(value) };
      default:
        return { type, valueString: value };
    }
  });
};

const CreateDeviceDefinitionMutation = gql`
  mutation CreateDeviceDefinitionMutation(
    $input: CreateDeviceDefinitionInput!
  ) {
    createDeviceDefinition(input: $input) {
      deviceDefinition {
        id
      }
    }
  }
`;

const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    case "Active device definition with the same classification_type, manufacturer_name, model_number, packaging_count, part_number already exists":
      return i18n._(
        t`Active device definition with the same classification_type, manufacturer_name, model_number, packaging_count, part_number already exists`
      );
    default:
      return errorMessage;
  }
};

export default Create;
