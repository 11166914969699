import React from "react";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex } from "@rebass/emotion";

import { Validation, Validations } from "@edenlabllc/ehealth-components";

import * as Field from "../../../components/Field";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";

import { UUID_PATTERN } from "../../../constants/validationPatterns";
import { MEDICAL_COMPOSITION_NUMBER } from "../../../constants/mask";

const PrimarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={2 / 4}>
        <Field.MaskedField
          name="filter.title"
          label={<Trans id="Medical composition number" />}
          placeholder={"хххх-хххх-хххх-хххх"}
          mask={MEDICAL_COMPOSITION_NUMBER}
          guide={false}
        />
        <Validations field={"filter.title"}>
          <Trans
            id="Filter, subject or title should be set"
            render={({ translation }) => (
              <Validation.Custom
                field="filter.title"
                dynamic={true}
                options={({
                  allValues: { filter }
                }: {
                  allValues: {
                    filter: {
                      title?: string;
                      focus?: string;
                      subject?: string;
                    };
                  };
                }) => {
                  return filter.title || filter.focus || filter.subject;
                }}
                message={translation}
              />
            )}
          />
        </Validations>
      </Box>
      <Box px={1} width={0.4 / 4} />
      <Box px={1} width={1.6 / 4}>
        <Composer
          components={[
            <DictionaryValue name="COMPOSITION_TYPES" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Enter medical composition type" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.type"
              label={<Trans id="Type" />}
              items={Object.keys(dict)}
              itemToString={(item) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={2 / 4}>
        <Trans
          id="Enter patient id"
          render={({ translation }) => (
            <>
              <Field.Input
                name="filter.subject"
                label={<Trans id="Patient" />}
                placeholder={translation}
              />
              <Validations field="filter.subject">
                <Validation.Matches
                  field="filter.subject"
                  options={UUID_PATTERN}
                  message="Invalid ID"
                />
                <Validation.Custom
                  dynamic={true}
                  options={({
                    allValues: { filter }
                  }: {
                    allValues: {
                      filter: {
                        title?: string;
                        focus?: string;
                        subject?: string;
                      };
                    };
                  }) => {
                    return filter.title || filter.focus || filter.subject;
                  }}
                  message="Filter, subject or title should be set"
                />
              </Validations>
            </>
          )}
        />
      </Box>
      <Box px={1} width={0.4 / 4} />
      <Box px={1} width={1.6 / 4}>
        <Composer
          components={[
            <DictionaryValue name="COMPOSITION_STATUS" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Enter status" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.compositionStatus"
              label={<Trans id="Status" />}
              items={Object.keys(dict)}
              itemToString={(item) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
    </Flex>

    <Flex mx={-1}>
      <Box px={1} width={2 / 4}>
        <Trans
          id="Enter medical composition focus"
          render={({ translation }) => (
            <>
              <Field.Input
                name="filter.focus"
                label={<Trans id="Medical composition focus" />}
                placeholder={translation}
              />
              <Validations field="filter.focus">
                <Validation.Matches
                  field="filter.focus"
                  options={UUID_PATTERN}
                  message="Invalid ID"
                />
                <Validation.Custom
                  dynamic={true}
                  options={({
                    allValues: { filter }
                  }: {
                    allValues: {
                      filter: {
                        title?: string;
                        focus?: string;
                        subject?: string;
                      };
                    };
                  }) => {
                    return filter.title || filter.focus || filter.subject;
                  }}
                  message="Filter, subject or title should be set"
                />
              </Validations>
            </>
          )}
        />
      </Box>
    </Flex>
  </>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={2 / 4}>
        <Trans
          id="Author"
          render={({ translation }) => (
            <>
              <Field.Input
                name="filter.author"
                label={<Trans id="Author" />}
                placeholder={translation}
              />
              <Validations field="filter.author">
                <Validation.Matches
                  field="filter.author"
                  options={UUID_PATTERN}
                  message="Invalid ID"
                />
              </Validations>
            </>
          )}
        />
      </Box>
      <Box px={1} width={0.4 / 4} />
      <Box px={1} width={1.6 / 4}>
        <Field.RangePicker
          rangeNames={["filter.from", "filter.to"]}
          label={<Trans id="Created at" />}
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={2 / 4}>
        <Trans
          id="Custodian"
          render={({ translation }) => (
            <>
              <Field.Input
                name="filter.custodian"
                label={<Trans id="Custodian" />}
                placeholder={translation}
              />
              <Validations field="filter.custodian">
                <Validation.Matches
                  field="filter.custodian"
                  options={UUID_PATTERN}
                  message="Invalid ID"
                />
              </Validations>
            </>
          )}
        />
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
