import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { Employee, LegalEntity, Party } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";

type GeneralInfoEmployeeProps = RouteComponentProps & {
  party: Party;
  isActive: Employee["isActive"];
  position: Employee["position"];
  employeeType: Employee["employeeType"];
  startDate: Employee["startDate"];
  endDate: Employee["endDate"];
  division: Employee["division"];
  legalEntity: LegalEntity;
};

const GeneralInfoEmployee = ({
  party: { id, databaseId },
  isActive,
  position,
  employeeType,
  startDate,
  endDate,
  division,
  legalEntity
}: GeneralInfoEmployeeProps) => (
  <Box p={5}>
    <DefinitionListView
      labels={{
        databaseId: <Trans>Employee party ID</Trans>,
        position: <Trans>Position</Trans>,
        employeeType: <Trans>Employee type</Trans>,
        startDate: <Trans>Employment start date</Trans>,
        endDate: <Trans>Employment end date</Trans>,
        legalEntity: <Trans>Facility</Trans>,
        division: <Trans>Division</Trans>,
        isActive: <Trans>Is employee active</Trans>
      }}
      data={{
        databaseId: (
          <Link to={`../../parties/${id}`} fontWeight="bold">
            {databaseId}
          </Link>
        ),
        position: <DictionaryValue name="POSITION" item={position} />,
        employeeType: (
          <DictionaryValue name="EMPLOYEE_TYPE" item={employeeType} />
        ),
        startDate: i18n.date(startDate),
        endDate: endDate && i18n.date(endDate),
        legalEntity: legalEntity && (
          <Link to={`../../legal-entities/${legalEntity.id}`} fontWeight="bold">
            {legalEntity.edrData ? legalEntity.edrData.name : legalEntity.name}
          </Link>
        ),
        division: division && (
          <Link
            to={`../../legal-entities/${legalEntity.id}/divisions/${division.id}`}
            fontWeight="bold"
          >
            {division.name}
          </Link>
        ),
        isActive: isActive ? <Trans>Yes</Trans> : <Trans>No</Trans>
      }}
      labelWidth="200px"
    />
  </Box>
);

GeneralInfoEmployee.fragments = {
  entry: gql`
    fragment GeneralInfoEmployee on Employee {
      party {
        id
        databaseId
      }
      position
      startDate
      endDate
      isActive
      division {
        id
        databaseId
        name
      }
    }
  `
};

export default GeneralInfoEmployee;
