import React from "react";
import Composer from "react-composer";
import { TransRenderProps } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";

import STATUSES from "../../../helpers/statuses";
import {
  UUID_PATTERN,
  CYRILLIC_NAME
} from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 4}>
        <>
          <Trans
            id="Enter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.databaseId"
                label={<Trans id="ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.databaseId"
            options={UUID_PATTERN}
            message="Invalid ID"
          />
        </>
      </Box>
      <Box px={1} width={1 / 4}>
        <>
          <Trans
            id="Enter full name"
            render={({ translation }) => (
              <Field.Text
                name="filter.fullName"
                label={<Trans id="Full name" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.fullName"
            options={CYRILLIC_NAME}
            message="Invalid name"
          />
        </>
      </Box>
      <Box px={1} width={1 / 4}>
        <Composer
          components={[
            <DictionaryValue name="PARTY_VERIFICATION_STATUSES" />,
            ({
              render
            }: {
              render: (props: TransRenderProps) => React.ReactElement;
            }) => <Trans id="Select option" render={render} />
          ]}
        >
          {([dict, { translation }]: [
            DictionaryAllValuesJson,
            { translation: React.ReactNode }
          ]) => (
            <Field.Select
              name="filter.drfoVerificationStatus"
              label={<Trans id="DRFO verification status" />}
              placeholder={translation}
              items={Object.keys(dict)}
              itemToString={(item) => dict[item] || String(translation)}
              variant="select"
              emptyOption
              filterOptions={{ keys: [(item: string) => dict[item]] }}
            />
          )}
        </Composer>
      </Box>
      <Box px={1} width={1 / 4}>
        <Trans
          id="Select option"
          render={({ translation }) => (
            <Field.Select
              name="filter.dracsDeathVerificationStatus"
              label={<Trans id="DRACS verification status" />}
              items={Object.keys(STATUSES.DRACS_STATUS_VERIFICATION)}
              itemToString={(item) =>
                STATUSES.DRACS_STATUS_VERIFICATION[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                keys: [
                  (item: string) => STATUSES.DRACS_STATUS_VERIFICATION[item]
                ]
              }}
            />
          )}
        />
      </Box>
    </Flex>
    <Flex mx={-1}>
      <Box px={1} width={1 / 4}>
        <Trans
          id="Select option"
          render={({ translation }) => (
            <Field.Select
              name="filter.noTaxId"
              label={<Trans id="Tax ID existance" />}
              items={Object.keys(STATUSES.NO_TAX_ID)}
              itemToString={(item: boolean) =>
                // @ts-expect-error statuses boolean key
                STATUSES.NO_TAX_ID[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                // @ts-expect-error statuses boolean key
                keys: [(item: boolean) => STATUSES.NO_TAX_ID[item]]
              }}
            />
          )}
        />
      </Box>
      <Form.Spy>
        {({ values }: $TSFixMe) => {
          const status =
            values &&
            values.filter &&
            values.filter.dracsDeathVerificationStatus;

          return (
            <Box px={1} width={1 / 2}>
              <Field.RangeNumber
                rangeNames={[
                  "filter.dracsDeathUnverifiedAt.start",
                  "filter.dracsDeathUnverifiedAt.end"
                ]}
                label={<Trans id="Dracs death unverified, days" />}
                disabled={status !== "NOT_VERIFIED"}
              />
            </Box>
          );
        }}
      </Form.Spy>
    </Flex>
  </>
);

export { PrimarySearchFields };
