import React from "react";
import { NavigateFn, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams, Modal } from "@edenlabllc/ehealth-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  convertStringToBoolean,
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { InnmDosage, InnmDosageConnection } from "@edenlabllc/graphql-schema";

import Badge from "../../components/Badge";
import Button from "../../components/Button";
import Close from "../../components/Close";
import DictionaryValue from "../../components/DictionaryValue";
import Dosage from "../../components/Dosage";
import EmptyData from "../../components/EmptyData";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Table, { SortingParams } from "../../components/Table";
import {
  TLocationParams,
  SearchParams,
  SetSearchParams
} from "../../components/SearchForm";

import filteredLocationParams from "../../helpers/filteredLocationParams";

import {
  PrimarySearchFields,
  SecondarySearchFields
} from "../INNMDosages/Search/SearchFields";
import { dosageFormIsDosedValues } from "../INNMDosages/constants";

type MedicationModalFormProps = RouteComponentProps<{
  navigate: NavigateFn;
}>;

const InnmDosageModalForm = ({ navigate }: MedicationModalFormProps) => {
  const onClose = () => navigate!("../");

  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          filter: { innmdosage, isActive, dosageFormIsDosed, ...params } = {}
        } = locationParams;

        const innmDosagesFilter = {
          ...params,
          name: innmdosage && innmdosage.name,
          isActive: convertStringToBoolean(isActive),
          dosageFormIsDosed:
            dosageFormIsDosed === "null"
              ? null
              : convertStringToBoolean(dosageFormIsDosed)
        };

        return (
          <Modal backdrop width="90%">
            <Close onClick={onClose} />
            <Form onSubmit={setLocationParams} initialValues={locationParams}>
              <PrimarySearchFields />
              <SecondarySearchFields />
              <Flex mx={-1} mt={4} justifyContent="flex-start">
                <Box px={1}>
                  <Button variant="red" onClick={onClose}>
                    <Trans>Close</Trans>
                  </Button>
                </Box>
                <Box px={1}>
                  <Button variant="blue">
                    <Trans>Search</Trans>
                  </Button>
                </Box>
                {!isEmpty(locationParams.filter) && (
                  <Box px={1}>
                    <Button
                      variant="link"
                      icon={RemoveItemIcon}
                      px={1}
                      type="reset"
                      onClick={() => {
                        setLocationParams({
                          ...locationParams,
                          filter: {}
                        });
                      }}
                    >
                      <Trans>Reset</Trans>
                    </Button>
                  </Box>
                )}
              </Flex>
            </Form>
            <Query
              query={SearchINNMDosagesQuery}
              fetchPolicy="network-only"
              variables={{
                ...filteredLocationParams(locationParams),
                filter: innmDosagesFilter
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{ innmDosages: InnmDosageConnection }>) => (
                <LoadingOverlay loading={loading}>
                  {isEmpty(data && data.innmDosages.nodes) ? (
                    <Box m={7}>
                      <EmptyData height={100} />
                    </Box>
                  ) : (
                    <>
                      <INNMDosagesTable
                        locationParams={locationParams}
                        innmDosages={data && data.innmDosages.nodes}
                        setLocationParams={setLocationParams}
                      />
                      <Pagination
                        pageInfo={data && data.innmDosages.pageInfo}
                      />
                    </>
                  )}
                </LoadingOverlay>
              )}
            </Query>
          </Modal>
        );
      }}
    </LocationParams>
  );
};

type INNMDosagesTableProps = {
  innmDosages: InnmDosageConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const INNMDosagesTable = ({
  innmDosages,
  locationParams,
  setLocationParams
}: INNMDosagesTableProps) => (
  <Table
    data={innmDosages}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>INNM dosage name</Trans>,
      form: <Trans>Medication form</Trans>,
      dosageFormIsDosed: <Trans>Is Dosage Form dosed</Trans>,
      mrBlankType: <Trans>Type of Medication request blank</Trans>,
      dailyDosage: <Trans>Daily dosage</Trans>,
      isActive: <Trans>Status</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={({
      id,
      form,
      mrBlankType,
      dosageFormIsDosed,
      isActive,
      insertedAt,
      dailyDosage,
      databaseId,
      name
    }: InnmDosage) => {
      return {
        databaseId,
        name,
        form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
        dosageFormIsDosed: (
          <Flex justifyContent="center">
            {/* @ts-expect-error statuses boolean key */}
            {dosageFormIsDosedValues[dosageFormIsDosed]}
          </Flex>
        ),
        mrBlankType: mrBlankType && (
          <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
        ),
        dailyDosage: <Dosage dosage={dailyDosage} />,
        isActive: (
          <Badge
            type="ACTIVE_STATUS_F"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        insertedAt: i18n.date(insertedAt),
        action: (
          <Link
            to={`../`}
            state={{ medication: { id, name, databaseId } }}
            fontWeight="bold"
          >
            <Trans>Select</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["form", "name", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="innmDosages/search"
    hiddenFields="dailyDosage,maxDailyDosage,dosageFormIsDosed"
  />
);

INNMDosagesTable.fragments = {
  entry: gql`
    fragment INNMDosages on INNMDosage {
      id
      databaseId
      name
      form
      dailyDosage
      isActive
      insertedAt
      dosageFormIsDosed
      mrBlankType
    }
  `
};

const SearchINNMDosagesQuery = gql`
  query SearchINNMDosagesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: INNMDosageFilter
    $orderBy: INNMDosageOrderBy
    $skip: Boolean! = false
  ) {
    innmDosages(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...INNMDosages
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${INNMDosagesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default InnmDosageModalForm;
