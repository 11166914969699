import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import { get } from "lodash";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Validation } from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { Conclusion, ConclusionConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../components/Ability";
import DictionaryValue from "../../components/DictionaryValue";
import EmptyData from "../../components/EmptyData";
import * as Field from "../../components/Field";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import SearchForm, { TLocationParams } from "../../components/SearchForm";
import Pagination from "../../components/Pagination";
import Table, { SortingParams } from "../../components/Table";

import pagination from "../../helpers/pagination";
import dateFormatter from "../../helpers/dateFormatter";
import paramToBase64 from "../../helpers/paramToBase64";
import { getReferenceProps } from "../../helpers/useMemoizedReference";
import { UUID_PATTERN } from "../../constants/validationPatterns";
import { DATE_TIME_FORMAT } from "../../constants/dateFormats";

import DetailsPageBreadcrumbs from "../PracticalMonitoring/Details/DetailsPageBreadcrumbs";
import Reference from "../PracticalMonitoring/Details/Reference";
import { PatientSearchField } from "./PatientSearchField";

const ConclusionsSearch = (_props: RouteComponentProps) => {
  const { i18n } = useLingui();

  return (
    <Ability action="read" resource="conclusion">
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => (
          <Box p={6}>
            <DetailsPageBreadcrumbs
              currentStepName={<Trans>Search conclusions</Trans>}
              isClinicalMonitoring={true}
            />
            <Query
              skip={!locationParams.first && !locationParams.last}
              query={ConclusionsQuery}
              fetchPolicy="network-only"
              variables={{
                ...pagination(locationParams),
                orderBy: locationParams.orderBy
                  ? locationParams.orderBy
                  : "INSERTED_AT_DESC",
                filter: locationParams.filter
              }}
            >
              {({
                data,
                loading
              }: QueryResult<{ conclusions: ConclusionConnection }>) => (
                <LoadingOverlay loading={loading}>
                  <Box p={1}>
                    <SearchForm
                      initialValues={locationParams}
                      onSubmit={setLocationParams}
                      renderPrimary={primarySearchFields}
                      onResetSkipPagination
                    />
                  </Box>
                  {isEmpty(data) ||
                  isEmpty(data.conclusions) ||
                  isEmpty(data.conclusions.nodes) ? (
                    <EmptyData mx={2} />
                  ) : (
                    <Box mb={6} p={2}>
                      <Box>
                        <Text>
                          <Trans>Total found</Trans>:{" "}
                          {data.conclusions.totalEntries}
                        </Text>
                      </Box>
                      <Table
                        data={data.conclusions.nodes}
                        header={{
                          patientId: <Trans>Assignee id</Trans>,
                          resourceId: <Trans>Resource ID</Trans>,
                          type: <Trans>Medical event name</Trans>,
                          conclusionType: <Trans>Conclusion</Trans>,
                          insertedAt: <Trans>Added</Trans>,
                          action: <Trans>Action</Trans>
                        }}
                        renderRow={({
                          consumer,
                          conclusionType,
                          resource,
                          insertedAt
                        }: Conclusion) => {
                          const [memoizedReferenceType, memoizedReferenceSlug] =
                            getReferenceProps(resource);

                          return {
                            patientId: consumer.userId,
                            resourceId: resource.identifier.value,
                            type: get(
                              resource,
                              "identifier.type.coding[0].code"
                            ),
                            conclusionType: (
                              <DictionaryValue
                                name="eHealth/clinical_monitoring_conclusion"
                                item={conclusionType.toLowerCase()}
                              />
                            ),
                            insertedAt: dateFormatter(
                              i18n.locale,
                              DATE_TIME_FORMAT,
                              insertedAt
                            ),
                            action: (
                              <Link
                                to={`../${memoizedReferenceSlug}/${
                                  locationParams.filter &&
                                  locationParams.filter.patientId
                                }/${paramToBase64(
                                  memoizedReferenceType,
                                  resource.identifier.value
                                )}`}
                                fontWeight="bold"
                              >
                                <Trans>Details</Trans>
                              </Link>
                            )
                          };
                        }}
                        sortableFields={["insertedAt", "status"]}
                        sortingParams={parseSortingParams(
                          locationParams.orderBy
                        )}
                        onSortingChange={(sortingParams: SortingParams) =>
                          setLocationParams({
                            ...locationParams,
                            orderBy: stringifySortingParams(sortingParams)
                          })
                        }
                        tableName="conclusions"
                      />
                      <Pagination pageInfo={data.conclusions.pageInfo} />
                    </Box>
                  )}
                </LoadingOverlay>
              )}
            </Query>
          </Box>
        )}
      </LocationParams>
    </Ability>
  );
};

export default ConclusionsSearch;

const primarySearchFields = () => (
  <Flex flexDirection="column">
    <PatientSearchField isClinicalMonitoring={true} />
    <Flex>
      <Box px={1} width={1} mt={3}>
        <Trans
          id="Enter resource ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.resourceId"
              label={<Trans id="Resource ID" />}
              placeholder={translation}
            />
          )}
        />
        <Validation.Matches
          field="filter.resourceId"
          options={UUID_PATTERN}
          message="Invalid resource ID"
        />
      </Box>
    </Flex>
  </Flex>
);

const ConclusionsQuery = gql`
  query ConclusionsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ConclusionFilter!
    $orderBy: ConclusionOrderBy
  ) {
    conclusions(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        resource {
          ...Reference
        }
        conclusionType
        consumer {
          userId
        }
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${Reference.fragments.entry}
`;
