import React from "react";
import { NavigateFn } from "@reach/router";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction, MutationResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Flex, Heading, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  SUBMIT_ERROR
} from "@edenlabllc/ehealth-components";

import Popup from "../../../../components/Popup";
import { TLocationParams } from "../../../../components/SearchForm";

import pagination from "../../../../helpers/pagination";
import { defaultError } from "../../../../constants/submitErrors";

import { PersonQuery } from "../../../Persons/Details";
import { UpdatePersonDracsDeathVerificationStatusMutation } from "./Verify";

type RejectVerifyProps = {
  isVisible: boolean;
  onCancel: () => void;
  personId: string;
  navigate: NavigateFn;
  verificationComment: string;
};

export const RejectVerify = ({
  isVisible,
  onCancel,
  personId,
  navigate,
  verificationComment
}: RejectVerifyProps) => {
  const { i18n } = useLingui();

  return (
    <LocationParams>
      {({ locationParams }: TLocationParams) => (
        <Popup
          visible={isVisible}
          okText={<Trans>Not verify</Trans>}
          onCancel={onCancel}
          formId="verifyPerson"
          okButtonProps={{ variant: "orange", width: 220 }}
          cancelButtonProps={{ variant: "blue", width: 220 }}
        >
          <Mutation
            mutation={UpdatePersonDracsDeathVerificationStatusMutation}
            refetchQueries={() => [
              {
                query: PersonQuery,
                variables: {
                  id: personId,
                  ...pagination(locationParams)
                }
              }
            ]}
          >
            {(
              updatePersonDracsDeathVerificationStatus: MutationFunction,
              { error }: MutationResult
            ) => (
              <Form
                onSubmit={async () => {
                  const { data } =
                    await updatePersonDracsDeathVerificationStatus({
                      variables: {
                        input: {
                          personId,
                          dracsDeathVerificationStatus: "NOT_VERIFIED",
                          dracsDeathVerificationReason: "MANUAL",
                          verificationComment
                        }
                      }
                    });

                  if (
                    error ||
                    isEmpty(data.updatePersonDracsDeathVerificationStatus)
                  ) {
                    return { [SUBMIT_ERROR]: defaultError };
                  }

                  onCancel();

                  await navigate("../../");
                }}
                id="verifyPerson"
              >
                <Heading as="h1" fontWeight="normal" mb={6}>
                  <Trans>Reject verification of Patient's personal data</Trans>
                </Heading>
                <Text mb={6}>
                  <Trans>
                    Warning! <br />
                    After setting of verification status to "Not verified", this
                    action cannot be reversed
                  </Trans>
                </Text>
                <Flex justifyContent="center">
                  <Form.Error
                    default={i18n._(
                      t`Something went wrong. Please try again later`
                    )}
                  />
                </Flex>
              </Form>
            )}
          </Mutation>
        </Popup>
      )}
    </LocationParams>
  );
};
