import React from "react";
import { gql } from "graphql-tag";
import { Trans, Plural } from "@lingui/macro";
import { i18n } from "@lingui/core";
import differenceInSeconds from "date-fns/difference_in_seconds";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  PersonsDeactivationJob,
  PersonsDeactivationJobConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";

type PersonsDeactivationJobsTableProps = {
  personsDeactivationJobs: PersonsDeactivationJobConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const PersonsDeactivationJobsTable = ({
  personsDeactivationJobs,
  locationParams,
  setLocationParams
}: PersonsDeactivationJobsTableProps) => (
  <Table
    data={personsDeactivationJobs}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Job name</Trans>,
      registerType: <Trans>Register type</Trans>,
      startedAt: <Trans>Started at</Trans>,
      executionTime: <Trans>Execution time</Trans>,
      status: <Trans>Job status</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      databaseId,
      name,
      meta,
      status,
      startedAt,
      endedAt
    }: PersonsDeactivationJob) => ({
      databaseId,
      name,
      registerType: meta && (
        <DictionaryValue name="REGISTER_TYPE" item={meta.registerType} />
      ),
      startedAt: dateFormatter(
        i18n.locale,
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        },
        startedAt
      ),
      executionTime:
        status === "PENDING" || !endedAt || !startedAt ? (
          "-"
        ) : (
          <Plural
            id="{0, plural, zero {# секунд} one {# секунда} few {# секунди} many {# секунд} other {# секунд}}"
            value={differenceInSeconds(endedAt, startedAt)}
            zero="# seconds"
            one="# second"
            few="# seconds"
            many="# seconds"
            other="# seconds"
          />
        ),
      status: <Badge type="JOBS" name={status} display="block" />,
      details: (
        <Link to={`../${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["startedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="name"
    tableName="persons-deactivation-jobs/search"
  />
);

PersonsDeactivationJobsTable.fragments = {
  entry: gql`
    fragment PersonsDeactivationJobs on PersonsDeactivationJob {
      id
      databaseId
      name
      meta {
        registerType
      }
      status
      startedAt
      endedAt
    }
  `
};

export default PersonsDeactivationJobsTable;
