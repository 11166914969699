import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Text } from "@rebass/emotion";
import isEqual from "lodash/isEqual";

import { ConfidantPerson, Person } from "@edenlabllc/graphql-schema";

import Line from "../../../../components/Line";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";

import TableDiff from "./TableDiff";
import DocumentsTable from "./DocumentsTable";
import PhonesTable from "./PhonesTable";
import { Phones } from "./EditPerson";

type ConfidantPersonsTableProps = {
  header: DictionaryAllValuesJson;
  person: Person["confidantPersons"];
  masterPerson: Person["confidantPersons"];
};

const ConfidantPersonsTable = ({
  header,
  person,
  masterPerson
}: ConfidantPersonsTableProps) => {
  const CONFIDANT_PERSONS_TYPES: any = Object.keys(header).reduce(
    (types, type) => ({ ...types, [type]: type }),
    {}
  );

  const primaryPersons: any = [
    person
      ? person.find(
          (item) =>
            item && item.relationType === CONFIDANT_PERSONS_TYPES.PRIMARY
        )
      : undefined,
    masterPerson
      ? masterPerson.find(
          (item) =>
            item && item.relationType === CONFIDANT_PERSONS_TYPES.PRIMARY
        )
      : undefined
  ];

  const [
    {
      documents: documentsPrimaryPersonFirst = [],
      relationshipDocuments: relationshipDocumentsPrimaryPersonFirst = [],
      phones: phonesPrimaryPersonFirst = [],
      ...generalInfoPrimaryPersonFirst
    } = {},
    {
      documents: documentsPrimaryPersonSecond = [],
      relationshipDocuments: relationshipDocumentsPrimaryPersonSecond = [],
      phones: phonesPrimaryPersonSecond = [],
      ...generalInfoPrimaryPersonSecond
    } = {}
  ] = primaryPersons;

  const secondaryPersons: any = [
    person
      ? person.find(
          (item) =>
            item && item.relationType === CONFIDANT_PERSONS_TYPES.SECONDARY
        )
      : undefined,
    masterPerson
      ? masterPerson.find(
          (item) =>
            item && item.relationType === CONFIDANT_PERSONS_TYPES.SECONDARY
        )
      : undefined
  ];

  const [
    {
      documents: documentsSecondaryPersonFirst = [],
      relationshipDocuments: relationshipDocumentsSecondaryPersonFirst = [],
      phones: phonesSecondaryPersonFirst = [],
      ...generalInfoSecondaryPersonFirst
    } = {},
    {
      documents: documentsSecondaryPersonSecond = [],
      relationshipDocuments: relationshipDocumentsSecondaryPersonSecond = [],
      phones: phonesSecondaryPersonSecond = [],
      ...generalInfoSecondaryPersonSecond
    } = {}
  ] = secondaryPersons;

  if (isEqual(person, masterPerson)) return null;

  return (
    <Box>
      {!isEqual(primaryPersons[0], primaryPersons[1]) && (
        <>
          <Box mb={6}>
            <Text fontSize={1} fontWeight="bold">
              {header.PRIMARY}
            </Text>
          </Box>
          {!isEqual(
            generalInfoPrimaryPersonFirst,
            generalInfoPrimaryPersonSecond
          ) && (
            <Box>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>General info</Trans>
              </Text>
              <TableDiff
                data={primaryPersons}
                header={{
                  firstName: <Trans>First name</Trans>,
                  lastName: <Trans>Last name</Trans>,
                  secondName: <Trans>Second name</Trans>,
                  birthDate: <Trans>Date of birth</Trans>,
                  birthCountry: <Trans>Country of birth</Trans>,
                  birthSettlement: <Trans>Settlement of birth</Trans>,
                  secret: <Trans>Secret word</Trans>,
                  gender: <Trans>Gender</Trans>,
                  email: <Trans>Email</Trans>,
                  preferredWayCommunication: (
                    <Trans>Preferred way of communication</Trans>
                  ),
                  taxId: <Trans>Tax ID</Trans>,
                  unzr: <Trans>Record ID in EDDR</Trans>
                }}
                renderRow={({
                  phones,
                  preferredWayCommunication,
                  ...content
                }: ConfidantPerson) => ({
                  preferredWayCommunication: (
                    <DictionaryValue
                      name="PREFERRED_WAY_COMMUNICATION"
                      item={preferredWayCommunication}
                    />
                  ),
                  ...content
                })}
                hideEmptyFields
              />
            </Box>
          )}
          {!isEqual(phonesPrimaryPersonFirst, phonesPrimaryPersonSecond) && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>Phones</Trans>
              </Text>
              <DictionaryValue name="PHONE_TYPE">
                {(phonesHeader: DictionaryAllValuesJson) => (
                  <PhonesTable
                    header={phonesHeader}
                    person={primaryPersons[0] && primaryPersons[0].phones}
                    masterPerson={primaryPersons[1] && primaryPersons[1].phones}
                  />
                )}
              </DictionaryValue>
            </Box>
          )}
          {!isEqual(
            documentsPrimaryPersonFirst,
            documentsPrimaryPersonSecond
          ) && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>Documents</Trans>
              </Text>
              <DictionaryValue name="DOCUMENT_TYPE">
                {(documentsHeader: DictionaryAllValuesJson) => (
                  <DocumentsTable
                    header={documentsHeader}
                    person={primaryPersons[0] && primaryPersons[0].documents}
                    masterPerson={
                      primaryPersons[1] && primaryPersons[1].documents
                    }
                  />
                )}
              </DictionaryValue>
            </Box>
          )}
          {!isEqual(
            relationshipDocumentsPrimaryPersonFirst,
            relationshipDocumentsPrimaryPersonSecond
          ) && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>Relationship documents</Trans>
              </Text>
              <DictionaryValue name="DOCUMENT_RELATIONSHIP_TYPE">
                {(documentsHeader: DictionaryAllValuesJson) => (
                  <DocumentsTable
                    header={documentsHeader}
                    person={
                      primaryPersons[0] &&
                      primaryPersons[0].relationshipDocuments
                    }
                    masterPerson={
                      primaryPersons[1] &&
                      primaryPersons[1].relationshipDocuments
                    }
                  />
                )}
              </DictionaryValue>
            </Box>
          )}
          <Line />
        </>
      )}
      {!isEqual(secondaryPersons[0], secondaryPersons[1]) && (
        <>
          <Box mb={6}>
            <Text fontSize={1} fontWeight="bold">
              {header.SECONDARY}
            </Text>
          </Box>
          {!isEqual(
            generalInfoSecondaryPersonFirst,
            generalInfoSecondaryPersonSecond
          ) && (
            <TableDiff
              data={secondaryPersons}
              header={{
                firstName: <Trans>First name</Trans>,
                lastName: <Trans>Last name</Trans>,
                secondName: <Trans>Second name</Trans>,
                birthDate: <Trans>Date of birth</Trans>,
                birthCountry: <Trans>Country of birth</Trans>,
                birthSettlement: <Trans>Settlement of birth</Trans>,
                secret: <Trans>Secret word</Trans>,
                gender: <Trans>Gender</Trans>,
                email: <Trans>Email</Trans>,
                preferredWayCommunication: (
                  <Trans>Preferred way of communication</Trans>
                ),
                taxId: <Trans>Tax ID</Trans>,
                unzr: <Trans>Record ID in EDDR</Trans>
              }}
              renderRow={({
                phones,
                preferredWayCommunication,
                ...content
              }: ConfidantPerson) => ({
                preferredWayCommunication: (
                  <DictionaryValue
                    name="PREFERRED_WAY_COMMUNICATION"
                    item={preferredWayCommunication}
                  />
                ),
                // @ts-expect-error maybe bug
                phones: <Phones data={phones} />,
                ...content
              })}
              hideEmptyFields
            />
          )}
          {!isEqual(
            phonesSecondaryPersonFirst,
            phonesSecondaryPersonSecond
          ) && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>Phones</Trans>
              </Text>
              <DictionaryValue name="PHONE_TYPE">
                {(phonesHeader: DictionaryAllValuesJson) => (
                  <PhonesTable
                    header={phonesHeader}
                    person={secondaryPersons[0] && secondaryPersons[0].phones}
                    masterPerson={
                      secondaryPersons[1] && secondaryPersons[1].phones
                    }
                  />
                )}
              </DictionaryValue>
            </Box>
          )}
          {!isEqual(
            documentsSecondaryPersonFirst,
            documentsSecondaryPersonSecond
          ) && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>Documents</Trans>
              </Text>
              <DictionaryValue name="DOCUMENT_TYPE">
                {(documentsHeader: DictionaryAllValuesJson) => (
                  <DocumentsTable
                    header={documentsHeader}
                    person={
                      secondaryPersons[0] && secondaryPersons[0].documents
                    }
                    masterPerson={
                      secondaryPersons[1] && secondaryPersons[1].documents
                    }
                  />
                )}
              </DictionaryValue>
            </Box>
          )}
          {!isEqual(
            relationshipDocumentsSecondaryPersonFirst,
            relationshipDocumentsSecondaryPersonSecond
          ) && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={2} fontSize={1}>
                <Trans>Relationship documents</Trans>
              </Text>
              <DictionaryValue name="DOCUMENT_RELATIONSHIP_TYPE">
                {(documentsHeader: DictionaryAllValuesJson) => (
                  <DocumentsTable
                    header={documentsHeader}
                    person={
                      secondaryPersons[0] &&
                      secondaryPersons[0].relationshipDocuments
                    }
                    masterPerson={
                      secondaryPersons[1] &&
                      secondaryPersons[1].relationshipDocuments
                    }
                  />
                )}
              </DictionaryValue>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ConfidantPersonsTable;
