import React from "react";
import { gql } from "graphql-tag";
import { useLingui } from "@lingui/react";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { Episode, CarePlan } from "@edenlabllc/graphql-schema";

import CodeableConcept from "../../../components/CodeableConcept";
import DictionaryValue from "../../../components/DictionaryValue";
import FullName from "../../../components/FullName";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type StatusHistoryProps = {
  statusHistory: Episode["statusHistory"] | CarePlan["statusHistory"];
  statusDictionaryName?: string;
  statusValues?: { values: { [key: string]: string } };
  hidePagination?: boolean;
  hideControls?: boolean;
};

const StatusHistory = ({
  statusHistory,
  hidePagination = true,
  hideControls = true,
  statusDictionaryName,
  statusValues
}: StatusHistoryProps) => {
  const { i18n } = useLingui();
  if (isEmpty(statusHistory)) return null;

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={statusHistory}
        header={{
          status: <Trans>Status</Trans>,
          insertedBy: <Trans>Inserted by</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          statusReason: (
            <Trans id="StatusHistory status reason">Status reason</Trans>
          )
        }}
        renderRow={({
          insertedAt,
          statusReason,
          status,
          insertedBy,
          party,
          ...restData
        }) => ({
          ...restData,
          status: statusDictionaryName ? (
            <DictionaryValue
              name={statusDictionaryName}
              item={status.toLowerCase()}
            />
          ) : !isEmpty(statusValues) ? (
            i18n._(statusValues.values[status.toLowerCase()])
          ) : null,
          insertedBy: party ? getFullName(party) : insertedBy,
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          ...(!isEmpty(statusReason) && {
            statusReason: <CodeableConcept codeableConcept={statusReason} />
          })
        })}
      />
    </Box>
  );
};

export default StatusHistory;

StatusHistory.fragments = {
  entry: gql`
    fragment StatusHistory on StatusHistory {
      status
      insertedAt
      insertedBy
      party {
        ...FullName
      }
      statusReason {
        ...CodeableConcept
      }
    }
    ${FullName.fragments.entry}
  `
};
