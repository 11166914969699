import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { ServiceConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParams from "../../../helpers/filteredLocationParams";

import CreateServicePopup from "./CreateServicePopup";
import { PrimarySearchFields } from "./SearchFields";
import ServicesTable from "./ServicesTable";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <>
          <Flex justifyContent="space-between" alignItems="flex-start" mb={6}>
            <Box>
              <Heading as="h1" fontWeight="normal" mb={4}>
                <Trans>Services</Trans>
              </Heading>
            </Box>
            <Ability action="write" resource="service_catalog">
              <CreateServicePopup
                locationParams={filteredLocationParams(locationParams)}
                refetchQuery={SearchServicesQuery}
              />
            </Ability>
          </Flex>
          <SearchForm
            initialValues={locationParams}
            onSubmit={setLocationParams}
            renderPrimary={PrimarySearchFields}
          />
          <Query
            query={SearchServicesQuery}
            fetchPolicy="network-only"
            variables={filteredLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{ services: ServiceConnection }>) => {
              if (isEmpty(data) || isEmpty(data.services)) return null;
              const {
                services: { nodes: services = [], pageInfo }
              } = data;

              return (
                <LoadingOverlay loading={loading}>
                  <ServicesTable
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                    services={services}
                  />
                  <Pagination pageInfo={pageInfo} />
                </LoadingOverlay>
              );
            }}
          </Query>
        </>
      )}
    </LocationParams>
  </Box>
);

const SearchServicesQuery = gql`
  query SearchServicesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: ServiceFilter
    $orderBy: ServiceOrderBy
  ) {
    services(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...Services
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ServicesTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default Search;
