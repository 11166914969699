import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import isEmpty from "lodash/isEmpty";

import { LocationParams } from "@edenlabllc/ehealth-components";
import { PersonConnection } from "@edenlabllc/graphql-schema";

import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, { TLocationParams } from "../../../components/SearchForm";

import filteredLocationParamsWithUnverified from "../../../helpers/filteredLocationParamsWithUnverified";

import PersonsTable from "../../Persons/Search/PersonsTable";
import {
  PERSON_VERIFICATION_STATUSES_FOR_DRACS,
  PrimarySearchFields
} from "./SearchFields";

export const DRACSTab = (_props: RouteComponentProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <>
        <SearchForm
          initialValues={locationParams}
          onSubmit={setLocationParams}
          renderPrimary={() =>
            PrimarySearchFields({
              statuses: PERSON_VERIFICATION_STATUSES_FOR_DRACS,
              isDracsTab: true
            })
          }
        />
        <Query
          fetchPolicy="network-only"
          query={UnverifiedDracsDeathActsPersonsQuery}
          variables={filteredLocationParamsWithUnverified(locationParams)}
        >
          {({
            data,
            loading
          }: QueryResult<{
            unverifiedDracsDeathActsPersons: PersonConnection;
          }>) => {
            const pageInfo =
              data &&
              data.unverifiedDracsDeathActsPersons &&
              data.unverifiedDracsDeathActsPersons.pageInfo;
            const unverifiedPersons = isEmpty(data)
              ? []
              : data.unverifiedDracsDeathActsPersons.nodes;

            return (
              <LoadingOverlay loading={loading}>
                <PersonsTable
                  persons={unverifiedPersons}
                  locationParams={locationParams}
                  setLocationParams={setLocationParams}
                  fromVerificationStatusPage={true}
                  tableName="unverifiedPersons/search/dracs"
                />
                <Pagination pageInfo={pageInfo} />
              </LoadingOverlay>
            );
          }}
        </Query>
      </>
    )}
  </LocationParams>
);

export const UnverifiedDracsDeathActsPersonsQuery = gql`
  query UnverifiedDracsDeathActsPersonsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: UnverifiedDracsDeathActsPersonFilter!
    $orderBy: UnverifiedDracsDeathActsPersonOrderBy
  ) {
    unverifiedDracsDeathActsPersons(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) {
      nodes {
        ...Persons
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PersonsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;
