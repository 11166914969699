import React from "react";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import format from "date-fns/format";

import { Field, DatePicker } from "@edenlabllc/ehealth-components";
import { CalendarIcon } from "@edenlabllc/ehealth-icons";
import { formatDate, parseDate } from "@edenlabllc/ehealth-utils";

import * as FieldView from "./FieldView";
import * as InputView from "./InputView";
import ErrorTranslation from "./ErrorTranslation";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd.mm.yyyy");

type DateFieldState = {
  isOpen: boolean;
};

type DateFieldProps = {
  onChange?: (tdValue: string) => void;
  name?: string;
  label?: React.ReactElement;
  hint?: string;
  warning?: string;
  minDate?: string;
  maxDate?: string | number; // sometimes its timestamp
  placement?: string;
  disabled?: boolean;
  withoutFormField?: boolean;
  hideErrors?: boolean;
  value?: string;
};

type DateFieldViewProps = {
  input?: {
    value?: string;
    onChange?: (tdValue: string) => void;
  };
  meta?: {
    state?: string;
    errored?: boolean;
    error?: string;
  };
  onKeyPress: (e: KeyboardEvent) => void;
  hideErrors?: boolean;
  warning?: string;
};

const DateFieldView = ({
  input,
  meta: { state, errored, error } = {},
  onKeyPress,
  hideErrors = false,
  warning
}: DateFieldViewProps) => (
  <>
    <InputView.Border variant={state}>
      <InputView.Content
        {...input}
        pl={2}
        py={0}
        flex="none"
        display="flex"
        alignItems="center"
      >
        <CalendarIcon />
      </InputView.Content>
      <InputView.Content
        is={MaskedInput}
        width={0}
        pl={2}
        pr={3}
        placeholder="ДД.ММ.РРРР"
        mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
        guide={false}
        {...input}
        onKeyPress={onKeyPress}
        pipe={autoCorrectedDatePipe}
        autoComplete="off"
        value={(input && input.value) || ""}
      />
    </InputView.Border>

    {!hideErrors && (
      <FieldView.Footer>
        <FieldView.Message variant={state}>
          {errored && error ? <ErrorTranslation error={error} /> : warning}
        </FieldView.Message>
      </FieldView.Footer>
    )}
  </>
);

type TimeoutID = NodeJS.Timeout;

class DateField extends React.Component<DateFieldProps, DateFieldState> {
  state = {
    isOpen: false
  };

  label?: HTMLLabelElement | null;
  timeoutIds: TimeoutID[] = [];

  componentWillUnmount() {
    this.internalClearTimeouts();
  }

  calendar = React.createRef();

  render() {
    const {
      label,
      hint,
      warning,
      placement = "bottom",
      minDate,
      maxDate,
      withoutFormField = false,
      hideErrors = false,
      ...props
    } = this.props;
    const { isOpen } = this.state;

    return (
      <FieldView.Wrapper ref={this.calendar}>
        <label
          // @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t... Remove this comment to see the full error message
          onClick={!props.disabled && this.handleClick}
          onBlur={this.handleBlur}
          ref={(label) => (this.label = label)}
        >
          {label && (
            <FieldView.Header>
              <FieldView.Label>{label}</FieldView.Label>
              {hint && <FieldView.Message>{hint}</FieldView.Message>}
            </FieldView.Header>
          )}

          {withoutFormField ? (
            <DateFieldView
              input={{
                value: props.value,
                onChange: props.onChange
              }}
              onKeyPress={this.handleKeyPress}
              hideErrors={hideErrors}
            />
          ) : (
            <Field format={formatDate} parse={parseDate} {...props}>
              {({ input, meta }: $TSFixMe) => (
                <DateFieldView
                  input={input}
                  meta={meta}
                  onKeyPress={this.handleKeyPress}
                  warning={warning}
                />
              )}
            </Field>
          )}
        </label>

        {isOpen && (
          <>
            {withoutFormField ? (
              <DatePicker
                placement={placement}
                minDate={this.getSelectedDate(minDate)}
                maxDate={maxDate ? new Date(maxDate) : undefined}
                selected={this.getSelectedDate(props.value)}
                onDateSelected={this.handleDateSelect(props.onChange)}
              />
            ) : (
              <Field {...props}>
                {({ input: { value, onChange } }: $TSFixMe) => (
                  <DatePicker
                    placement={placement}
                    minDate={this.getSelectedDate(minDate)}
                    maxDate={maxDate ? new Date(maxDate) : undefined}
                    selected={this.getSelectedDate(value)}
                    onDateSelected={this.handleDateSelect(onChange)}
                  />
                )}
              </Field>
            )}
          </>
        )}
      </FieldView.Wrapper>
    );
  }

  internalSetTimeout = (fn: () => void, time?: number): void => {
    const id: TimeoutID = setTimeout(() => {
      this.timeoutIds = this.timeoutIds.filter((i) => i !== id);
      fn();
    }, time);

    this.timeoutIds.push(id);
  };

  internalClearTimeouts() {
    this.timeoutIds.forEach((id) => {
      clearTimeout(id);
    });

    this.timeoutIds = [];
  }

  getSelectedDate(value?: string) {
    const parsedDate =
      value && value.length === 10 ? Date.parse(value) : undefined;
    // @ts-expect-error TS(2769): No overload matches this call.
    return new Date(isNaN(parsedDate) ? Date.now() : parsedDate);
  }

  handleDateSelect =
    (onFieldChange: ((date: string) => void) | undefined) =>
    ({ selectable, date }: { selectable: boolean; date: string }) => {
      if (!selectable) return;

      this.setState({ isOpen: false });
      if (this.label) {
        this.label.focus();
      }
      onFieldChange && onFieldChange(format(date, "YYYY-MM-DD"));
    };

  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleBlur = () => {
    this.internalSetTimeout(() => {
      if (
        document.hasFocus() &&
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        !this.calendar.current.contains(document.activeElement)
      ) {
        this.setState({ isOpen: false });
      }
    });
  };

  handleKeyPress = (e: KeyboardEvent) =>
    e.key === "Enter" && this.setState({ isOpen: false });
}

export default DateField;
