import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { loader } from "graphql.macro";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { getFullName } from "@edenlabllc/ehealth-utils";
import { ReimbursementContractRequest } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import DictionaryValue from "../../../components/DictionaryValue";
import DefinitionListView from "../../../components/DefinitionListView";
import Line from "../../../components/Line";
import SignContractRequest from "../../../components/SignContractRequest";
import Steps from "../../../components/Steps";

const ReimbursementContractRequestQuery = loader(
  "../../../graphql/ReimbursementContractRequestQuery.graphql"
);

type ApproveProps = RouteComponentProps<{
  id: string;
}>;

const Approve = ({ id }: ApproveProps) => (
  <>
    <Box pt={5} px={5}>
      <Steps.List>
        <Steps.Item to="../update">
          <Trans>Fill in fields</Trans>
        </Steps.Item>
        <Steps.Item to="./">
          <Trans>Confirm with EDS</Trans>
        </Steps.Item>
      </Steps.List>
    </Box>

    <Query
      query={ReimbursementContractRequestQuery}
      variables={{
        id
      }}
    >
      {({
        data
      }: QueryResult<{
        reimbursementContractRequest: ReimbursementContractRequest;
      }>) => {
        if (isEmpty(data) || isEmpty(data.reimbursementContractRequest))
          return null;

        const {
          status,
          databaseId,
          contractorLegalEntity: {
            databaseId: legalEntityId,
            name,
            edrpou,
            status: contractorLegalEntityStatus
          }
        } = data.reimbursementContractRequest;

        const isApproveDisabled = contractorLegalEntityStatus !== "ACTIVE";

        return (
          <>
            <OpacityBox m={5}>
              <DefinitionListView
                labels={{
                  id: <Trans>Contract request ID</Trans>,
                  status: <Trans>Status</Trans>,
                  edrpou: <Trans>EDRPOU</Trans>,
                  name: <Trans>Name</Trans>,
                  legalEntityId: <Trans>Pharmacy ID</Trans>
                }}
                data={{
                  id: databaseId,
                  status: (
                    <Badge
                      name={status}
                      type="CONTRACT_REQUEST"
                      minWidth={100}
                    />
                  ),
                  edrpou,
                  name,
                  legalEntityId
                }}
                color="#7F8FA4"
                labelWidth="100px"
              />
            </OpacityBox>
            <Router>
              <ApproveContractRequest
                path="/"
                data={data.reimbursementContractRequest}
                isApproveDisabled={isApproveDisabled}
              />
            </Router>
          </>
        );
      }}
    </Query>
  </>
);

type ApproveContractRequestProps = RouteComponentProps<{
  id: string;
  data: ReimbursementContractRequest;
  isApproveDisabled: boolean;
}>;

const ApproveContractRequest = ({
  id,
  navigate,
  data,
  isApproveDisabled
}: ApproveContractRequestProps) => {
  const { nhsSigner, nhsPaymentMethod, ...reimbursementContractRequest } =
    data || {};
  return (
    <Box m={5}>
      <Line />
      <DefinitionListView
        labels={{
          nhsSigner: <Trans>Signatory from the Customers side</Trans>,
          nhsSignerBase: <Trans>Basis</Trans>,
          nhsPaymentMethod: <Trans>Payment method</Trans>,
          issueCity: <Trans>The city of the conclusion of the contract</Trans>,
          miscellaneous: <Trans>Miscellaneous</Trans>
        }}
        data={{
          nhsSigner: nhsSigner && getFullName(nhsSigner.party),
          nhsPaymentMethod: nhsPaymentMethod && (
            <DictionaryValue
              name="CONTRACT_PAYMENT_METHOD"
              item={nhsPaymentMethod}
            />
          ),
          ...reimbursementContractRequest
        }}
        labelWidth="300px"
        marginBetween={2}
        flexDirection="column"
      />

      <SignContractRequest
        id={id!}
        data={data!}
        navigate={navigate!}
        query={ReimbursementContractRequestQuery}
        isApproveDisabled={isApproveDisabled!}
      />
    </Box>
  );
};

const OpacityBox = system({ extend: Box, opacity: 0.5 }, "opacity", "space");

export default Approve;
