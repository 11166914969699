import React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Scalars } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";

type NHSReviewProps = {
  id: Scalars["ID"]["output"];
};

const NHSReview = ({ id }: NHSReviewProps) => (
  <Mutation mutation={NhsReviewLegalEntityMutation}>
    {(nhsReviewLegalEntity: MutationFunction) => (
      <Button
        onClick={async () => {
          await nhsReviewLegalEntity({
            variables: {
              input: {
                id
              }
            }
          });
        }}
        variant="blue"
      >
        <Trans>To process</Trans>
      </Button>
    )}
  </Mutation>
);

const NhsReviewLegalEntityMutation = gql`
  mutation NhsReviewLegalEntityMutation($input: NhsReviewLegalEntityInput!) {
    legalEntity: nhsReviewLegalEntity(input: $input) {
      legalEntity {
        id
        nhsReviewed
      }
    }
  }
`;

export default NHSReview;
