import React from "react";
import { gql } from "graphql-tag";

import { normalizeName } from "@edenlabllc/ehealth-utils";
import { Address } from "@edenlabllc/graphql-schema";

import DictionaryValue from "./DictionaryValue";

type AddressViewProps = {
  data: Address | any; // data maybe is not Address type
};

const AddressView = ({ data }: AddressViewProps) => {
  if (!data) return null;

  const { zip, streetType, street, building, apartment } = data;

  const [region, district, settlementType, settlement] = Object.keys(
    data
  ).includes("area")
    ? [data.area, data.region, data.settlementType, data.settlement]
    : [
        data.region && data.region.name,
        data.district && data.district.name,
        data.type,
        data.name
      ];

  return (
    <>
      {zip && <>{zip}, </>}
      {region && <>{formatDistrict(region)}, </>}
      {district && <>{normalizeName(district)} район, </>}
      {settlement && (
        <>
          <DictionaryValue
            name="SETTLEMENT_TYPE"
            item={settlementType || "CITY"}
          />
          &nbsp;
          {normalizeName(settlement)},&nbsp;
        </>
      )}
      <>
        {street && (
          <>
            {streetType && (
              <DictionaryValue name="STREET_TYPE" item={streetType} />
            )}
            &nbsp;
            {normalizeName(street)}
            &nbsp;
          </>
        )}
        {building}
      </>
      {apartment && (
        <>
          , кв.&nbsp;
          {apartment}
        </>
      )}
    </>
  );
};

AddressView.fragments = {
  entry: gql`
    fragment Addresses on Address {
      type
      country
      area
      region
      settlement
      settlementType
      settlementId
      streetType
      street
      building
      apartment
      zip
    }
  `
};

export default AddressView;

const formatDistrict = (area: string) => {
  if (/КРИМ/i.test(area)) {
    return normalizeName(area);
  } else if (/^м\./i.test(area)) {
    const [prefix, name] = area.split(/\.\s*/);
    return `${prefix.toLowerCase()}. ${normalizeName(name)}`;
  } else {
    return `${normalizeName(area)} область`;
  }
};
