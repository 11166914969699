import React from "react";
import { Trans } from "@lingui/macro";
import { Flex, Box } from "@rebass/emotion";

import { Form, Validation } from "@edenlabllc/ehealth-components";
import { SearchIcon } from "@edenlabllc/ehealth-icons";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import STATUSES from "../../../helpers/statuses";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => (
  <Flex mx={-1}>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.databaseId"
            label={<Trans id="Program device ID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <SearchField.MedicalProgram
        name="filter.medicalProgram"
        filteredParams={["name"]}
        additionalFilters={{ isActive: true, type: "DEVICE" }}
      />
    </Box>
    <Box px={1} width={1 / 3}>
      <Trans
        id="Enter ID"
        render={({ translation }) => (
          <Field.Text
            name="filter.deviceDefinition.databaseId"
            label={<Trans id="Device definition ID" />}
            placeholder={translation}
            postfix={<SearchIcon color="silverCity" />}
          />
        )}
      />
    </Box>
  </Flex>
);

const SecondarySearchFields = () => (
  <>
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <SearchField.Status
          name="filter.isActive"
          status="ACTIVE_STATUS_M"
          label={<Trans id="Program device status" />}
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter ID"
          render={({ translation }) => (
            <Field.Text
              name="filter.medicalProgramId"
              label={<Trans id="Medical program ID" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
            />
          )}
        />
        <Validation.Matches
          field="filter.medicalProgramId"
          options={UUID_PATTERN}
          message="Invalid number"
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Select option"
          render={({ translation }) => (
            <Field.Select
              name="filter.deviceRequestAllowed"
              label={<Trans id="Device request allowed" />}
              items={Object.keys(STATUSES.DEVICE_REQUEST_ALLOWED)}
              itemToString={(item: boolean) =>
                // @ts-expect-error statuses boolean key
                STATUSES.DEVICE_REQUEST_ALLOWED[item] || translation
              }
              variant="select"
              emptyOption
              filterOptions={{
                keys: [
                  (item: boolean) =>
                    // @ts-expect-error statuses boolean key
                    STATUSES.DEVICE_REQUEST_ALLOWED[item]
                ]
              }}
            />
          )}
        />
      </Box>
    </Flex>

    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <DictionaryValue name="REIMBURSEMENT_TYPE">
          {(reimbursementTypes: DictionaryAllValuesJson) => (
            <Trans
              id="All types"
              render={({ translation }) => (
                <Field.Select
                  name="filter.reimbursementType"
                  label={<Trans id="Reimbursement type" />}
                  items={Object.keys(reimbursementTypes)}
                  itemToString={(item: string) =>
                    reimbursementTypes[item] || String(translation)
                  }
                  variant="select"
                  emptyOption
                />
              )}
            />
          )}
        </DictionaryValue>
      </Box>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Enter registry number"
          render={({ translation }) => (
            <Field.Text
              name="filter.registryNumber"
              label={<Trans id="Registry number" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
              autoComplete="off"
            />
          )}
        />
      </Box>
    </Flex>

    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Field.RangePicker
          rangeNames={["filter.startDate.from", "filter.startDate.to"]}
          label={<Trans id="Start date" />}
        />
        <Form.Spy>
          {({ values = {} }: $TSFixMe) => {
            if (
              (values.filter &&
                values.filter.startDate &&
                values.filter.startDate.from) ||
              (values.filter &&
                values.filter.startDate &&
                values.filter.startDate.to)
            ) {
              return (
                <>
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.startDate.from"
                        message={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.startDate.to"
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }
            return null;
          }}
        </Form.Spy>
      </Box>
      <Box px={1} width={1 / 3}>
        <Field.RangePicker
          rangeNames={["filter.endDate.from", "filter.endDate.to"]}
          label={<Trans id="End date" />}
        />
        <Form.Spy>
          {({ values = {} }: $TSFixMe) => {
            if (
              (values.filter &&
                values.filter.endDate &&
                values.filter.endDate.from) ||
              (values.filter &&
                values.filter.endDate &&
                values.filter.endDate.to)
            ) {
              return (
                <>
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.endDate.from"
                        message={translation}
                      />
                    )}
                  />
                  <Trans
                    id="Required field"
                    render={({ translation }) => (
                      <Validation.Required
                        field="filter.endDate.to"
                        message={translation}
                      />
                    )}
                  />
                </>
              );
            }
            return null;
          }}
        </Form.Spy>
      </Box>
    </Flex>
  </>
);

export { PrimarySearchFields, SecondarySearchFields };
