import * as React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { DeviceDefinition, Scalars } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import GeneralInfo from "./GeneralInfo";
import Names from "./Names";
import Packaging from "./Packaging";
import Properties from "./Properties";
import Deactivate from "./Mutations/Deactivate";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Details = ({ id }: DetailsProps) => (
  <Query query={DeviceDefinitionQuery} variables={{ id }}>
    {({
      loading,
      data
    }: QueryResult<{ deviceDefinition: DeviceDefinition }>) => {
      if (isEmpty(data) || isEmpty(data.deviceDefinition)) return null;
      const {
        id,
        isActive,
        databaseId,
        externalId,
        description,
        partNumber,
        classificationType,
        modelNumber,
        manufacturerName,
        manufacturerCountry,
        parentId,
        note,
        deviceNames,
        packagingType,
        packagingUnit,
        packagingCount,
        properties
      } = data.deviceDefinition;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/device-definitions">
                  <Trans>Device definitions</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Device definitions details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>ID</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    databaseId,
                    status: (
                      <Badge
                        name={isActive}
                        type="ACTIVE_STATUS_M"
                        variant={!isActive}
                        minWidth={100}
                      />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="100px"
                />
              </Box>
              {isActive && <Deactivate id={id} />}
            </Flex>
          </Box>

          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General info</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./device-names">
              <Trans>Names</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./packaging">
              <Trans>Packaging</Trans>
            </Tabs.NavItem>
            {properties && (
              <Tabs.NavItem to="./properties">
                <Trans>Properties</Trans>
              </Tabs.NavItem>
            )}
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <GeneralInfo
                path="/"
                externalId={externalId}
                description={description}
                partNumber={partNumber}
                classificationType={classificationType}
                modelNumber={modelNumber}
                manufacturerName={manufacturerName}
                manufacturerCountry={manufacturerCountry}
                parentId={parentId}
                note={note}
              />
              <Names path="/device-names" id={id} deviceNames={deviceNames} />
              <Packaging
                path="/packaging"
                packagingType={packagingType}
                packagingUnit={packagingUnit}
                packagingCount={packagingCount}
              />
              {properties && (
                <Properties path="/properties" properties={properties} />
              )}
            </Router>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

const DeviceDefinitionQuery = gql`
  query DeviceDefinitionQuery($id: ID!) {
    deviceDefinition(id: $id) {
      id
      databaseId
      isActive
      ...DeviceDefinitionGeneralInfo
      ...DeviceDefinitionNames
      ...DeviceDefinitionPackaging
      ...DeviceDefinitionProperties
    }
  }
  ${GeneralInfo.fragments.entry}
  ${Names.fragments.entry}
  ${Packaging.fragments.entry}
  ${Properties.fragments.entry}
`;

export default Details;
