import React from "react";
import { gql } from "graphql-tag";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Maybe, Range as TRange } from "@edenlabllc/graphql-schema";

import { SimpleQuantity } from "./SimpleQuantity";

type SimpleRangeProps = {
  simpleRange?: Maybe<TRange>;
};

export const SimpleRange = ({ simpleRange }: SimpleRangeProps) => {
  if (isEmpty(simpleRange)) return null;

  const { high, low } = simpleRange;
  return (
    <Flex>
      <Box mr={1}>
        <SimpleQuantity simpleQuantity={low} />
      </Box>
      {"/"}
      <Box ml={1}>
        <SimpleQuantity simpleQuantity={high} />
      </Box>
    </Flex>
  );
};

SimpleRange.fragments = {
  entry: gql`
    fragment SimpleRange on SimpleRange {
      high {
        ...SimpleQuantity
      }
      low {
        ...SimpleQuantity
      }
    }
    ${SimpleQuantity.fragments.entry}
  `
};
