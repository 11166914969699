import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { loader } from "graphql.macro";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Validation
} from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams,
  getFullName
} from "@edenlabllc/ehealth-utils";
import {
  SearchIcon,
  RemoveItemIcon,
  PositiveIcon,
  NegativeIcon
} from "@edenlabllc/ehealth-icons";
import { Declaration } from "@edenlabllc/graphql-schema";

import AddressView from "../../components/AddressView";
import Badge from "../../components/Badge";
import Button from "../../components/Button";
import * as Field from "../../components/Field";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../components/SearchForm";
import Table, { SortingParams } from "../../components/Table";

import { DECLARATION_NUMBER_PATTERN } from "../../constants/declarationSearchPatterns";

const DeclarationByNumberQuery = loader(
  "../../graphql/DeclarationByNumberQuery.graphql"
);

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Search for Declarations</Trans>
    </Heading>
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const { filter: { declarationNumber } = {} } = locationParams;

        return (
          <>
            <SearchDeclarationForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
            />
            <Query
              skip={isEmpty(declarationNumber)}
              query={DeclarationByNumberQuery}
              variables={{ declarationNumber }}
            >
              {({
                loading,
                data
              }: QueryResult<{ declarationByNumber: Declaration }>) => {
                if (isEmpty(data) || isEmpty(data.declarationByNumber))
                  return null;

                return (
                  <LoadingOverlay loading={loading}>
                    <Table
                      data={[data.declarationByNumber]}
                      header={{
                        databaseId: <Trans>Declaration ID</Trans>,
                        declarationNumber: <Trans>Declaration number</Trans>,
                        startDate: <Trans>Declaration start date</Trans>,
                        legalEntityName: <Trans>Legal entity</Trans>,
                        legalEntityEdrpou: <Trans>EDRPOU</Trans>,
                        divisionName: <Trans>Division name</Trans>,
                        divisionAddress: <Trans>Address</Trans>,
                        status: <Trans>Status</Trans>,
                        noTaxId: <Trans>No tax ID</Trans>,
                        patientName: <Trans>Patient Name</Trans>,
                        action: <Trans>Action</Trans>
                      }}
                      renderRow={({
                        id,
                        legalEntity,
                        startDate,
                        division,
                        person,
                        status,
                        // @ts-expect-error types mismatch
                        noTaxId,
                        ...declaration
                      }: Declaration) => ({
                        ...declaration,
                        legalEntityName: legalEntity.name,
                        legalEntityEdrpou: legalEntity.edrpou,
                        patientName: getFullName(person),
                        divisionName: division.name,
                        divisionAddress: division.addresses
                          .filter(
                            (address) => address && address.type === "RESIDENCE"
                          )
                          .map((item, key) => (
                            <AddressView data={item} key={key} />
                          )),
                        startDate: i18n.date(startDate),
                        status: (
                          <Badge
                            name={status}
                            type="DECLARATION"
                            minWidth={100}
                          />
                        ),
                        noTaxId: (
                          <Flex justifyContent="center">
                            {person.noTaxId ? (
                              <PositiveIcon />
                            ) : (
                              <NegativeIcon />
                            )}
                          </Flex>
                        ),
                        action: (
                          <Link to={`../${id}`} fontWeight="bold">
                            <Trans>Show details</Trans>
                          </Link>
                        )
                      })}
                      sortingParams={parseSortingParams(locationParams.orderBy)}
                      onSortingChange={(sortingParams: SortingParams) =>
                        setLocationParams({
                          orderBy: stringifySortingParams(sortingParams)
                        })
                      }
                      tableName="declaration-by-number/search"
                      whiteSpaceNoWrap={["databaseId"]}
                      hiddenFields="noTaxId,patientName,databaseId"
                    />
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export default Search;

type SearchDeclarationFormProps = {
  initialValues: SearchParams;
  onSubmit: SetSearchParams;
};

const SearchDeclarationForm = ({
  initialValues,
  onSubmit
}: SearchDeclarationFormProps) => (
  <Form
    initialValues={initialValues}
    onSubmit={(params: SearchParams) =>
      onSubmit({
        ...params
      })
    }
  >
    <Flex mx={-1}>
      <Box px={1} width={1 / 2}>
        <Trans
          id="Enter declaration number"
          render={({ translation }) => (
            <Field.Text
              name="filter.declarationNumber"
              label={<Trans id="Search by declaration number" />}
              placeholder={translation}
              postfix={<SearchIcon color="silverCity" />}
              autoComplete="off"
            />
          )}
        />
        <Validation.Matches
          field="filter.declarationNumber"
          options={DECLARATION_NUMBER_PATTERN}
          message="Invalid number"
        />
      </Box>
    </Flex>
    <Flex mx={-1} justifyContent="flex-start">
      <Box px={1}>
        <Button variant="blue">
          <Trans>Search</Trans>
        </Button>
      </Box>
      <Box px={1}>
        <Button
          variant="link"
          px={1}
          icon={RemoveItemIcon}
          type="reset"
          disabled={isEmpty(initialValues.filter)}
          onClick={() => {
            onSubmit({
              ...initialValues,
              filter: {}
            });
          }}
        >
          <Trans>Reset</Trans>
        </Button>
      </Box>
    </Flex>
  </Form>
);
