import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query, Mutation } from "@apollo/client/react/components";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { TransRenderProps, useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";
import Composer from "react-composer";
import styled from "@emotion/styled";
import { Box, Text, Flex, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  Form,
  LocationParams,
  Modal,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import {
  parsePhone,
  formatPhone,
  getFullName
} from "@edenlabllc/ehealth-utils";
import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { CreateAuthMethodInput, Person } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import DefinitionListView from "../../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";
import Line from "../../../../components/Line";
import { TLocationParams } from "../../../../components/SearchForm";

import filteredLocationParams from "../../../../helpers/filteredLocationParams";
import { AUTH_METHODS_FILTER_DEFAULTS } from "../../../../constants/filterValues";
import { ITEMS_PER_PAGE } from "../../../../constants/pagination";

import ThirdPersonModalForm from "./ThirdPersonModalForm";
import { clearFilterParamsPersonQuery } from "../AuthMethods";
import { PersonQuery } from "../";

type AddAuthMethodProps = RouteComponentProps<{
  id: string;
}>;

const AddAuthMethod = ({
  id,
  navigate,
  // @ts-expect-error location state
  location: { state }
}: AddAuthMethodProps) => {
  const { i18n } = useLingui();
  const [isVisibleAddAuthModal, setVisiblityAddAuthModal] = useState(false);
  const [isVisibleThirdPersonSearchModal, setVisiblityThirdPersonSearchModal] =
    useState(false);
  const [addAuthError, setAddAuthError] = useState<any>(null);

  const toggleVisiblityAddAuthModal = () =>
    setVisiblityAddAuthModal((prevState) => !prevState);

  const toggleVisibleThirdPersonSearchModal = () =>
    setVisiblityThirdPersonSearchModal((prevState) => !prevState);

  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const searchVariables = locationParams.filter
          ? filteredLocationParams(locationParams)
          : {
              ...filteredLocationParams(locationParams),
              ...AUTH_METHODS_FILTER_DEFAULTS
            };

        const { filter, ...restSearchVariables } = searchVariables;

        const personQueryVariables = {
          id,
          ...restSearchVariables,
          filter: clearFilterParamsPersonQuery(filter),
          first: locationParams.first
            ? parseInt(locationParams.first)
            : ITEMS_PER_PAGE[0]
        };

        return (
          <Mutation
            mutation={CreateAuthMethod}
            refetchQueries={[
              {
                query: PersonQuery,
                variables: personQueryVariables
              }
            ]}
          >
            {(createAuthMethod: MutationFunction) => {
              return (
                <Query
                  query={PersonQuery}
                  fetchPolicy="network-only"
                  variables={personQueryVariables}
                >
                  {({ data }: QueryResult<{ person: Person }>) => {
                    if (isEmpty(data)) return null;
                    const { person } = data;

                    return (
                      <>
                        <Form
                          onSubmit={(data: CreateAuthMethodInput) => {
                            setLocationParams(data);
                            setVisiblityAddAuthModal(true);
                          }}
                          initialValues={{
                            ...state,
                            ...locationParams,
                            ...(locationParams.thirdPerson &&
                              locationParams.thirdPerson.phoneNumber && {
                                phoneNumber:
                                  locationParams.thirdPerson.phoneNumber
                              })
                          }}
                        >
                          <Box p={5} width={3 / 4}>
                            <Text fontSize={1} mb={5}>
                              <Trans>Adding authentication method</Trans>
                            </Text>
                            <DefinitionListView
                              labels={{
                                fullName: <Trans>Full name</Trans>,
                                databaseId: <Trans>Patient ID</Trans>
                              }}
                              data={{
                                fullName: <Text>{getFullName(person)}</Text>,
                                databaseId: data.person.databaseId
                              }}
                              color="#7F8FA4"
                              labelWidth="120px"
                            />
                            <Line />
                            <Form.Spy>
                              {({ values = {} }: $TSFixMe) => {
                                const handleAddAuthMethod = async (
                                  formData: $TSFixMe
                                ) => {
                                  const {
                                    authMethod,
                                    alias,
                                    phoneNumber,
                                    thirdPerson
                                  } = values;

                                  try {
                                    await createAuthMethod({
                                      variables: {
                                        input: {
                                          personId: person.databaseId,
                                          type: authMethod,
                                          alias,
                                          ...(authMethod !== "OFFLINE" &&
                                            phoneNumber && { phoneNumber }),
                                          ...(authMethod === "THIRD_PERSON" &&
                                            thirdPerson && {
                                              value: thirdPerson.databaseId
                                            })
                                        }
                                      }
                                    });
                                    navigate!("../");
                                  } catch (err: $TSFixMe) {
                                    const { graphQLErrors } = err;
                                    setAddAuthError(graphQLErrors[0]);
                                  }
                                };
                                return (
                                  <>
                                    <Box width={1 / 2} mt={1}>
                                      <Composer
                                        components={[
                                          <DictionaryValue name="AUTHENTICATION_METHOD" />,
                                          ({
                                            render
                                          }: {
                                            render: (
                                              props: TransRenderProps
                                            ) => React.ReactElement;
                                          }) => (
                                            <Trans
                                              id="Select option"
                                              render={render}
                                            />
                                          )
                                        ]}
                                      >
                                        {([dict, { translation }]: [
                                          DictionaryAllValuesJson,
                                          { translation: React.ReactNode }
                                        ]) => (
                                          <Field.Select
                                            name="authMethod"
                                            label={
                                              <Trans id="Choose authentication method" />
                                            }
                                            placeholder={translation}
                                            items={Object.keys(dict)}
                                            itemToString={(item: string) =>
                                              dict[item] || String(translation)
                                            }
                                            variant="select"
                                          />
                                        )}
                                      </Composer>
                                    </Box>
                                    <Box width={1 / 2} mt={1}>
                                      <Field.Text
                                        name="alias"
                                        label={
                                          <Trans id="Name of authentication method" />
                                        }
                                        placeholder={i18n._(
                                          t`Enter authentication method name`
                                        )}
                                        maxLength={50}
                                        showLengthHint
                                      />
                                      {values.authMethod === "THIRD_PERSON" && (
                                        <Trans
                                          id="Required field"
                                          render={() => (
                                            <Validation.Required
                                              field="alias"
                                              message="Required field"
                                            />
                                          )}
                                        />
                                      )}
                                    </Box>
                                    {values.authMethod === "THIRD_PERSON" && (
                                      <>
                                        <Box width={1 / 2} mt={1}>
                                          <Field.Select
                                            name={`thirdPerson.fullName`}
                                            label={
                                              <Trans id="Third person full name" />
                                            }
                                            placeholder={i18n._(
                                              t`Enter third person full name`
                                            )}
                                            items={[]}
                                            itemToString={(item: string) =>
                                              item
                                            }
                                            emptyOption
                                            iconComponent={() => (
                                              <SearchIcon
                                                color="silverCity"
                                                onClick={() =>
                                                  setVisiblityThirdPersonSearchModal(
                                                    true
                                                  )
                                                }
                                              />
                                            )}
                                            onClick={() =>
                                              setVisiblityThirdPersonSearchModal(
                                                true
                                              )
                                            }
                                          />
                                        </Box>
                                        <Trans
                                          id="Required field"
                                          render={() => (
                                            <Validation.Required
                                              field="thirdPerson.fullName"
                                              message="Required field"
                                            />
                                          )}
                                        />
                                      </>
                                    )}
                                    {values.authMethod !== "OFFLINE" && (
                                      <Box width={1 / 2} mt={1}>
                                        <Field.Text
                                          name="phoneNumber"
                                          label={<Trans id="phone number" />}
                                          placeholder={i18n._(
                                            t`Enter phone number`
                                          )}
                                          format={formatPhone}
                                          parse={parsePhone}
                                        />
                                        <Validations field="phoneNumber">
                                          <Validation.Matches
                                            options={"^\\+380\\d{9}$"}
                                            message={i18n._(
                                              t`Invalid phone number`
                                            )}
                                          />
                                          <Validation.Required message="Required field" />
                                        </Validations>
                                      </Box>
                                    )}
                                    {isVisibleAddAuthModal && (
                                      <AuthActionModal
                                        heading={
                                          <Trans>
                                            Create new authentication method
                                          </Trans>
                                        }
                                        warningText={
                                          <Trans>
                                            Warning!
                                            <br />
                                            After confirmation, this
                                            authentication method will be
                                            created.
                                          </Trans>
                                        }
                                        cancelButtonText={<Trans>Return</Trans>}
                                        confirmButtonText={
                                          <Trans>
                                            Create new Authentication Method
                                          </Trans>
                                        }
                                        handleToggleVisibility={
                                          toggleVisiblityAddAuthModal
                                        }
                                        handleSubmit={() =>
                                          handleAddAuthMethod(values)
                                        }
                                      />
                                    )}
                                    {isVisibleThirdPersonSearchModal && (
                                      <ThirdPersonModalForm
                                        state={values}
                                        onClose={() => {
                                          toggleVisibleThirdPersonSearchModal();
                                        }}
                                      />
                                    )}
                                  </>
                                );
                              }}
                            </Form.Spy>
                            <Flex pt={5} mb={20}>
                              <Box mr={3}>
                                <Button
                                  type="reset"
                                  variant="blue"
                                  width={140}
                                  onClick={() => navigate!("../", { state })}
                                >
                                  <Trans>Back</Trans>
                                </Button>
                              </Box>
                              <Box>
                                <Button variant="green" width={140}>
                                  <Trans>Save</Trans>
                                </Button>
                              </Box>
                            </Flex>
                            {addAuthError && (
                              <Error
                                message={addAuthError.message}
                                i18n={i18n}
                              />
                            )}
                          </Box>
                        </Form>
                      </>
                    );
                  }}
                </Query>
              );
            }}
          </Mutation>
        );
      }}
    </LocationParams>
  );
};

export default AddAuthMethod;

type AuthActionModalProps = {
  heading: React.ReactNode;
  warningText: React.ReactNode;
  cancelButtonText: React.ReactNode;
  confirmButtonText: React.ReactNode;
  handleToggleVisibility: () => void;
  handleSubmit: () => void;
};

const AuthActionModal = ({
  heading,
  warningText,
  cancelButtonText,
  confirmButtonText,
  handleToggleVisibility,
  handleSubmit
}: AuthActionModalProps) => (
  <Modal width={760} backdrop>
    <Heading as="h1" fontWeight="normal" mb={6}>
      {heading}
    </Heading>
    <Text lineHeight={2} textAlign="center" mb={6}>
      {warningText}
    </Text>
    <Flex justifyContent="center">
      <Box mx={2}>
        <Button variant="blue" onClick={handleToggleVisibility}>
          {cancelButtonText}
        </Button>
      </Box>
      <Box mx={2}>
        <Button
          variant="green"
          onClick={async () => {
            try {
              handleSubmit();
              handleToggleVisibility();
            } catch (errors) {
              handleToggleVisibility();
              return errors;
            }
          }}
        >
          {confirmButtonText}
        </Button>
      </Box>
    </Flex>
  </Modal>
);

export const CreateAuthMethod = gql`
  mutation CreateAuthMethod($input: CreateAuthMethodInput!) {
    createAuthMethod(input: $input) {
      authenticationMethod {
        id
        alias
        databaseId
        insertedAt
        updatedAt
        startedAt
        isActive
        phoneNumber
        type
      }
    }
  }
`;

type ErrorProps = {
  message: string;
  i18n: I18n;
};

const Error = ({ message, i18n }: ErrorProps) => {
  hadleTranslateErrors(message, i18n);
  return (
    <ErrorContentHolder>
      <Text color="red">{hadleTranslateErrors(message, i18n)}</Text>
    </ErrorContentHolder>
  );
};

const ErrorContentHolder = styled.div`
  text-align: left;
  font-size: 14px;
  margin: 14px 0;
`;

const hadleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    case "This third person is already used as auth method":
      return i18n._(t`This third person is already used as auth method`);
    case "Such person isn't active":
      return i18n._(t`Such person isn't active`);
    case "This fiduciary person is present more than 6 times in the system":
      return i18n._(
        t`This fiduciary person is present more than 6 times in the system`
      );
    case "Such person doesn't exist":
      return i18n._(t`Such person doesn't exist`);
    case "Third person must be adult":
      return i18n._(t`Third person must be adult`);
    case "Person can't use himself as third person":
      return i18n._(t`Person can't use himself as third person`);
    case "Phone number does not match third person's phone number":
      return i18n._(t`Phone number does not match third person's phone number`);
    case "Phone number should be specified. Third person has OTP method":
      return i18n._(
        t`Phone number should be specified. Third person has OTP method`
      );
    case "Validation failed":
      return i18n._(t`Validation failed`);
    case "Third person must have auth method either OTP or OFFLINE":
      return i18n._(
        t`Third person must have auth method either OTP or OFFLINE`
      );
    case "Person age is wrong":
      return i18n._(t`Person age is wrong`);
    default:
      return errorMessage;
  }
};
