import React from "react";
import MaskedInput from "react-text-mask";

import { Field } from "@edenlabllc/ehealth-components";
import { Maybe } from "@edenlabllc/graphql-schema";

import * as FieldView from "./FieldView";
import * as InputView from "./InputView";
import ErrorTranslation from "./ErrorTranslation";

type InputFieldProps = {
  name: string;
  value?: string | Maybe<number>;
  placeholder?: React.ReactNode | string;
  label?: React.ReactNode;
  hint?: string;
  warning?: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  divider?: boolean;
  is?: any; // string | React.ReactElement
  showLengthHint?: boolean;
  hideErrors?: boolean;
  type?: string;
  maxLength?: number;
  rows?: number;
  autoComplete?: string;
  format?: (data?: string) => string;
  parse?: (value: string) => string;
  disabled?: boolean;
  width?: string;
};

export const TextField = (props: InputFieldProps) => (
  <InputField {...props} type="text" />
);

export const TextareaField = (props: InputFieldProps) => (
  <InputField {...props} is="textarea" />
);

export const NumberField = (
  props: { allowNull?: boolean } & InputFieldProps
) => <InputField {...props} type="number" />;

export const MaskedField = (
  props: { mask: (RegExp | string)[]; guide: boolean } & InputFieldProps
) => <InputField {...props} is={MaskedInput} type="text" />;

export const PasswordField = (props: InputFieldProps) => (
  <InputField {...props} type="password" />
);

const InputField = ({
  label,
  hint,
  warning,
  prefix,
  postfix,
  divider,
  is = "input",
  showLengthHint,
  hideErrors = false,
  ...props
}: InputFieldProps) => (
  <Field {...props}>
    {({ input, meta }: $TSFixMe) => (
      <Input
        input={input}
        meta={meta}
        label={label}
        hint={hint}
        warning={warning}
        prefix={prefix}
        postfix={postfix}
        divider={divider}
        is={is}
        showLengthHint={showLengthHint}
        hideErrors={hideErrors}
      />
    )}
  </Field>
);

type InputProps = {
  input: {
    value: string;
    maxLength?: number;
    onChange?: (e: React.KeyboardEvent) => void;
    min?: number;
    max?: number;
    step?: number;
    type?: string;
    disabled?: boolean;
  };
  meta?: {
    state: unknown;
    errored: boolean;
    error: string;
  };
  label?: React.ReactNode;
  hint?: string;
  warning?: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  divider?: boolean;
  is?: string | React.ReactElement;
  showLengthHint?: boolean;
  hideErrors?: boolean;
  placeholder?: React.ReactNode | string;
  maxLength?: number;
};

export const Input = ({
  input,
  // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
  meta: { state, errored, error } = {},
  label,
  hint,
  warning,
  prefix,
  postfix,
  divider,
  is = "input",
  showLengthHint,
  hideErrors
}: InputProps) => (
  <FieldView.Wrapper is="label">
    {(label || input.maxLength) && (
      <FieldView.Header>
        <FieldView.Label>{label}</FieldView.Label>
        {hint && <FieldView.Message>{hint}</FieldView.Message>}
        {showLengthHint && (
          <FieldView.Message>
            Залишилось символів {input.maxLength! - input.value.length}
          </FieldView.Message>
        )}
      </FieldView.Header>
    )}

    <InputView.Divider active={divider}>
      <InputView.Border variant={state}>
        {prefix && (
          <InputView.Content pl={2} flex="none">
            {prefix}
          </InputView.Content>
        )}
        <InputView.Content
          {...input}
          is={is}
          pl={prefix ? 2 : 3}
          pr={postfix ? 2 : 3}
          width="0"
        />
        {postfix && (
          <InputView.Content pr={2} flex="none">
            {postfix}
          </InputView.Content>
        )}
      </InputView.Border>
    </InputView.Divider>
    {!hideErrors && (
      <FieldView.Footer>
        <FieldView.Message variant={state}>
          {errored ? <ErrorTranslation error={error} /> : warning}
        </FieldView.Message>
      </FieldView.Footer>
    )}
  </FieldView.Wrapper>
);

export default InputField;
