import React, { Fragment } from "react";

import {
  HealthcareServiceAvailableTime,
  Maybe
} from "@edenlabllc/graphql-schema";

import { convertAvailableTime } from "../helpers/convertAvailableTime";
import DictionaryValue from "./DictionaryValue";

type AvailableTimeProps = {
  data?: Maybe<Maybe<HealthcareServiceAvailableTime>[]>;
};

const AvailableTime = ({ data }: AvailableTimeProps) => {
  if (!data) return null;
  const obj: any = convertAvailableTime(data);

  return (
    <>
      {Object.entries(obj).map(([key, value], index) => {
        // @ts-expect-error TS(2339): Property 'availableTime' does not exist on type 'u... Remove this comment to see the full error message
        const { availableTime, allDay } = value;
        return (
          <div key={index}>
            <DictionaryValue name="DAYS_OF_WEEK" item={key} />:{" "}
            {allDay ? "24/7" : <Time availableTime={availableTime} />}
          </div>
        );
      })}
    </>
  );
};

type TimeType = {
  availableStartTime: string;
  availableEndTime: string;
};

type TimeProps = {
  availableTime: TimeType[];
};

const Time = ({ availableTime }: TimeProps) => (
  <>
    {availableTime.map(
      ({ availableStartTime, availableEndTime }, index: number) => (
        <Fragment key={index}>
          {index !== 0 && ", "}
          {availableStartTime} - {availableEndTime}
        </Fragment>
      )
    )}
  </>
);

export default AvailableTime;
