import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box, Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { DeviceDefinition } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";

import { UpdateDeviceName } from "./Mutations/UpdateDeviceName";
import { AddDeviceName } from "./Mutations/AddDeviceName";

type NamesProps = RouteComponentProps & {
  id: DeviceDefinition["id"];
  deviceNames: DeviceDefinition["deviceNames"];
};

const Names = ({ deviceNames = [], id }: NamesProps) => (
  <Box p={5}>
    {deviceNames.map((item, index: number) => (
      <React.Fragment key={`${item && item.type}-${item && item.name}`}>
        {index !== 0 && <Line />}
        <DefinitionListView
          labelWidth={160}
          alignItems="center"
          labels={{
            type: <Trans>Device definition name type</Trans>,
            name: <Trans>Device definition name description</Trans>
          }}
          data={{
            type: (
              <Flex alignItems="center">
                <DictionaryValue
                  name="device_name_type"
                  item={item && item.type}
                />
                <Box ml={10}>
                  <UpdateDeviceName
                    id={id}
                    deviceNames={deviceNames}
                    index={index}
                  />
                </Box>
              </Flex>
            ),
            name: item && item.name
          }}
        />
      </React.Fragment>
    ))}
    <AddDeviceName id={id} deviceNames={deviceNames} />
  </Box>
);

Names.fragments = {
  entry: gql`
    fragment DeviceDefinitionNames on DeviceDefinition {
      deviceNames {
        type
        name
      }
    }
  `
};

export default Names;
