import React, { useMemo } from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloQueryResult, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty, uniq } from "lodash";

import { ForbiddenGroupConnection } from "@edenlabllc/graphql-schema";

import * as Field from "../Field";

type ForbiddenGroupServiceGroupsNameFieldProps = {
  name: string;
};

const ForbiddenGroupServiceGroupsNameField = ({
  name
}: ForbiddenGroupServiceGroupsNameFieldProps) => (
  <Trans
    id="Enter Service Group Name"
    render={({ translation }) => (
      <Query
        query={SearchForbiddenGroupsQuery}
        fetchPolicy="cache-first"
        variables={{ first: 20 }}
      >
        {({
          data,
          refetch
        }: QueryResult<{ forbiddenGroups: ForbiddenGroupConnection }>) => {
          const { forbiddenGroups: { nodes: forbiddenGroupsNodes = [] } = {} } =
            data || {};

          return (
            <NamesSelect
              forbiddenGroupsNodes={forbiddenGroupsNodes}
              refetch={refetch}
              translation={translation}
              name={name}
            />
          );
        }}
      </Query>
    )}
  />
);

export default ForbiddenGroupServiceGroupsNameField;

type NamesSelectProps = {
  forbiddenGroupsNodes: ForbiddenGroupConnection["nodes"];
  refetch: (variables: any) => Promise<
    ApolloQueryResult<{
      forbiddenGroups: ForbiddenGroupConnection;
    }>
  >;
  translation: React.ReactNode;
  name: string;
};

const NamesSelect = ({
  forbiddenGroupsNodes,
  refetch,
  translation,
  name
}: NamesSelectProps) => {
  const namesList = useMemo(() => {
    const names = forbiddenGroupsNodes!.reduce((accum: $TSFixMe, item) => {
      const forbiddenGroupServicesNodes =
        item &&
        item.forbiddenGroupServices &&
        item.forbiddenGroupServices.nodes;
      if (forbiddenGroupServicesNodes && forbiddenGroupServicesNodes.length) {
        const codes = forbiddenGroupServicesNodes
          .map((item) => {
            return item && item.serviceGroup && item.serviceGroup.name;
          })
          .filter((item) => Boolean(item));
        return [...accum, ...codes];
      }
      return accum;
    }, []);
    return uniq(names);
  }, [forbiddenGroupsNodes, name]);

  const itemToString = (item: string) => item;

  return (
    <Field.Select
      name={name}
      label={<Trans id="Service Group Name" />}
      placeholder={translation}
      items={namesList}
      itemToString={itemToString}
      onInputValueChange={debounce(
        (name, { selectedItem, inputValue }) =>
          !isEmpty(name) &&
          selectedItem !== inputValue &&
          refetch({
            first: 20,
            forbiddenGroupServicesFilter: {
              serviceGroup: { name }
            }
          }),
        300
      )}
    />
  );
};

const SearchForbiddenGroupsQuery = gql`
  query SearchForbiddenGroupsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $forbiddenGroupServicesFilter: ForbiddenGroupServiceFilter
  ) {
    forbiddenGroups(
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        id
        forbiddenGroupServices(
          first: $first
          filter: $forbiddenGroupServicesFilter
        ) {
          nodes {
            id
            serviceGroup {
              id
              name
            }
          }
        }
      }
    }
  }
`;
