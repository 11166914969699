import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Flex } from "@rebass/emotion";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
// @ts-expect-error types mismatch
import { MedicalComposition } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Link from "../../../components/Link";

import dateFormatter from "../../../helpers/dateFormatter";

const getExtensionValue = (
  extensions: $TSFixMe,
  code: string,
  defaultValue = null
) => {
  const foundCode = extensions.find((c: $TSFixMe) => c.valueCode === code);
  if (!foundCode) {
    return defaultValue;
  }
  return (
    foundCode.valueString ||
    foundCode.valueBoolean ||
    foundCode.valueDate ||
    foundCode.valueUuid ||
    defaultValue
  );
};

const getBooleanField = (value: null | boolean) => {
  if (value == null) {
    return null;
  } else {
    return value ? <PositiveIcon /> : <NegativeIcon />;
  }
};

const PrepersonLink = ({ person }: $TSFixMe) => {
  return person.type.coding[0].code === "preperson" ? (
    person.value
  ) : (
    <Link to={`/persons/${btoa(`Person:${person.value}`)}`}>
      {person.value}
    </Link>
  );
};

type GeneralInfo = RouteComponentProps<{
  data: MedicalComposition;
}>;

const GeneralInfo = ({ data }: GeneralInfo) => {
  const {
    type,
    extension,
    category,
    author,
    relatesTo,
    custodian,
    date,
    encounter,
    event,
    section,
    subject
  } = data;

  const gender = getExtensionValue(extension, "NEWBORN_SEX");
  const treatmentViolation = getExtensionValue(
    extension,
    "TREATMENT_VIOLATION"
  );
  return (
    <Flex p={5}>
      <Box width={2.5 / 4}>
        <DefinitionListView
          labelWidth={160}
          labels={{
            type: <Trans>Type</Trans>,
            category: <Trans>Category</Trans>,
            subject: <Trans>Patient</Trans>,
            focus: <Trans>Medical composition focus</Trans>,
            encounter: <Trans>Context</Trans>,
            createdAt: <Trans>Created at</Trans>,
            author: <Trans>Author</Trans>,
            custodian: <Trans>Custodian</Trans>,
            relatesTo: <Trans>Medical composition relation</Trans>,
            startedAt: <Trans>Started</Trans>,
            endedAt: <Trans>Expiration date</Trans>
          }}
          data={{
            type: (
              <DictionaryValue
                name="COMPOSITION_TYPES"
                item={type.coding[0].code}
              />
            ),
            category: (
              <DictionaryValue
                name="COMPOSITION_CATEGORIES"
                item={category.coding[0].code}
              />
            ),
            subject: <PrepersonLink person={subject} />,
            focus: <PrepersonLink person={section.focus} />,
            encounter: encounter.value,
            createdAt: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              },
              date
            ),
            author: (
              <Link to={`/employees/${btoa(`Employee:${author.value}`)}`}>
                {author.value}
              </Link>
            ),
            custodian: (
              <Link
                to={`/legal-entities/${btoa(`LegalEntity:${custodian.value}`)}`}
              >
                {custodian.value}
              </Link>
            ),
            relatesTo: relatesTo[0] ? (
              <>
                <DictionaryValue
                  name="COMPOSITION_RELATION_CODE"
                  item={relatesTo[0].code}
                />{" "}
                -{" "}
                <Link
                  to={`/medical-composition/${relatesTo[0].targetIdentifier.value}`}
                >
                  {relatesTo[0].targetIdentifier.value}
                </Link>
              </>
            ) : undefined,
            startedAt: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              },
              event.find((ev: $TSFixMe) => ev.period).period.start
            ),
            endedAt: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
              },
              event.find((ev: $TSFixMe) => ev.period).period.end
            )
          }}
        />
      </Box>
      <Box width={0.25 / 4} />
      <Box width={1.25 / 4}>
        <DefinitionListView
          labels={{
            newbornDate: <Trans>Newborn date</Trans>,
            newBornGender: <Trans>Newborn gender</Trans>,
            isAccident: <Trans>Is accident</Trans>,
            treatmentViolation: <Trans>Treatment violation</Trans>,
            treatmentViolationDate: <Trans>Treatment violation date</Trans>,
            isIntoxicated: <Trans>Is intoxicated</Trans>,
            isForeignTreatment: <Trans>Is foreign treatment</Trans>,
            isForceRenew: <Trans>Is force renew</Trans>,
            authorizeWith: <Trans>Authorize method</Trans>
          }}
          data={{
            newbornDate: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric"
              },
              getExtensionValue(extension, "NEWBORN_BIRTH_DATE")
            ),
            newBornGender: gender ? (
              <DictionaryValue name="GENDER" item={gender} />
            ) : null,
            isAccident: getBooleanField(
              getExtensionValue(extension, "IS_ACCIDENT")
            ),
            treatmentViolation: treatmentViolation ? (
              <DictionaryValue
                name="COMPOSITION_TREATMENT_VIOLATION"
                item={treatmentViolation}
              />
            ) : null,
            treatmentViolationDate: dateFormatter(
              i18n.locale,
              {
                year: "numeric",
                month: "numeric",
                day: "numeric"
              },
              getExtensionValue(extension, "TREATMENT_VIOLATION_DATE")
            ),
            isIntoxicated: getBooleanField(
              getExtensionValue(extension, "IS_INTOXICATED")
            ),
            isForeignTreatment: getBooleanField(
              getExtensionValue(extension, "IS_FOREIGN_TREATMENT")
            ),
            isForceRenew: getBooleanField(
              getExtensionValue(extension, "IS_FORCE_RENEW")
            ),
            authorizeWith: getExtensionValue(extension, "AUTHORIZE_WITH")
          }}
        />
      </Box>
    </Flex>
  );
};

export default GeneralInfo;
