import * as React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Medication, Scalars } from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import GeneralInfo from "./GeneralInfo";
import Ingredients from "./Ingredients";
import Deactivate from "./Mutations/Deactivate";

type DetailsProps = RouteComponentProps<{
  id: Scalars["ID"]["output"];
}>;

const Details = ({ id }: DetailsProps) => (
  <Query query={MedicationQuery} variables={{ id }}>
    {({ loading, data }: QueryResult<{ medication: Medication }>) => {
      if (isEmpty(data) || isEmpty(data.medication)) return null;
      const {
        databaseId,
        isActive,
        name,
        atcCodes,
        packageMinQty,
        packageQty,
        form,
        manufacturer,
        certificate,
        certificateExpiredAt,
        ingredients,
        container,
        maxRequestDosage,
        formPharm,
        endDate,
        drlzSkuId
      } = data.medication;

      return (
        <LoadingOverlay loading={loading}>
          <Box p={6}>
            <Box py={10}>
              <Breadcrumbs.List>
                <Breadcrumbs.Item to="/medications">
                  <Trans>Medications</Trans>
                </Breadcrumbs.Item>
                <Breadcrumbs.Item>
                  <Trans>Medication details</Trans>
                </Breadcrumbs.Item>
              </Breadcrumbs.List>
            </Box>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Box>
                <DefinitionListView
                  labels={{
                    databaseId: <Trans>ID</Trans>,
                    name: <Trans>Name</Trans>,
                    status: <Trans>Status</Trans>
                  }}
                  data={{
                    databaseId,
                    name,
                    status: (
                      <Badge
                        name={isActive}
                        type="ACTIVE_STATUS_M"
                        variant={!isActive}
                        minWidth={100}
                      />
                    )
                  }}
                  color="#7F8FA4"
                  labelWidth="100px"
                />
              </Box>
              {isActive && <Deactivate id={id!} name={name} />}
            </Flex>
          </Box>

          <Tabs.Nav>
            <Tabs.NavItem to="./">
              <Trans>General info</Trans>
            </Tabs.NavItem>
            <Tabs.NavItem to="./ingredients">
              <Trans>Ingredients</Trans>
            </Tabs.NavItem>
          </Tabs.Nav>
          <Tabs.Content>
            <Router>
              <GeneralInfo
                path="/"
                atcCodes={atcCodes}
                packageMinQty={packageMinQty}
                packageQty={packageQty}
                form={form}
                manufacturer={manufacturer}
                certificate={certificate}
                certificateExpiredAt={certificateExpiredAt}
                container={container}
                maxRequestDosage={maxRequestDosage}
                formPharm={formPharm}
                endDate={endDate}
                drlzSkuId={drlzSkuId}
              />
              <Ingredients path="/ingredients" data={ingredients} />
            </Router>
          </Tabs.Content>
        </LoadingOverlay>
      );
    }}
  </Query>
);

const MedicationQuery = gql`
  query MedicationQuery($id: ID!) {
    medication(id: $id) {
      id
      databaseId
      isActive
      name
      ...MedicationGeneralInfo
      ...MedicationIngredients
    }
  }
  ${GeneralInfo.fragments.entry}
  ${Ingredients.fragments.entry}
`;

export default Details;
