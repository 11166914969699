import React from "react";

import { Maybe, PersonDocument } from "@edenlabllc/graphql-schema";

import { Document } from "./EditPerson";
import TableDiff from "./TableDiff";
import { DictionaryAllValuesJson } from "../../../../components/DictionaryValue";

type DocumentsTableProps = {
  header: DictionaryAllValuesJson;
  person?: Array<Maybe<PersonDocument>> | null;
  masterPerson?: Array<Maybe<PersonDocument>> | null;
};

const DocumentsTable = ({
  header,
  person,
  masterPerson,
  ...props
}: DocumentsTableProps) => (
  <TableDiff
    header={header}
    data={combineNestedData(person, masterPerson, header)}
    renderRow={({ ...documents }) => {
      const [row] = Object.entries(documents).map(([key, value]) => ({
        [key]: <Document data={value as PersonDocument} />
      }));
      return row;
    }}
    {...props}
    hideEmptyFields
  />
);

export default DocumentsTable;

export const combineNestedData = (
  person: any,
  masterPerson: any,
  header: DictionaryAllValuesJson
) => {
  const types = Object.keys(header);
  return [
    convertTypeToKey(person, types),
    convertTypeToKey(masterPerson, types)
  ];
};

const convertTypeToKey = (data: any = [], types: string[]) =>
  types.reduce(
    (summary, item) => ({
      ...summary,
      [item]: Array.isArray(data)
        ? data.find(
            ({ type, relationType }: any) =>
              type === item || relationType === item
          )
        : undefined
    }),
    {}
  );
