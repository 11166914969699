import React from "react";
import { Router, Redirect, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  CarePlan as TCarePlan,
  Reference as TReference
} from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import CodeableConcept from "../../../components/CodeableConcept";
import Collapsable from "../../../components/Collapsable";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";

import dateFormatter from "../../../helpers/dateFormatter";
import paramToBase64 from "../../../helpers/paramToBase64";
import {
  FunctionComponentWithFragments,
  MonitoringDetailsPageProps
} from "../../../helpers/types";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

import { CARE_PLANS_DISPENSES_STATUSES } from "../Search/CarePlansTab";
import { ImpersonalActivitiesQuery } from "../../ClinicalMonitoring/graphql/Activities";
import CreateConclusion from "../../ClinicalMonitoring/CreateConclusion";
import getConclusionData from "../../ClinicalMonitoring/getConclusionData";
import Conclusions from "../../ClinicalMonitoring/Conclusions";
import { ActivitiesTab } from "./Activity/ActivitiesTab";
import CodeableConceptList from "./CodeableConceptList";
import DetailsPageBreadcrumbs from "./DetailsPageBreadcrumbs";
import Period from "./Period";
import Reference from "./Reference";
import ReferenceList from "./ReferenceList";
import StatusHistory from "./StatusHistory";
import SupportingInfo from "./SupportingInfo";
import { LABEL_WIDTH } from "./Episode";

const DEFAULT_REFERENCE_PROPS = {
  linkWrapperProps: {
    mt: 2,
    ml: 0
  }
};

const CarePlan: FunctionComponentWithFragments<MonitoringDetailsPageProps> = ({
  query,
  queryVariables,
  itemId,
  id,
  uri,
  patientId,
  isClinicalMonitoring = false
}) => {
  return (
    <Ability
      action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
      resource="care_plan"
    >
      <Box p={6}>
        <DetailsPageBreadcrumbs
          id={id}
          currentStepName={<Trans>Care plan details</Trans>}
          isClinicalMonitoring={isClinicalMonitoring}
        />
        <Query
          query={query!}
          fetchPolicy="network-only"
          variables={{
            ...queryVariables,
            id: itemId,
            ...(isClinicalMonitoring && { patientId })
          }}
        >
          {({
            data = {},
            loading,
            error
          }: QueryResult<{ [key: string]: TCarePlan }>) => {
            const carePlan = isClinicalMonitoring
              ? data.impersonalCarePlan
              : data.carePlan;

            return (
              <LoadingOverlay loading={loading}>
                <Box mt={4}>
                  {!isEmpty(data) && !isEmpty(carePlan) && !error && (
                    <>
                      <HeaderDefinitions
                        carePlan={carePlan}
                        isClinicalMonitoring={isClinicalMonitoring}
                        patientId={patientId}
                      />
                      <Box mt={4}>
                        <Tabs.Nav>
                          <Tabs.NavItem to={"./general-info"}>
                            <Trans>General info</Trans>
                          </Tabs.NavItem>
                          <Tabs.NavItem to={"./activities"}>
                            <Trans>Activities</Trans>
                          </Tabs.NavItem>
                        </Tabs.Nav>
                        <Tabs.Content>
                          <Router>
                            <Redirect from="/" to={`${uri}/general-info`} />
                            <GeneralInfoTab
                              path="general-info"
                              carePlan={carePlan}
                              isClinicalMonitoring={isClinicalMonitoring}
                              patientId={patientId}
                            />
                            <ActivitiesTab
                              path="activities"
                              query={
                                isClinicalMonitoring
                                  ? ImpersonalActivitiesQuery
                                  : undefined
                              }
                              isClinicalMonitoring={isClinicalMonitoring}
                            />
                          </Router>
                        </Tabs.Content>
                      </Box>
                    </>
                  )}
                </Box>
              </LoadingOverlay>
            );
          }}
        </Query>
        <Conclusions
          isClinicalMonitoring={isClinicalMonitoring}
          patientId={patientId!}
          itemId={itemId!}
        />
      </Box>
    </Ability>
  );
};

export default CarePlan;

type HeaderDefinitionsProps = {
  carePlan: TCarePlan;
  patientId?: string;
  isClinicalMonitoring?: boolean;
};

const HeaderDefinitions = ({
  carePlan,
  isClinicalMonitoring,
  patientId
}: HeaderDefinitionsProps) => {
  const { i18n } = useLingui();
  if (isEmpty(carePlan)) return null;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div>
          <DefinitionListView
            labels={{
              databaseId: <Trans>ID</Trans>,
              status: <Trans>Status</Trans>,
              ...(carePlan.statusReason && {
                statusReason: <Trans>Reason status</Trans>
              }),
              title: <Trans>Title</Trans>
            }}
            data={{
              ...carePlan,
              status: i18n._(
                CARE_PLANS_DISPENSES_STATUSES.values[
                  carePlan.status.toLowerCase()
                ]
              ),
              statusReason: (
                <CodeableConcept codeableConcept={carePlan.statusReason} />
              )
            }}
            color="#7F8FA4"
            labelWidth={LABEL_WIDTH}
          />
        </div>
        {isClinicalMonitoring && (
          <CreateConclusion
            initialData={getConclusionData(
              patientId!,
              "care_plan",
              carePlan.databaseId
            )}
          />
        )}
      </Flex>
      <Line />
    </>
  );
};

type GeneralInfoTabProps = RouteComponentProps & {
  carePlan: TCarePlan;
  patientId?: string;
  isClinicalMonitoring?: boolean;
};

const GeneralInfoTab = ({
  carePlan,
  patientId,
  isClinicalMonitoring
}: GeneralInfoTabProps) => {
  if (isEmpty(carePlan)) return null;

  const handleSetReferenceLink = (
    memoizedReferenceType: string,
    memoizedReferenceSlug: string,
    reference: TReference
  ) => {
    switch (memoizedReferenceType) {
      case "EpisodeOfCare":
        return isClinicalMonitoring
          ? `../../../../episode/${patientId}/${paramToBase64(
              "Episode",
              reference.identifier.value
            )}`
          : `../../../episode/${paramToBase64(
              "Episode",
              reference.identifier.value
            )}`;
      default:
        return isClinicalMonitoring
          ? `../../../../${memoizedReferenceSlug}/${patientId}/${paramToBase64(
              memoizedReferenceType,
              reference.identifier.value
            )}`
          : `../../../${memoizedReferenceSlug}/${paramToBase64(
              memoizedReferenceType,
              reference.identifier.value
            )}`;
    }
  };

  return (
    <Box my={4} ml={2}>
      <TopBodyDefinitions carePlan={carePlan} />
      <Box mt={2}>
        <Reference
          fontSize={14}
          header={<Trans>Encounter</Trans>}
          linkPath={
            isClinicalMonitoring
              ? `../../../../encounter/${patientId}/${paramToBase64(
                  "Encounter",
                  carePlan.encounter.identifier.value
                )}`
              : `../../../encounter/${paramToBase64(
                  "Encounter",
                  carePlan.encounter.identifier.value
                )}`
          }
          linkDisplayValue={
            carePlan.encounter.displayValue
              ? carePlan.encounter.displayValue
              : carePlan.encounter.identifier.value
          }
          {...DEFAULT_REFERENCE_PROPS}
        />
      </Box>
      {!isEmpty(carePlan.basedOn) && (
        <Box mt={2}>
          <Reference
            fontSize={14}
            header={<Trans id="Care plan based on">Based on</Trans>}
            linkPath={
              isClinicalMonitoring
                ? `../../../../care-plan/${patientId}/${paramToBase64(
                    "CarePlan",
                    carePlan.basedOn.identifier.value
                  )}`
                : `../../../care-plan/${paramToBase64(
                    "CarePlan",
                    carePlan.basedOn.identifier.value
                  )}`
            }
            linkDisplayValue={
              carePlan.basedOn.displayValue
                ? carePlan.basedOn.displayValue
                : carePlan.basedOn.identifier.value
            }
            {...DEFAULT_REFERENCE_PROPS}
          />
        </Box>
      )}
      {!isEmpty(carePlan.partOf) && (
        <Box mt={2}>
          <Reference
            fontSize={14}
            header={<Trans id="Care plan part of">Part of</Trans>}
            linkPath={
              isClinicalMonitoring
                ? `../../../../care-plan/${patientId}/${paramToBase64(
                    "CarePlan",
                    carePlan.partOf.identifier.value
                  )}`
                : `../../../care-plan/${paramToBase64(
                    "CarePlan",
                    carePlan.partOf.identifier.value
                  )}`
            }
            linkDisplayValue={
              carePlan.partOf.displayValue
                ? carePlan.partOf.displayValue
                : carePlan.partOf.identifier.value
            }
            {...DEFAULT_REFERENCE_PROPS}
          />
        </Box>
      )}
      <Box mt={2}>
        <Reference
          fontSize={14}
          header={<Trans>Author</Trans>}
          linkPath={`/employees/${paramToBase64(
            "Employee",
            carePlan.author.identifier.value
          )}`}
          linkDisplayValue={
            carePlan.author.displayValue
              ? carePlan.author.displayValue
              : carePlan.author.identifier.value
          }
          {...DEFAULT_REFERENCE_PROPS}
        />
      </Box>
      <Box mt={4}>
        <MiddleBodyDefinitions carePlan={carePlan} />
      </Box>
      {!isEmpty(carePlan.statusHistory) && (
        <Collapsable title={<Trans>Status history</Trans>}>
          <StatusHistory
            statusHistory={carePlan.statusHistory}
            statusValues={CARE_PLANS_DISPENSES_STATUSES}
          />
        </Collapsable>
      )}
      {!isEmpty(carePlan.supportingInfo) && (
        <Collapsable
          title={
            <Text color="darkCharcoal">
              <Trans>Supporting info</Trans>
            </Text>
          }
        >
          <SupportingInfo
            supportingInfo={carePlan.supportingInfo}
            handleSetReferenceLink={handleSetReferenceLink}
          />
        </Collapsable>
      )}
      <Box mt={4}>
        <BottomBodyDefinitions carePlan={carePlan} />
      </Box>
    </Box>
  );
};

const TopBodyDefinitions = ({ carePlan }: { carePlan: TCarePlan }) => {
  if (isEmpty(carePlan)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        requisition: <Trans>Requisition</Trans>,
        category: <Trans>Category</Trans>,
        addresses: (
          <Trans id="Care plans Addresses - Describes diagnoses">
            Addresses
          </Trans>
        )
      }}
      data={{
        ...carePlan,
        category: <CodeableConcept codeableConcept={carePlan.category} />,
        addresses: (
          <CodeableConceptList
            codeableConceptList={carePlan.addresses}
            withCode
          />
        )
      }}
    />
  );
};

const MiddleBodyDefinitions = ({ carePlan }: { carePlan: TCarePlan }) => {
  if (isEmpty(carePlan)) return null;

  return (
    <DefinitionListView
      fontSize={14}
      labelWidth={LABEL_WIDTH}
      labels={{
        ...(!isEmpty(carePlan.contributor) && {
          contributor: <Trans>Contributor</Trans>
        }),
        period: <Trans>Period</Trans>,
        termsOfService: <Trans>Terms of service</Trans>,
        intent: <Trans>Intent</Trans>,
        ...(carePlan.description && {
          description: <Trans>Description</Trans>
        }),
        ...(carePlan.note && { note: <Trans>Note</Trans> })
      }}
      data={{
        ...carePlan,
        intent: (
          <DictionaryValue
            name="MEDICATION_REQUEST_INTENT"
            item={carePlan.intent.toLowerCase()}
          />
        ),
        contributor: (
          <ReferenceList
            referencesList={carePlan.contributor}
            pathSlug="/employees"
            entityName="Employee"
          />
        ),
        period: (
          <Period datePeriod={carePlan.period} wrapperProps={{ mt: 0 }} />
        ),
        termsOfService: (
          <CodeableConcept codeableConcept={carePlan.termsOfService} />
        )
      }}
    />
  );
};

const BottomBodyDefinitions = ({ carePlan }: { carePlan: TCarePlan }) => {
  const { i18n } = useLingui();
  if (isEmpty(carePlan)) return null;

  return (
    <Box mt={4}>
      <DefinitionListView
        fontSize={14}
        labels={{
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>
        }}
        data={{
          insertedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            carePlan.insertedAt
          ),
          updatedAt: dateFormatter(
            i18n.locale,
            DATE_TIME_FORMAT,
            carePlan.updatedAt
          )
        }}
        labelWidth={LABEL_WIDTH}
      />
    </Box>
  );
};

CarePlan.fragments = {
  entry: gql`
    fragment CarePlan on CarePlan {
      id
      databaseId
      status
      statusReason {
        ...CodeableConcept
      }
      title
      requisition
      category {
        ...CodeableConcept
      }
      addresses {
        ...CodeableConcept
      }
      encounter {
        ...Reference
      }
      basedOn {
        ...Reference
      }
      partOf {
        ...Reference
      }
      author {
        ...Reference
      }
      contributor {
        ...Reference
      }
      period {
        ...Period
      }
      termsOfService {
        ...CodeableConcept
      }
      intent
      description @skip(if: $skipAdditionalFields)
      note @skip(if: $skipAdditionalFields)
      statusHistory {
        ...StatusHistory
      }
      supportingInfo @skip(if: $skipAdditionalFields) {
        ...Reference
      }
      insertedAt
      updatedAt
    }
    ${CodeableConcept.fragments!.entry}
    ${Reference.fragments.entry}
    ${Period.fragments!.entry}
    ${StatusHistory.fragments.entry}
  `
};

export const CarePlanByIDQuery = gql`
  query CarePlanByIDQuery(
    $id: ID!
    $personId: ID!
    $skipAdditionalFields: Boolean!
  ) {
    carePlan(id: $id, personId: $personId) {
      ...CarePlan
    }
  }
  ${CarePlan.fragments.entry}
`;
