import React from "react";
import { t, Trans } from "@lingui/macro";
import { TransRenderProps, useLingui } from "@lingui/react";
import { Flex, Box } from "@rebass/emotion";
import Composer from "react-composer";

import { SearchIcon } from "@edenlabllc/ehealth-icons";
import { Validation } from "@edenlabllc/ehealth-components";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";

import STATUSES from "../../../helpers/statuses";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

const PrimarySearchFields = () => {
  const { i18n } = useLingui();

  return (
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <SearchField.INNMDosage
          name="filter.innmdosage"
          filteredParams={["name"]}
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <SearchField.INNM
          name="filter.ingredients.innm.name"
          placeholder={i18n._(t`Enter ingredient name`)}
          label={<Trans>Ingredient name</Trans>}
          getItemByKey="name"
        />
      </Box>
      <Box px={1} width={1 / 3}>
        <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_F" />
      </Box>
    </Flex>
  );
};

const SecondarySearchFields = () => {
  const { i18n } = useLingui();

  return (
    <>
      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <Field.Text
            name="filter.databaseId"
            label={<Trans>INNM dosage ID</Trans>}
            placeholder={i18n._(t`Enter ID`)}
            postfix={<SearchIcon color="silverCity" />}
          />
          <Validation.Matches
            field="filter.databaseId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Field.Text
            name="filter.ingredients.innm.databaseId"
            label={<Trans>Ingredient ID</Trans>}
            placeholder={i18n._(t`Enter ID`)}
            postfix={<SearchIcon color="silverCity" />}
          />
          <Validation.Matches
            field="filter.ingredients.innm.databaseId"
            options={UUID_PATTERN}
            message="Invalid number"
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <Composer
            components={[
              <DictionaryValue name="MEDICATION_FORM" />,
              ({
                render
              }: {
                render: (props: TransRenderProps) => React.ReactElement;
              }) => <Trans render={render}>Select option</Trans>
            ]}
          >
            {([dict, { translation }]: [
              DictionaryAllValuesJson,
              { translation: React.ReactNode }
            ]) => (
              <Field.Select
                name="filter.form"
                label={<Trans>Form</Trans>}
                placeholder={translation}
                items={Object.keys(dict)}
                itemToString={(item: string) =>
                  dict[item] || String(translation)
                }
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            )}
          </Composer>
        </Box>
      </Flex>
      <Flex mx={-1}>
        <Box px={1} width={1 / 3}>
          <Composer
            components={[
              <DictionaryValue name="MR_BLANK_TYPES" />,
              ({
                render
              }: {
                render: (props: TransRenderProps) => React.ReactElement;
              }) => <Trans render={render}>Select option</Trans>
            ]}
          >
            {([dict, { translation }]: [
              DictionaryAllValuesJson,
              { translation: React.ReactNode }
            ]) => (
              <Field.Select
                name="filter.mrBlankType"
                label={<Trans>Type of Medication request blank</Trans>}
                items={Object.keys(dict)}
                itemToString={(item: string) =>
                  dict[item] || String(translation)
                }
                variant="select"
                emptyOption
                filterOptions={{ keys: [(item: string) => dict[item]] }}
              />
            )}
          </Composer>
        </Box>
        <Box px={1} width={1 / 3}>
          <Field.Select
            name="filter.dosageFormIsDosed"
            label={<Trans>Is Dosage Form dosed</Trans>}
            items={Object.keys(STATUSES.YES_NO_NULL)}
            itemToString={(item: boolean | null) =>
              // @ts-expect-error statuses boolean key
              STATUSES.YES_NO_NULL[item] || i18n._(t`Select option`)
            }
            variant="select"
            emptyOption
            filterOptions={{
              // @ts-expect-error statuses boolean key
              keys: [(item: boolean | null) => STATUSES.YES_NO_NULL[item]]
            }}
          />
        </Box>
      </Flex>
    </>
  );
};

export { PrimarySearchFields, SecondarySearchFields };
