import * as React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";

import { Form } from "@edenlabllc/ehealth-components";
import { Scalars } from "@edenlabllc/graphql-schema";

import Button, { ButtonVariantType } from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";

type UpdateStatusProps = {
  id: Scalars["ID"]["output"];
  isActive: boolean;
};

const UpdateStatus = ({ id, isActive }: UpdateStatusProps) => {
  const variant = isActive ? "orange" : "green";
  const status = isActive ? "SUSPENDED" : "ACTIVE";

  const [isVisible, setVisibilityState] = React.useState(false);
  const toggle = () => setVisibilityState(!isVisible);

  return (
    <Mutation mutation={UpdateLegalEntityStatusMutation}>
      {(updateLegalEntityStatus: MutationFunction) => (
        <>
          <UpdateButton toggle={toggle} variant={variant} isActive={isActive} />
          <Popup
            visible={isVisible}
            okButtonProps={{ type: "submit", variant }}
            onCancel={toggle}
            title={<Title isActive={isActive} />}
            formId="updateLegalEntityStatusForm"
            justifyButtons="left"
          >
            <Form
              onSubmit={async ({ reason }: { reason: string }) => {
                await updateLegalEntityStatus({
                  variables: { input: { id, reason, status } }
                });
                toggle();
              }}
              id="updateLegalEntityStatusForm"
            >
              <Trans
                id="Enter reason comment"
                render={({ translation }) => (
                  <Field.Textarea
                    name="reason"
                    placeholder={translation}
                    rows={5}
                    maxLength={3000}
                    showLengthHint
                  />
                )}
              />
            </Form>
          </Popup>
        </>
      )}
    </Mutation>
  );
};

type UpdateButtonProps = {
  variant: ButtonVariantType;
  toggle: () => void;
  isActive: boolean;
};

const UpdateButton = ({ variant, toggle, isActive }: UpdateButtonProps) => (
  <Button variant={variant} onClick={toggle}>
    <Title isActive={isActive} />
  </Button>
);

const Title = ({ isActive }: { isActive: boolean }) =>
  isActive ? (
    <Trans>Suspend Legal Entity</Trans>
  ) : (
    <Trans>Activate Legal Entity</Trans>
  );

const UpdateLegalEntityStatusMutation = gql`
  mutation UpdateLegalEntityStatusMutation(
    $input: UpdateLegalEntityStatusInput!
  ) {
    updateLegalEntityStatus(input: $input) {
      legalEntity {
        id
        status
        nhsReviewed
      }
    }
  }
`;

export default UpdateStatus;
