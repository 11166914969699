import * as React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";

import Ability from "../../../../components/Ability";
import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";

import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

type DeactivateProps = {
  id: string;
};

const Deactivate = ({ id }: DeactivateProps) => {
  const { i18n } = useLingui();
  const [isVisible, toggle] = React.useReducer((v) => !v, false);
  const [error, setError] = React.useState<string | null>(null);

  return (
    <Ability action="deactivate" resource="device_definition">
      <Mutation mutation={DeactivateDeviceDefinition}>
        {(deactivateDeviceDefinition: MutationFunction) => (
          <>
            <Button onClick={toggle} variant="red">
              <Trans>Deactivate</Trans>
            </Button>
            <Popup
              visible={isVisible}
              onCancel={toggle}
              title={
                <>
                  <Trans>Deactivate</Trans>?
                </>
              }
              okText={<Trans>Deactivate</Trans>}
              onOk={async () => {
                setError(null);

                try {
                  const { data } = await deactivateDeviceDefinition({
                    variables: {
                      input: {
                        id
                      }
                    }
                  });
                  if (data.deactivateDeviceDefinition) {
                    toggle();
                  }
                } catch (error) {
                  if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                    const errorTranslation = handleTranslateErrors(
                      getErrorMessage(error),
                      i18n
                    );
                    setError(errorTranslation);
                  }
                }
              }}
            />
            {error && (
              <UnpocessableEntityModalError errorMessage={error} isModalOpen />
            )}
          </>
        )}
      </Mutation>
    </Ability>
  );
};

export default Deactivate;

const DeactivateDeviceDefinition = gql`
  mutation DeactivateDeviceDefinition(
    $input: DeactivateDeviceDefinitionInput!
  ) {
    deactivateDeviceDefinition(input: $input) {
      deviceDefinition {
        id
        isActive
      }
    }
  }
`;

const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    case "Device definition has active Program devices":
      return i18n._(t`Device definition has active Program devices`);
    default:
      return errorMessage;
  }
};
