import * as React from "react";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { I18n } from "@lingui/core";

import { DictionaryValue } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";
import UnpocessableEntityModalError from "../../../../components/UnpocessableEntityModalError";
import {
  getErrorCode,
  getErrorMessage
} from "../../../../helpers/errorHelpers";

type ActivateValueProps = {
  id: DictionaryValue["id"];
};

const ActivateValue = ({ id }: ActivateValueProps) => {
  const { i18n } = useLingui();
  const [isVisible, toggle] = React.useReducer((v) => !v, false);
  const [error, setError] = React.useState<string | null>(null);

  return (
    <Mutation mutation={ActivateDictionaryValue}>
      {(activateDictionaryValue: MutationFunction) => (
        <>
          <Button onClick={toggle} variant="green">
            <Trans>Activate</Trans>
          </Button>
          <Popup
            visible={isVisible}
            onCancel={toggle}
            title={
              <>
                <Trans>Activate</Trans>?
              </>
            }
            okText={<Trans>Activate</Trans>}
            onOk={async () => {
              setError(null);

              try {
                const { data } = await activateDictionaryValue({
                  variables: {
                    input: {
                      id
                    }
                  }
                });
                if (data.activateDictionaryValue) {
                  toggle();
                }
              } catch (error) {
                if (getErrorCode(error) === "UNPROCESSABLE_ENTITY") {
                  const errorTranslation = handleTranslateErrors(
                    getErrorMessage(error),
                    i18n
                  );
                  setError(errorTranslation);
                }
              }
            }}
          />
          {error && (
            <UnpocessableEntityModalError errorMessage={error} isModalOpen />
          )}
        </>
      )}
    </Mutation>
  );
};

export default ActivateValue;

const ActivateDictionaryValue = gql`
  mutation ActivateDictionaryValue($input: ActivateDictionaryValueInput!) {
    activateDictionaryValue(input: $input) {
      dictionaryValue {
        id
        isActive
      }
    }
  }
`;

const handleTranslateErrors = (errorMessage: string, i18n: I18n) => {
  switch (errorMessage) {
    default:
      return errorMessage;
  }
};
