import React from "react";
import { gql } from "graphql-tag";
import { Flex } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { NegativeIcon, PositiveIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  ServiceGroup,
  ServiceGroupConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Link from "../../../components/Link";
import { SearchParams, SetSearchParams } from "../../../components/SearchForm";
import Table, { SortingParams } from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type ServiceGroupsTableProps = {
  serviceGroups: ServiceGroupConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
  tableName?: string;
};

const ServiceGroupsTable = ({
  serviceGroups,
  locationParams,
  setLocationParams,
  tableName = "service-groups-table/search"
}: ServiceGroupsTableProps) => (
  <Table
    data={serviceGroups}
    header={{
      databaseId: <Trans>ID</Trans>,
      name: <Trans>Name</Trans>,
      code: <Trans>Code</Trans>,
      isActive: <Trans>Status</Trans>,
      requestAllowed: <Trans>Is request allowed</Trans>,
      insertedAt: <Trans>Inserted at</Trans>,
      updatedAt: <Trans>Updated at</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      insertedAt,
      updatedAt,
      isActive,
      requestAllowed,
      code,
      ...serviceGroupData
    }: ServiceGroup) => ({
      ...serviceGroupData,
      code,
      insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
      updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
      isActive: (
        <Badge
          type="ACTIVE_STATUS_F"
          name={isActive}
          variant={!isActive}
          display="block"
        />
      ),
      requestAllowed: (
        <Flex justifyContent="center">
          {requestAllowed ? <PositiveIcon /> : <NegativeIcon />}
        </Flex>
      ),
      details: (
        <Link to={`/service-groups/${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["name", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    hiddenFields="updatedAt"
    tableName={tableName}
  />
);

ServiceGroupsTable.fragments = {
  entry: gql`
    fragment ServiceGroups on ServiceGroup {
      id
      databaseId
      name
      code
      isActive
      insertedAt
      updatedAt
      requestAllowed
    }
  `
};

export default ServiceGroupsTable;
