import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { differenceInCalendarDays } from "date-fns";
import { Box, Flex } from "@rebass/emotion";

import { Form } from "@edenlabllc/ehealth-components";
import { Scalars, ProgramDevice } from "@edenlabllc/graphql-schema";

import Button from "../../../../components/Button";
import * as Field from "../../../../components/Field";
import Popup from "../../../../components/Popup";

import { UpdateProgramDeviceMutation } from "./Update";

type UpdateReimbursementDataProps = {
  id: Scalars["ID"]["input"];
  startDate: ProgramDevice["startDate"];
  endDate: ProgramDevice["endDate"];
};

const UpdateReimbursementData = ({
  id,
  startDate,
  endDate
}: UpdateReimbursementDataProps) => {
  const { i18n } = useLingui();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const toggle = () => setPopupVisibility(!isPopupVisible);

  return (
    <Box>
      <Button variant="blue" onClick={toggle}>
        <Trans>Update reimbursement data</Trans>
      </Button>
      <Mutation mutation={UpdateProgramDeviceMutation}>
        {(updateProgramDevice: MutationFunction) => (
          <Popup
            visible={isPopupVisible}
            onCancel={toggle}
            title={<Trans>Update reimbursement data</Trans>}
            okButtonProps={{ variant: "green" }}
            okText={<Trans>Update</Trans>}
            justifyButtons="left"
            formId="updateReimbursementData"
          >
            <Form
              id="updateReimbursementData"
              validate={(values: { endDate: string }) => {
                const errors: any = {};
                if (startDate && values.endDate) {
                  const differenceInDays = differenceInCalendarDays(
                    new Date(values.endDate),
                    new Date(startDate)
                  );
                  if (differenceInDays < 0) {
                    errors.endDate = i18n._(
                      t`End date must be greater than start date`
                    );
                  }
                }
                return errors;
              }}
              initialValues={{
                endDate
              }}
              onSubmit={async ({ endDate }: { endDate: string }) => {
                toggle();
                await updateProgramDevice({
                  variables: {
                    input: {
                      id,
                      endDate: endDate || null
                    }
                  }
                });
              }}
            >
              <Flex mx={-1}>
                <Box px={1} width={1}>
                  <Field.DatePicker
                    name="endDate"
                    label={<Trans id="End date" />}
                    minDate="1900-01-01"
                  />
                </Box>
              </Flex>
            </Form>
          </Popup>
        )}
      </Mutation>
    </Box>
  );
};

export default UpdateReimbursementData;
