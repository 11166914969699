import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { MutationFunction } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box, Text, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import {
  Form,
  LocationParams,
  Modal,
  SUBMIT_ERROR,
  Validation
} from "@edenlabllc/ehealth-components";
import { RemoveItemIcon, DropDownButton } from "@edenlabllc/ehealth-icons";
import { getFullName } from "@edenlabllc/ehealth-utils";
import {
  Maybe,
  Person,
  PersonAuthenticationMethod,
  Scalars
} from "@edenlabllc/graphql-schema";

import Badge from "../../../components/Badge";
import Button from "../../../components/Button";
import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import SearchForm, {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../../components/SearchForm";
import Table from "../../../components/Table";

import { useAbility } from "../../../helpers/useAbility";
import dateFormatter from "../../../helpers/dateFormatter";
import filteredLocationParams from "../../../helpers/filteredLocationParams";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";
import { ITEMS_PER_PAGE } from "../../../constants/pagination";
import { AUTH_METHODS_FILTER_DEFAULTS } from "../../../constants/filterValues";

import { PersonQuery } from "./index";
import ResetAuth from "./ResetAuth";

const PERSONS_OWN_METHODS = ["OTP", "OFFLINE", "NA"];
const FILTER_IS_ENDED_OPTIONS = {
  // @ts-expect-error statuses boolean key
  [true]: "Ні",
  // @ts-expect-error statuses boolean key
  [false]: "Так"
};

const defaultError = [
  {
    entry: "default"
  }
];

type AuthInfoProps = RouteComponentProps<{
  id: Scalars["ID"]["output"];
  databaseId: Scalars["UUID"]["output"];
  status: Person["status"];
  authInfo: Maybe<Array<Maybe<PersonAuthenticationMethod>>>;
  personId: Scalars["ID"]["output"];
}>;

const AuthInfo = ({
  databaseId,
  status,
  authInfo,
  personId,
  navigate
}: AuthInfoProps) => {
  const [isVisibleDeactivateAuthModal, setVisibilityDeactivateAuthModal] =
    useState(false);
  const [personAuthMethodRequestAbility] = useAbility(
    "write_nhs",
    "authentication_method_request"
  );
  const [deactivateAllAuthMethodsAbility] = useAbility(
    "reset_authentication_method",
    "person"
  );
  const [isVisibleEditAuthModal, setVisibilityEditAuthModal] = useState(false);

  if (!authInfo || !status || !databaseId) return null;

  const toggleVisibilityDeactivateAuthModal = () =>
    setVisibilityDeactivateAuthModal((prevState) => !prevState);

  const toggleVisibilityEditAuthModal = () =>
    setVisibilityEditAuthModal((prevState) => !prevState);

  const isPersonInactive = status === "INACTIVE";

  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const formInitialValues = locationParams.filter
          ? filteredLocationParams(locationParams)
          : AUTH_METHODS_FILTER_DEFAULTS;

        return (
          <>
            <Box p={5}>
              <Flex mx={-1} justifyContent="space-between">
                <SearchForm
                  initialValues={formInitialValues}
                  onSubmit={setLocationParams}
                  renderPrimary={AuthInfoMethodsFilter}
                />
                <Flex flexDirection="column">
                  {personAuthMethodRequestAbility && (
                    <Button
                      disabled={isPersonInactive}
                      variant={isPersonInactive ? "linkDisabled" : "link"}
                      onClick={async () => {
                        if (navigate) {
                          await navigate("./add-auth-method", {
                            state: { authMethod: "OFFLINE" }
                          });
                        }
                      }}
                      icon={DropDownButton}
                      px={0}
                      mb={4}
                    >
                      <Trans>Create new authentication method</Trans>
                    </Button>
                  )}
                  {deactivateAllAuthMethodsAbility && (
                    <ResetAuth
                      status={status}
                      databaseId={databaseId}
                      authSearchValues={{
                        ...formInitialValues,
                        first: locationParams.first
                          ? parseInt(locationParams.first)
                          : ITEMS_PER_PAGE[0],
                        id: personId
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            </Box>
            {isEmpty(authInfo) ? (
              <Box mt={5}>
                <EmptyData mx={5} />
              </Box>
            ) : (
              <Box p={5}>
                <Table
                  tableName="person/authenticationMethods"
                  data={authInfo}
                  header={{
                    type: <Trans>Type</Trans>,
                    phoneNumber: <Trans>Own phone number</Trans>,
                    thirdPerson: <Trans>Third person</Trans>,
                    ownAuthMethodThirdPerson: (
                      <Trans>Own auth method third person</Trans>
                    ),
                    alias: <Trans>Alias</Trans>,
                    startedAt: <Trans>Date started</Trans>,
                    endedAt: <Trans>Date ended</Trans>,
                    isActive: <Trans>Status</Trans>,
                    ...(personAuthMethodRequestAbility && {
                      action: <Trans>Action</Trans>
                    })
                  }}
                  renderRow={({
                    type,
                    phoneNumber,
                    thirdPerson,
                    startedAt,
                    endedAt,
                    isActive,
                    alias,
                    id
                  }: PersonAuthenticationMethod) => {
                    const isDisabled =
                      isPersonInactive ||
                      // @ts-expect-error TS(2365): Operator '>' cannot be applied to types 'number' a... Remove this comment to see the full error message
                      (endedAt && Date.now() > new Date(endedAt));
                    return {
                      type: type ? (
                        <DictionaryValue
                          name="AUTHENTICATION_METHOD"
                          item={type}
                        />
                      ) : (
                        "-"
                      ),
                      phoneNumber: phoneNumber ? phoneNumber : "-",
                      thirdPerson: thirdPerson ? (
                        <Link
                          to={`/persons/${thirdPerson.id}`}
                          fontWeight="bold"
                        >
                          <Trans>Show details</Trans>
                        </Link>
                      ) : (
                        "-"
                      ),
                      ownAuthMethodThirdPerson: thirdPerson ? (
                        <ThirdPersonOwnMethod thirdPerson={thirdPerson} />
                      ) : (
                        "-"
                      ),
                      alias: alias,
                      startedAt: startedAt
                        ? dateFormatter(
                            i18n.locale,
                            DATE_TIME_FORMAT,
                            startedAt
                          )
                        : "-",
                      endedAt: endedAt
                        ? dateFormatter(i18n.locale, DATE_TIME_FORMAT, endedAt)
                        : "-",
                      isActive: (
                        <Badge
                          type="ACTIVE_INACTIVE_M"
                          name={isActive ? "ACTIVE" : "INACTIVE"}
                          display="block"
                        />
                      ),
                      ...(personAuthMethodRequestAbility && {
                        action: (
                          <Flex flexDirection="column">
                            <Box>
                              <Button
                                disabled={isDisabled}
                                variant={isDisabled ? "linkDisabled" : "link"}
                                alignItems="start"
                                px="0"
                                py="0"
                                type="reset"
                                onClick={() => {
                                  const thirdPersonPhoneNumber =
                                    handleDetectThirdPersonPhoneNumber(
                                      thirdPerson
                                    );
                                  setLocationParams({
                                    ...locationParams,
                                    id,
                                    ...(alias && { alias }),
                                    type,
                                    ...(phoneNumber && { phoneNumber }),
                                    ...(!phoneNumber &&
                                      thirdPerson &&
                                      thirdPersonPhoneNumber && {
                                        phoneNumber: thirdPersonPhoneNumber
                                      }),
                                    ...(thirdPerson && {
                                      thirdPersonId: thirdPerson.databaseId
                                    }),
                                    ...(thirdPerson && {
                                      thirdPersonFullName:
                                        getFullName(thirdPerson)
                                    })
                                  });
                                  setVisibilityDeactivateAuthModal(true);
                                }}
                              >
                                <Trans>Deactivate</Trans>
                              </Button>
                            </Box>
                            <Box mt={2}>
                              <Button
                                disabled={isDisabled}
                                variant={isDisabled ? "linkDisabled" : "link"}
                                alignItems="start"
                                px="0"
                                py="0"
                                type="reset"
                                onClick={() => {
                                  const thirdPersonPhoneNumber =
                                    handleDetectThirdPersonPhoneNumber(
                                      thirdPerson
                                    );
                                  setLocationParams({
                                    ...locationParams,
                                    id,
                                    ...(alias && { alias }),
                                    type,
                                    ...(phoneNumber && { phoneNumber }),
                                    ...(!phoneNumber &&
                                      thirdPerson &&
                                      thirdPersonPhoneNumber && {
                                        phoneNumber: thirdPersonPhoneNumber
                                      }),
                                    ...(thirdPerson && {
                                      thirdPersonId: thirdPerson.databaseId
                                    }),
                                    ...(thirdPerson && {
                                      thirdPersonFullName:
                                        getFullName(thirdPerson)
                                    })
                                  });
                                  setVisibilityEditAuthModal(true);
                                }}
                              >
                                <Trans>Edit</Trans>
                              </Button>
                            </Box>
                          </Flex>
                        )
                      })
                    };
                  }}
                />
                {isVisibleDeactivateAuthModal && (
                  <DeactivateAuthMethod
                    onClose={toggleVisibilityDeactivateAuthModal}
                    personDatabaseId={databaseId}
                    personId={personId}
                  />
                )}
                {isVisibleEditAuthModal && (
                  <EditAuthMethod
                    onClose={toggleVisibilityEditAuthModal}
                    personDatabaseId={databaseId}
                    personId={personId}
                  />
                )}
              </Box>
            )}
          </>
        );
      }}
    </LocationParams>
  );
};

export default AuthInfo;

const AuthInfoMethodsFilter = () => {
  const { i18n } = useLingui();

  return (
    <Flex mx={-1}>
      <Box px={1}>
        <Field.Select
          name="filter.isEnded"
          label={<Trans id="Active auth methods" />}
          placeholder={i18n._(t`Select option`)}
          items={Object.keys(FILTER_IS_ENDED_OPTIONS)}
          itemToString={(item: boolean) =>
            // @ts-expect-error statuses boolean key
            FILTER_IS_ENDED_OPTIONS[item]
          }
          variant="select"
        />
      </Box>
    </Flex>
  );
};

type ThirdPersonOwnMethodProps = {
  thirdPerson: PersonAuthenticationMethod["thirdPerson"];
};

export const ThirdPersonOwnMethod = ({
  thirdPerson
}: ThirdPersonOwnMethodProps) => {
  if (
    isEmpty(thirdPerson) ||
    isEmpty(thirdPerson.authenticationMethods) ||
    isEmpty(thirdPerson.authenticationMethods.nodes)
  ) {
    return null;
  }

  const [{ type, phoneNumber }] = thirdPerson.authenticationMethods
    .nodes as PersonAuthenticationMethod[];

  if (!PERSONS_OWN_METHODS.includes(type)) {
    return <>-</>;
  }
  return (
    <>
      <DictionaryValue name="AUTHENTICATION_METHOD" item={type} />
      {phoneNumber && <> {phoneNumber}</>}
    </>
  );
};

AuthInfo.fragments = {
  entry: gql`
    fragment AuthMethods on Person {
      authenticationMethods(
        filter: $filter
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        nodes {
          id
          alias
          type
          startedAt
          endedAt
          phoneNumber
          isActive
          thirdPerson {
            databaseId
            firstName
            lastName
            secondName
            id
            authenticationMethods(
              filter: { type: ["OTP", "OFFLINE", "NA"] }
              first: 50
            ) {
              nodes {
                id
                type
                phoneNumber
              }
            }
          }
        }
      }
    }
  `
};

type DeactivateAuthMethodProps = {
  onClose: () => void;
  personDatabaseId: string;
  personId?: string;
};

const DeactivateAuthMethod = ({
  onClose,
  personDatabaseId,
  personId
}: DeactivateAuthMethodProps) => (
  <LocationParams>
    {({
      locationParams,
      setLocationParams
    }: {
      locationParams: SearchParams;
      setLocationParams: SetSearchParams;
    }) => {
      const handleCloseModal = () => {
        setLocationParams(
          clearLocationParams(locationParams, LOCATION_PARAMS_LIST)
        );
        onClose();
      };

      const {
        id,
        type,
        alias,
        thirdPersonId,
        phoneNumber,
        thirdPersonFullName
      } = locationParams;

      const searchVariables = locationParams.filter
        ? filteredLocationParams(locationParams)
        : {
            ...filteredLocationParams(locationParams),
            ...AUTH_METHODS_FILTER_DEFAULTS
          };

      const { filter, ...restSearchVariables } = searchVariables;

      const personQueryVariables = {
        id: personId,
        ...restSearchVariables,
        filter: clearFilterParamsPersonQuery(filter)
      };

      return (
        <Modal backdrop width={760}>
          <Heading as="h1" fontWeight="normal" mb={6}>
            <Trans>Deactivate authentication method</Trans>
          </Heading>
          <Text lineHeight={2} textAlign="center" mb={6}>
            <Trans>
              Warning!
              <br />
              After confirmation, appropriate authentication method will be
              deactivated.
            </Trans>
          </Text>
          <Flex flexDirection="column" ml={10}>
            <Flex>
              <Text
                fontSize={1}
                fontWeight="normal"
                width={250}
                textAlign="left"
              >
                <Trans>Authentication method type</Trans>
              </Text>
              <Text fontSize={1}>
                <DictionaryValue name="AUTHENTICATION_METHOD" item={type} />
              </Text>
            </Flex>
            <Flex mt={3}>
              <Text
                fontSize={1}
                fontWeight="normal"
                width={250}
                textAlign="left"
              >
                <Trans>Name</Trans>
              </Text>
              <Text fontSize={1}>{alias}</Text>
            </Flex>
            {phoneNumber && (
              <Flex mt={3}>
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  width={250}
                  textAlign="left"
                >
                  <Trans>Phone number</Trans>
                </Text>
                <Text fontSize={1}>{phoneNumber}</Text>
              </Flex>
            )}
            {thirdPersonId && (
              <Flex mt={3}>
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  width={250}
                  textAlign="left"
                >
                  <Trans>Third person ID</Trans>
                </Text>
                <Text fontSize={1}>{thirdPersonId}</Text>
              </Flex>
            )}
            {thirdPersonFullName && (
              <Flex mt={3}>
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  width={250}
                  textAlign="left"
                >
                  <Trans>Third person full name</Trans>
                </Text>
                <Text fontSize={1}>{thirdPersonFullName}</Text>
              </Flex>
            )}
          </Flex>
          <Close onClick={handleCloseModal} />

          <Mutation
            mutation={DeactivateAuthMethodMutation}
            refetchQueries={() => [
              {
                query: PersonQuery,
                variables: personQueryVariables
              }
            ]}
          >
            {(deactivateAuthMethod: MutationFunction) => (
              <Form
                onSubmit={async () => {
                  try {
                    const { data } = await deactivateAuthMethod({
                      variables: {
                        input: {
                          id,
                          personId: personDatabaseId
                        }
                      }
                    });
                    if (
                      data &&
                      data.deactivateAuthMethod &&
                      data.deactivateAuthMethod.authenticationMethod &&
                      !isEmpty(data.deactivateAuthMethod.authenticationMethod)
                    ) {
                      onClose();
                    }
                  } catch (error) {
                    return { [SUBMIT_ERROR]: defaultError };
                  }
                }}
              >
                <Flex justifyContent="center" mt={5}>
                  <Box mx={2}>
                    <Button variant="blue" onClick={handleCloseModal}>
                      <Trans>Return</Trans>
                    </Button>
                  </Box>
                  <Box mx={2}>
                    <Button variant="green">
                      <Trans>Deactivate Authentication Method</Trans>
                    </Button>
                  </Box>
                </Flex>
                <Flex justifyContent="center">
                  <Box>
                    <Form.Error
                      default={i18n._(
                        t`Something went wrong. Please try again later`
                      )}
                    />
                  </Box>
                </Flex>
              </Form>
            )}
          </Mutation>
        </Modal>
      );
    }}
  </LocationParams>
);

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

const DeactivateAuthMethodMutation = gql`
  mutation DeactivateAuthMethod($input: DeactivateAuthMethodInput!) {
    deactivateAuthMethod(input: $input) {
      authenticationMethod {
        id
        type
        databaseId
        isActive
        insertedAt
        updatedAt
        endedAt
        startedAt
      }
    }
  }
`;

type EditAuthMethodProps = {
  onClose: () => void;
  personDatabaseId: string;
  personId?: string;
};

const EditAuthMethod = ({
  onClose,
  personDatabaseId,
  personId
}: EditAuthMethodProps) => (
  <LocationParams>
    {({
      locationParams,
      setLocationParams
    }: {
      locationParams: SearchParams;
      setLocationParams: SetSearchParams;
    }) => {
      const {
        id,
        type,
        alias,
        thirdPersonId,
        phoneNumber,
        thirdPersonFullName
      } = locationParams;

      const handleCloseModal = () => {
        setLocationParams(
          clearLocationParams(locationParams, LOCATION_PARAMS_LIST)
        );
        onClose();
      };

      const searchVariables = locationParams.filter
        ? filteredLocationParams(locationParams)
        : {
            ...filteredLocationParams(locationParams),
            ...AUTH_METHODS_FILTER_DEFAULTS
          };

      const { filter, ...restSearchVariables } = searchVariables;

      const personQueryVariables = {
        id: personId,
        ...restSearchVariables,
        filter: clearFilterParamsPersonQuery(filter)
      };

      return (
        <Modal backdrop width={760}>
          <Heading as="h1" fontWeight="normal" mb={6}>
            <Trans>Edit authentication method</Trans>
          </Heading>
          <Close onClick={handleCloseModal} />
          <Flex flexDirection="column">
            <Flex>
              <Text
                fontSize={1}
                fontWeight="normal"
                width={250}
                textAlign="left"
              >
                <Trans>Authentication method type</Trans>
              </Text>
              <Text fontSize={1}>
                <DictionaryValue name="AUTHENTICATION_METHOD" item={type} />
              </Text>
            </Flex>
            <Flex mt={3}>
              <Text
                fontSize={1}
                fontWeight="normal"
                width={250}
                textAlign="left"
              >
                <Trans>Name</Trans>
              </Text>
              <Text fontSize={1}>{alias}</Text>
            </Flex>
            {phoneNumber && (
              <Flex mt={3}>
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  width={250}
                  textAlign="left"
                >
                  <Trans>Phone number</Trans>
                </Text>
                <Text fontSize={1}>{phoneNumber}</Text>
              </Flex>
            )}
            {thirdPersonId && (
              <Flex mt={3}>
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  width={250}
                  textAlign="left"
                >
                  <Trans>Third person ID</Trans>
                </Text>
                <Text fontSize={1}>{thirdPersonId}</Text>
              </Flex>
            )}
            {thirdPersonFullName && (
              <Flex mt={3}>
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  width={250}
                  textAlign="left"
                >
                  <Trans>Third person full name</Trans>
                </Text>
                <Text fontSize={1}>{thirdPersonFullName}</Text>
              </Flex>
            )}
          </Flex>
          <Line />
          <Mutation
            mutation={UpdateAuthMethod}
            refetchQueries={() => [
              {
                query: PersonQuery,
                variables: personQueryVariables
              }
            ]}
          >
            {(updateAuthMethod: MutationFunction) => (
              <Form
                initialValues={{ alias }}
                onSubmit={async ({ alias }: { alias: string }) => {
                  try {
                    const { data } = await updateAuthMethod({
                      variables: {
                        input: {
                          id,
                          personId: personDatabaseId,
                          ...(!isEmpty(alias) ? { alias } : { alias: "" })
                        }
                      }
                    });

                    if (
                      data &&
                      data.updateAuthMethod &&
                      data.updateAuthMethod.authenticationMethod &&
                      !isEmpty(data.updateAuthMethod.authenticationMethod)
                    ) {
                      onClose();
                    }
                  } catch (error) {
                    return { [SUBMIT_ERROR]: defaultError };
                  }
                }}
              >
                <Box width={3 / 4} mt={6}>
                  <Trans
                    id="Enter authentication method name"
                    render={({ translation }) => (
                      <Field.Text
                        name="alias"
                        label={
                          <Text textAlign="left">
                            <Trans id="Name of authentication method" />
                          </Text>
                        }
                        placeholder={translation}
                        maxLength={50}
                        showLengthHint
                      />
                    )}
                  />
                  {type === "THIRD_PERSON" && (
                    <Trans
                      id="Required field"
                      render={() => (
                        <Validation.Required
                          field="alias"
                          message="Required field"
                        />
                      )}
                    />
                  )}
                </Box>
                <Flex justifyContent="center" mt={5}>
                  <Box mx={2}>
                    <Button variant="blue" onClick={handleCloseModal}>
                      <Trans>Return</Trans>
                    </Button>
                  </Box>
                  <Box mx={2}>
                    <Button variant="green">
                      <Trans>Update Authentication Method</Trans>
                    </Button>
                  </Box>
                </Flex>
                <Flex justifyContent="center">
                  <Box>
                    <Form.Error
                      default={i18n._(
                        t`Something went wrong. Please try again later`
                      )}
                    />
                  </Box>
                </Flex>
              </Form>
            )}
          </Mutation>
        </Modal>
      );
    }}
  </LocationParams>
);

const UpdateAuthMethod = gql`
  mutation UpdateAuthMethod($input: UpdateAuthMethodInput!) {
    updateAuthMethod(input: $input) {
      authenticationMethod {
        id
        alias
        type
        databaseId
        isActive
        insertedAt
        updatedAt
        endedAt
        startedAt
      }
    }
  }
`;

const LOCATION_PARAMS_LIST = [
  "id",
  "type",
  "alias",
  "thirdPerson",
  "phoneNumber",
  "thirdPersonId",
  "thirdPersonFullName"
];

const clearLocationParams = (
  locationParams: SearchParams,
  paramsToRemove: string[]
) =>
  Object.fromEntries(
    Object.entries(locationParams).filter(
      ([key, value]) => !paramsToRemove.includes(key)
    )
  );

const handleDetectThirdPersonPhoneNumber = (
  thirdPerson: PersonAuthenticationMethod["thirdPerson"]
) => {
  if (
    isEmpty(thirdPerson) ||
    isEmpty(thirdPerson.authenticationMethods) ||
    isEmpty(thirdPerson.authenticationMethods.nodes)
  ) {
    return null;
  }

  const personMethod = thirdPerson!.authenticationMethods!.nodes!.find(
    (authenticationMethods) => {
      return (
        PERSONS_OWN_METHODS.includes(authenticationMethods!.type) &&
        authenticationMethods!.phoneNumber
      );
    }
  );

  return personMethod && personMethod.phoneNumber;
};

export const clearFilterParamsPersonQuery = (filter: any) =>
  Object.fromEntries(
    Object.entries(filter).filter(([key, value]) => {
      return ![
        "databaseId",
        "taxId",
        "identity",
        "personal",
        "status",
        "personId",
        "resourceType",
        "personDataHistoryType"
      ].includes(key);
    })
  );
