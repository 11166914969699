import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { DefaultImageIcon } from "@edenlabllc/ehealth-icons";
import {
  ContractDocument,
  GlobalBudgetContract,
  Maybe
} from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";

type DocumentsGlobalBudgetProps = RouteComponentProps & {
  attachedDocuments: GlobalBudgetContract["attachedDocuments"];
};

export const DocumentsGlobalBudget = ({
  attachedDocuments
}: DocumentsGlobalBudgetProps) => {
  if (isEmpty(attachedDocuments)) return <EmptyData />;

  return (
    <>
      {attachedDocuments.map((document: Maybe<ContractDocument>) => (
        <Box m="5">
          <SaveLink href={document && document.url} target="_blank">
            <Box mr={2} color="shiningKnight">
              <DefaultImageIcon />
            </Box>
            <Text color="rockmanBlue" lineHeight="1">
              <DictionaryValue
                name="CONTRACT_DOCUMENT"
                item={document && document.type}
              />
            </Text>
          </SaveLink>
        </Box>
      ))}
    </>
  );
};

export default DocumentsGlobalBudget;

DocumentsGlobalBudget.fragments = {
  entry: gql`
    fragment DocumentsGlobalBudget on GlobalBudgetContract {
      attachedDocuments {
        type
        url
      }
    }
  `
};

const SaveLink = system(
  {
    is: "a"
  },
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    lineHeight: 0,
    textDecoration: "none"
  }
);
