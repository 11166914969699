import React from "react";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { CapitationContractRequest, Maybe } from "@edenlabllc/graphql-schema";

import Badge from "../../../../components/Badge";
import FullName from "../../../../components/FullName";
import Link from "../../../../components/Link";
import Table, { SortingParams } from "../../../../components/Table";
import {
  SearchParams,
  SetSearchParams
} from "../../../../components/SearchForm";
import dateFormatter from "../../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../../constants/dateFormats";

type ContractTableProps = {
  capitationContractRequests: Maybe<Maybe<CapitationContractRequest>[]>;
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const ContractTable = ({
  capitationContractRequests,
  locationParams,
  setLocationParams
}: ContractTableProps) => (
  <Table
    data={capitationContractRequests}
    header={{
      databaseId: <Trans>Contract request databaseID</Trans>,
      contractNumber: <Trans>Contract Number</Trans>,
      edrpou: <Trans>EDRPOU</Trans>,
      contractorLegalEntityName: <Trans>Name of medical institution</Trans>,
      assigneeName: <Trans>Performer</Trans>,
      status: <Trans>Status</Trans>,
      startDate: <Trans>The contract is valid with</Trans>,
      endDate: <Trans>The contract is valid for</Trans>,
      insertedAt: <Trans>Added</Trans>,
      details: <Trans>Details</Trans>
    }}
    renderRow={({
      id,
      status,
      startDate,
      endDate,
      contractorLegalEntity: { edrpou, edrData },
      assignee,
      insertedAt,
      ...capitationContractRequests
    }: CapitationContractRequest) => ({
      ...capitationContractRequests,
      edrpou,
      contractorLegalEntityName: edrData && edrData.name,
      startDate: i18n.date(startDate),
      endDate: i18n.date(endDate),
      insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
      assigneeName: assignee ? <FullName party={assignee.party} /> : undefined,
      status: <Badge type="CONTRACT_REQUEST" name={status} display="block" />,
      details: (
        <Link to={`./${id}`} fontWeight="bold">
          <Trans>Show details</Trans>
        </Link>
      )
    })}
    sortableFields={["status", "startDate", "endDate", "insertedAt"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    tableName="capitationContractRequests/search"
    whiteSpaceNoWrap={["databaseId"]}
  />
);

ContractTable.fragments = {
  entry: gql`
    fragment CapitationContractRequests on CapitationContractRequest {
      id
      databaseId
      contractNumber
      startDate
      endDate
      status
      assignee {
        id
        party {
          id
          ...FullName
        }
      }
      insertedAt
      contractorLegalEntity {
        id
        name
        edrpou
        databaseId
        edrData {
          name
        }
      }
      contractorOwner {
        id
      }
    }
    ${FullName.fragments.entry}
  `
};

export default ContractTable;
