import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { loader } from "graphql.macro";
import { get, cloneDeep, uniq } from "lodash";

import { Dictionary } from "@edenlabllc/graphql-schema";

import { convertDictionaryValuesArrayToObject } from "../DictionaryValue";

const DictionaryQuery = loader("../../graphql/SearchDictionariesQuery.graphql");

export type ListValue = {
  title: string;
  value: string;
};

export type ListValues = ListValue[];

export const useGetFields = () => {
  const { data: ruleEngineFieldSet } = useQuery(RULE_ENGINE_FIELD_SET_QUERY, {
    fetchPolicy: "cache-first"
  });

  const data = ruleEngineFieldSet && cloneDeep(ruleEngineFieldSet);

  const conditionsSubs = data && data.ruleEngineFieldSet.conditions.subfields;
  const diagnosticReportsSubs =
    data && data.ruleEngineFieldSet.diagnostic_reports.subfields;
  const encountersSubs = data && data.ruleEngineFieldSet.encounters.subfields;
  const episodesSubs = data && data.ruleEngineFieldSet.episodes.subfields;
  const medicationDispensesSubs =
    data && data.ruleEngineFieldSet.medication_dispenses.subfields;
  const observationsSubs =
    data && data.ruleEngineFieldSet.observations.subfields;
  const patientsSubs = data && data.ruleEngineFieldSet.patient.subfields;
  const proceduresSubs = data && data.ruleEngineFieldSet.procedures.subfields;

  /* start dictionaries block */
  const codeDictionaryPath = "subfields.code.fieldSettings.dictionaries";
  const codingDictionaryPath = `subfields.coding.${codeDictionaryPath}`;
  const identifierTypePath = `subfields.identifier.subfields.type.${codingDictionaryPath}`;

  // conditions dictionaries
  const conditionsBodySitesDictionaries = conditionsSubs
    ? get(conditionsSubs.body_sites, codingDictionaryPath)
    : [];
  const conditionsClinicalStatusDictionaries = conditionsSubs
    ? conditionsSubs.clinical_status.fieldSettings.dictionaries
    : [];
  const conditionsCodeDictionaries = conditionsSubs
    ? get(conditionsSubs.code, codingDictionaryPath)
    : [];
  const conditionsSeverityDictionaries = conditionsSubs
    ? get(conditionsSubs.severity, codingDictionaryPath)
    : [];
  const conditionsVerificationStatusDictionaries = conditionsSubs
    ? conditionsSubs.verification_status.fieldSettings.dictionaries
    : [];

  // diagnostic_reports dictionaries
  const diagnosticReportsCategoryDictionaries = diagnosticReportsSubs
    ? get(diagnosticReportsSubs.category, codingDictionaryPath)
    : [];
  const diagnosticReportsCodeDictionaries = diagnosticReportsSubs
    ? get(diagnosticReportsSubs.code, identifierTypePath)
    : [];
  const diagnosticReportsConclusionCodeDictionaries = diagnosticReportsSubs
    ? get(diagnosticReportsSubs.conclusion_code, codingDictionaryPath)
    : [];

  // encounters dictionaries
  const encountersActionsDictionaries = encountersSubs
    ? get(encountersSubs.actions, codingDictionaryPath)
    : [];
  const encountersClassDictionaries = encountersSubs
    ? get(encountersSubs.class, codeDictionaryPath)
    : [];
  const encountersHospitalizationDischargeDepartmentDictionaries =
    encountersSubs
      ? get(
          encountersSubs.hospitalization.subfields.discharge_department,
          codingDictionaryPath
        )
      : [];
  const encountersHospitalizationReAdmissionDictionaries = encountersSubs
    ? get(
        encountersSubs.hospitalization.subfields.re_admission,
        codingDictionaryPath
      )
    : [];
  const encountersReasonsDictionaries = encountersSubs
    ? get(encountersSubs.reasons, codingDictionaryPath)
    : [];
  const encountersTypeDictionaries = encountersSubs
    ? get(encountersSubs.type, codingDictionaryPath)
    : [];
  const encountersDiagnosesCodeDictionaries = encountersSubs
    ? get(encountersSubs.diagnoses.subfields.code, codingDictionaryPath)
    : [];
  const encountersDiagnosesRoleDictionaries = encountersSubs
    ? get(encountersSubs.diagnoses.subfields.role, codingDictionaryPath)
    : [];

  // episodes dictionaries
  const episodesDiagnosesCodeDictionaries = episodesSubs
    ? get(episodesSubs.current_diagnoses.subfields.code, codingDictionaryPath)
    : [];
  const episodesTypeDictionaries = episodesSubs
    ? get(episodesSubs.type, codeDictionaryPath)
    : [];
  const episodesDiagnosesRoleDictionaries = episodesSubs
    ? get(episodesSubs.current_diagnoses.subfields.role, codingDictionaryPath)
    : [];

  // medication dispenses
  const medicationDispensesStatusDictionaries = medicationDispensesSubs
    ? medicationDispensesSubs.status.fieldSettings.dictionaries
    : [];

  // observations dictionaries
  const observationsBodySiteDictionaries = observationsSubs
    ? get(observationsSubs.body_site, codingDictionaryPath)
    : [];
  const observationsCategoriesDictionaries = observationsSubs
    ? get(observationsSubs.categories, codingDictionaryPath)
    : [];
  const observationsCodeDictionaries = observationsSubs
    ? get(observationsSubs.code, codingDictionaryPath)
    : [];
  const observationsInterpretationDictionaries = observationsSubs
    ? get(observationsSubs.interpretation, codingDictionaryPath)
    : [];
  const observationsMethodDictionaries = observationsSubs
    ? get(observationsSubs.method, codingDictionaryPath)
    : [];
  const observationsStatusDictionaries = observationsSubs
    ? observationsSubs.status.fieldSettings.dictionaries
    : [];

  // patients dictionaries
  const patientsGenderDictionaries = patientsSubs
    ? patientsSubs.gender.fieldSettings.dictionaries
    : [];

  // procedures dictionaries
  const proceduresCategoryDictionaries = proceduresSubs
    ? get(proceduresSubs.category, codingDictionaryPath)
    : [];
  const proceduresCodeDictionaries = proceduresSubs
    ? get(proceduresSubs.code, identifierTypePath)
    : [];
  const proceduresOutcomeDictionaries = proceduresSubs
    ? get(proceduresSubs.outcome, codingDictionaryPath)
    : [];

  const dictionaryNames = [
    // conditions
    ...conditionsBodySitesDictionaries,
    ...conditionsClinicalStatusDictionaries,
    ...conditionsCodeDictionaries,
    ...conditionsSeverityDictionaries,
    ...conditionsVerificationStatusDictionaries,

    // diagnostic_reports
    ...diagnosticReportsCategoryDictionaries,
    ...diagnosticReportsCodeDictionaries,
    ...diagnosticReportsConclusionCodeDictionaries,

    // encounters
    ...encountersActionsDictionaries,
    ...encountersClassDictionaries,
    ...encountersHospitalizationDischargeDepartmentDictionaries,
    ...encountersHospitalizationReAdmissionDictionaries,
    ...encountersReasonsDictionaries,
    ...encountersTypeDictionaries,
    ...encountersDiagnosesCodeDictionaries,
    ...encountersDiagnosesRoleDictionaries,

    // episodes
    ...episodesDiagnosesCodeDictionaries,
    ...episodesTypeDictionaries,
    ...episodesDiagnosesRoleDictionaries,

    // medication dispenses
    ...medicationDispensesStatusDictionaries,

    // observations
    ...observationsBodySiteDictionaries,
    ...observationsCategoriesDictionaries,
    ...observationsCodeDictionaries,
    ...observationsInterpretationDictionaries,
    ...observationsMethodDictionaries,
    ...observationsStatusDictionaries,

    // patients
    ...patientsGenderDictionaries,

    // procedures
    ...proceduresCategoryDictionaries,
    ...proceduresCodeDictionaries,
    ...proceduresOutcomeDictionaries
  ];

  const { data: allDictionariesData } = useQuery(DictionaryQuery, {
    fetchPolicy: "cache-first",
    variables: {
      first: 30,
      filter: {
        name: uniq(dictionaryNames).join(",")
      }
    },
    skip: !dictionaryNames.length
  });

  if (!(allDictionariesData && allDictionariesData.dictionaries))
    return undefined;

  const allDictionaries =
    allDictionariesData &&
    allDictionariesData.dictionaries &&
    allDictionariesData.dictionaries.nodes;

  let dictionaries: any = {};
  allDictionaries &&
    allDictionaries.forEach((dictionary: Dictionary) => {
      if (dictionaryNames.includes(dictionary.name)) {
        if (!dictionary.name) return;
        const allValuesJson = convertDictionaryValuesArrayToObject(
          dictionary.allValues
        );

        dictionaries = {
          ...dictionaries,
          [dictionary.name]: allValuesJson
        };
      }
    });

  const searchDictionary = (availableDictionaries = [], searchValue = "") => {
    const res: ListValues = [];
    searchValue = searchValue.toLowerCase();
    Object.keys(dictionaries).forEach((dictionaryName) => {
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      if (availableDictionaries.includes(dictionaryName)) {
        for (const key in dictionaries[dictionaryName]) {
          if (
            key.toLowerCase().includes(searchValue) ||
            dictionaries[dictionaryName][key]
              .toLowerCase()
              .includes(searchValue)
          ) {
            res.push({
              value: key,
              title: dictionaries[dictionaryName][key]
            });
          }
        }
      }
    });

    return res;
  };

  const dictionaryToListValues = (name: string) => {
    return Object.keys(dictionaries[name]).map((key) => {
      return {
        title: dictionaries[name][key],
        value: key
      };
    });
  };

  const getListValues = (dictionaryNames = []) => {
    let listValues: ListValues = [];
    dictionaryNames.forEach((name) => {
      listValues = [...listValues, ...dictionaryToListValues(name)];
    });
    return listValues;
  };
  /* end dictionaries block */

  /* start medical programs block */
  const medicalPrograms =
    medicationDispensesSubs.medical_program_id.fieldSettings.listValues;

  const searchMedicalPrograms = (searchValue = "") => {
    searchValue = searchValue.toLowerCase();
    return medicalPrograms.filter(
      (medicalProgram: ListValue) =>
        medicalProgram.title.toLowerCase().includes(searchValue) ||
        medicalProgram.value.toLowerCase().includes(searchValue)
    );
  };
  /* end medical programs block */

  /* start services block */
  // same list in procedures
  const services =
    diagnosticReportsSubs.code.subfields.identifier.subfields.value
      .fieldSettings.listValues;

  const searchServices = (searchValue = "") => {
    searchValue = searchValue.toLowerCase();
    return services.filter(
      (service: ListValue) =>
        service.title.toLowerCase().includes(searchValue) ||
        service.value.toLowerCase().includes(searchValue)
    );
  };
  /* end services block */

  // conditions
  conditionsSubs.body_sites.subfields.coding.subfields.code.fieldSettings = {
    ...conditionsSubs.body_sites.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(conditionsBodySitesDictionaries)
  };
  conditionsSubs.clinical_status.fieldSettings = {
    ...conditionsSubs.clinical_status.fieldSettings,
    listValues: getListValues(conditionsClinicalStatusDictionaries)
  };
  conditionsSubs.code.subfields.coding.subfields.code.fieldSettings = {
    ...conditionsSubs.code.subfields.coding.subfields.code.fieldSettings,
    asyncFetch: (searchValue: string) =>
      searchDictionary(conditionsCodeDictionaries, searchValue),
    viewKeysInsteadTitles: true
  };
  conditionsSubs.severity.subfields.coding.subfields.code.fieldSettings = {
    ...conditionsSubs.severity.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(conditionsSeverityDictionaries)
  };
  conditionsSubs.verification_status.fieldSettings = {
    ...conditionsSubs.verification_status.fieldSettings,
    listValues: getListValues(conditionsVerificationStatusDictionaries)
  };

  // diagnostic_reports
  diagnosticReportsSubs.category.subfields.coding.subfields.code.fieldSettings =
    {
      ...diagnosticReportsSubs.category.subfields.coding.subfields.code
        .fieldSettings,
      listValues: getListValues(diagnosticReportsCategoryDictionaries)
    };
  diagnosticReportsSubs.code.subfields.identifier.subfields.type.subfields.coding.subfields.code.fieldSettings =
    {
      ...diagnosticReportsSubs.code.subfields.identifier.subfields.type
        .subfields.coding.subfields.code.fieldSettings,
      asyncFetch: (searchValue: string) =>
        searchDictionary(diagnosticReportsCodeDictionaries, searchValue)
    };
  diagnosticReportsSubs.conclusion_code.subfields.coding.subfields.code.fieldSettings =
    {
      ...diagnosticReportsSubs.conclusion_code.subfields.coding.subfields.code
        .fieldSettings,
      asyncFetch: (searchValue: string) =>
        searchDictionary(
          diagnosticReportsConclusionCodeDictionaries,
          searchValue
        )
    };
  diagnosticReportsSubs.code.subfields.identifier.subfields.value.fieldSettings =
    {
      asyncFetch: (searchValue: string) => searchServices(searchValue)
    };

  // encounters
  encountersSubs.actions.subfields.coding.subfields.code.fieldSettings = {
    ...encountersSubs.actions.subfields.coding.subfields.code.fieldSettings,
    asyncFetch: (searchValue: string) =>
      searchDictionary(encountersActionsDictionaries, searchValue)
  };
  encountersSubs.class.subfields.code.fieldSettings = {
    ...encountersSubs.class.subfields.code.fieldSettings,
    listValues: getListValues(encountersClassDictionaries)
  };
  encountersSubs.hospitalization.subfields.discharge_department.subfields.coding.subfields.code.fieldSettings =
    {
      ...encountersSubs.hospitalization.subfields.discharge_department.subfields
        .coding.subfields.code.fieldSettings,
      listValues: getListValues(
        encountersHospitalizationDischargeDepartmentDictionaries
      )
    };
  encountersSubs.hospitalization.subfields.re_admission.subfields.coding.subfields.code.fieldSettings =
    {
      ...encountersSubs.hospitalization.subfields.re_admission.subfields.coding
        .subfields.code.fieldSettings,
      listValues: getListValues(
        encountersHospitalizationReAdmissionDictionaries
      )
    };
  encountersSubs.reasons.subfields.coding.subfields.code.fieldSettings = {
    ...encountersSubs.reasons.subfields.coding.subfields.code.fieldSettings,
    asyncFetch: (searchValue: string) =>
      searchDictionary(encountersReasonsDictionaries, searchValue)
  };
  encountersSubs.type.subfields.coding.subfields.code.fieldSettings = {
    ...encountersSubs.type.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(encountersTypeDictionaries)
  };
  encountersSubs.diagnoses.subfields.code.subfields.coding.subfields.code.fieldSettings =
    {
      ...encountersSubs.diagnoses.subfields.code.subfields.coding.subfields.code
        .fieldSettings,
      asyncFetch: (searchValue: string) =>
        searchDictionary(encountersDiagnosesCodeDictionaries, searchValue),
      viewKeysInsteadTitles: true
    };
  encountersSubs.diagnoses.subfields.role.subfields.coding.subfields.code.fieldSettings =
    {
      ...encountersSubs.diagnoses.subfields.role.subfields.coding.subfields.code
        .fieldSettings,
      listValues: getListValues(encountersDiagnosesRoleDictionaries)
    };

  // episodes
  episodesSubs.current_diagnoses.subfields.code.subfields.coding.subfields.code.fieldSettings =
    {
      ...episodesSubs.current_diagnoses.subfields.code.subfields.coding
        .subfields.code.fieldSettings,
      asyncFetch: (searchValue: string) =>
        searchDictionary(episodesDiagnosesCodeDictionaries, searchValue),
      viewKeysInsteadTitles: true
    };
  episodesSubs.type.subfields.code.fieldSettings = {
    ...episodesSubs.type.subfields.code.fieldSettings,
    listValues: getListValues(episodesTypeDictionaries)
  };
  episodesSubs.current_diagnoses.subfields.role.subfields.coding.subfields.code.fieldSettings =
    {
      ...episodesSubs.current_diagnoses.subfields.role.subfields.coding
        .subfields.code.fieldSettings,
      listValues: getListValues(episodesDiagnosesRoleDictionaries)
    };

  // medication dispenses
  medicationDispensesSubs.status.fieldSettings = {
    ...medicationDispensesSubs.status.fieldSettings,
    listValues: getListValues(medicationDispensesStatusDictionaries)
  };
  medicationDispensesSubs.medical_program_id.fieldSettings = {
    asyncFetch: (searchValue: string) => searchMedicalPrograms(searchValue)
  };

  // observations
  observationsSubs.body_site.subfields.coding.subfields.code.fieldSettings = {
    ...observationsSubs.body_site.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(observationsBodySiteDictionaries)
  };
  observationsSubs.categories.subfields.coding.subfields.code.fieldSettings = {
    ...observationsSubs.categories.subfields.coding.subfields.code
      .fieldSettings,
    listValues: getListValues(observationsCategoriesDictionaries)
  };
  observationsSubs.code.subfields.coding.subfields.code.fieldSettings = {
    ...observationsSubs.code.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(observationsCodeDictionaries)
  };
  observationsSubs.interpretation.subfields.coding.subfields.code.fieldSettings =
    {
      ...observationsSubs.interpretation.subfields.coding.subfields.code
        .fieldSettings,
      listValues: getListValues(observationsInterpretationDictionaries)
    };
  observationsSubs.method.subfields.coding.subfields.code.fieldSettings = {
    ...observationsSubs.method.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(observationsMethodDictionaries)
  };
  observationsSubs.status.fieldSettings = {
    ...observationsSubs.status.fieldSettings,
    listValues: getListValues(observationsStatusDictionaries)
  };

  // patients
  patientsSubs.gender.fieldSettings = {
    ...patientsSubs.gender.fieldSettings,
    listValues: getListValues(patientsGenderDictionaries)
  };

  // procedures
  proceduresSubs.category.subfields.coding.subfields.code.fieldSettings = {
    ...proceduresSubs.category.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(proceduresCategoryDictionaries)
  };
  proceduresSubs.code.subfields.identifier.subfields.type.subfields.coding.subfields.code.fieldSettings =
    {
      ...proceduresSubs.code.subfields.identifier.subfields.type.subfields
        .coding.subfields.code.fieldSettings,
      asyncFetch: (searchValue: string) =>
        searchDictionary(proceduresCodeDictionaries, searchValue)
    };
  proceduresSubs.outcome.subfields.coding.subfields.code.fieldSettings = {
    ...proceduresSubs.outcome.subfields.coding.subfields.code.fieldSettings,
    listValues: getListValues(proceduresOutcomeDictionaries)
  };
  proceduresSubs.code.subfields.identifier.subfields.value.fieldSettings = {
    asyncFetch: (searchValue: string) => searchServices(searchValue)
  };

  return data.ruleEngineFieldSet;
};

const RULE_ENGINE_FIELD_SET_QUERY = gql`
  query RuleEngineFieldSet {
    ruleEngineFieldSet
  }
`;
