import React from "react";
import { gql } from "graphql-tag";
import { useLingui } from "@lingui/react";
import isEmpty from "lodash/isEmpty";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";

import { PaperReferral as PaperReferralType } from "@edenlabllc/graphql-schema";

import ExpandableText from "../../../components/ExpandableText";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";
import { isValidDate } from "../../../helpers/dateHelpers";

type PaperReferralProps = {
  paperReferral: PaperReferralType;
  dateFormat: Intl.DateTimeFormatOptions;
};

export const PaperReferral = ({
  paperReferral,
  dateFormat
}: PaperReferralProps) => {
  const { note } = paperReferral;
  const { i18n } = useLingui();
  if (isEmpty(paperReferral)) return null;

  return (
    <Box mt={-4}>
      <Table
        hidePagination
        hideControls
        data={[paperReferral]}
        header={{
          requisition: <Trans>Requisition</Trans>,
          serviceRequestDate: <Trans>Date</Trans>,
          requesterLegalEntityName: <Trans>Legal entity name</Trans>,
          requesterLegalEntityEdrpou: <Trans>Legal entity edrpou</Trans>,
          requesterEmployeeName: <Trans>Employee name</Trans>,
          note: <Trans>Note</Trans>
        }}
        renderRow={({
          serviceRequestDate,
          ...restData
        }: PaperReferralType) => ({
          ...restData,
          serviceRequestDate: dateFormatter(
            i18n.locale,
            dateFormat,
            // @ts-expect-error maybe bug
            isValidDate(serviceRequestDate)
          ),
          ...(note && {
            note: <ExpandableText text={note} fixedAlign />
          })
        })}
      />
    </Box>
  );
};

PaperReferral.fragments = {
  entry: gql`
    fragment PaperReferral on PaperReferral {
      note
      requesterEmployeeName
      requesterLegalEntityEdrpou
      requesterLegalEntityName
      requisition
      serviceRequestDate
    }
  `
};
