import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import Composer from "react-composer";
import { Trans } from "@lingui/macro";
import { TransRenderProps } from "@lingui/react";
import { Box, Flex, Heading } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import {
  LocationParams,
  Form,
  Validation
} from "@edenlabllc/ehealth-components";

import Button from "../../../components/Button";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import * as Field from "../../../components/Field";
import Line from "../../../components/Line";

const LEGAL_ENTITY_TYPES_WITH_RESTRICTIONS_REORGANIZATION = [
  "EMERGENCY",
  "OUTPATIENT"
];

const ReorganizationData = ({
  // @ts-expect-error location state
  location: { state, pathname },
  navigate
}: RouteComponentProps) => {
  const isLEReorganizationRestrictionsPresent = useMemo(() => {
    return LEGAL_ENTITY_TYPES_WITH_RESTRICTIONS_REORGANIZATION.includes(
      state.reorganizedLegalEntities[0].type
    );
  }, []);

  useEffect(() => {
    if (
      LEGAL_ENTITY_TYPES_WITH_RESTRICTIONS_REORGANIZATION.includes(
        state.reorganizedLegalEntities[0].type
      )
    ) {
      navigate!(pathname, {
        state: { ...state, reorganizationType: "TRANSFORMATION" }
      });
    }
  }, []);

  return (
    <LocationParams state={state}>
      {() => {
        return (
          <>
            <Heading as="h1" fontWeight="normal" mb={4}>
              <Trans>Reorganized legal entity</Trans>
            </Heading>
            <DefinitionListView
              labels={{
                databaseId: <Trans>Legal entity ID</Trans>,
                name: <Trans>Name</Trans>,
                edrpou: <Trans>EDRPOU</Trans>
              }}
              data={{
                databaseId:
                  state.reorganizedLegalEntities &&
                  state.reorganizedLegalEntities[0].databaseId,
                name:
                  state.reorganizedLegalEntities &&
                  state.reorganizedLegalEntities[0].name,
                edrpou:
                  state.reorganizedLegalEntities &&
                  state.reorganizedLegalEntities[0].edrpou
              }}
              labelWidth="120px"
            />
            <Line />
            <Form
              onSubmit={async (data: {
                reorganizationType: string;
                reorganizationReason: string;
              }) => {
                if (data.reorganizationType === "TRANSFORMATION") {
                  return navigate!("../confirm", {
                    state: { ...data, successorLegalEntites: null }
                  });
                }

                navigate!("../successor-legal-entity", {
                  state: {
                    ...data,
                    ...(data.reorganizationType !== "DIVIDING" &&
                      !isEmpty(state.successorLegalEntites) &&
                      state.successorLegalEntites.length > 1 && {
                        successorLegalEntites: undefined
                      })
                  }
                });
              }}
              initialValues={state}
            >
              <Box width={1 / 3} mr={2}>
                <Composer
                  components={[
                    <DictionaryValue name="LEGAL_ENTITY_REORGANIZATION_TYPES" />,
                    ({
                      render
                    }: {
                      render: (props: TransRenderProps) => React.ReactElement;
                    }) => <Trans id="Select option" render={render} />
                  ]}
                >
                  {([dict, { translation }]: [
                    DictionaryAllValuesJson,
                    { translation: React.ReactNode }
                  ]) => (
                    <Field.Select
                      name="reorganizationType"
                      label={<Trans id="Reorganization type" />}
                      placeholder={translation}
                      items={
                        isLEReorganizationRestrictionsPresent
                          ? Object.keys(dict).filter(
                              (type) => type === "TRANSFORMATION"
                            )
                          : Object.keys(dict)
                      }
                      itemToString={(item: string) =>
                        dict[item] || String(translation)
                      }
                      emptyOption
                      variant="select"
                    />
                  )}
                </Composer>
                <Validation.Required
                  field="reorganizationType"
                  message="Required field"
                />
              </Box>
              <Box width={1 / 3}>
                <Trans
                  id="Example: Order of the Cabinet of Ministers of Ukraine # 73465 "
                  render={({ translation }) => (
                    <Field.Textarea
                      name="reorganizationReason"
                      rows={6}
                      label={<Trans id="Specify the reorganization reason" />}
                      placeholder={translation}
                      maxLength={255}
                      showLengthHint
                    />
                  )}
                />
                <Validation.Required
                  field="reorganizationReason"
                  message="Required field"
                />
              </Box>
              <Box width={1 / 4} mt={1}>
                <Field.DatePicker
                  name="reorganizationReasonDate"
                  minDate="1900-01-01"
                  maxDate={Date.now()}
                  label={<Trans id="Reorganization reason date" />}
                />
                <Validation.Required
                  field="reorganizationReasonDate"
                  message="Required field"
                />
              </Box>
              <Flex>
                <Button
                  mr={2}
                  onClick={() => {
                    navigate!(
                      `../?filter.code=${state.reorganizedLegalEntities[0].edrpou}`,
                      {
                        state: state
                      }
                    );
                  }}
                  variant="blue"
                  type="reset"
                  width={140}
                >
                  <Trans>Return</Trans>
                </Button>
                <Button variant="green" width={140}>
                  <Trans>Next</Trans>
                </Button>
              </Flex>
            </Form>
          </>
        );
      }}
    </LocationParams>
  );
};

export default ReorganizationData;
