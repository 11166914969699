import React from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useLingui } from "@lingui/react";
import { Box, Flex, Text } from "@rebass/emotion";
import { Trans, t } from "@lingui/macro";

import {
  Form,
  Switch,
  Validation,
  Validations
} from "@edenlabllc/ehealth-components";
import { PersonDocument } from "@edenlabllc/graphql-schema";

import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../../components/DictionaryValue";
import * as Field from "../../../../components/Field";

import { getDayBefore } from "../../../../helpers/dateHelpers";
import {
  PASSPORT_PATTERN,
  TEMPORARY_CERTIFICATE_PATTERN,
  NATIONAL_ID,
  BIRTH_CERTIFICATE_PATTERN
} from "../../../../constants/validationPatterns";

type DocumentsFormProps = {
  name: string;
  fieldsProps: {
    documentsListName: string;
  };
};

const DocumentsForm = ({ name, fieldsProps }: DocumentsFormProps) => {
  const { i18n } = useLingui();

  return (
    <Form.Spy>
      {({ values }: $TSFixMe) => {
        const currIssuedAt = get(values, `${name}.issuedAt`);
        const currExpirationDate = get(values, `${name}.expirationDate`);
        const currDocumentType = get(values, `${name}.type`);

        const birthDate = name.includes("confidantPersons")
          ? get(values, `${name.split(".")[0]}["birthDate"]`)
          : get(values, "birthDate");

        const issuedAtMinDate = getDayBefore(birthDate);

        return (
          <Flex flexWrap="wrap" justifyContent="flex-start">
            {fieldsProps && !isEmpty(fieldsProps.documentsListName) && (
              <Box width={1}>
                <Text mb={2} fontSize={1}>
                  {fieldsProps.documentsListName}
                </Text>
              </Box>
            )}
            <Box width={0.45} mr={2}>
              <DictionaryValue
                name={
                  name.includes("relationshipDocuments")
                    ? "DOCUMENT_RELATIONSHIP_TYPE"
                    : "DOCUMENT_TYPE"
                }
                render={(dict: DictionaryAllValuesJson) => (
                  <Field.Select
                    name={`${name}.type`}
                    label={<Trans id="Select document type" />}
                    placeholder={i18n._(t`Select option`)}
                    items={Object.keys(dict)}
                    itemToString={(item: string) => dict[item] || item}
                    variant="select"
                    emptyOption
                    filterOptions={{ keys: [(item: string) => dict[item]] }}
                  />
                )}
              />
              <Validations field={`${name}.type`}>
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required
                      field={`${name}.type`}
                      message={translation}
                    />
                  )}
                />
                <Validation.Custom
                  options={({ value, allValues: { documents } }: $TSFixMe) => {
                    if (isEmpty(documents) || name.includes("confidantPersons"))
                      return true;
                    const duplicates = documents.filter(
                      (document: PersonDocument) => {
                        return document && document.type === value;
                      }
                    );
                    return duplicates.length >= 2 ? false : true;
                  }}
                  message={i18n._(t`This document type is used more than once`)}
                />
                <Validation.Custom
                  options={({
                    value,
                    allValues: { confidantPersons }
                  }: $TSFixMe) => {
                    if (
                      isEmpty(confidantPersons) ||
                      isEmpty(confidantPersons[0]) ||
                      isEmpty(confidantPersons[0].documents) ||
                      !name.includes("confidantPersons[0]")
                    )
                      return true;

                    const duplicates = confidantPersons[0].documents.filter(
                      (document: PersonDocument) =>
                        document && document.type === value
                    );
                    return duplicates.length >= 2 ? false : true;
                  }}
                  message={i18n._(t`This document type is used more than once`)}
                />
                <Validation.Custom
                  options={({
                    value,
                    allValues: { confidantPersons }
                  }: $TSFixMe) => {
                    if (
                      isEmpty(confidantPersons) ||
                      isEmpty(confidantPersons[0]) ||
                      isEmpty(confidantPersons[0].relationshipDocuments) ||
                      !name.includes("confidantPersons[0]")
                    )
                      return true;

                    const duplicates =
                      confidantPersons[0].relationshipDocuments.filter(
                        (document: PersonDocument) =>
                          document && document.type === value
                      );
                    return duplicates.length >= 2 ? false : true;
                  }}
                  message={i18n._(t`This document type is used more than once`)}
                />
                <Validation.Custom
                  options={({
                    value,
                    allValues: { confidantPersons }
                  }: $TSFixMe) => {
                    if (
                      isEmpty(confidantPersons) ||
                      isEmpty(confidantPersons[1]) ||
                      isEmpty(confidantPersons[1].documents) ||
                      !name.includes("confidantPersons[1]")
                    )
                      return true;

                    const duplicates = confidantPersons[1].documents.filter(
                      (document: PersonDocument) =>
                        document && document.type === value
                    );
                    return duplicates.length >= 2 ? false : true;
                  }}
                  message={i18n._(t`This document type is used more than once`)}
                />
                <Validation.Custom
                  options={({
                    value,
                    allValues: { confidantPersons }
                  }: $TSFixMe) => {
                    if (
                      isEmpty(confidantPersons) ||
                      isEmpty(confidantPersons[1]) ||
                      isEmpty(confidantPersons[1].relationshipDocuments) ||
                      !name.includes("confidantPersons[1]")
                    )
                      return true;

                    const duplicates =
                      confidantPersons[1].relationshipDocuments.filter(
                        (document: PersonDocument) =>
                          document && document.type === value
                      );
                    return duplicates.length >= 2 ? false : true;
                  }}
                  message={i18n._(t`This document type is used more than once`)}
                />
              </Validations>
            </Box>
            <Box width={0.45} mr={2}>
              <Trans
                id="Enter number"
                render={({ translation }) => (
                  <Field.Text
                    name={`${name}.number`}
                    label={<Trans id="Document number" />}
                    placeholder={translation}
                    maxLength={100}
                  />
                )}
              />
              <Trans
                id="Required field"
                render={({ translation }) => (
                  <Validation.Required
                    field={`${name}.number`}
                    message={translation}
                  />
                )}
              />
              <Switch
                value={currDocumentType}
                PASSPORT={
                  <DocumentValidation name={name} pattern={PASSPORT_PATTERN} />
                }
                COMPLEMENTARY_PROTECTION_CERTIFICATE={
                  <DocumentValidation name={name} pattern={PASSPORT_PATTERN} />
                }
                REFUGEE_CERTIFICATE={
                  <DocumentValidation name={name} pattern={PASSPORT_PATTERN} />
                }
                TEMPORARY_CERTIFICATE={
                  <DocumentValidation
                    name={name}
                    pattern={TEMPORARY_CERTIFICATE_PATTERN}
                  />
                }
                BIRTH_CERTIFICATE={
                  <DocumentValidation
                    name={name}
                    pattern={BIRTH_CERTIFICATE_PATTERN}
                  />
                }
                TEMPORARY_PASSPORT={
                  <DocumentValidation
                    name={name}
                    pattern={BIRTH_CERTIFICATE_PATTERN}
                  />
                }
                NATIONAL_ID={
                  <DocumentValidation name={name} pattern={NATIONAL_ID} />
                }
              />
            </Box>
            <Box width={0.92}>
              <Trans
                id="Enter issued by"
                render={({ translation }) => (
                  <Field.Text
                    name={`${name}.issuedBy`}
                    label={<Trans id="Issued by" />}
                    placeholder={translation}
                    maxLength={255}
                  />
                )}
              />
              {!name.includes("relationshipDocuments") && (
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required
                      field={`${name}.issuedBy`}
                      message={translation}
                    />
                  )}
                />
              )}
            </Box>
            <Box width={1 / 4} mr={2}>
              <Field.DatePicker
                name={`${name}.issuedAt`}
                label={<Trans id="Date of issued" />}
                minDate={
                  issuedAtMinDate
                    ? issuedAtMinDate.substring(0, 10)
                    : "1900-01-01"
                }
                maxDate={currExpirationDate ? currExpirationDate : Date.now()}
              />
              {!name.includes("relationshipDocuments") && (
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required
                      field={`${name}.issuedAt`}
                      message={translation}
                    />
                  )}
                />
              )}
            </Box>
            <Box width={1 / 4} mr={2}>
              <Field.DatePicker
                name={`${name}.expirationDate`}
                label={<Trans id="Date of expiration" />}
                minDate={currIssuedAt ? currIssuedAt : birthDate}
              />
              {(currDocumentType === "NATIONAL_ID" ||
                currDocumentType === "PERMANENT_RESIDENCE_PERMIT") && (
                <Trans
                  id="Required field"
                  render={({ translation }) => (
                    <Validation.Required
                      field={`${name}.expirationDate`}
                      message={translation}
                    />
                  )}
                />
              )}
            </Box>
          </Flex>
        );
      }}
    </Form.Spy>
  );
};

type DocumentValidationProps = {
  name: string;
  pattern: string;
};

const DocumentValidation = ({ name, pattern }: DocumentValidationProps) => (
  <Trans
    id="Invalid format"
    render={({ translation }) => (
      <Validation.Matches
        field={`${name}.number`}
        options={pattern}
        message={translation}
      />
    )}
  />
);

export default DocumentsForm;
