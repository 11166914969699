import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box, Text } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import isEmpty from "lodash/isEmpty";

import { Medication } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";

type GeneralInfoProps = RouteComponentProps & {
  drlzSkuId: Medication["drlzSkuId"];
  atcCodes: Medication["atcCodes"];
  packageMinQty: Medication["packageMinQty"];
  packageQty: Medication["packageQty"];
  form: Medication["form"];
  manufacturer: Medication["manufacturer"];
  certificate: Medication["certificate"];
  certificateExpiredAt: Medication["certificateExpiredAt"];
  container: Medication["container"];
  formPharm: Medication["formPharm"];
  maxRequestDosage: Medication["maxRequestDosage"];
  endDate: Medication["endDate"];
};

const GeneralInfo = ({
  drlzSkuId,
  atcCodes = [],
  packageMinQty,
  packageQty,
  form,
  manufacturer,
  certificate,
  certificateExpiredAt,
  container,
  formPharm,
  maxRequestDosage,
  endDate
}: GeneralInfoProps) => (
  <Box p={5}>
    <DefinitionListView
      labelWidth={160}
      labels={{
        drlzSkuId: <Trans>Product ID in DRLZ register</Trans>,
        atcCodes: <Trans>Atc Codes</Trans>,
        form: <Trans>Medication form</Trans>,
        ...(formPharm && {
          formPharm: <Trans>Pharmaceutical form </Trans>
        }),
        ...(maxRequestDosage && {
          maxRequestDosage: (
            <Trans>
              Maximum quantity for dispense of per one Medication request
            </Trans>
          )
        }),
        ...(endDate && {
          endDate: <Trans id="End date field">End date</Trans>
        })
      }}
      data={{
        drlzSkuId,
        atcCodes: atcCodes ? atcCodes.join(", ") : "-",
        form: <DictionaryValue name="MEDICATION_FORM" item={form} />,
        formPharm: <DictionaryValue name="FORM_PHARM_LIST" item={formPharm} />,
        maxRequestDosage,
        endDate: endDate && i18n.date(endDate)
      }}
    />
    <Line />

    <Text fontSize={2} mb={6}>
      <Trans>Package</Trans>
    </Text>
    <DefinitionListView
      labelWidth={160}
      labels={{
        numeratorValue: <Trans>Numerator value</Trans>,
        ...(!isEmpty(container) &&
          !isEmpty(container.numeratorUnit) && {
            numeratorUnit: <Trans> Numerator unit</Trans>
          }),
        ...(!isEmpty(container) &&
          !isEmpty(container.denumeratorUnit) && {
            denumeratorUnit: <Trans>Denumerator unit</Trans>
          })
      }}
      data={{
        numeratorValue: container && container.numeratorValue,
        numeratorUnit: (
          <DictionaryValue
            name="MEDICATION_UNIT"
            item={container && container.numeratorUnit}
          />
        ),
        denumeratorUnit: (
          <DictionaryValue
            name="MEDICATION_UNIT"
            item={container && container.denumeratorUnit}
          />
        )
      }}
    />

    <Box mt={6}>
      <DefinitionListView
        labelWidth={160}
        labels={{
          packageMinQty: <Trans>Package minimum quantity</Trans>,
          packageQty: <Trans>Package quantity</Trans>
        }}
        data={{
          packageMinQty,
          packageQty
        }}
      />
    </Box>
    <Line />

    <DefinitionListView
      labelWidth={160}
      labels={{
        manufacturerName: <Trans>Manufacturer name</Trans>,
        manufacturerCountry: <Trans>Manufacturer country</Trans>
      }}
      data={{
        manufacturerName: manufacturer ? manufacturer.name : "-",
        manufacturerCountry: manufacturer ? (
          <DictionaryValue name="COUNTRY" item={manufacturer.country} />
        ) : (
          "-"
        )
      }}
    />
    <Line />
    <DefinitionListView
      labelWidth={160}
      labels={{
        certificate: (
          <Trans>
            Certificate <br />
            number
          </Trans>
        ),
        certificateExpiredAt: <Trans>Certificate expired At</Trans>
      }}
      data={{
        certificate,
        certificateExpiredAt: i18n.date(certificateExpiredAt)
      }}
    />
  </Box>
);

GeneralInfo.fragments = {
  entry: gql`
    fragment MedicationGeneralInfo on Medication {
      atcCodes
      packageMinQty
      packageQty
      form
      manufacturer {
        country
        name
      }
      certificate
      certificateExpiredAt
      container {
        numeratorUnit
        numeratorValue
        denumeratorUnit
        denumeratorValue
        __typename
      }
      formPharm
      maxRequestDosage
      endDate
      drlzSkuId
    }
  `
};

export default GeneralInfo;
