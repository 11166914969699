import * as React from "react";
import { i18n } from "@lingui/core";
import { Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

type DocumentViewProps = {
  data: any; // Maybe<PersonDocument> | Maybe<PartyDocument>;
};

const DocumentView = ({ data }: DocumentViewProps) => {
  if (isEmpty(data)) return null;
  const { number, issuedAt, issuedBy, expirationDate } = data;

  return (
    <Box fontSize={0}>
      {number}
      {issuedBy && `, виданий ${issuedBy}`}
      {issuedAt && <>, від {i18n.date(issuedAt)}</>}
      {expirationDate && <>, дійсний до {i18n.date(expirationDate)}</>}
    </Box>
  );
};

export default DocumentView;
