import React from "react";
import { NavigateFn, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import system from "@edenlabllc/ehealth-system-components";
import { Form, LocationParams, Modal } from "@edenlabllc/ehealth-components";
import { RemoveItemIcon } from "@edenlabllc/ehealth-icons";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import {
  DeviceDefinition,
  DeviceDefinitionConnection
} from "@edenlabllc/graphql-schema";

import Badge from "../../components/Badge";
import Button from "../../components/Button";
import DictionaryValue from "../../components/DictionaryValue";
import EmptyData from "../../components/EmptyData";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import Table, { SortingParams } from "../../components/Table";
import {
  TLocationParams,
  SearchParams,
  SetSearchParams
} from "../../components/SearchForm";

import dateFormatter from "../../helpers/dateFormatter";
import filteredLocationParams from "../../helpers/filteredLocationParams";
import { DATE_TIME_FORMAT } from "../../constants/dateFormats";

import {
  PrimarySearchFields,
  SecondarySearchFields
} from "../DeviceDefinitions/Search/SearchFields";

type DeviceDefinitionModalFormProps = RouteComponentProps<{
  initialValues: SearchParams;
  navigate: NavigateFn;
}>;

const DeviceDefinitionModalForm = ({
  navigate
}: DeviceDefinitionModalFormProps) => {
  const onClose = () => navigate!("../");

  return (
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Modal backdrop width="90%">
          <Close onClick={onClose} />
          <Form onSubmit={setLocationParams} initialValues={locationParams}>
            <PrimarySearchFields />
            <SecondarySearchFields />
            <Flex mx={-1} mt={4} justifyContent="flex-start">
              <Box px={1}>
                <Button variant="red" onClick={onClose}>
                  <Trans>Close</Trans>
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="blue">
                  <Trans>Search</Trans>
                </Button>
              </Box>
              {!isEmpty(locationParams.filter) && (
                <Box px={1}>
                  <Button
                    variant="link"
                    icon={RemoveItemIcon}
                    px={1}
                    type="reset"
                    onClick={() => {
                      setLocationParams({
                        ...locationParams,
                        filter: {},
                        searchRequest: null
                      });
                    }}
                  >
                    <Trans>Reset</Trans>
                  </Button>
                </Box>
              )}
            </Flex>
          </Form>
          <Query
            query={SearchDeviceDefinitionsQuery}
            fetchPolicy="network-only"
            variables={filteredLocationParams(locationParams)}
          >
            {({
              loading,
              data
            }: QueryResult<{
              deviceDefinitions: DeviceDefinitionConnection;
            }>) => (
              <LoadingOverlay loading={loading}>
                {isEmpty(data) ||
                isEmpty(data.deviceDefinitions) ||
                isEmpty(data.deviceDefinitions.nodes) ? (
                  <Box m={7}>
                    <EmptyData height={100} />
                  </Box>
                ) : (
                  <>
                    <DeviceDefinitionsTable
                      locationParams={locationParams}
                      deviceDefinitions={data.deviceDefinitions.nodes}
                      setLocationParams={setLocationParams}
                    />
                    <Pagination pageInfo={data.deviceDefinitions.pageInfo} />
                  </>
                )}
              </LoadingOverlay>
            )}
          </Query>
        </Modal>
      )}
    </LocationParams>
  );
};

const Close = system(
  {
    is: RemoveItemIcon
  },
  {
    position: "absolute",
    right: "20px",
    top: "20px",
    width: 12,
    height: 12,
    opacity: 0.2
  }
);

type DeviceDefinitionsTableProps = {
  deviceDefinitions: DeviceDefinitionConnection["nodes"];
  locationParams: SearchParams;
  setLocationParams: SetSearchParams;
};

const DeviceDefinitionsTable = ({
  deviceDefinitions,
  locationParams,
  setLocationParams
}: DeviceDefinitionsTableProps) => (
  <Table
    data={deviceDefinitions}
    header={{
      databaseId: <Trans>ID</Trans>,
      classificationType: <Trans>Device definition classification type</Trans>,
      deviceNames: <Trans>Device definition name</Trans>,
      modelNumber: <Trans>Device definition model number</Trans>,
      isActive: <Trans>Status</Trans>,
      manufacturerName: <Trans>Device definition manufacturer name</Trans>,
      manufacturerCountry: (
        <Trans>Device definition manufacturer country</Trans>
      ),
      insertedAt: <Trans>Inserted at</Trans>,
      action: <Trans>Action</Trans>
    }}
    renderRow={(deviceDefinition: DeviceDefinition) => {
      const {
        databaseId,
        classificationType,
        deviceNames,
        modelNumber,
        insertedAt,
        isActive,
        manufacturerName,
        manufacturerCountry
      } = deviceDefinition;
      const deviceNamesList =
        deviceNames && deviceNames.map((item) => item && item.name).join(", ");

      return {
        databaseId,
        classificationType: (
          <DictionaryValue
            name="device_definition_classification_type"
            item={classificationType}
          />
        ),
        deviceNames: deviceNamesList,
        modelNumber,
        insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
        isActive: (
          <Badge
            type="ACTIVE_STATUS_M"
            name={isActive}
            variant={!isActive}
            display="block"
          />
        ),
        manufacturerName,
        manufacturerCountry: manufacturerCountry && (
          <DictionaryValue name="COUNTRY" item={manufacturerCountry} />
        ),
        action: (
          <Link to={`../`} state={{ deviceDefinition }} fontWeight="bold">
            <Trans>Select</Trans>
          </Link>
        )
      };
    }}
    sortableFields={["insertedAt", "classificationType", "modelNumber"]}
    sortingParams={parseSortingParams(locationParams.orderBy)}
    onSortingChange={(sortingParams: SortingParams) =>
      setLocationParams({
        ...locationParams,
        orderBy: stringifySortingParams(sortingParams)
      })
    }
    whiteSpaceNoWrap={["databaseId"]}
    tableName="device-definitions/search"
    hiddenFields="insertedAt,manufacturerCountry"
  />
);

DeviceDefinitionsTable.fragments = {
  entry: gql`
    fragment DeviceDefinitionsProgramDevices on DeviceDefinition {
      id
      databaseId
      classificationType
      deviceNames {
        name
        type
      }
      modelNumber
      manufacturerName
      manufacturerCountry
      isActive
      insertedAt
    }
  `
};

const SearchDeviceDefinitionsQuery = gql`
  query SearchDeviceDefinitionsQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: DeviceDefinitionFilter
    $orderBy: DeviceDefinitionOrderBy
    $skip: Boolean = false
  ) {
    deviceDefinitions(
      first: $first
      filter: $filter
      orderBy: $orderBy
      before: $before
      after: $after
      last: $last
    ) @skip(if: $skip) {
      nodes {
        ...DeviceDefinitionsProgramDevices
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${DeviceDefinitionsTable.fragments.entry}
  ${Pagination.fragments.entry}
`;

export default DeviceDefinitionModalForm;
