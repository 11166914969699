import React from "react";
import styled from "@emotion/styled";
import { ifProp } from "styled-tools";
import * as Reach from "@reach/router";
import { Trans } from "@lingui/macro";

import { ChevronBottomIcon } from "@edenlabllc/ehealth-icons";

import { Flag } from "../flags";
import Ability from "./Ability";
import Link from "./Link";

import env from "../env";

const Nav = () => (
  <NavContainer>
    <NavList>
      <NavLinkExternal to="dashboard">
        <Trans>Statistic</Trans>
      </NavLinkExternal>

      {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
      <Flag name={["features", "legacy"]}>
        <Ability action="read" resource="person">
          <NavLinkExternal to="persons">
            <Trans>Persons</Trans>
          </NavLinkExternal>
        </Ability>
        <Ability action="reset_authentication_method" resource="person">
          <NavLinkExternal to="reset-authentication-method">
            Скинути метод
            <br />
            авторизації
          </NavLinkExternal>
        </Ability>

        <Ability action="read" resource="declaration">
          <NavSection title={<Trans>Declarations</Trans>}>
            <NavLinkExternal to="declarations">
              <Trans>Declarations</Trans>
            </NavLinkExternal>
            <NavLinkExternal to="pending-declarations">
              <Trans>Pending declarations</Trans>
            </NavLinkExternal>
          </NavSection>
        </Ability>

        <Ability action="read" resource="employee">
          <NavSection title={<Trans>Employees</Trans>}>
            <Ability action="read" resource="employee">
              <NavLinkExternal to="employees">
                <Trans>Employees</Trans>
              </NavLinkExternal>
              <NavLinkExternal to="pending-employees">
                <Trans>Pending employees</Trans>
              </NavLinkExternal>
            </Ability>
          </NavSection>
        </Ability>
      </Flag>

      <NavLinkExternal to="reports">
        <Trans>Reports</Trans>
      </NavLinkExternal>

      {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
      <Flag name={["features", "legacy"]}>
        <Ability action="read" resources={["register", "register_entry"]} loose>
          <NavSection title={<Trans>Registers</Trans>}>
            <Ability action="read" resource="register">
              <NavLinkExternal to="registers">
                <Trans>Registers</Trans>
              </NavLinkExternal>
            </Ability>
            <Ability action="read" resource="register_entry">
              <NavLinkExternal to="registers-entries">
                <Trans>Registers entries</Trans>
              </NavLinkExternal>
            </Ability>
          </NavSection>
        </Ability>

        <Ability action="read" resource="global_parameters">
          <NavLinkExternal to="configuration">
            <Trans>Configuration</Trans>
          </NavLinkExternal>
        </Ability>

        <Ability
          action="read"
          resources={["innm", "innm_dosage", "medication"]}
          loose
        >
          <NavSection title={<Trans>Medicines</Trans>}>
            <Ability action="read" resource="innm">
              <NavLinkExternal to="innms">
                <Trans>Innm</Trans>
              </NavLinkExternal>
            </Ability>
            <Ability action="read" resource="innm_dosage">
              <NavLinkExternal to="innm-dosages">
                <Trans>Innm dosage</Trans>
              </NavLinkExternal>
            </Ability>
            <Ability action="read" resource="medication">
              <NavLinkExternal to="medications">
                <Trans>Trade name</Trans>
              </NavLinkExternal>
            </Ability>
          </NavSection>
        </Ability>

        <Ability
          action="read"
          resources={["medical_program", "program_medication"]}
          loose
        >
          <NavSection title={<Trans>Medical program</Trans>}>
            <Ability action="read" resource="medical_program">
              <NavLinkExternal to="medical-programs">
                <Trans>List of medical programs</Trans>
              </NavLinkExternal>
            </Ability>
            <Ability action="read" resource="program_medication">
              <NavLinkExternal to="program-medications">
                <Trans>Program Participants</Trans>
              </NavLinkExternal>
            </Ability>
          </NavSection>
        </Ability>
      </Flag>

      {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
      <Flag name={["features", "medicationRequestsLegacy"]}>
        <Ability
          action="read"
          resources={[
            "medication_request_admin",
            "medication_dispense",
            "reimbursement_report"
          ]}
          loose
        >
          <NavSection title={<Trans>Рецепти legacy</Trans>}>
            <Ability action="read" resource="medication_request_admin">
              <NavLinkExternal to="medication-requests">
                <Trans>Recipes</Trans>
              </NavLinkExternal>
            </Ability>
            <Ability action="read" resource="medication_dispense_admin">
              <NavLinkExternal to="medication-dispenses">
                <Trans>Medication dispenses</Trans>
              </NavLinkExternal>
            </Ability>
            <Ability action="download" resource="reimbursement_report">
              <NavLinkExternal to="reimbursement-report">
                <Trans>Reimbursement report</Trans>
              </NavLinkExternal>
            </Ability>
          </NavSection>
        </Ability>
      </Flag>

      <Ability
        action="read"
        resources={["medication_request_admin", "medication_dispense_admin"]}
        loose
      >
        <NavSection title={<Trans>Recipes</Trans>}>
          {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
          <Flag name={["features", "medicationRequests"]}>
            <Ability action="read" resource="medication_request_admin">
              <NavLink to="medication-requests">
                <Trans>Recipes</Trans>
              </NavLink>
            </Ability>
          </Flag>
          {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
          <Flag name={["features", "medicationDispenses"]}>
            <Ability action="read" resource="medication_dispense_admin">
              <NavLink to="medication-dispenses">
                <Trans>Medication dispenses</Trans>
              </NavLink>
            </Ability>
          </Flag>
        </NavSection>
      </Ability>

      <Ability action="read" resources={["bl_user", "party_user"]} loose>
        <NavSection title={<Trans>Users</Trans>}>
          <Ability action="read" resource="bl_user">
            <NavLinkExternal to="black-list-users">
              <Trans>Black list users</Trans>
            </NavLinkExternal>
          </Ability>
          <Ability action="read" resource="party_user">
            <NavLinkExternal to="party-users">
              <Trans>Accounts</Trans>
            </NavLinkExternal>
          </Ability>
        </NavSection>
      </Ability>

      <Ability action="read" resources={["contract_request", "contract"]} loose>
        <NavSection title={<Trans>Contracts</Trans>}>
          <Ability action="read" resource="contract_request">
            <NavLink to="/contracts">
              <Trans>Contracts</Trans>
            </NavLink>
            <NavLink to="/contract-requests">
              <Trans>Contract requests</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>
      <Ability
        actions={["read", "reset_authentication_method"]}
        resource="person"
        loose
      >
        <NavSection title={<Trans>Persons</Trans>}>
          <Ability action="read" resource="person">
            <NavLink to="/persons">
              <Trans>Persons</Trans>
            </NavLink>
          </Ability>
          <Ability action="verify" resource="person">
            <NavLink to="/persons-verifications">
              <Trans>Verification of patients</Trans>
            </NavLink>
          </Ability>
          <Ability action="reset_authentication_method" resource="person">
            <NavLink to="reset-authentication-method">
              <Trans>Reset persons authentication method</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>
      <Ability
        resources={[
          "episode",
          "encounter",
          "observation",
          "condition",
          "procedure",
          "service_request",
          "immunization",
          "allergy_intolerance",
          "care_plan",
          "diagnostic_report",
          "medication_request",
          "medication_dispense"
        ]}
        action="clinical_monitor"
        loose
      >
        <NavLink to="/clinical-monitoring">
          <Trans>Clinical monitoring</Trans>
        </NavLink>
      </Ability>

      <Ability action="write" resource="register">
        <NavLink to="/registers">
          <Trans>Registers</Trans>
        </NavLink>
      </Ability>

      <Ability action="read" resource="legal_entity">
        <NavSection title={<Trans>Legal entities</Trans>}>
          <NavLink to="/legal-entities">
            <Trans>Legal entities</Trans>
          </NavLink>
          <Ability action="merge" resource="legal_entity">
            <NavLink to="legal-entities-reorganizations">
              <Trans>Legal Entities Reorganization</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>

      <Ability
        action="read"
        resources={["innm", "innm_dosage", "medication"]}
        loose
      >
        <NavSection title={<Trans>Medications</Trans>}>
          <Ability action="read" resource="innm">
            <NavLink to="/innms">
              <Trans>INNMs</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="innm_dosage">
            <NavLink to="/innm-dosages">
              <Trans>INNM dosages</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="medication">
            <NavLink to="/medications">
              <Trans>Trade name</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>

      <Ability action="read" resource="device_definition">
        <NavLink to="/device-definitions">
          <Trans>Device definitions</Trans>
        </NavLink>
      </Ability>

      <Ability
        action="read"
        resources={[
          "medical_program",
          "program_medication",
          "program_service",
          "program_device"
        ]}
        loose
      >
        <NavSection title={<Trans>Medical program</Trans>}>
          <Ability action="read" resource="medical_program">
            <NavLink to="/medical-programs">
              <Trans>Medical programs</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="program_medication">
            <NavLink to="/program-medications">
              <Trans>Program medications</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="program_service">
            <NavLink to="/program-services">
              <Trans>Program services</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="program_device">
            <NavLink to="/program-devices">
              <Trans>Program devices</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>

      {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
      <Flag name={["features", "medicalCompositions"]}>
        <Ability action="read" resources={["composition_admin"]} loose>
          <NavSection title={<Trans>Medical composition</Trans>}>
            <Ability action="read" resource="composition_admin">
              <NavLink to="/medical-composition/search">
                <Trans>MC search</Trans>
              </NavLink>
            </Ability>
          </NavSection>
        </Ability>
      </Flag>

      <Ability action="read" resource="service_catalog">
        <NavSection title={<Trans>Services</Trans>}>
          <NavLink to="/services">
            <Trans>Services</Trans>
          </NavLink>
          <NavLink to="/service-groups">
            <Trans>Service groups</Trans>
          </NavLink>
        </NavSection>
      </Ability>

      <Ability action="read" resource="declaration">
        <NavSection title={<Trans>Declarations</Trans>}>
          <NavLink to="declarations">
            <Trans>Declarations</Trans>
          </NavLink>
          <NavLink to="pending-declarations">
            <Trans>Pending declarations</Trans>
          </NavLink>
        </NavSection>
      </Ability>

      <Ability
        scopes={[
          "legal_entity:merge",
          "legal_entity:deactivate",
          "persons_auth_reset_job:read",
          "persons_deactivation_job:read",
          "declarations_termination_job:read",
          "medication_registry_job:read",
          "device_registry_job:read"
        ]}
        loose
      >
        <NavSection title={<Trans>Jobs</Trans>}>
          <Ability action="read" resource="legal_entity_merge_job">
            <NavLink to="/legal-entity-reorganization-jobs">
              <Trans>Legal entity reorganization jobs</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="legal_entity_deactivation_job">
            <NavLink to="/legal-entity-deactivate-jobs">
              <Trans>Legal entity deactivate jobs</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="persons_auth_reset_job">
            <NavLink to="/reset-persons-auth-method-jobs">
              <Trans>Reset persons authentication method</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="persons_deactivation_job">
            <NavLink to="/persons-deactivation-jobs">
              <Trans>Persons deactivation jobs</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="declarations_termination_job">
            <NavLink to="/declarations-termination-jobs">
              <Trans>Declarations termination jobs</Trans>
            </NavLink>
          </Ability>
          {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
          <Flag name={["features", "medicationRegistryJobs"]}>
            <Ability action="read" resource="medication_registry_job">
              <NavLink to="/medication-registry-jobs">
                <Trans>Medication registry jobs</Trans>
              </NavLink>
            </Ability>
          </Flag>
          <Ability action="read" resource="device_registry_job">
            <NavLink to="/device-registry-jobs">
              <Trans>Device registry jobs</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>

      <Ability action="read" resource="person_merge">
        <NavSection title={<Trans>Patients Merge</Trans>}>
          <NavLink to="/applications-patient-merge-requests">
            <Trans>Applications patients merge</Trans>
          </NavLink>
          <NavLink to="/candidates-patient-merge-requests">
            <Trans>Candidates patients merge</Trans>
          </NavLink>
        </NavSection>
      </Ability>

      <Ability action="read" resource="employee">
        <NavSection title={<Trans>Employees</Trans>}>
          <Ability action="read" resource="employee">
            <NavLink to="/employees">
              <Trans>Employees</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="employee_request">
            <NavLink to="/employee-requests">
              <Trans>Employee Requests</Trans>
            </NavLink>
          </Ability>
          <Ability action="read" resource="party">
            <NavLink to="/unverified-parties">
              <Trans>Party verification</Trans>
            </NavLink>
          </Ability>
        </NavSection>
      </Ability>

      <Ability action="read" resource="global_parameters">
        <NavLink to="/configuration">
          <Trans>Configuration</Trans>
        </NavLink>
      </Ability>

      <NavLink to="/dictionaries">
        <Trans>Dictionaries</Trans>
      </NavLink>

      {/* @ts-expect-error TS(2322): Type '[string, string]' is not assignable to type ... Remove this comment to see the full error message */}
      <Flag name={["features", "diagnosesGroups"]}>
        <Ability action="read" resource="diagnoses_group">
          <NavLink to="/diagnoses-groups">
            <Trans>Diagnoses groups</Trans>
          </NavLink>
        </Ability>
      </Flag>

      <Ability action="details" resource="forbidden_group">
        <NavLink to="/forbiddenGroups">
          <Trans>Forbidden groups</Trans>
        </NavLink>
      </Ability>

      <Ability action="details" resource="rule_engine_rule">
        <NavLink to="/rule-engine">
          <Trans>Rule engine rules</Trans>
        </NavLink>
      </Ability>
    </NavList>
  </NavContainer>
);

export default Nav;

type NavLinkProps = {
  to: string;
  children: React.ReactElement;
};

const NavLink = ({ to, children }: NavLinkProps) => (
  <Reach.Match path={`${to}/*`}>
    {({ match }) => (
      // @ts-expect-error TS(2322): Type '{ children: Element; isCurrent: { uri: strin... Remove this comment to see the full error message
      <NavItem isCurrent={match}>
        <Link to={to} color="white" fontWeight={match && "bold"}>
          {children}
        </Link>
      </NavItem>
    )}
  </Reach.Match>
);

type NavLinkExternalProps = {
  to: string;
  href?: string;
  children: React.ReactNode;
};

const NavLinkExternal = ({ to, href, children }: NavLinkExternalProps) => {
  const externalProps = href
    ? {
        href,
        rel: "noopener noreferrer",
        target: "_blank"
      }
    : {
        href: `${env.REACT_APP_ADMIN_LEGACY_URL}/${to}`
      };

  return (
    <NavItem>
      <Link is="a" color="white" {...externalProps}>
        {children}
      </Link>
    </NavItem>
  );
};

type NavSectionProps = {
  title: React.ReactElement;
  children: React.ReactNode;
};

const NavSection = ({ title, children }: NavSectionProps) => (
  <NavItem>
    <details>
      <Link
        is="summary"
        color="white"
        display="inline"
        verticalAlign="baseline"
      >
        {title}
        <ChevronBottomIcon
          width="7px"
          height="7px"
          ml={2}
          css={`
          details[open] & {
            transform: rotate(180deg);
        `}
        />
      </Link>
      <NavList>{children}</NavList>
    </details>
  </NavItem>
);

const NavContainer = styled.nav`
  margin: 80px 0 35px;
`;

const NavList = styled.ul`
  margin: 25px 0 0;
  padding-left: 20px;
`;

const NavItem = styled.li`
  width: 210px;
  font-weight: ${ifProp("isCurrent", "700", "400")};
  line-height: 16px;
  list-style-image: radial-gradient(
    circle closest-side,
    ${ifProp("isCurrent", "#ff7800", "#fff")},
    ${ifProp("isCurrent", "#ff7800", "#fff")} 90%,
    transparent 100%
  );
  margin-top: 15px;
  > a,
  summary {
    margin-left: 10px;
  }
`;
