import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty } from "lodash";

import { getFullName } from "@edenlabllc/ehealth-utils";
import { EmployeeConnection } from "@edenlabllc/graphql-schema";

import * as Field from "./Field";
import FullName from "./FullName";

const AssigneeSearch = () => (
  <Trans
    id="Choose assignee"
    render={({ translation }) => (
      <Query query={GetEmployeeNameQuery} variables={{ skip: true }}>
        {({
          data,
          refetch: refetchEmployees
        }: QueryResult<{ employees: EmployeeConnection }>) => {
          const { employees } = data || {};

          return (
            <Field.Select
              name="filter.assignee"
              label={<Trans id="Performer" />}
              placeholder={translation}
              items={
                employees && employees.nodes
                  ? employees.nodes.map((employee) => {
                      const { databaseId, party } = employee || {};
                      return {
                        databaseId,
                        name: getFullName(party)
                      };
                    })
                  : []
              }
              onInputValueChange={debounce(
                (name: string) =>
                  !isEmpty(name) &&
                  refetchEmployees({
                    skip: false,
                    first: 50,
                    filter: {
                      employeeType: ["NHS ADMIN SIGNER"],
                      status: "APPROVED",
                      party: { fullName: name }
                    }
                  }),
                1000
              )}
              itemToString={(item: { databaseId: string; name: string }) =>
                item && item.name
              }
              filterOptions={{ keys: ["name"] }}
            />
          );
        }}
      </Query>
    )}
  />
);

const GetEmployeeNameQuery = gql`
  query EmployeesQuery(
    $first: Int!
    $filter: EmployeeFilter
    $skip: Boolean! = false
  ) {
    employees(first: $first, filter: $filter) @skip(if: $skip) {
      nodes {
        id
        databaseId
        party {
          id
          ...FullName
        }
      }
    }
  }
  ${FullName.fragments.entry}
`;

export default AssigneeSearch;
