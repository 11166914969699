import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import isEmpty from "lodash/isEmpty";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Flex, Box, Heading } from "@rebass/emotion";

import { PositiveIcon } from "@edenlabllc/ehealth-icons";
import { InnmDosage, Scalars } from "@edenlabllc/graphql-schema";

import DictionaryValue from "../../../components/DictionaryValue";
import DefinitionListView from "../../../components/DefinitionListView";
import Line from "../../../components/Line";
import Link from "../../../components/Link";
import Table from "../../../components/Table";

import dateFormatter from "../../../helpers/dateFormatter";

import UpdateDosage from "./Mutations/UpdateDosage";

type IngredientsProps = RouteComponentProps<{
  id: Scalars["ID"];
  data: InnmDosage["ingredients"];
  dailyDosage: InnmDosage["dailyDosage"];
  dosageFormIsDosed: InnmDosage["dosageFormIsDosed"];
  mrBlankType: InnmDosage["mrBlankType"];
  endDate: InnmDosage["endDate"];
}>;

const Ingredients = ({
  id,
  data = [],
  dailyDosage,
  dosageFormIsDosed,
  mrBlankType,
  endDate
}: IngredientsProps) => {
  if (isEmpty(data)) return null;

  const dataForSort = [...data];
  const ingredients = dataForSort.sort((ingredient) =>
    ingredient && ingredient.isPrimary ? -1 : 1
  );

  const dosageFormIsDosedValues: { [key: string]: React.ReactNode } = {
    true: <Trans>Yes</Trans>,
    false: <Trans>No</Trans>,
    null: <Trans>Missing value</Trans>
  };

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          dosageFormIsDosed: <Trans>Is Dosage Form dosed</Trans>,
          ...(!isEmpty(mrBlankType) && {
            mrBlankType: <Trans>Type of Medication request blank</Trans>
          }),
          dailyDosage: <Trans>Daily dosage</Trans>,
          ...(endDate && {
            endDate: <Trans id="End date field">End date</Trans>
          })
        }}
        data={{
          dosageFormIsDosed: dosageFormIsDosedValues[String(dosageFormIsDosed)],
          mrBlankType: mrBlankType && (
            <DictionaryValue name="MR_BLANK_TYPES" item={mrBlankType} />
          ),
          dailyDosage: (
            <UpdateDosage
              id={id!}
              dosage={dailyDosage}
              fieldName="dailyDosage"
              denumeratorUnit={
                ingredients[0] && ingredients[0].dosage.denumeratorUnit
              }
            />
          ),
          endDate: endDate && i18n.date(endDate)
        }}
        labelWidth="200px"
      />
      {ingredients.length > 0 && (
        <>
          <Line />
          <Heading fontSize="1" fontWeight="normal" mb={5}>
            <Trans>Ingredients</Trans>
          </Heading>
          <Table
            data={ingredients}
            header={{
              databaseId: <Trans>ID</Trans>,
              name: <Trans>INNM</Trans>,
              dosage: <Trans>Mixture</Trans>,
              nameOriginal: <Trans>INNM original name</Trans>,
              sctid: <Trans>SCTID</Trans>,
              insertedAt: <Trans>Inserted at</Trans>,
              isPrimary: <Trans>Primary</Trans>
            }}
            renderRow={({ isPrimary, dosage, innm }) => {
              const { insertedAt, databaseId, ...innmDetails } = innm || {};
              const {
                numeratorUnit,
                numeratorValue,
                denumeratorUnit,
                denumeratorValue
              } = dosage || {};

              return {
                ...innmDetails,
                databaseId: (
                  <Link to={`/innms/search?filter.databaseId=${databaseId}`}>
                    {databaseId}
                  </Link>
                ),
                insertedAt: dateFormatter(
                  i18n.locale,
                  {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  },
                  insertedAt
                ),
                isPrimary: isPrimary && (
                  <Flex justifyContent="center">
                    <PositiveIcon />
                  </Flex>
                ),
                dosage: (
                  <>
                    {denumeratorValue}{" "}
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={denumeratorUnit}
                    />{" "}
                    <Trans>includes</Trans> {numeratorValue}{" "}
                    <DictionaryValue
                      name="MEDICATION_UNIT"
                      item={numeratorUnit}
                    />
                  </>
                )
              };
            }}
            whiteSpaceNoWrap={["databaseId"]}
            hiddenFields="nameOriginal,sctid,insertedAt"
            tableName="INNMDosagesIngredients/search"
          />
        </>
      )}
    </Box>
  );
};

Ingredients.fragments = {
  entry: gql`
    fragment INNMDosageIngredients on INNMDosage {
      dailyDosage
      ingredients {
        dosage {
          numeratorUnit
          numeratorValue
          denumeratorUnit
          denumeratorValue
        }
        isPrimary
        innm {
          id
          databaseId
          name
          nameOriginal
          insertedAt
          sctid
        }
      }
      dosageFormIsDosed
      mrBlankType
      endDate
    }
  `
};

export default Ingredients;
