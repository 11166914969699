import React from "react";

import system from "@edenlabllc/ehealth-system-components";
import { variant } from "@edenlabllc/ehealth-system-tools";

import statuses from "../helpers/statuses";
import DictionaryValue from "./DictionaryValue";

type BadgeProps = {
  name: string | boolean | undefined;
  type?: string;
  dictionary?: string;
  minWidth?: number;
  maxWidth?: number;
  variant?: boolean;
  display?: string;
};

const Badge = ({ name, type, dictionary, ...props }: BadgeProps) => {
  const title =
    dictionary && typeof name === "string" ? (
      <DictionaryValue name={dictionary} item={name} />
    ) : type && typeof name !== undefined ? (
      // @ts-expect-error statuses boolean key
      statuses[type][name]
    ) : null;

  return (
    <Item variant={name} {...props}>
      {title}
    </Item>
  );
};

export const Item = system(
  {
    display: "inline-block",
    p: 1,
    color: "white",
    minWidth: 20,
    maxWidth: "initial",
    minHeight: 20,
    fontSize: 10
  },
  {
    textOverflow: "ellipsis",
    textTransform: "uppercase",
    verticalAlign: "middle",
    borderRadius: 2,
    fontWeight: "bold",
    lineHeight: 1,
    textAlign: "center",
    overflow: "hidden"
  },
  variant({
    key: "status.bg.states"
  }),
  "minWidth",
  "maxWidth",
  "minHeight",
  "display",
  "color",
  "space",
  "fontSize"
);

export default Badge;
