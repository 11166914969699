import React, { useMemo } from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { ApolloQueryResult, QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { debounce, isEmpty, uniq } from "lodash";

import { ForbiddenGroupConnection } from "@edenlabllc/graphql-schema";

import * as Field from "../Field";

type ForbiddenGroupNameFieldProps = {
  name: string;
};

const ForbiddenGroupNameField = ({ name }: ForbiddenGroupNameFieldProps) => {
  return (
    <Trans
      id="Enter Group name"
      render={({ translation }) => (
        <Query
          query={SearchForbiddenGroupsQuery}
          fetchPolicy="cache-first"
          variables={{ first: 20 }}
        >
          {({
            data,
            refetch
          }: QueryResult<{ forbiddenGroups: ForbiddenGroupConnection }>) => {
            const {
              forbiddenGroups: { nodes: forbiddenGroupsNodes = [] } = {}
            } = data || {};

            return (
              <NamesSelect
                forbiddenGroupsNodes={forbiddenGroupsNodes}
                refetch={refetch}
                translation={translation}
                name={name}
              />
            );
          }}
        </Query>
      )}
    />
  );
};

type NamesSelectProps = {
  forbiddenGroupsNodes: ForbiddenGroupConnection["nodes"];
  refetch: (variables: any) => Promise<
    ApolloQueryResult<{
      forbiddenGroups: ForbiddenGroupConnection;
    }>
  >;
  translation: React.ReactNode;
  name: string;
};

const NamesSelect = ({
  forbiddenGroupsNodes,
  refetch,
  translation,
  name
}: NamesSelectProps) => {
  const namesList = useMemo(() => {
    const names = forbiddenGroupsNodes!.map(
      (forbiddenGroup) => forbiddenGroup && forbiddenGroup.name
    );
    return uniq(names);
  }, [forbiddenGroupsNodes, name]);

  const itemToString = (item: string) => item;

  return (
    <Field.Select
      name={name}
      label={<Trans id="Find group" />}
      placeholder={translation}
      items={namesList}
      itemToString={itemToString}
      onInputValueChange={debounce(
        (name, { selectedItem, inputValue }) =>
          !isEmpty(name) &&
          selectedItem !== inputValue &&
          refetch({
            first: 20,
            filter: {
              name
            }
          }),
        300
      )}
    />
  );
};

export default ForbiddenGroupNameField;

const SearchForbiddenGroupsQuery = gql`
  query SearchForbiddenGroupsQuery(
    $filter: ForbiddenGroupFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    forbiddenGroups(
      filter: $filter
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        id
        name
      }
    }
  }
`;
