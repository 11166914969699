import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { Flex, Box, Heading } from "@rebass/emotion";
import { isEmpty, isEqual } from "lodash";

import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import {
  parseSortingParams,
  stringifySortingParams
} from "@edenlabllc/ehealth-utils";
import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { Declaration, DeclarationConnection } from "@edenlabllc/graphql-schema";

import AddressView from "../../components/AddressView";
import Badge from "../../components/Badge";
import EmptyData from "../../components/EmptyData";
import * as Field from "../../components/Field";
import FullName from "../../components/FullName";
import Link from "../../components/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import Pagination from "../../components/Pagination";
import {
  SearchParams,
  SetSearchParams,
  TLocationParams
} from "../../components/SearchForm";
import Table, { SortingParams } from "../../components/Table";

import pagination from "../../helpers/pagination";
import resetPaginationParams from "../../helpers/resetPaginationParams";
import STATUSES from "../../helpers/statuses";

const Search = (_props: RouteComponentProps) => (
  <Box p={6}>
    <Heading as="h1" fontWeight="normal" mb={6}>
      <Trans>Pending declarations</Trans>
    </Heading>

    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => {
        const {
          filter: { reason } = {},
          first,
          last,
          after,
          before,
          orderBy
        } = locationParams;

        return (
          <>
            <SearchDeclarationForm
              initialValues={locationParams}
              onSubmit={setLocationParams}
            />
            <Query
              query={SearchPendingDeclarationsQuery}
              variables={{
                ...pagination({ first, last, after, before, orderBy }),
                filter: { reason }
              }}
            >
              {({
                loading,
                data
              }: QueryResult<{
                pendingDeclarations: DeclarationConnection;
              }>) => {
                if (isEmpty(data) || isEmpty(data.pendingDeclarations))
                  return null;
                const {
                  pendingDeclarations: { nodes: declarations, pageInfo }
                } = data;

                return (
                  <LoadingOverlay loading={loading}>
                    {isEmpty(declarations) ? (
                      <EmptyData />
                    ) : (
                      <>
                        <Table
                          data={declarations}
                          header={{
                            databaseId: <Trans>Declaration ID</Trans>,
                            declarationNumber: (
                              <Trans>Declaration number</Trans>
                            ),
                            startDate: <Trans>Declaration start date</Trans>,
                            legalEntityName: <Trans>Legal entity</Trans>,
                            legalEntityEdrpou: <Trans>EDRPOU</Trans>,
                            divisionName: <Trans>Division name</Trans>,
                            divisionAddress: <Trans>Address</Trans>,
                            status: <Trans>Status</Trans>,
                            reason: <Trans>No tax ID</Trans>,
                            patientName: <Trans>Patient Name</Trans>,
                            action: <Trans>Action</Trans>
                          }}
                          renderRow={({
                            id,
                            legalEntity,
                            startDate,
                            division,
                            person,
                            status,
                            reason,
                            ...declaration
                          }: Declaration) => ({
                            ...declaration,
                            legalEntityName: legalEntity.name,
                            legalEntityEdrpou: legalEntity.edrpou,
                            patientName: <FullName party={person} />,
                            divisionName: division.name,
                            divisionAddress: division.addresses
                              .filter(
                                (address) =>
                                  address && address.type === "RESIDENCE"
                              )
                              .map((item, key) => (
                                <AddressView data={item} key={key} />
                              )),
                            startDate: i18n.date(startDate),
                            status: (
                              <Badge
                                name={status}
                                type="DECLARATION"
                                minWidth={100}
                              />
                            ),
                            reason: (
                              <Flex justifyContent="center">
                                {reason === "no_tax_id" ? (
                                  <PositiveIcon />
                                ) : (
                                  <NegativeIcon />
                                )}
                              </Flex>
                            ),
                            action: (
                              <Link
                                to={`../../declarations/${id}`}
                                fontWeight="bold"
                              >
                                <Trans>Show details</Trans>
                              </Link>
                            )
                          })}
                          sortableFields={["startDate", "status", "reason"]}
                          sortingParams={parseSortingParams(
                            locationParams.orderBy
                          )}
                          onSortingChange={(sortingParams: SortingParams) =>
                            setLocationParams({
                              orderBy: stringifySortingParams(sortingParams)
                            })
                          }
                          tableName="pending-declarations/search"
                          whiteSpaceNoWrap={["databaseId"]}
                          hiddenFields="reason,patientName,databaseId"
                        />
                        <Pagination pageInfo={pageInfo} />
                      </>
                    )}
                  </LoadingOverlay>
                );
              }}
            </Query>
          </>
        );
      }}
    </LocationParams>
  </Box>
);

export default Search;

type SearchDeclarationFormProps = {
  initialValues: SearchParams;
  onSubmit: SetSearchParams;
};

const SearchDeclarationForm = ({
  initialValues,
  onSubmit
}: SearchDeclarationFormProps) => (
  <Form onSubmit={() => null} initialValues={initialValues}>
    <Form.AutoSubmit
      onSubmit={(params: SearchParams) =>
        !isEqual(params.filter, initialValues.filter) &&
        onSubmit({
          ...params,
          ...resetPaginationParams(initialValues)
        })
      }
    />
    <Flex mx={-1}>
      <Box px={1} width={1 / 3}>
        <Trans
          id="Show all"
          render={({ translation }) => (
            <Field.Select
              name="filter.reason"
              label={<Trans id="Patient IPN" />}
              items={Object.keys(STATUSES.REASON)}
              itemToString={(item: string) =>
                STATUSES.REASON[item] || translation
              }
              emptyOption
              variant="select"
              filterOptions={{
                keys: [(item: string) => STATUSES.REASON[item]]
              }}
            />
          )}
        />
      </Box>
    </Flex>
  </Form>
);

const SearchPendingDeclarationsQuery = gql`
  query SearchPendingDeclarationsQuery(
    $filter: PendingDeclarationFilter
    $orderBy: DeclarationOrderBy
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    pendingDeclarations(
      filter: $filter
      orderBy: $orderBy
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      nodes {
        id
        databaseId
        declarationNumber
        startDate
        signedAt
        status
        reason
        legalEntity {
          id
          databaseId
          edrpou
          name
        }
        person {
          id
          databaseId
          firstName
          lastName
          secondName
          noTaxId
        }
        division {
          id
          databaseId
          name
          addresses {
            ...Addresses
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
  ${AddressView.fragments.entry}
`;
