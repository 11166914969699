import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { Form, LocationParams } from "@edenlabllc/ehealth-components";
import {
  NegativeIcon,
  PositiveIcon,
  SearchIcon
} from "@edenlabllc/ehealth-icons";
import { convertStringToBoolean, getPhones } from "@edenlabllc/ehealth-utils";
import { Scalars, Division, LegalEntity } from "@edenlabllc/graphql-schema";

import Ability from "../../../../components/Ability";
import AddressView from "../../../../components/AddressView";
import Badge from "../../../../components/Badge";
import * as Field from "../../../../components/Field";
import EmptyData from "../../../../components/EmptyData";
import Link from "../../../../components/Link";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Pagination from "../../../../components/Pagination";
import {
  SearchParams,
  TLocationParams
} from "../../../../components/SearchForm";
import Table from "../../../../components/Table";

import STATUSES from "../../../../helpers/statuses";
import { ITEMS_PER_PAGE } from "../../../../constants/pagination";

import { LegalEntityQuery } from "../";

type DivisionsProps = RouteComponentProps<{
  id: Scalars["ID"];
}>;

const Divisions = ({ id }: DivisionsProps) => {
  return (
    <Ability action="read" resource="division">
      <LocationParams>
        {({ locationParams, setLocationParams }: TLocationParams) => {
          const {
            first,
            last,
            after,
            before,
            filter: { divisionFilter, dlsVerified } = {}
          } = locationParams;

          return (
            <>
              <Form onSubmit={setLocationParams} initialValues={locationParams}>
                <Form.AutoSubmit
                  onSubmit={(values: SearchParams) => setLocationParams(values)}
                />
                <Flex pt={5}>
                  <Box px={5} width={1 / 2}>
                    <Trans
                      id="Enter division name"
                      render={({ translation }) => (
                        <Field.Text
                          name="filter.divisionFilter.name"
                          label={<Trans id="Find division" />}
                          placeholder={translation}
                          postfix={<SearchIcon color="silverCity" />}
                        />
                      )}
                    />
                  </Box>
                  <Box px={1} width={1 / 4}>
                    <Trans
                      id="Select option"
                      render={({ translation }) => (
                        <Field.Select
                          name="filter.dlsVerified"
                          label={<Trans id="DLS Verification" />}
                          items={Object.keys(STATUSES.YES_NO)}
                          itemToString={(item: boolean) =>
                            // @ts-expect-error statuses boolean key
                            STATUSES.YES_NO[item] || translation
                          }
                          variant="select"
                          emptyOption
                          filterOptions={{
                            // @ts-expect-error statuses boolean key
                            keys: [(item: boolean) => STATUSES.YES_NO[item]]
                          }}
                        />
                      )}
                    />
                  </Box>
                </Flex>
              </Form>
              <Query
                query={LegalEntityQuery}
                variables={{
                  id,
                  firstDivisions:
                    !first && !last
                      ? ITEMS_PER_PAGE[0]
                      : first
                        ? parseInt(first)
                        : undefined,
                  lastDivisions: last ? parseInt(last) : undefined,
                  beforeDivisions: before,
                  afterDivisions: after,
                  divisionFilter: {
                    ...divisionFilter,
                    dlsVerified: convertStringToBoolean(dlsVerified)
                  },
                  firstMergedFromLegalEntities: ITEMS_PER_PAGE[0],
                  firstHealthcareServices: ITEMS_PER_PAGE[0],
                  firstLicenses: ITEMS_PER_PAGE[0]
                }}
              >
                {({
                  loading,
                  data
                }: QueryResult<{ legalEntity: LegalEntity }>) => {
                  if (
                    isEmpty(data) ||
                    isEmpty(data.legalEntity) ||
                    isEmpty(data.legalEntity.divisions)
                  )
                    return null;
                  const {
                    legalEntity: {
                      divisions: { nodes: divisions = [], pageInfo }
                    }
                  } = data;

                  return (
                    <LoadingOverlay loading={loading}>
                      {divisions!.length > 0 ? (
                        <>
                          <Table
                            data={divisions}
                            header={{
                              name: <Trans>Legal entity name</Trans>,
                              addresses: <Trans>Address</Trans>,
                              mountainGroup: <Trans>Mountain region</Trans>,
                              phones: <Trans>Phone</Trans>,
                              email: <Trans>Email</Trans>,
                              dlsVerified: <Trans>DLS Verification</Trans>,
                              status: <Trans>Status</Trans>,
                              action: <Trans>Action</Trans>
                            }}
                            renderRow={({
                              id: divisionId,
                              mountainGroup,
                              addresses,
                              phones,
                              status,
                              dlsVerified,
                              ...props
                            }: Division) => ({
                              ...props,
                              mountainGroup: (
                                <Flex justifyContent="center">
                                  {mountainGroup ? (
                                    <PositiveIcon />
                                  ) : (
                                    <NegativeIcon />
                                  )}
                                </Flex>
                              ),
                              dlsVerified: (
                                <Flex justifyContent="center">
                                  {/* @ts-expect-error statuses boolean key */}
                                  {STATUSES.YES_NO[dlsVerified]}
                                </Flex>
                              ),
                              addresses: addresses
                                .filter((a) => a && a.type === "RESIDENCE")
                                .map((item, key) => (
                                  <AddressView data={item} key={key} />
                                )),
                              phones: getPhones(phones),
                              status: (
                                <Badge
                                  type="DIVISIONS"
                                  name={status}
                                  display="block"
                                />
                              ),
                              action: (
                                <Link to={divisionId} fontWeight="bold">
                                  <Trans>Details</Trans>
                                </Link>
                              )
                            })}
                          />
                          <Pagination pageInfo={pageInfo} />
                        </>
                      ) : (
                        <EmptyData />
                      )}
                    </LoadingOverlay>
                  );
                }}
              </Query>
            </>
          );
        }}
      </LocationParams>
    </Ability>
  );
};

Divisions.fragments = {
  entry: gql`
    fragment LegalEntityDivisions on LegalEntity {
      divisions(
        first: $firstDivisions
        filter: $divisionFilter
        before: $beforeDivisions
        after: $afterDivisions
        last: $lastDivisions
      ) {
        nodes {
          id
          databaseId
          name
          addresses {
            ...Addresses
          }
          phones {
            type
            number
          }
          email
          status
          mountainGroup
          dlsVerified
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
    ${Pagination.fragments.entry}
    ${AddressView.fragments.entry}
  `
};

export default Divisions;
