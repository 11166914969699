import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Flex } from "@rebass/emotion";

import { Dictionary } from "@edenlabllc/graphql-schema";
import { SearchIcon } from "@edenlabllc/ehealth-icons";

import * as Field from "../../../components/Field";
import * as SearchField from "../../../components/SearchField";
import fromBase64toUUID from "../../../helpers/fromBase64toUUID";

type SearchFieldsProps = {
  allValues: Dictionary["allValues"];
};

export const SearchFields = ({ allValues }: SearchFieldsProps) => {
  const itemsKeys = allValues ? allValues.map((item) => item && item.code) : [];
  const itemsValues = allValues
    ? allValues.map((item) => item && item.description)
    : [];

  return (
    <>
      <Flex mx={-1} pt={5}>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Select key"
            render={({ translation }) => (
              <Field.Select
                name="filter.code"
                label={<Trans id="Key" />}
                placeholder={translation}
                items={itemsKeys}
                hideErrors
              />
            )}
          />
        </Box>
        <Box px={1} width={2 / 3}>
          <Trans
            id="Enter description"
            render={({ translation }) => (
              <Field.Select
                name="filter.description"
                label={<Trans id="Description" />}
                placeholder={translation}
                items={itemsValues}
                iconComponent={SearchIcon}
                hideErrors
              />
            )}
          />
        </Box>
      </Flex>
      <Flex mx={-1} pt={5}>
        <Box px={1} width={1 / 3}>
          <Trans
            id="Select parent code"
            render={({ translation }) => (
              <Field.Select
                name="filter"
                label={<Trans id="Parent code" />}
                placeholder={translation}
                items={
                  allValues
                    ? allValues.map((item) => ({
                        parentId: fromBase64toUUID((item && item.id) || ""),
                        parentCode: item && item.code
                      }))
                    : undefined
                }
                itemToString={(item) => item && item.parentCode}
                filterOptions={{
                  keys: ["parentCode"]
                }}
                hideErrors
              />
            )}
          />
        </Box>
        <Box px={1} width={1 / 3}>
          <SearchField.Status name="filter.isActive" status="ACTIVE_STATUS_M" />
        </Box>
      </Flex>
    </>
  );
};
