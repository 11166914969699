import React from "react";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { useLingui } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Box, Text, Flex } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { LocationParams, Validation } from "@edenlabllc/ehealth-components";
import { ProcedureConnection } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import DictionaryValue, {
  DictionaryAllValuesJson
} from "../../../components/DictionaryValue";
import EmptyData from "../../../components/EmptyData";
import * as Field from "../../../components/Field";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Pagination from "../../../components/Pagination";
import SearchForm, {
  SearchParams,
  TLocationParams
} from "../../../components/SearchForm";

import { MonitoringTabProps } from "../../../helpers/types";
import pagination from "../../../helpers/pagination";
import paramToBase64 from "../../../helpers/paramToBase64";
import { UUID_PATTERN } from "../../../constants/validationPatterns";

import { PatientSearchField } from "../../ClinicalMonitoring/PatientSearchField";
import { SearchResultTable } from "./EpisodesTab";

const ProceduresTab = ({
  id,
  query = ProceduresQuery,
  isClinicalMonitoring = false
}: MonitoringTabProps) => (
  <Ability
    action={isClinicalMonitoring ? "clinical_monitor" : "practical_monitor"}
    resource="procedure"
  >
    <LocationParams>
      {({ locationParams, setLocationParams }: TLocationParams) => (
        <Query
          skip={!locationParams.first && !locationParams.last}
          query={query}
          fetchPolicy="network-only"
          variables={prepareParamsToQuery(
            id!,
            locationParams,
            isClinicalMonitoring
          )}
        >
          {({
            data = {},
            loading
          }: QueryResult<{ [key: string]: ProcedureConnection }>) => {
            const procedures = isClinicalMonitoring
              ? data.impersonalProcedures
              : data.procedures;

            return (
              <LoadingOverlay loading={loading}>
                <Box p={1}>
                  <SearchForm
                    initialValues={locationParams}
                    onSubmit={setLocationParams}
                    renderPrimary={() =>
                      PrimarySearchFields(isClinicalMonitoring)
                    }
                    onResetSkipPagination
                  />
                </Box>
                {!data || isEmpty(procedures) || isEmpty(procedures.nodes) ? (
                  <EmptyData mx={2} />
                ) : (
                  <Box mb={6} p={2}>
                    <Box>
                      <Text>
                        <Trans>Total found</Trans>: {procedures.totalEntries}
                      </Text>
                    </Box>
                    <SearchResultTable
                      data={procedures.nodes}
                      locationParams={locationParams}
                      setLocationParams={setLocationParams}
                      statusSystem="eHealth/procedure_statuses"
                      tableName="procedures"
                      pathSlug={
                        isClinicalMonitoring
                          ? `procedure/${
                              locationParams.filter &&
                              locationParams.filter.patientId
                            }`
                          : "procedure"
                      }
                      isClinicalMonitoring={isClinicalMonitoring}
                      codeColumnTextType="service"
                    />
                    <Pagination pageInfo={procedures.pageInfo} />
                  </Box>
                )}
              </LoadingOverlay>
            );
          }}
        </Query>
      )}
    </LocationParams>
  </Ability>
);

export default ProceduresTab;

const ProceduresQuery = gql`
  query ProceduresQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: ProcedureFilter!
    $orderBy: ProcedureOrderBy
  ) {
    procedures(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      orderBy: $orderBy
    ) {
      totalEntries
      nodes {
        id
        databaseId
        status
        insertedAt
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${Pagination.fragments.entry}
`;

const prepareParamsToQuery = (
  id: string,
  locationParams: SearchParams,
  isClinicalMonitoring: boolean
) => {
  const {
    code,
    status,
    basedOn,
    encounterId,
    managingOrganizationId,
    originEpisodeId,
    procedureId,
    ...restFilterParams
  } = locationParams.filter || {};

  return {
    orderBy: "INSERTED_AT_DESC",
    ...pagination(locationParams),
    filter: {
      ...(!isClinicalMonitoring && { personId: id }),
      ...restFilterParams,
      ...(code && {
        code: paramToBase64("Service", code)
      }),
      ...(status && {
        status: status.toUpperCase()
      }),
      ...(basedOn && {
        basedOn: paramToBase64("ServiceRequest", basedOn)
      }),
      ...(encounterId && {
        encounterId: paramToBase64("Encounter", encounterId)
      }),
      ...(managingOrganizationId && {
        managingOrganizationId: paramToBase64(
          "LegalEntity",
          managingOrganizationId
        )
      }),
      ...(originEpisodeId && {
        originEpisodeId: paramToBase64("Episode", originEpisodeId)
      }),
      ...(procedureId && {
        id: paramToBase64("Procedure", procedureId)
      })
    }
  };
};

const PrimarySearchFields = (isClinicalMonitoring: boolean) => {
  const { i18n } = useLingui();
  return (
    <Flex flexDirection="column">
      <PatientSearchField isClinicalMonitoring={isClinicalMonitoring} />
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter service ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.code"
                label={<Trans id="Service ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.code"
            options={UUID_PATTERN}
            message="Invalid service ID"
          />
        </Box>
        <Box width={1 / 3} mt={3} px={1}>
          <DictionaryValue name="eHealth/procedure_statuses">
            {(dict: DictionaryAllValuesJson) => (
              <Field.Select
                name="filter.status"
                label={<Trans id="Procedure status" />}
                placeholder={i18n._(t`Select status`)}
                items={Object.keys(dict)}
                itemToString={(item: string) => dict[item] || item}
                variant="select"
              />
            )}
          </DictionaryValue>
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter service request ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.basedOn"
                label={<Trans id="Based on service request" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.basedOn"
            options={UUID_PATTERN}
            message="Invalid service request ID"
          />
        </Box>
      </Flex>
      <Flex>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter procedure's encounter ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.encounterId"
                label={<Trans id="Procedure's encounter ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.encounterId"
            options={UUID_PATTERN}
            message="Invalid encounter ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter managing organization ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.managingOrganizationId"
                label={<Trans id="Managing organization ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.managingOrganizationId"
            options={UUID_PATTERN}
            message="Invalid managing organization ID"
          />
        </Box>
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter origin episode ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.originEpisodeId"
                label={<Trans id="Origin episode ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.originEpisodeId"
            options={UUID_PATTERN}
            message="Invalid origin episode ID"
          />
        </Box>
      </Flex>
      {isClinicalMonitoring && (
        <Box px={1} width={1 / 3} mt={3}>
          <Trans
            id="Enter procedure ID"
            render={({ translation }) => (
              <Field.Text
                name="filter.procedureId"
                label={<Trans id="Procedure ID" />}
                placeholder={translation}
              />
            )}
          />
          <Validation.Matches
            field="filter.procedureId"
            options={UUID_PATTERN}
            message="Invalid procedure ID"
          />
        </Box>
      )}
    </Flex>
  );
};
