import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { QueryResult } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { Flex, Box, Text } from "@rebass/emotion";
import isEmpty from "lodash/isEmpty";

import { PositiveIcon, NegativeIcon } from "@edenlabllc/ehealth-icons";
import { Dictionary } from "@edenlabllc/graphql-schema";
import { LocationParams } from "@edenlabllc/ehealth-components";

import Breadcrumbs from "../../../components/Breadcrumbs";
import DefinitionListView from "../../../components/DefinitionListView";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Tabs from "../../../components/Tabs";
import Pagination from "../../../components/Pagination";
import { TLocationParams } from "../../../components/SearchForm";
import { DictionaryValues } from "./DictionaryValues";
import filteredLocationParams from "../../../helpers/filteredLocationParams";

import { UpdateDictionaryDescription } from "./Mutations/UpdateDictionaryDescription";
import DictionaryLabels from "./Mutations/UpdateDictionaryLabels";
import Ability from "../../../components/Ability";

type DetailsProps = RouteComponentProps<{
  name: Dictionary["name"];
}>;

const Details = ({ name }: DetailsProps) => (
  <LocationParams>
    {({ locationParams, setLocationParams }: TLocationParams) => (
      <Query
        query={DictionaryByNameQuery}
        variables={{
          dictionaryName: name ? decodeURIComponent(name) : "",
          ...filteredLocationParams(locationParams)
        }}
      >
        {({
          loading,
          data,
          refetch
        }: QueryResult<{ dictionaryByName: Dictionary }>) => {
          if (isEmpty(data) || isEmpty(data.dictionaryByName)) return null;
          const {
            id,
            databaseId,
            isActive,
            labels = [],
            description,
            values,
            allValues
          } = data.dictionaryByName;
          const isReadOnly = labels ? labels.includes("READ_ONLY") : false;

          return (
            <LoadingOverlay loading={loading}>
              <Box p={6}>
                <Box py={10}>
                  <Breadcrumbs.List>
                    <Breadcrumbs.Item to="/dictionaries">
                      <Trans>Dictionaries</Trans>
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item>
                      <Trans>Editing the dictionary</Trans>
                    </Breadcrumbs.Item>
                  </Breadcrumbs.List>
                </Box>
                <Flex justifyContent="space-between">
                  <Box>
                    {name ? (
                      <Box mb={20}>
                        <Text>{decodeURIComponent(name)}</Text>
                      </Box>
                    ) : (
                      ""
                    )}

                    <DefinitionListView
                      labels={{
                        status: <Trans>Status</Trans>,
                        description: <Trans>Description</Trans>
                      }}
                      data={{
                        status: isActive ? (
                          <Flex alignItems="center">
                            <PositiveIcon />
                            <Text ml={2}>
                              <Trans>Active</Trans>
                            </Text>
                          </Flex>
                        ) : (
                          <Flex alignItems="center">
                            <NegativeIcon />
                            <Text ml={2}>
                              <Trans>Inactive</Trans>
                            </Text>
                          </Flex>
                        ),
                        description: (
                          <Flex alignItems="center">
                            <Box mr={2}>{description || "-"}</Box>
                            {!isReadOnly && (
                              <Ability action="write" resource="dictionary">
                                <UpdateDictionaryDescription
                                  id={id}
                                  description={description}
                                />
                              </Ability>
                            )}
                          </Flex>
                        )
                      }}
                      labelWidth="100px"
                    />
                  </Box>
                </Flex>
              </Box>

              <Tabs.Nav>
                <Tabs.NavItem to="./">
                  <Trans>Value</Trans>
                </Tabs.NavItem>
                <Tabs.NavItem to="./labels">
                  <Trans>Tags</Trans>
                </Tabs.NavItem>
              </Tabs.Nav>
              <Tabs.Content>
                <Router>
                  <DictionaryValues
                    path="/"
                    id={id}
                    databaseId={databaseId}
                    allValues={allValues}
                    values={values ? values.nodes : undefined}
                    pageInfo={values ? values.pageInfo : undefined}
                    isActive={isActive}
                    isReadOnly={isReadOnly}
                    locationParams={locationParams}
                    setLocationParams={setLocationParams}
                    refetch={refetch}
                  />
                  <DictionaryLabels
                    path="/labels"
                    id={id}
                    labels={labels}
                    isActive={isActive}
                    isReadOnly={isReadOnly}
                  />
                </Router>
              </Tabs.Content>
            </LoadingOverlay>
          );
        }}
      </Query>
    )}
  </LocationParams>
);

const DictionaryByNameQuery = gql`
  query DictionaryByNameQuery(
    $after: String
    $before: String
    $dictionaryName: String!
    $filter: DictionaryValueFilter
    $first: Int
    $last: Int
  ) {
    dictionaryByName(dictionaryName: $dictionaryName) {
      id
      databaseId
      name
      isActive
      labels
      description
      allValues {
        id
        code
        description
      }
      values(
        first: $first
        last: $last
        before: $before
        after: $after
        filter: $filter
      ) {
        nodes {
          id
          databaseId
          code
          description
          isActive
          parentId
          parent {
            id
            code
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${Pagination.fragments.entry}
`;

export default Details;
