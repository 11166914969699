import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Box } from "@rebass/emotion";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { Link } from "@edenlabllc/ehealth-components";
import { ProgramDevice } from "@edenlabllc/graphql-schema";

import Ability from "../../../components/Ability";
import DefinitionListView from "../../../components/DefinitionListView";
import DictionaryValue from "../../../components/DictionaryValue";
import Line from "../../../components/Line";
import Price from "../../../components/Price";

import paramToBase64 from "../../../helpers/paramToBase64";
import { getProgramDeviceName } from "../../../helpers/deviceDefinition";

import UpdateReimbursementData from "./Mutations/UpdateReimbursementData";

type GeneralInfoProps = RouteComponentProps & {
  isActive: ProgramDevice["isActive"];
  details: Partial<ProgramDevice>;
};

const GeneralInfo = ({
  isActive,
  details: {
    id,
    wholesalePrice,
    consumerPrice,
    estimatedPaymentAmount,
    reimbursementDailyCount,
    deviceDefinition,
    medicalProgram,
    reimbursement,
    startDate,
    endDate,
    registryNumber,
    maxDailyCount
  }
}: GeneralInfoProps) => {
  const {
    id: deviceId,
    databaseId: deviceDatabaseId,
    deviceNames
  } = deviceDefinition || {};
  const { databaseId: medicalProgramId, name: medicalProgramName } =
    medicalProgram || {};
  const { type, reimbursementAmount, percentageDiscount } = reimbursement || {};

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          deviceName: <Trans>Device definition registered name</Trans>
        }}
        data={{
          deviceName: deviceNames && getProgramDeviceName(deviceNames)
        }}
        labelWidth="280px"
      />
      <DefinitionListView
        labels={{
          deviceId: <Trans>Device definition ID</Trans>
        }}
        data={{
          deviceId: (
            <Link to={`/device-definitions/${deviceId}`}>
              {deviceDatabaseId}
            </Link>
          )
        }}
        color="blueberrySoda"
        labelWidth="280px"
      />
      <Line />
      <DefinitionListView
        labels={{
          medicalProgramName: <Trans>Medical program name</Trans>
        }}
        data={{
          medicalProgramName
        }}
        labelWidth="280px"
      />
      <DefinitionListView
        labels={{
          medicalProgramId: <Trans>Medical program ID</Trans>
        }}
        data={{
          medicalProgramId: (
            <Link
              to={`/medical-programs/${paramToBase64(
                "MedicalProgram",
                medicalProgramId
              )}`}
            >
              {medicalProgramId}
            </Link>
          )
        }}
        color="blueberrySoda"
        labelWidth="280px"
      />
      <Line />
      <DefinitionListView
        labels={{
          type: <Trans>Reimbursement type</Trans>,
          reimbursementAmount: (
            <Trans>
              Reimbursement amount for the package of the medical product
            </Trans>
          ),
          ...(percentageDiscount && {
            percentageDiscount: <Trans>% of discount</Trans>
          }),
          wholesalePrice: <Trans>Wholesale price</Trans>,
          consumerPrice: <Trans>Consumer price</Trans>,
          estimatedPaymentAmount: <Trans>Estimated payment amount</Trans>,
          reimbursementDailyCount: <Trans>Reimbursement daily count</Trans>,
          startDate: <Trans>Start date</Trans>,
          endDate: <Trans>End date</Trans>,
          registryNumber: <Trans>Registry number</Trans>,
          ...(maxDailyCount && {
            maxDailyCount: <Trans>Max daily count</Trans>
          })
        }}
        data={{
          type: <DictionaryValue name="REIMBURSEMENT_TYPE" item={type} />,
          reimbursementAmount: <Price amount={reimbursementAmount} />,
          ...(percentageDiscount && {
            percentageDiscount: `${percentageDiscount} %`
          }),
          wholesalePrice: wholesalePrice && <Price amount={wholesalePrice} />,
          consumerPrice: consumerPrice && <Price amount={consumerPrice} />,
          estimatedPaymentAmount: typeof estimatedPaymentAmount ===
            "number" && <Price amount={estimatedPaymentAmount} />,
          reimbursementDailyCount: typeof reimbursementDailyCount ===
            "number" && <Price amount={reimbursementDailyCount} />,
          startDate: startDate ? i18n.date(startDate) : "-",
          endDate: endDate ? i18n.date(endDate) : "-",
          registryNumber: registryNumber ? registryNumber : "-",
          maxDailyCount: maxDailyCount
        }}
        labelWidth="280px"
      />
      {isActive && (
        <Box pt={10}>
          <Ability action="write" resource="program_device">
            <UpdateReimbursementData
              id={id!}
              startDate={startDate}
              endDate={endDate}
            />
          </Ability>
        </Box>
      )}
    </Box>
  );
};

GeneralInfo.fragments = {
  entry: gql`
    fragment ProgramDeviceGeneralInfo on ProgramDevice {
      wholesalePrice
      consumerPrice
      estimatedPaymentAmount
      reimbursementDailyCount
      startDate
      endDate
      registryNumber
      maxDailyCount
      reimbursement {
        type
        reimbursementAmount
        percentageDiscount
      }
      medicalProgram {
        id
        databaseId
        name
        isActive
      }
      deviceDefinition {
        id
        databaseId
        deviceNames {
          name
          type
        }
        isActive
      }
    }
  `
};

export default GeneralInfo;
