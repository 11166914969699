import React from "react";
import { RouteComponentProps } from "@reach/router";
import { gql } from "graphql-tag";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Box } from "@rebass/emotion";

import { ForbiddenGroup } from "@edenlabllc/graphql-schema";

import DefinitionListView from "../../../components/DefinitionListView";

import dateFormatter from "../../../helpers/dateFormatter";
import { DATE_TIME_FORMAT } from "../../../constants/dateFormats";

type GeneralInfoProps = RouteComponentProps & {
  forbiddenGroup: ForbiddenGroup;
};

const GeneralInfo = ({ forbiddenGroup }: GeneralInfoProps) => {
  const {
    creationReason,
    deactivationReason,
    insertedAt,
    updatedAt,
    name,
    shortName,
    smsUrl
  } = forbiddenGroup;

  return (
    <Box p={5}>
      <DefinitionListView
        labels={{
          name: <Trans>Forbidden group name</Trans>,
          insertedAt: <Trans>Inserted at</Trans>,
          updatedAt: <Trans>Updated at</Trans>,
          creationReason: <Trans>Creation reason</Trans>,
          deactivationReason: <Trans>Deactivation reason</Trans>,
          shortName: <Trans>Short name</Trans>,
          smsUrl: <Trans>Forbidden group sms url</Trans>
        }}
        data={{
          creationReason,
          deactivationReason,
          insertedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, insertedAt),
          updatedAt: dateFormatter(i18n.locale, DATE_TIME_FORMAT, updatedAt),
          name,
          shortName: shortName || "-",
          smsUrl: smsUrl || "-"
        }}
        labelWidth="250px"
      />
    </Box>
  );
};

export default GeneralInfo;

GeneralInfo.fragments = {
  entry: gql`
    fragment ForbiddenGroupGeneralInfo on ForbiddenGroup {
      id
      creationReason
      deactivationReason
      insertedAt
      name
      shortName
      smsUrl
    }
  `
};
